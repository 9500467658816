@import url('https://fonts.googleapis.com/css2?family=Jost:wght@100;200;300;400;500;600;700&display=swap');
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Jost', sans-serif;
}
body {
    background: #fff;
    overflow: visible !important;
    padding: 0 !important;
}
body.flex100vh,
body > div#root {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
body > div#root ~ .modal-backdrop{display: none !important;}
body {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background: #f3f3f3;
}
.flex-1 {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
a {
    color: #d42941;
}
a:hover {
    color: #bb1b32;
    text-decoration: none;
}
.font-12 {
    font-size: 12px !important;
}
.font-13 {
    font-size: 13px !important;
}
.font-14 {
    font-size: 14px !important;
}
.font-15 {
    font-size: 15px !important;
}
.font-16 {
    font-size: 16px !important;
}
.font-17 {
    font-size: 17px !important;
}
.font-18 {
    font-size: 18px !important;
}
.font-19 {
    font-size: 19px !important;
}
.font-20 {
    font-size: 20px !important;
}
.font-21 {
    font-size: 21px !important;
}
.font-22 {
    font-size: 22px !important;
}
.font-23 {
    font-size: 23px !important;
}
.font-24 {
    font-size: 24px !important;
}
.font-25 {
    font-size: 25px !important;
}
.font-26 {
    font-size: 26px !important;
}
.font-27 {
    font-size: 27px !important;
}
.font-28 {
    font-size: 28px !important;
}
.font-29 {
    font-size: 29px !important;
}
.font-30 {
    font-size: 30px !important;
}
.font-31 {
    font-size: 31px !important;
}
.font-32 {
    font-size: 32px !important;
}
.font-48 {
    font-size: 48px !important;
}
.font-700 {
    font-weight: 700 !important;
}
.font-600 {
    font-weight: 600 !important;
}
.font-500 {
    font-weight: 500 !important;
}
.font-400 {
    font-weight: 400 !important;
}
.font-300 {
    font-weight: 300 !important;
}
.f.input-otp.input-otpont-100 {
    font-weight: 100 !important;
}
.text-burgandi {
    color: #d42941;
}
.text-green {
    color: #5cae48;
}
.text-dark-blue {
    color: #0b3454;
}
.text-light-blue,
.text-sky-blue {
    color: #26a7df;
}
.text-purple {
    color: #51499c;
}
.text-gray-41 {
    color: #414141;
}
.text-gray-84 {
    color: #848484;
}
.text-gray-64 {
    color: #646464;
}
.text-gray-53 {
    color: #535353;
}
.text-gray-4b {
    color: #4b4b4b;
}
.line-height-24 {
    line-height: 24px;
}
.line-height-16 {
    line-height: 16px;
}
.line-height-13 {
    line-height: 13px;
}
.top-10 {
    top: 10px !important;
}
.top-20 {
    top: 20px !important;
}
.top-30 {
    top: 30px !important;
}
.top-40 {
    top: 40px !important;
}
.top-50 {
    top: 50px !important;
}
.bottom-10 {
    bottom: 10px !important;
}
.bottom-20 {
    bottom: 20px !important;
}
.bottom-30 {
    bottom: 30px !important;
}
.bottom-40 {
    bottom: 40px !important;
}
.bottom-50 {
    bottom: 50px !important;
}
.right-10 {
    right: 10px !important;
}
.right-20 {
    right: 20px !important;
}
.right-30 {
    right: 30px !important;
}
.right-40 {
    right: 40px !important;
}
.right-50 {
    right: 50px !important;
}
.max-1170 {
    max-width: 1170px;
    width: 90%;
}
.max-1140 {
    max-width: 1140px;
    width: 94%;
}
.max-1040 {
    max-width: 1040px;
    width: 94%;
}
.max-1010 {
    max-width: 1010px;
    width: 94%;
}
.max-940 {
    max-width: 940px;
    width: 94%;
}
.max-900 {
    max-width: 900px;
    width: 90%;
}
.max-850 {
    max-width: 850px;
    width: 94%;
}
.max-740 {
    max-width: 740px;
    width: 90%;
}
.max-700 {
    max-width: 700px;
    width: 90%;
}
.max-650 {
    max-width: 650px;
    width: 90%;
}
.max-630 {
    max-width: 630px;
    width: 90%;
}
.max-620 {
    max-width: 620px;
    width: 90%;
}
.max-570 {
    max-width: 570px;
    width: 90%;
}
.max-540 {
    max-width: 540px;
    width: 90%;
}

.max-120 {
    max-width: 120px !important;
    width: 120px;
}
.max-150 {
    max-width: 150px !important;
    width: 150px;
}
.max-167 {
    max-width: 167px !important;
    width: 167px;
}
.max-170 {
    max-width: 170px !important;
    width: 170px;
}
.max-190 {
    max-width: 190px !important;
    width: 190px;
}
.max-200 {
    max-width: 200px !important;
    width: 100% !important;
}
.max-240 {
    max-width: 240px !important;
    width: 100%;
}
.max-250 {
    max-width: 250px !important;
    width: 100%;
}
.max-300 {
    max-width: 300px !important;
    width: 100%;
}
.max-360 {
    max-width: 360px !important;
    width: 100%;
}
.max-420 {
    max-width: 420px !important;
    width: 90%;
}
.max-470 {
    max-width: 470px !important;
    width: 100%;
}
.max-100per {
    max-width: 100% !important;
    width: 100%;
}
.max-80 {
    max-width: 100% !important;
    width: 80px;
}
.min-wauto {
    min-width: auto !important;
    width: auto;
}

/* login and signup css */
body .bg_side_blue {
    position: relative;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
body .bg_side_blue::after {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    content: '';
    background: #0b3454;
    width: 32%;
}

@media (max-width: 767px) {
    body .bg_side_blue::after {
        width: 100%;
    }
}
body .bg_side_blue > .container {
    z-index: 1;
}
.custom_logo {
    padding: 40px 0 0;
}
.custom_logo .img-fluid {
    max-height: 100px;
    width: auto;
}
.leftPaneMain {
    width: 100%;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
}
.leftPaneMain h2 {
    font-size: 30px;
    line-height: 35px;
    max-width: 420px;
    text-align: center;
    font-weight: bold;
    margin: 0 0 20px;
}
.leftPaneMain .login_carousel {
    max-width: 460px;
    margin: 0 auto 30px;
    width: 90%;
}
.leftPaneMain .login_carousel .innercover .img img {
    max-width: 320px;
}
.leftPaneMain .login_carousel .innercover h3 {
    font-size: 30px;
    line-height: 35px;
    font-weight: bold;
}
.leftPaneMain .login_carousel .innercover p {
    font-size: 18px;
    line-height: 26px;
}
.leftPaneMain .login_carousel .owl-dots {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
}
.leftPaneMain .login_carousel .owl-dots .owl-dot {
    background: #585858 !important;
    opacity: 0.2;
    width: 9px;
    height: 9px;
    margin: 0 4px;
    opacity: 0.2;
    filter: alpha(opacity=20);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.leftPaneMain .login_carousel .owl-dots .owl-dot.active {
    opacity: 0.8;
    filter: alpha(opacity=80);
}

.carousel-item figure {
    padding: 0px;
    margin: 0px 0 30px 0;
    height: 240px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: end;
}
.carousel-item figure img {
    max-width: 250px;
    width: 100%;
}
.carousel-item h4 {
    font-size: 30px;
    line-height: 35px;
    color: #000000;
    padding: 0px;
    margin: 0px auto 20px auto;
    font-weight: 600;
    max-width: unset;
}
.carousel-item p {
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    padding: 0px;
    margin: 0px auto;
    max-width: 400px;
}

.leftPaneFooter {
    padding: 40px 0;
    width: 100%;
}
.leftPaneFooter .copyRight {
    font-size: 14px;
    font-weight: 500;
}
.leftPaneFooter .copyRight span {
    display: block;
    font-size: 13px;
    color: #060606;
    opacity: 0.6;
}
.leftPaneFooter .powerby {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    max-width: 127px;
}
.leftPaneFooter .powerby span {
    font-size: 14px;
}
.btn-View-appeal {
    background: #d42941;
    border-radius: 35px;
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    -ms-border-radius: 35px;
    -o-border-radius: 35px;
    color: #fff;
    border: 0;
    height: 50px;
    padding: 5px !important;
    align-items: center;
    margin: 0 auto;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.btn-View-appeal i {
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    margin-right: 10px;
    position: relative;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.btn-View-appeal i::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    width: 22px;
    height: 16px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/youtube.svg);
}
.btn-View-appeal span {
    font-size: 16px;
    font-weight: 500;
    margin-right: 20px;
}
.btn-View-appeal:hover {
    background: #e8354e;
}
.btn-View-appeal:hover i {
    background: #000;
}
.btn-View-appeal:hover i::before {
    filter: invert(1) brightness(1);
    -webkit-filter: invert(1) brightness(1);
}
.bg_side_blue .signup {
    flex-direction: column;
    padding-top: 40px;
    background: #0b3454;
    margin-right: -1px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    border-radius: 0 18px 18px 80px;
    -webkit-border-radius: 0 18px 18px 80px;
    -moz-border-radius: 0 18px 18px 80px;
    -ms-border-radius: 0 18px 18px 80px;
    -o-border-radius: 0 18px 18px 80px;
}
.bg_side_blue .signup h2 {
    color: #fff;
    font-size: 28px;
}
.bg_side_blue .signup h2:empty,
.bg_side_blue .signup h6:empty {
    display: none;
}
.bg_side_blue .signup > ul.nav {
    display: inline-flex !important;
    border-bottom: 0px solid #000;
    max-width: 496px;
    width: 90%;
    margin: 0 auto;
}
.bg_side_blue .signup > ul.nav li {
    -webkit-box-flex: 2;
    -moz-box-flex: 2;
    -webkit-flex: 2;
    -ms-flex: 2;
    flex: 2;
}
.bg_side_blue .signup > ul.nav li button {
    width: 100%;
    border: 0;
    border-radius: 0;
    line-height: 70px;
    padding: 0;
    font-size: 14px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.5);
    border-bottom: 2px solid #375973;
    background: none !important;
    font-size: 16px !important;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
}
.bg_side_blue .signup > ul.nav li button.active {
    color: #fff;
    border-color: #5cae48;
}
.bg_side_blue .signup > .tab-content {
    max-width: 496px;
    width: 90%;
    margin: 0 auto;
}
.bg_side_blue .signup .form-group {
    position: relative;
}
.bg_side_blue .signup .form-label {
    color: #fff;
    font-size: 16px;
}
.bg_side_blue .signup .form-group .viewpassword {
    position: absolute;
    right: 15px;
    bottom: auto;
    top: 14px;
    margin: auto;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-content: center;
    z-index: 2;
}
.bg_side_blue .signup .form-group .form-check-label {
    cursor: pointer;
}
.bg_side_blue .signup .form-group .form-check-label .icon-whatapp {
    width: 20px;
    height: 20px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/whatsapp.svg) no-repeat 0 0;
    display: inline-block;
    margin-right: 5px;
    margin-top: 3px;
}
.bg_side_blue .signup .form-check {
    position: relative;
}
.bg_side_blue .signup .form-check #agreeToUnivariety + .text-danger {
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
}
.bg_side_blue .signup .form-check-label {
    color: #fff;
}
.bg_side_blue .signup .form-check-label[for*='provideUpdatesOnWhatsapp'] {
    display: inline-flex;
    align-content: center;
}
.bg_side_blue .signup .form-check-label[for*='provideUpdatesOnWhatsapp']:before {
    content: '';
    width: 20px;
    height: 20px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/whatsapp.svg) no-repeat 0 0;
    display: inline-block;
    margin: 0 5px 0 0;
}
#provideUpdatesOnWhatsapp {
    vertical-align: top;
    display: inline-block;
    position: static;
    order: -1;
}
.bg_side_blue .signup .email-text {
    color: #ffffff99;
}
.bg_side_blue .signup .btn-completed {
    background: #5cae48;
    color: #fff;
    border-color: #5cae48;
    max-width: 200px;
    width: 100%;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
}
.bg_side_blue .signup .btn-completed:hover {
    background: #88bb1b;
    color: #fff;
    border-color: #88bb1b;
}
.bg_side_blue .signup .btn-View-appeal {
    background: #fff;
    color: #d42941;
    line-height: 18px;
}
.bg_side_blue .signup .btn-View-appeal i {
    background: #d42941;
}
.bg_side_blue .signup .btn-View-appeal i::before {
    filter: invert(1);
    -webkit-filter: invert(1);
}
.bg_side_blue .signup .btn-View-appeal:hover {
    background: #5cae48;
    color: #fff;
}
.bg_side_blue .signup .btn-View-appeal:hover i {
    background: #fff;
}
.bg_side_blue .signup .btn-View-appeal:hover i::before {
    filter: invert(0);
    -webkit-filter: invert(0);
}
.bg_side_blue .signup .or {
    margin: 0 0 20px;
    text-align: center;
    display: inline-block;
    width: 100%;
    font-size: 18px;
    line-height: 22px;
    color: #fff;
    position: relative;
}
.bg_side_blue .signup .or::after {
    background: #38566e;
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 11px;
    margin: auto;
    content: '';
    left: 0;
    right: 0;
    max-width: 463px;
}
.bg_side_blue .signup .or b {
    font-weight: 400;
    background: #0b3454;
    position: relative;
    z-index: 2;
    padding: 0 10px;
}
.bg_side_blue .signup .sociallnks {
    text-align: center;
}
.bg_side_blue .signup .sociallnks p {
    font-size: 14px;
    line-height: 17px;
    color: #fff;
    font-family: 'Jost', sans-serif;
    margin: 0;
    font-weight: 400;
}
.bg_side_blue .signup .sociallnks p a {
    color: #a2e609;
    font-weight: 500;
}
.bg_side_blue .signup .sociallnks p a:hover {
    color: #5cae48 !important;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
}
.bg_side_blue .signup .sociallnks ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    justify-content: center;
}
.bg_side_blue .signup .sociallnks ul li {
    width: 100px;
    margin: 20px 10px;
}
.bg_side_blue .signup .sociallnks ul li a {
    background: #ffffff;
    box-shadow: 0px 5px 10px #0000001a;
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    border-radius: 18px;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    -ms-border-radius: 18px;
    -o-border-radius: 18px;
    height: 36px;
    justify-content: center;
    align-items: center;
}
.bg_side_blue .signup .leftPaneFooter {
    background: #fff;
    padding: 20px;
    width: auto;
    margin-right: calc(var(--bs-gutter-x) / -1.03);
    margin-left: calc(var(--bs-gutter-x) / -1.02);
}

.login_social_media .or {
    display: block;
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    color: #ffffff;
    position: relative;
    padding-bottom: 4px;
    margin-bottom: 24px;
}
.login_social_media .or:after {
    width: 100%;
    height: 1px;
    background: #1e4d94;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    content: '';
}
.login_social_media .or em {
    font-style: normal;
    padding: 0 15px;
    background: #0d3453;
    font-size: 18px;
    line-height: 24px;
    color: #ffffff;
    position: relative;
    z-index: 99;
}
.login_social_media ul {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 20px 0;
    padding: 0px;
    list-style: none;
}
.login_social_media ul li {
    padding: 0 10px;
}
.login_social_media ul li a {
    width: auto;
    height: auto;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    padding: 15px 30px;
    color: #fff;
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.login_social_media ul li a img {
    filter: brightness(0) invert(1);
    -webkit-filter: brightness(0) invert(1);
}
.login_social_media ul li a.google {
    background-color: #cb4023;
}

.login_social_media ul li a.linkedin {
    background-color: #0077b7;
}
.login_social_media ul li a.google:hover {
    background-color: #fff;
    color: #cb4023 !important;
}
.login_social_media ul li a.linkedin:hover {
    background-color: #fff;
    color: #0077b7 !important;
}
.login_social_media ul li a:hover img {
    filter: none;
    -webkit-filter: none;
}
.login_social_media label {
    display: block;
    text-align: center;
    font-size: 14px;
    color: #ffffff;
    margin-bottom: 18px;
}
.login_social_media p {
    display: block;
    text-align: center;
    font-size: 14px;
    color: #ffffff;
    padding: 0px;
    margin: 0px;
}
.login_social_media p a {
    color: #a2e609;
}

.cursor-pointer {
    cursor: pointer;
}
.max-1300 {
    max-width: 1300px;
    width: 94%;
}

@media (max-width: 767px) {
    .bg_side_blue .signup {
        padding-top: 0px;
    }
    .bg_side_blue .signup .custom_logo {
        background: #fff;
        padding: 20px;
        margin-right: calc(var(--bs-gutter-x) / -1.03);
        margin-left: calc(var(--bs-gutter-x) / -1.02);
        margin-bottom: 40px;
    }
    .max-1300 {
        width: 100%;
    }
    .bg_side_blue .signup .leftPaneFooter {
        margin-top: auto;
    }
}

.landing_login_page .form-control {
    min-height: 40px;
    border: none;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    font-weight: 500;
    color: #000;
    font-size: 16px;
    z-index: 1;
    position: relative;
}
.input-otp {
    max-width: 60px;
    width: 100%;
    outline: none;
    box-shadow: none;
    height: 55px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.password-toggle {
    right: 20px;
    width: 20px;
    cursor: pointer;
    float: right;
    margin: -34px 0 0;
    position: relative;
    z-index: 1;
}
.password-toggle img {
    width: 100%;
}
.landing_login_page #myloginTabContent > .bg {
    position: relative;
    margin-top: 30px;
}
.landing_login_page #myloginTabContent > .bg p {
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    padding: 10px;
    border-radius: 3px;
    text-align: center;
    font-size: 18px;
    color: #fff;
    font-weight: 500;
    max-width: 380px;
    margin: auto;
}
.landing_login_page #myloginTabContent > .bg.fail-message p {
    background: #e30000;
}
.landing_login_page #myloginTabContent > .bg.success-message p {
    background: #5cae48;
}
.btn-forgotpw {
    color: #fff;
}
.btn-forgotpw a {
    color: #fff;
}
.btn-relogin {
    color: #fff;
}
.form-pop-up {
    max-width: 280px;
}
.form-pop-up .icon {
    width: 70px;
    height: 70px;
    margin: auto auto 15px;
    display: inline-block;
    border-radius: 50%;
}
.form-pop-up h2 {
    font-size: 24px;
    font-weight: 500;
    margin: 0 0 7px;
}
.form-pop-up .icon.success {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/popup-checked.png) no-repeat 0 0 /
        100%;
}
.form-pop-up .icon.sorry {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/popup-cancel.png) no-repeat 0 0 /
        100%;
}
.success {
    color: #32ba7c;
}
.sorry {
    color: #e31e1e;
}
.form-pop-up p {
    margin-bottom: 30px;
}
.form-pop-up button {
    border: 0;
    border-radius: 25px;
    max-width: 120px;
    margin: auto;
    width: 100%;
    padding: 5px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    height: 35px;
    color: #fff;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.form-pop-up button.btn-success {
    background: #32ba7c;
    color: #fff;
}
.form-pop-up button.btn-success:hover {
    background: #3a8c26;
}
.form-pop-up button.btn-sorry {
    background: #e31e1e;
    color: #fff;
}
.form-pop-up button.btn-sorry:hover {
    background: #ad0e0e;
}
/* login and signup css End */

/* Profile Registration Start */

.bg-skyblue-gradient,
.basic-information {
    background: #f3f9ff;
    background: -moz-linear-gradient(top, #f3f9ff 0%, #f7fbff 44%, #f0f7ff 100%);
    background: -webkit-linear-gradient(top, #f3f9ff 0%, #f7fbff 44%, #f0f7ff 100%);
    background: linear-gradient(to bottom, #f3f9ff 0%, #f7fbff 44%, #f0f7ff 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f3f9ff', endColorstr='#f0f7ff',GradientType=0 );
}
.bg-beige-gradient,
.undergraduate-college {
    background: #fffbf2;
    background: -moz-linear-gradient(top, #fffbf2 0%, #fef8ea 44%, #fef7e7 100%);
    background: -webkit-linear-gradient(top, #fffbf2 0%, #fef8ea 44%, #fef7e7 100%);
    background: linear-gradient(to bottom, #fffbf2 0%, #fef8ea 44%, #fef7e7 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FFFBF2', endColorstr='#FEF7E7',GradientType=0 );
}
.bg-purple-gradient {
    background: transparent linear-gradient(144deg, #edebff 0%, #f6f5ff 100%) !important;
}
.bg-green-gradient {
    background: transparent linear-gradient(211deg, #fefffd 0%, #ebffe6 76%, #c6ebbe 100%) !important;
}
.bg-pink-gradient {
    background: transparent linear-gradient(145deg, #ffe8eb 0%, #fff8f9 100%) !important;
}
.rightPane.basic-information .rightPaneHeaderWrap {
    background: #f3f9ff;
}
.rightPane.undergraduate-college .rightPaneHeaderWrap {
    background: #fffbf2;
}
.rightPane.bg-purple-gradient .rightPaneHeaderWrap {
    background: #efedff;
}
.bg-green-gradient .rightPaneHeaderWrap {
    background: transparent !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}
.bg-green-gradient .rightPaneHeaderWrap {
    background: transparent !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.bg-pink-gradient .rightPaneHeaderWrap {
    background: transparent !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.green_recom_bg {
    background: transparent linear-gradient(95deg, #5cae48 0%, #27a7df 100%) !important;
    right: 50px !important;
}
.bg_transparant {
    background: transparent !important;
}
.prize_bg {
    background: transparent linear-gradient(94deg, #51499c 0%, #d42941 100%) !important;
}
.laststep_bg {
    background: transparent linear-gradient(101deg, #51499c 0%, #27a7df 100%) !important;
}
.user_name span {
    font-weight: 600;
}
.main-wrap {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    background-color: #fff;
}
.main-wrap > .row {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.leftPane {
    width: 57.82%;
}
.leftPaneHeaderWrap {
    padding: 25px 0px;
    border-bottom: 1px solid #e2e8ef;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: end;
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 3;
    height: 93px;
}
.leftPaneHeader {
    max-width: 900px;
    width: 96%;
}
.leftPaneHeader .user_name {
    display: none;
}
.leftPaneHeader img {
    height: 100%;
    width: auto;
}
.leftPaneBodyWrap {
    padding: 50px 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: end;
}
.leftPaneBody {
    padding: 0 100px;
    max-width: 890px;
    width: 100%;
}
.leftPaneWrap {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.leftPaneWrap .leftPaneBodyWrap {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.leftPaneBody .qs_body {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.leftPaneBody .qs_body .step {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.leftPaneBody .qs_body .steps {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.leftPaneBody .qs_body .form-check-inline {
    min-height: 45px;
    display: inline-flex;
    align-items: center;
    margin-top: 2px;
}
.leftPaneBody .qs_body .form-check-inline input {
    margin-right: 5px;
    width: 20px;
    height: 20px;
    transition: all 0.3s ease-in-out;
}
.leftPaneBody .qs_body .form-check-inline .form-check-label {
    margin-top: 2px;
}
.progress_svg {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
    background: white;
    border-radius: 50%;
}
.global {
    position: absolute;
    background: white;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    bottom: -50px;
    right: -50px;
}
.unlock {
    white-space: nowrap;
    display: flex;
    align-items: center;
    background: transparent linear-gradient(96deg, #f05a2a 0%, #f8b018 100%);
    border-radius: 30px 0px 0px 30px;
    height: 40px;
    right: 20px;
    position: absolute;
    bottom: 0;
    top: 0;
    margin: auto;
    z-index: 1;
    -webkit-border-radius: 30px 0px 0px 30px;
    -moz-border-radius: 30px 0px 0px 30px;
    -ms-border-radius: 30px 0px 0px 30px;
    -o-border-radius: 30px 0px 0px 30px;
    width: auto;
    transition: all 0.5s ease-in-out;
    opacity: 0;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
}
.unlock_blue {
    background: transparent linear-gradient(95deg, #27a7df 0%, #51499c 100%) !important;
    border-radius: 30px 0px 0px 30px !important;
}
.unlock .img {
    margin: 0 10px 0 5px;
}
.unlock .img img {
    width: 30px;
    height: 30px;
}
.unlock.prize_bg .img,
.unlock.laststep_bg .img {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: end;
    height: 100%;
}
.unlock.unlock_blue .img {
    margin: 0 5px 10px -3px;
}
.unlock.green_recom_bg .img {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: end;
    height: 100%;
    padding: 0 0 5px 8px;
}
.unlock.prize_bg .img img {
    width: 62px;
    height: auto;
}
.unlock.laststep_bg .img img {
    width: 48px;
    height: auto;
}
.unlock.green_recom_bg .img img {
    width: 43px;
    height: auto;
}
.unlock.unlock_blue .img img {
    width: 57px;
    height: auto;
}
.unlock.prize_bg .img {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: end;
    height: 100%;
}
.unlock.prize_bg .img img {
    width: 62px;
    height: auto;
}
.unlock .text {
    line-height: 18px;
    color: #fff;
    padding-right: 30px;
    font-size: 18px;
    font-weight: 400;
    width: 0;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.progress_svg span {
    position: absolute;
    left: auto;
    right: auto;
    top: 0;
    bottom: 0;
    margin: auto;
    font-size: 25px !important;
    font-weight: 600;
    color: #000 !important;
    line-height: 90px !important;
}
.progress_svg span::after {
    content: '%';
    font-size: 18px !important;
}
.progress_svg .gaugeMeter {
    width: 90px;
    height: 90px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
}
.global.show .unlock .text {
    width: auto;
}
.global.show .unlock {
    right: 80px;
    opacity: 1;
}
.rightPane {
    width: 42.18%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
}
.rightPaneWrap {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.rightPaneHeaderWrap {
    min-height: 93px;
    border-bottom: 1px solid #e4e8ef;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 2;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
}
.rightPaneHeader {
    max-width: 600px;
    width: 96%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: end;
    align-items: center;
}
.rightPaneBodyWrap {
    padding: 50px 60px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    position: sticky;
    top: 93px;
}
.rightPaneBody {
    padding: 0;
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
}
.step {
    display: none;
    flex-direction: column;
}
.step .stepHeader {
    width: 100%;
    margin: 0;
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;
    position: relative;
    padding: 0;
}
/* .step .stepHeader::before {content: '';position: absolute;left: 0;bottom: 0;width: 44px;height: 4px;background: #5cae48;border-radius: 5px;}*/
.step_img {
    margin: 0;
}
.step_img img {
    width: inherit;
}
.step p {
    width: 100%;
    margin: 0;
    font-size: 18px;
}
.listed_points {
    margin-top: 20px;
}
.step_header_wrap_link {
    display: none;
}
.listed_points ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.listed_points ul li {
    position: relative;
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: 400;
    line-height: 22px;
    padding: 0 0 0 10px;
}
.listed_points ul li:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0px;
    width: 3px;
    height: 20px;
    background: #27a7df;
    border-radius: 15px;
}
.listed_points ul.beige-line li:before {
    background: #f8b018 !important;
}
.listed_points ul.purple-line li:before {
    background: #51499c !important;
}
.listed_points ul.orange-line li:before {
    background: #f05a2a !important;
}
.listed_points ul.green-line li:before {
    background: #5cae48 !important;
}
.listed_points ul.pink-line li:before {
    background: #ee4c61 !important;
}
.undergraduate-college .listed_points ul li::before {
    background: #f8b018;
}
.rightPane.basic-information .step_1 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}
.rightPane.undergraduate-college .step_2 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}
.steps {
    /*height: 0px;opacity: 0;overflow: hidden;*/
    transition: all 1s ease-in-out;
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -ms-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
}
.steps.showTab {
    height: auto;
    opacity: 1;
}
.action_btns #viewcareersbtn {
    display: none;
}
.steps_questions {
    padding: 0 0 0 0px;
    margin-top: 0px; /* left: -50px; */
    position: relative; /* opacity: 0; */
    transition: all 1s ease-in-out;
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -ms-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    max-width: 640px;
}
.steps.showTab .steps_questions {
    /* left: 0px;opacity: 1; */
}
.steps_questions h2 {
    font-size: 22px;
    font-weight: 800; /* margin-left: 50px; */ /* opacity: 0; */
    transition: all 0.3s ease-in-out;
}
.steps.showTab h2 {
    margin-left: 0px;
    opacity: 1;
}
.steps_questions h2 span {
    position: relative;
}
.steps_questions h2 span:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 2px;
    background: #5dae48;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
}
.steps_questions h2 span:before {
    width: 100%;
}
.steps_questions .form-control {
    margin-bottom: 0;
}
.steps.showTab h2 {
    opacity: 1;
    margin-left: 0;
}
.question_wrap {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
}
.question_number {
    margin-right: 10px;
    display: none;
}
.question_number span {
    width: 33px;
    height: 33px;
    border: 2px solid #dddddd;
    border-radius: 50%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    color: #000;
}
.question .form-label {
    font-size: 18px;
    font-weight: 500;
    margin-top: 3px;
    margin-bottom: 3px;
}
.question .form-label .info {
    width: 20px;
    height: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #656565;
    border: 1px solid #656565;
    border-radius: 50%;
    font-style: italic;
    font-family: initial;
    font-weight: 600;
    font-size: 12px;
    color: #fff;
}
.question {
    width: 100%;
    position: relative;
}
.main-wrap .form-control {
    max-width: 100%;
    height: 45px;
    border: 1px solid #b5c2cb;
    border-radius: 6px;
    line-height: 30px;
}
.profile_wrap textarea.form-control {
    margin-bottom: 20px;
}
.flex-fill.form-control {
    z-index: inherit;
}
.input-group-text {
    border-color: #b5c2cb;
}
.main-wrap .small-text {
    color: #0b3454;
    font-size: 15px;
}
.main-wrap .form-select {
    max-width: 100%;
    height: 45px;
    border: 1px solid #b5c2cb;
    border-radius: 6px;
}
.form-check-input + label.gap-switch {
    background: #fff;
    padding: 5px;
    border-radius: 25px;
    position: relative;
    width: 100px;
    height: 40px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    border: 2px solid #f34e65;
    color: #f34e65;
}
.form-check-input:checked + label.gap-switch {
    background: #5dae48;
    border-color: #5dae48;
}
.form-check-input + label.gap-switch:before {
    width: 24px;
    height: 24px;
    display: inline-block;
    background: #f34e65;
    border-radius: 50%;
    content: '';
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.form-check-input:checked + label.gap-switch::before {
    transform: translateX(62px);
    -webkit-transform: translateX(62px);
    -moz-transform: translateX(62px);
    -ms-transform: translateX(62px);
    -o-transform: translateX(62px);
    background: #fff;
}
.form-check-input + label.gap-switch:after {
    content: 'NO';
    color: #f34e65;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    text-align: center;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.form-check-input:checked + label.gap-switch::after {
    transform: translateX(-30px);
    content: 'YES';
    -webkit-transform: translateX(-30px);
    -moz-transform: translateX(-30px);
    -ms-transform: translateX(-30px);
    -o-transform: translateX(-30px);
    color: #fff;
}
.action_btns {
    max-width: 650px;
    background: #f5faff;
    padding: 20px;
    border-radius: 5px;
    margin: 35px 0 0 0px;
    text-align: center;
}
.form-switch .form-check-input:focus,
.form-switch .form-check-input {
    --bs-form-switch-bg: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23F34E65%27/%3e%3c/svg%3e');
    border: 2px solid #f34e65;
    cursor: pointer;
}
.form-check-input:checked {
    background-color: #29a745;
    border-color: #29a745;
}
.form-switch .form-check-input:checked:focus {
    --bs-form-switch-bg: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23FFFFFF%27/%3e%3c/svg%3e');
    border: 2px solid #29a745;
}
.action_btns button.nextbtn {
    padding: 0px 30px;
    font-size: 1.5em;
    width: 180px;
    cursor: pointer;
    border: 0px;
    position: relative;
    margin: auto;
    transition: all 0.25s ease;
    background: #0b3454;
    color: #fff;
    overflow: hidden;
    border-radius: 25px;
    height: 45px;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
}
.load {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: inherit;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: inherit;
    -webkit-border-radius: inherit;
    -moz-border-radius: inherit;
    -ms-border-radius: inherit;
    -o-border-radius: inherit;
}
.load::after {
    content: '';
    position: absolute;
    border-radius: 50%;
    border: 3px solid #fff;
    width: 30px;
    height: 30px;
    border-left: 3px solid transparent;
    border-bottom: 3px solid transparent;
    animation: loading1 1s ease infinite;
    -webkit-animation: loading1 1s ease infinite;
    z-index: 10;
}
.load::before {
    content: '';
    position: absolute;
    border-radius: 50%;
    border: 3px dashed #fff;
    width: 30px;
    height: 30px;
    border-left: 3px solid transparent;
    border-bottom: 3px solid transparent;
    animation: loading1 2s linear infinite;
    -webkit-animation: loading1 2s linear infinite;
    z-index: 5;
}
@keyframes loading1 {
    0% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
    }
}
.float_horizontal_animate {
    transform: translatex(0px);
    animation: float_horizontal 4s ease-in-out infinite;
    -webkit-animation: float_horizontal 4s ease-in-out infinite;
    -webkit-transform: translatex(0px);
    -moz-transform: translatex(0px);
    -ms-transform: translatex(0px);
    -o-transform: translatex(0px);
}
@keyframes float_horizontal {
    0% {
        transform: translatex(0px);
        -webkit-transform: translatex(0px);
        -moz-transform: translatex(0px);
        -ms-transform: translatex(0px);
        -o-transform: translatex(0px);
    }
    50% {
        transform: translatex(-10px);
        -webkit-transform: translatex(-10px);
        -moz-transform: translatex(-10px);
        -ms-transform: translatex(-10px);
        -o-transform: translatex(-10px);
    }
    100% {
        transform: translatex(0px);
        -webkit-transform: translatex(0px);
        -moz-transform: translatex(0px);
        -ms-transform: translatex(0px);
        -o-transform: translatex(0px);
    }
}
button .loading {
    opacity: 0;
    visibility: hidden;
}
button.activeLoading .loading {
    visibility: visible;
    opacity: 1;
}
.question .form-select.max-100 {
    max-width: 100%;
}
.check_box {
    background: #f5f7f8;
    border-radius: 6px;
    min-height: 48px;
    padding: 12px 20px 10px;
    margin: 20px 0 -35px 0;
    max-width: 640px;
}
.check_box .form-check-input {
    width: 20px;
    height: 20px;
    vertical-align: top;
    display: inline-block;
    margin-top: 0.5px;
    border: 1px solid #0b3454cc;
}
.check_box .form-check-input:checked {
    background-color: #5dae48;
    border-color: #5dae48;
}
.check_box .form-check-input:focus {
    box-shadow: none;
}
.datewrap {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    background: #ffffff;
    border: 1px solid #b5c2cb;
    border-radius: 6px;
    height: 45px;
    position: relative;
    overflow: hidden;
}
.daterangePickerList,
.singledaterangePickerList {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    margin: auto;
    cursor: pointer;
    opacity: 0;
}
.datewrap .arrow {
    background: #f1f1f1;
    width: 45px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
}
.datewrap .from,
.datewrap .to {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.form-control[type='date'] {
    background: #fff url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/calender.svg) no-repeat right
        10px center;
    text-align: left;
    padding-right: 5px;
    position: relative;
}
.form-control[type='date']::-webkit-calendar-picker-indicator,
.form-control[type='date']::-moz-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
}
.form-control.form-date {
    background-image: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/calender.svg);
    background-position: center right 15px;
    background-repeat: no-repeat;
}
.form-control.form-search {
    background-image: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/search.svg);
    background-size: 14px;
    background-repeat: no-repeat;
    background-position: 13px center;
    padding-left: 34px;
}
.form-control.form-linkedin {
    background-image: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/linkedin.svg);
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: 13px center;
    padding-left: 48px;
}
.form-control.form-facebook {
    background-image: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/facebook.svg);
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: 13px center;
    padding-left: 48px;
}
.form-control.form-instagram {
    background-image: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/instagram.svg);
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: 13px center;
    padding-left: 48px;
}
.form-control.form-twitter {
    background-image: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/twitter.svg);
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: 13px center;
    padding-left: 48px;
}
.datepicker {
    max-width: 280px;
    width: 100%;
    padding: 0;
}
.datepicker-years {
    overflow: hidden;
    border-radius: 5px;
}

.react-datepicker-cnt > div,
.react-datepicker-cnt > div > div {
    width: 100%;
}
.react-datepicker-cnt {
    position: relative;
}
.react-datepicker-cnt .form-control.form-date {
    background-image: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/calender.svg) !important;
    background-position: center right 15px !important;
    background-repeat: no-repeat;
}
.datepicker .table-condensed {
    width: 100%;
}
.datepicker table tr td span {
    height: auto;
    line-height: 35px;
    border-radius: 25px;
}
.datepicker thead {
    background: #f4faff;
    line-height: 40px;
}
.datepicker table tr td span.active.active {
    background: #5dae48;
}
.prev {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: 16px 12px !important;
    font-size: 0;
    width: 50px !important;
}
.opacity-0 {
    opacity: 0;
}
.next {
    background-image: url("data:; image/svg+xml,%3csvgxmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: 16px 12px !important;
    font-size: 0;
    width: 50px !important;
    transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    -o-transform: scaleX(-1);
}
.datepicker .datepicker-switch {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 5px;
}
.form-check.custom-rectbox-radio .form-check-input + label {
    width: 100%;
    height: 45px;
    font-weight: 500;
    border: 1px solid #b9c2cb;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    padding: 0 14px;
    position: relative;
    cursor: pointer;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
}
.form-check.custom-rectbox-radio .form-check-input:checked + label {
    background: #5dae48;
    border: 1px solid #5dae48;
    color: #fff;
}
.custom-rectbox-radio {
    padding: 0;
    margin: 0;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.custom-rectbox-radio:not(:last-child) {
    margin-right: 8px;
}
.radio_parent {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
}
.form-check.custom-rectbox-radio .form-check-input + label:before {
    content: '';
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 24px;
    height: 24px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/male_icon.svg) no-repeat center;
}
.form-check.custom-rectbox-radio .form-check-input + label[for='female']:before {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/female_icon.svg) no-repeat center;
}
.form-check.custom-rectbox-radio .form-check-input:checked + label::before {
    filter: invert(1) brightness(100);
    -webkit-filter: invert(1) brightness(100);
}
.step_header_wrap {
    display: none;
}
.header_container {
    max-width: 1500px;
    margin: auto;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 0px;
    width: 96%;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
}
.ful-header {
    border-bottom: 1px solid #e2e8ef;
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 1;
}
.header_container .user_name {
    font-size: 20px;
}
.rightSection_header {
    position: relative;
}
.global_wrap {
    position: relative;
    margin-left: auto;
    height: 100%;
    margin-right: 0px;
}
.global_wrap .global {
    right: 0;
    top: 0px;
    bottom: auto;
}
.scholarship_banner {
    background: transparent linear-gradient(262deg, #fffdf8 0%, #fef6e4 76%, #feeccb 100%);
    width: 100%;
    height: 100%;
}
.scholarship_container {
    max-width: 1066px;
    width: 90%;
    margin: auto;
    padding-top: 0px;
}
.scholarship_container h2 {
    font-size: 22px;
    font-weight: 600;
    margin-top: 55px;
}
.scholarship_container h2 span {
    position: relative;
}
.scholarship_container h2 span:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: #5cae48;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
}
.scholarship_detail_list {
    margin: 24px 0 20px 0;
    padding: 0;
    list-style: none;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
}
.scholarship_detail_list li:not(:last-child) {
    margin-right: 30px;
}
.scholarship_detail_list li {
    position: relative;
    padding-left: 27px;
    margin-bottom: 16px;
}
.scholarship_detail_list li:before {
    content: '';
    position: absolute;
    left: 0;
    top: 2px;
    width: 20px;
    height: 18px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/tick.svg) no-repeat 0 0;
}
.scholarship_detail_carousel_container {
    max-width: 1066px;
    margin: auto;
    width: 90%;
}
.scholarship_detail_carousel {
    width: 100%;
    padding: 40px 0;
}
.joined_offer_wrap {
    position: relative;
    margin-left: 0;
    margin-bottom: 15px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}
.joined_offer_wrap:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
    background: #5cae48;
    height: 1px;
}
.joined_label {
    position: relative;
    background: #5cae48;
    border-radius: 16px;
    height: 32px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: 400;
    padding: 7px 20px 7px 7px;
    font-size: 14px;
}
.joined_label i {
    width: 20px;
    height: 20px;
    display: inline-block;
    background: #fff;
    border-radius: 50%;
    margin-right: 7px;
    position: relative;
}
.joined_label i:before {
    content: '';
    position: absolute;
    width: 10px;
    height: 5px;
    border-left: 2px solid #5cae48;
    border-bottom: 2px solid #5cae48;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    left: 5px;
    top: 7px;
}
.joined_label_noline:before {
    display: none !important;
}
.w-65 {
    width: 65%;
}
.offered-univarsity-name {
    font-size: 28px;
    line-height: 32px;
    font-weight: 700;
}
.joined_label.didnt-join {
    background: #0b3454;
}
.joined_label.didnt-join i:before {
    border-left: 2px solid #0b3454;
    border-bottom: 2px solid #0b3454;
}
.joined_offer_wrap.didnt-join:before {
    background: #0b3454;
}
.scholarship_block {
    width: 350px;
}
.card_profiles {
    background: transparent linear-gradient(144deg, #edebff 0%, #f6f5ff 100%);
    border-radius: 6px;
    padding: 1px;
    height: auto;
    max-height: initial;
    min-height: initial;
}
.card_profiles_pink {
    background: transparent linear-gradient(145deg, #ffe8eb 0%, #fff8f9 100%) !important;
}
.card_profiles:first-child {
    margin: 0 0 10px;
}
.card_profiles .card_profiles_univarsity {
    padding: 19px 19px 13px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.card_profiles .card_profiles_univarsity .card_profiles_name {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
}
.card_profiles .card_profiles_univarsity .card_profiles_name .student_detail {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.card_profiles .card_profiles_univarsity .card_profiles_name > a {
    border-radius: 50%;
    overflow: hidden;
    margin-right: 12px;
    width: 70px;
    height: 70px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
}
.card_profiles .card_profiles_univarsity .card_profiles_name a img {
    width: auto;
    height: 100%;
}
.card_profiles .card_profiles_univarsity .card_profiles_name .student_detail h2 {
    font-size: 22px;
    font-weight: 600;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
}
.card_profiles .card_profiles_univarsity .card_profiles_name .student_detail h2 a {
    border-radius: 0;
    text-decoration: none;
    color: #000;
}
.card_profiles .card_profiles_univarsity .card_profiles_name .student_detail h2 span {
    font-size: 15px;
    font-weight: 500;
    margin-top: 5px;
    color: #0b3454;
}
.carousel-indicators .dot {
    width: 10px;
    height: 10px;
    background: #0b34541a;
    border-radius: 50%;
    margin: 0 4px;
    padding: 0 !important;
    border: 0;
}
.carousel-indicators .dot.active {
    background: #0b3454b3;
}
.scholarship_block > h3 {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 10px;
}
.scholarship_block > h3 span {
    font-size: 18px;
    font-weight: 600;
    display: block;
}
.scholarship_amt_detail {
    background: #ffffff;
    border-radius: 0px 0px 6px 6px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    -webkit-border-radius: 0px 0px 6px 6px;
    -moz-border-radius: 0px 0px 6px 6px;
    -ms-border-radius: 0px 0px 6px 6px;
    -o-border-radius: 0px 0px 6px 6px;
}
.scholarship_amt {
    font-size: 24px;
    font-weight: 700;
}
.scholarship_label {
    font-size: 16px;
    font-weight: 500;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
}
.scholarship_label i {
    width: 37px;
    height: 25px;
    display: inline-block;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/scholarships-hat.jpg) no-repeat 0 0;
    margin-right: 10px;
}
.univarsity-block {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    margin-top: 3px;
}
.univarsity-block .univarsity-block-cover {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    max-width: 255px;
    width: 100%;
}
.card_profiles .card_profiles_univarsity .card_profiles_name a img {
    width: auto;
    height: 100%;
}
.univarsity-block .univarsity-block-cover > a {
    width: 67px;
    height: 67px;
    background: #fff;
    border-radius: 50%;
    overflow: hidden;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border: 1px solid #ddd;
    margin-right: 10px;
}
.univarsity-block .univarsity-block-cover > a img {
    width: auto;
    height: 100%;
}
.univarsity-block .univarsity-block-cover h3 {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin: 0;
}
.univarsity-block .univarsity-block-cover h3 p {
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 3px;
}
.univarsity-block .univarsity-block-cover h3 p a {
    text-decoration: none;
    color: #000;
}
.univarsity-block .univarsity-block-cover h3 .course {
    font-size: 15px;
    font-weight: 500;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    align-items: start;
}
.univarsity-block .univarsity-block-cover h3 .course .img-fluid {
    width: 16px;
    margin-right: 7px;
    margin-top: 1px;
}
.univarsity-block .univarsity-block-cover h3 .course span {
    width: calc(100% - 23px);
}
.scholarship_carousel .owl-dots {
    display: flex;
    justify-content: center;
    margin: 15px;
}
.scholarship_carousel .owl-dots .owl-dot {
    width: 8px;
    height: 8px;
    background: #0b34541a;
    border-radius: 50%;
    margin: 0 4px;
}
.scholarship_carousel .owl-dots .owl-dot.active {
    background: #0b3454b3;
}
#skip_pop .modal-dialog {
    max-width: 600px;
    border-radius: 10px;
}
#skip_pop .modal-content {
    border-radius: 10px;
}
#skip_pop .modal-dialog .modal-body {
    padding: 40px 0 0 50px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    border-radius: 10px;
    min-height: 300px;
}
.img_pop {
    position: relative;
    z-index: 1;
    float: right;
}
#skip_pop .modal-dialog .modal-body h4 {
    font-size: 22px;
    font-weight: 600;
    position: relative;
    text-transform: uppercase;
}
#skip_pop .modal-dialog .modal-body h4::before {
    content: '';
    position: absolute;
    bottom: -17px;
    width: 50px;
    height: 3px;
    background: #5cae48;
    border-radius: 5px;
}
#skip_pop .modal-dialog .modal-body p {
    font-size: 20px;
    line-height: 28px;
    max-width: 318px;
    margin: 40px 0 0;
    font-weight: 500;
}
#skip_pop .img_pop {
    position: relative;
    z-index: 1;
    float: right;
    margin-top: -30px;
    width: 220px;
    text-align: right;
    display: flex;
    align-items: end;
}
#skip_pop .modal-footer {
    background: transparent linear-gradient(261deg, #fdfeff 0%, #eff7ff 16%, #d4ffc9 100%);
    border-radius: 90px 10px 10px 10px;
    -webkit-border-radius: 90px 10px 10px 10px;
    -moz-border-radius: 90px 10px 10px 10px;
    -ms-border-radius: 90px 10px 10px 10px;
    -o-border-radius: 90px 10px 10px 10px;
    opacity: 1;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: start;
    padding-left: 70px;
    border: 0;
    min-height: 90px;
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    margin-top: -90px;
}
.btn-goback {
    background: #5cae48;
    border-radius: 31px;
    width: 158px;
    height: 50px;
    color: #fff;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    text-decoration: none;
    -webkit-border-radius: 31px;
    -moz-border-radius: 31px;
    -ms-border-radius: 31px;
    -o-border-radius: 31px;
}
.btn-goback:hover {
    background: #27970b;
    color: #fff;
}
.btn-later {
    font-size: 14px;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.btn-later:hover {
    color: #f34e65;
}
.btn-skip {
    margin: 15px;
    font-size: 12px;
    text-decoration: navajowhite;
    font-weight: 500;
    color: #000;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    background: no-repeat;
    border: 0;
}
.btn-skip:hover {
    color: #d42941;
}
.modal-backdrop.show {
    opacity: 0.8;
}
.multiple_benefits_banner {
    background: transparent linear-gradient(262deg, #fdfeff 0%, #eff7ff 76%, #dbf1ff 100%);
    width: 100%;
    padding-top: 24px;
}
.multiple_benefits_container {
    max-width: 990px;
    margin: auto;
    width: 90%;
}
.multiple_benefits_container h2 {
    font-size: 22px;
    font-weight: 600;
    position: relative;
    margin: 37px 0 25px;
    line-height: 24px;
}
.multiple_benefits_container h2:before {
    content: '';
    position: absolute;
    width: 40px;
    height: 3px;
    background: #5cae48;
    left: 0;
    bottom: -5px;
}
.multiple_benefits_container p {
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    max-width: 490px;
}
.multiple_benefits_cards_container {
    max-width: 990px;
    width: 90%;
    margin: auto;
}
.multiple_benefits_cards {
    width: 100%;
    padding: 30px 0;
}
.multiple_benefits_cards_container .card {
    border: 0;
    border-radius: 6px; /* overflow: hidden; */
    height: 100%;
}
.multiple_benefits_cards_container .card.network-card {
    background: #fff7f8;
}
.multiple_benefits_cards_container .card.event-card {
    background: #f9f8ff;
}
.multiple_benefits_cards_container .card .card-header {
    padding: 18px 40px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 144px;
}
.multiple_benefits_cards_container .card .card-header .title {
    font-size: 20px;
    line-height: 22px;
    flex: 1;
    color: #fff;
}
.multiple_benefits_cards_container .card .card-header .card-img {
    width: auto;
}
.multiple_benefits_cards_container .card.network-card .card-header {
    background: #d42941;
}
.multiple_benefits_cards_container .card.event-card .card-header {
    background: #51499c;
}
.multiple_benefits_cards_container .card .card-body {
    padding: 0;
}
.card .card-body .benefits_toggle {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.card.network-card .card-body .benefits_toggle {
    background: #fce4e7;
}
.card.event-card .card-body .benefits_toggle {
    background: #e8e7f0;
}
.multiple_benefits_cards_container .card .card-body .benefits_toggle .btn {
    width: 100%;
    border-radius: 0;
    min-height: 56px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    padding: 15px 20px 15px 30px;
    font-weight: 500;
    font-size: 18px;
    color: #000;
    border: 0;
    justify-content: space-between;
}
.benefits_toggle .btn:focus {
    box-shadow: none;
}
.benefits_toggle .toggle {
    width: 15px;
    height: 15px;
    position: relative;
}
.benefits_toggle .toggle::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 15px;
    height: 3px;
    background: #000;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.benefits_toggle .toggle::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 3px;
    height: 15px;
    background: #000;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.benefits_toggle .btn[aria-expanded='true'] .toggle::after {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
}
.multiple_benefits_cards_container .card.network-card .card-body .benefits_toggle .btn {
    background: #fce4e7;
}
.benefit-list {
    padding: 0px 30px 26px;
}
.multiple_benefits_cards_container .card .card-body .benefit_form {
    padding: 20px 30px 40px;
}
.benefit-list ul {
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: 18px;
    line-height: 22px;
}
.benefit-list ul li {
    padding-left: 25px;
    position: relative;
    margin-bottom: 10px;
}
.benefit-list ul li:before {
    content: '';
    position: absolute;
    width: 12px;
    height: 6px;
    border-left: 2px solid #000;
    border-bottom: 2px solid #000;
    transform: rotate(-45deg);
    left: 0;
    top: 7px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
}
.video-btn {
    background: #0b3454;
    border-radius: 25px;
    padding: 9px 20px;
    display: inline-flex;
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    align-items: center;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    border: 0;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
}
.video-btn b {
    width: 16px;
    height: 16px;
    background: #fff;
    border-radius: 50%;
    margin: 0 0 0 7px;
    display: inline-block;
    font-size: 0;
    position: relative;
}
.video-btn b::before {
    content: '';
    position: absolute;
    top: 4px;
    left: 5.5px;
    margin: auto;
    width: 0;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 5px solid #0b3454;
    height: 0;
}
.video-btn:hover {
    color: #fff;
    background: #5bae47;
}
.video-btn:hover b::before {
    border-left: 5px solid #5bae47;
}
.form-label {
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    margin: 0 0 4px;
}
.select-option.first-option,
.select-option.second-option {
    position: relative;
}
.select-option.first-option .form-select,
.select-option.second-option .form-select {
    margin-bottom: 20px;
}
.form-check-input:checked ~ .select-option.first-option:after {
    content: '';
    position: absolute;
    bottom: 0;
    border-bottom: 1px solid #00000016;
    width: 208%;
    left: 0;
}
.form-check-input:checked ~ .select-option.second-option:after {
    content: '';
    position: absolute;
    bottom: 0;
    border-bottom: 1px solid #00000016;
    width: 208%;
    right: 0;
}
.benefit_form .form-check {
    margin: 0;
    padding: 0;
}
.benefit_form .form-check .form-check-input[role='switch'] {
    margin-left: 0;
}
.custom_radius_check {
    background: #ffffff;
    border: 1px solid #0b3454;
    border-radius: 25px;
    width: 100%;
    padding: 10px 20px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    justify-content: space-between;
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
}
.custom_radius_check i {
    width: 17px;
    height: 12px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/check-tick.svg) no-repeat 0 0;
    filter: invert(76%) sepia(79%) saturate(0%) hue-rotate(131deg) brightness(94%) contrast(96%);
    -webkit-filter: invert(76%) sepia(79%) saturate(0%) hue-rotate(131deg) brightness(94%) contrast(96%);
}
.select-option {
    display: none;
    width: 100%;
    position: relative;
}
.form-check-input:checked ~ .select-option {
    display: inline-block;
}
.select-option .form-select {
    margin-top: 16px;
    border: 1px solid #0b3454;
    border-radius: 6px;
    height: 42px;
}
.form-check-input:checked + .custom_radius_check {
    background: #5cae48;
    border-color: #5cae48;
    color: #fff;
}
.form-check-input:checked + .custom_radius_check i {
    filter: invert(1) brightness(1);
    -webkit-filter: invert(1) brightness(1);
}
.custom_radius_check::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    border-top: 7px solid transparent;
    width: 0;
    margin: auto;
    top: 85%;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    opacity: 0;
}
.form-check-input:checked + .custom_radius_check::after {
    top: 100%;
    border-top: 7px solid #5cae48;
    opacity: 1;
}
.multiple_benefits_cards_container .action_btns {
    max-width: 100%;
    margin: 0;
}
.bg-drop {
    display: none;
}
footer.footer {
    padding: 15px 0;
    border-top: 1px solid #eee;
    background: #fff;
    position: relative;
    height: auto;
}
.modal-open footer.footer{display: none;}
.footer-wrap {
    width: 90%;
    margin: auto;
    max-width: 1500px;
}
.powerby {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    width: 139px;
}
.pop-dialog {
    max-width: 700px;
    width: 90%;
    margin: auto;
}
.modal.show .modal-dialog .pop-content {
    background: #ffffff;
    border-radius: 10px;
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    position: relative;
}
.pop-footer {
    background: transparent linear-gradient(261deg, #fdfeff 0%, #eff7ff 16%, #d4ffc9 100%);
    border-radius: 90px 0px 10px 10px;
    opacity: 1;
    padding: 20px 66px;
    margin-left: 10px;
    -webkit-border-radius: 90px 0px 10px 10px;
    -moz-border-radius: 90px 0px 10px 10px;
    -ms-border-radius: 90px 0px 10px 10px;
    -o-border-radius: 90px 0px 10px 10px;
}
.pop-body {
    min-height: 255px;
    padding: 80px 50px 0;
}
.pop-body .img_pop {
    position: absolute;
    right: -38px;
    bottom: 0;
}
.pop-dialog h4 {
    font-size: 22px;
    font-weight: 600;
    position: relative;
    margin: 0 0 35px;
}
.pop-dialog h4::before {
    content: '';
    position: absolute;
    bottom: -12px;
    width: 50px;
    height: 3px;
    background: #5cae48;
    border-radius: 5px;
}
.pop-body p {
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
}
.pop_step {
    padding: 0;
    margin: 40px 0 0;
    list-style: none;
}
.pop_step li {
    padding: 0 0 30px 53px;
    line-height: 22px;
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    font-size: 18px;
    font-weight: 500;
}
.pop_step li b {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 7px;
}
.pop_step li .completed_li {
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #5cae48;
    left: 0;
    top: -7px;
    margin: 0 8px;
}
.pop_step li .completed_li:before {
    content: '';
    position: absolute;
    border-left: 3px solid #fff;
    border-bottom: 3px solid #fff;
    width: 12px;
    height: 7px;
    left: 6px;
    top: 7px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
}
.pop_step li .edit_li {
    position: absolute;
    width: 40px;
    height: 40px;
    background: #0b3454 url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/blue_edit.png) no-repeat
        center;
    left: 0;
    top: 0;
    border-radius: 50%;
}
.pop_step li.pop_completed::before {
    content: '';
    position: absolute;
    top: 0;
    left: 19px;
    background: #5cae48;
    width: 2px;
    bottom: 0;
    margin: auto;
}
.mentoring_contact i {
    width: 36px;
    height: 36px;
    background: #27a7df;
    display: flex;
    border-radius: 60px;
    align-items: center;
    justify-content: center;
}
.btn-update {
    border: none;
    padding: 10px 30px;
    background: #5cae48;
    border-radius: 25px;
    color: #ffff;
    text-transform: uppercase;
    font-weight: 500;
    font-weight: 14px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.form-control {
    max-width: 100%;
    height: 45px;
    border: 1px solid #b5c2cb;
    border-radius: 6px;
}
textarea.form-control {
    min-height: 60px;
}
.btn-update:hover {
    background: #43992e;
}
.edit_phone .btn-close {
    font-size: 12px;
}
.btn-cancel {
    background: none;
    border: 0;
    padding: 10px 5px;
    border-radius: 25px;
    margin-left: 10px;
    font-weight: 500;
    color: #828282;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.btn-cancel:hover {
    color: #000;
}

@media (max-width: 1199px) {
    /* .global {bottom: -60px;}
 */
    .global.show .unlock {
        white-space: normal;
        width: 310px;
    }
    .global.show .unlock .text {
        line-height: 17px;
        font-size: 16px;
    }
    .cta_add {
        flex-direction: column;
    }
    .cta_add .btn-add {
        margin-bottom: 10px;
    }
}

@media (max-width: 991px) {
    .leftPaneBody {
        padding: 0 50px;
    }
    .rightPaneBodyWrap {
        padding: 50px 40px;
    }
    .action_btns button.nextbtn {
        width: 150px;
    }
    .scholarship_block {
        width: 100%;
    }
    .scholarship_carousel .item {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        justify-content: space-between;
    }
    .scholarship_carousel .item .card_profiles {
        width: 49%;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
    }
    .item-wrap {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-wrap: wrap;
        -moz-box-wrap: wrap;
        -ms-flex-wrap: wrap;
        -webkit-wrap: wrap;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .item-wrap .card_profiles {
        width: 49%;
        margin: 0;
    }
}

@media (min-width: 768px) and (max-width: 992.98px) {
    .form-check-input:checked ~ .select-option.first-option::after {
        content: '';
        position: absolute;
        bottom: 0;
        border-bottom: 1px solid #00000016;
        width: 208%;
        left: -10%;
    }
    .form-check-input:checked ~ .select-option.second-option::after {
        content: '';
        position: absolute;
        bottom: 0;
        border-bottom: 1px solid #00000016;
        width: 208%;
        right: -10%;
    }
    .form-check-input:checked ~ .select-option.third-option::after {
        content: '';
        position: absolute;
        bottom: 0;
        border-bottom: 1px solid #00000016;
        width: 208%;
        left: -10%;
    }
    .select-option.third-option .form-select {
        margin-bottom: 20px;
    }
}

@media (max-width: 767px) {
    .leftPane {
        width: 100%;
    }
    .leftPaneBody {
        max-width: 100%;
        padding: 30px;
    }
    .leftPaneBodyWrap {
        padding: 0px 0 50px;
    }
    .rightPane {
        width: 100%;
        box-shadow: 0px -7px 15px #00000026;
        border-radius: 15px 15px 0px 0px;
        padding: 45px 0px 0px 25px;
        cursor: pointer;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 7;
        display: none !important;
    }
    .rightPane.basic-information .rightPaneHeaderWrap {
        display: none;
    }
    .rightPaneBodyWrap {
        padding: 0;
        position: static;
    }
    .rightPane.basic-information {
        background: transparent linear-gradient(254deg, #fdfeff 0%, #eff7ff 76%, #dbf1ff 100%);
    }
    .rightPane.basic-information.undergraduate-college {
        background: transparent linear-gradient(213deg, #fffdf8 0%, #fef6e4 76%, #feeccb 100%) !important;
    }
    .rightPane.basic-information .step_1 {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: end;
    }
    .step_img img {
        width: auto;
        height: 128px;
    }
    .mobile-step-block[aria-expanded='true'] ~ .step_img {
        position: absolute;
        right: 0;
        border: 0;
        opacity: 0.2;
    }
    .textDetail {
        -webkit-box-flex: 1;
        -moz-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
    }
    .action_btns {
        max-width: 90%;
        margin: auto;
        width: 100%;
    }
    .action_btns.social {
        margin: auto;
    }
    .custom-rectbox-radio {
        flex: none;
        width: 130px;
    }
    .step_header_wrap {
        display: none !important;
        -webkit-box-wrap: wrap;
        -moz-box-wrap: wrap;
        -ms-flex-wrap: wrap;
        -webkit-wrap: wrap;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 9;
        background: transparent linear-gradient(254deg, #fdfeff 0%, #eff7ff 76%, #dbf1ff 100%);
        box-shadow: 0px -7px 15px #00000026;
        border-radius: 15px 15px 0px 0px;
        padding: 45px 0px 0px 25px;
        cursor: pointer;
        transition: all 0.1s ease-in-out;
        -webkit-transition: all 0.1s ease-in-out;
        -moz-transition: all 0.1s ease-in-out;
        -ms-transition: all 0.1s ease-in-out;
        -o-transition: all 0.1s ease-in-out;
        align-items: end;
    }
    .step_header_wrap_bg-beige {
        background: transparent linear-gradient(213deg, #fffdf8 0%, #fef6e4 76%, #feeccb 100%) !important;
    }
    .step_header_wrap::before,
    .rightPane::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        width: 50px;
        height: 2px;
        background: #727272;
        margin: auto;
        top: 20px;
    }
    .step_header_wrap_img img {
        height: 128px;
    }
    .step_header_wrap_title {
        padding-top: 15px;
        -webkit-box-flex: 1;
        -moz-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
    }
    .steps.showTab > h2 {
        padding: 40px 40px 0;
    }
    .steps_questions {
        /* padding: 0px 40px 40px; */
        margin-top: 30px;
        max-width: 100%;
    }
    .leftPaneHeaderWrap {
        justify-content: center;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        height: auto;
    }
    .leftPaneHeader {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 94%;
        margin: auto;
    }
    .leftPaneHeader .user_name {
        display: inline-block;
        font-size: 18px;
    }
    .check_box {
        margin: 20px auto -35px;
        max-width: 680px;
        width: 90%;
    }
    .step_header_wrap_title > h2 {
        font-size: 18px;
        font-weight: 600;
        margin-right: 15px;
    }
    .step_header_wrap_link {
        background: transparent;
        border-radius: 27px;
        color: #0b3454;
        text-decoration: none;
        padding: 3px 20px;
        transition: all 0.3s ease-in-out;
        display: inline-block;
        margin-bottom: 18px;
        font-weight: 500;
        border: 2px solid #0b3454;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -webkit-border-radius: 27px;
        -moz-border-radius: 27px;
        -ms-border-radius: 27px;
        -o-border-radius: 27px;
        display: inline-block;
        margin-top: 16px;
    }
    .step_header_wrap_link span {
        transition: all 0.3s ease-in-out;
    }
    .step_header_wrap_link .less {
        font-size: 0;
    }
    .step_header_wrap_link:hover {
        background: #0b3454;
        color: #fff;
    }
    .step_header_wrap.open .step_header_wrap_link {
        margin-bottom: 20px;
    }
    .listed_points:not(.show) {
        height: 0;
        overflow: hidden;
    }
    .listed_points ul li {
        font-size: 16px;
        font-size: 16px;
        margin-bottom: 15px;
        line-height: 19px;
    }
    .listed_points ul li:last-child {
        margin-bottom: 0;
    }
    .mobile-step-block {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 9;
    }
    .mobile-step-block[aria-expanded='false'] + .step_header_wrap_title .step_header_wrap_link .more,
    .mobile-step-block[aria-expanded='false'] + .textDetail .step_header_wrap_link .more {
        font-size: 16px;
    }
    .mobile-step-block[aria-expanded='false'] + .step_header_wrap_title .step_header_wrap_link .less,
    .mobile-step-block[aria-expanded='false'] + .textDetail .step_header_wrap_link .less {
        font-size: 0;
    }
    .mobile-step-block[aria-expanded='true'] + .step_header_wrap_title .step_header_wrap_link .more,
    .mobile-step-block[aria-expanded='true'] + .textDetail .step_header_wrap_link .more {
        font-size: 0px;
    }
    .mobile-step-block[aria-expanded='true'] + .step_header_wrap_title .step_header_wrap_link .less,
    .mobile-step-block[aria-expanded='true'] + .textDetail .step_header_wrap_link .less {
        font-size: 16px;
    }
    .step_header_wrap_title .listed_points ul li::before {
        height: 17px;
    }
    .global {
        width: 100%;
        height: 40px;
        position: relative;
        margin-bottom: -26px;
        background: none;
        border-radius: 0;
        margin-top: 25px;
        border-top: 1px solid #e2e8ef;
        bottom: auto;
        right: auto;
    }
    .global .unlock,
    .global.show .unlock {
        width: 100%;
        right: 0;
        bottom: 0;
        border-radius: 0;
        position: static;
        opacity: 1;
    }
    .progress_svg {
        width: 70px;
        height: 70px;
        position: absolute;
        right: 30px;
        top: auto;
        bottom: 0px;
        background: #fff;
        padding: 0;
        border-radius: 50%;
        box-shadow: 0px 0px 10px 0 #d3d3d3;
    }
    .progress_svg .gaugeMeter {
        width: 60px !important;
        height: 60px;
    }
    .progress_svg .gaugeMeter canvas {
        width: 100%;
        height: 100%;
    }
    .progress_svg span {
        line-height: 60px !important;
        font-size: 18px !important;
    }
    .global_wrap {
        width: 100%;
        order: 1;
    }
    .global.show .unlock,
    .global .unlock {
        white-space: normal;
    }
    .global.show .unlock.prize_bg,
    .global .unlock.prize_bg {
        width: auto;
        margin: 0 -2%;
    }
    .global.show .unlock .text,
    .global .unlock .text {
        width: calc(100% - 140px);
    }
    .global .unlock.unlock_blue {
        border-radius: 0 !important;
    }
    .global .unlock .text {
        font-size: 16px;
        font-weight: 500;
    }
    .scholarship_carousel .item {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-wrap: wrap;
        -moz-box-wrap: wrap;
        -ms-flex-wrap: wrap;
        -webkit-wrap: wrap;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .scholarship_block {
        width: 100%;
        margin-top: 15px;
        margin-bottom: 30px;
        position: relative;
        z-index: 0;
    }
    .scholarship_carousel .item .card_profiles {
        width: 48%;
        margin: 0 0 10px;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
    }
    .scholarship_amt {
        font-size: 18px;
        font-weight: 700;
    }
    .scholarship_amt_detail {
        padding: 20px 15px;
    }
    .bg-drop {
        position: fixed;
        left: 0;
        right: 0;
        top: 100%;
        bottom: 0;
        margin: auto;
        background: rgba(0, 0, 0, 0.7);
        z-index: 6;
        transition: all 0.1s ease-in-out;
        display: block;
        -webkit-transition: all 0.1s ease-in-out;
        -moz-transition: all 0.1s ease-in-out;
        -ms-transition: all 0.1s ease-in-out;
        -o-transition: all 0.1s ease-in-out;
    }
    .bg-drop[aria-expanded='true'] {
        top: 0;
    }
    .mobile-step-block[aria-expanded='true'] ~ .step_header_wrap_img {
        position: absolute;
        right: 0;
        border: 0;
        opacity: 0.2;
    }
    .scholarship_detail_carousel {
        padding: 0;
    }
    .leftPaneHeaderWrap .global.nobadge {
        height: 0;
    }
    .leftPaneHeaderWrap .global.nobadge .progress_svg {
        top: -30px;
    }
    .global_wrap .global.nobadge {
        height: auto;
        border: 0;
    }
    .global_wrap .global.nobadge .progress_svg {
        top: -30px;
    }
    footer {
        margin-bottom: 160px;
    }
    .pop-body .img_pop {
        position: static;
        width: 100%;
        text-align: center;
    }
}

@media (max-width: 575px) {
    div#root > div {
        overflow: hidden;
    }
    .ful-header + .main-wrap {
        padding: 30px;
    }
    .leftPaneBody {
        padding: 30px;
    }
    .listed_points {
        margin-top: 10px;
        margin-right: 15px;
    }
    .leftPaneHeader img {
        width: 130px;
    }
    .header_container img {
        width: 130px;
    }
    .step_header_wrap_title {
        padding-top: 0;
    }
    .steps.showTab > h2 {
        padding: 30px 25px 0;
    }
    .steps_questions {
        /* padding: 0px 25px 30px; */
    }
    .action_btns {
        max-width: 100%;
        margin: 0 auto;
        padding: 10px;
    }
    .step_header_wrap_img img {
        height: 100px;
        animation: none;
        -webkit-animation: none;
    }
    .leftPaneHeader .user_name span {
        font-weight: 600;
        width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
        vertical-align: top;
    }
    .global .unlock {
        padding: 12px 0;
    }
    .global {
        height: auto;
    }
    .global .unlock {
        height: auto;
    }
    .progress_svg {
        bottom: 10px;
        right: 10px;
    }
    .global_wrap .progress_svg {
        right: 0;
        bottom: 5px;
    }
    .scholarship_carousel .item .card_profiles {
        width: 100%;
        margin: 0 0 10px;
    }
    .scholarship_detail_carousel_container {
        width: 100%;
        overflow: hidden;
    }
    .joined_offer_wrap {
        margin-left: 0;
    }
    .scholarship_block {
        width: 100%;
        margin: auto;
    }
    .scholarship_detail_carousel {
        padding: 0px 0 40px;
    }
    .global_wrap .global {
        top: 0;
        left: 0px;
    }
    .skip_pop .brife {
        -webkit-box-flex: 1;
        -moz-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
    }
    #skip_pop .modal-footer {
        margin: 0;
    }
    #skip_pop .modal-dialog .modal-body {
        padding: 30px 0 0 30px;
    }
    .action_btns .btn-skip {
        margin: 18px 18px 0;
        display: inline-block;
    }
    #skip_pop .img_pop {
        margin-top: 15px;
        text-align: center;
        width: 100%;
        padding-right: 30px;
    }
    .form-check-input:checked ~ .select-option.first-option::after {
        content: '';
        position: absolute;
        bottom: 0;
        border-bottom: 1px solid #00000016;
        width: 208%;
        left: -10%;
    }
    .form-check-input:checked ~ .select-option.second-option::after {
        content: '';
        position: absolute;
        bottom: 0;
        border-bottom: 1px solid #00000016;
        width: 208%;
        right: -10%;
    }
    .form-check-input:checked ~ .select-option.third-option::after {
        content: '';
        position: absolute;
        bottom: 0;
        border-bottom: 1px solid #00000016;
        width: 208%;
        left: -10%;
    }
    .select-option.third-option .form-select {
        margin-bottom: 20px;
    }
    .global.nobadge {
        top: auto;
        bottom: auto;
        height: 20px;
        border: 0;
    }
    .global.nobadge .progress_svg {
        top: -23px;
        bottom: auto;
        right: 0;
    }
    .leftPaneHeaderWrap .global.nobadge .progress_svg {
        right: 10px;
        top: -23px;
    }
    .global.show .unlock.prize_bg,
    .global .unlock.prize_bg,
    .global .unlock.laststep_bg {
        height: 50px;
    }
    .unlock.prize_bg .img,
    .unlock.laststep_bg .img {
        margin-bottom: -20px;
    }
    .leftPaneHeaderWrap .progress_svg {
        top: -22px;
    }
    .global_wrap .global.nobadge .progress_svg {
        top: -23px;
    }
    .global_wrap .global.nobadge {
        padding: 10px;
    }
    .global .unlock.green_recom_bg {
        padding: 7px 0;
    }
    .global .unlock.green_recom_bg .text {
        width: calc(100% - 0px);
        padding: 0 20px 0 0;
    }
    .global .unlock.unlock_blue {
        padding: 0;
    }
    .pop-body {
        padding: 30px 30px 0;
    }
    .edit_phone .btn-close {
        font-size: 12px;
        position: absolute;
        top: 0px;
        right: -10px;
    }
    .step_img img {
        height: auto;
        width: 125px;
    }
    .item-wrap .card_profiles {
        width: 100%;
        margin: 0 0 20px;
    }
    .pop-body .img_pop img {
        width: 100%;
        max-width: 210px;
    }
}

@media (max-width: 400px) {
    .global.show .unlock .text {
        line-height: 16px;
        font-size: 13px;
    }
}
.cta_add {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 16px;
    font-weight: 500;
}
.cta_add .form-check label {
    color: #0b3454;
}
.cta_add .form-check input {
    border: 2px solid #0b3454;
    width: 22px;
    height: 22px;
    border-radius: 40px;
    margin-right: 10px;
}
.cta_add .form-check-input:checked {
    background-color: #27970b;
    border: 0 !important;
}
.cta_add .form-check label {
    padding-top: 3px;
    color: #0b3454;
}
.cta_add .btn-add {
    color: #27a7df;
    padding: 0 30px 0 0;
    text-decoration: none;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
}
.cta_add .btn-add:hover {
    color: #5cae48;
}
.cta_add .btn-add i {
    margin-right: 10px;
    margin-top: -4px;
}
.cta_add .btn-add i path {
    fill: #5cae48;
    color: #5cae48;
}
.cta_add .btn-add:focus {
    box-shadow: none;
}
.cta_add .btn-add:focus-visible {
    border: none;
    outline: none;
}
.cta_add .btn-add:hover i img {
    filter: invert(54%) sepia(94%) saturate(312%) hue-rotate(63deg) brightness(93%) contrast(83%);
    -webkit-filter: invert(54%) sepia(94%) saturate(312%) hue-rotate(63deg) brightness(93%) contrast(83%);
}
.btn-delete {
    padding: 0;
    width: 18px;
    height: 18px;
    position: absolute;
    right: 100%;
    top: 15px;
    opacity: 0.4;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    background: transparent
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z'/%3e%3c/svg%3e")
        0 0 / 18px auto no-repeat;
}
.btn-delete:hover {
    opacity: 1;
}
.form-label ~ .btn-delete {
    top: 45px;
}
.section {
    position: relative;
    padding-right: 20px;
}
.section + .section {
    margin-top: 60px;
}
.section:first-child {
    margin-top: 20px;
}
.section .btn-delete {
    top: 35px;
    bottom: 0;
    margin: auto;
    right: -60px;
}
.section::after {
    content: '';
    position: absolute;
    right: -2px;
    top: 23px;
    bottom: 0;
    width: 1px;
    height: calc(100% - 23px);
    background: #e2e8ef;
}

@media (max-width: 767px) {
    .cta_add {
        display: block;
    }
    .cta_add .btn-add {
        margin-bottom: 10px;
    }
    .section {
        width: calc(100% - 25px);
    }
    .section .btn-delete {
        right: -50px;
    }
}
.font-18 {
    font-size: 18px;
}
.mentoring_contact {
    border: 1px solid #b5c1cb;
    border-radius: 6px;
}
.mentoring_contact p {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
}
.mentoring_contact p i {
    width: 36px;
    height: 36px;
    background: #27a7df;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    border-radius: 60px;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}
.mentoring_contact p i img {
    /* height: auto; */
}
.card_achievements {
    text-align: center;
}
.card_achievements .card_profiles_name {
    display: block !important;
}
.card_achievements .card_profiles_univarsity .card_profiles_name > a {
    margin: 0 auto;
    margin-bottom: -47px;
    width: 80px;
    height: 80px;
    position: relative;
    overflow: inherit; /* z-index: 9999; */
}
.card_achievements .scholarship_amt {
    margin: 0 auto;
    margin-top: 30px;
    font-size: 18px;
}
.card_achievements .card_profiles_univarsity .card_profiles_name > a span {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/badge.svg) no-repeat center;
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 20px;
    height: 28px;
    margin-left: -10px;
    bottom: -15px;
}
.card_achievements .card_profiles_univarsity .card_profiles_name > a img {
    border-radius: 50%;
}
.profile_picture_qr {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    min-height: 242px;
    border-radius: 10px;
    overflow: hidden;
}
.profile_picture_qr .or {
    width: 2px;
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.profile_picture_qr .profile_picture_section {
    background: #d42941;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    position: relative;
}
.profile_picture_qr .profile_picture_section:hover {
    background: #bd1f35;
}
.profile_picture_qr .profile_picture_section .block {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;
    z-index: 1;
}
.profile_picture_qr .qr_section {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    background: #cffed2;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.profile_picture_qr .or span {
    background: #fff;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    position: absolute;
    left: -21px;
    bottom: 0;
    top: 0;
    margin: auto;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
}
.profile_picture_qr .or::after {
    display: none;
}
.profile_picture_edit {
    border: 2px dashed #ffffff;
    border-radius: 74px;
    width: 145px;
    height: 145px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 0 5px #d42941 inset;
    position: relative;
}
.profile_picture_edit:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    background: #fff;
    border-radius: 50%;
    z-index: 0;
}
.profile_picture_edit img {
    width: 100%;
    position: relative;
}
.profile_picture_edit .image-edit {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 32px;
    height: 32px;
    background: #5cae48;
    border: 3px solid #fff;
    border-radius: 50%;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.profile_picture_edit .image-edit:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 2px;
    height: 12px;
    background: #fff;
    margin: auto;
}
.profile_picture_edit .image-edit:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 12px;
    height: 2px;
    background: #fff;
    margin: auto;
}
.profile_picture_edit .image-edit:hover {
    background: #669f34;
}
.profile_picture_edit_btn {
    margin-top: 10px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    text-align: center;
}
.profile_picture_edit_btn label {
    background: #ffe3e3;
    border: 2px solid #ffffff;
    border-radius: 60px;
    font-size: 12px;
    font-weight: 500;
    width: 140px;
    height: 30px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.profile_picture_section:hover .profile_picture_edit_btn label {
    background: #f7b3b3;
    border: 2px solid#F7B3B3;
}
.profile_picture_edit_btn span {
    font-size: 14px;
    color: #fff;
    line-height: 18px;
}
.profile_picture_qr .qr_section p {
    font-size: 16px;
    margin: 8px 0 0;
    font-weight: 500;
}
.whatapp_text i {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/whatsapp.svg) no-repeat 0 0;
}
.whatapp_text {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    font-size: 16px;
    font-weight: 600;
    align-items: center;
}
.desclamier {
    font-size: 14px;
    font-style: italic;
    margin: 10px 0 20px;
}

@media (min-width: 768px) and (max-width: 992.98px) {
    .profile_picture_qr {
        flex-direction: column;
    }
    .profile_picture_qr .profile_picture_section {
        padding: 30px;
    }
    .profile_picture_qr .qr_section {
        padding: 30px;
    }
    .profile_picture_qr .or {
        width: 100%;
    }
    .profile_picture_qr .or span {
        left: 0;
        right: 0;
    }
}

@media (max-width: 575px) {
    .profile_picture_qr {
        flex-direction: column;
    }
    .profile_picture_qr .profile_picture_section {
        padding: 30px;
    }
    .profile_picture_qr .qr_section {
        padding: 30px;
    }
    .profile_picture_qr .or {
        width: 100%;
    }
    .profile_picture_qr .or span {
        left: 0;
        right: 0;
    }
    .btn-delete {
        right: -20px;
    }
}

.success_popup {
    max-width: 700px;
}
.success_popup .img_pop {
    position: absolute;
    right: -40px;
    bottom: 0;
}
.success_popup .modal-content {
    min-height: 380px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.success_popup .modal-body {
    display: flex;
    align-items: center;
    padding-left: 60px;
}
.success_popup .brife {
    margin-top: -30px;
    max-width: 320px;
}
.success_popup .modal-body h4::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 50px;
    height: 3px;
    background: #5cae48;
    border-radius: 15px;
}
.success_popup .modal-body h4 {
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 20px;
}
.success_popup .modal-footer {
    background: transparent linear-gradient(261deg, #fdfeff 0%, #eff7ff 16%, #d4ffc9 100%);
    border-radius: 90px 10px 10px 10px;
    opacity: 1;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: start;
    padding-left: 70px;
    border: 0;
    min-height: 90px;
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    margin-top: -90px;
    -webkit-border-radius: 90px 10px 10px 10px;
    -moz-border-radius: 90px 10px 10px 10px;
    -ms-border-radius: 90px 10px 10px 10px;
    -o-border-radius: 90px 10px 10px 10px;
}
.file {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
}
.file > input[type='file'] {
    display: none;
}
.file > label {
    font-size: 1rem;
    font-weight: 300;
    cursor: pointer;
    outline: 0;
    user-select: none;
    border-radius: 6px;
    padding-right: 16px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: 1px dotted #8499a9;
    min-height: 60px;
    padding: 0;
    font-weight: 400;
    color: #0b3454;
}
.file > label span {
    padding-left: 5px;
    text-decoration: underline;
    color: #27a7df;
    font-weight: 400;
}
.file > label:hover {
    border-color: hsl(0, 0%, 21%);
}
.file > label:active {
    background-color: hsl(0, 0%, 96%);
}
.file > label > i {
    padding-right: 15px;
}
.file--upload > label {
}
.file--upload > label:hover {
    border-color: hsl(204, 86%, 53%);
    background-color: hsl(204, 86%, 96%);
}
.file--upload > label:active {
    background-color: hsl(204, 86%, 91%);
}
.file--uploading > label {
    color: hsl(48, 100%, 67%);
    border-color: hsl(48, 100%, 67%);
}
.file--uploading > label > i {
    animation: pulse 5s infinite;
    -webkit-animation: pulse 5s infinite;
}
.file--uploading > label:hover {
    border-color: hsl(48, 100%, 67%);
    background-color: hsl(48, 100%, 96%);
}
.file--uploading > label:active {
    background-color: hsl(48, 100%, 91%);
}
form-control.is-valid,
.was-validated .form-control:valid {
    background-image: none;
}
.was-validated .form-select:valid:not([multiple]):not([size]) {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}
.form-select.is-valid,
.was-validated .form-select:valid {
    color: #000;
}
.question .form-select.customSelect {
    border: 1px solid #b5c2cb;
    border-radius: 6px;
    background-color: #fff;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.customChoose {
    background: #ffffff;
    box-shadow: 0px 10px 20px #efefef;
    border: 1px solid #b5c1cb;
    border-radius: 6px;
    overflow: hidden;
    position: absolute;
    width: 100%;
    top: 150%;
    z-index: -1;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.question .customChoose .form-control {
    border-width: 0 0 1px 0;
    border-radius: 0;
}
.customChoose.open {
    opacity: 1;
    z-index: 1;
    top: 102%;
}
.customChooseoptions {
    margin: 20px 0 20px;
    padding: 0 15px;
    list-style: none;
    max-height: 160px;
    overflow: auto;
    line-height: 16px;
    scrollbar-width: 4px;
}
.customChooseoptions::-webkit-scrollbar {
    width: 4px;
}
.customChooseoptions::-moz-scrollbar {
    width: 4px;
}
.customChooseoptions::-webkit-scrollbar-track {
    background: #eee;
}
.customChooseoptions::-webkit-scrollbar-thumb {
    background-color: #666;
    border-radius: 20px;
    border: 2px solid #eee;
}
.customChooseoptions li {
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
}
.customChooseoptions li:hover {
    color: #5cae48;
}
.customChooseoptions li:not(:last-child) {
    margin-bottom: 12px;
}
.customInputSearch + .customChoose.open,
.customInputSearch:focus + .customChoose {
    opacity: 1;
    top: 102%;
    z-index: 1;
}
.input-group-customSelect {
    width: 33.3%;
}
.input-group .customSelect {
    border-radius: 6px 0 0 6px !important;
}
.input-group-customSelect:not(:first-child) label {
    border-radius: 0 !important;
}
.form-select.CurrencySelect.customSelect ~ .customChoose {
    width: 168px;
    border-radius: 6px !important;
}
#login_pop .signup,
#sociallogin,
.bg_side_blue .signup {
    background: #0b3454;
    margin-right: -1px;
    border-radius: 0px 18px 18px 80px;
}
#login_pop .signup h1 {
    font-family: 'Jost', sans-serif;
    color: #fff;
}
#login_pop .signup:before {
    display: none;
}
#login_pop .signup .title {
    position: relative;
}
#login_pop .innercover {
    max-width: 385px;
    width: 90%;
    margin: auto;
    padding: 0 20px;
}
#login_pop .innercover .img {
    display: flex;
    height: auto;
    align-items: flex-end;
    margin-bottom: 37px;
    justify-content: center;
}
.innercover .img img {
    max-width: 200px;
}
.icon.icon-correct::before {
    content: '';
    width: 13px;
    height: 7px;
    border-left: 2px solid #98bbdf;
    border-bottom: 2px solid #98bbdf;
    display: inline-block;
    transform: rotate(-40deg);
    margin: 7px 3px 0 0;
    vertical-align: top;
    -webkit-transform: rotate(-40deg);
    -moz-transform: rotate(-40deg);
    -ms-transform: rotate(-40deg);
    -o-transform: rotate(-40deg);
}
.signup form .form-check-label {
    cursor: pointer;
}
.form-group label[onclick] {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.form-group.form-control-email::before {
    width: 16px;
    height: 12px;
    content: '';
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/form-email.svg) no-repeat 0 center;
    position: absolute;
    top: 15px;
    bottom: auto;
    margin: auto;
    left: 16px;
    z-index: 2;
}
.form-group.form-control-password::before {
    width: 12px;
    height: 16px;
    content: '';
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/form-lock.svg) no-repeat 0 center;
    position: absolute;
    top: 15px;
    bottom: auto;
    margin: auto;
    left: 19px;
    z-index: 2;
}
/* .form-group.form-control-mobile::before {width: 9px;height: 16px;content: '';background: url(../images/form-cell-phone.svg) no-repeat 0 center;position: absolute;top: 15px;bottom: auto;margin: auto;left: 16px;z-index: 2;} */
.form-group label[onclick]:hover,
.signup form .form-check-label[for='exampleCheck1']:hover,
.signup a:hover {
    color: #5cae48 !important;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
}
form a:hover {
    text-decoration: none;
}
.form-group label[onclick]:hover {
    color: #5cae48 !important;
}
.signup form .form-check-input:not(#exampleCheck1):checked + label.form-check-label {
    background: #217aff !important;
    border-color: #217aff !important;
    color: #fff;
}
.signup form .form-check-input:checked + label .icon.icon-correct::before {
    border-color: #fff !important;
}
.signup form .form-control {
    min-height: 40px;
    border: none;
    border-radius: 6px;
    font-weight: 500;
    color: #000;
    font-family: 'Jost', sans-serif;
    font-size: 16px;
    z-index: 1;
    position: relative;
}
.signup #existing-user form .form-control {
    padding-left: 50px;
}
.signup #existing-user form .form-control#ShopLoginFormModel_email {
    background: #fff url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/form-email.svg) no-repeat 16px
        center;
}
.email-text {
    color: #ffffff99;
}
.signup #existing-user form .form-control#ShopLoginFormModel_password,
.signup #existing-user form .form-control#ShopSignupFormModel_password {
    background: #fff url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/form-lock.svg) no-repeat 19px
        center;
}
.signup #existing-user form .form-control#ShopSignupFormModel_first_name,
.signup form .form-group {
    position: relative;
}
.signup form .form-group .viewpassword {
    position: absolute;
    right: 15px;
    bottom: auto;
    top: 14px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-content: center;
    z-index: 2;
}
.signup form .form-control + .viewpassword::before {
    content: '';
    width: 0%;
    height: 2px;
    background: #022d6d;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    transform: rotate(45deg);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.signup form .form-control[type='text'] + .viewpassword::before {
    width: 100%;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.signup form .form-check-input {
    width: 1em;
    height: 1em;
    margin-top: 0.25em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, 0.25);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    -webkit-transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    -moz-transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    -ms-transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    -o-transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
}
.signup form .form-check .form-check-input {
    float: left;
    margin-left: -1.5em;
}
.signup form .form-check-input[type='checkbox'] {
    border-radius: 0.25em;
}
.signup form .form-check-input:checked {
    background-color: #7fb507;
    border-color: #7fb507;
}
.signup form .form-check-input:checked[type='checkbox'] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.signup .owl-dots .owl-dot {
    box-shadow: 0 0 0 0;
}
.signup .owl-theme .owl-dots .owl-dot.active span,
.signup .owl-theme .owl-dots .owl-dot:hover span {
    background: #585858;
    opacity: 0.8;
}
.login-footer {
    margin: 50px 0;
}
.login-footer p {
    font-size: 12px;
    font-weight: 600;
    margin: 0;
    position: relative;
}
.login-footer p::after {
    background: #c5c5c5;
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 7px;
    margin: auto;
    content: '';
    left: 0;
    right: 0;
}
.login-footer p b {
    font-size: 12px;
    font-weight: 600;
    margin: 0;
    position: relative;
    z-index: 2;
    background: #fff;
    padding-right: 15px;
}
.login-footer > span {
    color: #060606;
    font-size: 12px;
    line-height: 16px;
    opacity: 0.6;
}
.signup .or {
    margin: 0 0 20px;
    text-align: center;
    display: inline-block;
    width: 100%;
    font-size: 18px;
    line-height: 22px;
    color: #fff;
    position: relative;
}
#existing-user.active ~ div .btn-signin {
    display: none;
}
.input-otp {
    max-width: 60px;
    width: 100%;
    outline: none;
    box-shadow: none;
}
#new-user.active ~ div .btn-signup {
    display: none;
}
#new-user .nav-pills .nav-link {
    background: #fff;
    width: 100%;
}
#new-user .nav-pills .nav-link.active {
    background: #217aff;
}
#new-user ul.nav-pills li {
    width: 30%;
}
#new-user .nav-pills .nav-link.active .icon.icon-correct::before {
    border-color: #fff !important;
}
#pills-others.active + div {
    display: none;
}
.or::after {
    background: #38566e;
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 7px;
    margin: auto;
    content: '';
    left: 0;
    right: 0;
    max-width: 463px;
}
.signup .or b {
    font-weight: 400;
    font-family: 'Jost', sans-serif;
    background: #0b3454;
    position: relative;
    z-index: 2;
    padding: 0 10px;
}
.sociallnks {
    text-align: center;
}
.sociallnks p {
    font-size: 14px;
    line-height: 17px;
    color: #fff;
    font-family: 'Jost', sans-serif;
    margin: 0;
    font-weight: 400;
}
.sociallnks p a {
    color: #a2e609;
    font-weight: 500;
}
.sociallnks p a:hover {
    text-decoration: none;
}
.sociallinks ul {
    list-style: none;
}
.sociallnks ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    justify-content: center;
}
.sociallnks ul li a {
    background: #ffffff;
    box-shadow: 0px 5px 10px #0000001a;
    border-radius: 18px;
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    height: 36px;
    justify-content: center;
    align-items: center;
}
.sociallnks ul li {
    width: 100px;
    margin: 20px 10px;
}
.signup form .gendar-radio-button + label {
    float: left;
    padding: 0.5em 1em;
    cursor: pointer;
    border: 1px solid #28608f;
    margin-right: -1px;
    color: #000;
    background-color: #ffffff;
    width: 100%;
    border-radius: 6px;
    min-height: 40px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    border: none;
}
.signup form .error .error-valid-text {
    color: #fff !important;
    margin-top: 3px;
}
.signup form .error .form-control {
    background-color: #f7d7d7 !important;
    border: 1px solid #fd7373;
}
.signup form .gendar-radio-button + label:first-of-type {
}
.signup form .gendar-radio-button + label:last-of-type {
}
.signup form .gendar-radio-button:checked + label {
    background-color: #1074e9;
    color: #fff;
}
.btn-access {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
#new-user .progress {
    height: 6px;
    background: #051c3e;
}
#new-user a#but_add {
    cursor: pointer;
}
#myloginTab {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}
#myloginTab li {
    flex: 2;
}
#myloginTab li button {
    width: 100%;
    border: 0;
    border-radius: 0;
    line-height: 70px;
    padding: 0;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Jost', sans-serif;
    background: #04243c;
    color: rgba(255, 255, 255, 0.5);
}
#myloginTab li button.active {
    background: #0b3454;
    color: #fff;
}
.daterangepicker .calendar-table th {
    background-image: none;
}
.daterangepicker .calendar-table .next span {
    transform: rotate(130deg);
    -webkit-transform: rotate(130deg);
}
.page_loader {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.8);
    z-index: 5;
}
.page_loader .sr-only {
    font-size: 0;
}
.page_loader .spinner-border {
    border-color: #fff #fff #fff transparent;
    width: 100px;
    height: 100px;
    border-width: 10px;
}
.css-b62m3t-container .css-t3ipsp-control,
.css-b62m3t-container,
.css-13cymwt-control {
    height: 43px !important;
    border: 0 !important;
    flex: 1;
}
div.css-9kqgro-control,
div.css-xpesdl-control,
div.css-9kqgro-control {
    min-height: 38px !important;
    margin-top: 1px;
    margin-right: 1px;
}
div.css-1fdsijx-ValueContainer {
    padding: 0;
}
div.css-3w2yfm-ValueContainer {
    display: inline-block;
    overflow: hidden;
    padding-left: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow-x: auto;
    scrollbar-width: thin;
}
div.css-3w2yfm-ValueContainer::-webkit-scrollbar {
    height: 3px;
}
div.css-3w2yfm-ValueContainer::-moz-scrollbar {
    height: 3px;
}
div.css-3w2yfm-ValueContainer::-webkit-scrollbar-track {
    background: #ccc;
}
div.css-3w2yfm-ValueContainer::-webkit-scrollbar-thumb {
    background-color: #666;
    border-radius: 20px;
    border: 2px solid #666;
}
div.css-1p3m7a8-multiValue {
    display: inline-flex;
}
.delete-sticky {
    position: sticky;
    top: 147px;
    right: 0;
    text-align: right;
    cursor: pointer;
}
.blue-tick {
    color: blue;
}
/* Profile Registration End */

/* Alumni Dashboard Start */
@font-face {
    font-family: 'icomoon';
    src: url('fonts/icomoon.eot?ynntp9');
    src: url('fonts/icomoon.eot?ynntp9#iefix') format('embedded-opentype'), url('fonts/icomoon.woff2?ynntp9') format('woff2'),
        url('fonts/icomoon.ttf?ynntp9') format('truetype'), url('fonts/icomoon.woff?ynntp9') format('woff'),
        url('fonts/icomoon.svg?ynntp9#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
[class^='icon-'],
[class*=' icon-'] {
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.icon-add-user:before {
    content: '\e900';
}
.icon-notification:before {
    content: '\e901';
}
.icon-book:before {
    content: '\e902';
}
.icon-box:before {
    content: '\e903';
}
.icon-brife-case:before {
    content: '\e904';
}
.icon-calendar:before {
    content: '\e905';
}
.icon-calender-time:before {
    content: '\e906';
}
.icon-chat:before {
    content: '\e907';
}
.icon-chat-invert:before {
    content: '\e908';
}
.icon-check:before {
    content: '\e909';
}
.icon-check-list:before {
    content: '\e90a';
}
.icon-clipboard:before {
    content: '\e90b';
}
.icon-correct:before {
    content: '\e90c';
}
.icon-correct-invert:before {
    content: '\e90d';
}
.icon-cross:before {
    content: '\e90e';
}
.icon-dashboard:before {
    content: '\e90f';
}
.icon-down-arrow:before {
    content: '\e910';
}
.icon-down-arrow-invert:before {
    content: '\e911';
}
.icon-edit:before {
    content: '\e912';
}
.icon-edit-pencil:before {
    content: '\e913';
}
.icon-mail:before {
    content: '\e914';
}
.icon-filter:before {
    content: '\e915';
}
.icon-folder:before {
    content: '\e916';
}
.icon-goal:before {
    content: '\e917';
}
.icon-headphones:before {
    content: '\e918';
}
.icon-heart:before {
    content: '\e919';
}
.icon-info:before {
    content: '\e91a';
}
.icon-insert-picture-icon:before {
    content: '\e91b';
}
.icon-insert-picture-square:before {
    content: '\e91c';
}
.icon-invert-cross:before {
    content: '\e91d';
}
.icon-invert-down-arrow:before {
    content: '\e91e';
}
.icon-invert-play:before {
    content: '\e91f';
}
.icon-key:before {
    content: '\e920';
}
.icon-laptop:before {
    content: '\e921';
}
.icon-left-arrow:before {
    content: '\e922';
}
.icon-like:before {
    content: '\e923';
}
.icon-location-pin:before {
    content: '\e924';
}
.icon-lock:before {
    content: '\e925';
}
.icon-log-in:before {
    content: '\e926';
}
.icon-multiple-users:before {
    content: '\e927';
}
.icon-pdf:before {
    content: '\e928';
}
.icon-pdf-upload:before {
    content: '\e929';
}
.icon-profit-report:before {
    content: '\e92a';
}
.icon-qualified:before {
    content: '\e92b';
}
.icon-question:before {
    content: '\e92c';
}
.icon-ribbon:before {
    content: '\e92d';
}
.icon-ribbon-invert:before {
    content: '\e92e';
}
.icon-right-arrow:before {
    content: '\e92f';
}
.icon-rupee:before {
    content: '\e930';
}
.icon-rupee-invert:before {
    content: '\e931';
}
.icon-search:before {
    content: '\e932';
}
.icon-setting:before {
    content: '\e933';
}
.icon-share:before {
    content: '\e934';
}
.icon-shuttle:before {
    content: '\e935';
}
.icon-shuttle-invert:before {
    content: '\e936';
}
.icon-sound:before {
    content: '\e937';
}
.icon-speak:before {
    content: '\e938';
}
.icon-star:before {
    content: '\e939';
}
.icon-study:before {
    content: '\e93a';
}
.icon-switch:before {
    content: '\e93b';
}
.icon-university:before {
    content: '\e93c';
}
.icon-up-arrow:before {
    content: '\e93d';
}
.icon-upload:before {
    content: '\e93e';
}
.icon-upload-big-arrow:before {
    content: '\e93f';
}
.icon-user-invert:before {
    content: '\e940';
}
.icon-user:before {
    content: '\e941';
}
.icon-varified:before {
    content: '\e942';
}
.icon-video-camera:before {
    content: '\e943';
}
.icon-view:before {
    content: '\e944';
}
.icon-world:before {
    content: '\e945';
}
.icon-hat:before {
    content: '';
}
.custom-header {
    min-height: 85px;
    overflow: hidden;
}
.custom-header:hover {
    overflow: visible;
}
header {
    position: relative;
    z-index: 99;
    background: #fff;
}
.univarsity_logo {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    padding: 0;
    width: auto;
}
.univarsity_logo .logo img {
    max-height: 70px;
    width: auto;
    max-width: 170px;
}
.profileSection {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}
.manager_profile .profileSection {
    margin: 0 0 0 20px;
    padding: 0 0 0 20px;
    border-left: 1px solid #e1dede;
    display: none;
}
.profileNamePosition .position {
    background: #d6ffcc;
    border-radius: 12px;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    line-height: 20px;
    height: 20px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}
.profileNamePosition {
    margin-left: 10px;
}
.profileNamePosition .name {
    font-weight: 500;
    font-size: 16px;
}
.search {
    max-width: 400px;
    padding: 0;
    background: #f1f1f1;
    border-radius: 20px;
    height: 40px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}
.headerRightSide {
    padding: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    width: auto;
    z-index: 1;
}
.headerRightSide > ul li:not(:last-child) {
    margin-right: 20px;
}
.headerRightSide > ul li {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: right;
}
.headerRightSide > ul li div.username > label {
    overflow: hidden;
    border-radius: 50%;
}
.userimg {
    font-size: 34px;
    color: #0b3454;
}
.headerRightSide > ul li .username {
    margin-right: 18px;
}
.headerRightSide > ul li div.username > i {
    top: 15px;
    right: -14px;
    font-size: 9px;
    cursor: pointer;
}
.headerRightSide > ul li div.username:hover > i {
    color: #5cae48;
}
.headerRightSide > ul li div.username:hover {
    overflow: visible;
}
.headerRightSide > ul li a {
    cursor: pointer;
}
.headerRightSide > ul li a.mail {
    position: relative;
}
.headerRightSide > ul li a.mail .count {
    position: absolute;
    background: #5cae48;
    border-radius: 50%;
    color: #fff;
    font-size: 12px;
    width: 17px;
    height: 17px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -10px;
    right: -10px;
}
.headerRightSide > ul li a.invite {
    background: #5cae48;
    border-radius: 25px;
    height: 35px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #fff;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.headerRightSide > ul li a.invite:hover {
    background: #408e2d;
    color: #fff;
}
.headerRightSide > ul li a:hover,
.username:hover .userimg {
    color: #5cae48;
}
.headerRightSide .user-dropdown ul li a b {
    font-size: 12px;
    color: #5cae48;
}
.userimg {
    cursor: pointer;
    vertical-align: bottom;
}
.search .form-control {
    border: 0;
    background: none;
    height: 40px;
    border-radius: 20px;
}
.search .form-control::placeholder {
    color: rgba(0, 0, 0, 0.8);
    opacity: 1;
    font-size: 16px;
    font-weight: 400;
}
.search .form-control:-ms-input-placeholder {
    color: #000;
}
.search .form-control::-ms-input-placeholder {
    color: #000;
}
.form-control:focus,
.search .btn-search:focus {
    box-shadow: none;
}
.form-control:focus-visible,
.search .btn-search:focus-visible {
    outline: none;
}
.search .btn-search {
    font-size: 13px;
    padding: 0;
    width: 28px;
    height: 28px;
    padding: 0;
    position: absolute;
    right: 7px;
    top: 7px;
}
.search .btn-search:hover {
    color: #26a7df;
}
.search > form,
.search > div {
    position: relative;
}
.search > form input:focus,
.search > div input:focus {
    box-shadow: none;
    background: #fff;
}
.search > form input:focus + .btn-search,
.search > div input:focus + .btn-search {
    color: #000;
}
.user-dropdown {
    background: #ffffff;
    box-shadow: 0px 3px 6px #0000001a;
    border: 1px solid #d2d2d2;
    border-radius: 6px;
    top: 80px;
    right: -17px;
    z-index: 1;
    width: 150px;
    opacity: 0;
    position: absolute;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.username:hover div.user-dropdown {
    opacity: 1;
    top: 50px;
}
.username:hover div.user-dropdown::before {
    width: 20px;
    height: 20px;
    content: '';
    position: absolute;
    top: -20px;
    right: 6px;
}
.username:not(:hover) div.user-dropdown ul li a {
    pointer-events: none;
}
.user-dropdown ul {
    padding: 15px;
    margin: 0;
}
.headerRightSide * {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.headerRightSide .user-dropdown ul li a {
    color: #000;
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
}
.headerRightSide .user-dropdown ul li a .message_count {
    font-size: 12px;
    width: 17px;
    height: 17px;
    background: #5cae48;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #fff;
}
.headerRightSide .user-dropdown ul li a:hover {
    color: #5cae48;
}
.headerRightSide .user-dropdown ul li:not(:last-child) {
    margin: 0 0 15px 0;
}
.headerRightSide > ul {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
}
.calender {
    width: 20px;
    height: 21px;
    display: inline-block;
    text-decoration: none;
    color: #0b3454;
    font-size: 20px;
}
.mail {
    width: 18px;
    display: inline-block;
    color: #0b3454;
    font-size: 18px;
    text-decoration: none;
}
.notification {
    width: 20px;
    height: 21px;
    display: inline-block;
    font-size: 20px;
    color: #0b3454;
    text-decoration: none;
}
.mobileonly {
    display: none;
}
.btn-search-toggle {
    display: none;
}
.main {
    position: relative;
}
.main.dashboard::before {
    content: '';
    width: 50%;
    height: 100%;
    background: #f3f3f3;
    position: absolute;
}
.mainContainer {
    position: relative;
    background: #fff;
}
.sideBar * {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.sideBar {
    width: 80px;
    position: relative;
    z-index: 1;
}
.sideBar ul {
    background: #f3f3f3;
    box-shadow: 8px 10px 10px 0px #e8e8e8;
    border-right: 1px solid #dbdbdb;
    list-style: none;
    padding: 36px 0 0 0;
    margin: 0;
    height: 100%;
    width: 80px;
}
.sideBar ul:hover {
    width: 260px;
}
.sideBar ul li a {
    width: 100%;
    float: left;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    color: #000;
    text-decoration: none;
    cursor: pointer;
}
.sideBar ul li a > b {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto 0;
    max-width: 60px;
    font-size: 25px;
    position: relative;
}
.sideBar ul li:not(.active) a > b::after {
    position: absolute;
    width: 0%;
    height: 0%;
    content: '';
    background: transparent;
    border-radius: 50%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.sideBar ul li:not(.active) a > b::before {
    position: relative;
    z-index: 2;
}
.sideBar ul li:not(.active) a:hover > b::after {
    width: 100%;
    height: 100%;
}
.sideBar ul li:hover a > b {
    color: #26a7df;
}
.sideBar ul li.active a > b {
    background: #26a7df;
    color: #fff;
}
.sideBar ul li a span {
    padding: 0 0 0 13px;
    font-size: 18px;
    line-height: 22px;
    width: 0;
    overflow: hidden;
}
.sideBar ul:hover li a span {
    width: 199px;
}
.sideBar ul li a span b {
    width: 175px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    font-weight: 400;
}
.pageContent {
    padding-left: 50px;
}
.btn-green {
    background: #5cae48;
    height: 40px;
    padding: 0 25px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    position: relative;
    overflow: hidden;
    border: 0;
    border-radius: 27px;
    -webkit-border-radius: 27px;
    -moz-border-radius: 27px;
    -ms-border-radius: 27px;
    -o-border-radius: 27px;
}
.btn-green:hover {
    color: #eeffea;
}
.btn-green .icon-question.circle {
    border-radius: 50%;
    border: 2px solid #fff;
    padding: 2px;
    font-size: 12px;
    width: 22px;
    height: 22px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 4px;
}
.welcome-title h1 {
    font-size: 35px;
    font-weight: 600;
    margin: 0 0 5px;
}
.welcome-title p {
    font-size: 18px;
    margin: 0;
}
.footer {
    padding: 20px 40px 40px;
    border-top: 0px solid #dedede;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
    font-size: 14px !important;
}
.powerby {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
}
.milestone {
    background: #fff;
    box-shadow: 0px 3px 6px #0000001a;
    border: 1px solid #d2d2d2;
    border-radius: 6px;
    height: 100%;
    position: relative;
    padding: 30px;
    text-align: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
}
.milestone .icon {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: -33px;
    background: #d42941;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}
.milestone .icon span {
    font-size: 32px;
    color: #fff;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.milestone .icon span:before {
    color: #fff;
}
.milestone .count {
    font-size: 22px;
    font-weight: 600;
    text-align: left;
}
.milestone .count {
    font-size: 22px;
    font-weight: 600;
    position: absolute;
    left: 15px;
    top: 12px;
}
.milestone .btn,
.activity-btn {
    height: 40px;
    text-align: center;
    margin: auto;
    display: inline-block;
    padding: 0 25px;
    border-radius: 25px;
    line-height: 36px;
    overflow: hidden;
    position: relative;
}
.milestone .btn span,
.btn-green span,
.activity-btn span {
    position: relative;
}
.activity-btn {
    min-width: 140px;
}
.btn-complete-now {
    background: #0b3454;
    color: #fff;
}
.btn-complete-now::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    bottom: 0;
    width: 0;
    background: #185584;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.btn-complete-now:hover::before {
    width: 100%;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.btn-complete-now:hover {
    color: #fff;
}
.btn-completed {
    background: #5cae48;
    color: #fff;
}
.btn-green::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    bottom: 0;
    width: 0;
    background: #398227;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.btn-green:hover::before {
    width: 100%;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.btn-completed:hover {
    color: #d7f7cf;
}
.btn-completed .icon-correct {
    font-size: 12px;
}
.milestone .count {
    font-size: 22px;
    font-weight: 600;
    position: absolute;
    left: 15px;
    top: 12px;
}
.milestone h3 {
    text-align: center;
    width: calc(100% - 34px);
    margin: 10px auto 0;
    font-size: 20px;
    font-weight: 600;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 25px;
}
.milestone .progress-status {
    padding: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    text-align: center;
    list-style: none;
    justify-content: center;
    align-items: center;
    margin: 16px 0 0;
}
.milestone .progress-status li {
    width: 40px;
    height: 3px;
    background: #dedede;
    display: inline-block;
    margin: 0 7px;
}
.milestone .progress-status li.completed {
    background: #5cae48;
}
.activities_status {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
    cursor: pointer;
    padding: 15px 0 0 0;
}
.sticky-notification {
    background: #0b3454;
    box-shadow: 0px 5px 10px #0b345463;
    border-radius: 6px;
    max-width: 840px;
    margin: auto auto 50px;
    position: relative;
    height: 100px;
    align-items: flex-end;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.sticky-notification .btn-close {
    color: #fff;
    opacity: 1;
    font-size: 9px;
    position: absolute;
    top: 10px;
    right: 10px;
    text-decoration: none;
}
.sticky-notification .imgplace {
    padding-left: 5px;
}
.sticky-notification .notification-detail {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    height: 100%;
    justify-content: space-around;
}
.sticky-notification .notification-detail p {
    margin: 0;
    color: #fff;
    max-width: 315px;
}
.imglink {
    max-width: 299px;
    width: 100%;
}
.imglink .imgplace {
    display: none;
}
.sticky-notification .notification-detail .links {
    max-width: 299px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.btn-message {
    background: #fff;
    padding: 0 25px;
    line-height: 38px;
    text-decoration: none;
    border-radius: 25px;
    color: #000;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    position: relative;
    overflow: hidden;
}
.btn-message span {
    position: relative;
}
.btn-message::before {
    width: 0;
    height: 100%;
    content: '';
    background: #a4a4a4;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.btn-message:hover::before {
    width: 100%;
}
.btn-message:hover {
    color: #fff;
}
#dashboard-pills-tab.nav-pills .nav-link {
    width: 175px;
    border-radius: 25px;
    border: 2px solid #7d7d7d;
    color: #414141;
}
#dashboard-pills-tab.nav-pills .nav-item:not(:last-child) {
    margin-right: 15px;
}
#dashboard-pills-tab.nav-pills .nav-link.active {
    background: #5cae48;
    border-color: #5cae48;
    color: #fff;
}
.expandOption {
    text-align: right;
    margin: 10px 0 20px;
}
.expandOption .btn-expand {
    font-size: 16px;
    font-weight: 500;
    color: #147ad6;
}
.expandOption .btn-expand .colapse {
    display: none;
}
.expandOption .btn-collapse .expand {
    display: none;
}
.dashboardAccordion .accordion-item:not(:last-child) {
    margin-bottom: 20px;
}
.dashboardAccordion .accordion-item {
    border: 1px solid #d2d2d2;
    border-radius: 6px;
}
.dashboardAccordion .accordion-item .accordion-button {
    background-color: transparent;
    font-size: 22px;
    font-weight: 600;
    color: #000;
    box-shadow: none;
    padding: 20px 13px 20px 30px;
}
.dashboardAccordion .accordion-item .accordion-button.text-burgandi {
    color: #d42941;
}
.dashboardAccordion .accordion-item .accordion-button span {
    font-size: 19px;
    margin-left: 40px;
    color: #0b3454;
}
.dashboardAccordion .accordion-item .accordion-button::after {
    content: '';
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px 20px;
    width: 20px;
    height: 20px;
}
.dashboardAccordion .accordion-item .accordion-body {
    padding: 10px 30px 30px;
}
.dashboardAccordion h3 {
    font-size: 22px;
    font-weight: 600;
    margin: 0;
}
.orange {
    background-color: #ff9f40;
}
.dark-blue {
    background-color: #147ad6;
}
.lite-red {
    background-color: #ff6384;
}
.gold {
    background-color: #ffcd56;
}
.cyan {
    background-color: #4bc0c0;
}
.pink {
    background-color: #e86bc2;
}
.yellow {
    background-color: #f9ff54;
    border: 1px solid #f0f64b;
}
.sky-blue {
    background-color: #79d2de;
}
.bg-red {
    background: #d52941; /* background:#b92940*/
}
.bg-gray {
    background: #f8f8f8;
}
.bg-lighter-blue {
    background: #f2f9ff;
}
.radius-10 {
    border-radius: 10px;
}
.custom-border-84 {
    width: 24px;
    height: 24px;
    border-width: 2px;
    border-color: #848484;
}
.custom-border-84.form-check-input:checked {
    background-color: #848484 !important;
    border-color: #848484 !important;
    box-shadow: inset 0 0 0 4px #fff;
    background-image: none !important;
}
.custom-border-84-20 {
    width: 20px;
    height: 20px;
    border-width: 2px;
    border-color: #848484;
}
.custom-border-84-20.form-check-input:checked {
    background-color: #848484 !important;
    border-color: #848484 !important;
    box-shadow: inset 0 0 0 4px #fff;
    background-image: none !important;
}
.custom-border-white.form-check-input {
    width: 18px;
    height: 18px;
}
.custom-border-white.form-check-input:checked {
    background-color: #707070 !important;
    border-color: #707070 !important;
    box-shadow: inset 0 0 0 4px #fff;
    background-image: none !important;
}
.blue-strip > div:nth-child(2n) {
    background: #f2f9ff;
}
.graph-result-list ul {
    margin: 20px auto 0;
    padding: 0;
    list-style: none;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    max-width: 317px;
}
.graph-result-list ul li {
    min-width: auto;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    margin: 0 11px 10px 0;
}
.graph-result-list ul li .color {
    width: 16px;
    height: 16px;
    display: inline-block;
    margin: 0 11px 0 0;
    border-radius: 2px;
}
.graph-result-list.oneLine ul,
.graph-result-list.rightPaneColor ul {
    max-width: initial;
}
.graph-result-list.oneLine ul li:last-child,
.graph-result-list.rightPaneColor ul li:last-child {
    margin-right: 0;
}
.onboardingLaunchGraph ul,
.triangleGraph ul {
    padding: 0;
    margin: 0;
    text-align: right;
    list-style: none;
}
.onboardingLaunchGraph ul li,
.triangleGraph ul li {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}
.onboardingLaunchGraph ul li div,
.triangleGraph ul li div {
    width: 100%;
    text-align: center;
    height: 65px;
    margin-bottom: 2px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    max-width: 380px;
    justify-content: center;
}
.onboardingLaunchGraph ul li div span,
.triangleGraph ul li div span {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    position: relative;
}
.onboardingLaunchGraph ul li div span:before,
.triangleGraph ul li div span:before {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    bottom: 0;
    border-width: 34px 10px 34px 10px;
    border-style: solid solid solid solid;
    border-color: transparent white white transparent;
}
.onboardingLaunchGraph ul li div span::before,
.triangleGraph ul li div span::before {
    transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    -o-transform: scaleX(-1);
}
.onboardingLaunchGraph ul li div span:after,
.triangleGraph ul li div span:after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    right: 0;
    bottom: 0;
    border-width: 34px 10px 34px 10px;
    border-style: solid solid solid solid;
    border-color: transparent white white transparent;
}
.onboardingLaunchGraph ul li:nth-child(1) div span,
.triangleGraph ul li:nth-child(1) div span {
    width: 100%;
    background: #ff9f40;
}
.onboardingLaunchGraph ul li:nth-child(2) div span,
.triangleGraph ul li:nth-child(2) div span {
    width: calc(100% - 40px);
    margin: 0 20px;
    background: #147ad6;
}
.onboardingLaunchGraph ul li:nth-child(3) div span,
.triangleGraph ul li:nth-child(3) div span {
    width: calc(100% - 80px);
    margin: 0 40px;
    background: #4bc0c0;
}
.onboardingLaunchGraph ul li:nth-child(4) div span,
.triangleGraph ul li:nth-child(4) div span {
    width: calc(100% - 60px);
    margin: 0 60px;
    background: #ff6384;
}
.onboardingLaunchGraph ul li:nth-child(5) div span,
.triangleGraph ul li:nth-child(5) div span {
    width: calc(100% - 160px);
    margin: 0 60px;
    background: #ffcd56;
}
.onboardingLaunchGraph ul li:nth-child(6) div span,
.triangleGraph ul li:nth-child(6) div span {
    width: calc(100% - 200px);
    margin: 0 60px;
    background: #e86bc2;
}
.onboardingLaunchGraph ul li label,
.triangleGraph ul li label {
    font-size: 16px;
    font-weight: 600;
    margin-left: 40px;
    white-space: nowrap;
    position: absolute;
    right: 0;
}
.blue-container {
    background: #26a7df url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/bulb.jpg) no-repeat right
        20px bottom 20px;
    border-radius: 6px;
    padding: 25px 30px;
    color: #fff;
    height: 100%;
}
.onboarding {
    max-width: 120px;
    height: 35px;
    border-radius: 25px;
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 25px;
    border: 2px solid #7d7d7d;
}
.highlight-text {
    text-align: center;
    margin: 20px 0 0;
    font-size: 16px;
    font-weight: 500;
}
.dashboardAccordion .blue-container h3 {
    font-size: 20px;
    font-weight: 500;
}
.blue-container ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.blue-container ul li {
    margin-bottom: 25px !important;
}
.blue-container ul li {
    position: relative;
    padding: 0 0 0 19px;
}
.blue-container ul li:before {
    content: '';
    width: 3px;
    height: 14px;
    background: #fff;
    position: absolute;
    left: 0;
    top: 6px;
    display: inline-block;
    border-radius: 55px;
}
.blue-container ul li p {
    margin: 0;
}
.actionableInsights {
    max-width: 350px;
}
#milestoneProgress-collapseThree .graph-result-list ul,
#tasksAssessmentsProgress-collapseFour .graph-result-list ul {
    max-width: initial;
}
.milestoneProgressGraph {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between;
}
.milestoneGraph {
    min-height: 260px;
    width: 16%;
}
.milestoneGraph .progress {
    width: 30%;
    height: auto;
}
.dashboardAccordion .milestoneGraph h3 {
    font-size: 16px;
    margin: 0 0 10px;
}
#milestoneProgress-collapseThree .graph-result-list ul li:last-child {
    margin-right: 0;
}
#tasksAssessmentsProgress-collapseFour .graph-result-list ul li:last-child {
    margin-right: 0;
}
.status_progress {
    max-width: 260px;
    height: 40px;
    width: 260px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    overflow: hidden;
    border-radius: 3px;
}
.status_progress > div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}
.tablesection {
}
.tablesection > .table {
    border: 1px solid #d2d2d2;
    border-radius: 6px;
    overflow: auto;
    padding: 0px;
}
.tablesection > .table thead {
    background: #0b3454;
    color: #fff;
}
.tablesection > .table > .table {
    margin: 0;
}
.tablesection > .table > .table tr:last-child {
    border-color: transparent;
}
.tablesection > .table > .table tr th + th,
.tablesection > .table > .table tr td {
    text-align: center;
    vertical-align: middle;
}
.alumni_directory_details .tablesection > .table > .table tr th + th,
.alumni_directory_details .tablesection > .table > .table tr td {
    text-align: left;
    font-weight: 500;
}
.tablesection > .table > .table > tbody th {
    font-weight: 500;
}
#tasksAssessmentsProgress-headingFour .tablesection > .table > .table tr td:last-child {
    width: 260px;
}
.tablesection > .table > .table th,
.tablesection > .table > .table td {
    vertical-align: middle;
    padding: 15px 25px;
}
.tablesection > .table > .table thead tr th {
    text-transform: uppercase;
    font-weight: 500;
    height: 57px;
}
.tablesection > .table > .table td a {
    color: #147ad6;
}
.tablesection > .table > .table td a:hover {
    color: #0b3454;
}
.tablesection > .table > .table td a.btn-border {
    color: #fff;
}
.tablesection > .table > .table td a.btn-border:hover {
    color: #fff;
}
.btn-border-gray {
    border-radius: 25px !important;
    padding: 0 25px;
    line-height: 32px;
    border: 2px solid #7d7d7d;
    height: 35px;
    display: inline-flex;
    text-decoration: none;
    color: #000;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    position: relative;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    background: #fff;
    -webkit-border-radius: 25px !important;
    -moz-border-radius: 25px !important;
    -ms-border-radius: 25px !important;
    -o-border-radius: 25px !important;
}
.btn-border-gray::before {
    width: 0;
    height: 100%;
    content: '';
    background: #0b3454;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.btn-border-gray:hover::before {
    width: 100%;
}
.btn-border-gray:hover {
    color: #fff;
    background: #0b3454;
    border: 2px solid #0b3454;
}
.btn-border-gray span {
    position: relative;
}
.btn-border-gray-de {
    border-radius: 25px;
    padding: 0 25px;
    line-height: 32px;
    border: 2px solid #dedede;
    height: 35px;
    display: inline-flex;
    text-decoration: none;
    color: #0b3454;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    position: relative;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    background: #fff;
}
.btn-border-gray-de::before {
    width: 0;
    height: 100%;
    content: '';
    background: #0b3454;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.btn-border-gray-de:hover::before {
    width: 100%;
}
.btn-border-gray-de:hover {
    color: #fff;
    background: #0b3454;
    border: 2px solid #0b3454;
}
.btn-border-gray-de i,
.btn-border-gray-de:hover i {
    color: #0b3454;
}
.btn-border-gray-de:hover i {
    color: #fff;
}
.btn-border-gray-de span {
    position: relative;
}
.btn-border-gray.disabled {
    opacity: 0.5;
    pointer-events: none;
}
.btn-white-border {
    padding: 0 25px;
    height: 35px;
    line-height: 30px;
    text-align: center;
    display: inline-block;
    border: 2px solid #fff;
    border-radius: 25px;
    text-decoration: none;
    color: #fff;
    margin-top: 10px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    position: relative;
    overflow: hidden;
}
.btn-white-border::before {
    width: 0;
    height: 100%;
    content: '';
    background: #fff;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.btn-white-border:hover::before {
    width: 100%;
}
.btn-white-border:hover {
    background: #fff;
    color: #000;
}
.btn-white {
    padding: 0 25px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    display: inline-block;
    border-radius: 35px;
    text-decoration: none;
    color: #000;
    background: #fff;
    margin-top: 10px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    position: relative;
    overflow: hidden;
}
.btn-white::before {
    width: 0;
    height: 100%;
    content: '';
    background: #0b3454;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.btn-white:hover::before {
    width: 100%;
}
.btn-white:hover {
    color: #fff;
}
.btn-white span,
.btn-white-border span,
.btn-border span {
    position: relative;
}
.shortList ul {
    padding: 0;
    margin: 0 0 24px 0;
    list-style: none;
}
.shortList ul li {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
    padding: 15px 0 15px 44px;
    position: relative;
}
.shortList ul li:not(:last-child) {
    border-bottom: 1px solid #d2d2d2;
}
.shortList ul li span {
    font-weight: 500;
}
.shortList ul li span:before {
    content: '';
    width: 24px;
    height: 24px;
    background: url(https://ap-south-1.console.aws.amazon.com/s3/object/media.univariety.com?region=ap-south-1&bucketType=general&prefix=userreactapp/style-images/flag.svg)
        no-repeat 0 0;
    position: absolute;
    left: 0;
    top: 15px;
}
.horizontalProgress .progress {
    height: 35px;
    border-radius: 4px;
    position: relative;
    background: #d8e7f2;
    margin-bottom: 28px;
}
.horizontalProgress .progress .progress-bar {
    border-radius: 4px;
    text-align: right;
    padding: 0 25px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.32px;
}
.horizontalProgress .progress label {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    padding-right: 20px;
    font-size: 16px;
    color: #000;
    font-weight: 500;
}
.verticle-progress .progress {
    width: 59px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    height: 177px;
    align-items: flex-end;
    margin: 0 10px;
}
.verticle-progress .progress .progress-bar {
    width: 100%;
    border-radius: 4px;
    justify-content: flex-end;
    padding-bottom: 7px;
    font-size: 16px;
    font-weight: 500;
}
.verticle-progress {
    margin-bottom: 15px;
}
.thinVerticleProgress {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 926px;
    max-width: 926px;
}
.thinVerticleProgress .verticleProgress {
    width: 28px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
    min-height: 510px;
    position: relative;
    flex-direction: column;
}
.thinVerticleProgress .verticleProgress .caption {
    position: relative;
    white-space: nowrap;
    text-align: right;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}
.thinVerticleProgress .verticleProgress .caption span {
    position: absolute;
    -moz-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    top: 15px;
    width: 100%;
    text-align: left;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: flex-end;
    height: 28px;
    align-items: center;
}
.thinVerticleProgress .verticleProgress .graphContainer {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
}
.thinVerticleProgress .verticleProgress .graphContainer .progress {
    width: 11px;
    height: 177px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: flex-end;
}
.thinVerticleProgress .verticleProgress .graphContainer .progress .progress-bar {
    width: 100%;
    border-radius: 4px;
}
.width-97 {
    width: 97px;
}
.width-59 {
    width: 59px;
}
.width-56 {
    width: 56px;
}
.width-43 {
    width: 43px;
}
.height-30 {
    height: 30px !important;
}
.height-32 {
    height: 32px !important;
}
.height-35 {
    height: 35px !important;
}
.height-40 {
    height: 40px !important;
}
.height-85 {
    height: 85px !important;
}
.height-125 {
    height: 125px !important;
}
.height-217 {
    height: 217px !important;
}
.paddings-67 {
    padding-left: 67px;
}
.horizontalScrol {
    overflow-x: auto;
    padding-bottom: 30px;
}
.horizontalScrol::-webkit-scrollbar {
    height: 3px;
}
.horizontalScrol::-moz-scrollbar {
    height: 3px;
}
.horizontalScrol::-webkit-scrollbar-track {
    background: #eee;
}
.horizontalScrol::-webkit-scrollbar-thumb {
    background-color: #666;
    border-radius: 20px;
    border: 2px solid #666;
}
.bigVerticalProgress {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: start;
}
.bigVerticalProgress .graphContainer {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.bigVerticalProgress .graphContainer .progress {
    width: 100%;
    height: 177px;
    align-items: flex-end;
}
.bigVerticalProgress .graphContainer .progress .progress-bar {
    border-radius: 4px;
    width: 100%;
    justify-content: flex-end;
    padding-bottom: 0px;
    font-size: 16px;
    font-weight: 500;
}
.bigVerticalProgress .verticleProgress {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 97px;
}
.bigVerticalProgress .verticleProgress:not(:last-child) {
    margin-right: 43px;
}
.bigVerticalProgress .verticleProgress .caption {
    text-align: center;
}
.event {
    background: #ffffff;
    border: 1px solid #d2d2d2;
    border-radius: 6px;
    padding: 20px;
    margin-bottom: 14px;
}
.eventDetails {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: flex-start;
}
.eventDetails .datedetail > div:not(.time) {
    width: 80px;
    height: 80px;
    background: #51499c;
    border-radius: 6px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-right: 20px;
}
.eventDetails .datedetail.Fair > div:not(.time) {
    background: #0b3454;
}
.eventDetails .date {
    font-size: 35px;
    font-weight: 600;
    line-height: 30px;
    color: #fff;
}
.eventDetails .month {
    color: #fff;
    font-weight: 500;
    line-height: 16px;
}
.eventType .badge {
    padding: 0 20px;
    height: 25px;
    background: #0b3454;
    border-radius: 25px;
    line-height: 20px;
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
}
.eventType h2 {
    font-size: 18px;
    margin: 12px 0 10px;
}
.hostby {
    border-top: 1px solid #dedede;
    width: 100%;
    padding: 12px 0 0 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
}
.eventType {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 0 5% 0 0;
}
.hostNamePosition p {
    margin: 0;
}
.hostImg {
    margin-right: 14px;
}
.dashboardAccordion .hostNamePosition h3 {
    font-size: 16px;
}
.participants {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.participants label {
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
}
.participantsnumber {
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    background: #f6f6f6;
    border-radius: 6px;
    padding: 11px 7px;
    margin: 5px 0 20px;
}
.participantsnumber > div {
    width: 50%;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
}
.participantsnumber > div.participantsnumberuniversity {
    border-right: 1px solid #000;
}
.event a {
    color: #147ad6;
}
.event a:hover {
    color: #000;
}
.alumni_directory_details a {
    text-decoration: underline;
}
.alumni_directory_details a:hover {
    text-decoration: none;
}
.white-block {
    background: #ffffff;
    box-shadow: 0px 3px 6px #0000001a;
    border: 1px solid #d2d2d2;
    border-radius: 6px;
    opacity: 1;
    padding: 30px;
}
.white-block-notopborderradius {
    border-radius: 0 0 6px 6px;
    border-top: 0;
}
.alumni_directory_details ul.orderlist {
    list-style: none;
    padding: 0;
}
.alumni_directory_details ul.orderlist li:not(:last-child) {
    margin-bottom: 20px;
}
.alumni_directory_details ul.orderlist li {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
    position: relative;
    padding-left: 0px;
}
.alumni_directory_details ul.orderlist li .btn-border {
    margin: 0;
    color: #fff;
    text-decoration: none;
    max-width: 154px;
    width: 100%;
    font-size: 14px;
    height: 32px;
    padding: 0 15px;
    line-height: 32px;
    font-weight: 400;
}
.alumni_directory_details ul.orderlist li p {
    margin: 3px 0 0;
    font-weight: 500;
    color: #414141;
}
.alumni_directory_details ul.orderlist li:before {
    content: '';
    width: 3px;
    height: 20px;
    background: #5cae48;
    position: absolute;
    top: 6px;
    left: 0;
    display: none;
}
.alumni_directory_details ul.orderlist.arrow li:before {
    top: 3px;
}
.alumni_directory_details ul.orderlist.arrow li {
    margin-top: 28px;
}
.gray-radius-box {
    background: #fff;
    border: 2px solid #e8e8e8;
    border-radius: 6px;
    opacity: 1;
}
.mapChart .btn-border-gray[aria-expanded='true'] {
    display: none;
}
.alumni_directory_details ul.orderlist.arrow li a {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    color: #000;
    align-items: center;
}
.alumni_directory_details ul.orderlist.arrow li a i {
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}
.alumni_directory_details ul.orderlist.arrow li a:hover i {
    -moz-transform: translateX(3px);
    -webkit-transform: translateX(3px);
    -o-transform: translateX(3px);
    -ms-transform: translateX(3px);
    transform: translateX(3px);
}
.mapChart .accordion-item {
    border: 0;
}
.mapChart .accordion-body {
    padding: 0;
}
.mapChart .accordion-body .mapSection {
    padding: 250px 0;
    position: relative;
}
.mapChart .accordion-body .mapSection iframe {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}
.mapChart .bigVerticalProgress .graphContainer .progress .progress-bar {
    border-radius: 4px 4px 0 0 !important;
}
.mapChart .progress {
    border-radius: 0;
}
.map-Search {
    position: absolute;
    z-index: 1;
    top: 30px;
    left: 30px;
    max-width: 414px;
    width: 100%;
    height: 50px;
    background: #ffffff;
    box-shadow: 0px 0px 20px #0000001a;
    border-radius: 6px;
    opacity: 1;
}
.map-Search .btn-search {
    background: #0b3454;
    border-radius: 0px 6px 6px 0px;
    opacity: 1;
    color: #fff;
}
.presentCareer .bigVerticalProgress .graphContainer .progress {
    border-radius: 0;
}
.presentCareer .bigVerticalProgress .graphContainer .progress .progress-bar {
    border-radius: 0;
}
.presentCareer .bigVerticalProgress .graphContainer .progress .progress-bar:first-child {
    border-radius: 4px 4px 0 0;
}
.number-bg {
    width: 32px;
    height: 32px;
    background: #51499c;
    border-radius: 50%;
    text-align: center;
    font-size: 14px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}
.custom-border-gray {
    border-color: #dedede !important;
}
.custom-border-gray-848484 {
    border-color: #848484 !important;
}
.school_notification {
    padding: 0;
    margin: 0;
    list-style: none;
}
.school_notification li:not(:last-child) {
    border-bottom: 1px solid #dedede;
}
.school_notification li {
    padding: 30px 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
}
.school_notification li .detail {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.icon-checked {
    position: relative;
    width: 14px;
    height: 11px;
    display: inline-block;
}
.icon-checked::before,
.icon-checked::after {
    content: '';
    position: absolute;
    border-left: 2px solid #b1b1b1;
    border-bottom: 2px solid #b1b1b1;
    width: 12px;
    height: 6px;
    left: 0;
    top: 0;
    -moz-transform: rotate(-40deg);
    -webkit-transform: rotate(-40deg);
    -o-transform: rotate(-40deg);
    -ms-transform: rotate(-40deg);
    transform: rotate(-40deg);
}
.unread .icon-checked::before,
.unread .icon-checked::after {
    border-color: #26a7df;
}
.icon-checked::after {
    top: 5px;
}
.btn-close-small {
    width: 12px;
    height: 12px;
    padding: 0 !important;
    position: absolute;
    top: 25px;
    right: 25px;
    z-index: 9;
}
.input-otp {
    max-width: 80px;
    height: 60px;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #848484;
    margin: 0 5px;
}
.btn-edit {
    background: #ffffff;
    border: 1px solid #e1e1e1;
    border-radius: 27px;
    opacity: 1;
    padding: 0 15px;
    margin-left: 20px;
    font-size: 14px;
    line-height: 12px;
    height: 30px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
}
.profilePicSection {
    width: 200px;
    height: 200px;
    border: 1px dashed #707070;
    border-radius: 50%;
    margin: auto;
    padding: 14px;
}
.profileImg {
    background: #e3e3e3;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.profileImg.icon-user {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 145px;
    color: #fff;
}
.editProfilePicSection {
    overflow: hidden;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.editprofileImg:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 172px;
    height: 172px;
    border: 2px solid #0b3454;
    margin: auto;
    border-radius: 50%;
    box-shadow: 0 0 0 100px rgba(255, 255, 255, 0.5);
}
.small-thumb > .thumb + .thumb {
    margin-left: -30px;
}
.small-thumb {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}
.thumb {
    width: 60px;
    display: inline-block;
    height: 60px;
    background: #fff;
    border-radius: 50%;
    padding: 3px;
}
#GaugeMeter_1 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 113px;
    position: relative;
    flex-direction: column;
}
.percentage-score span {
    line-height: 20px !important;
    color: #000 !important;
    font-weight: 500;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    z-index: 2;
}
.percentage-score span::after {
    content: ' %';
    font-size: 14px;
    line-height: 14px;
    margin-left: 3px;
}
#GaugeMeter_1 canvas {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
}
.percentage-score b {
    line-height: 23px !important;
    position: relative;
    z-index: 2;
    font-size: 12px;
    font-weight: 500;
    font-style: italic;
}
.alumni_directory_details .custom-border {
    border-right: 1px solid #e8e8e8;
}
.slideRange {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 5px;
    border-radius: 5px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
}
.slideRange::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 14px;
    height: 14px;
    border: 0;
    background: #5cae48;
    cursor: pointer;
    border-radius: 50%;
}
.slideRange::-moz-range-thumb {
    width: 14px;
    height: 14px;
    border: 0;
    background: #5cae48;
    cursor: pointer;
}
.slideRange::-ms-thumb {
    width: 14px;
    height: 14px;
    border: 0;
    background: #5cae48;
    cursor: pointer;
    border-radius: 50%;
}
.slideRange::-webkit-range-progress {
    background-color: #5cae48;
    height: 5px;
    border-radius: 5px;
}
input[type='range'].slideRange::-moz-range-progress {
    background-color: #5cae48;
    height: 5px;
    border-radius: 5px;
}
input[type='range'].slideRange::-ms-fill-lower {
    background-color: #5cae48;
    height: 5px;
    border-radius: 5px;
}
.basicInformation {
    padding: 30px 20px 40px 0;
}
.basicInformation .phone_number {
    line-height: 18px;
}
.profile_container {
    background: #ffffff;
    box-shadow: 0px 3px 6px #0000001a;
    border: 1px solid #d2d2d2;
    border-radius: 6px;
    overflow: hidden;
}
.location-pin {
    background: url(https://ap-south-1.console.aws.amazon.com/s3/object/media.univariety.com?region=ap-south-1&bucketType=general&prefix=userreactapp/style-images/pointer.png)
        no-repeat 0 0;
    width: 12px;
    display: inline-block;
    height: 16px;
    margin-top: 9px;
    vertical-align: top;
    margin-right: 5px;
}
.btn:hover .location-pin {
    filter: invert(1);
    -webkit-filter: invert(1);
}
.contactrow .country-code {
    width: 85px;
    border-radius: 0.25rem 0 0 0.25rem;
    border-right: 0 !important;
}
.contactrow #PhoneNumber {
    width: calc(100% - 85px);
    border-radius: 0 0.25rem 0.25rem 0;
}
.contactrow {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
}
.profilenameclass .profilename {
    line-height: 24px;
}
.profilenameclass .class {
    line-height: 18px;
}
#profile_progress {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    width: 65px;
    margin-right: 14px;
}
#profile_progress img {
    border: 2px solid #fff;
    border-radius: 50%;
    width: 100%;
}
.profileLinks {
    display: none;
}
.profile_videos {
    height: 100%;
    background: #00000033;
    padding: 35px 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.profile_video_block {
    border: 4px solid #00000066;
    border-radius: 6px;
    max-width: 300px;
    width: 100%;
    position: relative;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    overflow: hidden;
}
.profile_video_block:hover {
    border-color: #010000;
}
.profile_video_number {
    font-size: 16px;
    line-height: 16px;
    margin-top: 15px;
    color: #fff;
}
.profile_video_block .video_thumb_title {
    text-align: center;
    position: absolute;
    bottom: -4px;
    background: #000000;
    border-radius: 6px 6px 0px 0px;
    height: 45px;
    width: 280px;
    margin: auto;
    left: 0;
    right: 0;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    line-height: 45px;
    -webkit-border-radius: 6px 6px 0px 0px;
    -moz-border-radius: 6px 6px 0px 0px;
    -ms-border-radius: 6px 6px 0px 0px;
    -o-border-radius: 6px 6px 0px 0px;
}
.profile_video_block:hover .video_thumb_title {
    color: #5cae48;
}
.profile_video_block .icon-invert-play {
    position: absolute;
    color: #000;
    font-size: 41px;
    width: 41px;
    height: 41px;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    background: #fff;
    border-radius: 50%;
    z-index: 9;
}
.profile_video_block:hover .icon-invert-play {
    color: #5cae48;
}
.profile_videos_empty {
    max-width: 282px;
    width: 90%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.profile_videos_empty p {
    margin: 0 0 30px;
    color: #fff;
    text-align: center;
    font-size: 15px;
    line-height: 18px;
}
.profile_videos_empty span {
    margin-bottom: 10px;
}
.profile_videos_empty .btn-white-border {
    margin: 0;
    height: 40px;
    font-weight: 500;
}
.video_thumb img {
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
}
.profile_video_block:hover img {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
}
.meter {
    background: #ffffff;
    border: 1px solid #dedede;
    border-radius: 18px;
    opacity: 1;
    padding: 5px 15px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    margin-right: 5px;
    letter-spacing: 0.32px;
}
.meter:hover {
    box-shadow: 0px 0px 20px #00000033;
}
.meter:not(:last-child) {
    margin-bottom: 5px;
}
.custom_breadcrumb {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    padding: 0 0;
    margin-bottom: 0;
    list-style: none;
}
.custom_breadcrumb-item {
    padding-right: 0.5rem;
}
.custom_breadcrumb-item:not(.active)::after {
    float: right;
    padding-left: 0.5rem;
    color: #d42941;
    content: var(--bs-breadcrumb-divider, '/');
}
.custom_breadcrumb-item.active {
    color: #000;
}
.search-icon {
    padding-left: 40px;
    background-image: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/search.svg);
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: 13px 13px;
}
.minus-marginx-30 {
    margin-left: -30px;
    margin-right: -30px;
}
.minus-paddingx-30 {
    padding-left: 30px;
    padding-right: 30px;
}
.padding-30 {
    padding: 30px;
}
.p-40 {
    padding: 40px;
}
.modal-content.copywriting {
    padding: 40px;
}
.copywritecontainer {
    overflow: auto;
    max-height: 565px;
    scrollbar-width: thin;
}
.copywriting .modal-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 25px;
    padding-bottom: 20px;
}
.copywriting .modal-header h5.modal-title {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 5px;
    line-height: 35px;
}
.copywriting .modal-header .sub-head {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 16px;
    color: #0b3454;
}
.copywriting .modal-header .sub-head span {
    position: relative;
}
.copywriting .modal-header .sub-head span:not(:first-child):before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 5px;
    height: 5px;
    background: #000;
    border-radius: 50%;
}
.copywriting .modal-header .sub-head span:not(:first-child) {
    margin-left: 15px;
    padding-left: 20px;
}
.btn-download {
    background: #ffffff;
    border: 1px solid #dedede;
    border-radius: 18px;
    opacity: 1;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Jost', sans-serif;
    padding: 0 20px;
    height: 32px;
    display: flex;
    align-items: center;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    color: #0b3454;
}
.btn-download:hover {
    background: #dedede;
}
.btn-download span {
    width: 13px;
    height: 13px;
    display: inline-block;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/download.svg) no-repeat 0 0;
    margin-right: 5px;
}
.copywritecontainer h4 {
    font-size: 16px;
    font-weight: 600;
}
.copywritecontainer p {
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 30px;
}
.copywritecontainer p:last-child {
    margin-bottom: 0px;
}
.copywritecontainer p a {
    color: #000;
}
.copywritecontainer p a:hover {
    color: #333;
    text-decoration: none;
}
.copywritecontainer ul {
    list-style: none;
    padding: 0;
    margin: 0 0 30px 19px;
}
.copywritecontainer ul li {
    position: relative;
    padding: 0 0 0 12px;
    font-size: 16px;
    line-height: 23px;
}
.copywritecontainer ul li:before {
    content: '';
    position: absolute;
    left: 0;
    top: 12px;
    width: 5px;
    height: 2px;
    background: black;
}
.modal-content.viewRequests {
    padding: 30px 40px;
}
.modal-content.viewRequests .modal-header h5.modal-title {
    font-size: 24px;
    font-weight: 600;
}
.modal-content.viewRequests .modal-header {
    border: 0;
    padding: 0;
}
.profileRow:not(:last-child) {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.profileRow {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between;
}
.profile_link {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    margin-top: 10px;
}
.profileLeftSide {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
}
.profileLeftSide .profileImg {
    width: 60px;
    height: 60px;
    margin-right: 10px;
}
.profileLeftSide .profileLeftSideDetail {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.profileLeftSide .profileLeftSideDetail h3 {
    font-size: 16px;
    margin-bottom: 0;
}
.profileLeftSide .profileLeftSideDetail h3 span {
    font-size: 14px;
    font-style: italic;
}
.profileLeftSide .profileLeftSideDetail .profileUniversity {
    font-size: 14px;
    color: #646464;
    font-style: italic;
    font-weight: 500;
}
.profileLeftSide .profileLeftSideDetail p {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
}
.profileLeftSide .profileLeftSideDetail .profileUniversity span {
    color: #000;
}
.profile_link .btn-border {
    width: 70px;
    margin: 0;
}
.alumni_college {
    background: #ffffff;
    border: 1px solid #d2d2d2;
    border-radius: 6px;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.alumni_college_yr {
    position: absolute;
    background: #0b3454;
    border-radius: 0px 6px 0px 15px;
    color: #fff;
    height: 25px;
    font-size: 14px;
    padding: 0 14px;
    top: -1px;
    right: -1px;
    line-height: 24px;
}
.alumni_college > div:not(.alumni_college_yr) {
    /* padding: 15px; */
}
.alumni_college_bottom {
    background: #f2f9ff;
    border-radius: 0px 0px 6px 6px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 5px 15px 15px;
}
.alumni_college_top {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
    padding: 15px 15px 8px;
}
.alumni_college_top p {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
}
.alumni_college_profileimg {
    margin-right: 20px;
}
.alumni_college_bottom label {
    color: #d52941;
    font-size: 13px;
    font-weight: 500;
}
.CollegeLabel {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.courseLabel {
    margin-right: 37px;
    max-width: 120px;
    width: 100%;
}
.alumni_college_bottom label + div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
}
.alumni_college_bottom label + div span {
    font-size: 16px;
    font-weight: 500;
}
.alumni_college_bottom label + div span.alumni_university {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin-right: 7px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.video_block,
.uni_profiles .video_block,
.underGraduate-detail .video_block {
    background: #ffffff;
    border: 1px solid #d2d2d2;
    border-radius: 6px;
    overflow: hidden;
    text-decoration: none;
    position: relative;
    display: inline-block;
    color: #000;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    padding: 0;
}
.icon-invert-play {
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.uni_profiles .video_block:hover,
.uni_profiles .video_block:hover .icon-invert-play,
.video_block:hover,
.video_block:hover .icon-invert-play {
    color: #5cae48;
    background: none;
}
.video_block_name {
    background: #ffffff;
    border-radius: 6px 6px 0px 0px;
    opacity: 1;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
    padding: 0px 20px;
    margin: -26px 11px 0;
    position: relative;
    align-items: center;
    height: 78px;
}
.uni_profiles .video_block:hover .video_block_img img,
.video_block:hover .video_block_img img {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
}
.uni_profiles .video_block_img {
    overflow: hidden;
    display: inline-block;
}
.uni_profiles .video_block_img img,
.video_block_img img {
    border-radius: 6px 6px 0 0;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
}
.uni_profiles .video_block_name .icon-invert-play,
.video_block_name .icon-invert-play {
    font-size: 30px;
    color: #0b3454;
}
.uni_profiles .video_block_name label,
.video_block_name label {
    font-size: 18px;
    font-weight: 600;
    margin-right: 15px;
    line-height: 22px;
}
.uni_profiles .video_block .video-btn,
.video_block .video-btn {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}
#video_pop button.btn-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #fff !important;
    opacity: 1;
    z-index: 999;
    background-size: 40%;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 0;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
#video_pop button.btn-close:hover {
    background-color: #5cae48 !important;
}
#video_pop button.btn-close span {
    display: none;
}
.profile-detail {
    padding: 0;
    margin: 10px 0 0;
    list-style: none;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
}
.profile-detail li {
    border-left: 4px solid #fff;
    padding-left: 7px;
}
.profile-detail li:not(:last-child) {
    margin-bottom: 20px;
}
.bullet ul {
    margin: 0;
    padding: 0;
    list-style: none;
    color: #fff;
}
.bullet ul li:not(:last-child) {
    margin-bottom: 20px;
}
.bullet ul li div.bullet_bg {
    display: flex;
    align-items: start;
}
.bullet ul li b {
    width: 120px;
    height: 41px;
    display: flex;
    align-items: center;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/bullet-bg.jpg);
    padding-left: 17px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    margin-right: 10px;
}
.bullet ul li {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
}
.bullet ul li div.bullet_brife {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
    justify-content: start;
}
.bullet ul li div span {
    width: auto;
    position: relative;
    line-height: 20px;
}
.bullet ul li div span.highlight {
    width: auto;
    position: relative;
}
.bullet ul li div span.highlight::after {
    content: '';
    position: absolute;
    right: 0;
    top: 3px;
    width: 16px;
    height: 25px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/subtraction.svg);
    filter: brightness(0) invert(1);
    display: none;
}
.bullet ul li div i.highlight {
    width: 16px;
    height: 25px;
    display: inline-block;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/subtraction.svg);
    margin-left: 5px;
    margin-top: 1px;
    filter: brightness(0) invert(1);
    vertical-align: top;
}
.skillnactivityDetail {
    max-width: 400px;
    width: 100%;
}
.skillnactivityDetail h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
}
.skillnactivityDetail > div {
    padding: 30px;
    background: transparent linear-gradient(180deg, #ffffff 0%, #f8f8f8 100%);
    border: 1px solid #e1e1e1;
    border-radius: 6px;
}
.skillnactivityDetail > div.skillDetail {
    margin-bottom: 20px;
}
.skillnactivityDetail h4 {
    color: #d42941;
    font-size: 14px;
    font-weight: 500;
    margin: 30px 0 5px;
}
.skillnactivityDetail .skillinterest {
    font-size: 16px;
    font-weight: 500;
}
.recentActivitycontainer {
    max-height: 325px;
    overflow-y: auto;
    scrollbar-width: thin;
}
.recentActivitycontainer::-webkit-scrollbar {
    width: 8px;
}
.recentActivitycontainer::-webkit-scrollbar-track {
    background: #f0f0f0;
}
.recentActivitycontainer::-webkit-scrollbar-thumb {
    background: #cdcdcd;
}
.recentActivitycontainer ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.recentActivitycontainer ul li {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
}
.recentActivitycontainer ul li:not(:last-child) {
    margin-bottom: 20px;
}
.recentActivitycontainer ul li .detail span {
    display: block;
    letter-spacing: 0.28px;
    color: #0b3454;
    font-size: 14px;
    line-height: 20px;
}
.recentActivitycontainer ul li .detail {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    font-size: 16px;
    line-height: 20px;
    padding-top: 4px;
}
.recentActivitycontainer ul li i {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0b3454;
    margin-right: 10px;
    background: #ffffff;
    border: 1px solid #0b34541a;
}
.year_section ul li > i {
    background: #d1f1ff;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    font-size: 19px;
    position: relative;
    z-index: 1;
}
#alumniProfileTab {
    border: 0;
    flex-wrap: nowrap;
    height: 44px;
}
#alumniProfileTab .nav-item:not(:last-child) {
    margin-right: 10px;
}
#alumniProfileTab .nav-item .nav-link {
    background: #ffffff;
    border: 2px solid #7d7d7d;
    border-radius: 20px;
    width: 180px;
    height: 40px;
    padding: 0;
    font-size: 16px;
    font-weight: 500;
    color: #414141;
}
#alumniProfileTab .nav-item .nav-link.active {
    background: #0b3454;
    border-color: #0b3454;
    color: #fff;
}
.yearNumber {
    position: relative;
}
.yearNumber label {
    background: #26a7df;
    border-radius: 6px;
    height: 32px;
    width: 90px;
    text-align: center;
    line-height: 32px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
}
.year_section ul {
    list-style: none;
    padding: 20px 0 50px 20px;
    margin: 0;
    position: relative;
}
.year_section ul::before {
    content: '';
    position: absolute;
    width: 2px;
    height: 50px;
    background: #d1f1ff;
    left: 43px;
    top: 0;
    z-index: 0;
}
.year_section:not(:last-child) ul::after {
    content: '';
    position: absolute;
    width: 2px;
    height: 50px;
    background: #d1f1ff;
    left: 43px;
    bottom: 0;
    z-index: 0;
}
.year_section ul li:not(:last-child) {
    margin-bottom: 30px;
}
.year_section ul li {
    display: flex;
    position: relative;
}
.year_section ul li::before {
    content: '';
    position: absolute;
    width: 2px;
    height: calc(100% + 30px);
    background: #d1f1ff;
    left: 23px;
    top: 0;
    z-index: 1;
}
.year_section ul li .brife {
    display: flex;
    align-items: start;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.year_section ul li .brife p {
    margin: 0;
    line-height: 23px;
    font-size: 16px;
}
.year_section ul li > i.icon-hat::before {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/hat.svg);
    width: 24px;
    height: 23px;
    background-size: 100%;
}
.year_section ul li .brife .offer {
    position: relative;
    padding-left: 40px;
}
.year_section ul li .brife .offer::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    margin: auto;
    left: 0;
}
.year_section:last-child ul li:last-child:before {
    opacity: 0;
}
.year_section ul li .brife .offer.joined::before {
    background: #5cae48;
}
.year_section ul li .brife .offer.dintjoined::before {
    background: #d52941;
}
.year_section ul li .brife .offer.joined {
    color: #5cae48;
}
.year_section ul li .brife .offer.dintjoined {
    color: #d52941;
}
.year_section ul li .brife .offer span {
    position: relative;
    background: #fff;
    padding-left: 7px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
}
.year_section ul li .brife .offer span i {
    font-size: 16px;
    margin-right: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.year_section ul li .brife .offer span i.icon-correct-invert {
    color: #5cae48;
}
.year_section ul li .brife .offer span i.icon-cross {
    width: 16px;
    height: 16px;
    background: #d42941;
    border-radius: 50%;
    font-size: 6px;
    color: #fff;
}
.achievementsBlocks {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
}
.achievementsBlocks .icon {
    width: 38px;
    margin-right: 14px;
}
.achievementsBlocksDetails {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.achievementsBlocksDetails h2 {
    font-size: 18px;
    font-weight: 500;
    margin: 11px 0 25px;
}
.achievementsBlocks:not(:last-child) {
    padding-bottom: 30px;
    margin-bottom: 35px;
    border-bottom: 2px solid #e1e1e1;
}
.achievementsBlocksDetails h2 a {
    color: #147ad6;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.28px;
    text-decoration: underline;
}
.achievementsBlocksDetails h2 a:hover {
    text-decoration: none;
    color: #0b3454;
}
.achievementsBlocksDetailsection .blockrow:not(:last-child) {
    margin-bottom: 21px;
}
.achievementsBlocksDetailsection .blockrow {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
}
.achievementsBlocksDetailsection .blockrow label {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    width: 130px;
}
.achievementsBlocksDetailsection .blockrow .colon {
    margin: 0 30px;
    line-height: 16px;
}
.achievementsBlocksDetailsection .blockrow .brife {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: 0.32px;
    max-width: 305px;
}
.achievementsBlocksDetailsection .blockrow .brife a {
    text-decoration: underline;
    color: #147ad6;
}
.achievementsBlocksDetailsection .blockrow .brife a:hover {
    text-decoration: none;
    color: #0b3454;
}
.contributionsSection > div:not(:last-child) {
    margin-bottom: 40px;
}
.alumnislider .video_block_name label {
    font-size: 16px;
}
.alumnislider .album_contblk h3 {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}
.contributionsSection > div:not(:last-child) {
    margin-bottom: 40px;
}
.seactionheader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    flex-wrap: wrap;
}
.countnbtn {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.seactionheader h3 {
    font-size: 24px;
    margin: 0;
}
.countnbtn .count {
    margin-right: 20px;
    font-style: italic;
    font-size: 14px;
    color: #0b3454;
}
.custom-btns a {
    display: inline-block;
    color: #0b3454;
    text-decoration: none;
}
.custom-btns a:hover {
    color: #d42941;
}
.jobsandInternshipslist .act_lst > .row.postedrow > div:last-child,
.jobsandInternshipslist .act_lst.actlst_listed > .row > div:last-child {
    width: auto;
}
.nolisting {
    background: #fff;
    border: 1px solid #d2d2d2;
    border-radius: 6px;
    padding: 30px;
    text-align: center;
}
.selected_univarsity {
    background: #f8f8f8;
    padding: 20px;
    border-radius: 7px;
    position: relative;
}
.selected-form-label {
    background: #ffffff;
    border-radius: 7px;
    width: 100%;
    height: 50px;
    padding: 0 20px;
    font-size: 16px;
    display: flex;
    align-items: center;
    font-weight: 600;
}
.selected_univarsity .btn-cross {
    position: absolute;
    top: 22px;
    right: -20px;
    font-size: 8px;
    color: #0b345480;
}
.dashboard.innerpage {
    display: flex;
    align-items: center;
}
.dashboard.innerpage .label {
    margin-right: 15px;
    font-weight: 500;
}
.uni_connect_sec {
    border: 1px solid #d2d2d2;
    border-radius: 6px;
    padding: 10px 20px 10px 10px;
}
.logo-icon {
    width: 50px;
    height: 50px;
    border: 1px solid #e3e2e2;
    border-radius: 50%;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.selected-prodile-invite > div {
    background: #fff;
    box-shadow: 0px 1px 1px #f1f1f1;
    border: 1px solid #dedede;
    border-radius: 7px;
    padding: 10px 20px;
    margin-top: 5px;
    position: relative;
}
.selected-prodile-invite p.font-16 {
    line-height: 16px;
}
.selected-prodile-invite p.font-14 {
    line-height: 14px;
}
.selected-prodile-invite a {
    text-decoration: none;
    color: #000;
}
.selected-prodile-invite a:hover {
    color: #5cae48;
}
.selected-prodile-invite a[class*='icon'] {
    color: #535353;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 15px;
    height: 16px;
    width: 16px;
}
.selected-prodile-invite a[class*='icon']:hover {
    color: #000;
}

@media (min-width: 992px) {
    body::before {
        position: fixed;
        top: 0;
        bottom: auto;
        right: 0;
        left: 0;
        margin: auto;
        content: '';
        background: rgba(0, 0, 0, 0.8);
        width: 100%;
        height: 0;
        opacity: 0;
        z-index: 1;
        transition: unset;
    }
    .mouseHover::before {
        height: 100%;
        opacity: 1;
        z-index: 1;
        transition: unset;
    }
    body.mouseHover::before {
        z-index: 9;
    }
    nav.custom-nav.offcanvas.offcanvas-start {
        background: #0b3454;
        height: 70px;
        position: static;
        visibility: visible !important;
        transform: translateX(0);
        width: 100%;
        padding: 0;
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
    }
    nav.custom-nav.offcanvas.offcanvas-start ~ .offcanvas-backdrop.show {
        display: none;
    }
    .custom-nav > ul {
        max-width: 1155px;
        margin: 0 auto;
        position: relative;
        z-index: 2;
        width: 94%;
    }
    .custom-nav > ul > li > a.nav-link {
        color: #fff;
        position: relative;
        z-index: -1;
    }
    .custom-nav > ul li.nav-item a.nav-link::before {
        content: '';
        width: 0;
        height: 5px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: #5cae48;
        margin: auto;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }
    .custom-nav > ul li.nav-item:hover a.nav-link::before,
    .custom-nav > ul li.nav-item.active a.nav-link::before {
        width: 100%;
    }
    .custom-nav > ul li.nav-item a.nav-link {
        color: #fff;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 500;
        line-height: 54px;
    }
    .custom-nav > ul li.nav-item:hover a.nav-link {
        color: #26a7df;
    }
    .custom-nav > ul > li > ul {
        position: absolute;
        background: #ffffff;
        box-shadow: 0px 3px 6px #0000001a;
        border: 1px solid #d2d2d2;
        border-radius: 6px;
        padding: 20px 30px;
        width: max-content;
        list-style: none;
        left: -20px;
    }
    .custom-nav > ul > li > ul {
        display: block !important;
        z-index: -1;
        opacity: 0;
        top: 100px;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }
    .custom-nav > ul > li:hover > ul {
        top: 55px;
        opacity: 1;
    }
    .custom-nav > ul > li {
        overflow: hidden;
        margin: 0;
    }
    .custom-nav > ul > li:hover {
        overflow: visible;
    }
    .custom-nav > ul > li:last-child > ul {
        right: -10px;
        left: auto;
    }
    .custom-nav > ul > li:hover > ul > li:not(:last-child) {
        margin-bottom: 8px;
    }
    .custom-nav > ul > li:hover > ul > li > a {
        color: #000;
        text-decoration: none;
        font-size: 16px;
        font-weight: 500;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        background: none;
    }
    .custom-nav > ul > li:hover > ul > li > a:hover,
    .custom-nav > ul > li:hover > ul > li > ul li a:hover {
        color: #26a7df;
    }
    .custom-nav > ul > li:hover > ul > li > a.gray-effect {
        color: #8c8c8c;
        cursor: default;
    }
    .custom-nav > ul > li:hover > ul > li > ul {
        display: block;
        position: absolute;
        left: 80%;
        top: -16px;
        width: 250px;
        padding: 0;
        z-index: -1;
        transition: all 0.3s ease-in-out;
        opacity: 0;
        background: #ffffff;
        box-shadow: 0px 3px 6px #0000001a;
        border: 1px solid #d2d2d2;
        border-radius: 6px;
        padding: 10px 20px;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
    }
    .custom-nav > ul > li:hover > ul > li {
        position: relative;
    }
    .custom-nav > ul > li:hover > ul > li:hover > ul {
        left: 105%;
        opacity: 1;
    }
    .custom-nav > ul > li:hover > ul > li > ul > li > a {
        padding: 5px 15px;
        display: block;
        color: #000;
        text-decoration: none;
        font-size: 16px;
        font-weight: 500;
    }
    .custom-nav > ul > li:hover > ul > li > ul li {
        list-style: none;
    }
    .custom-nav > ul > li:hover > ul > li > a .icon.icon-arrow-right {
        border-top: 2px solid #000;
        border-right: 2px solid #000;
        width: 10px;
        height: 10px;
        -moz-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        margin-right: 2px;
    }
    .custom-nav > ul > li:hover > ul > li:hover {
        overflow: visible;
    }
}

@media (max-width: 1199px) {
    .search {
        width: 30%;
    }
    .skillnactivityDetail {
        max-width: 305px;
        width: 100%;
    }
}

@media (max-width: 1140px) {
    .onboardingLaunchGraph ul li div,
    .triangleGraph ul li div {
        width: 65%;
    }
}

@media (max-width: 991px) {
    .mobileonly {
        display: block;
    }
    .custom-nav {
        width: 300px !important;
        background: #0b3454;
        padding: 0;
        overflow-y: auto;
    }
    .manager_profile .profileSection {
        display: none;
    }
    .logo {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .logo .btn-offcanvas {
        border: 0;
        background: none;
        font-size: 0;
        margin-right: 15px;
    }
    .btn-offcanvas .navbar-toggler-icon::before {
        font-size: 0;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        background: none;
        border: 0;
        content: '';
        width: 100%;
        height: 3px;
        background: #000;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }
    .btn-offcanvas .navbar-toggler-icon::after {
        font-size: 0;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        background: none;
        border: 0;
        content: '';
        width: 70%;
        height: 3px;
        background: #000;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }
    .btn-offcanvas .navbar-toggler-icon {
        width: 20px;
        height: 20px;
        justify-content: space-between;
        flex-direction: column;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
    }
    .btn-offcanvas .navbar-toggler-icon b {
        height: 3px;
        width: 85%;
        background: #000;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }
    body[style*='overflow:hidden'] .btn-offcanvas .navbar-toggler-icon b {
        -moz-transform: scale(0);
        -webkit-transform: scale(0);
        -o-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
    }
    body[style*='overflow:hidden'] .btn-offcanvas span.navbar-toggler-icon::before {
        transform: translateY(10px) rotate(135deg);
        -moz-transform: translateY(10px) rotate(135deg);
        -webkit-transform: translateY(10px) rotate(135deg);
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        width: 100%;
        height: 3px !important;
    }
    body[style*='overflow:hidden'] .btn-offcanvas span.navbar-toggler-icon::after {
        transform: translateY(-7px) rotate(-135deg);
        -moz-transform: translateY(-7px) rotate(-135deg);
        -webkit-transform: translateY(-7px) rotate(-135deg);
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        width: 100%;
        height: 3px !important;
    }
     header .offcanvas ~ .modal{z-index: 99999;}
    #custom-nav {
        margin: 0 !important;
        padding: 10px 0 0;
        width: 100%;
    }
    #custom-nav > li.mobileonly {
        background: #fff;
        padding: 0;
        margin-bottom: 15px;
    }
    #custom-nav > li {
        padding: 0 20px;
    }
    #custom-nav > li .nav-link {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        justify-content: space-between;
        color: #fff;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
    }
    #custom-nav > li .nav-link .icon {
        display: none;
    }
    #custom-nav > li.mobileonly ul {
        padding: 0;
        list-style: none;
        margin-top: 0px;
    }
    #custom-nav > li.mobileonly ul li a {
        width: 100%;
        padding: 10px 0 10px 33px;
        font-size: 16px;
        color: #000;
        font-weight: 500;
        text-decoration: none;
        display: block;
        height: auto;
        background-position: center left;
        cursor: pointer;
    }
    #custom-nav > li.mobileonly ul li:not(:first-child) {
        border-top: 1px solid #e3e3e3;
    }
    #custom-nav > li.mobileonly .profileSection {
        margin-bottom: 17px;
    }
    #custom-nav > li.mobileonly .btn-close {
        margin: 0;
        opacity: 1;
        position: absolute;
        right: 12px;
        top: 8px;
        background-size: 12px auto;
    }
    #custom-nav > li .nav-link.nav-invite {
        justify-content: start;
        background: #5cae48;
        border-radius: 25px;
        padding: 10px 15px 10px 10px;
        width: auto;
    }
    #custom-nav > li .nav-link.nav-invite:hover {
        color: #ffff;
        background: #408e2d;
    }
    .headerRightSide > ul > li:not(:last-child) {
        margin-right: 30px;
        display: none;
    }
    .search {
        max-width: 275px;
        padding: 0;
        background: #f1f1f1;
        border-radius: 20px;
        height: 40px;
        margin-left: auto;
        margin-right: 30px;
        width: 100%;
    }
    #custom-nav > li > ul {
        list-style: none;
        padding: 0 0 0 10px;
    }
    #custom-nav > li > ul > li a {
        font-size: 16px;
        color: #fff;
        text-decoration: none;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    #custom-nav > li > ul > li a.gray-effect {
        cursor: default;
        color: #335168;
    }
    #custom-nav li a {
        width: 100%;
        display: block;
    }
    #custom-nav > li > ul > li:not(:last-child) {
        margin-bottom: 0px;
    }
    #custom-nav > li .nav-link:hover {
        color: #5cae48;
    }
    #custom-nav > li > ul > li a:hover {
        color: #5cae48;
    }
    #custom-nav > li > ul > li ul {
        list-style: none;
        padding: 0 0 0 15px;
    }
    .custom-nav > ul > li > ul > li > a .icon.icon-arrow-right {
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
        width: 8px;
        height: 8px;
        -moz-transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        -ms-transform: rotate(135deg);
        transform: rotate(135deg);
        margin-right: 2px;
    }
    .sideBar {
        width: 100%;
        overflow-y: auto;
    }
    .sideBar::-webkit-scrollbar {
        display: none;
    }
    .sideBar {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    .mainContainer {
        background: none;
        flex-direction: column;
    }
    .sideBar ul {
        width: auto;
        border-right: 0;
        padding: 11px 0 0;
        background: none;
        height: 70px;
        box-shadow: none;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
    }
    .sideBar ul li {
        display: inline-block;
    }
    .main:before {
        width: 100%;
        height: 70px;
        box-shadow: 8px 10px 10px 0px #e8e8e8;
        border-bottom: 1px solid #dbdbdb;
    }
    .pageContent {
        padding: 0;
    }
    .sideBar ul li a > b {
        width: 45px;
        height: 45px;
    }
    .sideBar ul li a span {
        width: auto;
    }
    .sideBar ul:hover {
        width: auto;
    }
    .sideBar ul li a span b {
        width: auto;
        white-space: nowrap;
    }
    .sideBar ul li:not(:last-child) {
        margin-right: 30px;
    }
    .sideBar ul:hover li a span {
        width: auto;
    }
    .footer {
        padding: 20px 0;
    }
    .onboardingLaunchGraph ul li label,
    .triangleGraph ul li label {
        font-size: 14px;
    }
    .onboardingLaunchGraph ul li div span::before,
    .triangleGraph ul li div span::before {
        border-width: 17px 10px 17px 10px;
    }
    .onboardingLaunchGraph ul li div span::after,
    .triangleGraph ul li div span::after {
        border-width: 17px 10px 17px 10px;
    }
    .onboardingLaunchGraph ul li div span,
    .triangleGraph ul li div span {
        font-size: 12px;
    }
    .onboardingLaunchGraph ul li div,
    .triangleGraph ul li div {
        width: 65%;
        height: 35px;
    }
    .dashboardAccordion .accordion-item .accordion-body {
        padding: 0 13px 20px;
    }
    #dashboard-pills-tab {
        flex-wrap: inherit;
        overflow: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    #dashboard-pills-tab::-webkit-scrollbar {
        display: none;
    }
    .alumni_directory_details .bigVerticalProgress {
        max-width: 990px;
        width: max-content;
    }
    .alumni_directory_details .bigVerticalProgress .verticleProgress {
        margin: 0 1% 20px;
    }
    .alumni_directory_details .tablesection > .table > .table {
        max-width: 990px;
        width: 990px;
    }
    .icn_details > .flex-1 {
        float: left;
        margin: 0 0 15px;
        width: calc(100% - 70px);
    }
    .input-otp {
        max-width: 50px;
    }
}

@media (max-width: 800px) {
    .sticky-notification .notification-detail {
        flex-direction: column;
        align-items: flex-start !important;
        padding-left: 15px;
        justify-content: center;
    }
    .sticky-notification .notification-detail p {
        max-width: 100%;
        margin-bottom: 10px;
    }
    .sticky-notification {
        height: 130px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .dashboardAccordion .accordion-item .accordion-button {
        padding: 20px 13px;
    }
    .dashboardAccordion .accordion-item .accordion-button span {
        margin: 0 10px;
    }
    .participantsnumber > div {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
    }
    .courseLabel {
        margin-right: 0;
    }
    .alumni_college_bottom {
        flex-direction: column;
    }
    .btn .btn-green {
        line-height: 14px;
    }
}

@media (min-width: 768px) and (max-width: 890px) {
    .achievementsBlocksDetailsection .blockrow {
        flex-direction: column;
    }
    .achievementsBlocksDetailsection .blockrow .colon {
        display: none;
    }
    .achievementsBlocksDetailsection .blockrow label::after {
        content: ' :';
    }
    .achievementsBlocksDetailsection .blockrow label {
        width: 100%;
    }
}

@media (max-width: 767px) {
    .btn-search-toggle {
        font-size: 13px;
        padding: 0;
        width: 40px;
        height: 40px;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        color: #0b3454;
    }
    .search {
        position: absolute;
        right: 50px;
        top: 24px;
        width: 40px;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }
    .search form,
    .search > div {
        display: none !important;
    }
    .search.open {
        width: 260px;
        border: 1px solid #c8c8c8;
    }
    .search.open form,
    .search.open > div {
        display: -webkit-box !important;
        display: -moz-box !important;
        display: -ms-flexbox !important;
        display: -webkit-flex !important;
        display: flex !important;
    }
    .search.open .btn-search-toggle {
        display: none;
    }
    .dashboard {
        position: fixed;
        z-index: 9;
        height: 80px;
        background: #fff;
        bottom: 0;
        right: 0;
        width: 100%;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 4px 7px #92929259;
        border-top: 1px solid #dbdbdb;
    }
    .dashboard .booksessionEnroll {
        margin: 5px 0 0;
    }
    .dashboard.smart-scroll.scrolled-down {
        transition: all 0.3s ease-in-out;
        bottom: 0;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
    }
    .dashboard.smart-scroll.scrolled-up {
        bottom: 0;
        transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
    }
    .dashboard.innerpage {
        padding: 0 15px;
        justify-content: center;
    }
    .footer {
        flex-direction: column;
        text-align: center;
        padding: 20px;
        margin: 0 0;
    }
    .powerby {
        margin-top: 15px;
    }
    .powerby img {
        max-width: 139px;
        margin: auto;
    }
    .welcome-title h1,
    .welcome-title p {
        text-align: left;
    }
    .highlight-text {
        margin: 20px 0;
    }
    .blue-container {
        max-width: initial;
        margin: 0 -13px 0;
        width: auto;
        border-radius: 0 0 5px 5px;
        padding: 25px !important;
    }
    .dashboardAccordion .accordion-item .accordion-body {
        padding: 0 13px 0px;
    }
    .dashboardAccordion .accordion-item {
        box-shadow: 0px 3px 6px #0000001a;
    }
    .dashboardAccordion .accordion-item .accordion-button {
        padding: 20px 13px 20px;
    }
    .dashboardAccordion .accordion-item .accordion-button[aria-expanded='true'] {
        padding: 20px 13px 5px !important;
    }
    .bigVerticalProgress {
        margin: 0 -1%;
        justify-content: flex-start;
    }
    .bigVerticalProgress .verticleProgress {
        width: 18%;
        margin: 0 1% 20px;
    }
    .participants {
        align-items: flex-start;
    }
    .participants label {
        text-align: left;
    }
    #pills-student .accordion-item .accordion-body > .row > div.col-md-6:first-child {
        margin-bottom: 25px;
    }
    #pills-student .accordion-item .accordion-body > .row.pt-3 {
        padding-top: 0 !important;
    }
    .school_notification li .check {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        margin-top: 15px;
    }
    .school_notification li .check span {
        margin-right: 5px;
    }
    .alumni_directory_details .custom-border {
        border-right: 0;
        border-bottom: 1px solid #e8e8e8;
        padding-bottom: 25px;
        margin-bottom: 20px;
    }
    .profileRow {
        flex-direction: column;
    }
    .courseLabel {
        margin-right: 0;
    }
    .alumni_college_bottom {
        flex-direction: column;
    }
    .profile_videos {
        margin: 0 -19px;
    }
    .skillnactivityDetail {
        max-width: 100%;
    }
}

@media (max-width: 575px) {
    .search {
        right: 38px;
    }
    .overlaystick.aos-animate {
        position: fixed;
        left: 0;
        bottom: 0;
        right: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.7);
        z-index: 9;
        display: flex;
        align-items: flex-end;
    }
    .overlaystick.aos-animate .sticky-notification {
        border-radius: 60px 60px 0 0 / 60px 60px 0 0;
        height: 231px;
        left: 0;
        right: 0;
        margin: 0;
        width: 100%;
    }
    .overlaystick.aos-animate .sticky-notification > .imgplace {
        display: none;
    }
    .overlaystick.aos-animate .sticky-notification .notification-detail {
        padding: 20px 20px 0;
        justify-content: flex-end;
    }
    .overlaystick.aos-animate .sticky-notification .notification-detail p {
        text-align: center;
        max-width: 100%;
        margin: 0 auto 20px;
    }
    .imglink {
        max-width: 100%;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        justify-content: space-around;
    }
    .imglink .imgplace {
        display: block;
    }
    .sticky-notification .notification-detail .links {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    .sticky-notification .notification-detail .links .btn-green {
        margin-bottom: 13px;
    }
    .sticky-notification .notification-detail .links a {
        max-width: 190px;
        width: 100%;
    }
    .overlaystick.aos-animate .sticky-notification .btn-close {
        left: 0;
        right: 0;
        width: 40px;
        height: 40px;
        margin: auto;
        border: 2px solid #fff;
        border-radius: 50%;
        background: none;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        top: -75px;
    }
    .dashboardAccordion .accordion-item .accordion-button {
        padding: 20px 13px;
    }
    .dashboardAccordion .accordion-item .accordion-button span {
        margin: 0 10px;
    }
    .onboardingLaunchGraph ul li label,
    .onboardingLaunchGraph ul li label {
        font-size: 14px;
    }
    .onboardingLaunchGraph ul li div span::before,
    .onboardingLaunchGraph ul li div span::before {
        border-width: 17px 10px 17px 10px;
    }
    .onboardingLaunchGraph ul li div span::after {
        border-width: 17px 10px 17px 10px;
    }
    .onboardingLaunchGraph ul li div span {
        font-size: 12px;
    }
    .onboardingLaunchGraph ul li div {
        width: 65%;
        height: 35px;
    }
    .dashboardAccordion .accordion-item .accordion-body {
        padding: 0 13px;
    }
    .milestoneProgressGraph {
        justify-content: center;
        margin: 0 -5px;
    }
    .milestoneGraph {
        width: 29%;
        margin: 0px 5px 30px;
    }
    .bigVerticalProgress .verticleProgress {
        width: 23%;
    }
    .white-block {
        padding: 15px;
    }
    .profile_radiorow > .icon-cross {
        right: -18px;
        padding: 5px;
    }
    .btn-white-border {
        padding: 0 15px;
    }
    .courseLabel {
        margin-right: 20px;
    }
    .alumni_college_bottom {
        flex-direction: row;
    }
    .achievementsBlocksDetailsection .blockrow {
        flex-direction: column;
    }
    .achievementsBlocksDetailsection .blockrow .colon {
        display: none;
    }
    .achievementsBlocksDetailsection .blockrow label::after {
        content: ' :';
    }
    .achievementsBlocksDetailsection .blockrow label {
        width: 100%;
    }
    .selected_univarsity .btn-cross {
        top: 8px;
        right: 8px;
    }
}

@media (max-width: 480px) {
    .sticky-notification {
        height: 210px;
        flex-direction: row-reverse;
    }
    .sticky-notification .notification-detail .links {
        flex-direction: column;
        align-items: flex-start;
    }
    .sticky-notification .notification-detail .links .btn-green {
        margin-bottom: 15px;
    }
    .eventDetails {
        flex-direction: column;
        align-items: center;
    }
    .eventType {
        flex: 0;
        width: 100%;
        flex-basis: 100%;
        margin: 20px 0;
        padding: 0;
        flex-direction: column;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        align-items: center;
    }
    .eventDetails .datedetail {
        margin: 0;
    }
    .hostby {
        flex-direction: column;
        padding-top: 20px;
    }
    .hostImg {
        margin: 0 0 14px;
    }
    .hostNamePosition {
        text-align: center;
    }
    .participants {
        align-items: center;
    }
    .eventType h2 {
        text-align: center;
        margin-bottom: 20px;
    }
}

.overlaystick.hide {
    display: none;
}
.btn-verify {
    background: linear-gradient(to right, #185584 50%, #0d3453 50%);
    color: #fff;
    width: 140px;
    height: 40px;
    line-height: 38px;
    padding: 0 25px;
    text-decoration: none;
    border-radius: 25px;
    text-align: center;
    background-size: 200% 100%;
    transition: all 0.5s;
    background-position: right bottom;
    border: 1px solid #0d3453;
    font-size: 16px;
    font-weight: 400;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
}
.btn-border {
    background: linear-gradient(to right, #185584 50%, #0d3453 50%);
    color: #fff;
    width: max-content;
    height: 40px;
    line-height: 38px;
    padding: 0 25px;
    text-decoration: none;
    border-radius: 25px;
    text-align: center;
    background-size: 200% 100%;
    transition: all 0.5s;
    background-position: right bottom;
    border: 1px solid #0d3453;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
}
.btn-border:hover,
.btn-verify:hover {
    background-position: left bottom;
    color: #fff;
    background-size: 201% 100%;
}
.icn_details .btn-green {
    width: 140px;
}
.icn_details .btn-green span {
    font-weight: 500;
    font-size: 16px;
}
.icn_details .btn-green span.icon-correct::before {
    font-size: 13px;
}
a.dashb_arr {
    width: 40px;
    height: 40px;
    background: #f1f1f1;
    border-radius: 50%;
    position: relative;
    display: inline-block;
}
a.dashb_left::before {
    content: '';
    width: 8px;
    height: 8px;
    background: transparent;
    display: inline-block;
    position: absolute;
    left: 17px;
    top: 0;
    bottom: 0;
    right: auto;
    margin: auto;
    border-top: 1.5px solid #000;
    border-right: 1.5px solid #000;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
}
a.dashb_right::before {
    content: '';
    width: 8px;
    height: 8px;
    background: transparent;
    display: inline-block;
    position: absolute;
    left: 15px;
    top: 0;
    bottom: 0;
    right: auto;
    margin: auto;
    border-top: 1.5px solid #000;
    border-right: 1.5px solid #000;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
}
.iconsec {
    height: 50px;
    width: 50px;
    background: #d42941;
    border-radius: 50%;
}
.iconsec > b {
    color: #fff;
    font-size: 23px;
}
.icn_details {
    background: #ffffff;
    box-shadow: 0px 3px 6px #0000001a;
    border: 1px solid #d2d2d2;
    border-radius: 6px;
    padding: 25px 30px;
}
.iconsec_title {
    font-weight: 500;
    font-size: 18px;
    color: #000;
    margin: 0 0 8px;
}
.iconsec_cont {
    font-weight: 400;
    font-size: 16px;
    color: #000;
    display: none;
    margin: 0 0 8px;
}
.iconsec_link {
    text-decoration: underline;
    color: #147ad6;
    font-size: 14px;
}
.iconsec_link:hover {
    text-decoration: none;
}
.iconsec_link .readmore {
    display: block;
}
.iconsec_link .readless {
    display: none;
}
.iconsec_link.active .readmore {
    display: none;
}
.iconsec_link.active .readless {
    display: block;
}
.profile_setup {
    width: 100%;
    max-width: 682px;
}
.profile_setup_help {
    width: 100%;
    max-width: 304px;
    height: auto;
    background: transparent linear-gradient(180deg, #ffffff 0%, #f8f8f8 100%) 0% 0% no-repeat padding-box;
    border: 1px solid #e1e1e1;
    border-radius: 6px;
    padding: 30px;
    text-align: center;
}
.profile_setup > div:not(:last-child) {
    margin: 0 0 15px;
}
.container.my-5.profile_setup_cont {
    max-width: 100%;
}
.profile_setup_help > p:first-child {
    width: 100%;
    margin: auto;
    text-align: center;
    max-width: 190px;
}
.custom-nav > ul li.nav-item a.nav-link.dropdown-toggle::after {
    margin-left: 17px;
    vertical-align: middle;
    border-top: 7px solid;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
}
.activities_status_hovercont ol {
    margin: 0 0 0 20px;
    padding: 0 10px 0 0;
    text-align: left;
}
.activities_status_hovercont ol li {
    line-height: 18px;
}
.activities_status_hovercont ol li:not(:last-child) {
    margin: 0 0 8px 0;
}
.activities_status_hovercont {
    background: #fff;
    border: 1px solid #d2d2d2;
    border-radius: 6px;
    padding: 20px;
    width: 270px;
    height: 165px;
    position: absolute;
    top: -30px;
    left: 0;
    right: 0;
    margin: auto;
    box-shadow: 0px 3px 6px #0000001a;
    display: none;
}
.activities_status_hovercont::after {
    position: absolute;
    content: '';
    width: 23px;
    height: 18px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/hover_after.png) left top no-repeat;
    bottom: -18px;
}
.activities_status_hovercont > div {
    height: 125px;
    overflow-y: scroll;
    scrollbar-width: thin;
    font-size: 14px;
    font-weight: 400;
}
.activities_status:hover .activities_status_hovercont {
    display: block;
    color: #000;
}
.activities_status:hover {
    color: #26a7df;
}
.activities_status_hovercont > div::-webkit-scrollbar {
    scrollbar-width: thin;
}
.profile_setup > div.icn_details:first-child .iconsec_cont {
    display: block;
}
.dashboardAccordion .accordion-item .accordion-button:hover {
    box-shadow: 0px 0px 5px #0000001a;
    background: #fafafa;
    border-radius: 5px;
}
.dashboardAccordion .accordion-item .accordion-button[aria-expanded='true']:hover {
    box-shadow: unset;
    background: transparent;
    border-radius: 5px;
}
.float_horizontal_animate {
    transform: translatex(0px);
    animation: float_horizontal 4s ease-in-out infinite;
    -webkit-animation: float_horizontal 4s ease-in-out infinite;
    -webkit-transform: translatex(0px);
    -moz-transform: translatex(0px);
    -ms-transform: translatex(0px);
    -o-transform: translatex(0px);
}

@keyframes float_horizontal {
    0% {
        transform: translatex(0px);
        -webkit-transform: translatex(0px);
        -moz-transform: translatex(0px);
        -ms-transform: translatex(0px);
        -o-transform: translatex(0px);
    }
    50% {
        transform: translatex(-10px);
        -webkit-transform: translatex(-10px);
        -moz-transform: translatex(-10px);
        -ms-transform: translatex(-10px);
        -o-transform: translatex(-10px);
    }
    100% {
        transform: translatex(0px);
        -webkit-transform: translatex(0px);
        -moz-transform: translatex(0px);
        -ms-transform: translatex(0px);
        -o-transform: translatex(0px);
    }
}

@media (max-width: 1080px) {
    .profile_setup_cont .profile_setup_help {
        width: 274px;
    }
    .profile_setup_cont .profile_setup {
        width: calc(100% - 300px);
    }
}

@media (max-width: 767px) {
    .icn_details {
        display: block !important;
    }
    .iconsec {
        float: left;
    }
    .icn_details > .flex-1 {
        float: left;
        margin: 0 0 15px;
        width: calc(100% - 70px);
    }
    .btn-verify,
    .icn_details .btn-green {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        clear: both;
        margin: 0 auto 0 !important;
        float: none;
        text-align: center;
        justify-content: center;
    }
    .profile_setup_cont .row > .profile_setup {
        width: 100%;
        margin: 0 0 30px 0;
    }
    .profile_setup_cont .row > .profile_setup_help {
        width: 100%;
        border-radius: 0;
        border-left: 0 solid #fff;
        border-right: 0 solid #fff;
        border-bottom: 0 solid #fff;
    }
    .max-1140.profile_container {
        width: 100%;
    }
    .profile_container .sideBar {
        width: 94%;
        margin: auto;
    }
    .profile_container .pageContent > div:first-child {
        width: 94%;
        margin: auto;
        padding-top: 35px !important;
    }
    .profile_container .pageContent > div:first-child .welcome-title {
        width: 100%;
    }
    .profile_container .container.profile_setup_cont {
        width: 100%;
        margin: 25px auto 25px !important;
    }
    .profile_setup_cont .row > .profile_setup {
        width: 94%;
        margin: 0 auto 30px auto;
    }
    .profile_container .footer {
        margin: 0;
    }
    .dashboard .btn-green {
        order: 1;
        margin: 0 15px;
    }
    .dashboard .dashb_left {
        order: 0;
        margin: 0 !important;
    }
    .dashboard .dashb_right {
        order: 2;
    }
    .profile_container .welcome-title h1 {
        font-size: 30px;
    }
    .profile_setup_help > p:first-child {
        width: 100%;
    }
    .profile_setup_help {
        margin-top: 30px;
        max-width: 100%;
        margin-bottom: 25px;
    }
    .milestoneSection .white-block {
        padding: 15px;
    }
}

@media (max-width: 575px) {
    .profile_container .container.profile_setup_cont {
        margin: 25px auto 0 !important;
    }
    .icn_details {
        display: flex !important;
        flex-direction: column;
        justify-content: center !important;
        align-items: center;
    }
    .icn_details > .flex-1 {
        width: 100%;
    }
    .iconsec_title {
        margin: 20px 0 10px;
        text-align: left;
    }
    .iconsec_link,
    .iconsec_cont {
        text-align: center;
    }
    .minus-paddingx-30 {
        padding-left: 15px;
        padding-right: 15px;
    }
    .padding-30 {
        padding: 15px;
    }
    .copywriting .modal-header > div:first-child {
        flex-direction: column;
        align-items: start !important;
        margin-bottom: 20px;
    }
    .modal-content.viewRequests {
        padding: 15px 20px;
    }
}

@media (max-width: 380px) {
    .bullet ul li div span {
        margin-top: -4px;
    }
}
.sideBar li a > b:first-child,
.headerRightSide > ul > li > a,
.pageContent > .container > .row > div .milestone > .icon > span,
.milestone > .icon > span,
.icn_details .iconsec > b,
.user-dropdown ul li a b,
.swingtag .swingicon {
    -webkit-animation-name: bounceIn;
    animation-name: bounceIn;
    -webkit-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
}
.sideBar li:hover a > b:first-child,
.headerRightSide > ul > li:hover > a,
.pageContent > .container > .row > div:hover .milestone > .icon > span,
.milestone:hover > .icon > span,
.icn_details:hover .iconsec > b,
.user-dropdown ul li a:hover b,
.swingtag:hover .swingicon {
    -webkit-transform-origin: top center;
    -ms-transform-origin: top center;
    transform-origin: top center;
    -webkit-animation-name: swing;
    animation-name: swing;
}
.sideBar li.active:hover a > b:first-child,
.headerRightSide > ul li a.invite {
    -webkit-animation-name: unset;
    animation-name: unset;
}

@keyframes swing {
    20% {
        -webkit-transform: rotate3d(0, 0, 1, 15deg);
        transform: rotate3d(0, 0, 1, 15deg);
    }
    40% {
        -webkit-transform: rotate3d(0, 0, 1, -10deg);
        transform: rotate3d(0, 0, 1, -10deg);
    }
    60% {
        -webkit-transform: rotate3d(0, 0, 1, 5deg);
        transform: rotate3d(0, 0, 1, 5deg);
    }
    80% {
        -webkit-transform: rotate3d(0, 0, 1, -5deg);
        transform: rotate3d(0, 0, 1, -5deg);
    }
    100% {
        -webkit-transform: rotate3d(0, 0, 1, 0deg);
        transform: rotate3d(0, 0, 1, 0deg);
    }
}
.tooltip-inner {
    background: #0b3454;
    border: 1px solid #0b3454;
}
.bs-tooltip-top .tooltip-arrow::before {
    border-top-color: #0b3454;
}
a.text-burgandi:hover {
    color: #d42941;
}
.welcome-title + .dashboard {
    margin-left: 100px;
}
.custom-nav > ul li.nav-item a.nav-link.dropdown-toggle.dashboardnav::after,
.interestsPreferences_sidebar,
.achievements_sidebar {
    display: none;
}
.profile_setup .btn-verify {
    padding: 0 15px;
}
.alumni-directory-block .icon-right-arrow:hover {
    color: #0b3454;
}
.aldir_scroll > .row > .aldir_box {
    width: 212px;
    height: 133px;
    padding: 0;
}
.aldir_scroll > .row > .aldir_box:not(:last-child) {
    margin-right: 20px;
}
.aldir_box .number {
    padding: 10px 12px 0 20px;
}
.aldir_box .number + div > div.font-16 {
    color: #0b3454;
    line-height: 18px;
    padding: 8px 0 0 18px;
}
.aldir_box .alumni-directory-img {
    margin-right: 10px;
    display: flex;
    align-items: flex-end;
}
.aldir_box .alumni-directory-block {
    justify-content: space-between;
    flex-direction: row;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
}
.aldir_box .alumni-directory-block.active {
    background: linear-gradient(-30deg, #fff, #41edff);
}
.aldir_box .alumni-directory-block > div {
    width: 100%;
}
.aldir_box .number + div > div.font-16.min94 {
    min-width: 94px;
}
.aldir_scroll > .row {
    width: max-content;
    margin: 0;
}
.profile_pic .profile_picedt {
    height: 24px;
    width: 24px;
    background: #5cae48;
    border-radius: 50%;
    text-align: center;
    top: 0px;
    right: -4px;
}
.phone_number.max-135 {
    max-width: 135px;
}
.btn-white-border.verifynow {
    padding: 0 20px;
    height: 30px;
    line-height: 26px;
    border: 2px solid #e1e1e1;
}
.btn-white.locbtn:hover .text-dark-blue {
    color: #fff;
}
.btn-white.locbtn {
    line-height: 14px;
}
.locbtncol {
    justify-content: flex-end;
    align-items: center;
}
.height-40 {
    height: 40px !important;
    line-height: 40px;
}
.height-45 {
    height: 45px !important;
    line-height: 45px;
}
.height-28 {
    height: 28px !important;
}
.height-36 {
    height: 36px !important;
}
.line-height-18 {
    line-height: 18px;
}
.line-height-26 {
    line-height: 26px;
}
.text-gray-e1 {
    color: #e1e1e1;
}
.text-gray-98 {
    color: #989898;
}
.text-black {
    color: #000;
}
.font-35 {
    font-size: 35px;
}
.me5 {
    margin-right: 5px;
}
.me10 {
    margin-right: 10px;
}
.mb-10 {
    margin-bottom: 10px !important;
}
.mb-20 {
    margin-bottom: 20px;
}
.mt-20 {
    margin-top: 20px;
}
.mt-30 {
    margin-top: 30px;
}
.mb-30 {
    margin-bottom: 30px;
}
.mt-38 {
    margin-top: 38px;
}
.mt-39 {
    margin-top: 39px;
}
.mt-40 {
    margin-top: 40px;
}
.mb-40 {
    margin-bottom: 40px;
}
.mt-43 {
    margin-top: 43px;
}
.mt-42 {
    margin-top: 42px;
}
.ps-20 {
    padding-left: 20px;
}
.xscroll {
    width: 100%;
    overflow-x: auto;
    scrollbar-width: thin;
    position: relative;
    z-index: 8;
}
.max-400 {
    max-width: 400px !important;
    width: 90% !important;
}
.max-464 {
    max-width: 464px !important;
    width: 90%;
}
.max-480 {
    max-width: 480px !important;
    width: 90%;
}
.border-lightgray {
    background: #f3f3f3;
    height: 1px;
    width: 100%;
    display: block;
}
.border-gray-e2 {
    background: #e2e2e2;
    height: 1px;
    width: 100%;
    display: block;
}
.border-gray-dc {
    background: #dcdcdc;
    height: 1px;
    width: 100%;
    display: block;
}
.border-radius-6 {
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.w-max-content {
    width: max-content !important;
}
.bg-skyblue {
    background: #26a7df;
}
.bg-purple {
    background: #51499c;
}
.btn-darkblue {
    background: #0b3454;
    border-radius: 27px;
    height: 40px;
    padding: 0 25px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    position: relative;
    overflow: hidden;
    border: 0;
}
.btn-darkblue:hover{
    background-color: #0b3454;
    color: #fff;
}

.btn-green-msg:hover,
.btn-pink-msg:hover {
    color: #fff;
}
.btn-darkblue span,
.btn-green-msg span,
.btn-pink-msg span {
    position: relative;
}
.btn-darkblue::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    bottom: 0;
    width: 0;
    background: #08273f;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.btn-darkblue:hover::before,
.btn-green-msg:hover::before,
.btn-pink-msg:hover::before {
    width: 100%;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.btn-icon-bg,
.circle-effect {
    background: #fff;
    position: relative;
}
.btn-icon-bg:hover,
.circle-effect:hover,
.btn-icon-bg:hover span,
.listblk_icon:hover > span.icon,
.album_shareblk > a.btn.btnicon:hover span.icon {
    color: #fff;
}
.btn-icon-bg span,
.circle-effect span {
    position: relative;
}
.btn-icon-bg::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    bottom: 0;
    width: 0;
    background: #08273f;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.btn-icon-bg:hover img {
    filter: brightness(0) invert(1);
    -webkit-filter: brightness(0) invert(1);
}
.circle-effect::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    bottom: 0;
    width: 0;
    background: #08273f;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    right: 0;
    width: 0;
    height: 0;
    margin: auto;
    border-radius: 50%;
}
.btn-icon-bg:hover::before {
    width: 100%;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.circle-effect:hover::before {
    width: 100%;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    height: 100%;
}
.btn-green-msg {
    background: #5cae48;
    border-radius: 4px;
    padding: 0 20px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    position: relative;
    overflow: hidden;
    border: 0;
}
.btn-green-msg::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    bottom: 0;
    width: 0;
    background: #398227;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.btn-pink-msg {
    background: #e37070;
    border-radius: 4px;
    padding: 0 20px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    position: relative;
    overflow: hidden;
    border: 0;
}
.btn-pink-msg::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    bottom: 0;
    width: 0;
    background: #d55151;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.btn-dark-blue {
    width: 150px;
    text-align: center;
    padding: 0 20px;
    border: 1px solid #0b3454;
    border-radius: 27px;
    background: linear-gradient(to right, #08273f 50%, #0b3454 50%);
    background-size: 201% 100%;
    transition: all 0.5s;
    background-position: right bottom;
}
.btn-greenm {
    width: 150px;
    text-align: center;
    padding: 0 20px;
    border: 1px solid #5cae48;
    border-radius: 27px;
    background: linear-gradient(to right, #398227 50%, #5cae48 50%);
    background-size: 201% 100%;
    transition: all 0.5s;
    background-position: right bottom;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
}
.btn-dark-blue:hover,
.btn-greenm:hover {
    background-position: left bottom;
    background-size: 201% 100%;
}
.btn-greenm.btnverified {
    width: 104px;
    text-align: center;
    padding: 0 20px;
    border: 1px solid #5cae48;
    border-radius: 27px;
    background: linear-gradient(to right, #398227 50%, #5cae48 50%);
    background-size: 200% 100%;
    transition: all 0.5s;
    background-position: right bottom;
    height: 30px;
    line-height: 30px;
    color: #fff;
    text-decoration: none;
    font-size: 13px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}
.btn-greenm.btnverified:hover {
    background-position: left bottom;
    background-size: 201% 100%;
    color: #fff;
}
.btn-red.btnverified {
    background: #0000001a;
    border: 2px solid #ffffff99;
    border-radius: 20px;
    font-size: 12px;
    padding: 0 12px;
    height: 30px;
    width: 99px;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #fff;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.btn-red.btnverified:hover {
    background: #fff;
    color: #000;
}
.btn-red.btnverified:hover .icon-varified {
    color: #000 !important;
}
.polartabsec {
    padding: 18px 25px;
    margin: 0;
    background: #f2f9ff;
}
.polartabsec ul {
    border-bottom: 0 solid #fff;
    width: max-content;
}
.polartabsec ul li {
    background: transparent;
    border: 0px solid #7d7d7d;
    border-radius: 20px;
    height: 40px;
    padding: 0;
}
.polartabsec ul li .nav-link {
    color: #414141;
    font-size: 16px;
    font-weight: 500;
    height: 40px;
    line-height: 38px;
    background: #fff;
    border: 2px solid #7d7d7d;
    padding: 0 23px;
    border-radius: 20px;
}
.polartabsec ul li .nav-link.active,
.polartabsec ul li .nav-link:hover {
    color: #fff;
    border: 2px solid #5cae48;
    background: #5cae48;
}
.polartabsec ul li:not(:last-child) {
    margin: 0 10px 0 0;
}
.ji_filtersec .icon-filter {
    font-size: 15px;
    color: #000;
    margin: -3px 5px 0 0;
}
.fiterItem {
    padding: 0;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 6px;
    overflow: hidden;
}
.filterHeader {
    background: transparent;
    border-radius: 0;
    padding: 0;
    border: 0px solid rgba(0, 0, 0, 0.125);
}
.filterHeader button span.toggle {
    width: 10px;
    height: 10px;
    margin-left: 10px;
    position: relative;
}
.filterHeader button span.toggle::before {
    width: 10px;
    height: 10px;
    background: transparent;
    border-left: 2px solid #000;
    border-top: 2px solid #000;
    transform: rotate(45deg);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    content: '';
    transition: all 0.3s ease-in-out;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.filterHeader button.collapsed span.toggle::before {
    transform: rotate(225deg);
    -webkit-transform: rotate(225deg);
    -moz-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    -o-transform: rotate(225deg);
}
.scrollMe {
    max-height: 215px;
    margin-bottom: 0px;
    overflow-y: auto;
    scrollbar-width: thin;
}
.scrollMe::-webkit-scrollbar {
    width: 4px;
}
.scrollMe::-moz-scrollbar {
    width: 4px;
}
.scrollMe::-webkit-scrollbar-track {
    background: #eee;
}
.scrollMe::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 20px;
    border: 2px solid #666;
}
.fiterItem .form-check:not(:last-child) {
    margin-bottom: 10px;
}
.fiterItem .form-check .form-check-input {
    width: 20px;
    height: 20px; /* margin-left: -20px; */
    border: 1px solid #bacfde;
}
.fiterItem label {
    cursor: pointer;
    padding-top: 3px !important;
}
.fiterItem .card {
    border-radius: 0;
    border: 0px solid #fff;
    padding: 0px 20px 20px;
}
.fiterItem .form-check {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}
.filterHeader button {
    background: #fff;
    border-radius: 0;
    padding: 0;
    border: 0px solid rgba(0, 0, 0, 0.125);
    padding: 15px 20px;
}
.filterHeader button.collapsed {
    border-bottom: 0px solid rgba(0, 0, 0, 0.125);
}
.filterSearchblk {
    margin: 0px;
    padding: 0 18px;
}
.filterSearchblk .filterSearch input {
    background: #f3f3f3;
    border: 0 solid #fff;
    border-radius: 6px;
    color: #000;
    height: 36px;
    line-height: 36px;
    margin: 0 4px 2px;
}
.filterSearchblk .filterSearch button {
    position: absolute;
    right: 14px;
    width: 14px;
    height: 14px;
    font-size: 14px;
    top: 9px;
    padding: 0;
    color: #414141;
}
.ji_filtersec {
    width: 100%;
    max-width: 320px;
}
form.minmaxlb .form-label {
    color: #535353;
    font-size: 14px;
    font-weight: 500;
    font-style: italic;
}
form.minmaxlb button {
    max-width: 91px;
    background: #0b3454;
    padding: 0;
    height: 36px;
    line-height: 36px;
    color: #fff;
    border-radius: 18px;
}
.fiterItem .card .form-check-input:checked,
form.lstCustForm .form-check-input:checked {
    background-color: #5cae48;
    border-color: #5cae48 !important;
}
.form-check-input:focus {
    box-shadow: 0 0 0 0 rgba(13, 110, 253, 0.25);
}
.act_lst {
    border: 1px solid #d2d2d2;
    padding: 40px 25px 25px 25px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.act_lst > .row > div:last-child {
    width: 255px;
}
.act_lst > .row.postedrow > div:last-child,
.act_lst.actlst_listed > .row > div:last-child {
    width: 150px;
}
.list_badge {
    position: absolute;
    width: 90px;
    height: 25px;
    line-height: 25px;
    color: #fff;
    background: #d42941;
    text-align: center;
    border-radius: 6px 0px 10px 0px;
    left: 0;
    top: 0;
}
.exp_loc > a.btn {
    display: inline-flex;
    align-items: center;
    border: 1px solid #dedede;
    height: 32px;
    line-height: 32px;
    padding: 0 16px;
    border-radius: 18px;
    font-size: 14px;
    font-weight: 500;
    color: #0b3454;
    margin-bottom: 10px;
    background: #fff;
    overflow: hidden;
}
.exp_loc > a.btn:not(:last-child) {
    margin-right: 5px;
}
.exp_loc > a .btn > img {
    margin: -3px 8px 0 0;
}
.exp_loc > a.btn > span.icon,
.exp_loc > a.btn > img {
    margin: -3px 8px 0 0;
}
.person_detls {
    margin-top: 10px;
}
.listblk_icon {
    border: 1px solid #dedede;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.listblk_icon > span.icon,
.album_shareblk > a.btn.btnicon span.icon {
    color: rgba(11, 52, 84, 0.4);
}
.act_lst > .row > div:last-child .btn-green {
    width: 150px;
}
.act_lst .btn-green {
    padding: 0 20px;
}
.load_more_btn {
    display: block;
    margin: 30px auto 0;
    width: 80%;
    max-width: 318px;
    height: 50px;
    line-height: 48px;
    border: 1px solid #e1e1e1;
    border-radius: 25px;
    text-align: center;
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    color: #535353;
    background: linear-gradient(to right, #fff 50%, #fff 50%);
    background-size: 200% 100%;
    transition: all 0.5s;
    background-position: right bottom;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
}
.load_more_btn:hover {
    background-position: left bottom;
    background-size: 201% 100%;
    color: #d42941;
}
.load_more_btn img {
    margin: 0 15px 0 0;
}
.no_listed_items_blk > div {
    text-align: center;
    border: 1px solid #d2d2d2;
    border-radius: 6px;
    padding: 40px;
}
.JobInternshipForm {
    width: 94%;
    max-width: 670px;
    display: none;
}
.JobInternshipForm h2 {
    border-bottom: 1px solid rgba(125, 125, 125, 0.3);
    padding: 0 0 10px;
    margin: 0 0 40px;
}
form.lstCustForm .form-select,
form.lstCustForm input.form-control {
    border: 1px solid #848484;
    height: 45px;
}
form.lstCustForm textarea.form-control {
    border: 1px solid #848484;
}
form.lstCustForm .form-check .form-check-input {
    width: 24px;
    height: 24px;
    margin-left: -20px;
    border: 2px solid #848484;
}
form.lstCustForm .form-check:not(:last-child) {
    margin: 0 40px 5px 0;
}
form.lstCustForm .btn-greenm,
.btn-greenm252 {
    min-width: 252px;
}
form.lstCustForm label span {
    font-size: 14px;
    color: #848484;
    font-weight: 400;
    font-style: italic;
    display: inline-block;
    padding: 0 0 0 20px;
}
form.lstCustForm .inputfile_parent label span.uploadlabel {
    font-size: 16px;
    color: #414141;
    font-weight: 400;
    display: inline-block;
    padding: 0 0 0 8px;
    font-style: normal;
}
form.lstCustForm label span.icon {
    font-style: unset;
    color: #414141;
}
.fileclose {
    height: 12px;
    line-height: 10px;
    width: 12px;
    text-align: center;
    background: #959595;
    border-radius: 50%;
    color: #fff;
    font-size: 12px;
    cursor: pointer;
    display: none;
    margin-left: 6px;
    justify-content: center;
    align-items: center;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.fileclose span {
    font-size: 6px;
    display: block;
    margin: 0 0 0px 1px;
}
.fileicon {
    display: none;
    margin-right: 6px;
}
.docselect {
    display: none;
    align-items: center;
    width: max-content;
}
.docselect.docseladdbg {
    background: #f8f8f8;
    display: flex;
    padding: 7px 12px;
    margin: 10px 0 0 0;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.inputfile::file-selector-button {
    font: inherit;
    display: none;
}
.inputfile_parent label {
    color: #f1e5e6;
    background-color: #f3f3f3;
    border: 1px dashed #848484;
    border-radius: 6px;
    height: 60px;
    line-height: 60px;
    max-width: 100%;
    font-size: 1.25rem;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 0;
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
}
.albmform .inputfile_parent label {
    height: 80px;
    line-height: 80px;
}
.inputfile_parent label span {
    font-size: 16px;
    color: #414141;
    font-weight: 500;
    font-style: unset;
    float: none;
    padding: 0 0 0 10px;
}
.inputfile_parent {
    position: relative;
    height: auto;
}
.inputfile_parent.profile .docselect {
    display: none;
}
.inputfile_parent.profile.select + p {
    display: none;
}
.inputfile_parent.profile label {
    background-color: #fff;
    border: 1px dashed #a8c5dd;
    border-radius: 6px;
    height: 40px;
    line-height: 40px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.inputfile_parent.profile.select .docseladdbg {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    background: #eaeaea;
}
.inputfile_parent.profile.pop-up label {
    background: #f3f3f3;
    border: 1px dashed #848484;
    border-radius: 6px;
    opacity: 1;
    height: 45px;
    line-height: 45px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.album_imgblk img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 190px;
}
.album_blk {
    border: 1px solid #d2d2d2;
    border-radius: 6px;
    height: 100%;
}
.album_badge {
    background: #d42941;
    padding: 0 20px;
    height: 32px;
    line-height: 32px;
    border-radius: 6px 0 15px 0;
    top: 0;
    left: 0;
}
.album_imgblk {
    border-radius: 6px 6px 0 0;
    overflow: hidden;
    min-height: 190px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.album_contblk {
    background: #fff;
    border-radius: 6px;
    width: calc(100% - 20px);
    margin: -40px auto 0;
    position: relative;
    padding: 20px 20px 15px;
}
.album_contblk h3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.album_shareblk {
    width: 60px;
    padding: 0 0 0 20px;
}
.album_shareblk > span,
.album_shareblk > a.btn.btnicon {
    width: 40px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    height: 40px;
    line-height: 38px;
    border: 1px solid #dedede;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
}
.album_shareblk > span:not(:last-child),
.album_shareblk > a.btn:not(:last-child) {
    margin: 0 0 5px;
}
.albumblk_footer {
    background: #f2f9ff;
    padding: 13px 30px;
    border-radius: 0 0 6px 6px;
}
.albumblk_footer img {
    margin: 0 6px 0 0;
}
.album_blk_parent {
    margin: 0 0 20px 0;
}
.album_imgvdo {
    right: 10px;
    top: 10px;
}
.Wrdrow {
    width: calc(100% - 60px);
}
.lstperdetails {
    width: calc(100% - 40px);
}
.image_tn {
    margin: 0 0 5px 0;
}
.image_tn > a {
    width: 63px;
    height: 30px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 16px;
    color: #e1e1e1;
    font-size: 13px;
    text-decoration: none;
}
.image_tn > a img.gallicon {
    width: 14px;
    height: auto;
    margin: 0 3px 0 0;
}
img.custimg {
    width: auto;
    display: block;
    margin: 0 auto;
}
.icon_bluecir {
    background: #d1f1ff;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
}
.EventsBlk .btn-border-gray {
    color: #414141;
    min-width: 110px;
    padding: 0 12px;
}
.EventsBlk .btn-border-gray.text-gray-41:hover {
    color: #fff;
}
.album_contblk.eventsbox {
    padding: 20px 8px 15px 20px;
}
.lightboxcontainer {
    width: 100%;
    text-align: left;
}
.lightboxleft {
    width: 40%;
    float: left;
}
.lightboxright {
    width: 60%;
    float: left;
}
.lightboxright iframe {
    min-height: 390px;
}
.divtext {
    margin: 36px;
}
#html5lightbox-watermark,
.html5-nav-showcontrol {
    display: none !important;
}
.html5-image {
    padding: 0 !important;
}
#html5-lightbox-box,
.html5-elem-wrap,
.html5lightbox-video iframe {
    width: 600px !important;
    height: 400px !important;
}
.html5-prev-inside .mh-icon-left,
.html5-next-inside .mh-icon-right {
    position: relative;
}
.html5-next-inside .mh-icon-right::after {
    position: absolute;
    width: 12px;
    height: 12px;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    content: '';
    transform: rotate(45deg);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
}
.html5-prev-inside .mh-icon-left::after {
    position: absolute;
    width: 12px;
    height: 12px;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    content: '';
    transform: rotate(-135deg);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
}
.html5gallery-title-0 {
    top: 360px !important;
}
.html5gallery-title-text-0 {
    color: #333 !important;
    background-color: transparent !important;
    text-align: center !important;
    font-size: 18px !important;
    filter: progid: DXImageTransform.Microsoft.gradient(enabled=false) !important;
}
.html5gallery-play-0,
.html5gallery-pause-0,
.html5gallery-lightbox-0 {
    top: 8px !important;
}
.skills_input_selected > span {
    padding: 6px 15px;
    border: 1px solid #dedede;
    border-radius: 7px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.profile_radiorow > .icon-cross {
    position: absolute;
    font-size: 8px;
    color: #0b345480;
    right: -17px;
    top: 19px;
    cursor: pointer;
}
.msg_title .form-check-input:focus,
.msg_title.msg_heading_row form select:focus {
    box-shadow: unset;
}
.w-h-32 {
    width: 32px;
    height: auto;
}
.w-h-90 {
    width: 90px;
    height: auto;
}
.dropdowncheck span {
    padding: 5px 12px;
    border: 1px solid #dedede;
    border-radius: 7px;
    font-size: 14px;
    font-weight: 400;
    width: max-content;
    margin: 0 10px 0 0;
}
.text-gray-f3 {
    color: #f3f3f3;
}
.albumadd {
    background: #f3f3f3;
    border-radius: 6px;
    padding: 12px 16px 12px 12px;
    margin-bottom: 10px;
}
.albumadd img {
    border-radius: 6px;
}
.albumadd > div textarea.form-control {
    border: 1px solid #d9d9d9;
    min-height: 112px;
}
.albumadd .addvdo i {
    color: #000;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    width: 30px;
    height: 30px;
    filter: invert(100%);
    background: #fff;
    border-radius: 50%;
    -webkit-filter: invert(100%);
}
.list_badge.thumbbadge {
    width: 108px;
    height: 32px;
    line-height: 32px;
    border-radius: 6px 0px 15px 0px;
}
.albumadd .dropdown-menu li a:hover {
    color: #5cae48;
    background-color: transparent;
}
.albumadd .dropdown-menu {
    transform: unset !important;
    top: 22px !important;
    right: -2px !important;
    left: auto !important;
    padding: 10px 15px;
    width: 108px;
    height: 92px;
    max-width: 108px;
}
.albumadd .dropdown-menu .dropdown-item {
    padding: 2px 0;
    font-weight: 400;
    color: #000000;
    font-size: 14px;
}
.gallimg_update.modal-dialog {
    max-width: 700px;
    width: 94%;
}
.gallimg_update .editprofileImg::after {
    display: none;
}
.gallimg_update .editprofileImg {
    max-width: 370px;
}
.profiles_blk > div > div {
    border: 1px solid #d2d2d2;
    border-radius: 6px;
}
.profiles_contsec {
    padding: 0 20px 20px;
    text-align: center;
}
.profiles_blk img {
    border-radius: 6px 6px 0 0;
    width: 100%;
    height: auto;
}
.profiles_contsec .btn-green {
    line-height: 14px;
}
.uni_profiles_sec {
    border: 1px solid #d2d2d2;
    border-radius: 6px;
    padding: 20px 30px 20px 20px;
}
.uni_profiles_100 .uni_profiles_sec {
    border: 0px solid #d2d2d2;
    border-radius: 0;
    padding: 0;
}
textarea.graybg-textarea,
textarea.graybg-textarea:focus {
    background: #f3f3f3;
    border: 1px solid #dddddd;
    border-radius: 5px;
    color: #414141;
    font-size: 16px;
    font-weight: 500;
}
.btn-green.disable {
    border-radius: 25px;
    padding: 0 25px;
    line-height: 32px;
    border: 2px solid #cbcbcb;
    height: 35px;
    display: inline-flex;
    text-decoration: none;
    color: #bcbcbc;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    position: relative;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    background: #fff;
}
.btn-green.disable::before {
    width: 0;
    height: 100%;
    content: '';
    background: #fff;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.btn-green.disable:hover::before {
    width: 100%;
}
.btn-green.disable:hover {
    color: #bcbcbc;
    background: #fff;
}
.btn-green.disable span {
    position: relative;
}
.polartabsec ul.msgtabs li button.nav-link {
    padding: 0 18px;
}
.newmsg .ji_filtersec {
    background: #f8f8f8;
    padding: 25px 30px 30px;
}
.newmsg .filterSearch input {
    border-radius: 18px;
    border: 1px solid #e1e1e1;
    padding-left: 37px;
    color: #000000;
    height: 35px;
}
.newmsg .filterSearch .btn.icon-search {
    left: 11px;
    top: 13px;
    padding: 0;
    font-size: 14px;
    color: #414141;
    font-weight: 400;
    z-index: 1;
}
.dotted-blue-border {
    height: 1px;
    width: 100%;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/dotted_border_bg.png) left top
        repeat-x;
}
.newmsg .ji_filtersec ul {
    list-style: none;
    padding: 0;
}
.newmsg .ji_filtersec ul li {
    margin: 0 0 20px;
}
.newmsg .ji_filtersec ul li a {
    color: #0b3454;
    font-weight: 500;
    font-size: 16px;
    text-decoration: none;
    width: 100%;
    display: inline-block;
}
.newmsg .ji_filtersec ul li.active a {
    color: #5cae48;
}
.msgtabcont {
    padding: 25px;
}
.msgtabcont.msgwrapext {
    padding: 25px 10px 25px 25px;
}
.msgtabcont .msgbox {
    border: 1px solid #dedede;
    border-radius: 6px;
    padding: 20px 0px 90px;
    min-height: 470px;
}
.msgtabcont .msgbox.msgboxconvo {
    padding: 5px 0px 90px;
}
.msgbox .filterSearch input {
    height: 45px;
    line-height: 45px;
    border: 1px solid #848484;
    border-radius: 23px;
    font-size: 16px;
    color: #666666;
}
.newmsg .msgbox .filterSearch .btn.icon-search {
    top: 14px;
}
.search_btmshadow {
    background: transparent linear-gradient(180deg, #f2f2f2 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
    height: 20px;
    width: 100%;
}
.msgbox_send {
    width: 100%;
    height: 60px;
    background: #f3f3f3;
    left: 0;
    bottom: 0;
    border-radius: 0 0 6px 6px;
    padding: 5px;
}
.msgbox_send input {
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    font-weight: 500;
    color: #414141;
    padding-right: 80px;
}
.btn-complete-now.msgsendbtn span {
    font-size: 14px;
    font-weight: 600;
    position: relative;
}
.btn-complete-now.msgsendbtn {
    top: 5px;
    right: 5px;
    position: absolute;
    z-index: 1;
}
.search_sugg {
    background: #fff;
    position: absolute;
    height: auto;
    width: 100%;
    top: auto;
    border: 1px solid #dedede;
    border-radius: 20px;
    box-shadow: 0px 10px 20px #0000001a;
    padding: 25px;
    transition: all 0.5s ease-in-out;
    z-index: 9;
    display: none;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}
.msgbox .filterSearch input.open + .search_sugg {
    display: block;
}
.msgbox .uni_profiles_sec {
    padding: 20px 45px 20px 20px;
}
.msgbox .uni_profiles_sec .icon-invert-cross {
    color: #a9a9a9;
    right: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 16px;
    width: 16px;
}
.search_ttlblk .uni_profiles_sec {
    padding: 0;
    border: 0 solid #fff;
}
.msgbox .search_cont {
    height: 280px;
    overflow-y: auto;
    scrollbar-width: thin;
}
.msg_popup_cont {
    background: #f8f8f8;
    border-radius: 10px;
    margin: 60px 0 0 0;
    padding: 70px 30px 25px;
    position: relative;
}
.msg_popup_cont > img {
    width: 90px;
    height: auto;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: -50px;
    border: 6px solid #fff;
    box-sizing: content-box;
}
.msg_popup_modal .modal-body {
    padding: 30px;
}
.alumni-switch-container.msg-switch {
    background: #fff;
    border: 1px solid #b9b9b9;
    width: 342px;
}
.alumni-switch-container.msg-switch .switch > a {
    color: #000;
}
.alumni-switch-container.msg-switch .switch > a.active {
    color: #fff;
}
.alumni-switch-container.msg-switch .switch > span {
    background: #5cae48;
}
.msg_name {
    width: max-content;
    background: #f3f3f3;
    padding: 16px 20px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.msg_area {
    background: #f3f3f3;
    border-radius: 6px;
    padding: 20px 20px;
    max-width: 620px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.msg_title .icon-star {
    color: #dedede;
}
.msg_title .icon-star.selected {
    color: #f8b018;
}

/* conversation */
.profile_person {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    align-items: end;
}
.profile_person p {
    background: #2296c9;
    padding: 11px 20px;
    border-radius: 10px 10px 0px 10px;
    color: #fff;
    max-width: 456px;
    line-height: 20px;
    margin-bottom: 10px;
}
.profile_person p.accepted {
    background: #5cae48;
    border-radius: 6px;
    max-width: 150px;
    width: 100%;
    text-align: center;
}
.profile_person p.reject {
    background: #a00;
    border-radius: 6px;
    max-width: 150px;
    width: 100%;
    text-align: center;
}
.profile_person p.date {
    background: none;
    color: #848484;
    font-size: 14px;
    font-weight: 500;
    padding: 0;
}
.profile_person p + p.date {
    margin-top: -10px;
}
.non-profile_person {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    align-items: start;
}
.non-profile_person p {
    background: #413a7d;
    padding: 11px 20px;
    border-radius: 10px 10px 10px 0px;
    color: #fff;
    max-width: 456px;
    line-height: 20px;
    margin-bottom: 10px;
}
.non-profile_person p.accepted {
    background: #5cae48;
    border-radius: 6px;
}
.non-profile_person p.reject {
    background: #a00;
    border-radius: 6px;
}
.non-profile_person p.date {
    background: none;
    color: #848484;
    font-size: 14px;
    font-weight: 500;
    padding: 0;
}
.non-profile_person p + p.date {
    margin-top: -10px;
}
.university_ttl > span.unicon {
    width: 32px;
    height: 32px;
    display: inline-block;
    color: #fff;
    text-align: center;
    line-height: 32px;
    border-radius: 50%;
    margin: 0 5px 0 0;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.university_ttl {
    max-width: 180px;
    min-width: 180px;
}
.msg_title .form-check .form-check-input {
    width: 20px;
    height: 20px;
    border: 2px solid #848484;
}
.msg_title .form-check-input:checked {
    background-color: #0b3454;
    border-color: #0b3454;
    background-size: 18px auto;
}
.convo_btns a.btn {
    height: 30px;
    width: 180px;
    background: #f3f3f3;
    border-radius: 6px;
    padding: 0 10px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #000;
    justify-content: flex-start;
    text-align: left;
}
.msg_title.msg_title_row {
    border: 1px solid #dedede;
    border-radius: 6px;
    margin-bottom: 10px;
    min-height: 80px;
}
.msg_title_row_wrapper .msg_title.msg_title_row:last-child {
    margin-bottom: 0;
}
.msg_title_row_wrapper {
    max-height: 264px;
    overflow-y: auto;
    margin-bottom: 10px;
    scrollbar-width: thin;
    padding-right: 15px;
}
.extmar {
    margin-right: 15px !important;
}
.msg_lt_rt a.btn {
    background: #f3f3f3;
    height: 36px;
    width: 36px;
    display: inline-block;
    line-height: 36px;
    text-align: center;
    border-radius: 6px;
    padding: 0;
    font-size: 14px;
    font-weight: 800;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.msg_lt_rt a.btn.msg_lt i {
    margin-left: -2px;
}
.msg_lt_rt a.btn.msg_rt i {
    margin-right: -2px;
}
.msg_title.msg_heading_row .btn-complete-now {
    height: 36px;
    text-align: center;
    margin: auto;
    display: inline-block;
    padding: 0 20px;
    border-radius: 25px;
    line-height: 32px;
    overflow: hidden;
    position: relative;
    font-size: 14px;
    font-weight: 500;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
}
.msg_title.msg_heading_row .btn-complete-now span {
    position: relative;
}
.msg_title.msg_heading_row form select {
    border: 2px solid #7d7d7d;
    border-radius: 18px;
    height: 36px;
    min-width: 170px;
    max-width: 170px;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    -ms-border-radius: 18px;
    -o-border-radius: 18px;
}
.send_msg_text {
    width: max-content;
}
.send_msg_text > a {
    width: 150px;
    height: 70px;
    background: #5cae48;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    line-height: 23px;
    font-weight: 500;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.send_msg_text > a:not(:last-child) {
    margin-right: 10px;
}
.uniprof_btnmbl_align.paddings-67 {
    padding-left: 20px;
}
.welcome_pg {
    padding-left: 50px;
    padding-right: 50px;
}
.cpw_bg {
    padding: 40px;
    background: #f8f8f8;
    border-radius: 8px;
    margin: 0 0 30px 0;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.cpw_bg > div {
    width: 94%;
    max-width: 385px;
    margin: 0 auto;
}
.icon-green-right {
    width: 48px;
    height: 48px;
    background: #3bb54a;
    border-radius: 50%;
    margin: auto auto 25px;
    position: relative;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.icon-green-right:after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 23px;
    height: 13px;
    border-left: 5px solid #fff;
    border-bottom: 5px solid #fff;
    content: '';
    transform: rotate(-45deg);
    top: -7px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
}

@media (min-width: 1600px) {
    #html5-lightbox-box,
    .html5-elem-wrap,
    .html5lightbox-video iframe {
        width: 1000px !important;
        height: 667px !important;
    }
}

@media (min-width: 768px) {
    .mobileFilter.mobileonly,
    .btn-filter.mobileonly {
        display: none;
    }
    .welcome-title + .dashboard.actdashboard {
        min-width: 292px;
    }
    .school_notification li div.check.notifychk {
        width: 100px;
        text-align: right;
        margin-left: 30px;
    }
}

@media (max-width: 1180px) {
    .msgacpign {
        width: 100% !important;
        align-items: start !important;
        flex-direction: column;
        margin-left: 74px;
    }
    .msgacpign > .flex-column {
        flex-direction: row !important;
        order: 2;
        margin: 8px 0 0 0;
    }
    .msgacpign > .flex-column a.mb-2 {
        margin-bottom: 0 !important;
    }
    .university_ttl {
        max-width: unset;
        min-width: unset;
        width: calc(100% - 85px);
        margin-right: 0 !important;
    }
    .msg_title_txt {
        width: calc(100% - 80px);
        max-width: unset !important;
        margin-left: 74px;
        margin-right: 0 !important;
    }
    .msg_title.msg_title_row {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
    }
    .msgtabcont .msgbox.msgboxconvo .search_ttlblk {
        padding-top: 15px;
        padding-bottom: 15px;
    }
    .msg_title_row_wrapper {
        max-height: 514px;
    }
}

@media (max-width: 1150px) {
    .aldir_scroll {
        width: 100%;
        overflow-x: auto;
        scrollbar-width: thin;
    }
    .aldir_scroll::-webkit-scrollbar {
        height: 4px;
    }
    .aldir_scroll::-moz-scrollbar {
        height: 4px;
    }
    .aldir_scroll::-webkit-scrollbar-track {
        background: #e372848f;
    }
    .aldir_scroll::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 20px;
        border: 2px solid #810d20;
    }
}

@media (max-width: 1080px) {
    .act_lst > .row > .flex-1 {
        width: 100%;
        flex: unset;
        padding-right: 0 !important;
    }
    .act_lst > .row > div:last-child {
        width: 100%;
        padding: 15px 0 0 0;
        text-align: left !important;
    }
    .act_lst > .row.postedrow > div:last-child {
        width: 100%;
        padding: 5px 0 0 0;
    }
    .act_lst.actlst_listed > .row > div:last-child {
        width: 100%;
        padding: 10px 0 0 0;
    }
    .act_lst .postedtxt {
        margin-bottom: 5px !important;
    }
    .ji_filtersec {
        max-width: 275px;
    }
    .act_lst.actlst_listed .btn-green {
        margin-bottom: 5px;
    }
    .act_lst > .row,
    .act_lst > .row > div:last-child > div {
        justify-content: start !important;
    }
    .welcome-title + .dashboard {
        margin-left: 30px;
    }
    ul.school_notification li.read .check {
        width: 100%;
        text-align: right;
    }
}

@media (min-width: 768px) and (max-width: 1080px) {
    .school_notification li div.check.notifychk {
        width: 100px;
        text-align: right;
        margin-left: 30px;
    }
}

@media (min-width: 992px) and (max-width: 1160px) {
    .album_contblk .btn-green {
        height: 34px;
        padding: 0 15px;
        line-height: 34px;
    }
    .album_contblk {
        padding: 20px 15px 15px;
    }
    .album_shareblk > span img {
        max-width: 14px;
        height: auto;
    }
    .album_shareblk > span img.vimg {
        max-width: unset;
        max-height: 16px;
        width: auto;
    }
}

@media (max-width: 1024px) {
    .welcome-title h1,
    .notificationSection h3.font-35 {
        font-size: 30px;
    }
}

@media (min-width: 768px) and (max-width: 1180px) {
    .dashboard.actdashboard .ms-4.me-3.dashb_arr.dashb_left {
        margin-left: 10px !important;
        margin-right: 10px !important;
    }
}

@media (max-width: 1024px) {
    .msg_title_row_wrapper {
        max-height: 530px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .profile_setup .icn_details .iconsec + .flex-1 {
        width: calc(100% - 80px);
        flex: unset;
        margin: 0 0 15px 0px;
        -webkit-box-flex: unset;
        -moz-box-flex: unset;
        -webkit-flex: unset;
        -ms-flex: unset;
    }
    .profile_setup .icn_details .activity-btn {
        margin: auto !important;
        display: block;
        min-width: 140px;
        max-width: max-content;
    }
}

@media (max-width: 991px) {
    .tabw-100 {
        width: 100% !important;
        max-width: unset !important;
    }
    .msg_title.msg_heading_row form {
        width: 100%;
        padding-top: 10px;
    }
    .selallarr {
        width: max-content !important;
        position: static;
        right: 0px;
        top: 0;
        margin-left: auto;
    }
}

@media (max-width: 900px) {
    .convo_btns {
        width: 100%;
        padding-left: 74px;
    }
}

@media (max-width: 800px) {
    .lightboxleft,
    .lightboxright {
        width: 100%;
    }
    .divtext {
        margin: 12px;
    }
    .uni_profiles_sec.profiles_sec_brk {
        -webkit-box-wrap: wrap;
        -moz-box-wrap: wrap;
        -ms-flex-wrap: wrap;
        -webkit-wrap: wrap;
        flex-wrap: wrap;
    }
    .uniprof_btnmbl_align.paddings-67 {
        width: 100%;
        padding-left: 67px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .no_listed_items_blk .max-470 {
        max-width: unset !important;
        width: 300px;
    }
    .no_listed_items_blk > div {
        padding: 30px;
    }
    .footer {
        padding: 20px 40px;
    }
    header {
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    }
}

@media (min-width: 768px) and (max-width: 800px) {
    .uniprof_btnmbl_align a:first-child {
        margin-bottom: 10px;
    }
}

@media (max-width: 767px) {
    .activity-btn {
        margin: auto !important;
        display: block;
        min-width: 140px;
        max-width: max-content;
    }
    .filters {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        width: 100%;
        bottom: 0;
        background: #fff;
        z-index: 99;
        max-width: 100%;
        overflow-x: scroll;
        padding: 40px 20px;
        display: block !important;
        -moz-transform: translate3d(0, 0, 0);
        -webkit-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        height: 100%;
    }
    .filters.show {
        -moz-transform: translate3d(0, -100%, 0);
        -webkit-transform: translate3d(0, -100%, 0);
        -o-transform: translate3d(0, -100%, 0);
        -ms-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }
    .filters .btn-filter {
        position: absolute;
        right: 25px;
        font-size: 0;
        background: none;
        border: 0;
        width: 20px;
        height: 20px;
        top: 25px;
    }
    .filters .btn-filter::before {
        transform: translateY(10px) rotate(135deg);
        -moz-transform: translateY(10px) rotate(135deg);
        -webkit-transform: translateY(-5px) rotate(135deg);
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        width: 18px;
        height: 2px;
        content: '';
        background: #000;
        position: absolute;
        border-radius: 5px;
    }
    .filters .btn-filter::after {
        transform: translateY(-5px) rotate(-135deg);
        -moz-transform: translateY(-5px) rotate(-135deg);
        -webkit-transform: translateY(-5px) rotate(-135deg);
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        width: 18px;
        height: 2px;
        content: '';
        background: #000;
        position: absolute;
        border-radius: 5px;
    }
    .filters #accordionFilters {
        margin-bottom: 30px;
    }
    .filters > h3 {
        display: -webkit-box !important;
        display: -moz-box !important;
        display: -ms-flexbox !important;
        display: -webkit-flex !important;
        display: flex !important;
        align-items: center;
        justify-content: space-between;
    }
    .mobileFilter .btn-filter {
        border: 0;
        background-color: transparent;
        padding-right: 0px;
        padding-left: 0;
        cursor: pointer;
    }
    .filters {
        max-width: 100%;
        width: 100%;
    }
    .ji_filtersec {
        max-width: unset;
    }
    .JobInternshipForm {
        padding: 0;
        width: 90%;
    }
    .JobInternshipForm > .col-12 {
        padding: 0;
    }
    #html5-lightbox-box,
    .html5-elem-wrap,
    .html5lightbox-video iframe {
        width: 450px !important;
        height: 300px !important;
    }
    .container.max-1140,
    .max-1140 {
        width: 100%;
        padding: 0;
    }
    header .container.max-1140 {
        max-width: 1140px;
        width: 94%;
        padding-right: var(--bs-gutter-x, 0.75rem);
        padding-left: var(--bs-gutter-x, 0.75rem);
    }
    footer .max-1140 {
        padding: 20px 0px;
        width: 90%;
        align-items: center;
    }
    .milestoneSection > .container > .row,
    .notificationSection > .container > .row {
        margin: 0;
    }
    .milestoneSection > .container > .row > .col-md-12,
    .notificationSection > .container > .row > .col-md-12 {
        padding: 0;
    }
    .white-block {
        box-shadow: unset;
        border: 0px solid #d2d2d2;
        border-radius: 0px;
    }
    .welcome-title + .dashboard {
        margin-left: auto;
    }
    .dashboard.innerpage > a.btn {
        margin-top: 0 !important;
    }
    header {
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    }
    .font-35 {
        font-size: 24px;
        line-height: 28px;
    }
    .welcome-title h1,
    .notificationSection h3.font-35 {
        font-size: 24px;
    }
    #alumni_directory .container.max-1140 {
        padding: 0 20px;
    }
    .phone_number.max-135 {
        max-width: unset;
        -webkit-box-flex: 1;
        -moz-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
    }
    .modal-dialog.modal-dialog-centered {
        align-items: flex-start;
        margin-top: 25px;
    }
    .polarcontsec {
        margin-top: 20px;
        padding: 0;
    }
    .page_ttl_row,
    .m-w-90,
    .max-1010.m-w-90,
    .m-w-90.plr0 {
        padding-left: 0 !important;
        padding-right: 0 !important;
        width: 90%;
        margin: auto !important;
    }
    .milestoneSection .white-block.m-w-90-parent {
        padding: 0;
    }
    .msgtabcont.polarcontsec {
        padding: 20px 0 0 0;
        width: 90%;
        margin: auto !important;
        flex: unset;
        -webkit-box-flex: unset;
        -moz-box-flex: unset;
        -webkit-flex: unset;
        -ms-flex: unset;
    }
    form.lstCustForm .form-check:not(:last-child) {
        margin: 0 25px 8px 0;
    }
    .profile_radiorow > .icon-cross {
        right: 12px;
        top: 12px;
        background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/trash.svg) no-repeat 0 0;
        width: 16px;
        height: 16px;
    }
    .profile_radiorow > .icon-cross::before {
        display: none;
    }
    .max-630 {
        max-width: 100%;
        width: 90%;
    }
    .page_ttl_row > .mx-sm-1 {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    .welcome_pg {
        padding-left: 0;
        padding-right: 0;
    }
    .msg_title_row_wrapper {
        scrollbar-width: none;
        padding-right: 0;
    }
    .extmar {
        margin-right: 0px !important;
    }
    .msgtabcont.polarcontsec.msgwrapext {
        padding: 20px 0 0 0;
    }
}

@media (max-width: 575px) {
    .btn-verify {
        line-height: 40px;
    }
    .cpw_bg {
        padding: 20px;
    }
    .JobInternshipForm h2 {
        margin: 0 0 30px;
    }
    .btn-border:hover,
    .btn-verify:hover {
        background-size: 200% 100%;
    }
    .polartabsec {
        padding: 18px 0;
    }
    .polartabsec .xscroll {
        width: 95%;
        margin-left: 5%;
    }
    .polartabsec + div {
        padding-bottom: 30px !important;
    }
    .polartabsec + div.newmsg {
        padding: 0 !important;
    }
    .act_lst {
        padding: 40px 15px 15px 15px;
        text-align: left;
    }
    .exp_loc,
    .act_lst > .row > div:last-child > div {
        justify-content: flex-start !important;
    }
    .person_detls {
        justify-content: flex-start;
        margin-top: 5px;
    }
    .act_lst > .row > div:last-child {
        text-align: left !important;
    }
    .lstperdetails {
        width: 100%;
    }
    .listblk_icon {
        margin-bottom: 10px;
        width: 34px;
        height: 34px;
    }
    .act_lst.actlst_listed .btn-green,
    .act_lst .btn-green,
    .act_lst .btn-dark-blue {
        height: 34px !important;
        line-height: 34px;
    }
    .listblk_icon .listblk_icon_bm {
        width: 10px;
        height: auto;
    }
    .listblk_icon .listblk_icon_sh {
        width: 14px;
        height: auto;
    }
    .load_more_btn {
        height: 40px;
        line-height: 38px;
    }
    .docselect {
        width: 100%;
    }
    .docselect .inputfile {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        -webkit-box-flex: 1;
        -moz-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
    }
    .album_shareblk {
        width: 100%;
        padding: 20px 0 0 0px;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        justify-content: start;
    }
    .album_shareblk > span:not(:last-child) {
        margin: 0 5px 5px 0;
    }
    .EventsBlk .album_shareblk {
        width: 60px;
        padding: 0px 0 0 20px;
    }
    .justify-content-start.mContCenter {
        justify-content: flex-start !important;
    }
    .d-flex.mflexcol {
        flex-direction: column;
        align-items: flex-start !important;
    }
    .d-flex.mflexcol.btnview {
        flex-direction: row;
    }
    .d-flex.mflexcol.btnview > span {
        margin-left: 10px;
    }
    .alumni_directory_details ul.orderlist li {
        flex-wrap: wrap;
    }
    .alumni_directory_details ul.orderlist li p {
        margin: 3px 0 5px;
        width: 100%;
    }
    .mscroll {
        width: 100%;
        overflow-x: auto;
        scrollbar-width: thin;
    }
    .mscroll > div {
        width: max-content;
    }
    .graph-result-list {
        width: 100%;
    }
    .mjcstart {
        justify-content: flex-start !important;
    }
    .mjcstart select:last-child {
        margin-left: 5px;
    }
    .map-Search {
        max-width: 260px;
    }
    .locbtncol {
        justify-content: flex-start;
        align-items: flex-start;
        margin: 0 0 10px;
    }
    .phone_number.max-135 {
        max-width: unset;
        -webkit-box-flex: unset;
        -moz-box-flex: unset;
        -webkit-flex: unset;
        -ms-flex: unset;
        flex: unset;
        width: unset;
    }
    .phone_number.contemail {
        max-width: unset;
        -webkit-box-flex: unset;
        -moz-box-flex: unset;
        -webkit-flex: unset;
        -ms-flex: unset;
        flex: unset;
        width: unset;
        margin: 10px 0 0;
    }
    .albumadd {
        position: relative;
    }
    .albumadd > .flex-1 {
        -webkit-box-flex: unset;
        -moz-box-flex: unset;
        -webkit-flex: unset;
        -ms-flex: unset;
        flex: unset;
        width: 100%;
        margin: 15px 0 0 !important;
    }
    .albumadd .albm_edit_settings {
        position: absolute !important;
        right: 15px;
        top: 15px;
    }
    form.lstCustForm .btn-greenm,
    .btn-greenm252 {
        min-width: unset;
        width: auto;
        line-height: 15px;
        justify-content: center;
        align-items: center;
        display: flex !important;
        max-width: 252px;
    }
    .font-24 {
        font-size: 20px !important;
    }
    .btn-green {
        font-size: 14px;
        line-height: 14px;
    }
    .album_contblk,
    .album_contblk.eventsbox {
        padding: 12px 15px 15px 15px;
    }
    .EventsBlk .btn-border-gray {
        min-width: 92px;
        padding: 0 8px;
    }
    .btn-green.max-170 {
        max-width: unset !important;
        width: unset;
        min-width: 110px;
    }
    .uniprof_btnmbl_align .btn-darkblue {
        font-size: 14px;
    }
    .search_cont.px-4 {
        padding-left: 12px !important;
        padding-right: 12px !important;
    }
    .msgbox .uni_profiles_sec .icon-invert-cross {
        right: 12px;
    }
    .msgbox .uni_profiles_sec {
        padding: 5px 35px 5px 15px;
    }
    .search_ttlblk .uni_profiles_sec {
        padding: 0px 15px 5px;
    }
    .msg_area {
        -webkit-box-wrap: wrap;
        -moz-box-wrap: wrap;
        -ms-flex-wrap: wrap;
        -webkit-wrap: wrap;
        flex-wrap: wrap;
    }
    .msg_area .flex-1 {
        flex: unset;
        -webkit-box-flex: unset;
        -moz-box-flex: unset;
        -webkit-flex: unset;
        -ms-flex: unset;
        width: 100%;
        margin: 0 0 12px 0 !important;
    }
    .msg_area .flex-column {
        flex-direction: row !important;
        width: 100%;
        justify-content: end;
    }
    .msg_area .flex-column a:first-child {
        margin-right: 5px;
    }
    .msg_title_txt,
    .msgacpign {
        width: 100%;
        margin-left: 0px;
    }
    .convo_btns {
        padding-left: 0;
    }
    form.lstCustForm label span {
        padding: 0 0 0 0px;
        width: 100%;
    }
    form.lstCustForm .inputfile_parent label span {
        width: unset;
    }
}

@media (max-width: 479px) {
    #html5-lightbox-box,
    .html5-elem-wrap,
    .html5lightbox-video iframe {
        width: 300px !important;
        height: 200px !important;
    }
    .uniprof_btnmbl_align .btn-green.height-40 {
        height: 34px !important;
        line-height: 34px;
        padding: 0 13px;
    }
    .uniprof_btnmbl_align .btn-darkblue {
        height: 34px !important;
        padding: 0 13px;
        line-height: 14px;
    }
    .alumni-switch-container.msg-switch {
        width: 290px;
    }
    .alumni-switch-container.msg-switch .switch > a {
        font-size: 14px !important;
    }
}

@media (max-width: 350px) {
    .m-max-105 {
        max-width: 105px;
    }
}

.sideBar-btn {
    width: 48px;
    height: 48px;
    background: #414141;
    border-radius: 0 5px 5px 0;
    border: 0;
    left: 100%;
    top: 180px;
}
.customOffcanvas {
    width: 250px;
    background: #414141;
    border-color: #414141;
}
.sideBar-btn .navbar-toggler-icon {
    width: 20px;
    height: 30px;
    font-size: 0;
}
.sideBar-btn .navbar-toggler-icon::after,
.sideBar-btn .navbar-toggler-icon::before,
.sideBar-btn .navbar-toggler-icon b {
    background-color: #b3b3b3;
    -o-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 5px;
    content: '';
    display: block;
    height: 2px;
    margin: 6px 0;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    font-size: 0;
}
.offcanvas[aria-modal='true'] .sideBar-btn .navbar-toggler-icon::before {
    transform: translateY(10px) rotate(135deg);
    -moz-transform: translateY(10px) rotate(135deg);
    -webkit-transform: translateY(10px) rotate(135deg);
    width: 20px;
    height: 3px;
    -ms-transform: translateY(10px) rotate(135deg);
    -o-transform: translateY(10px) rotate(135deg);
}
.offcanvas[aria-modal='true'] .sideBar-btn .navbar-toggler-icon b {
    -moz-transform: scale(0);
    -webkit-transform: scale(0);
    transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
}
.offcanvas[aria-modal='true'] .sideBar-btn .navbar-toggler-icon::after {
    transform: translateY(-7px) rotate(-135deg);
    -moz-transform: translateY(-7px) rotate(-135deg);
    -webkit-transform: translateY(-7px) rotate(-135deg);
    width: 20px;
    height: 3px;
    -ms-transform: translateY(-7px) rotate(-135deg);
    -o-transform: translateY(-7px) rotate(-135deg);
}
.offcanvas.offcanvas-start:not(.show) ~ div.offcanvas-backdrop {
    display: none;
}
.offcanvas-body > ul > li:not(:last-child) {
    border-bottom: 1px solid #545454;
}
.offcanvas-body > ul > li > a {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    padding: 15px 0;
    color: #fff;
    justify-content: space-between;
    font-size: 16px;
}
.offcanvas-body > ul > li > a .icon {
    font-size: 12px;
    transform: rotate(90deg);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
}
.offcanvas-body > ul > li > a[aria-expanded='true'] .icon {
    transform: rotate(270deg);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    -o-transform: rotate(270deg);
}
.offcanvas-body > ul > li ul {
    margin-bottom: 20px;
    list-style: none;
    margin-left: 0;
    padding: 0 0 0 10px;
}
.offcanvas-body > ul > li ul a {
    font-size: 14px;
    color: #fff;
}
.offcanvas-body a:hover,
.offcanvas-body a:focus {
    color: #fff;
}
.offcanvas-body {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.offcanvas-body::-webkit-scrollbar {
    display: none;
}
.tooltip-inner {
    background: #0b3454;
    border: 1px solid #0b3454;
}
.bs-tooltip-top .tooltip-arrow::before {
    border-top-color: #0b3454;
}
.alumni-switch-container {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    width: 261px;
    height: 40px;
}
.alumni-switch-container .switch {
    width: 100%;
    height: 40px;
}
.alumni-switch-container .switch label {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    color: #fff;
    text-align: center;
    height: 100%;
    cursor: pointer;
    position: relative;
    opacity: 0.6;
}
.switch {
    position: relative;
}
.switch > span {
    width: 50%;
    height: 40px;
    background: #fafcff;
    content: '';
    border-radius: 20px;
    position: absolute;
}
.alumni-switch-container * {
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.alumni-switch-container .form-check-input[id*='shortlistCheck']:checked ~ div.switch::before {
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.alumni-switch-container .form-check-input[id*='finalizeCheck']:checked ~ div.switch::before {
    transform: translateX(100%);
    transition: all 0.3s ease-in-out;
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
}
.alumni-switch-container .form-check-input[id*='shortlistCheck']:checked ~ div.switch label[for*='shortlistCheck'] {
    color: #0b3454;
    opacity: 1;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.alumni-switch-container .form-check-input[id*='finalizeCheck']:checked ~ div.switch label[for*='finalizeCheck'] {
    color: #0b3454;
    opacity: 1;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.alumni-switch-container .switch > a {
    width: 50%;
    color: rgba(255, 255, 255, 0.6);
    text-decoration: none;
    position: relative;
    z-index: 1;
}
.alumni-switch-container .switch > a.active {
    color: #0b3454;
}
.alumni-switch-container .switch > a.btn-allCampuses.active + span {
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
}
.aldir_scroll {
    display: none;
}
.aldir_scroll.active {
    display: block;
}
.load_more_btn img {
    animation: spin 4s infinite linear;
    -webkit-animation: spin 4s infinite linear;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
    }
}
.filterChecked .selected {
    background: #ffffff;
    box-shadow: 0px 1px 1px #f1f1f1;
    border: 1px solid #dedede;
    border-radius: 7px;
    opacity: 1;
    margin: 3px;
    padding: 0px 10px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn-back * {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.btn-back {
    text-decoration: none;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
}
.btn-back:hover span {
    text-decoration: underline;
}
.btn-back:hover .icon-left-arrow {
    -moz-transform: translateX(-5px);
    -webkit-transform: translateX(-5px);
    -o-transform: translateX(-5px);
    -ms-transform: translateX(-5px);
    transform: translateX(-5px);
}
.calendar_status {
    display: none;
}
.fc-header-toolbar {
    background: #f2f9ff;
    padding: 20px 30px;
}
.fc-toolbar-chunk:first-child > div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
}
.fc-theme-standard td,
.fc-theme-standard th {
    position: relative;
}
.fc-toolbar-chunk:first-child > div .fc-toolbar-title {
    margin: 3px 10px 0;
    font-size: 18px;
    font-weight: 600;
}
.fc-toolbar-chunk:first-child > div .fc-button {
    background: none;
    border: 0;
    color: #000;
    padding: 0;
}
.fc-toolbar-chunk:first-child > div .fc-button.fc-button-primary:focus {
    box-shadow: none;
}
.fc-toolbar-chunk:first-child > div .fc-button.fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active {
    color: #000;
    color: var(--fc-button-text-color, #fff);
    background-color: transparent;
    background-color: var(--fc-button-active-bg-color, transparent);
    border-color: transparent;
    border-color: var(--fc-button-active-border-color, transparent);
}
.fc-toolbar-chunk:last-child > div.fc-button-group {
    max-width: 312px;
    width: 312px;
    background: #fff;
    border: 1px solid #b9b9b9;
    border-radius: 20px;
    opacity: 1;
}
.fc-toolbar-chunk:last-child > div.fc-button-group .fc-button {
    background: #fff;
    font-size: 16px;
    color: #000;
    text-transform: capitalize;
    font-weight: 500;
    border: 0;
    border-radius: 20px;
    position: relative;
    z-index: 1;
}
.fc-toolbar-chunk:last-child > div.fc-button-group .fc-button.fc-button-active {
    background: #5cae48;
    color: #fff;
    border: 0;
    position: relative;
    z-index: 2;
}
.fc-toolbar-chunk:last-child > div.fc-button-group .fc-button:focus {
    outline: none;
    box-shadow: none;
}
.fc-view {
    padding: 10px 30px 30px;
}
.calendar_filter {
    display: none;
}
.fc-col-header .fc-scrollgrid-sync-inner {
    height: 50px;
    padding: 7px 15px;
    text-align: left;
}
.fc-col-header .fc-scrollgrid-sync-inner .fc-col-header-cell-cushion {
    font-size: 14px;
    text-decoration: none;
    font-weight: 500;
    text-transform: uppercase;
    padding: 0;
    color: #000;
}
.fc div.fc-daygrid-day-top {
    flex-direction: row;
}
.fc a.fc-daygrid-day-number {
    padding: 18px;
    text-decoration: none;
    color: #000;
    font-size: 14px;
    font-weight: 500;
}
.fc-direction-ltr .fc-daygrid-week-number {
    display: none;
}
.fc .fc-bg-event.bluesection {
    background: #f2f9ff;
    opacity: 1;
}
.fc-scrollgrid-sync-inner.eventHas {
    background: #f2f9ff;
    opacity: 1;
    border-left: 2px solid #26a7df;
}
.fc .fc-bg-event.bluesection .fc-event-title {
    margin-top: 44px;
    padding-left: 8px;
    font-weight: 500;
}
.fc-scrollgrid-sync-inner.eventHas {
    background: #f2f9ff;
    border-left: 2px solid #26a7df;
    max-height: 100%;
    box-sizing: border-box;
    max-width: 100%;
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    position: absolute !important;
}
.fc .fc-daygrid-body-balanced div.fc-daygrid-day-events {
    left: 10px;
}
.fc .fc-daygrid-event {
    color: #0b3454;
}
.fc-daygrid-dot-event div.fc-event-title {
    font-size: 13px;
    font-weight: 400;
}
.fc-direction-ltr .fc-daygrid-event div.fc-event-time {
    font-size: 13px;
    font-weight: 400;
    padding-right: 3px;
}
.fc .fc-daygrid-event-harness a.bluesection div {
    color: #000;
}
.fc-daygrid-block-event .fc-event-title {
    font-size: 12px;
}
.fc-media-screen .fc-timegrid-event {
    background: #f2f9ff;
    border-color: #26a7df;
    border-width: 0 0 0 2px;
    border-radius: 0;
    box-shadow: none !important;
}
.fc-direction-ltr .fc-timegrid-col-events {
    margin: 0;
}
div.fc-daygrid-event-dot {
    border: calc(var(--fc-daygrid-event-dot-width, 8px) / 2.5) solid var(--fc-event-border-color, #26a7df);
}
.fc .fc-timeGridWeek-view .fc-bg-event.bluesection .fc-event-title {
    margin: 6px 0 0;
}
.fc .fc-timeGridWeek-view .fc-v-event .fc-event-main-frame {
    color: #000;
}
.fc-direction-ltr .fc-timegrid-col-events {
    margin: 0;
}
.share_option {
    padding: 0;
    margin: 0 -9px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}
.share_option li {
    list-style: none;
    width: 206px;
    margin: 0 5px 10px;
}
.share_option li a {
    width: 100%;
    height: 45px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    border-radius: 6px;
    padding: 8px 15px;
    font-size: 16px;
    color: #fff;
    text-decoration: none;
    align-items: center;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.share_option li a.wa {
    color: #000;
}
.share_option li a:hover {
    -moz-transform: translateY(-3px);
    -webkit-transform: translateY(-3px);
    -o-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    transform: translateY(-3px);
}
.fc-timeGridDay-view .fc-v-event .fc-event-main-frame {
    color: #000;
    padding: 5px 10px;
}
.fc .fc-timegrid-axis-cushion {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #000;
    font-weight: 500;
}
.fc-direction-ltr div.fc-timegrid-col-events {
    margin: 0;
}
.share_option li a img {
    width: 28px;
}
.fb {
    background-color: #3d5a96;
}
.tw {
    background-color: #2aa3ef;
}
.in {
    background-color: #017ab7;
}
.wa {
    background: #41c452;
}
.mobilesidebar .accordion-button {
    max-width: 155px;
    background: none;
    color: #5cae48;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
}
.mobilesidebar .accordion-button:not(.collapsed) {
    background: none;
    box-shadow: none;
}
.mobilesidebar .accordion-button:focus {
    outline: 0;
    box-shadow: none;
}
.paddingx-30 {
    padding-left: 6%;
    padding-right: 6%;
}
#accordionSideMenu .accordion-item {
    border: 0;
    background: none;
    border-bottom: 1px solid #eee;
}
#accordionSideMenu .accordion-button {
    font-size: 16px;
    font-weight: 600;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
}
#accordionSideMenu .accordion-button:not(.collapsed) {
    color: #5cae48;
    background-color: #fff;
    box-shadow: none;
}
.sideMenu .accordion-button:focus {
    box-shadow: none;
}
.sideMenu .accordion-button span {
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.sideMenu .accordion-button::after {
    display: none;
}
.sideMenu .accordion-button:not(.collapsed) span[class*='icon'] {
    transform: rotate(-180deg);
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
}
.toggleSearch {
    width: 33px;
    height: 33px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    border-radius: 50%;
    background: #fff;
    border: 1px solid #e1e1e1;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #414141;
    font-size: 14px;
    right: 0;
}
.mobilesidebar .filterSearch {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    -o-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.mobilesidebar .open .filterSearch {
    -moz-transform: translateX(0px);
    -webkit-transform: translateX(0px);
    -o-transform: translateX(0px);
    -ms-transform: translateX(0px);
    transform: translateX(0px);
}
.needs-validation.was-validated .form-control:invalid,
.login_wrap .login_form form.was-validated .form-group .form-control:invalid,
.needs-validation.was-validated .form-select:invalid {
    border-color: #e55355;
    box-shadow: 0px 0px 10px #e55355 !important;
}
.needs-validation .form-control {
    position: relative;
}
.needs-validation .invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0;
    font-size: 0.875em;
    color: #fff;
    background: #d86465;
    padding: 5px 15px 5px;
    border-radius: 4px;
    position: relative;
    font-size: 13px;
    font-style: italic;
}
.needs-validation .invalid-feedback::before {
    position: absolute;
    content: '';
    width: 10px;
    height: 10px;
    border-bottom: 7px solid #d86465;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    top: -10px;
    left: 20px;
}

@media (min-width: 630px) and (max-width: 767px) {
    .fc .fc-daygrid-body-balanced div.fc-daygrid-day-events {
        left: 10px;
        bottom: 13px;
    }
    .fc .fc-bg-event.bluesection .fc-event-title {
        margin-top: 40px;
        padding-left: 8px;
        font-weight: 500;
        white-space: nowrap;
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .fc-v-event .fc-event-title {
        white-space: nowrap;
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .fc .fc-timegrid-axis-cushion {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        align-items: center;
        text-decoration: none;
        color: #000;
        font-weight: 500;
    }
}

@media (max-width: 767px) {
    .fc-header-toolbar {
        padding: 20px 0px;
    }
    .fc-header-toolbar > div {
        width: 90%;
        margin: auto;
        text-align: center;
    }
    .fc-view {
        padding: 10px 0px 30px;
        width: 90%;
        margin: auto;
    }
}

@media (max-width: 629px) {
    .share_option {
        justify-content: center;
    }
    .fc-toolbar-chunk {
        width: 100%;
    }
    .fc-toolbar-chunk:first-child > div {
        justify-content: center;
        margin-bottom: 15px;
    }
    .fc .fc-toolbar {
        -webkit-box-wrap: wrap;
        -moz-box-wrap: wrap;
        -ms-flex-wrap: wrap;
        -webkit-wrap: wrap;
        flex-wrap: wrap;
    }
    .fc-toolbar-chunk:last-child > div.fc-button-group {
        width: 100%;
    }
    .fc a.fc-daygrid-day-number {
        padding: 8px;
        font-size: 12px;
    }
    .fc .fc-bg-event.bluesection .fc-event-title {
        margin-top: 30px;
        padding-left: 0px;
        font-weight: 500;
    }
    .fc-col-header .fc-scrollgrid-sync-inner {
        padding: 5px;
        height: 40px;
    }
    .fc .fc-daygrid-body-balanced .fc-daygrid-day-events {
        left: 0 !important;
        bottom: 0;
        font-size: 12px;
    }
    .fc .fc-bg-event.bluesection .fc-event-title {
        margin-top: 14px;
        padding-left: 0px;
        font-weight: 500;
        font-size: 11px;
        max-width: 36px;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .fc-direction-ltr .fc-timegrid-col-events {
        margin: 0;
    }
    .fc-timegrid-event {
        border-radius: 0;
        background: #f2f9ff;
        border-left-color: #26a7df;
        border-width: 0 0 0 2px;
        padding: 5px 12px;
        color: #000 !important;
    }
    .fc-v-event .fc-event-main-frame {
        color: #000;
    }
    .fc .fc-timegrid-axis-cushion {
        max-width: 60px;
        flex-shrink: 0;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        align-items: center;
        text-decoration: none;
        color: #000;
        font-weight: 500;
    }
}

@media (max-width: 480px) {
    .fc-col-header .fc-scrollgrid-sync-inner .fc-col-header-cell-cushion {
        font-size: 12px;
    }
    .fc .fc-timegrid-axis-cushion {
        font-size: 16px;
    }
    .fc a.fc-daygrid-day-number {
        padding: 5px;
        font-size: 12px;
    }
    .fc .fc-daygrid-more-link {
        white-space: nowrap;
    }
    .fc-media-screen .fc-timegrid-event {
        padding: 3px;
    }
}

@media (max-width: 400px) {
    .courseLabel {
        margin-right: 0;
    }
    .alumni_college_bottom {
        flex-direction: column;
    }
}

.modal.show .modal-dialog {
    margin-left: auto;
    margin-right: auto;
}
#html5box-html5-lightbox {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 97px);
}
#html5-lightbox-box {
    margin: auto !important;
}
.html5-elem-data-box {
    position: fixed !important;
    top: 15px;
    left: 0;
    right: 0;
    background: none !important;
    color: #fff !important;
    height: auto;
}
#html5box-html5-lightbox .html5-text {
    color: #fff;
    text-align: center;
    margin: 0 !important;
    height: auto;
}
#html5box-html5-lightbox .html5-text .html5-title {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    height: auto;
    align-items: center;
    color: #fff;
    font-weight: 500;
}
#html5box-html5-lightbox .html5-text .html5-title .html5-playpause {
    display: none !important;
}
.html5-nav-thumb.video img {
    position: relative;
}
.html5-nav-thumb.video:before {
    content: '\e91f';
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    z-index: 1;
    position: absolute;
    left: 0;
    right: 0 !important;
    bottom: 0;
    top: 0 !important;
    margin: auto;
    color: #fff;
    font-size: 25px;
    text-align: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
}
.html5-nav-thumb.video {
    position: relative;
}
.no-background-img {
    background-image: none !important;
}
.customselect_parnt .custom-select {
    position: relative;
    width: max-content;
    flex: unset;
    min-width: 109px;
}
.customselect_parnt .custom-select select {
    display: none;
}
.customselect_parnt .select-selected {
    background-color: DodgerBlue;
}
.customselect_parnt .select-selected:after {
    position: absolute;
    content: '';
    top: 14px;
    right: 10px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-color: #fff transparent transparent transparent;
}
.customselect_parnt .select-selected.select-arrow-active:after {
    border-color: transparent transparent #fff transparent;
    top: 7px;
}
.customselect_parnt .select-selected {
    color: #414141;
    padding: 0px 30px 0 14px;
    border: 2px solid #7d7d7d;
    border-color: #7d7d7d;
    cursor: pointer;
    border-radius: 18px;
    height: 32px;
    width: max-content;
    transition: all 0.3s ease-in-out;
    background: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 32px;
    min-width: 65px;
    box-sizing: initial;
    border-radius: 18px;
}
.customselect_parnt .select-selected::after {
    position: absolute;
    content: '';
    top: 10px;
    right: 12px;
    width: 10px;
    height: 10px;
    border: unset;
    border-top: 2px solid #7d7d7d;
    border-right: 2px solid #7d7d7d;
    transform: rotate(135deg);
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
}
.customselect_parnt .select-selected.select-arrow-active::after {
    border: unset;
    top: 15px;
    border-top: 2px solid #7d7d7d;
    border-right: 2px solid #7d7d7d;
    transform: rotate(-45deg);
    width: 10px;
    height: 10px;
    right: 12px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
}
.customselect_parnt .select-items div {
    color: #000000;
    padding: 3px 16px;
    border: 0px solid transparent;
    border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
}
.customselect_parnt .select-items div:first-child {
    margin-top: 10px;
}
.customselect_parnt .select-items div:last-child {
    margin-bottom: 10px;
}
.customselect_parnt .select-items {
    position: absolute;
    background-color: #fff;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
    border: 1px solid #dedede;
    border-radius: 18px;
    box-shadow: 0px 10px 20px #0000001a;
    width: 145px;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    -ms-border-radius: 18px;
    -o-border-radius: 18px;
}
.customselect_parnt .select-hide {
    display: none;
}
.customselect_parnt .select-items div:hover,
.customselect_parnt .same-as-selected {
    background-color: rgba(0, 0, 0, 0.1);
}
.needs-validation.was-validated.contfwrap .form-control:invalid,
.login_wrap .login_form form.was-validated.contfwrap .form-group .form-control:invalid,
.needs-validation.was-validated.contfwrap .form-select:invalid {
    border-color: #e55355;
    box-shadow: 0px 0px 5px #e553554d !important;
}
.needs-validation.contfwrap .invalid-feedback {
    color: #d42941;
    background: transparent;
    padding: 3px 0 0;
    border-radius: unset;
    font-size: 14px;
    font-style: unset;
}
.needs-validation.contfwrap .invalid-feedback::before {
    display: none;
}
.btn-response-msg {
    background: #26a7df;
    line-height: 23px;
    padding: 0;
    max-width: 150px;
    width: 76px !important;
    color: #fff;
    font-weight: 500;
}
.btn-response-msg:hover,
.btn-response-msg.open {
    background: #0b3454;
    color: #fff;
}
.response-cover {
    position: absolute;
    top: 54px;
    background: #0b3454;
    padding: 10px;
    border-radius: 5px;
    z-index: -1;
    left: -31px;
    width: 138px;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    border: 1px solid #0b3454;
    box-shadow: 0px 3px 6px #0000001a;
}
.btn-response-msg.open + div.response-cover {
    opacity: 1;
    z-index: 1;
    top: 39px;
}
.response-cover:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #0b3454;
    left: 0;
    right: 0;
    margin: auto;
    top: -6px;
}
.btn-response-msg:focus {
    box-shadow: none;
}
.tooltip.show {
    opacity: 1;
}
.tooltip-response > div:not(.tooltip-arrow) {
    padding-top: 8px;
    padding-bottom: 8px;
}
.universities_list_wrap {
    overflow: auto;
    max-height: 375px;
    padding: 3px 3px;
}
.universities_list_wrap ul {
    padding: 0;
    margin: 0;
    list-style: none;
}
.universities_list_wrap ul li {
    display: flex;
    align-items: center;
}
.universities_list_wrap ul li .logo_college {
    width: 60px;
    background: #fff;
    padding: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    box-shadow: 0 0 3px 0px #ccc;
    margin-right: 15px;
}
.universities_list_wrap ul li .logo_college img {
    width: auto;
    height: 100%;
}
.universities_list_wrap ul li:not(:last-child) {
    margin-bottom: 15px;
}
.universities_list_wrap ul li span {
    font-size: 18px;
    font-weight: 500;
}
.universities_list_wrap::-webkit-scrollbar {
    width: 7px;
}
.universities_list_wrap::-moz-scrollbar {
    width: 7px;
}
.universities_list_wrap::-webkit-scrollbar-track {
    background: #eee;
}
.universities_list_wrap::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 20px;
    border: 2px solid #eee;
}
.view_new_message_wrap {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    justify-content: end;
    margin: 0;
}
.view_new_message_wrap .form-select {
    border: 2px solid #7d7d7d;
    border-radius: 25px;
    height: 36px;
    min-width: 150px;
    max-width: 150px;
    margin-right: 10px;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
    min-width: 55px;
    box-sizing: initial;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.view_new_message_wrap .btn-green-msg {
    border-radius: 25px;
}
.msg_title.msg_heading_row .btn-complete-now:hover span {
    color: #fff;
}
.alumni_message {
    background: transparent;
    padding: 0;
    font-size: 14px;
    border-radius: 25px;
    line-height: 22px;
    margin: 3px 0 0;
    color: #d42941;
    text-transform: uppercase;
    font-weight: 500;
}
.student_message {
    background: transparent;
    padding: 0;
    font-size: 14px;
    border-radius: 25px;
    line-height: 22px;
    color: #c38400;
    text-transform: uppercase;
    font-weight: 500;
    margin: 3px 0 0;
}
.school_message {
    background: transparent;
    padding: 0;
    font-size: 14px;
    border-radius: 25px;
    line-height: 22px;
    color: #26a7df;
    text-transform: uppercase;
    font-weight: 500;
    margin: 3px 0 0;
}
.msg_title_row .university_ttl span[class*='message'] {
    display: block;
    font-size: 14px !important;
    color: #000;
    line-height: 16px;
}
.mgs_sender {
    flex-direction: column;
    align-items: end;
}

@media (max-width: 1180px) {
    .mgs_sender {
        flex-direction: row;
    }
    .mgs_sender > span[class*='_message'] {
        margin-left: 10px;
        margin-top: -1px;
    }
}

@media (max-width: 650px) {
    .view_new_message_wrap {
        margin-top: 14px;
    }
}

@media (max-width: 575px) {
    .university_ttl {
        width: 100%;
        margin-right: 0 !important;
    }
}

@media (max-width: 400px) {
    .view_new_message_wrap .form-select {
        width: calc(100% - 201px);
        max-width: initial;
        min-width: initial;
    }
}
.user_id {
    position: absolute;
    bottom: -25px;
    right: 10px;
    color: #fff;
    font-size: 14px;
    background: #5cae48;
    font-weight: 500;
    padding: 0 15px;
    border-radius: 10px 10px 0 0;
    white-space: nowrap;
}
header > .container {
    position: relative;
}

/* New css 10012023 */
.alumni-directory-header {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}
.alumni-directory-header_search {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
}
.alumni-directorySearch {
    background: #fff;
    width: 365px;
    height: 40px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    border-radius: 25px;
    overflow: hidden;
    position: relative;
}
.alumni-directorySearch .form-control {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    border: 0;
    padding-left: 0;
}
.alumni-directorySearch .btn.icon-search {
    font-size: 12px;
    padding: 0 10px 0 20px;
}
.alumni-directorySearch .btn-search-cross {
    position: absolute;
    right: 13px;
    top: 0;
    bottom: 0;
    margin: auto;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    color: #000;
    font-weight: 100 !important;
}
.alumni-directorySearch .btn-cross {
    width: 14px;
    height: 14px;
    position: absolute;
    right: -15px;
    top: 0;
    bottom: 0;
    margin: auto;
    transform: rotate(0deg);
    opacity: 0;
    transition: all 0.5s ease-in-out;
}
.alumni-directorySearch .btn-cross:before {
    content: '';
    position: absolute;
    width: 14px;
    height: 2px;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    margin: auto;
    background: #000;
    border-radius: 5px;
}
.alumni-directorySearch .btn-cross:after {
    content: '';
    position: absolute;
    width: 2px;
    height: 14px;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    margin: auto;
    background: #000;
    border-radius: 5px;
}
.alumni-directorySearch .form-control:valid + .btn-cross {
    opacity: 1;
    right: 15px;
    transform: rotate(135deg);
}
.alumni_directory_filter {
    padding: 30px 0;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
}
.alumni_directory_filter .ji_filtersec {
    width: 100%;
    max-width: 100%;
}
.alumni_directory_filter .fiterItem {
    width: 220px;
    margin: 0 5px;
    box-shadow: 0px 10px 25px #00000008;
}
.alumni_directory_filter .fiterItem.key {
    border-color: #5cae48;
}
.alumni_directory_filter .filterGroup {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    align-items: start;
    margin: 0 -5px;
}
.alumni_directory_filter .filterSearchblk {
    background: #fff;
    padding: 0 14px 10px;
}
.alumni_directory_filter .filterSearchblk .filterSearch input {
    margin: 0;
}
.alumni_directory_filter .filterHeader button {
    transition: all 0.3s ease-in-out;
    padding: 15px 15px;
}
.alumni_directory_filter .filterHeader button:not([aria-expanded='false']) {
    padding-bottom: 8px;
}
.alumni_directory_filter .fiterItem .card {
    padding: 0px 15px 15px;
}
.alumni_directory_filter .scrollMe {
    max-height: 122px;
    min-height: 122px;
    overflow-x: hidden;
}
.alumni_directory_filter .fiterItem .form-check:not(:last-child) {
    margin-bottom: 4px;
}
.additionalfilter_count {
    display: none;
}
.btn-md-filter {
    text-decoration: none;
    transform: rotate(90deg);
    color: #000;
    font-size: 20px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.btn-md-filter:hover {
    color: #000;
}
.btn-md-filter[aria-expanded='true'] {
    transform: rotate(270deg);
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    -o-transform: rotate(270deg);
}
.additionalfilter_wrap {
    width: 100%;
    padding: 0 5px;
}
.alumni_directory_filter .filterGroup > div {
    order: 1;
}
.alumni_directory_filter .filterGroup > div:nth-child(5) ~ div {
    order: 3;
}
.alumni_directory_filter .filterGroup > div:nth-child(5) ~ div.additionalfilter_wrap {
    order: 2;
}
.alumni_directory_filter .filterGroup > div:nth-child(5) ~ div.additionalfilter_wrap.last {
    order: 9;
}
.alumni_directory_filter .fiterItem.campus {
    width: 450px;
}
.showProfiles_wrap {
    background: #fff;
    border: 1px solid #d2d2d2;
    border-radius: 6px;
    box-shadow: 0px 10px 25px #00000008;
    width: calc(100% - 10px);
    margin: 0 5px;
    padding: 17px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.showProfiles_left {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
}
.showProfiles_left h3 {
    margin: 0 30px 0 0;
    font-size: 18px;
    line-height: 20px;
    font-weight: 600;
}
.showProfiles_left .form-check-label {
    font-size: 16px;
}
.showProfiles_left .form-check-input {
    width: 20px;
    height: 20px;
    vertical-align: top;
    margin-top: -2px;
    margin-right: 10px;
}
.showProfiles_left .form-check.form-check-inline {
    display: inline-flex;
    align-items: center;
}
.showProfiles_left .check_list {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
}
.showProfiles_left .form-check-input:checked {
    background-color: #5cae48;
    border-color: #5cae48;
}
.showProfiles_right .icon {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/subtraction.svg);
    width: 16px;
    height: 25px;
    margin-right: 8px;
}
.showProfiles_right {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
}
.showProfiles_right .title {
    font-size: 14px;
    letter-spacing: -0.35px;
}
.alumni-bucket {
    background: #fff;
    border: 1px solid #d2d2d2;
    border-radius: 6px;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}
.alumni-bucket .block-link {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    z-index: 1;
}
.alumni_img {
    width: 60px;
    margin-right: 10px;
    height: 60px;
    background: #f1f1f1;
    border-radius: 50%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.alumni_img img {
    width: auto;
    height: 100%;
    border-radius: 50%;
}
.alumni_img.no_pic {
    align-items: end;
}
.alumni_img.no_pic img {
    width: auto;
    height: auto;
}
.alumni_name_brife h3 {
    font-size: 18px;
    margin: 0;
}
.class_year {
    color: #6a6868;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
}
.alumni_directory_details .btn-viewcompus {
    font-size: 13px;
    color: #1452ce;
    font-weight: 500;
    line-height: 18px;
    position: relative;
    z-index: 2;
    text-decoration: none;
}
.alumni_directory_details .btn-viewcompus:hover {
    color: #0b3454;
    text-decoration: underline;
}
.alumni_directory_details a.btn-send {
    padding: 0 20px;
    line-height: 36px;
    text-decoration: none;
    background: #5cae48;
    border-radius: 25px;
    height: 36px;
    display: inline-flex;
    color: #fff;
    align-items: center;
    transition: all 0.3s ease-in-out;
    position: relative;
    z-index: 2;
}
.alumni_directory_details a.btn-send:hover {
    background: #40862f;
}
.alumni_directory_details a.btn-send span {
    display: none;
}
.icon-alumni-send {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/send_alumni_profile.svg) no-repeat 0
        0 / 100%;
    width: 19px;
    height: 19px;
    margin-right: 0;
    margin-left: 0;
}
.alumni_univarsity_details {
    background: #f9f9f9;
    border-radius: 0px 0px 5px 5px;
    opacity: 1;
    display: flex;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 10px 20px 15px;
    position: relative;
}
.alumni_univarsity_details .alumni_univarsity_details_inner_cover {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
}
.alumni_univarsity_details .alumni_univarsity_details_inner_cover .alumni_univarsity_logo {
    width: 64px;
    margin-right: 10px;
    margin-top: 6px;
    height: 64px;
    background: #e6e6e6;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.alumni_univarsity_details .alumni_univarsity_details_inner_cover .alumni_univarsity_logo img {
    width: 100%;
}
.alumni_univarsity_details .alumni_univarsity_details_inner_cover .alumni_univarsity_logo img[src*='no_img.png'] {
    max-width: 35px;
}
.alumni_univarsity_details .alumni_univarsity_details_inner_cover .alumni_univarsity_brife {
    width: calc(100% - 75px);
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.alumni_directory_details a.alumni_name_link {
    color: #000;
    text-decoration: none;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    display: inline-block;
}
.alumni_directory_details a.alumni_name_link:hover {
    color: #1452ce;
}
.alumni_class_link {
    padding: 15px 20px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
}
.alumni_name_detail {
    width: calc(100% - 64px);
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
}
.alumni_name_brife {
    display: flex;
    flex-direction: column;
    width: calc(100% - 70px);
    justify-content: center;
}
.alumni_education {
    color: #4d4d4d;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
}
.alumni_univarsity_name {
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    margin-bottom: 3px; /* word-break: break-sall; */
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.alumni_univarsity_name .icon-subtraction {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/subtraction.svg) no-repeat 0 0 / 100%;
    width: 12px;
    height: 20px;
    margin-left: 3px;
    display: inline-block;
    vertical-align: top;
}
.alumni_univarsity_name span:empty ~ .icon-subtraction {
    display: none;
}
.alumni_univarsity_subject {
    color: #0b3454;
    font-size: 14px;
    line-height: 16px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
}
.alumni_univarsity_subject span {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.alumni_univarsity_subject i {
    width: 16px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/subject_setting.svg) no-repeat 0 0 /
        100%;
    height: 16px;
    margin-right: 7px;
}
.filter_result ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
}
.filter_result_title {
    margin-bottom: 18px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 10px;
}
.filter_result_title .filter_result {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.filter_result ul li {
    background: #e9f6fc;
    border-radius: 25px;
    padding: 9px 23px;
    margin-top: 6px;
    margin-bottom: 6px;
}
.filter_result ul li:not(:last-child) {
    margin-right: 10px;
}
.filter_result ul li:last-child {
    background: no-repeat;
    padding: 9px 0;
}
.filter_result ul li span {
    margin-right: 10px;
}
.filter_result ul li .btn-cross {
    font-size: 10px;
    color: #000;
    text-decoration: none;
}
.filter_result ul li .btn-cross:hover {
    color: #105271;
}
.alumni_count {
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    margin: 18px 0 0;
}
.btn-additionalfilter {
    text-decoration: none;
    font-size: 14px;
    color: #1452ce;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    transition: all 0.3s ease-in-out;
}
.btn-additionalfilter span {
    text-decoration: underline;
    margin-right: 10px;
}
.btn-additionalfilter .icon-right-arrow {
    transform: rotate(90deg);
    transition: all 0.3s ease-in-out;
}
.btn-additionalfilter[aria-expanded='true'] .icon-right-arrow {
    transform: rotate(270deg);
}
.btn-additionalfilter:hover {
    color: #0b3454;
}
.btn-additionalfilter:hover span {
    text-decoration: none;
}
.alumni_directory_filter .filters-head h3 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    margin: 0;
    align-items: center;
}
.alumni_directory_filter .filters-head h3 .icon-filter + span {
    flex: 1;
}
.alumni_directory_filter .filters-head h3[aria-expanded='true'] i {
    transform: rotate(270deg);
}
.alumni_directory_details .load_more_btn {
    text-decoration: none;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
}
.alumni_directory_details .load_more_btn:hover {
    background: #0b3454;
    color: #fff;
    border-color: #0b3454;
}
.alumni_directory_details .load_more_btn img {
    -webkit-animation: none;
}
.alumni_directory_details .load_more_btn:hover img {
    filter: invert(1) brightness(100);
}
.tooltip-viewcompus .tooltip-arrow::before {
    display: none;
}
.alumni_profile_brife_wrap {
    border-bottom: 1px solid #e7e7e7;
}
.alumni_profile_brife {
    padding: 40px 0;
}
.alumni_profile_brife .btn-back {
    color: #000;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
}
.alumni_profile_brife .btn-back .icon-right-arrow {
    transform: scaleX(-1);
    margin-right: 7px;
    font-size: 12px;
}
.alumni_profile_brife .btn-back:hover {
    color: #0b3454;
}
.alumni_profile_name_college_other_detail_wrap {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
}
.alumni_profile_image_name_other_detail {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    padding: 0 45px;
    position: relative;
    width: 45%;
}
.alumni_profile_image_name_other_detail:after {
    content: '';
    width: 1px;
    height: 85%;
    background: #d4d4d4;
    position: absolute;
    right: 0;
    top: 0;
}
.alumni_profile_image {
    width: 110px;
    height: 110px;
    background: #f1f1f1;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin-right: 20px;
}
.alumni_profile_image img {
    width: auto;
    height: 100%;
}
.alumni_profile_image.no_pic {
    align-items: end;
}
.alumni_profile_image.no_pic img {
    height: 80%;
}
.alumni_profile_name_other_detail {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.alumni_profile_name_other_detail h2 {
    font-size: 30px;
    line-height: 18px;
    font-weight: 600;
    word-break: break-all;
    line-height: 35px;
}
.alumni_profile_name_other_detail .alumni_profile_location {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 15px;
}
.alumni_profile_class_college {
    position: relative;
    padding: 0 0 0 20px;
    margin-bottom: 13px;
}
.alumni_profile_class_college:before {
    content: '';
    position: absolute;
    left: 6px;
    top: 0;
    bottom: 0;
    width: 3px;
    height: 100%;
    background: #d52941;
    border-radius: 5px;
}
.alumni_profile_class_college .alumni_profile_class {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.32px;
    line-height: 18px;
    margin-bottom: 5px;
}
.alumni_profile_class_college .alumni_profile_college {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.32px;
    line-height: 18px;
}
.alumni_profile_college_phone {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
.alumni_profile_college_phone .verifyLink {
    font-size: 16px;
    font-weight: 500;
}
.verifiedLink {
    color: #5cae48;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
}
.alumni_profile_name_other_detail .btn-send {
    width: auto;
    height: 40px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    background: #5cae48;
    border-radius: 25pc;
    color: #fff;
    text-decoration: none;
    align-items: center;
    padding: 0 25px;
    font-size: 16px;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
}
.alumni_profile_name_other_detail .btn-send .icon-send {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/send_alumni_profile.svg) no-repeat 0
        0 / 100%;
    width: 19px;
    height: 19px;
    margin-right: 10px;
    position: relative;
    transition: all 0.3s ease-in-out;
    left: 0;
}
.alumni_profile_name_other_detail .btn-send:hover {
    background: #2fa612;
}
.alumni_profile_name_other_detail .btn-send:hover .icon-send {
    left: 3px;
}
.alumni_profile_college_name_other_detail_wrap {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    padding: 0 0 0 80px;
}
.alumni_profile_college_name_other_detail_wrap .alumni_profile_college_logo {
    width: 100px;
    height: 100px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid #d2d2d2;
    margin-right: 18px;
}
.alumni_profile_college_name_other_detail_wrap .alumni_profile_college_logo img {
    width: 80%;
    height: auto;
}
.alumni_profile_college_name_other_detail_wrap .alumni_profile_college_logo img[src*='college_icon'] {
    width: 40px;
}
.alumni_profile_college_name_other_detail_wrap .alumni_profile_college_logo img[src*='no_img'] {
    width: auto;
}
.alumni_profile_college_name_other_detail_wrap .alumni_profile_college_name_other_detail {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    align-items: start;
    width: calc(100% - 120px);
}
.alumni_profile_brife_tab {
    margin-bottom: 20px;
}
.alumni_profile_status {
    list-style: none;
    margin: 0;
    padding: 0;
}
.alumni_profile_status li {
    margin-bottom: 16px;
    width: 176px;
    position: relative;
    padding-left: 16px;
}
.alumni_profile_status li .title {
    letter-spacing: 0.32px;
    color: #000000;
    font-size: 16px;
}
.alumni_profile_status li p {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.32px;
}
.alumni_profile_status li .alumni_profile_name_other_detail{
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

.alumni_profile_status li:before {
    content: '';
    width: 3px;
    height: 17px;
    position: absolute;
    left: 0;
    top: 4px;
    background: #d52941;
    border-radius: 5px;
}
.alumni_profile_college_joined {
    color: #5cae48;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    margin-bottom: 5px;
}
.alumni_profile_college_name_detail {
    width: calc(100% - 176px);
    padding-right: 50px;
}
.alumni_profile_college_name_other_detail_wrap .alumni_profile_college_name_detail h3 {
    font-size: 20px;
    line-height: 26px;
    font-weight: 600;
}
.alumni_profile_college_subject {
    height: 30px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    background: #e5f1fb;
    border-radius: 25px;
    font-size: 16px;
    line-height: 16px;
    font-weight: 600;
    color: #0b3454;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    max-width: 100%;
}
.alumni_profile_college_subject span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 20px);
}
.alumni_profile_college_subject .icon {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/course_alumni_profile.svg);
    width: 16px;
    height: 16px;
    margin-right: 8px;
    min-width: 16px;
}
.alumni_profile_detail {
    padding: 30px 0;
}
.alumni_profile_brife_tab .nav {
    justify-content: center;
}
.alumni_profile_brife_tab .nav-item:not(:last-child) {
    margin-right: 10px;
}
.alumni_profile_brife_tab .nav-pills .nav-link {
    min-width: 100px;
    height: 40px;
    white-space: nowrap;
    border: 2px solid #7d7d7d;
    border-radius: 25px;
    background: #fff;
    color: #000;
    padding: 0 20px;
}
.alumni_profile_brife_tab .nav-pills .nav-link.active,
.alumni_profile_brife_tab .nav-pills .nav-link:hover {
    background: #0b3454;
    border-color: #0b3454;
    color: #fff;
}
.underGraduate-detail .main_title,
.blockHeader {
    font-size: 22px;
    color: #000;
    width: auto;
    margin: -40px -40px 25px;
    border-radius: 6px 6px 0px 0px;
    padding: 20px 40px;
    line-height: 22px;
    border-bottom: 1px solid #f5f5f5;
}
.blockHeader .title span {
    font-weight: 600;
}
.graduateRow .college_detail_wrap .college_detail h3 {
    font-size: 20px;
    font-weight: 600;
    margin: 0 0 2px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.college_name_scholarship .college_name a {
    color: #000;
    text-decoration: none;
}
/* AGS-362 @dt 2nd.Aug,2K23 @by RG */

/* .graduateRow .college_detail_wrap:hover h3 {text-decoration: underline;color: #1452ce;} */
.graduateRow .college_detail_wrap .college_detail h3 .icon-subtraction {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/subtraction.svg) no-repeat 0 0 / 100%;
    width: 16px;
    height: 25px;
    margin-left: 3px;
    display: inline-block;
    vertical-align: top;
}
.graduateRow .college_detail_wrap .college_detail p {
    font-size: 16px;
    color: #414141;
    margin: 0 0 5px;
}
.graduateRow {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    position: relative;
}
.graduateRow:not(:last-child) {
    border-bottom: 2px solid #e5e5e5;
    margin-bottom: 28px;
    padding-bottom: 28px;
}
.underGraduate-detail,
.postGraduate-detail {
    box-shadow: 0px 10px 25px #00000008;
    border-radius: 6px;
    background: #fff;
    padding: 40px;
}
.graduateRow .college_detail_wrap {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    width: calc(100% - 265px);
    padding-right: 40px;
    position: relative;
    align-items: center;
}
.graduateRow .college_detail_wrap > a {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}
.graduateRow .college_detail_wrap:after {
    content: '';
    position: absolute;
    top: 13px;
    right: 0;
    width: 2px;
    height: 63px;
    background: #d1d1d1;
}
.graduateRow .offer {
    width: 265px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: center;
}
.graduateRow .college_detail_wrap .college_detail {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
}
.graduateRow .college_detail_wrap .college_detail .college_logo {
    margin-right: 20px;
    width: 150px;
    height: 95px;
    border: 1px solid #d5d5d5;
    border-radius: 6px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
}
.graduateRow .college_detail_wrap .college_detail .college_logo img[src*="no_university_img"]{width:50px}
.college_name_scholarship {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
.college_name_scholarship .college_name {
    width: calc(100% - 100px);
    display: flex;
    flex-direction: column;
    align-items: start;
}
.graduateRow .college_detail_wrap .college_detail .college_logo .logo_link {
    padding: 10px;
    display: inline-block;
    width: 100%;
    height: 100%;
}
.graduateRow .college_detail_wrap .scholarship {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    text-align: center;
    line-height: 20px;
}
.graduateRow .college_detail_wrap .scholarship .scholarship_label {
    font-weight: 500;
    color: #8a8a8a;
}
.graduateRow .college_detail_wrap .scholarship .scholarship_amt {
    font-weight: 600;
    white-space: nowrap;
}
.graduateRow .offer > a,
.graduateRow .offer a.joindeLink {
    padding: 0 20px;
    text-decoration: none;
    height: 37px;
    background: #5cae48;
    color: #fff;
    border-radius: 25px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    transition: all 0.3s ease-in-out;
}
.graduateRow .offer > a:hover,
.graduateRow .offer a.joindeLink:hover {
    background: #3e9329;
}
.graduateRow .offer > a i,
.graduateRow .offer a.joindeLink i {
    margin-right: 10px;
}
.graduateRow .offer > a:hover {
    background: #3b8d27;
}
.graduateRow .offer .got_offer {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 5px;
}
.graduateRow .offer .got_offer i {
    margin-right: 10px;
    font-size: 20px;
    color: #5cae48;
}
.graduateRow .offer .offerLink {
    color: #1452ce;
}
.graduateRow .offer .offerLink:hover {
    color: #0b3454;
}
.graduateRow .offer .offer_joined i {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/hat_alumni.svg);
    width: 23px;
    height: 17px;
    margin-right: 10px;
}
.graduateRow .offer .offer_joined {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
}
.graduateRow.edit .college_detail_wrap {
    width: calc(100% - 350px);
}
.graduateRow .offer.offer_edit {
    flex-direction: row;
    align-items: center;
    width: 350px;
    justify-content: end;
}
.graduateRow .offer.offer_edit .edit_section,
.edit_section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: 20px;
    align-items: center;
}
.edit_section_inner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.graduateRow .offer .edit_link,
.edit_section .edit_link {
    width: 52px;
    height: 30px;
    background: #eaf4fc;
    border-radius: 20px;
    transition: all 0.3s ease-in-out;
    position: relative;
}
.graduateRow .offer .edit_link:hover,
.edit_section .edit_link:hover {
    background-color: #0b3454;
}
.graduateRow .offer .edit_link:before,
.edit_section .edit_link::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/draw.svg) no-repeat center / 18px
        auto;
}
.graduateRow .offer .edit_link:hover:before,
.edit_section .edit_link:hover::before {
    filter: invert(99%) sepia(1%) saturate(2%) hue-rotate(228deg) brightness(110%) contrast(100%);
}
.graduateRow .offer .del_link,
.edit_section .del_link,
.del_link {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/delete.svg);
    width: 14px;
    height: 16px;
    margin-left: 10px;
    opacity: 0.5;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.graduateRow .offer .del_link:hover,
.edit_section .del_link:hover,
.del_link:hover {
    opacity: 1;
}
.graduateRow .offer .offer_existing_links {
    display: flex;
    flex-direction: column;
    align-items: end;
}
.form-group .del_link {
    position: absolute;
    top: 15px;
    right: -20px;
}
.graduation-title {
    font-size: 22px;
    font-weight: 400;
    margin: -15px 0 20px;
}
.graduation-title span {
    font-weight: 600;
}
.graduation_carousel_wrap {
    margin-top: 20px;
    box-shadow: 0px 10px 25px #00000008;
    border-radius: 6px;
    background: #fff;
    padding: 40px;
}
.graduation_carousel_wrap .card_profiles:first-child {
    margin-bottom: 18px;
}
.graduation_carousel_wrap .seactionheader {
    margin: -10px 0 27px;
}
.graduation_carousel_wrap .seactionheader h3 {
    font-size: 22px;
    font-weight: 400;
}
.graduation_carousel_wrap .seactionheader h3 span {
    font-weight: 600;
}
.owl-item .card_profiles {
    border: 1px solid #d2d2d2;
    padding: 20px;
    border-radius: 6px;
    flex-direction: column !important;
    display: flex;
    justify-content: space-between;
    height: 100%;
    position: relative;
    background: #fff;
    max-height: 240px;
    min-height: 240px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    overflow-y: auto;
}
.card_profiles::-webkit-scrollbar {
    width: 4px;
}
.card_profiles::-moz-scrollbar {
    width: 4px;
}
.card_profiles::-webkit-scrollbar-track {
    background: #ccc;
}
.card_profiles::-webkit-scrollbar-thumb {
    background-color: #666;
    border-radius: 20px;
    border: 2px solid #666;
}
.card_profiles .alumni-Profile-button-rounded {
    position: absolute;
    right: 20px;
    padding: 0 20px;
}
.card_profiles .alumni-Profile-button-rounded:hover,
.card_profiles .alumni-Profile-button-rounded:focus {
    background: #40952c;
    color: #fff;
    background-color: #40952c;
    border-color: 0;
    box-shadow: none;
}
.card_profiles figure.student {
    width: 90px;
    height: 90px;
    overflow: hidden;
    border-radius: 200px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.card_profiles figure.student img {
    border-radius: 200px;
}
.student_details {
    width: calc(100% - 110px);
}
.card_profiles .student_details figure img {
}
.card_profiles .student_details h2 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 15px;
    width: 65%;
    margin-top: 010px;
}
.card_profiles .student_details h2 a {
    color: #000;
    text-decoration: none;
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
    display: inline-block;
    overflow: hidden;
}
.card_profiles .student_details h2 a:hover {
    text-decoration: underline;
    color: #1452ce;
}
.card_profiles .student_details h2 span {
    display: block;
    font-size: 16px;
    color: #6a6868;
    font-weight: 500;
}
.card_profiles .student_details .sub_details {
    display: flex;
    flex-grow: 1 !important;
}
.card_profiles .student_details .sub_details_workExp {
    align-items: center;
    position: relative;
    padding-bottom: 5px;
}
.card_profiles .student_details .sub_details_achivements figure {
    background: #0b3454 !important;
}
.card_profiles .student_details .sub_details_workExp figure {
    border: 0 !important;
    border-radius: 100px !important;
    min-width: 50px !important;
    height: 50px !important;
    width: 50px !important;
    background-color: #d52941;
}
.card_profiles .student_details .sub_details_workExp figure img {
    width: 45%;
    height: auto;
}
.card_profiles .student_details .sub_details_workExp figure.bg_white {
    background-color: #fff !important;
    border: 1px solid #d2d2d2 !important;
}
.card_profiles .student_details .sub_details_workExp figure.bg_white img {
    width: 80%;
}
.card_profiles .student_details .sub_details_workExp:after {
    position: absolute;
    width: 83%;
    height: 1px;
    content: '';
    background: #c1c1c1;
    bottom: 0;
    right: 0;
}
.card_profiles .student_details .sub_details_workExp1:after {
    display: none;
}
.card_profiles .student_details .sub_details h3 {
    margin-bottom: 0;
    min-width: 43px;
    justify-content: center;
}
.card_profiles .student_details .sub_details figure {
    border: 1px solid #d2d2d2;
    border-radius: 100px;
    min-width: 80px;
    height: 80px;
    overflow: hidden;
    margin-right: 10px;
    margin-bottom: 0;
    width: 80px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.card_profiles .student_details .sub_details p {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 5px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 43px;
    overflow: hidden;
    line-height: 18px;
}
.card_profiles .student_details .sub_details a {
    color: #000;
    text-decoration: none;
}
.card_profiles .student_details .sub_details a:hover {
    text-decoration: underline;
    color: #1452ce;
}
.card_profiles .student_details .sub_details p.title,
.card_profiles .student_details .sub_details p.green-title {
    color: #5cae48;
    font-size: 14px;
    margin-bottom: 10px;
}
.card_profiles .student_details .sub_details span {
    color: #333333;
    font-size: 14px;
    margin-bottom: 15px;
    font-weight: 400;
}
.card_profiles .student_details .sub_details span.course {
    color: #0b3454;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    align-items: center;
    margin-bottom: 0;
}
.card_profiles .student_details .sub_details span.course img {
    margin-right: 6px;
    width: 16px;
}
.card_profiles .action_btns {
    margin-top: 20px;
    border-top: 1px solid #d2d2d2;
    padding-top: 20px;
    display: flex;
    justify-content: center !important;
    width: 100%;
}
.card_profiles .action_btns .btn-outline-primary {
    margin-right: 10px;
    border: 2px solid #5bae47;
    border-radius: 50px;
    padding: 7px 20px;
    color: #5bae47;
}
.card_profiles .action_btns .btn-outline-primary:hover img {
    filter: brightness(0) invert(1);
    -webkit-filter: brightness(0) invert(1);
}
.card_profiles .action_btns .btn-outline-primary:hover {
    border: 2px solid #418d2e;
    background-color: #418d2e;
    color: #fff;
}
.card_profiles .action_btns .btn-secondary {
    border-radius: 50px;
    padding: 7px 20px;
    color: #fff;
    background: #0b3454;
}
.card_profiles .action_btns .btn-secondary:hover {
    background: #26a7df;
    border: 1px solid #26a7df;
}
.card_profiles .btn-send {
    width: 123px;
    height: 40px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    background: #5cae48;
    border-radius: 25pc;
    color: #fff;
    text-decoration: none;
    align-items: center;
    padding: 0 25px;
    font-size: 16px;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
}
.card_profiles .btn-send {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    position: absolute;
    top: 20px;
    right: 20px;
}
.card_profiles .btn-send .icon-send {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/send_alumni_profile.svg) no-repeat 0
        0 / 100%;
    width: 19px;
    height: 19px;
    margin-right: 10px;
    position: relative;
    transition: all 0.3s ease-in-out;
    left: 0;
}
.card_profiles .btn-send:hover .icon-send {
    left: 3px;
}
.card_profiles .btn-send:hover {
    background: #3b8d27;
}
.workExperience-detail {
    box-shadow: 0px 10px 25px #00000008;
    border-radius: 6px;
    background: #fff;
    padding: 40px;
}
.workExperience-detail .workExperience-title {
    font-size: 22px;
    font-weight: 400;
    margin: -15px 0 30px;
}
.workExperience-detail .workExperience-title span {
    font-weight: 600;
}
.work-block {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
}
.work-block .work-detail {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: flex;
    flex-direction: column;
}
.work-block .icon {
    width: 55px;
    height: 55px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    margin-right: 20px;
    background: #d52941;
    border-radius: 50%;
    color: #fff;
}
.work-block .work-detail p {
    font-size: 20px;
    line-height: 24px;
    margin: 0 0 5px;
    font-weight: 500;
}
.work-block .work-detail span {
    font-size: 16px;
    color: #6d6b6b;
    line-height: 16px;
    font-weight: 500;
}
.achievements-block {
    box-shadow: 0px 10px 25px #00000008;
    border-radius: 6px;
    background: #fff;
    padding: 30px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    position: relative;
}
.achievements-block:not(:last-child) {
    border-bottom: 1px solid #f5f5f5;
}
.achievements-icon-block {
    padding: 0 55px 0 30px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 185px;
    max-width: 185px;
    align-items: center;
    text-align: center;
    border-right: 1px solid #cecece;
    margin-right: 30px;
}
.achievements-icon-block .icon {
    width: 60px;
    height: 60px;
    background: #0b3454;
    border-radius: 50%;
    margin-bottom: 8px;
    position: relative;
}
.achievements-icon-block .icon.icon-medal:before {
    content: '';
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/exam_ico.svg);
    width: 26px;
    height: 36px;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    margin: auto;
}
.achievements-icon-block .icon.icon-grade:before {
    content: '';
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/exam_ico.svg);
    width: 25px;
    height: 31px;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    margin: auto;
}
.achievements-icon-block .icon.icon-publication:before {
    content: '';
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/publication_ico.svg);
    width: 29px;
    height: 31px;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    margin: auto;
}
.achievements-icon-block .icon.icon-promotion:before {
    content: '';
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/promotion_ico.svg);
    width: 32px;
    height: 37px;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    margin: auto;
}
.achievements-icon-block .icon.icon-course-completion:before {
    content: '';
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/course_ico.svg);
    width: 31px;
    height: 37px;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    margin: auto;
}
.achievements-icon-block .icon.icon-social-service:before {
    content: '';
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/social_ico.svg);
    width: 36px;
    height: 36px;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    margin: auto;
}
.achievements-icon-block .icon.icon-scholarship:before {
    content: '';
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/scholarship_ico.svg);
    width: 39px;
    height: 28px;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    margin: auto;
}
.achievements-icon-block .icon.icon-others:before {
    content: '';
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/others_ico.svg);
    width: 32px;
    height: 32px;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    margin: auto;
}
.achievements-icon-block .achievements-title {
    font-size: 18px;
    font-weight: 600;
}
.achievements-icon-block .achievements-year {
    font-size: 18px;
    line-height: 22px;
    color: #414141;
}
.achievements-block-detail h3 {
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 0px;
}
.achievements-block-detail h3 span {
    font-weight: 600;
}
.achievements-block-detail p {
    font-size: 16px;
    line-height: 24px;
    margin: 0;
}
.achievements-block-detail {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}
.hide {
    display: none;
}
.achievements-block-detail p.show .hide {
    display: inline;
}
.achievements-block-detail p.show .btn-readmore {
    font-size: 0;
}
.achievements-block-detail p.show .btn-readmore:before {
    content: 'Read Less';
    font-size: 16px;
}
.achievements-block-detail p .btn-readmore {
    color: #1452ce;
    transition: all 0.3s ease-in-out;
}
.achievements-block-detail p .btn-readmore:hover {
    color: #1046b3;
}
.achievements-block-detail .url-link {
    color: #1452ce;
    transition: all 0.3s ease-in-out;
}
.achievements-block-detail .url-link:hover {
    color: #1046b3;
}
.achievements-list {
    box-shadow: 0px 10px 25px #00000008;
    border-radius: 6px;
    background: #fff;
}
.achievements-block-title {
    padding: 20px 40px;
    border-bottom: 1px solid #f5f5f5;
    border-radius: 6px 6px 0 0;
}
.achievements-block-title .title {
    font-size: 22px;
}
.achievements-block-title .title span {
    font-weight: 600;
}
.btn-add-section {
    background: #fff;
    border: 1px solid #0b3454;
    border-radius: 19px;
    height: 37px;
    display: flex;
    padding: 0 20px;
    align-items: center;
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
    color: #0b3454;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.btn-add-section i {
    position: relative;
    width: 11px;
    height: 110px;
    margin-right: 10px;
}
.btn-add-section i:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #0b3454;
    width: 2px;
    height: 10px;
}
.btn-add-section i:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #0b3454;
    width: 10px;
    height: 2px;
}
.btn-add-section:hover {
    background: #0b3454;
    color: #fff;
}
.btn-add-section:hover i:before,
.btn-add-section:hover i:after {
    background: #fff;
}
.contributions-block {
    box-shadow: 0px 10px 25px #00000008;
    border-radius: 6px;
    background: #fff;
    padding: 40px;
}
.contributions-block:not(:last-child) {
    margin-bottom: 20px;
}
.contributions-block .act_lst:not(:last-child) {
    margin-bottom: 20px;
}
.contributions-block .seactionheader {
    margin: -10px 0 20px;
}
.contributions-block h3 {
    font-size: 22px;
    font-weight: 600;
}
.contributions-block .circle-effect::before {
    background: #6c8598;
}
.contributions-block .list_badge {
    color: #d52941;
    background: no-repeat;
    position: static;
    text-transform: uppercase;
}
.contributions-block .act_lst {
    padding: 25px 25px 25px 25px;
}
.contributions-block .video_block_name label {
    font-size: 16px;
}
.skills-block {
    box-shadow: 0px 10px 25px #00000008;
    border-radius: 6px;
    background: #fff;
    padding: 40px;
    position: relative;
}
.skills-block:first-child {
    padding-bottom: 20px;
}
.skills-block.EntranceExam-skills-block {
    padding-bottom: 33px;
}
.skills-block:not(:last-child) {
    margin-bottom: 20px;
}
.skills-block h2 {
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 15px;
    margin: -10px 0 25px;
}
.skills-block h2 span {
    font-weight: 600;
}
.skills-block-detail {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
}
.skills-block-detail .meter {
    margin: 0 12px 20px 0;
    border: 1px solid #c7c7c7;
    padding: 10px 30px;
    border-radius: 50px;
    font-size: 20px;
    font-weight: 500;
    align-items: center;
}
.skills-block-detail .meter img {
    width: 50px;
}
.skills-block-title {
    margin-top: -30px;
    margin-bottom: 20px;
}
.preferences-block {
    box-shadow: 0px 10px 25px #00000008;
    border-radius: 6px;
    background: #fff;
    padding: 40px;
}
.preferences-block:not(:last-child) {
    margin-bottom: 20px;
}
.preferences-block.EntranceExam-skills-block {
    padding-bottom: 33px;
}
.preferences-block h2 {
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 15px;
    margin: -10px 0 25px;
}
.preferences-block h2 span {
    font-weight: 600;
}
.preferences-block-detail {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
}
.preferences-block-detail .meter {
    margin: 0 12px 20px 0;
    border: 1px solid #c7c7c7;
    padding: 10px 30px;
    border-radius: 50px;
    font-size: 20px;
    font-weight: 500;
    align-items: center;
}
.preferences-block-detail .meter img {
    width: 50px;
}
.preferences-block-title {
    margin-top: -30px;
    margin-bottom: 20px;
}
.interested-block .icon {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background: #0b3454;
    position: relative;
    margin-right: 20px;
}
.interested-block .icon.icon-bulb::before {
    content: '';
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/bulb.svg) no-repeat 0 0 / 34px auto;
    width: 34px;
    height: 36px;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    margin: auto;
}
.interested-block .icon.icon-note::before {
    content: '';
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/publication_ico.svg);
    width: 29px;
    height: 31px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}
.interested-block {
    display: flex;
    align-items: center;
}
.interested-block .interested-topics {
    font-size: 20px;
    flex: 1;
    line-height: 22px;
}
.interested-block .interested-topics span {
    font-weight: 500;
}
.graduationslider .meter {
    padding: 10px 30px;
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    border-radius: 50px;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
}
.profile-item {
    text-align: center;
    width: 196px;
    height: 210px;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
}
.owl-item:not(:last-child) .profile-item {
    border-right: 1px solid #d2d2d2;
}
.profile-item-img {
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 11px;
}
.profile-item-name-class h3 {
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 4px;
}
.profile-item-name-class p {
    margin: 0 0 11px;
    font-size: 14px;
    font-style: italic;
    color: #535353;
}
.profile-item-name-class .subject {
    color: #0b3454;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    position: relative;
    padding-top: 10px;
}
.profile-item-name-class .subject:before {
    content: '';
    width: 30px;
    height: 2px;
    background: #5cae48;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
}
.profile-item-name-class .btn-viewcompus {
    font-size: 13px;
    color: #1452ce;
    font-weight: 500;
    line-height: 18px;
    position: relative;
    z-index: 2;
    text-decoration: none;
}
.profile-item-name-class .btn-viewcompus:hover {
    color: #0b3454;
    text-decoration: underline;
}
.psychology-item {
    text-align: center;
    width: 245px;
    height: 196px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 10px;
}
.owl-item:not(:last-child) .psychology-item {
    border-right: 1px solid #d2d2d2;
}
.popularCollegesslider .owl-item.active + .owl-item.active + .owl-item.active + .owl-item.active .psychology-item {
    border-right: 0px solid #d2d2d2;
}
.psychology-item-img {
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    height: 90px;
    align-items: center;
}
.psychology-item-name h3 {
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 5px;
    line-height: 17px;
}
.psychology-item-name p {
    font-size: 14px;
    font-style: italic;
    font-weight: 500;
    line-height: 20px;
}
.alumni_directory_details .btn-working {
    font-size: 13px;
    color: #1452ce;
    font-weight: 500;
    line-height: 18px;
    position: relative;
    z-index: 2;
    text-decoration: none;
}
.alumni_directory_details .btn-working:hover {
    color: #0b3454;
    text-decoration: underline;
}
.tooltip-viewcompus .tooltip-inner {
    background: #3b3b3b;
    border-color: #3b3b3b;
}
.tooltip-working .tooltip-inner {
    display: flex;
    flex-direction: column;
    padding: 15px;
    background: #3b3b3b;
    border-color: #3b3b3b;
}
.tooltip-working .tooltip-inner .logo {
    position: relative;
    font-size: 20px;
}
.tooltip-working .tooltip-inner span.logo + span {
    font-size: 16px;
}
.tooltip-working.bs-tooltip-top .tooltip-arrow::before {
    border-top-color: #3b3b3b;
}
.tooltip-working.bs-tooltip-bottom .tooltip-arrow::before {
    border-bottom-color: #3b3b3b;
}
.tooltip-working.bs-tooltip-end .tooltip-arrow::before {
    border-right-color: #3b3b3b;
}
.tooltip-working.bs-tooltip-start .tooltip-arrow::before {
    border-left-color: #3b3b3b;
}
.alumni_name_brife > div {
    align-items: center;
}
.alumni_name_brife > div .sep {
    margin: 0 6px;
    color: #1452ce;
}
.custom_badge {
    display: inline-block;
    border: 1px solid #dedede;
    height: 32px;
    line-height: 32px;
    padding: 0 16px;
    border-radius: 18px;
    font-size: 14px;
    font-weight: 500;
    color: #0b3454;
    margin: 0 5px 10px 0;
    background: #fff;
    overflow: hidden;
}
.custom_badge .icon {
    margin-right: 5px;
}
.delete_confirm .icon-cross {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid red;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #e94e58;
}
.delete_confirm .del_text {
    margin: 20px 0 10px;
    text-align: center;
    max-width: 240px;
    font-size: 28px;
    line-height: 36px;
    font-weight: 500;
    width: 100%;
}
.delete_confirm .btn-delete {
    background: #d52941;
    border-radius: 31px;
    border: 0;
    height: 36px;
    color: #fff;
    padding: 0 20px;
    font-weight: 600;
    font-size: 14px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.delete_confirm .btn-delete:hover {
    background: #a0182b;
}
.selected_options ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}
.selected_options ul li {
    background: #f7fbff;
    border: 1px solid #0b34544d;
    border-radius: 18px;
    height: 35px;
    display: flex;
    align-items: center;
    padding: 0 18px;
    margin: 12px 12px 6px 0;
    font-size: 16px;
    font-weight: 500;
}
.selected_options ul li .icon-cross {
    font-size: 9px;
    margin: 2px 0 0 10px;
    text-decoration: none;
    color: #000;
}
.selected_options ul li .icon-cross:hover {
    text-decoration: none;
    color: #333;
}
.question .form-select.w-90 {
    width: 90px !important;
    max-width: 90px;
}
.custom_row_title {
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 20px;
}
.custom_row_title span {
    font-weight: 600;
}
.custom_row_block .program {
    font-size: 20px;
}
.custom_row_block .program span {
    font-weight: 500;
}
.program-time-interest {
    color: #414141;
    font-size: 16px;
    line-height: 20px;
}
.custom_row_block {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
}
.custom_row_block .icon {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background: #0b3454;
    margin-right: 15px;
    position: relative;
}
.custom_row_block .icon.icon-hat:before {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/hat_alumni.svg) no-repeat 0 0 /26px;
    width: 26px;
    height: 20px;
    display: inline-block;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    position: absolute;
    filter: invert(1);
    -webkit-filter: invert(1);
}
.custom_row_block .icon + div {
    width: calc(100% - 70px);
}
.custom_row {
    width: 100%;
    border-top: 1px solid #dbdbdb;
    padding-top: 20px;
}
.custom_row:not(:last-child) {
    padding-bottom: 20px;
}
.radius_block {
    background: #ffffff;
    border: 1px solid #c7c7c7;
    border-radius: 30px;
    height: 60px;
    padding: 0 30px;
    display: flex;
    align-items: center;
    font-size: 20px;
    line-height: 20px;
    font-weight: 500;
}
.radius_block span {
    margin-right: 30px;
}
.radius_block i {
    font-size: 24px;
}
.radius_block i.gray {
    color: #8599a9;
}
.radius_block i.green {
    color: #5dae48;
}
.universities_block {
    background: #ffffff;
    border: 1px solid #c7c7c7;
    border-radius: 30px;
    height: 60px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
}
.universities_block .logo {
    width: auto;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    border-radius: 50%;
    overflow: hidden;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.universities_block span {
    font-size: 20px;
    margin-right: 15px;
    line-height: 20px;
}
.logo.no_logo {
    width: 40px;
    border: 1px solid #cad6df;
}
.edit_section {
    font-size: 0;
}
.edit_section_toggle,
.custom-btns a.edit_section_toggle {
    display: none;
    width: 20px;
    height: 20px;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='%23343a40' stroke='none' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px 20px;
    border: 0;
    background-color: transparent;
}

@media (min-width: 768px) {
    .alumni_directory_filter .filterHeader button {
        cursor: none;
        pointer-events: none;
    }
}
@media (max-width: 1199px) {
    .alumni_profile_college_name_other_detail_wrap {
        padding: 0 0 0 50px;
    }
}

@media (max-width: 1130px) {
    .alumni_profile_brife_tab {
        overflow: auto;
        scrollbar-width: thin;
        padding-bottom: 10px;
        margin-bottom: 10px;
    }
    .alumni_profile_brife_tab::-webkit-scrollbar {
        height: 4px;
    }
    .alumni_profile_brife_tab::-moz-scrollbar {
        height: 4px;
    }
    .alumni_profile_brife_tab::-webkit-scrollbar-track {
        background: #ccc;
    }
    .alumni_profile_brife_tab::-webkit-scrollbar-thumb {
        background-color: #666;
        border-radius: 20px;
        border: 2px solid #666;
    }
    .alumni_profile_brife_tab .nav {
        justify-content: start;
        flex-wrap: nowrap;
    }
}

@media (max-width: 1099px) {
    .alumni_profile_college_name_other_detail_wrap {
        padding: 0 0 0 30px;
    }
    .alumni_profile_image_name_other_detail {
        padding: 0 30px;
        width: 45%;
    }
    .alumni_profile_college_name_other_detail_wrap .alumni_profile_college_name_other_detail {
        flex-direction: column;
    }
    .alumni_profile_college_name_detail {
        padding: 0 0 21px;
    }
    .alumni_profile_status {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-wrap: wrap;
        -moz-box-wrap: wrap;
        -ms-flex-wrap: wrap;
        -webkit-wrap: wrap;
        flex-wrap: wrap;
    }
    .alumni_profile_status li {
        margin-right: 16px;
    }
    .alumni_profile_name_other_detail h2 {
        line-height: 30px;
    }
    .college_name_scholarship .college_name {
        width: 100%;
        margin-bottom: 10px;
    }
}

@media (max-width: 1035px) {
    .college_name_scholarship {
        flex-direction: column;
        align-items: start;
    }
    .graduateRow .college_detail_wrap .scholarship {
        margin-top: 10px;
    }
    .graduateRow .offer {
        justify-content: start;
        padding-top: 13px;
    }
}

@media (min-width: 992px) and (max-width: 1150px) {
    .filter_result_title {
        flex-direction: column;
    }
    .alumni_count {
        text-align: right;
    }
    .alumni_class_link {
        flex-direction: column;
    }
    .alumni_class_link .send_link {
        padding: 10px 0 0 70px;
    }
}

@media (max-width: 991px) {
    .alumni-directorySearch {
        width: 230px;
    }
    .alumni_profile_name_college_other_detail_wrap {
        flex-direction: column;
    }
    .alumni_profile_image_name_other_detail {
        width: 100%;
        padding-bottom: 45px;
        margin-bottom: 45px;
    }
    .alumni_profile_image_name_other_detail::after {
        content: '';
        width: 100%;
        height: 1px;
        background: #d4d4d4;
        position: absolute;
        right: auto;
        top: auto;
        bottom: 0;
        left: 0;
    }
    .alumni_profile_college_name_other_detail_wrap {
        padding: 0;
    }
    .alumni_profile_college_name_other_detail_wrap .alumni_profile_college_name_other_detail {
        flex-direction: row;
    }
    .alumni_profile_brife_tab .nav-item:not(:last-child) {
        margin-right: 7px;
    }
    .card_profiles {
        overflow: visible;
        max-height: initial;
    }
}

@media (max-width: 950px) {
    .showProfiles_left {
        flex-direction: column;
        align-items: start;
        -webkit-box-flex: 1;
        -moz-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
    }
    .showProfiles_left h3 {
        margin: 0 0 10px;
    }
}

@media (max-width: 875px) {
    .graduateRow {
        flex-direction: column;
    }
    .graduateRow .college_detail_wrap {
        width: 100%;
    }
    .graduateRow .offer {
        width: 100%;
    }
    .college_name_scholarship {
        flex-direction: row;
        align-items: start;
    }
    .graduateRow .college_detail_wrap {
        width: 100%;
        padding: 0 0 20px;
        margin-bottom: 20px;
    }
    .graduateRow .college_detail_wrap::after {
        top: auto;
        right: 0;
        width: 75px;
        height: 2px;
        bottom: 0;
    }
    .graduateRow .offer {
        padding: 0;
        align-items: start;
    }
    .graduateRow .college_detail_wrap::after {
        right: auto;
        bottom: 0;
        left: 0;
    }
    .graduateRow.edit .college_detail_wrap {
        width: 100%;
    }
    .graduateRow .offer.offer_edit {
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: start;
    }
    .graduateRow .offer .offer_existing_links {
        align-items: start;
    }
}

@media (max-width: 820px) {
    .graduation_carousel_wrap {
        padding: 20px;
    }
    .graduationslider .meter {
        padding: 10px 15px;
        margin: 15px 0 10px 0px;
        font-size: 16px;
    }
    .postGraduate-detail {
        padding: 0;
        background: none;
        box-shadow: none;
        border: 1px solid #d2d2d2;
        overflow: hidden;
    }
    .postGraduate-detail .graduateRow {
        padding: 20px;
        background: #fff;
    }
    .graduation-title {
        margin: 0;
        background: #eff8ff;
        border-radius: 6px 6px 0px 0px;
        padding: 20px;
        text-align: center;
        line-height: 22px;
    }
    .graduation_carousel_wrap .seactionheader {
        margin: 0 0 15px;
        flex-direction: column;
        align-items: inherit;
    }
    .graduation_carousel_wrap .seactionheader .countnbtn {
        width: 100%;
        margin-top: 13px;
        justify-content: space-between;
    }
    .graduation_carousel_wrap .seactionheader h3 {
        width: auto;
        margin: -20px -20px 0;
        background: #eff8ff;
        border-radius: 6px 6px 0px 0px;
        padding: 20px;
        text-align: center;
        line-height: 22px;
    }
    .workExperience-detail .workExperience-title {
        width: auto;
        margin: 0;
        background: #eff8ff;
        border-radius: 6px 6px 0px 0px;
        padding: 20px;
        text-align: center;
        line-height: 22px;
    }
    .workExperience-detail {
        padding: 0px;
        overflow: hidden;
    }
    .workExperienceRow {
        padding: 25px;
    }
    .skills-block-title {
        margin: 0 0 20px;
        width: auto;
        margin: -40px -40px 20px;
        background: #eff8ff;
        border-radius: 6px 6px 0px 0px;
        padding: 20px;
        text-align: center;
        line-height: 22px;
    }
    .skills-block-title h2 {
        background: no-repeat;
        padding: 0;
        text-align: left;
    }
    .preferences-block {
        padding: 30px;
    }
    .preferences-block-title {
        width: auto;
        margin: -30px -30px 0px;
        background: #eff8ff;
        border-radius: 6px 6px 0px 0px;
        padding: 20px;
        line-height: 22px;
    }
    .custom_row:first-child {
        border: 0;
    }
    .achievements-block-title {
        background: #eff8ff;
    }
    .underGraduate-detail .main_title,
    .blockHeader {
        background: #eff8ff;
    }
    .graduateRow {
        background: #fff;
    }
    .postGraduate-detail .blockHeader {
        margin: 0;
    }
    .workExperience-detail .blockHeader {
        margin: 0;
    }
}

@media (min-width: 767px) {
    .nolisting_wrap .nolisting {
        max-width: 850px;
        margin: auto;
        border: 0;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-wrap: wrap;
        -moz-box-wrap: wrap;
        -ms-flex-wrap: wrap;
        -webkit-wrap: wrap;
        flex-wrap: wrap;
        text-align: left;
        padding-bottom: 10px;
    }
    .nolisting_text {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: start;
    }
    .nolisting_icon {
        margin-right: 40px;
    }
}

@media (max-width: 767px) {
    .alumni_directory_filter {
        padding: 18px 0;
        padding-right: var(--bs-gutter-x, 0.75rem);
        padding-left: var(--bs-gutter-x, 0.75rem);
    }
    .alumni-directory-header_search {
        flex-direction: column;
        align-items: start;
    }
    .alumni-directory-header_search h2 {
        margin-bottom: 15px !important;
    }
    .alumni-directory-header {
        align-items: end;
    }
    .filter_result_title {
        flex-direction: column;
    }
    .filter_result_title .filter_result {
        display: none;
    }
    .alumni_count {
        text-align: right;
        margin: 0;
    }
    .alumni_directory_filter .fiterItem {
        width: 170px;
        margin: 0 !important;
        box-shadow: none;
        border: 0;
        border-radius: 0;
        border-bottom: 1px solid #e6e6e6 !important;
    }
    .alumni_directory_filter .filters {
        padding: 0;
        display: flex !important;
        flex-direction: column;
    }
    .alumni_directory_filter .filters > div:first-child {
        padding: 15px 20px;
        border-bottom: 1px solid #dbdbdb;
    }
    .alumni_directory_filter .filters-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .alumni_directory_filter .filters #accordionFilters {
        -webkit-box-flex: 1;
        -moz-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        margin: 0;
        position: relative;
    }
    .alumni_directory_filter .filters #accordionFilters::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 170px;
        background: #f5f5f5;
    }
    .alumni_directory_filter .filters > #accordionFilters > .filterGroup.mt-3 {
        margin: 0 !important;
        flex-direction: column;
        height: 100%;
        position: relative;
    }
    .alumni_directory_filter .fiterItem .filterHeader > div {
        border-radius: 0;
    }
    .alumni_directory_filter .fiterItem .filterHeader > div button {
        background: #f5f5f5;
        border-radius: 0;
        padding: 10px;
        color: #000;
    }
    .alumni_directory_filter .fiterItem .filterHeader > div button .toggle {
        display: none;
    }
    .alumni_directory_filter .fiterItem .filterHeader > div button .font-18.font-600 {
        font-size: 16px !important;
        font-weight: 500 !important;
    }
    .additionalfilter_wrap {
        width: 170px;
        padding: 0;
        margin: 0 !important;
    }
    .btn-additionalfilter {
        display: none !important;
    }
    .additionalfilter_wrap > #additionalfilter {
        display: block !important;
    }
    .alumni_directory_filter .fiterItem .filterHeader + div {
        position: absolute;
        right: 0;
        top: 0;
        padding: 0;
        bottom: 0;
        width: calc(100% - 170px);
        padding: 15px;
        overflow: auto;
        background: #fff;
    }
    .alumni_directory_filter .filterGroup {
        margin: 0 !important;
    }
    .alumni_directory_filter .filterGroup.mt-3 {
        margin: 0 !important;
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .filter_close_apply {
        background: #ffffff;
        border-top: 1px solid #e9e9e9;
        padding: 15px 20px;
        justify-content: center;
        align-items: center;
    }
    .filter_close_apply > a.btn-filter-apply {
        padding: 8px 30px;
        background: #5cae48;
        border-radius: 3px;
        text-decoration: none;
        color: #fff;
        margin: 0 15px;
        font-weight: 500;
        text-transform: uppercase;
        transition: all 0.3s ease-in-out;
    }
    .filter_close_apply > a.btn-filter-apply:hover {
        background: #448e32;
        color: #fff;
    }
    .filter_close_apply > a.btn-filter-close {
        text-decoration: none;
        text-transform: uppercase;
        color: #979797;
        font-weight: 500;
        transition: all 0.3s ease-in-out;
        margin: 0 15px;
    }
    .filter_close_apply > a.btn-filter-close:hover {
        color: #666262;
    }
    .alumni_directory_filter .showProfiles_right {
        margin-left: 12px;
    }
    .alumni_directory_filter .filterSearchblk {
        background: #fff;
        padding: 0 0 10px;
    }
    .alumni_directory_filter .fiterItem .card {
        padding: 0px 0px 15px;
    }
    .alumni_directory_filter .fiterItem .filterHeader > div button[aria-expanded='true'] {
        pointer-events: none;
        color: #1452ce;
    }
    .alumni_directory_filter .scrollMe {
        max-height: initial;
        min-height: initial;
    }
    .additionalfilter_count {
        display: block;
        letter-spacing: 2px;
    }
    .additionalfilter_count:before {
        content: '(';
    }
    .additionalfilter_count:after {
        content: ')';
    }
    .alumni_directory_filter .mobileFilter .btn-filter .icon-filter + span {
        -webkit-box-flex: 1;
        -moz-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        text-align: left;
    }
    .alumni_directory_filter .mobileFilter .btn-filter .icon-filter + span + span {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-wrap: wrap;
        -moz-box-wrap: wrap;
        -ms-flex-wrap: wrap;
        -webkit-wrap: wrap;
        flex-wrap: wrap;
        align-items: center;
        color: #b92940;
        font-size: 14px;
    }
    .alumni_directory_filter .mobileFilter .btn-filter {
        display: flex;
        align-items: center;
    }
    .alumni_directory_filter .mobileFilter .btn-filter .icon-filter + span + span .icon-right-arrow {
        font-size: 15px;
        transform: rotate(270deg);
        margin-left: 10px;
        color: #000;
        transition: all 0.3s ease-in-out;
    }
    .alumni_directory_filter .mobileFilter .btn-filter[aria-expanded='false'] .icon-right-arrow {
        transform: rotate(90deg) !important;
    }
    #filter_collapse h3 {
        pointer-events: none;
    }
    .alumni_directory_filter {
        background: #f3f3f3;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 99;
        box-shadow: 0 0 5px 0px #cacaca;
    }
    .alumni_directory_filter .filters.show {
        -moz-transform: translate3d(0, 100%, 0);
        -webkit-transform: translate3d(0, 100%, 0);
        -o-transform: translate3d(0, 100%, 0);
        -ms-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
    .alumni_profile_image_name_other_detail {
        width: 100%;
        padding-bottom: 30px;
        margin-bottom: 30px;
    }
    .alumni_profile_college_name_other_detail_wrap .alumni_profile_college_name_other_detail {
        flex-direction: column;
    }
    .nolisting_wrap .nolisting {
        border: 0;
        padding: 20px 0 10px;
    }
    .nolisting_text {
        text-align: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .edit_section {
        position: absolute;
        right: 20px;
        top: 5px;
    }
    .countnbtn .edit_section {
        position: relative;
        left: auto;
        right: auto;
        top: 0;
        margin-left: 5px;
    }
    .skills-block .edit_section {
        top: 22px;
    }
    .edit_section .edit_section_toggle {
        display: inline-block;
    }
    .custom-btns a.edit_section_toggle {
        display: inline-block;
    }
    .edit_section .edit_section_inner {
        position: absolute;
        top: 50px;
        right: 0;
        background: #fff;
        box-shadow: 0px 0px 5px #57555533;
        display: flex;
        flex-direction: column;
        width: 100px;
        border-radius: 6px;
        overflow: hidden;
        opacity: 0;
        z-index: -1;
        transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
    }
    .edit_section.open .edit_section_inner {
        top: 30px;
        opacity: 1;
        z-index: 9;
    }
    .edit_section .edit_section_inner a {
        width: 100% !important;
        border-radius: 0 !important;
        padding: 0 0 0 30px;
        font-size: 14px;
        text-decoration: none;
        color: #000;
        line-height: 33px;
        height: 35px !important;
        background: #fff !important;
        position: relative;
        opacity: 1;
        margin: 0 !important;
        -webkit-border-radius: 0 !important;
        -moz-border-radius: 0 !important;
        -ms-border-radius: 0 !important;
        -o-border-radius: 0 !important;
        opacity: 1 !important;
    }
    .edit_section .edit_section_inner a:hover {
        color: #fff;
        background-color: #0b3454 !important;
    }
    .edit_section .edit_section_inner a.edit_link:before {
        width: 16px;
        height: 16px;
        margin: 0;
        background-size: 14px;
        left: 7px;
        top: 8px;
    }
    .edit_section .edit_section_inner a:first-child {
        border-bottom: 1px solid #eee;
    }
    .edit_section .edit_section_inner a.del_link:before {
        content: '';
        background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/delete.svg) no-repeat center /
            14px auto;
        width: 14px;
        height: 16px;
        position: absolute;
        left: 8px;
        top: 8px;
    }
    .edit_section .edit_section_inner a.del_link:hover {
        background: #0b3454;
        color: #fff;
    }
    .edit_section .edit_section_inner a.del_link:hover:before {
        filter: invert(92%) sepia(100%) saturate(0%) hue-rotate(202deg) brightness(106%) contrast(106%);
        -webkit-filter: invert(92%) sepia(100%) saturate(0%) hue-rotate(202deg) brightness(106%) contrast(106%);
    }
    .graduateRow .college_detail_wrap .scholarship {
        margin-right: 60px;
    }
    .achievements-block-detail .edit_section {
        top: 4px;
        right: 0;
    }
    .achievements-block-detail {
        padding-right: 25px;
    }
    .postGraduate-detail .graduateRow .offer.offer_edit .edit_section {
        top: 20px;
    }
    .work-block .edit_section {
        right: 0;
    }
    .work-block .work-detail {
        padding-right: 24px;
    }
    .directory_wrap {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 100%;
        bottom: auto;
        left: 0;
        right: 0;
        margin: auto;
        background: white;
        display: flex;
        flex-direction: column;
        transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
    }
    .directory_wrap.open {
        top: 0;
        bottom: 0;
    }
}

@media (min-width: 575px) and (max-width: 767px) {
    .filter_result_title {
        flex-direction: column;
    }
    .alumni_count {
        text-align: right;
    }
    .alumni_class_link {
        flex-direction: column;
    }
    .alumni_class_link .send_link {
        padding: 10px 0 0 70px;
    }
}

@media (max-width: 575px) {
    .alumni-directory-header {
        flex-direction: column;
    }
    .alumni-directory-header_search {
        flex-direction: row;
        width: 100%;
        margin-bottom: 20px;
        justify-content: space-between;
        overflow: hidden;
        align-items: center;
    }
    .alumni-directory-header_search h2 {
        margin-bottom: 0 !important;
    }
    .alumni-directorySearch-wrap {
        position: relative;
    }
    .alumni-directorySearch-wrap .alumni-directorySearch {
        position: absolute;
        right: -230px;
        transition: all 0.3s ease-in-out;
    }
    .alumni-directorySearch-wrap.show .alumni-directorySearch {
        right: 0;
    }
    .btn-alumniSearchToggle.icon-search {
        width: 40px;
        height: 40px;
        background: #fff;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        color: #000;
        font-size: 12px;
    }
    .alumni-directorySearch .form-control + .btn-cross {
        opacity: 1;
        right: 15px;
        transform: rotate(135deg);
    }
    .alumni-switch-container {
        margin: auto;
    }
    .alumni-directorySearch .form-control {
        padding-right: 40px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .alumni_profile_image_name_other_detail {
        padding: 0 0 20px;
        margin-bottom: 20px;
    }
    .alumni_profile_college_name_other_detail .alumni_profile_other_detail {
        margin: 0 0 0 -98px;
        width: calc(100% + 98px);
    }
    .alumni_profile_status li {
        width: 47%;
        margin-bottom: 0;
    }
    .alumni_profile_status li:last-child {
        margin-right: 0;
    }
    .alumni_profile_image {
        width: 80px;
        height: 80px;
    }
    .alumni_profile_name_other_detail h2 {
        line-height: 30px;
        font-size: 24px;
    }
    .alumni_profile_college_name_other_detail_wrap .alumni_profile_college_logo {
        width: 80px;
        height: 80px;
    }
    .alumni_profile_college_name_other_detail_wrap .alumni_profile_college_name_other_detail {
        flex-direction: row;
    }
    .alumni_profile_brife {
        padding: 20px 0 10px;
    }
    /*.graduateRow .college_detail_wrap .college_detail {  flex-direction: column;align-items: center;}*/
    .graduateRow .college_detail_wrap .college_detail {
        padding-right: 25px;
    }
    .graduateRow {
        margin-left: -20px;
        margin-right: -20px;
        width: auto;
        padding: 5px 20px;
    }
    .graduateRow .college_detail_wrap .college_detail .college_logo {
        margin: 0 10px 10px 0;
        width: 70px;
        height: 70px;
        border-radius: 50%;
    }
    .graduateRow .college_name_scholarship {
        flex-direction: column;
        align-items: start;
        justify-content: start;
        text-align: left;
        width: calc(100% - 80px);
    }
    .graduateRow .college_detail_wrap .college_detail h3 {
        font-size: 19px;
        line-height: 20px;
    }
    .alumni_profile_college_subject {
        padding: 0 15px;
        font-size: 15px;
        width: 100%;
    }
    .graduateRow .college_detail_wrap .scholarship {
        flex-direction: row;
    }
    .graduateRow .college_detail_wrap .scholarship .scholarship_label {
        margin-right: 7px;
    }
    .graduateRow .college_detail_wrap::after {
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 100%;
        height: 1px;
    }
    .graduateRow .offer {
        align-items: center;
        flex-direction: row;
        justify-content: center;
    }
    .graduateRow:not(:last-child) {
        margin-bottom: 15px;
        padding-bottom: 20px;
    }
    .graduateRow .college_detail_wrap .college_detail .college_logo img[src*="no_university_img"]{width:38px}
    .underGraduate-detail {
        padding: 20px;
        box-shadow: none;
    }
    .workExperience-detail {
        padding: 0px;
    }
    .workExperienceRow {
        padding: 20px 20px 30px;
    }
    .achievements-block {
        flex-direction: column;
    }
    .achievements-icon-block {
        border-right: 0;
        margin: 0 0 18px;
        padding: 0 0 18px;
        border-bottom: 1px solid #cecece;
    }
    .achievements-block-detail {
        text-align: center;
    }
    .achievements-block-detail h3 {
        margin: 0;
    }
    .contributions-block .seactionheader {
        margin: 0px 0 20px;
        flex-direction: column;
        align-items: inherit;
    }
    .contributions-block .seactionheader > h3 {
        width: auto;
        margin: -20px -20px 0;
        background: #eff8ff;
        border-radius: 6px 6px 0px 0px;
        padding: 20px;
        text-align: center;
        line-height: 22px;
    }
    .contributions-block .seactionheader .countnbtn {
        width: 100%;
        margin-top: 10px;
        align-items: center;
        justify-content: space-between;
        position: relative;
    }
    .contributions-block .seactionheader .countnbtn .custom-btns {
        padding-right: 0;
    }
    .contributions-block .seactionheader .countnbtn .custom-btns .edit_section {
        right: 0;
        top: 0px;
    }
    .contributions-block {
        padding: 20px;
    }
    .skills-block {
        padding: 20px;
    }
    .skills-block:first-child {
        padding-bottom: 5px;
    }
    .skills-block.EntranceExam-skills-block {
        padding-bottom: 25px;
    }
    .skills-block h2 {
        margin: 0 0 20px;
        width: auto;
        margin: -20px -20px 20px;
        background: #eff8ff;
        border-radius: 6px 6px 0px 0px;
        padding: 20px;
        text-align: center;
        line-height: 22px;
    }
    .skills-block-detail .meter {
        font-size: 18px;
        align-items: center;
        padding: 10px 20px;
        line-height: 18px;
    }
    .card_profiles figure.student {
        position: absolute;
        left: 15px;
        top: 15px;
    }
    .card_profiles .student_details h2 {
        margin: 0 0 10px 90px;
    }
    .card_profiles .btn-send {
        position: static;
        margin-left: 90px;
    }
    .card_profiles .student_details .sub_details {
        margin-top: 15px;
    }
    .card_profiles .student_details .sub_details span.course {
        line-height: 15px;
    }
    .contributions-block .act_lst {
        padding: 15px;
    }
    .contributions-block .act_lst .postedtxt {
        text-align: left;
        position: absolute;
        top: 15px;
        white-space: nowrap;
        right: 15px;
    }
    .graduateRow .offer > a {
        margin: 0 10px 0 0;
    }
    .graduateRow .offer div.got_offer {
        margin-right: 10px;
        width: 50%;
        align-items: center;
        display: flex;
        margin-bottom: 0 !important;
        text-align: left;
        flex: none;
        justify-content: center;
        line-height: 18px;
    }
    .graduateRow .offer .offer_joined {
        align-items: start;
        width: 50%;
        align-items: center;
        display: flex;
        margin-bottom: 0 !important;
        line-height: 16px;
    }
    .graduateRow .offer .offer_joined:only-child {
        width: auto;
    }
    .graduateRow .offer .offer_joined i {
        margin-right: 8px;
    }
    .contributions-block .act_lst > .row > div:last-child .btn-green {
        width: 135px;
    }
    .contributions-block .act_lst .btn-dark-blue {
        width: 135px;
    }
    .contributions-block .listblk_icon {
        margin-bottom: 0;
        width: 34px;
        height: 34px;
    }
    .contributions-block .act_lst .postedrow > div:last-child {
        width: 100% !important;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        border-top: 1px solid #d1d1d1;
        padding-top: 10px;
        margin-top: 8px;
    }
    .act_lst > .row.postedrow > div:last-child > div:first-child {
        width: calc(100% - 190px);
        margin: 0 0 0 15px;
        align-items: center;
    }
    .alumni_class_link {
        align-items: center;
    }
    .filter_result_title {
        margin-bottom: 12px;
    }
    .contributions-block .album_shareblk {
        padding: 5px 0 0 0px;
    }
    .profile-item,
    .psychology-item {
        width: 100%;
        border: 0 !important;
    }
    .skills-block-title {
        margin: 0 0 20px;
        width: auto;
        margin: -20px -20px 20px;
        background: #eff8ff;
        border-radius: 6px 6px 0px 0px;
        padding: 20px;
        text-align: center;
        line-height: 22px;
    }
    .skills-block-title h2 {
        padding: 0;
        text-align: left;
    }
    #edit_Higher_Education .question {
        padding-right: 20px;
    }
    #job_Preferences .question {
        padding-right: 20px;
    }
    .underGraduate-detail .main_title,
    .blockHeader {
        margin: -20px -20px 30px;
        padding: 20px;
    }
    .graduateRow .offer .offer_existing_links {
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .graduateRow .offer .got_offer {
        margin-right: 10px;
    }
    .graduateRow .offer a.joindeLink {
        margin: 0 10px 0 0;
    }
    .powerby {
        margin: auto;
    }
    .graduateRow .offer.offer_edit .edit_section {
        top: 10px;
    }
    .postGraduate-detail .graduateRow {
        margin: 0;
    }
    .alumni_profile_college_name_detail {
        width: 100%;
    }
}

@media (max-width: 480px) {
    .card_profiles.card_profiles_pg > div {
        flex-direction: column;
        display: flex;
    }
}

@media (max-width: 400px) {
    .graduateRow .offer.offer_edit {
        flex-direction: column;
        align-items: start;
    }
    .graduateRow .offer.offer_edit .edit_section {
        margin: 0;
    }
}
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
    appearance: none;
    -webkit-appearance: none;
}
header + .relevantAlumniMatch {
    display: none;
}
.relevantAlumniMatch {
    background: transparent linear-gradient(93deg, #fff7f8 0%, #ffe6ea 100%);
    box-shadow: 0px 10px 20px #ffebee;
    border: 1px solid #fdd3da;
    border-radius: 8px;
    padding: 15px 20px;
    margin-bottom: 20px;
    position: relative;
}
.relevantAlumniMatch p {
    font-size: 20px;
    margin: 0;
    font-weight: 500;
    line-height: 22px;
}
.relevantAlumniMatch .btn-complete-now {
    border: 1px solid #d42941;
    border-radius: 25px;
    width: 160px;
    height: 40px;
    background: #fff;
    color: #000;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    margin-right: 23px;
    transition: all 0.3s ease-in-out;
}
.relevantAlumniMatch .btn-complete-now:before {
    display: none;
}
.relevantAlumniMatch .btn-complete-now:hover {
    background: #d42941;
    color: #fff;
}
.relevantAlumniMatch .btn-close {
    font-size: 10px;
}

@media (max-width: 767px) {
    .relevantAlumniMatch .btn-close {
        font-size: 10px;
        position: absolute;
        top: 13px;
        right: 10px;
    }
}
.pencil-edit {
    background-image: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/draw.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 17px;
    width: 17px;
    height: 17px;
    display: inline-block;
}
.alumni_profile_image_name_other_detail .pencil-edit {
    position: absolute;
    right: 30px;
    top: 10px;
}
.verifynow {
    border: 2px solid #d52941;
    padding: 2px 14px;
    border-radius: 25px;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
    height: 30px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
}
.verifynow:hover {
    background: #d52941;
    color: #fff;
}
.alumni_profile_image_name_other_detail .btnverified {
    background: #5cae48;
    border: 0;
    padding: 0 22px;
    width: auto;
    font-size: 14px;
}
.alumni_profile_image_name_other_detail .btnverified:hover {
    background: #439130;
    color: #fff;
}
.alumni_profile_image_name_other_detail .btnverified:hover .icon-varified {
    color: #fff !important;
}
.btn-back-dismiss {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='%23343a40' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='0.2' d='M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50px 32px;
    width: 20px;
    height: 15px;
    margin: 5px 5px 0 0px;
}
#delete_confirm_pop {
    z-index: 9999;
}
.modal.show ~ .modal.show ~ .modal-backdrop {
    z-index: 9998;
    opacity: 0.7;
}

/* css 11042023 */
.landing_login_page .carousel button {
    background: no-repeat;
}
.landing_login_page .carousel button span {
    filter: invert(1);
    -webkit-filter: invert(1);
}
.landing_login_page #myloginTab {
    border: 0;
    display: flex;
    justify-content: center;
    max-width: 496px;
    width: 90%;
}
.landing_login_page #myloginTab .nav-item button {
    line-height: 70px;
    width: 100%;
    background: none;
    border: 0;
    color: rgba(255, 255, 255, 0.5);
    border-bottom: 2px solid #375973;
}
.landing_login_page #myloginTab .nav-item {
    max-width: 200px;
    width: 100%;
}
.landing_login_page #myloginTab .nav-item button.active {
    color: #fff;
    border-color: #5cae48;
}
.landing_login_page #myloginTabContent {
    max-width: 496px;
    width: 90%;
    margin: 0 auto;
}
.landing_login_page .form-control {
    min-height: 40px;
    border: none;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    font-weight: 500;
    color: #000;
    font-size: 16px;
    z-index: 1;
    position: relative;
}
.landing_login_page .action_btns {
    padding: 0;
    margin: 0;
    background: none;
}
.landing_login_page .action_btns .btn-login,
.btn-setPassWord {
    background: #5cae48;
    color: #fff;
    border-color: #5cae48;
    max-width: 200px;
    width: 100%;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
}
.landing_login_page .action_btns .btn-login:hover,
.btn-setPassWord:hover {
    background: #88bb1b;
    color: #fff;
    border-color: #88bb1b;
}
.landing_login_page .carousel-indicators button {
    border: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #333 !important;
    opacity: 0.5;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.landing_login_page .carousel-indicators button.active {
    opacity: 1;
}
.landing_login_page .form-select {
    border-radius: 6px;
}
.form-select#mobileCountryCode {
    width: 125px;
    height: 45px;
    font-size: 16px;
    font-weight: 500;
}
.footer {
    position: relative;
    bottom: 0;
    width: 100%;
    height: 60px;
}
.headerRightSide > ul li .user_id {
    position: static;
    border-radius: 5px 5px 0 0;
    width: 100%;
    padding: 7px 15px;
    text-align: right;
    background: #eee;
    color: #000;
}
.headerRightSide span.userimg {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}
.headerRightSide span.userimg img {
    width: auto;
    height: 100%;
}
.headerRightSide span.userimg.icon-user-invert img {
    display: none;
}
.username:hover div.user-dropdown::before {
    width: 0;
    height: 0;
    content: '';
    position: absolute;
    top: -5px;
    right: 10px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #d2d2d2;
}
.newmsg > .containerinnercover {
    width: calc(100% - 320px);
}
.containerinnercover div.css-13cymwt-control {
    height: auto !important;
    border: 1px solid #eaeaea !important;
}
.containerinnercover div.css-1fdsijx-ValueContainer {
    height: auto !important;
    border: 1px solid #eaeaea;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.containerinnercover div.css-b62m3t-container {
    height: auto !important;
    border: 1px solid #eaeaea;
}
.containerinnercover .css-b62m3t-container .css-t3ipsp-control {
    height: auto !important;
}
.containerinnercover div.css-1p3m7a8-multiValue {
    background: #f7f7f7;
    border-radius: 5px;
}
.containerinnercover .university_ttl img {
    width: 40px;
}
.containerinnercover .university_ttl {
    width: inherit;
    max-width: inherit;
    padding: 5px 10px;
    border-radius: 3px;
}
.containerinnercover .css-qbdosj-Input {
    min-height: 45px;
}
.containerinnercover div[class*='-placeholder'] {
    padding-left: 15px;
}
.containerinnercover.singleOption div[class*='-singleValue'] {
    padding-left: 15px;
}
.containerinnercover.singleOption div[data-value] {
    padding-left: 15px;
}
.containerinnercover.singleOption div[data-value] input {
    min-height: 50px;
}
.student_profile {
    position: relative;
}
.student_profile .pencil-edit {
    position: absolute;
    top: 12px;
    right: 0;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.student_profile .pencil-edit:hover {
    opacity: 1;
}
.student_profile .alumni_profile_image_name_other_detail:after {
    opacity: 0;
}
.student_profile .alumni_profile_class_college::before {
    background: #f8b018;
    left: 0;
}
.student_profile .alumni_profile_image_name_other_detail {
    width: 100%;
}
.student_profile_details {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    align-items: start;
}
.student_profile_details .alumni_profile_class_college {
    margin-right: 70px;
    padding-left: 17px;
    margin-bottom: 25px;
}
.student_profile_details .verifiedLink {
    margin-left: 20px;
}
.student_profile .school_other_details {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
}
.student_profile .school_other_details li {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-left: 10px;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 10px;
}
.student_profile .school_other_details li:not(:last-child) {
    margin-right: 70px;
}
.student_profile .school_other_details li:before {
    content: '';
    position: absolute;
    left: 0;
    top: 3px;
    bottom: 0;
    width: 3px;
    height: 17px;
    background: #f8b018;
    border-radius: 5px;
}
.alumni_profile_name_other_detail .btn-send {
    width: auto;
    height: 40px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    background: #5cae48;
    border-radius: 25pc;
    color: #fff;
    text-decoration: none;
    align-items: center;
    padding: 0 25px;
    font-size: 16px;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
}
.alumni_profile_name_other_detail .btn-send .icon-send {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/send_alumni_profile.svg) no-repeat 0
        0 / 100%;
    width: 19px;
    height: 19px;
    margin-right: 10px;
    position: relative;
    transition: all 0.3s ease-in-out;
    left: 0;
}
.alumni_profile_name_other_detail .btn-send:hover {
    background: #2fa612;
}
.alumni_profile_name_other_detail .btn-send:hover .icon-send {
    left: 3px;
}
.edit-profile-image .imgUpload {
    position: absolute;
    top: -100px;
    left: 0;
    right: 0;
    margin: auto;
    width: 90px;
    height: 30px;
    background: #0b3454;
    border-radius: 3px;
    color: #fff;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5 ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
}
.edit-profile-image .imgUpload i {
    margin-left: 5px;
}
.edit-profile-image:hover span.imgUpload {
    top: 40px;
}

/*tab 31032023*/
.tab-white-block {
    box-shadow: 0px 10px 25px #00000008;
    border-radius: 6px;
    background: #fff;
    padding: 44px;
    position: relative;
}
.customRow:not(:last-child) {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #dbdbdb;
}
.customRow-header {
    display: flex;
    align-items: center;
}
.customRow-header .icon {
    width: 47px;
    height: 47px;
    border-radius: 50%;
    background-color: #f8b018;
    margin-right: 10px;
}
.customRow-header .icon:before {
    display: none;
}
.icon.icon-book {
    background-image: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/open-book.svg);
    background-position: center;
    background-repeat: no-repeat;
}
.icon.icon-global {
    background-image: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/world.svg);
    background-position: center;
    background-repeat: no-repeat;
}
.icon.icon-university-college {
    background-image: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/college.svg);
    background-position: center;
    background-repeat: no-repeat;
}
.icon.icon-book {
    background-image: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/open-book.svg);
    background-position: center;
    background-repeat: no-repeat;
}
.icon.icon-global {
    background-image: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/world.svg);
    background-position: center;
    background-repeat: no-repeat;
}
.icon.icon-father {
    background-image: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/father.svg);
    background-position: center;
    background-repeat: no-repeat;
}
.icon.icon-mother {
    background-image: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/mother.svg);
    background-position: center;
    background-repeat: no-repeat;
}
.icon.icon-guardian {
    background-image: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/guardian.svg);
    background-position: center;
    background-repeat: no-repeat;
}
.icon.icon-college {
    background-image: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/edu-college.svg);
    background-position: center;
    background-repeat: no-repeat;
}
.icon.icon-school {
    background-image: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/school-bag.svg);
    background-position: center;
    background-repeat: no-repeat;
}
.icon.icon-exam-note {
    background-image: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/exam-note.svg);
    background-position: center;
    background-repeat: no-repeat;
}
.icon.icon-aboutMe {
    background-image: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/about-me.svg);
    background-position: center;
    background-repeat: no-repeat;
}
.icon.icon-achievements {
    background-image: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/trophy.svg);
    background-position: center;
    background-repeat: no-repeat;
}
.icon.icon-books {
    background-image: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/book.svg);
    background-position: center;
    background-repeat: no-repeat;
}
.icon.icon-activities {
    background-image: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/target.svg);
    background-position: center;
    background-repeat: no-repeat;
}
.customRow-detail {
    padding: 0 0 0 58px;
}
.tab-white-block .pencil-edit {
    background-color: #bee0fb;
    width: 37px;
    height: 37px;
    border-radius: 19px;
    opacity: 0.5;
    position: absolute;
    top: 20px;
    right: 20px;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
}
.tab-white-block .pencil-edit:hover {
    opacity: 1;
}
.curriculars-item {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 25px #00000008;
    border-radius: 6px;
    position: relative;
}
.curriculars-item:not(:last-child) {
    margin-bottom: 10px;
}
.curriculars-header .customRow-header {
    padding: 20px 20px 20px 40px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
}
.customRow-header-title {
    width: calc(100% - 110px);
}
.curriculars-body {
    padding: 0px 20px 20px 40px;
}
.curriculars-header .customRow-header[aria-expanded='true'] {
    padding-bottom: 10px;
}
.curriculars-header .pencil-edit {
    background-color: #bee0fb;
    width: 37px;
    height: 37px;
    border-radius: 19px;
    opacity: 0.5;
    position: absolute;
    top: 25px;
    right: 60px;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
}
.curriculars-header .pencil-edit:hover {
    opacity: 1;
}
tr:nth-child(even) {
    background: #f1f1f170;
}
.curriculars_table th div {
    padding: 8px 20px;
    background-color: #f1f1f1;
    font-size: 16px;
    font-weight: 400;
}
.curriculars_table thead th:first-child div {
    border-radius: 5px 0px 0px 5px;
}
.curriculars_table thead th:last-child div {
    border-radius: 0px 5px 5px 0px;
}
.curriculars_table tbody th {
    padding: 20px;
    font-size: 18px;
    font-weight: 500;
}
.curriculars_table td {
    padding: 20px;
}
.curriculars-header .customRow-header i {
    position: absolute;
    width: 16px;
    height: 16px;
    right: 30px;
    top: 36px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.curriculars-header .customRow-header[aria-expanded='true'] i:before {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
}
.curriculars-header .customRow-header i:before {
    content: '';
    width: 2px;
    height: 16px;
    background: #0d4466;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.curriculars-header .customRow-header i:after {
    content: '';
    width: 16px;
    height: 2px;
    background: #0d4466;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.book-wrap {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
}
.book-wrap .book-detial {
    width: calc(100% - 80px);
    margin-left: 15px;
}
.book-wrap .book-img img {
    box-shadow: 0 0 3px 1px #ccc;
}
.lineHeight-26 {
    line-height: 26px;
}

@media (max-width: 767px) {
    .newmsg > .containerinnercover {
        width: 100%;
    }
}

@media (max-width: 575px) {
    .tab-white-block {
        padding: 24px;
    }
    .curriculars-header .customRow-header {
        padding: 24px 20px 24px 24px;
    }
    .curriculars-body {
        padding: 0px 20px 20px 24px;
    }
    .curriculars-body .customRow-detail {
        padding: 0;
    }
    .curriculars-header .pencil-edit {
        top: 25px;
    }
    .curriculars-header .customRow-header i {
        top: 35px;
    }
}

header .search .form-inline {
    border-radius: 20px;
    border: 1px solid #f1f1f1;
}
header .search .form-inline .css-b62m3t-container {
    border-radius: 20px;
}
header .search .form-inline .css-b62m3t-container .css-13cymwt-control {
    border-radius: 20px;
}
header .search .form-inline .css-b62m3t-container .css-t3ipsp-control {
    border-radius: 20px;
}
header .search .form-inline .css-b62m3t-container input {
    padding: 0 15px !important;
    font-size: 16px !important;
}
header .search .form-inline .css-b62m3t-container .css-1jqq78o-placeholder {
    padding: 0 15px;
    font-size: 16px;
}
header .search .form-inline .css-t3ipsp-control .css-1jqq78o-placeholder {
    display: none;
}
header .search .form-inline .css-b62m3t-container .css-qbdosj-Input,
header .search .form-inline .css-b62m3t-container .css-166bipr-Input {
    display: -webkit-box !important;
    display: -moz-box !important;
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
}
header .search .form-inline div * {
    display: block;
    line-height: 40px;
}
header .search .form-inline div[aria-hidden='true'] {
    display: none;
}
header .search .form-inline div.css-t3ipsp-control > div {
    margin: 0;
    padding: 0;
}
header .search .form-inline div[class*='-control'] > div {
    padding: 0;
}
header .search .form-inline div[data-value] {
    margin: 0;
    padding: 0;
}
header .search .form-inline #react-select-2-placeholder {
    line-height: 43px;
}
header .search .form-inline .css-t3ipsp-control #react-select-2-placeholder {
    display: none;
}
header .search .form-inline .css-t3ipsp-control input {
    height: 45px;
}
.lstCustForm .css-b62m3t-container {
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.lstCustForm .select__value-container {
    -webkit-box-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}
.lstCustForm .css-b62m3t-container .css-13cymwt-control {
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border: 1px solid #848484 !important;
}
.lstCustForm .css-b62m3t-container .css-t3ipsp-control {
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border: 1px solid #848484 !important;
}

.lstCustForm .css-b62m3t-container input {
    padding: 0 15px !important;
    font-size: 16px !important;
}
.lstCustForm .css-b62m3t-container .css-1jqq78o-placeholder {
    padding: 0 15px;
    font-size: 16px;
}
.lstCustForm .css-b62m3t-container .css-qbdosj-Input,
.lstCustForm .css-b62m3t-container div[data-value] {
    display: -webkit-box !important;
    display: -moz-box !important;
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
}
.lstCustForm .css-b62m3t-container .css-qbdosj-Input,
.lstCustForm .css-b62m3t-container .css-166bipr-Input {
    display: -webkit-box !important;
    display: -moz-box !important;
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
}
.lstCustForm .css-b62m3t-container div[data-value] {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}
.attendee_dtl .table thead th {
    background: #f1f1f1;
    font-size: 14px;
    font-weight: 500;
    height: 40px;
    border: 0;
}
.attendee_dtl .table thead th:first-child {
    border-radius: 6px 0 0 6px;
}
.attendee_dtl .table thead th:last-child {
    border-radius: 0 6px 6px 0;
}
.table-responsive.attendee_dtl table td:first-child,
.table-responsive.attendee_dtl > table th:first-child {
    padding-left: 20px;
}
.table-responsive.attendee_dtl table td {
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 14px;
    font-weight: 500;
    vertical-align: middle;
}
.table-responsive.attendee_dtl table .btn {
    line-height: 15px;
    display: flex;
    align-items: center;
    padding: 0 20px !important;
    margin: 0 5px 0 0;
}
.table-responsive.attendee_dtl table td.active {
    width: 315px;
}
.table-responsive.attendee_dtl table td.engagement {
    width: 245px;
}
.table-responsive.attendee_dtl > table .university-name {
    width: 175px;
}
.table-responsive.attendee_dtl > table td.sentDeliveredOpened {
    width: 365px;
}
.table-responsive.attendee_dtl a.tablink {
    color: #0b3454;
    display: flex;
    align-items: center;
    text-decoration: none;
    line-height: 18px;
}
.table-responsive.attendee_dtl a.tablink:hover {
    color: #4c4f51;
}
.table-responsive.attendee_dtl a.btn-gray-border:hover {
    color: #fff;
}
.table-responsive.attendee_dtl > table td.deliveryStatus {
    width: 205px;
}
.table_cus_badge {
    background: #fef7e8;
    border-radius: 20px;
    height: 36px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #000;
    font-weight: 500;
    padding: 0 15px;
    margin: 3px;
    min-width: 65px;
}
.tab-ul {
    padding: 0;
    line-height: 22px;
    list-style: none;
    margin: 0 13px 0 0;
}
.attendee_dtl input[type='checkbox'] {
    width: 18px;
    height: 18px;
    display: inline-block;
}
.aldir_scroll {
    width: 100%;
    overflow-x: auto;
    scrollbar-width: thin;
    scrollbar-color: #fb9aa7 #780011;
    padding: 0;
}
.aldir_scroll::-webkit-scrollbar {
    height: 4px;
}
.aldir_scroll::-moz-scrollbar {
    height: 4px;
}
.aldir_scroll::-webkit-scrollbar-track {
    background: #780011 !important;
}
.aldir_scroll::-webkit-scrollbar-thumb {
    background-color: #fb9aa7;
    border-radius: 20px;
    border: 1px solid #780011 !important;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}
.css-1n6sfyn-MenuList::-webkit-scrollbar {
    width: 4px;
}
.css-1n6sfyn-MenuList::-moz-scrollbar {
    width: 4px;
}
.css-1n6sfyn-MenuList::-webkit-scrollbar-track {
    background: #ccc !important;
}
.css-1n6sfyn-MenuList::-webkit-scrollbar-thumb {
    background-color: #fff;
    border-radius: 20px;
    border: 1px solid #ccc !important;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}
.fitergroup .show .accordion-body {
    border-radius: 0 6px 6px 6px;
}
.fitergroup .accordion-body {
    background: #f1f1f1;
    border-radius: 6px;
}
.fitergroup .form-select {
    height: 45px;
}
.btn-grpfiter {
    border: 0px solid #e1e1e1;
    border-radius: 20px;
    height: 40px;
    padding: 0 25px;
    font-size: 14px;
    font-weight: 500;
    background: #f1f1f1;
    position: relative;
}
.btn-grpfiter[aria-expanded='true'] {
    border-radius: 20px 20px 0 0;
    transition: all 0.3s ease-in-out;
}
.btn-grpfiter::before {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 0;
    right: 0;
    background: #f1f1f1;
    height: 10px;
    transition: all 0.3s ease-in-out;
    opacity: 0;
}
.btn-grpfiter[aria-expanded='true']::before {
    bottom: -10px;
    opacity: 1;
}
/* .btn-grpfiter ~ div .form-control {max-height: 38px;background-size: 14px;background-repeat: no-repeat;background-position: 13px center;padding-left: 34px;height: 40px;border-radius: 25px;-webkit-border-radius: 25px;-moz-border-radius: 25px;-ms-border-radius: 25px;-o-border-radius: 25px;} */
.btn-grpfiter ~ div .form-control {
    border-color: #dee2c6;
}
/* background-image: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/search.svg); */

@media (max-width: 480px) {
    .vertical-input-group {
        flex-direction: column;
    }
    .vertical-input-group.input-group .form-select,
    .vertical-input-group .form-control {
        width: 100%;
    }
    .vertical-input-group.input-group
        > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
        margin-left: 0px;
        margin-top: -1px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .vertical-input-group.has-validation.input-group > .dropdown-toggle:nth-last-child(n + 4),
    .vertical-input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu) {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 0;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 0;
    }
    .vertical-input-group.has-validation.input-group > .form-select:nth-last-child(2) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 6px !important;
        border-top-left-radius: 0;
        border-bottom-left-radius: 6px !important;
    }
}
.custom-input-group[style*='grid'] {
    border: 1px solid #cccccc;
    border-radius: 6px;
    display: flex !important;
    height: auto !important;
}
.custom-input-group[style*='grid'] .basic-multi-select{height: auto !important;min-height: 43px;}
.custom-input-group[style*='grid'] .border {
    border: 0 !important;
    border-radius: 5px 0px 0 5px;
}
.custom-input-group[style*='grid'] .css-nozp1o-control {
    border: none !important;
    height: 43px;
    border-radius: 0 5px 5px 0;
    overflow: visible;
}
.custom-input-group[style*='grid'] .select__control{height: auto;padding: 5px;}
.custom-input-group[style*='grid'] .css-oorrsa-IndicatorsContainer{height: auto;}
.custom-input-group[style*='grid'] .select__indicators{height: auto;}

select#rangeOption ~ .form-control[type*='date'] {
    background-image: none;
}
.modal-dialog .modal-content .modal-header .btn-close {
    background-color: none;
    background-image: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/close.svg);
    width: 15px;
    padding: 0;
    font-size: 12px;
    margin-right: 10px;
    color: #000000;
    opacity: 2;
}
.modal-dialog .modal-content .modal-header .btn-close::before {
    display: none;
}
.top20 {
    top: 20px;
}
.top40 {
    top: 40px;
}
.right20 {
    right: 20px;
}
.z1 {
    z-index: 1;
}
.z2 {
    z-index: 2;
}
.z3 {
    z-index: 3;
}
.z4 {
    z-index: 4;
}
.z5 {
    z-index: 5;
}
.z6 {
    z-index: 6;
}
.z7 {
    z-index: 7;
}
.z8 {
    z-index: 8;
}
.z9 {
    z-index: 9;
}
.radius-20 {
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}
.radius-15 {
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}
.radius-10 {
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.radius-5 {
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
/* Alumni Dashboard End */

.tooltip .tooltip-inner {
    background-color: #5a6268;
    color: #fff;
    font-size: 16px;
    padding: 10px;
}
.tooltip .tooltip-arrow {
    border-color: #5a6268;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type='number'] {
    -moz-appearance: textfield;
}
.social-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px;
    height: 48px;
    padding: 0 20px;
    line-height: 38px;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
}
form.lstCustForm label.social-block span {
    color: #fff;
    font-style: normal;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    padding-left: 0;
}
.social-block.fb {
    background: #3456a2;
}
.social-block.tw {
    background: #00a1f9;
}
.social-block.linkedin {
    background: #0077b5;
}
.social-block.fb img {
    margin-top: -4px;
}
.social-check label {
    width: 24px;
    height: 24px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0px;
    color: #fff;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.social-check input:checked + label {
    background: #5cae48;
    border-color: #5cae48;
    font-size: 11px;
}
.toggle-switch-parent {
    width: 380px;
    margin-right: 30px;
}
.masRow {
    display: flex;
    flex-wrap: wrap;
}
.masRow:not(:last-child) {
    margin-bottom: 20px;
}
.form-check-input-parent {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex: 1;
}
.form-check-input-parent .form-control {
    max-width: 80px;
    width: 80px;
}
.form-check-input-parent .btn-customize:not(:checked) ~ .form-control {
    background-color: #f2f2f2 !important;
    border-color: #dfdfdf !important;
    pointer-events: none;
}
.socialist-label {
    font-size: 20px;
    font-weight: 500;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 22px;
}
.socialist-block {
    background: #f3f3f3;
    border-radius: 6px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 5px 20px;
    height: 40px;
    width: 320px;
}
.sociallist-detail ul {
    display: flex;
    align-items: center;
    list-style: none;
    font-size: 14px;
    padding: 0;
    margin: 0;
}
.sociallist-detail ul li {
    position: relative;
}
.sociallist-detail ul li:not(:first-child):before {
    content: '';
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #000;
    position: absolute;
    margin: auto;
    opacity: 0.3;
    top: 0;
    bottom: 0;
    left: -3px;
}
.sociallist-detail ul li:not(:first-child) {
    padding: 0 0 0 15px;
    margin: 0 0 0 15px;
}
.socialist {
    padding: 20px;
    border: 1px solid #eee;
    border-radius: 6px;
}
.socialist:not(:last-child) {
    margin-bottom: 10px;
}
.postdetail {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
}
.postdetail .poston {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-size: 13px;
    opacity: 0.8;
    line-height: 17px;
}
.text-gray-68 {
    color: #686868;
}
.row_parent {
    padding: 15px 35px 20px !important;
    margin: -20px -25px 20px !important;
}
.row_parent:nth-child(even) {
    background: #f2f6fa;
}
.row_parent:last-child {
    margin-bottom: -25px !important;
}
form.lstCustForm .form-select,
form.lstCustForm input.form-control {
    border: 1px solid #848484;
    height: 45px;
}
form.lstCustForm textarea.form-control {
    border: 1px solid #848484;
}
form.lstCustForm .form-check .form-check-input {
    width: 24px;
    height: 24px;
    margin-left: 0px;
    border: 2px solid #848484;
}
form.lstCustForm .form-check:not(:last-child) {
    margin: 0 40px 5px 0;
}
form.lstCustForm .btn-greenm,
.btn-greenm252 {
    min-width: 252px;
}
form.lstCustForm label span {
    font-size: 14px;
    color: #848484;
    font-weight: 400;
    font-style: italic;
    float: right;
    display: inline-block;
    padding: 0 0 0 20px;
}
form.lstCustForm .inputfile_parent label span.uploadlabel {
    font-size: 16px;
    color: #414141;
    font-weight: 400;
    float: right;
    display: inline-block;
    padding: 0 0 0 8px;
    font-style: normal;
}
form.lstCustForm label span.icon {
    font-style: unset;
    color: #414141;
}
.fileclose {
    height: 12px;
    line-height: 10px;
    width: 12px;
    text-align: center;
    background: #959595;
    border-radius: 50%;
    color: #fff;
    font-size: 12px;
    cursor: pointer;
    display: none;
    margin-left: 6px;
    justify-content: center;
    align-items: center;
}
.fileclose span {
    font-size: 6px;
    display: block;
    margin: 0 0 0px 1px;
}
.fileicon {
    display: none;
    margin-right: 6px;
}
.docselect {
    display: none;
    align-items: center;
    width: max-content;
}
.docselect.docseladdbg {
    background: #f8f8f8;
    display: flex;
    padding: 7px 12px;
    margin: 10px 0 0 0;
    border-radius: 6px;
}
.inputfile::file-selector-button {
    font: inherit;
    display: none;
}
.inputfile_parent label {
    color: #f1e5e6;
    background-color: #f3f3f3;
    border: 1px dashed #848484;
    border-radius: 6px;
    height: 60px;
    line-height: 60px;
    max-width: 100%;
    font-size: 1.25rem;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.albmform .inputfile_parent label {
    height: 80px;
    line-height: 80px;
}
.inputfile_parent label span {
    font-size: 16px;
    color: #414141;
    font-weight: 500;
    font-style: unset;
    float: none;
    padding: 0 0 0 10px;
}
.inputfile_parent {
    position: relative;
    height: auto;
}
.inputfile_parent.profile .docselect {
    display: none;
}
.inputfile_parent.profile.select + p {
    display: none;
}
.inputfile_parent.profile label {
    background-color: #fff;
    border: 1px dashed #a8c5dd;
    border-radius: 6px;
    height: 40px;
    line-height: 40px;
}
.inputfile_parent.profile.select .docseladdbg {
    display: flex;
    background: #eaeaea;
}
.inputfile_parent.profile.pop-up label {
    background: #f3f3f3 0% 0% no-repeat padding-box;
    border: 1px dashed #848484;
    border-radius: 6px;
    opacity: 1;
    height: 45px;
    line-height: 45px;
}
.newmsg .ji_filtersec {
    background: #e6ebee;
    padding: 25px 30px 30px;
    margin-top: -1px;
}
.newmsg .filterSearch input {
    border-radius: 18px;
    border: 1px solid #e1e1e1;
    padding-left: 47px;
    color: #000000;
}
.newmsg .filterSearch .btn.icon-search {
    left: 20px;
    top: 10px;
    padding: 0;
    font-size: 14px;
    color: #414141;
    font-weight: 400;
}
.newmsg .filterSearch .btn.icon-search:hover {
    color: #0b3454;
}
.dotted-blue-border {
    height: 1px;
    width: 100%;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/dotted_border_bg.png) left top
        repeat-x;
}
.newmsg .ji_filtersec ul {
    list-style: none;
    padding: 0;
}
.newmsg .ji_filtersec ul li {
    margin: 0 0 20px;
}
.newmsg .ji_filtersec ul li a {
    color: #0b3454;
    font-weight: 400;
    font-size: 16px;
    text-decoration: none;
    width: 100%;
    display: inline-block;
}
.newmsg .ji_filtersec ul li.active a,
.newmsg .ji_filtersec ul li a:hover {
    color: #5cae48;
}
.msgtabcont {
    padding: 25px;
}
.msgtabcont.msgwrapext {
    padding: 25px 10px 25px 25px;
}
.msgtabcont .msgbox {
    border: 1px solid #dedede;
    border-radius: 6px;
    padding: 20px 0px 90px;
    min-height: 490px;
    max-height: 490px;
}
.msgtabcont .msgbox.msgboxconvo {
    padding: 5px 0px 60px;
    display: flex;
    flex-direction: column;
}
.mgs-links {
    display: flex;
    padding: 0 0 8px;
    width: 291px;
}
.mgs-links .btn {
    background: #d4edf9 0% 0% no-repeat padding-box;
    border: 1px solid #93d3ef;
    border-radius: 18px;
    font-size: 14px;
    color: #000;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    height: 36px;
}
.mgs-links .btn:first-child {
    margin-right: 10px;
}
.mgs-links .btn:hover {
    background: #93d3ef;
    color: #0e2d39;
}
.mgs-links .btn:focus {
    box-shadow: none;
}
.mgs-links .btn:focus-visible {
    outline: none;
}
.search-form-control {
    background: #fff url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/search.svg) no-repeat left 13px
        top 14px / 16px auto;
    border: 1px solid #d4d4d4;
    border-radius: 23px;
    opacity: 1;
    padding: 6px 10px 6px 43px;
    height: 45px;
}
.msgbox .filterSearch input {
    height: 45px;
    line-height: 45px;
    border: 1px solid #848484;
    border-radius: 23px;
    font-size: 16px;
    color: #666666;
}
.msgbox .filterSearch input:focus {
    border-color: #86b7fe;
}
.msgbox .filterSearch input:focus-visible {
    border-color: #86b7fe;
}
.msgbox .filterSearch input:focus-within {
    border-color: #86b7fe;
}
.newmsg .msgbox .filterSearch .btn.icon-search {
    top: 15px;
}
.msgbox .filterSearch input:focus-visible {
    border-color: #86b7fe;
}
.search_btmshadow {
    background: transparent linear-gradient(180deg, #f2f2f2 0%, #ffffff 100%) 0%0% no-repeat padding-box;
    height: 20px;
    width: 100%;
}
.msgbox_send {
    width: 100%;
    height: 60px;
    background: #f3f3f3;
    left: 0;
    bottom: 0;
    border-radius: 0 0 6px 6px;
    padding: 5px;
}
.msgbox_send input {
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    font-weight: 500;
    color: #414141;
}
.btn-complete-now.msgsendbtn span {
    font-size: 14px;
    font-weight: 600;
    position: relative;
}
.btn-complete-now.msgsendbtn {
    top: 5px;
    right: 5px;
    position: absolute;
    max-width: 70px;
    width: 100%;
}
.peerprofile {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    background: #f4f4f4;
    border-radius: 50%;
    margin: 0 10px 0 0;
    overflow: hidden;
}
.peerprofile img[src*='dummy_male.svg'] {
    margin-top: auto;
    width: auto;
    height: 85%;
}
.customCheckBox {
    width: 20px;
    height: 20px;
    border-width: 2px;
}
.customCheckBox:checked {
    background-color: #0b3454;
    border-color: #0b3454;
}
.customCheckBox:checked[type='checkbox'] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.lite-blue {
    background-color: #e9f6fc;
}
.height-80 {
    height: 80px !important;
}
.min-h-420 {
    min-height: 420px;
}
.radius-6 {
    border-radius: 6px !important;
}
.broadcast_submit .btn {
    background: #fff;
    box-shadow: 1px 1px 1px #0b34541a;
    border: 1px solid #0b34544d;
    border-radius: 25px;
    margin: 5px;
    font-size: 16px;
    transition: all 0.3s ease-in-out;
    padding: 0 25px;
    height: 50px;
}
.broadcast_submit .btn:hover {
    background: #5cae48;
    color: #fff;
    border-color: #5cae48;
}
.broadcast_submit .btn-publish {
    background: #5cae48;
    width: 200px;
    color: #fff;
}
.broadcast_submit .btn-publish:hover {
    background: #4c9649;
}
.card.twitter {
    border-color: #1da1f3;
    overflow: hidden;
}
.card.twitter .card-header {
    background: #1da1f3;
    color: #fff;
}
.card.facebook {
    border-color: #3456a2;
    overflow: hidden;
}
.card.facebook .card-header {
    background: #3456a2;
    color: #fff;
}
.card.instagram {
    border-color: #f84e44;
    overflow: hidden;
}
.card.instagram .card-header {
    color: #fff;
    background: rgb(171,16,128);
    background: linear-gradient(208deg, rgba(171,16,128,1) 0%, rgba(254,83,64,1) 70%, rgba(255,213,84,1) 100%);
}
.card.linkedin {
    border-color: #0077b5;
    overflow: hidden;
}
.card.linkedin .card-header {
    background: #0077b5;
    color: #fff;
}
.icon-star.circle-img {
    position: absolute;
    bottom: -3px;
    right: -3px;
    background: #5cae48;
    border-radius: 50%;
    color: #fff;
    font-size: 7px;
    padding: 4px;
}
.filter-custom-select {
    width: 100%;
    height: 45px;
    border: 1px solid #dee2e6;
    border-radius: 6px;
    background-color: #fff;
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e');
    background-repeat: no-repeat;
    background-position: center right 10px;
    background-size: 12px 12px;
    padding-right: 30px;
}
.dropdown-wrap {
    overflow-x: hidden;
    max-height: 250px;
}
.dropdown-wrap label input {
    margin-right: 5px;
    width: 18px;
    height: 18px;
}
.filter-custom-select.dashboard-filter {
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 10px;
    border: 2px solid #7d7d7d;
    height: 35px;
    border-radius: 25px;
}
.alumni-directory-header {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}
.alumni-directory-header_search {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
}
.alumni-directorySearch {
    background: #ffffff;
    width: 365px;
    height: 40px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    border-radius: 25px;
    overflow: hidden;
    position: relative;
}
.alumni-directorySearch .form-control {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    border: 0;
    padding-left: 0;
}
.alumni-directorySearch .btn.icon-search {
    font-size: 12px;
    padding: 0 10px 0 20px;
}
.alumni-directorySearch .btn-search-cross {
    position: absolute;
    right: 13px;
    top: 0;
    bottom: 0;
    margin: auto;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    color: #000;
    font-weight: 100 !important;
}
.alumni-directorySearch .btn-cross {
    width: 14px;
    height: 14px;
    position: absolute;
    right: -15px;
    top: 0;
    bottom: 0;
    margin: auto;
    transform: rotate(0deg);
    opacity: 0;
    transition: all 0.5s ease-in-out;
}
.alumni-directorySearch .btn-cross:before {
    content: '';
    position: absolute;
    width: 14px;
    height: 2px;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    margin: auto;
    background: #000;
    border-radius: 5px;
}
.alumni-directorySearch .btn-cross:after {
    content: '';
    position: absolute;
    width: 2px;
    height: 14px;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    margin: auto;
    background: #000;
    border-radius: 5px;
}
.alumni-directorySearch .form-control:valid + .btn-cross {
    opacity: 1;
    right: 15px;
    transform: rotate(135deg);
}
.alumni_directory_filter {
    padding: 30px 0;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
}
.alumni_directory_filter .ji_filtersec {
    width: 100%;
    max-width: 100%;
}
.alumni_directory_filter .fiterItem {
    width: 220px;
    margin: 0 5px;
    box-shadow: 0px 10px 25px #00000008;
}
.alumni_directory_filter .fiterItem.key {
    border-color: #5cae48;
}
.alumni_directory_filter .filterGroup {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    align-items: start;
    margin: 0 -5px;
}
.alumni_directory_filter .filterSearchblk {
    background: #fff;
    padding: 0 14px 10px;
}
.alumni_directory_filter .filterSearchblk .filterSearch input {
    margin: 0;
}
.alumni_directory_filter .filterHeader button {
    transition: all 0.3s ease-in-out;
    padding: 15px 15px;
}
.alumni_directory_filter .filterHeader button:not([aria-expanded='false']) {
    padding-bottom: 8px;
}
.alumni_directory_filter .fiterItem .card {
    padding: 0px 15px 15px;
}
.alumni_directory_filter .scrollMe {
    max-height: 122px;
    min-height: 122px;
    overflow-x: hidden;
}
.alumni_directory_filter .fiterItem .form-check:not(:last-child) {
    margin-bottom: 4px;
}
.additionalfilter_count {
    display: none;
}
.btn-md-filter {
    text-decoration: none;
    transform: rotate(90deg);
    color: #000;
    font-size: 20px;
    transition: all 0.3s ease-in-out;
}
.btn-md-filter:hover {
    color: #000;
}
.btn-md-filter[aria-expanded='true'] {
    transform: rotate(270deg);
}
.additionalfilter_wrap {
    width: 100%;
    padding: 0 5px;
}
.alumni_directory_filter .fiterItem.campus {
    width: 450px;
}
.showProfiles_wrap {
    background: #fff;
    border: 1px solid #d2d2d2;
    border-radius: 6px;
    box-shadow: 0px 10px 25px #00000008;
    width: calc(100% - 10px);
    margin: 0 5px;
    padding: 17px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.showProfiles_left {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
}
.showProfiles_left h3 {
    margin: 0 30px 0 0;
    font-size: 18px;
    line-height: 20px;
    font-weight: 600;
}
.showProfiles_left .form-check-label {
    font-size: 16px;
}
.showProfiles_left .form-check-input {
    width: 20px;
    height: 20px;
    vertical-align: top;
    margin-top: -2px;
    margin-right: 10px;
}
.showProfiles_left .form-check.form-check-inline {
    display: inline-flex;
    align-items: center;
}
.showProfiles_left .check_list {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
}
.showProfiles_left .form-check-input:checked {
    background-color: #5cae48;
    border-color: #5cae48;
}
.showProfiles_right .icon {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/subtraction.svg);
    width: 16px;
    height: 25px;
    margin-right: 8px;
}
.showProfiles_right {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.showProfiles_right .title {
    font-size: 14px;
    letter-spacing: -0.35px;
}
.alumni-bucket {
    background: #ffffff;
    border: 1px solid #d2d2d2;
    border-radius: 6px;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}
.alumni-bucket .block-link {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    z-index: 1;
}
.alumni_img {
    width: 60px;
    margin-right: 10px;
    height: 60px;
    background: #f1f1f1;
    border-radius: 50%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
}
.alumni_img img {
    width: auto;
    height: 100%;
}
.alumni_img.no_pic {
    align-items: end;
}
.alumni_img.no_pic img {
    width: auto;
    height: auto;
}
.alumni_name_brife h3 {
    font-size: 18px;
    margin: 0;
}
.class_year {
    color: #6a6868;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
}
.alumni_directory_details .btn-viewcompus {
    font-size: 13px;
    color: #1452ce;
    font-weight: 500;
    line-height: 18px;
    position: relative;
    z-index: 2;
    text-decoration: none;
}
.alumni_directory_details .btn-viewcompus:hover {
    color: #0b3454;
    text-decoration: underline;
}
.alumni_directory_details a.btn-send {
    padding: 0 20px;
    line-height: 36px;
    text-decoration: none;
    background: #5cae48;
    border-radius: 25px;
    height: 36px;
    display: inline-flex;
    color: #fff;
    align-items: center;
    transition: all 0.3s ease-in-out;
    position: relative;
    z-index: 2;
}
.alumni_directory_details a.btn-send:hover {
    background: #40862f;
}
.icon-alumni-send {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/send_alumni_profile.svg) no-repeat 0
        0 / 100%;
    width: 19px;
    height: 19px;
    margin-right: 8px;
    margin-left: -3px;
}
/* .alumni_univarsity_details {background: #F9F9F9 ;border-radius: 0px 0px 5px 5px;opacity: 1;display: flex;justify-content: center;-webkit-box-flex: 1;-moz-box-flex: 1;-webkit-flex: 1;-ms-flex: 1;flex: 1;padding: 11px;position:relative}
.alumni_univarsity_details .alumni_univarsity_details_inner_cover {max-width: 260px;width: 90%;display: flex;flex-wrap: wrap;}
.alumni_univarsity_details .alumni_univarsity_details_inner_cover .alumni_univarsity_logo {width: 64px;margin-right: 10px;padding-top: 6px;}
.alumni_univarsity_details .alumni_univarsity_details_inner_cover .alumni_univarsity_logo img {width: 100%;border-radius: 50%;}
.alumni_univarsity_details .alumni_univarsity_details_inner_cover .alumni_univarsity_brife {width: calc(100% - 75px);} */
.alumni_directory_details a.alumni_name_link {
    color: #000;
    text-decoration: none;
}
.alumni_directory_details a.alumni_name_link:hover {
    color: #1452ce;
}
.alumni_class_link {
    padding: 15px 20px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
}
.alumni_education {
    color: #4d4d4d;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
}
.alumni_univarsity_name {
    font-size: 19px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 3px;
}
.alumni_univarsity_name .icon-subtraction {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/subtraction.svg) no-repeat 0 0 / 100%;
    width: 12px;
    height: 20px;
    margin-left: 3px;
    display: inline-block;
    vertical-align: top;
}
.alumni_univarsity_subject {
    color: #0b3454;
    font-size: 14px;
    line-height: 16px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
}
.alumni_univarsity_subject span {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.alumni_univarsity_subject i {
    width: 16px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/subject_setting.svg) no-repeat 0 0 /
        100%;
    height: 16px;
    margin-right: 7px;
}
.filter_result ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
}
.filter_result_title {
    margin-bottom: 18px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 10px;
}
.filter_result_title .filter_result {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.filter_result ul li {
    background: #e9f6fc;
    border-radius: 25px;
    padding: 9px 23px;
    margin-top: 6px;
    margin-bottom: 6px;
}
.filter_result ul li:not(:last-child) {
    margin-right: 10px;
}
.filter_result ul li:last-child {
    background: no-repeat;
    padding: 9px 0;
}
.filter_result ul li span {
    margin-right: 10px;
}
.filter_result ul li .btn-cross {
    font-size: 10px;
    color: #000;
    text-decoration: none;
}
.filter_result ul li .btn-cross:hover {
    color: #105271;
}
.alumni_count {
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    margin: 18px 0 0;
}
.btn-additionalfilter {
    text-decoration: none;
    font-size: 14px;
    color: #1452ce;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    transition: all 0.3s ease-in-out;
}
.btn-additionalfilter span {
    text-decoration: underline;
    margin-right: 10px;
}
.btn-additionalfilter .icon-right-arrow {
    transform: rotate(90deg);
    transition: all 0.3s ease-in-out;
}
.btn-additionalfilter[aria-expanded='true'] .icon-right-arrow {
    transform: rotate(270deg);
}
.btn-additionalfilter:hover {
    color: #0b3454;
}
.btn-additionalfilter:hover span {
    text-decoration: none;
}
.alumni_directory_filter .filters-head h3 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    margin: 0;
    align-items: center;
}
.alumni_directory_filter .filters-head h3 .icon-filter + span {
    flex: 1;
}
.alumni_directory_filter .filters-head h3[aria-expanded='true'] i {
    transform: rotate(270deg);
}
.alumni_directory_details .load_more_btn {
    text-decoration: none;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
}
.alumni_directory_details .load_more_btn:hover {
    background: #0b3454;
    color: #fff;
    border-color: #0b3454;
}
.alumni_directory_details .load_more_btn img {
    -webkit-animation: none;
}
.alumni_directory_details .load_more_btn:hover img {
    filter: invert(1) brightness(100);
}
.tooltip-viewcompus .tooltip-arrow::before {
    display: none;
}
.alumni_profile_brife_wrap {
    border-bottom: 1px solid #e7e7e7;
}
.alumni_profile_brife {
    padding: 40px 0;
}
.alumni_profile_brife .btn-back {
    color: #000;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
}
.alumni_profile_brife .btn-back .icon-right-arrow {
    transform: scaleX(-1);
    margin-right: 7px;
    font-size: 12px;
}
.alumni_profile_brife .btn-back:hover {
    color: #0b3454;
}
.alumni_profile_name_college_other_detail_wrap {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
}
.alumni_profile_image_name_other_detail {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    padding: 0 45px;
    position: relative;
    width: 45%;
}
.alumni_profile_image_name_other_detail:after {
    content: '';
    width: 1px;
    height: 100%;
    background: #d4d4d4;
    position: absolute;
    right: 0;
    top: 0;
}
.alumni_profile_image {
    width: 110px;
    height: 110px;
    background: #f1f1f1;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin-right: 20px;
}
.alumni_profile_image img {
    width: auto;
    height: 100%;
}
.alumni_profile_image.no_pic {
    align-items: end;
}
.alumni_profile_image.no_pic img {
    height: 80%;
}
.alumni_profile_name_other_detail {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.alumni_profile_name_other_detail h2 {
    font-size: 30px;
    line-height: 18px;
    font-weight: 600;
    word-break: break-all;
    line-height: 35px;
}
.alumni_profile_name_other_detail .alumni_profile_location {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 15px;
}
.alumni_profile_class_college {
    position: relative;
    padding: 0 0 0 20px;
    margin-bottom: 13px;
}
.alumni_profile_class_college:before {
    content: '';
    position: absolute;
    left: 6px;
    top: 0;
    bottom: 0;
    width: 3px;
    height: 100%;
    background: #d52941;
    border-radius: 5px;
}
.alumni_profile_class_college .alumni_profile_class {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.32px;
    line-height: 18px;
    margin-bottom: 5px;
}
.alumni_profile_class_college .alumni_profile_college {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.32px;
    line-height: 18px;
}
.alumni_profile_college_phone {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
.alumni_profile_college_phone .verifyLink {
    font-size: 16px;
    font-weight: 500;
}
.verifiedLink {
    color: #5cae48;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
}
.alumni_profile_name_other_detail .btn-send {
    width: auto;
    height: 40px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    background: #5cae48;
    border-radius: 25pc;
    color: #fff;
    text-decoration: none;
    align-items: center;
    padding: 0 25px;
    font-size: 16px;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
}
.alumni_profile_name_other_detail .btn-send .icon-send {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/send_alumni_profile.svg) no-repeat 0
        0 / 100%;
    width: 19px;
    height: 19px;
    margin-right: 10px;
    position: relative;
    transition: all 0.3s ease-in-out;
    left: 0;
}
.alumni_profile_name_other_detail .btn-send:hover {
    background: #2fa612;
}
.alumni_profile_name_other_detail .btn-send:hover .icon-send {
    left: 3px;
}
.alumni_profile_brife_tab {
    margin-bottom: 20px;
}
.alumni_profile_status {
    list-style: none;
    margin: 0;
    padding: 0;
}
.alumni_profile_status li {
    margin-bottom: 16px;
    width: 176px;
    position: relative;
    padding-left: 16px;
}
.alumni_profile_status li .title {
    letter-spacing: 0.32px;
    color: #000000;
    font-size: 16px;
}
.alumni_profile_status li p {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.32px;
}
.alumni_profile_status li:before {
    content: '';
    width: 3px;
    height: 17px;
    position: absolute;
    left: 0;
    top: 4px;
    background: #d52941;
    border-radius: 5px;
}
.alumni_profile_college_joined {
    color: #5cae48;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    margin-bottom: 5px;
}

.alumni_profile_college_name_other_detail_wrap .alumni_profile_college_name_detail h3 {
    font-size: 20px;
    line-height: 26px;
    font-weight: 600;
}
.alumni_profile_college_subject {
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    background: #e5f1fb;
    border-radius: 25px;
    font-size: 16px;
    line-height: 16px;
    font-weight: 600;
    color: #0b3454;
}

.underGraduate-detail .main_title,
.blockHeader {
    font-size: 22px;
    color: #000;
    width: auto;
    margin: -40px -40px 25px;
    border-radius: 6px 6px 0px 0px;
    padding: 20px 40px;
    line-height: 22px;
    border-bottom: 1px solid #f5f5f5;
}
.blockHeader .title span {
    font-weight: 600;
}
.graduateRow .college_detail_wrap .college_detail h3 {
    font-size: 20px;
    font-weight: 600;
    margin: 0 0 2px;
    transition: all 0.3s ease-in-out;
}
.graduateRow .college_detail_wrap:hover h3 {
    text-decoration: underline;
    color: #1452ce;
}
.graduateRow .college_detail_wrap .college_detail h3 .icon-subtraction {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/subtraction.svg) no-repeat 0 0 / 100%;
    width: 16px;
    height: 25px;
    margin-left: 3px;
    display: inline-block;
    vertical-align: top;
}
.graduateRow .college_detail_wrap .college_detail p {
    font-size: 16px;
    color: #414141;
    margin: 0 0 5px;
}
.graduateRow {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    position: relative;
}
.graduateRow:not(:last-child) {
    border-bottom: 2px solid #e5e5e5;
    margin-bottom: 28px;
    padding-bottom: 28px;
}
.underGraduate-detail,
.postGraduate-detail {
    box-shadow: 0px 10px 25px #00000008;
    border-radius: 6px;
    background: #fff;
    padding: 40px;
}
.graduateRow .college_detail_wrap {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    width: calc(100% - 265px);
    padding-right: 40px;
    position: relative;
    align-items: center;
}
.graduateRow .college_detail_wrap > a {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}
.graduateRow .college_detail_wrap:after {
    content: '';
    position: absolute;
    top: 13px;
    right: 0;
    width: 2px;
    height: 63px;
    background: #d1d1d1;
}
.graduateRow .offer {
    width: 265px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: center;
}
.graduateRow .college_detail_wrap .college_detail {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
}
.graduateRow .college_detail_wrap .college_detail .college_logo {
    margin-right: 20px;
    width: 150px;
    height: 95px;
    border: 1px solid #d5d5d5;
    border-radius: 6px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
}
.college_name_scholarship {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
.college_name_scholarship .college_name {
    width: calc(100% - 100px);
}
.graduateRow .college_detail_wrap .college_detail .college_logo .logo_link {
    padding: 10px;
    display: inline-block;
    width: 100%;
    height: 100%;
}
.graduateRow .college_detail_wrap .college_detail .college_logo .logo_link img {
    width: 100%;
    height: 100%;
}
.graduateRow .college_detail_wrap .scholarship {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    text-align: center;
    line-height: 20px;
}
.graduateRow .college_detail_wrap .scholarship .scholarship_label {
    font-weight: 500;
    color: #8a8a8a;
}
.graduateRow .college_detail_wrap .scholarship .scholarship_amt {
    font-weight: 600;
    white-space: nowrap;
}
.graduateRow .offer > a,
.graduateRow .offer a.joindeLink {
    padding: 0 20px;
    text-decoration: none;
    height: 37px;
    background: #5cae48;
    color: #fff;
    border-radius: 25px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    transition: all 0.3s ease-in-out;
}
.graduateRow .offer > a:hover,
.graduateRow .offer a.joindeLink:hover {
    background: #3e9329;
}
.graduateRow .offer > a i,
.graduateRow .offer a.joindeLink i {
    margin-right: 10px;
}
.graduateRow .offer > a:hover {
    background: #3b8d27;
}
.graduateRow .offer .got_offer {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 5px;
}
.graduateRow .offer .got_offer i {
    margin-right: 10px;
    font-size: 20px;
    color: #5cae48;
}
.graduateRow .offer .offerLink {
    color: #1452ce;
}
.graduateRow .offer .offerLink:hover {
    color: #0b3454;
}
.graduateRow .offer .offer_joined i {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/hat_alumni.svg);
    width: 23px;
    height: 17px;
    margin-right: 10px;
}
.graduateRow .offer .offer_joined {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
}
.graduateRow.edit .college_detail_wrap {
    width: calc(100% - 350px);
}
.graduateRow .offer.offer_edit {
    flex-direction: row;
    align-items: center;
    width: 350px;
    justify-content: end;
}
.graduateRow .offer.offer_edit .edit_section,
.edit_section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: 20px;
    align-items: center;
}
.edit_section_inner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.graduateRow .offer .edit_link,
.edit_section .edit_link {
    width: 52px;
    height: 30px;
    background: #eaf4fc;
    border-radius: 20px;
    transition: all 0.3s ease-in-out;
    position: relative;
}
.graduateRow .offer .edit_link:hover,
.edit_section .edit_link:hover {
    background-color: #0b3454;
}
.graduateRow .offer .edit_link:before,
.edit_section .edit_link::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/draw.svg) no-repeat center / 18px
        auto;
}
.graduateRow .offer .edit_link:hover:before,
.edit_section .edit_link:hover::before {
    filter: invert(99%) sepia(1%) saturate(2%) hue-rotate(228deg) brightness(110%) contrast(100%);
}
.graduateRow .offer .del_link,
.edit_section .del_link,
.del_link {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/delete.svg);
    width: 14px;
    height: 16px;
    margin-left: 10px;
    opacity: 0.5;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.graduateRow .offer .del_link:hover,
.edit_section .del_link:hover,
.del_link:hover {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/delete_dark.svg);
    opacity: 1;
}
.graduateRow .offer .offer_existing_links {
    display: flex;
    flex-direction: column;
    align-items: end;
}
.form-group .del_link {
    position: absolute;
    top: 15px;
    right: -20px;
}
.graduation-title {
    font-size: 22px;
    font-weight: 400;
    margin: -15px 0 20px;
}
.graduation-title span {
    font-weight: 600;
}
.graduation_carousel_wrap {
    margin-top: 20px;
    box-shadow: 0px 10px 25px #00000008;
    border-radius: 6px;
    background: #fff;
    padding: 40px;
}
.graduation_carousel_wrap .card_profiles:first-child {
    margin-bottom: 18px;
}
.graduation_carousel_wrap .seactionheader {
    margin: -10px 0 27px;
}
.graduation_carousel_wrap .seactionheader h3 {
    font-size: 22px;
    font-weight: 400;
}
.graduation_carousel_wrap .seactionheader h3 span {
    font-weight: 600;
}
.card_profiles {
    border: 1px solid #d2d2d2;
    padding: 20px;
    border-radius: 6px;
    flex-direction: column !important;
    display: flex;
    justify-content: space-between;
    height: 100%;
    position: relative;
    background: #fff;
    max-height: 240px;
    min-height: 240px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    overflow-y: auto;
}
.card_profiles::-webkit-scrollbar {
    width: 4px;
}
.card_profiles::-moz-scrollbar {
    width: 4px;
}
.card_profiles::-webkit-scrollbar-track {
    background: #ccc;
}
.card_profiles::-webkit-scrollbar-thumb {
    background-color: #666;
    border-radius: 20px;
    border: 2px solid #666;
}
.card_profiles .alumni-Profile-button-rounded {
    position: absolute;
    right: 20px;
    padding: 0 20px;
}
.card_profiles .alumni-Profile-button-rounded:hover,
.card_profiles .alumni-Profile-button-rounded:focus {
    background: #40952c;
    color: #fff;
    background-color: #40952c;
    border-color: 0;
    box-shadow: none;
}
.card_profiles figure.student {
    width: 90px;
    height: 90px;
    overflow: hidden;
    border-radius: 200px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.card_profiles figure.student img {
    border-radius: 200px;
}
.student_details {
    width: calc(100% - 110px);
}
.card_profiles .student_details figure img {
}
.card_profiles .student_details h2 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 15px;
    width: 65%;
    margin-top: 010px;
}
.card_profiles .student_details h2 a {
    color: #000;
    text-decoration: none;
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
    display: inline-block;
    overflow: hidden;
}
.card_profiles .student_details h2 a:hover {
    text-decoration: underline;
    color: #1452ce;
}
.card_profiles .student_details h2 span {
    display: block;
    font-size: 16px;
    color: #6a6868;
    font-weight: 500;
}
.card_profiles .student_details .sub_details {
    display: flex;
    flex-grow: 1 !important;
}
.card_profiles .student_details .sub_details_workExp {
    align-items: center;
    position: relative;
    padding-bottom: 5px;
}
.card_profiles .student_details .sub_details_achivements figure {
    background: #0b3454 !important;
}
.card_profiles .student_details .sub_details_workExp figure {
    border: 0 !important;
    border-radius: 100px !important;
    min-width: 50px !important;
    height: 50px !important;
    width: 50px !important;
    background-color: #d52941;
}
.card_profiles .student_details .sub_details_workExp figure img {
    width: 45%;
    height: auto;
}
.card_profiles .student_details .sub_details_workExp figure.bg_white {
    background-color: #fff !important;
    border: 1px solid #d2d2d2 !important;
}
.card_profiles .student_details .sub_details_workExp figure.bg_white img {
    width: 80%;
}
.card_profiles .student_details .sub_details_workExp:after {
    position: absolute;
    width: 83%;
    height: 1px;
    content: '';
    background: #c1c1c1;
    bottom: 0;
    right: 0; /* padding-bottom: 20px; */
}
.card_profiles .student_details .sub_details_workExp1:after {
    display: none;
}
.card_profiles .student_details .sub_details h3 {
    margin-bottom: 0;
    min-width: 43px;
    justify-content: center;
}
.card_profiles .student_details .sub_details figure {
    border: 1px solid #d2d2d2;
    border-radius: 100px;
    min-width: 80px;
    height: 80px;
    overflow: hidden;
    margin-right: 10px;
    margin-bottom: 0;
    width: 80px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.card_profiles .student_details .sub_details p {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 5px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 43px;
    overflow: hidden;
    line-height: 18px;
}
.card_profiles .student_details .sub_details a {
    color: #000;
    text-decoration: none;
}
.card_profiles .student_details .sub_details a:hover {
    text-decoration: underline;
    color: #1452ce;
}
.card_profiles .student_details .sub_details p.title,
.card_profiles .student_details .sub_details p.green-title {
    color: #5cae48;
    font-size: 14px;
    margin-bottom: 10px;
}
.card_profiles .student_details .sub_details span {
    color: #333333;
    font-size: 14px;
    margin-bottom: 15px;
    font-weight: 400;
}
.card_profiles .student_details .sub_details span.course {
    color: #0b3454;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    align-items: center;
    margin-bottom: 0;
}
.card_profiles .student_details .sub_details span.course img {
    margin-right: 6px;
    width: 16px;
}
.card_profiles .action_btns {
    margin-top: 20px;
    border-top: 1px solid #d2d2d2;
    padding-top: 20px;
    display: flex;
    justify-content: center !important;
    width: 100%;
}
.card_profiles .action_btns .btn-outline-primary {
    margin-right: 10px;
    border: 2px solid #5bae47;
    border-radius: 50px;
    padding: 7px 20px;
    color: #5bae47;
}
.card_profiles .action_btns .btn-outline-primary:hover img {
    filter: brightness(0) invert(1);
}
.card_profiles .action_btns .btn-outline-primary:hover {
    border: 2px solid #418d2e;
    background-color: #418d2e;
    color: #fff;
}
.card_profiles .action_btns .btn-secondary {
    border-radius: 50px;
    padding: 7px 20px;
    color: #fff;
    background: #0b3454;
}
.card_profiles .action_btns .btn-secondary:hover {
    background: #26a7df;
    border: 1px solid #26a7df;
}
.card_profiles .btn-send {
    width: 123px;
    height: 40px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    background: #5cae48;
    border-radius: 25pc;
    color: #fff;
    text-decoration: none;
    align-items: center;
    padding: 0 25px;
    font-size: 16px;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
}
.card_profiles .btn-send {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    position: absolute;
    top: 20px;
    right: 20px;
}
.card_profiles .btn-send .icon-send {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/send_alumni_profile.svg) no-repeat 0
        0 / 100%;
    width: 19px;
    height: 19px;
    margin-right: 10px;
    position: relative;
    transition: all 0.3s ease-in-out;
    left: 0;
}
.card_profiles .btn-send:hover .icon-send {
    left: 3px;
}
.card_profiles .btn-send:hover {
    background: #3b8d27;
}
.workExperience-detail {
    box-shadow: 0px 10px 25px #00000008;
    border-radius: 6px;
    background: #fff;
    padding: 40px;
}
.workExperience-detail .workExperience-title {
    font-size: 22px;
    font-weight: 400;
    margin: -15px 0 30px;
}
.workExperience-detail .workExperience-title span {
    font-weight: 600;
}
.work-block {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
}
.work-block .work-detail {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: flex;
    flex-direction: column;
}
.work-block .icon {
    width: 55px;
    height: 55px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    margin-right: 20px;
    background: #d52941;
    border-radius: 50%;
    color: #fff;
}
.work-block .work-detail p {
    font-size: 20px;
    line-height: 24px;
    margin: 0 0 5px;
    font-weight: 500;
}
.work-block .work-detail span {
    font-size: 16px;
    color: #6d6b6b;
    line-height: 16px;
    font-weight: 500;
}
.achievements-block {
    box-shadow: 0px 10px 25px #00000008;
    border-radius: 6px;
    background: #fff;
    padding: 30px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    position: relative;
}
.achievements-block:not(:last-child) {
    border-bottom: 1px solid #f5f5f5;
}
.achievements-icon-block {
    padding: 0 55px 0 30px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 185px;
    max-width: 185px;
    align-items: center;
    text-align: center;
    border-right: 1px solid #cecece;
    margin-right: 30px;
}
.achievements-icon-block .icon {
    width: 60px;
    height: 60px;
    background: #0b3454;
    border-radius: 50%;
    margin-bottom: 8px;
    position: relative;
}
.achievements-icon-block .icon.icon-medal:before {
    content: '';
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/exam_ico.svg);
    width: 26px;
    height: 36px;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    margin: auto;
}
.achievements-icon-block .icon.icon-grade:before {
    content: '';
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/exam_ico.svg);
    width: 25px;
    height: 31px;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    margin: auto;
}
.achievements-icon-block .icon.icon-publication:before {
    content: '';
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/publication_ico.svg);
    width: 29px;
    height: 31px;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    margin: auto;
}
.achievements-icon-block .icon.icon-promotion:before {
    content: '';
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/promotion_ico.svg);
    width: 32px;
    height: 37px;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    margin: auto;
}
.achievements-icon-block .icon.icon-course-completion:before {
    content: '';
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/course_ico.svg);
    width: 31px;
    height: 37px;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    margin: auto;
}
.achievements-icon-block .icon.icon-social-service:before {
    content: '';
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/social_ico.svg);
    width: 36px;
    height: 36px;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    margin: auto;
}
.achievements-icon-block .icon.icon-scholarship:before {
    content: '';
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/scholarship_ico.svg);
    width: 39px;
    height: 28px;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    margin: auto;
}
.achievements-icon-block .icon.icon-others:before {
    content: '';
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/others_ico.svg);
    width: 32px;
    height: 32px;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    margin: auto;
}
.achievements-icon-block .achievements-title {
    font-size: 18px;
    font-weight: 600;
}
.achievements-icon-block .achievements-year {
    font-size: 18px;
    line-height: 22px;
    color: #414141;
}
.achievements-block-detail h3 {
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 0px;
}
.achievements-block-detail h3 span {
    font-weight: 600;
}
.achievements-block-detail p {
    font-size: 16px;
    line-height: 24px;
    margin: 0;
}
.achievements-block-detail {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}
.hide {
    display: none;
}
.achievements-block-detail p.show .hide {
    display: inline;
}
.achievements-block-detail p.show .btn-readmore {
    font-size: 0;
}
.achievements-block-detail p.show .btn-readmore:before {
    content: 'Read Less';
    font-size: 16px;
}
.achievements-block-detail p .btn-readmore {
    color: #1452ce;
    transition: all 0.3s ease-in-out;
}
.achievements-block-detail p .btn-readmore:hover {
    color: #1046b3;
}
.achievements-block-detail .url-link {
    color: #1452ce;
    transition: all 0.3s ease-in-out;
}
.achievements-block-detail .url-link:hover {
    color: #1046b3;
}
.achievements-list {
    box-shadow: 0px 10px 25px #00000008;
    border-radius: 6px;
    background: #fff;
}
.achievements-block-title {
    padding: 20px 40px;
    border-bottom: 1px solid #f5f5f5;
    border-radius: 6px 6px 0 0;
}
.achievements-block-title .title {
    font-size: 22px;
}
.achievements-block-title .title span {
    font-weight: 600;
}
.btn-add-section {
    background: #ffffff;
    border: 1px solid #0b3454;
    border-radius: 19px;
    height: 37px;
    display: flex;
    padding: 0 20px;
    align-items: center;
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
    color: #0b3454;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.btn-add-section i {
    position: relative;
    width: 11px;
    height: 110px;
    margin-right: 10px;
}
.btn-add-section i:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #0b3454;
    width: 2px;
    height: 10px;
}
.btn-add-section i:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #0b3454;
    width: 10px;
    height: 2px;
}
.btn-add-section:hover {
    background: #0b3454;
    color: #fff;
}
.btn-add-section:hover i:before,
.btn-add-section:hover i:after {
    background: #fff;
}
.contributions-block {
    box-shadow: 0px 10px 25px #00000008;
    border-radius: 6px;
    background: #fff;
    padding: 40px;
}
.contributions-block:not(:last-child) {
    margin-bottom: 20px;
}
.contributions-block .act_lst:not(:last-child) {
    margin-bottom: 20px;
}
.contributions-block .seactionheader {
    margin: -10px 0 20px;
}
.contributions-block h3 {
    font-size: 22px;
    font-weight: 600;
}
.contributions-block .circle-effect::before {
    background: #6c8598;
}
.contributions-block .list_badge {
    color: #d52941;
    background: no-repeat;
    position: static;
    text-transform: uppercase;
}
.contributions-block .act_lst {
    padding: 25px 25px 25px 25px;
}
.contributions-block .video_block_name label {
    font-size: 16px;
}
.skills-block {
    box-shadow: 0px 10px 25px #00000008;
    border-radius: 6px;
    background: #fff;
    padding: 40px;
    position: relative;
}
.skills-block:first-child {
    padding-bottom: 20px;
}
.skills-block.EntranceExam-skills-block {
    padding-bottom: 33px;
}
.skills-block:not(:last-child) {
    margin-bottom: 20px;
}
.skills-block h2 {
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 15px;
    margin: -10px 0 25px;
}
.skills-block h2 span {
    font-weight: 600;
}
.skills-block-detail {
    display: flex;
    flex-wrap: wrap;
}
.skills-block-detail .meter {
    margin: 0 12px 20px 0;
    border: 1px solid #c7c7c7;
    padding: 10px 30px;
    border-radius: 50px;
    font-size: 20px;
    font-weight: 500;
    align-items: center;
}
.skills-block-detail .meter img {
    width: 50px;
}
.skills-block-title {
    margin-top: -30px;
    margin-bottom: 20px;
}
.preferences-block {
    box-shadow: 0px 10px 25px #00000008;
    border-radius: 6px;
    background: #fff;
    padding: 40px;
}
.preferences-block:not(:last-child) {
    margin-bottom: 20px;
}
.preferences-block.EntranceExam-skills-block {
    padding-bottom: 33px;
}
.preferences-block h2 {
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 15px;
    margin: -10px 0 25px;
}
.preferences-block h2 span {
    font-weight: 600;
}
.preferences-block-detail {
    display: flex;
    flex-wrap: wrap;
}
.preferences-block-detail .meter {
    margin: 0 12px 20px 0;
    border: 1px solid #c7c7c7;
    padding: 10px 30px;
    border-radius: 50px;
    font-size: 20px;
    font-weight: 500;
    align-items: center;
}
.preferences-block-detail .meter img {
    width: 50px;
}
.preferences-block-title {
    margin-top: -30px;
    margin-bottom: 20px;
}
.interested-block .icon {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background: #0b3454;
    position: relative;
    margin-right: 20px;
}
.interested-block .icon.icon-bulb::before {
    content: '';
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/bulb.svg) no-repeat 0 0 / 34px auto;
    width: 34px;
    height: 36px;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    margin: auto;
}
.interested-block .icon.icon-note::before {
    content: '';
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/publication_ico.svg);
    width: 29px;
    height: 31px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}
.interested-block {
    display: flex;
    align-items: center;
}
.interested-block .interested-topics {
    font-size: 20px;
    flex: 1;
    line-height: 22px;
}
.interested-block .interested-topics span {
    font-weight: 500;
}
.graduationslider .meter {
    padding: 10px 30px;
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    border-radius: 50px;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
}
.profile-item {
    text-align: center;
    width: 196px;
    height: 210px;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
}
.owl-item:not(:last-child) .profile-item {
    border-right: 1px solid #d2d2d2;
}
.profile-item-img {
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 11px;
}
.profile-item-name-class h3 {
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 4px;
}
.profile-item-name-class p {
    margin: 0 0 11px;
    font-size: 14px;
    font-style: italic;
    color: #535353;
}
.profile-item-name-class .subject {
    color: #0b3454;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    position: relative;
    padding-top: 10px;
}
.profile-item-name-class .subject:before {
    content: '';
    width: 30px;
    height: 2px;
    background: #5cae48;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
}
.profile-item-name-class .btn-viewcompus {
    font-size: 13px;
    color: #1452ce;
    font-weight: 500;
    line-height: 18px;
    position: relative;
    z-index: 2;
    text-decoration: none;
}
.profile-item-name-class .btn-viewcompus:hover {
    color: #0b3454;
    text-decoration: underline;
}
.psychology-item {
    text-align: center;
    width: 245px;
    height: 196px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 10px;
}
.owl-item:not(:last-child) .psychology-item {
    border-right: 1px solid #d2d2d2;
}
.popularCollegesslider .owl-item.active + .owl-item.active + .owl-item.active + .owl-item.active .psychology-item {
    border-right: 0px solid #d2d2d2;
}
.psychology-item-img {
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    height: 90px;
    align-items: center;
}
.psychology-item-name h3 {
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 5px;
    line-height: 17px;
}
.psychology-item-name p {
    font-size: 14px;
    font-style: italic;
    font-weight: 500;
    line-height: 20px;
}
.alumni_directory_details .btn-working {
    font-size: 13px;
    color: #1452ce;
    font-weight: 500;
    line-height: 18px;
    position: relative;
    z-index: 2;
    text-decoration: none;
}
.alumni_directory_details .btn-working:hover {
    color: #0b3454;
    text-decoration: underline;
}
.tooltip-viewcompus .tooltip-inner {
    background: #3b3b3b;
    border-color: #3b3b3b;
}
.tooltip-working .tooltip-inner {
    display: flex;
    flex-direction: column;
    padding: 15px;
    background: #3b3b3b;
    border-color: #3b3b3b;
}
.tooltip-working .tooltip-inner .logo {
    position: relative;
    font-size: 20px;
}
.tooltip-working .tooltip-inner span.logo + span {
    font-size: 16px;
}
.tooltip-working.bs-tooltip-top .tooltip-arrow::before {
    border-top-color: #3b3b3b;
}
.tooltip-working.bs-tooltip-bottom .tooltip-arrow::before {
    border-bottom-color: #3b3b3b;
}
.tooltip-working.bs-tooltip-end .tooltip-arrow::before {
    border-right-color: #3b3b3b;
}
.tooltip-working.bs-tooltip-start .tooltip-arrow::before {
    border-left-color: #3b3b3b;
}
.alumni_name_brife > div {
    align-items: center;
}
.alumni_name_brife > div .sep {
    margin: 0 6px;
    color: #1452ce;
}
.custom_badge {
    display: inline-block;
    border: 1px solid #dedede;
    height: 32px;
    line-height: 32px;
    padding: 0 16px;
    border-radius: 18px;
    font-size: 14px;
    font-weight: 500;
    color: #0b3454;
    margin: 0 5px 10px 0;
    background: #fff;
    overflow: hidden;
}
.custom_badge .icon {
    margin-right: 5px;
}
.delete_confirm .icon-cross {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid red;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #e94e58;
}
.delete_confirm .del_text {
    margin: 20px 0 10px;
    text-align: center;
    max-width: 240px;
    font-size: 28px;
    line-height: 36px;
    font-weight: 500;
    width: 100%;
}
.delete_confirm .btn-delete {
    background: #d52941;
    border-radius: 31px;
    border: 0;
    height: 36px;
    color: #fff;
    padding: 0 20px;
    font-weight: 600;
    font-size: 14px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.delete_confirm .btn-delete:hover {
    background: #a0182b;
}
.selected_options ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}
.selected_options ul li {
    background: #f7fbff;
    border: 1px solid #0b34544d;
    border-radius: 18px;
    height: 35px;
    display: flex;
    align-items: center;
    padding: 0 18px;
    margin: 12px 12px 6px 0;
    font-size: 16px;
    font-weight: 500;
}
.selected_options ul li .icon-cross {
    font-size: 9px;
    margin: 2px 0 0 10px;
    text-decoration: none;
    color: #000;
}
.selected_options ul li .icon-cross:hover {
    text-decoration: none;
    color: #333;
}
.question .form-select.w-90 {
    width: 90px !important;
    max-width: 90px;
}
.custom_row_title {
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 20px;
}
.custom_row_title span {
    font-weight: 600;
}
.custom_row_block .program {
    font-size: 20px;
}
.custom_row_block .program span {
    font-weight: 500;
}
.program-time-interest {
    color: #414141;
    font-size: 16px;
    line-height: 20px;
}
.custom_row_block {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.custom_row_block .icon {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background: #0b3454;
    margin-right: 15px;
    position: relative;
}
.custom_row_block .icon.icon-hat:before {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/hat_alumni.svg) no-repeat 0 0 /26px;
    width: 26px;
    height: 20px;
    display: inline-block;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    position: absolute;
    filter: invert(1);
    -webkit-filter: invert(1);
}
.custom_row_block .icon + div {
    width: calc(100% - 70px);
}
.custom_row {
    width: 100%;
    border-top: 1px solid #dbdbdb;
    padding-top: 20px;
}
.custom_row:not(:last-child) {
    padding-bottom: 20px;
}
.radius_block {
    background: #ffffff;
    border: 1px solid #c7c7c7;
    border-radius: 30px;
    height: 60px;
    padding: 0 30px;
    display: flex;
    align-items: center;
    font-size: 20px;
    line-height: 20px;
    font-weight: 500;
}
.radius_block span {
    margin-right: 30px;
}
.radius_block i {
    font-size: 24px;
}
.radius_block i.gray {
    color: #8599a9;
}
.radius_block i.green {
    color: #5dae48;
    background-color: transparent !important;
}
.radius_block.yes {
    background: #5dae48;
    border-color: #5dae48;
    color: #fff;
}
.radius_block.yes span {
    margin: 0 20px;
}
.radius_block.no {
    background: #c7c7c7;
    color: #333;
}
.radius_block.no span {
    margin: 0 20px;
}
.universities_block {
    background: #ffffff;
    border: 1px solid #c7c7c7;
    border-radius: 30px;
    height: 60px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
}
.universities_block .logo {
    width: auto;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    border-radius: 50%;
    overflow: hidden;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.universities_block span {
    font-size: 20px;
    margin-right: 15px;
    line-height: 20px;
}
.logo.no_logo {
    width: 40px;
    border: 1px solid #cad6df;
}
.edit_section {
    font-size: 0;
}
.edit_section_toggle,
.custom-btns a.edit_section_toggle {
    display: none;
    width: 20px;
    height: 20px;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='%23343a40' stroke='none' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px 20px;
}

@media (max-width: 1199px) {
    .alumni_profile_college_name_other_detail_wrap {
        padding: 0 0 0 50px;
    }
}

@media (max-width: 1130px) {
    .alumni_profile_brife_tab {
        overflow: auto;
        scrollbar-width: thin;
        padding-bottom: 10px;
        margin-bottom: 10px;
    }
    .alumni_profile_brife_tab::-webkit-scrollbar {
        height: 4px;
    }
    .alumni_profile_brife_tab::-moz-scrollbar {
        height: 4px;
    }
    .alumni_profile_brife_tab::-webkit-scrollbar-track {
        background: #ccc;
    }
    .alumni_profile_brife_tab::-webkit-scrollbar-thumb {
        background-color: #666;
        border-radius: 20px;
        border: 2px solid #666;
    }
    .alumni_profile_brife_tab .nav {
        justify-content: start;
        flex-wrap: nowrap;
    }
}

@media (max-width: 1099px) {
    .alumni_profile_college_name_other_detail_wrap {
        padding: 0 0 0 30px;
    }
    .alumni_profile_image_name_other_detail {
        padding: 0 30px;
        width: 45%;
    }
    .alumni_profile_college_name_other_detail_wrap .alumni_profile_college_name_other_detail {
        flex-direction: column;
    }
    .alumni_profile_college_name_detail {
        padding: 0 0 21px;
    }
    .alumni_profile_status {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-wrap: wrap;
        -moz-box-wrap: wrap;
        -ms-flex-wrap: wrap;
        -webkit-wrap: wrap;
        flex-wrap: wrap;
    }
    .alumni_profile_status li {
        margin-right: 16px;
    }
    .alumni_profile_name_other_detail h2 {
        line-height: 30px;
    }
    .college_name_scholarship .college_name {
        width: 100%;
        margin-bottom: 10px;
    }
}
@media (max-width: 1035px) {
    .college_name_scholarship {
        flex-direction: column;
        align-items: start;
    }
    .graduateRow .college_detail_wrap .scholarship {
        margin-top: 10px;
    }
    .graduateRow .offer {
        justify-content: start;
        padding-top: 13px;
    }
}

@media (min-width: 992px) and (max-width: 1150px) {
    .filter_result_title {
        flex-direction: column;
    }
    .alumni_count {
        text-align: right;
    }
    .alumni_class_link {
        flex-direction: column;
    }
    .alumni_class_link .send_link {
        padding: 10px 0 0 70px;
    }
}

@media (max-width: 991px) {
    .alumni-directorySearch {
        width: 230px;
    }
    .alumni_profile_name_college_other_detail_wrap {
        flex-direction: column;
    }
    .alumni_profile_image_name_other_detail {
        width: 100%;
        padding-bottom: 45px;
        margin-bottom: 45px;
    }
    .alumni_profile_image_name_other_detail::after {
        content: '';
        width: 100%;
        height: 1px;
        background: #d4d4d4;
        position: absolute;
        right: auto;
        top: auto;
        bottom: 0;
        left: 0;
    }
    .alumni_profile_college_name_other_detail_wrap {
        padding: 0;
    }
    .alumni_profile_college_name_other_detail_wrap .alumni_profile_college_name_other_detail {
        flex-direction: row;
    }
    .alumni_profile_brife_tab .nav-item:not(:last-child) {
        margin-right: 7px;
    }
    .card_profiles {
        overflow: visible;
        max-height: initial;
    }
}

@media (max-width: 950px) {
    .showProfiles_left {
        flex-direction: column;
        align-items: start;
        -webkit-box-flex: 1;
        -moz-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
    }
    .showProfiles_left h3 {
        margin: 0 0 10px;
    }
}

@media (max-width: 875px) {
    .graduateRow {
        flex-direction: column;
    }
    .graduateRow .college_detail_wrap {
        width: 100%;
    }
    .graduateRow .offer {
        width: 100%;
    }
    .college_name_scholarship {
        flex-direction: row;
        align-items: start;
    }
    .graduateRow .college_detail_wrap {
        width: 100%;
        padding: 0 0 20px;
        margin-bottom: 20px;
    }
    .graduateRow .college_detail_wrap::after {
        top: auto;
        right: 0;
        width: 75px;
        height: 2px;
        bottom: 0;
    }
    .graduateRow .offer {
        padding: 0;
        align-items: start;
    }
    .graduateRow .college_detail_wrap::after {
        right: auto;
        bottom: 0;
        left: 0;
    }
    .graduateRow.edit .college_detail_wrap {
        width: 100%;
    }
    .graduateRow .offer.offer_edit {
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: start;
    }
    .graduateRow .offer .offer_existing_links {
        align-items: start;
    }
}

@media (max-width: 820px) {
    .graduation_carousel_wrap {
        padding: 20px;
    }
    .graduationslider .meter {
        padding: 10px 15px;
        margin: 15px 0 10px 0px;
        font-size: 16px;
    }
    .postGraduate-detail {
        padding: 0;
        background: none;
        box-shadow: none;
        border: 1px solid #d2d2d2;
        overflow: hidden;
    }
    .postGraduate-detail .graduateRow {
        padding: 20px;
        background: #fff;
    }
    .graduation-title {
        margin: 0;
        background: #eff8ff;
        border-radius: 6px 6px 0px 0px;
        padding: 20px;
        text-align: center;
        line-height: 22px;
    }
    .graduation_carousel_wrap .seactionheader {
        margin: 0 0 15px;
        flex-direction: column;
        align-items: inherit;
    }
    .graduation_carousel_wrap .seactionheader .countnbtn {
        width: 100%;
        margin-top: 13px;
        justify-content: space-between;
    }
    .graduation_carousel_wrap .seactionheader h3 {
        width: auto;
        margin: -20px -20px 0;
        background: #eff8ff;
        border-radius: 6px 6px 0px 0px;
        padding: 20px;
        text-align: center;
        line-height: 22px;
    }
    .workExperience-detail .workExperience-title {
        width: auto;
        margin: 0;
        background: #eff8ff;
        border-radius: 6px 6px 0px 0px;
        padding: 20px;
        text-align: center;
        line-height: 22px;
    }
    .workExperience-detail {
        padding: 0px;
        overflow: hidden;
    }
    .workExperienceRow {
        padding: 25px;
    }
    .skills-block-title {
        margin: 0 0 20px;
        width: auto;
        margin: -40px -40px 20px;
        background: #eff8ff;
        border-radius: 6px 6px 0px 0px;
        padding: 20px;
        text-align: center;
        line-height: 22px;
    }
    .skills-block-title h2 {
        background: no-repeat;
        padding: 0;
        text-align: left;
    }
    .preferences-block {
        padding: 30px;
    }
    .preferences-block-title {
        width: auto;
        margin: -30px -30px 0px;
        background: #eff8ff;
        border-radius: 6px 6px 0px 0px;
        padding: 20px;
        line-height: 22px;
    }
    .custom_row:first-child {
        border: 0;
    }
    .achievements-block-title {
        background: #eff8ff;
    }
    .underGraduate-detail .main_title,
    .blockHeader {
        background: #eff8ff;
    }
    .graduateRow {
        background: #fff;
    }
    .postGraduate-detail .blockHeader {
        margin: 0;
    }
    .workExperience-detail .blockHeader {
        margin: 0;
    }
}

@media (min-width: 767px) {
    .nolisting_wrap .nolisting {
        max-width: 850px;
        margin: auto;
        border: 0;
        display: flex;
        flex-wrap: wrap;
        text-align: left;
        padding-bottom: 10px;
    }
    .nolisting_text {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: start;
    }
    .nolisting_icon {
        margin-right: 40px;
    }
}

@media (max-width: 767px) {
    .alumni_directory_filter {
        padding: 18px 0;
        padding-right: var(--bs-gutter-x, 0.75rem);
        padding-left: var(--bs-gutter-x, 0.75rem);
    }
    .alumni-directory-header_search {
        flex-direction: column;
        align-items: start;
    }
    .alumni-directory-header_search h2 {
        margin-bottom: 15px !important;
    }
    .alumni-directory-header {
        align-items: end;
    }
    .filter_result_title {
        flex-direction: column;
    }
    .filter_result_title .filter_result {
        display: none;
    }
    .alumni_count {
        text-align: right;
        margin: 0;
    }
    .alumni_directory_filter .fiterItem {
        width: 170px;
        margin: 0 !important;
        box-shadow: none;
        border: 0;
        border-radius: 0;
        border-bottom: 1px solid #e6e6e6 !important;
    }
    .alumni_directory_filter .filters {
        padding: 0;
        display: flex !important;
        flex-direction: column;
    }
    .alumni_directory_filter .filters > div:first-child {
        padding: 15px 20px;
        border-bottom: 1px solid #dbdbdb;
    }
    .alumni_directory_filter .filters-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: auto;
        padding: 0 15px;
        box-shadow: 0px 0px 3px 0px #ccc;
        position: relative;
        z-index: 1;
    }
    .alumni_directory_filter .filters-head + #accordionFilters {
        flex: 1 1;
        position: relative;
        background: #f5f5f5;
    }
    .alumni_directory_filter .filters #accordionFilters {
        -webkit-box-flex: 1;
        -moz-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        margin: 0;
        position: relative;
    }
    .alumni_directory_filter .filters #accordionFilters::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 170px;
        background: #f5f5f5;
    }
    .alumni_directory_filter .filters > #accordionFilters > .filterGroup.mt-3 {
        margin: 0 !important;
        flex-direction: column;
        height: 100%;
        position: relative;
    }
    .alumni_directory_filter .fiterItem .filterHeader > div {
        border-radius: 0;
    }
    .alumni_directory_filter .fiterItem .filterHeader > div button {
        background: #f5f5f5;
        border-radius: 0;
        padding: 10px;
        color: #000;
    }
    .alumni_directory_filter .fiterItem .filterHeader > div button .toggle {
        display: none;
    }
    .alumni_directory_filter .fiterItem .filterHeader > div button .font-18.font-600 {
        font-size: 16px !important;
        font-weight: 500 !important;
    }
    .additionalfilter_wrap {
        width: 170px;
        padding: 0;
        margin: 0 !important;
    }
    /* .btn-additionalfilter {display: none;} */
    .additionalfilter_wrap > #additionalfilter {
        display: block !important;
    }
    .alumni_directory_filter .fiterItem .filterHeader + div {
        position: absolute;
        right: 0;
        top: 0;
        padding: 0;
        bottom: 0;
        width: calc(100% - 170px);
        padding: 15px;
        overflow: auto;
        background: #fff;
    }
    .alumni_directory_filter .filterGroup {
        margin: 0 !important;
    }
    .alumni_directory_filter .filterGroup.mt-3 {
        margin: 0 !important;
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .filter_close_apply {
        background: #ffffff;
        border-top: 1px solid #e9e9e9;
        padding: 15px 20px;
        justify-content: center;
        align-items: center;
    }
    .filter_close_apply > a.btn-filter-apply {
        padding: 8px 30px;
        background: #5cae48;
        border-radius: 3px;
        text-decoration: none;
        color: #fff;
        margin: 0 15px;
        font-weight: 500;
        text-transform: uppercase;
        transition: all 0.3s ease-in-out;
    }
    .filter_close_apply > a.btn-filter-apply:hover {
        background: #448e32;
        color: #fff;
    }
    .filter_close_apply > a.btn-filter-close {
        text-decoration: none;
        text-transform: uppercase;
        color: #979797;
        font-weight: 500;
        transition: all 0.3s ease-in-out;
        margin: 0 15px;
    }
    .filter_close_apply > a.btn-filter-close:hover {
        color: #666262;
    }
    .alumni_directory_filter .showProfiles_right {
        margin-left: 12px;
    }
    .alumni_directory_filter .filterSearchblk {
        background: #fff;
        padding: 0 0 10px;
    }
    .alumni_directory_filter .fiterItem .card {
        padding: 0px 0px 15px;
    }
    .alumni_directory_filter .fiterItem .filterHeader > div button[aria-expanded='true'] {
        pointer-events: none;
        color: #1452ce;
    }
    .alumni_directory_filter .scrollMe {
        max-height: initial;
        min-height: initial;
    }
    .additionalfilter_count {
        display: block;
        letter-spacing: 2px;
    }
    .additionalfilter_count:before {
        content: '(';
    }
    .additionalfilter_count:after {
        content: ')';
    }
    .alumni_directory_filter .mobileFilter .btn-filter .icon-filter + span {
        -webkit-box-flex: 1;
        -moz-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        text-align: left;
    }
    .alumni_directory_filter .mobileFilter .btn-filter .icon-filter + span + span {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-wrap: wrap;
        -moz-box-wrap: wrap;
        -ms-flex-wrap: wrap;
        -webkit-wrap: wrap;
        flex-wrap: wrap;
        align-items: center;
        color: #b92940;
        font-size: 14px;
    }
    .alumni_directory_filter .mobileFilter .btn-filter {
        display: flex;
        align-items: center;
    }
    .alumni_directory_filter .mobileFilter .btn-filter .icon-filter + span + span .icon-right-arrow {
        font-size: 15px;
        transform: rotate(270deg);
        margin-left: 10px;
        color: #000;
        transition: all 0.3s ease-in-out;
    }
    .alumni_directory_filter .mobileFilter .btn-filter[aria-expanded='false'] .icon-right-arrow {
        transform: rotate(90deg) !important;
    }
    #filter_collapse h3 {
        pointer-events: none;
    }
    .alumni_directory_filter {
        background: #f3f3f3;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 99;
        box-shadow: 0 0 5px 0px #cacaca;
    }
    .alumni_directory_filter .filters.show {
        -moz-transform: translate3d(0, 100%, 0);
        -webkit-transform: translate3d(0, 100%, 0);
        -o-transform: translate3d(0, 100%, 0);
        -ms-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
    .alumni_profile_image_name_other_detail {
        width: 100%;
        padding-bottom: 30px;
        margin-bottom: 30px;
    }
    .alumni_profile_college_name_other_detail_wrap .alumni_profile_college_name_other_detail {
        flex-direction: column;
    }
    .nolisting_wrap .nolisting {
        border: 0;
        padding: 20px 0 10px;
    }
    .nolisting_text {
        text-align: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .edit_section {
        position: absolute;
        right: 20px;
        top: 5px;
    }
    .countnbtn .edit_section {
        position: relative;
        left: auto;
        right: auto;
        top: 0;
        margin-left: 5px;
    }
    .skills-block .edit_section {
        top: 22px;
    }
    .edit_section .edit_section_toggle {
        display: inline-block;
    }
    .custom-btns a.edit_section_toggle {
        display: inline-block;
    }
    .edit_section .edit_section_inner {
        position: absolute;
        top: 50px;
        right: 0;
        background: #fff;
        box-shadow: 0px 0px 5px #57555533;
        display: flex;
        flex-direction: column;
        width: 100px;
        border-radius: 6px;
        overflow: hidden;
        opacity: 0;
        z-index: -1;
        transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
    }
    .edit_section.open .edit_section_inner {
        top: 30px;
        opacity: 1;
        z-index: 9;
    }
    .edit_section .edit_section_inner a {
        width: 100% !important;
        border-radius: 0 !important;
        padding: 0 0 0 30px;
        font-size: 14px;
        text-decoration: none;
        color: #000;
        line-height: 33px;
        height: 35px !important;
        background: #fff !important;
        position: relative;
        opacity: 1;
        margin: 0 !important;
        -webkit-border-radius: 0 !important;
        -moz-border-radius: 0 !important;
        -ms-border-radius: 0 !important;
        -o-border-radius: 0 !important;
        opacity: 1 !important;
    }
    .edit_section .edit_section_inner a:hover {
        color: #fff;
        background-color: #0b3454 !important;
    }
    .edit_section .edit_section_inner a.edit_link:before {
        width: 16px;
        height: 16px;
        margin: 0;
        background-size: 14px;
        left: 7px;
        top: 8px;
    }
    .edit_section .edit_section_inner a:first-child {
        border-bottom: 1px solid #eee;
    }
    .edit_section .edit_section_inner a.del_link:before {
        content: '';
        background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/delete.svg) no-repeat center /
            14px auto;
        width: 14px;
        height: 16px;
        position: absolute;
        left: 8px;
        top: 8px;
    }
    .edit_section .edit_section_inner a.del_link:hover {
        background: #0b3454;
        color: #fff;
    }
    .edit_section .edit_section_inner a.del_link:hover:before {
        filter: invert(92%) sepia(100%) saturate(0%) hue-rotate(202deg) brightness(106%) contrast(106%);
        -webkit-filter: invert(92%) sepia(100%) saturate(0%) hue-rotate(202deg) brightness(106%) contrast(106%);
    }
    .graduateRow .college_detail_wrap .scholarship {
        margin-right: 60px;
    }
    .achievements-block-detail .edit_section {
        top: 4px;
        right: 0;
    }
    .achievements-block-detail {
        padding-right: 25px;
    }
    .postGraduate-detail .graduateRow .offer.offer_edit .edit_section {
        top: 20px;
    }
    .work-block .edit_section {
        right: 0;
    }
    .work-block .work-detail {
        padding-right: 24px;
    }
    .directory_wrap {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 100%;
        bottom: auto;
        left: 0;
        right: 0;
        margin: auto;
        background: white;
        display: flex;
        flex-direction: column;
        transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
    }
    .directory_wrap.open {
        top: 0;
        bottom: 0;
    }
}

@media (min-width: 575px) and (max-width: 767px) {
    .filter_result_title {
        flex-direction: column;
    }
    .alumni_count {
        text-align: right;
    }
    .alumni_class_link {
        flex-direction: column;
    }
    .alumni_class_link .send_link {
        padding: 10px 0 0 70px;
    }
}

@media (max-width: 575px) {
    .alumni-directory-header {
        flex-direction: column;
    }
    .alumni-directory-header_search {
        flex-direction: row;
        width: 100%;
        margin-bottom: 20px;
        justify-content: space-between;
        overflow: hidden;
        align-items: center;
    }
    .alumni-directory-header_search h2 {
        margin-bottom: 0 !important;
    }
    .alumni-directorySearch-wrap {
        position: relative;
    }
    .alumni-directorySearch-wrap .alumni-directorySearch {
        position: absolute;
        right: -230px;
        transition: all 0.3s ease-in-out;
    }
    .alumni-directorySearch-wrap.show .alumni-directorySearch {
        right: 0;
    }
    .btn-alumniSearchToggle.icon-search {
        width: 40px;
        height: 40px;
        background: #fff;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        color: #000;
        font-size: 12px;
    }
    .alumni-directorySearch .form-control + .btn-cross {
        opacity: 1;
        right: 15px;
        transform: rotate(135deg);
    }
    .alumni-switch-container {
        margin: auto;
    }
    .alumni-directorySearch .form-control {
        padding-right: 40px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .alumni_profile_image_name_other_detail {
        padding: 0 0 20px;
        margin-bottom: 20px;
    }
    .alumni_profile_college_name_other_detail .alumni_profile_other_detail {
        margin: 0 0 0 -98px;
    }
    .alumni_profile_status li {
        width: 47%;
        margin-bottom: 0;
    }
    .alumni_profile_status li:last-child {
        margin-right: 0;
    }
    .alumni_profile_image {
        width: 80px;
        height: 80px;
    }
    .alumni_profile_name_other_detail h2 {
        line-height: 30px;
        font-size: 24px;
    }
    .alumni_profile_college_name_other_detail_wrap .alumni_profile_college_logo {
        width: 80px;
        height: 80px;
    }
    .alumni_profile_brife {
        padding: 20px 0 10px;
    }
    /*.graduateRow .college_detail_wrap .college_detail {  flex-direction: column;align-items: center;}*/
    .graduateRow .college_detail_wrap .college_detail {
        padding-right: 25px;
    }
    .graduateRow {
        margin-left: -20px;
        margin-right: -20px;
        width: auto;
        padding: 5px 20px;
    }
    .graduateRow .college_detail_wrap .college_detail .college_logo {
        margin: 0 10px 10px 0;
        width: 70px;
        height: 70px;
        border-radius: 50%;
    }
    .graduateRow .college_name_scholarship {
        flex-direction: column;
        align-items: start;
        justify-content: start;
        text-align: left;
        width: calc(100% - 80px);
    }
    .graduateRow .college_detail_wrap .college_detail h3 {
        font-size: 19px;
        line-height: 20px;
    }
    .alumni_profile_college_subject {
        padding: 0 15px;
        font-size: 15px;
    }
    .graduateRow .college_detail_wrap .scholarship {
        flex-direction: row;
    }
    .graduateRow .college_detail_wrap .scholarship .scholarship_label {
        margin-right: 7px;
    }
    .graduateRow .college_detail_wrap::after {
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 100%;
        height: 1px;
    }
    .graduateRow .offer {
        align-items: center;
        flex-direction: row;
        justify-content: center;
    }
    .graduateRow:not(:last-child) {
        margin-bottom: 15px;
        padding-bottom: 20px;
    }
    .underGraduate-detail {
        padding: 20px;
        box-shadow: none;
    }
    .workExperience-detail {
        padding: 0px;
    }
    .workExperienceRow {
        padding: 20px 20px 30px;
    }
    .achievements-block {
        flex-direction: column;
    }
    .achievements-icon-block {
        border-right: 0;
        margin: 0 0 18px;
        padding: 0 0 18px;
        border-bottom: 1px solid #cecece;
    }
    .achievements-block-detail {
        text-align: center;
    }
    .achievements-block-detail h3 {
        margin: 0;
    }
    .contributions-block .seactionheader {
        margin: 0px 0 20px;
        flex-direction: column;
        align-items: inherit;
    }
    .contributions-block .seactionheader > h3 {
        width: auto;
        margin: -20px -20px 0;
        background: #eff8ff;
        border-radius: 6px 6px 0px 0px;
        padding: 20px;
        text-align: center;
        line-height: 22px;
    }
    .contributions-block .seactionheader .countnbtn {
        width: 100%;
        margin-top: 10px;
        align-items: center;
        justify-content: space-between;
        position: relative;
    }
    .contributions-block .seactionheader .countnbtn .custom-btns {
        padding-right: 0;
    }
    .contributions-block .seactionheader .countnbtn .custom-btns .edit_section {
        right: 0;
        top: 0px;
    }
    .contributions-block {
        padding: 20px;
    }
    .skills-block {
        padding: 20px;
    }
    .skills-block:first-child {
        padding-bottom: 5px;
    }
    .skills-block.EntranceExam-skills-block {
        padding-bottom: 25px;
    }
    .skills-block h2 {
        margin: 0 0 20px;
        width: auto;
        margin: -20px -20px 20px;
        background: #eff8ff;
        border-radius: 6px 6px 0px 0px;
        padding: 20px;
        text-align: center;
        line-height: 22px;
    }
    .skills-block-detail .meter {
        font-size: 18px;
        align-items: center;
        padding: 10px 20px;
        line-height: 18px;
    }
    .card_profiles figure.student {
        position: absolute;
        left: 15px;
        top: 15px;
    }
    .card_profiles .student_details h2 {
        margin: 0 0 10px 90px;
    }
    .card_profiles .btn-send {
        position: static;
        margin-left: 90px;
    }
    .card_profiles .student_details .sub_details {
        margin-top: 15px;
    }
    .card_profiles .student_details .sub_details span.course {
        line-height: 15px;
    }
    .contributions-block .act_lst {
        padding: 15px;
    }
    .contributions-block .act_lst .postedtxt {
        text-align: left;
        position: absolute;
        top: 15px;
        white-space: nowrap;
        right: 15px;
    }
    .graduateRow .offer > a {
        margin: 0 10px 0 0;
    }
    .graduateRow .offer div.got_offer {
        margin-right: 15px;
        width: calc(100% - 173px);
        align-items: center;
        display: flex;
        margin-bottom: 0 !important;
        text-align: left;
        flex: 1;
        justify-content: center;
        line-height: 18px;
    }
    .graduateRow .offer .offer_joined {
        align-items: start;
        width: 158px;
        align-items: center;
        display: flex;
        margin-bottom: 0 !important;
        line-height: 16px;
    }
    .graduateRow .offer .offer_joined i {
        margin-right: 8px;
    }
    .contributions-block .act_lst > .row > div:last-child .btn-green {
        width: 135px;
    }
    .contributions-block .act_lst .btn-dark-blue {
        width: 135px;
    }
    .contributions-block .listblk_icon {
        margin-bottom: 0;
        width: 34px;
        height: 34px;
    }
    .contributions-block .act_lst .postedrow > div:last-child {
        width: 100% !important;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        border-top: 1px solid #d1d1d1;
        padding-top: 10px;
        margin-top: 8px;
    }
    .act_lst > .row.postedrow > div:last-child > div:first-child {
        width: calc(100% - 190px);
        margin: 0 0 0 15px;
        align-items: center;
    }
    .alumni_class_link {
        align-items: center;
    }
    .filter_result_title {
        margin-bottom: 12px;
    }
    .contributions-block .album_shareblk {
        padding: 5px 0 0 0px;
    }
    .profile-item,
    .psychology-item {
        width: 100%;
        border: 0 !important;
    }
    /*.user_id {	bottom: 0;}*/
    .skills-block-title {
        margin: 0 0 20px;
        width: auto;
        margin: -20px -20px 20px;
        background: #eff8ff;
        border-radius: 6px 6px 0px 0px;
        padding: 20px;
        text-align: center;
        line-height: 22px;
    }
    .skills-block-title h2 {
        padding: 0;
        text-align: left;
    }
    #edit_Higher_Education .question {
        padding-right: 20px;
    }
    #job_Preferences .question {
        padding-right: 20px;
    }
    .underGraduate-detail .main_title,
    .blockHeader {
        margin: -20px -20px 30px;
        padding: 20px;
    }
    .graduateRow .offer .offer_existing_links {
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .graduateRow .offer .got_offer {
        margin-right: 10px;
    }
    .graduateRow .offer a.joindeLink {
        margin: 0 10px 0 0;
    }
    .powerby {
        margin: auto;
    }
    .graduateRow .offer.offer_edit .edit_section {
        top: 10px;
    }
    .postGraduate-detail .graduateRow {
        margin: 0;
    }
    .alumni_profile_college_name_detail {
        width: 100%;
    }
}

@media (max-width: 480px) {
    .card_profiles.card_profiles_pg > div {
        flex-direction: column;
        display: flex;
    }
}

@media (max-width: 400px) {
    .graduateRow .offer.offer_edit {
        flex-direction: column;
        align-items: start;
    }
    .graduateRow .offer.offer_edit .edit_section {
        margin: 0;
    }
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
}
header + .relevantAlumniMatch {
    display: none;
}
.relevantAlumniMatch {
    background: transparent linear-gradient(93deg, #fff7f8 0%, #ffe6ea 100%);
    box-shadow: 0px 10px 20px #ffebee;
    border: 1px solid #fdd3da;
    border-radius: 8px;
    padding: 15px 20px;
    margin-bottom: 20px;
    position: relative;
}
.relevantAlumniMatch p {
    font-size: 20px;
    margin: 0;
    font-weight: 500;
    line-height: 22px;
}
.relevantAlumniMatch .btn-complete-now {
    border: 1px solid #d42941;
    border-radius: 25px;
    width: 160px;
    height: 40px;
    background: #fff;
    color: #000;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    margin-right: 23px;
    transition: all 0.3s ease-in-out;
}
.relevantAlumniMatch .btn-complete-now:before {
    display: none;
}
.relevantAlumniMatch .btn-complete-now:hover {
    background: #d42941;
    color: #fff;
}
.relevantAlumniMatch .btn-close {
    font-size: 10px;
}

@media (max-width: 767px) {
    .relevantAlumniMatch .btn-close {
        font-size: 10px;
        position: absolute;
        top: 13px;
        right: 10px;
    }
}
.pencil-edit {
    background-image: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/draw.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 17px;
    width: 17px;
    height: 17px;
    display: inline-block;
}
.alumni_profile_image_name_other_detail .pencil-edit {
    position: absolute;
    right: 30px;
    top: 10px;
}
.verifynow {
    border: 2px solid #d52941;
    padding: 2px 14px;
    border-radius: 25px;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
    height: 30px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
}
.verifynow:hover {
    background: #d52941;
    color: #fff;
}
.alumni_profile_image_name_other_detail .btnverified {
    background: #5cae48;
    border: 0;
    padding: 0 22px;
    width: auto;
    font-size: 14px;
}
.alumni_profile_image_name_other_detail .btnverified:hover {
    background: #439130;
    color: #fff;
}
.alumni_profile_image_name_other_detail .btnverified:hover .icon-varified {
    color: #fff !important;
}
.btn-back-dismiss {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='%23343a40' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='0.2' d='M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50px 32px;
    width: 20px;
    height: 15px;
    margin: 5px 5px 0 0px;
}
#delete_confirm_pop {
    z-index: 9999;
}
.modal.show ~ .modal.show ~ .modal-backdrop {
    z-index: 9998;
    opacity: 0.7;
}
.headerRightSide > ul li .user_id {
    position: static;
    border-radius: 5px 5px 0 0;
    width: 100%;
    padding: 7px 15px;
    text-align: right;
    background: #eee;
    color: #000;
}
.username:hover div.user-dropdown::before {
    width: 0;
    height: 0;
    content: '';
    position: absolute;
    top: -5px;
    right: 10px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #d2d2d2;
}
/* New css */
.modal-dialog .modal-content .modal-header .btn-close {
    background-color: none;
    background-image: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/close.svg);
    width: 15px;
    padding: 0;
    font-size: 12px;
    margin-right: 10px;
    color: #000000;
    opacity: 2;
}
.modal-dialog .modal-content .modal-header .btn-close::before {
    display: none;
}
.icon-plus {
    position: relative;
    width: 20px;
    height: 20px;
}
.icon-plus::before,
.icon-plus::after {
    content: '';
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 2px;
    height: 10px;
    background: #fff;
}
.icon-plus::after {
    transform: rotate(90deg);
}
.icon-medal {
    width: 16px;
    height: 16px;
    display: inline-block;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpath fill='%23343a40' stroke='' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M223.7 130.8L149.1 7.77C147.1 2.949 141.9 0 136.3 0H16.03c-12.95 0-20.53 14.58-13.1 25.18l111.3 158.9C143.9 156.4 181.7 137.3 223.7 130.8zM256 160c-97.25 0-176 78.75-176 176S158.8 512 256 512s176-78.75 176-176S353.3 160 256 160zM348.5 317.3l-37.88 37l8.875 52.25c1.625 9.25-8.25 16.5-16.63 12l-46.88-24.62L209.1 418.5c-8.375 4.5-18.25-2.75-16.63-12l8.875-52.25l-37.88-37C156.6 310.6 160.5 299 169.9 297.6l52.38-7.625L245.7 242.5c2-4.25 6.125-6.375 10.25-6.375S264.2 238.3 266.2 242.5l23.5 47.5l52.38 7.625C351.6 299 355.4 310.6 348.5 317.3zM495.1 0H375.7c-5.621 0-10.83 2.949-13.72 7.77l-73.76 122.1c42 6.5 79.88 25.62 109.5 53.38l111.3-158.9C516.5 14.58 508.9 0 495.1 0z'/%3e%3c/svg%3e");
    background-size: cover;
}
.icon-dollor {
    width: 20px;
    height: 20px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/dollor.svg) no-repeat 0 0 / 100%;
    display: inline-block;
}
.icon-note {
    width: 16px;
    height: 20px;
    display: inline-block;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/document.svg) no-repeat 0 0 / 100%;
}
.top20 {
    top: 20px;
}
.top40 {
    top: 40px;
}
.right20 {
    right: 20px;
}
.z1 {
    z-index: 1;
}
.z2 {
    z-index: 2;
}
.z3 {
    z-index: 3;
}
.z4 {
    z-index: 4;
}
.z5 {
    z-index: 5;
}
.z6 {
    z-index: 6;
}
.z7 {
    z-index: 7;
}
.z8 {
    z-index: 8;
}
.z9 {
    z-index: 9;
}
.radius-25 {
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
}
.radius-20 {
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}
.radius-15 {
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}
.radius-10 {
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.radius-5 {
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.skill-range .progress {
    height: 20px;
    max-width: 800px;
    width: 90%;
    border-radius: 25px;
}
.skill-range .progress .progress-bar {
    border-radius: 25px;
}
.green {
    background: #5cae48 !important;
}
#skillvideoslider {
    padding: 0 30px;
}
.owl-theme#skillvideoslider .owl-nav [class*='owl-'] {
    position: absolute;
    top: 37%;
    width: 10px;
    height: 10px;
    border-top: 2px solid #000;
    border-right: 2px solid #000;
    border-radius: 0;
    transform: rotate(45deg);
    font-size: 0;
}
.owl-theme#skillvideoslider .owl-nav [class*='owl-']:hover {
    background-color: transparent;
}
.owl-theme#skillvideoslider .owl-nav .owl-prev {
    left: 0;
    transform: scaleX(-1) rotate(45deg);
}
.owl-theme#skillvideoslider .owl-nav .owl-next {
    right: 0;
}
.owl-theme#skillvideoslider .owl-dots .owl-dot span {
    width: 16px;
    height: 16px;
    background: #fff;
    border: 1px solid #c2c2c2;
    margin: 0px 5px;
}
.owl-theme#skillvideoslider .owl-dots .owl-dot.active span,
.owl-theme#skillvideoslider .owl-dots .owl-dot:hover span {
    background: #fff;
    border: 0;
    box-shadow: 0 0 0 5px #0b3454 inset;
}
.small-line {
    position: relative;
}
.small-line:before {
    position: absolute;
    content: '';
    width: 40px;
    height: 2px;
    background: #2680eb 0% 0% no-repeat padding-box;
    border-radius: 100px;
    border-radius: 100px;
    bottom: -5px;
}
.benefitsBox {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.benefitsBox li {
    width: 49%;
    border-radius: 10px;
    opacity: 1;
    min-height: 80px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    padding: 15px 30px;
}
.beige {
    background: #f6ebdf;
}
.lightGreen {
    background: #daf3e5;
}
.lime {
    background: #e4f7cd;
}
.lightsky {
    background: #d3f1f7;
}
ul.green-right {
    list-style: none;
    margin: 0 0 20px;
    padding: 0;
}
ul.green-right li {
    position: relative;
    padding-left: 30px;
    font-size: 16px;
    margin: 0 0 18px;
    font-family: 'Jost', sans-serif;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between;
}
ul.green-correct li:last-child {
    margin: 0;
}
ul.green-right li .rupee {
    line-height: 22px;
}
ul.green-right li::before {
    content: '';
    width: 18px;
    height: 16px;
    display: inline-block;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/15-checked.svg);
    position: absolute;
    left: 0px;
    top: 3px;
}
.btn-play {
    bottom: 20px;
    left: 20px;
    background: #fff;
    border-radius: 25px;
    width: 120px;
    height: 40px;
    padding: 8px;
    display: flex;
    align-items: center;
    z-index: 2;
    transition: all 0.3s ease-in-out;
    font-size: 16px;
    text-transform: uppercase;
    color: #000;
}
.btn-play .play-icon {
    width: 24px;
    height: 24px;
    position: relative;
    left: auto;
    right: auto;
    bottom: auto;
    top: auto;
    margin: auto;
    background: #000;
    display: inline-block;
    margin: 0 8px 0 0;
}
.btn-play .play-icon:after {
    border-left: 6px solid #fff;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    left: 10px;
    right: auto;
}
.video-btn:hover .btn-play {
    background: #5cae48;
    color: #fff;
}
.video-btn:hover .btn-play .play-icon {
    background: #fff;
}
.video-btn:hover .btn-play .play-icon:after {
    border-left: 6px solid #5cae48;
}
.play-icon {
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    width: 40px;
    height: 40px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    z-index: 2;
}
.play-icon::after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 10px solid #fff;
    margin: auto;
}
.video-btn:hover img {
    transform: scale(1.1);
    transition: all 0.3s ease-in-out;
}
.video-btn:hover img {
    transform: scale(1.1);
    transition: all 0.3s ease-in-out;
}
.imgplace .video-btn:hover img {
    transform: scale(1.1);
    transition: all 0.3s ease-in-out;
}
.btn-submit {
    max-width: 200px;
    height: 45px;
    width: 100%;
    border: 0;
    border-radius: 25px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    background: #5cae48;
    color: #fff;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    text-decoration: none;
}
.btn-submit:hover {
    background: #88bb1b;
    color: #fff;
    border-color: #88bb1b;
}
.info {
    background: #edf6fb;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    font-family: sans;
    font-size: 30px;
    font-weight: 600;
    color: rgba(11, 52, 84, 0.8);
}
.rightcustomborder {
    padding: 0 30px 0 0;
    border-right: 1px solid #e3e3e3;
}
.s_no label {
    width: 32px;
    height: 32px;
    background: #0b3454;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}
.s_no label {
    width: 32px;
    height: 32px;
    background: #0b3454;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}
.skillBlock .form-check-input[type='radio'] + label {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #0b345440;
    border-radius: 6px;
    opacity: 1;
    padding: 10px;
    height: 100%;
    width: 100%;
    cursor: pointer;
    text-align: center;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.skillBlock .form-check-input[type='radio']:checked + label {
    background: #0b3454;
    border: 1px solid #0b3454 !important;
    color: #fff;
}
/* alumni_statistics starts */
.alumni_statistics .filters {
    position: static;
    padding-top: 0;
    padding-bottom: 0;
}
.alumni_statistics .filters .additionalfilters {
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: underline;
    color: #26a7df;
    font-weight: 500;
}
.alumni_statistics .filters .apply_filters {
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    background-color: #5bae47;
    border: 0;
    border-radius: 100px;
    padding: 10px 40px;
    width: 170px;
}
.alumni_statistics .filters .apply_filters:hover {
    background-color: #198754;
}
.alumni_statistics .filters .additionalfilters:hover {
    color: #5bae47;
}
.alumni_statistics .filters .form-search {
    background: url('https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/search.svg') no-repeat 15px;
    padding-left: 40px !important;
    background-size: 16px;
    max-width: 100%;
}
.alumni_statistics .filters .accordion-button {
    background: none;
    box-shadow: none;
    font-size: 24px;
    font-weight: 500;
    color: #000;
    padding: 25px;
    border-bottom: 1px solid #e6eaed;
}
.alumni_statistics .filters .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom: 0;
}
.alumni_statistics .filters .text-bg-primary {
    width: 40px;
    height: 40px;
    font-size: 16px;
    display: flex;
    align-items: center;
    background-color: #0b3454 !important;
    position: absolute;
    padding: 0;
    margin: 0;
    justify-content: center;
    font-weight: 400;
    right: 70px;
    top: 20px;
}
.alumni_statistics .filters .accordion-button img {
    margin-right: 10px;
}
.alumni_statistics .filters .accordion-body {
    padding: 30px;
}
.alumni_statistics .selected_filters {
    margin-bottom: 20px;
}
.alumni_statistics .selected_filters p {
    background-color: #e9f6fc !important;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    margin-right: 10px;
    margin-bottom: 0;
    color: #000;
}
.alumni_statistics .selected_filters .clearfilters {
    color: #d42941;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    padding: 6px;
}
.alumni_statistics .selected_filters .clearfilters:hover {
    text-decoration: none;
}
.alumni_statistics .selected_filters p a {
    margin-left: 15px;
}
.alumni_statistics .selected_filters p a img {
    margin-top: -3px;
    width: 9px;
}
.alumni_statistics .filters .form-control,
.alumni_statistics .filters .form-select {
    min-height: 50px;
    padding: 0 20px;
    border-radius: 5px;
    line-height: 48px; /* display: flex; */ /* align-items: center; */
}
.accordion-body .form-control:focus {
    box-shadow: 0px 0px 20px #00000033;
}
.accordion-body .form-select:focus {
    box-shadow: 0px 0px 20px #00000033;
}
.alumni_statistics .filters .form-check {
    margin-bottom: 0;
    display: flex;
    align-items: center;
}
.alumni_statistics .filters .form-check-label {
    padding-top: 5px;
}
.alumni_statistics .filters .form-check-input {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}
.form-check-input:checked {
    background-color: #0b3454;
    border-color: #0b3454;
}
.alumni_statistics .card_custom .alumni_presence {
    padding: 0 30px 40px;
}
.alumni_statistics .card_custom .card-header {
    background: transparent;
    border-bottom: none;
    font-size: 22px;
    text-transform: uppercase;
    font-weight: 600;
    padding: 30px 30px;
}
.alumni_statistics .card_custom .card-header select {
    border-radius: 200px;
    height: 40px;
    border: 1px solid #8499a9;
    font-weight: 500;
    font-size: 16px;
    width: auto;
    max-width: 180px;
    padding-left: 15px;
    -webkit-border-radius: 200px;
    -moz-border-radius: 200px;
    -ms-border-radius: 200px;
    -o-border-radius: 200px;
}
.alumni_statistics .card_custom .top-countries,
.alumni_statistics .card_custom .top-careers {
    min-height: 415px;
}
.alumni_statistics .card_custom .card-header p span img {
    margin-top: -7px !important;
}
/* Top Countries */
.alumni_statistics .top-countries .progress {
    height: 40px;
    background-color: rgba(0, 0, 0, 0.06);
}
.alumni_statistics .top-countries .progress-bar.countries {
    background-color: #26a7df;
}
.alumni_statistics .top-countries .exclude_india {
    font-size: 18px;
    font-weight: 500;
}
.alumni_statistics .top-countries .caountryname {
    line-height: 15px;
}
.alumni_statistics .top-careers .progress-bar.countries {
    background-color: #51499c;
}
/* Top Countries */
.alumni_statistics .top-colleges {
}
.alumni_statistics .top-colleges .slider {
    padding: 0px 70px;
}
.alumni_statistics .filters .accordion-item {
    border: 1px solid #d2d2d2;
}
.alumni_statistics .filters .accordion-header {
    position: relative;
}
.alumni_statistics .top-colleges .carousel-control-prev,
.alumni_statistics .top-colleges .carousel-control-next {
    width: auto;
}
.alumni_statistics .top-colleges .slider .colleges {
    border: 1px solid #d2d2d2 !important;
    display: flex;
    align-items: center !important;
    border-radius: 6px;
    line-height: 18px;
    padding-right: 20px;
}
.alumni_statistics .top-colleges .slider .colleges:not(:last-child) {
    margin-bottom: 10px;
}
.alumni_statistics .top-colleges .slider .colleges img {
    width: 65px;
}
.alumni_statistics .top-colleges .nav-link {
    font-size: 18px;
    color: #0b3454;
    border: 1px solid #8499a9;
    display: flex;
    align-items: center !important;
    border-radius: 100px;
    padding: 15px 30px;
}
.alumni_statistics .top-colleges .nav-item {
    margin-right: 10px;
    white-space: nowrap;
}
.alumni_statistics .top-colleges .nav-item:last-child {
    margin-right: 0px;
}
.alumni_statistics .top-colleges .nav-link.active {
    color: #fff;
    background: #0b3454;
}
.alumni_statistics .top-colleges .nav-link.active {
    color: #fff;
    background: #0b3454;
}
.alumni_statistics .top-colleges .carousel-item {
    min-height: 380px;
}
.owl-theme.college-slider .owl-nav button {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto !important;
    width: 50px;
    height: 50px;
    background: transparent url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/arrow-left.svg) no-repeat
        0 0;
    border-radius: 50%;
    font-size: 0;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.owl-theme.college-slider .owl-nav button:hover {
    background: transparent url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/arrow-left.svg) no-repeat
        0 0;
}
.owl-theme.college-slider .owl-nav button.owl-prev {
    left: 0;
}
.owl-theme.college-slider .owl-nav button.owl-next {
    right: 0;
    transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    -o-transform: scaleX(-1);
}
.alumni_statistics .alumni_presence .progress-bar-vertical {
    width: 80px;
    min-height: 200px;
    display: flex;
    align-items: flex-end;
    margin-right: 20px;
    float: left;
    margin: 0 auto;
    border-radius: 6px 6px 0 0;
}
.alumni_statistics .alumni_presence .progress-bar-vertical .prestigiouscolor {
    background: #26a7df;
}
.alumni_statistics .alumni_presence .progress-bar-vertical .nonprestigiouscolor {
    background: #51499c;
}
.alumni_statistics .alumni_presence .progress-bar-vertical {
    position: relative;
}
.alumni_statistics .alumni_presence .progress-bar-vertical span {
    position: absolute;
    top: 0;
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}
.alumni_statistics .alumni_presence .progress-bar-vertical .progress-bar {
    width: 100%;
    height: 0;
    -webkit-transition: height 0.6s ease;
    -o-transition: height 0.6s ease;
    transition: height 0.6s ease;
}
.alumni_statistics .alumni_presence .prestigious {
    border-top: 1px solid #e2e2e2;
    margin-bottom: 0;
    padding: 5px;
    min-width: 140px;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
}
.alumni_statistics .alumni_presence p.prestigiousnumber {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
}
.alumni_statistics .alumni_presence .stats {
    position: relative;
}
.alumni_statistics .alumni_presence .stats:after {
    content: 'vs';
    position: absolute;
    top: 45%;
    font-size: 20px;
    left: 47%;
}
.alumni_statistics .card_profiles {
    min-height: max-content;
    max-height: max-content;
}
.guidance_videos {
}
.guidance_videos .videos_list {
    text-decoration: none;
}
.guidance_videos .videos_list figure {
    max-height: 180px;
    overflow: hidden;
    position: relative;
    border-radius: 4px;
    border: 2px solid #000;
}
.guidance_videos .videos_list figure:before {
    content: '';
    display: block;
    width: 100%;
    position: absolute;
    height: 100%;
    background: #00000050;
}
.guidance_videos .videos_list figure img {
    transition: all 0.3s ease;
}
.guidance_videos .videos_list:hover figure img {
    transform: scale(1.05);
}
.guidance_videos .videos_list figure:after {
    content: '';
    position: absolute;
    background: url('https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/play_video.svg') no-repeat;
    left: 50%;
    width: 56px;
    height: 56px;
    margin-left: -28px;
    top: 50%;
    margin-top: -25px;
}
.guidance_videos .videos_list figure img {
    width: 100%;
}
.guidance_videos .videos_list p {
    text-align: center;
    font-size: 20px;
    color: #000;
    text-decoration: none;
    font-weight: 500;
    line-height: 29px;
}
.guidance_video_popup {
    border-radius: 0;
    background: #000;
    position: relative;
}
.guidance_video_popup .btn-close {
    position: absolute;
    right: 0;
    top: -30px;
    color: #fff;
    filter: brightness(0) invert(1);
    opacity: 01;
}
.w-10 {
    width: 10%;
}
.bg-darker-blue {
    background-color: #0b3454;
}
.profileContainer {
    background: #ffffff;
    box-shadow: 0px 3px 6px #0000001a;
    border: 1px solid #d2d2d2;
    border-radius: 6px;
    overflow: hidden;
}
.profilenameclass .profilename {
    line-height: 24px;
}
.profilenameclass .univarsity-school {
    border-left: 4px solid #fff;
    padding-left: 7px;
}
.alumni_profile_college_name_other_detail_wrap .phone_number label {
    width: 140px;
    margin-right: 20px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/path-72031.svg) no-repeat 0 0;
    height: 40px;
    padding-left: 13px;
    display: flex;
    align-items: center;
    font-weight: 500;
}
.btn-white-border.verifynow {
    height: 30px;
    line-height: 25px;
}
.alumni_profile_college_name_other_detail_wrap .phone_number {
    align-items: center;
}
.profilenameclass .class {
    line-height: 18px;
}
.alumni_profile_brife_wrap.myProfile {
    border-top: 1px solid #264a66;
}
.myProfile .alumni_profile_college_name_other_detail_wrap .verifynow {
    background: #fff;
    border: 2px solid #224764;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    color: #224764;
}
.myProfile .alumni_profile_college_name_other_detail_wrap .verifynow:hover {
    background: #224764;
    color: #fff;
}
.days .white-block {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #dedede;
    border-radius: 18px;
    height: 35px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    margin: 0 10px 10px 0;
}
.customselect_parnt > .form-select {
    border-radius: 25px;
    margin-right: 10px;
    border: 2px solid #7d7d7d;
    height: 36px;
    display: flex;
    align-items: center;
    line-height: 18px;
}
.customselect_parnt button {
    height: 36px;
}
.icon-download {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/download.svg) no-repeat 0 0 / 100%;
    width: 17px;
    height: 16px;
    display: inline-block;
}
.mailSetting .icon-download {
    filter: invert(16%) sepia(71%) saturate(697%) hue-rotate(167deg) brightness(92%) contrast(99%);
    -webkit-filter: invert(16%) sepia(71%) saturate(697%) hue-rotate(167deg) brightness(92%) contrast(99%);
}
.mailSetting .icon-download:hover {
    filter: invert(53%) sepia(75%) saturate(353%) hue-rotate(62deg) brightness(97%) contrast(89%);
    -webkit-filter: invert(53%) sepia(75%) saturate(353%) hue-rotate(62deg) brightness(97%) contrast(89%);
}
/*.gray_badge .badge {background: #F1F1F1 0% 0% no-repeat padding-box;border-radius: 100px;height: 28px;padding: 0 15px;display: inline-flex;justify-content: center;align-items: center;font-size: 14px;color: #000;font-weight: 400;margin-bottom: 5px;}*/
.gray_badge .badge {
    background: #f1f1f1;
    border-radius: 100px;
    height: 28px;
    padding: 0 15px;
    display: inline-block;
    font-size: 14px;
    color: #000;
    font-weight: 400;
    margin-bottom: 5px;
    max-width: 108px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    text-align: center;
    line-height: 28px;
}
.tooltip-white .tooltip-inner {
    background: #fff !important;
    box-shadow: 0px 3px 6px #0000001a;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 230px;
    width: 100%;
    border: none;
    text-align: left;
}
.tooltip-white .tooltip-inner p, .tooltip-white .tooltip-inner{color: #000 !important;}
.tooltip-white .tooltip-inner .badge {
    background: #f1f1f1 0% 0% no-repeat padding-box;
    border-radius: 100px;
    height: 28px;
    padding: 0 15px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #000;
    font-weight: 400;
    text-align: left;
}
.tooltip-white .tooltip-inner .badge:not(:last-child) {
    margin-bottom: 5px;
}
.tooltip-white .tooltip-arrow::before {
    border-top-color: #fff;
}
.attendee_dtl .table > thead th {
    background: #f1f1f1;
    font-size: 14px;
    font-weight: 500;
    height: 40px;
    border: 0;
}
.attendee_dtl .table > thead th:first-child {
    border-radius: 6px 0 0 6px;
}
.attendee_dtl .table > thead th:last-child {
    border-radius: 0 6px 6px 0;
}
.table-responsive.attendee_dtl > table td:first-child,
.table-responsive.attendee_dtl > table th:first-child {
    padding-left: 20px;
}
.table-responsive.attendee_dtl > table td {
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 14px;
    vertical-align: middle;
    background-color: #fff;
}
.table-responsive.attendee_dtl > table .btn {
    line-height: 15px;
    display: flex;
    align-items: center;
    padding: 0 20px !important;
    margin: 0 5px 0 0;
}
.table-responsive.attendee_dtl > table td.active {
    width: 315px;
}
.table-responsive.attendee_dtl > table td.engagement {
    width: 245px;
}
.table-responsive.attendee_dtl > table .university-name {
    width: 175px;
}
.table-responsive.attendee_dtl > table td.sentDeliveredOpened {
    width: 365px;
}
.table-responsive.attendee_dtl a.tablink {
    color: #0b3454;
    display: flex;
    align-items: center;
    text-decoration: none;
    line-height: 18px;
}
.table-responsive.attendee_dtl a.tablink:hover {
    color: #5cae48;
}
.table-responsive.attendee_dtl a.btn-gray-border:hover {
    color: #fff;
}
.table-responsive.attendee_dtl > table td.deliveryStatus {
    width: 205px;
}
.input-group-select select.form-select {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.empty-notification .profile-selection-notification {
    z-index: 9;
    left: 0;
    max-width: 1012px;
    margin: auto;
    width: 1012px;
    right: 0;
    position: absolute;
    top: 50px;
}
.table-responsive .icon-like.dislike {
    width: 15px;
    height: 15px;
    -moz-transform: scale(-1);
    -webkit-transform: scale(-1);
    -o-transform: scale(-1);
    -ms-transform: scale(-1);
    transform: scale(-1) translateY(-3px);
    display: inline-block;
}
.table_cus_badge {
    background: #fef7e8;
    border-radius: 20px;
    height: 36px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #000;
    font-weight: 500;
    padding: 0 15px;
    margin: 3px;
    min-width: 65px;
}
.tab-ul {
    padding: 0;
    line-height: 22px;
    list-style: none;
    margin: 0 13px 0 0;
}
.tab-ul a {
    color: #000;
}
.tab-ul a:hover {
    color: #414141;
}
.custom-btn a {
    margin: 0 2px;
    text-decoration: none;
    color: #000;
    font-size: 12px;
}
.active-status {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #d2d2d2;
    border-radius: 20px;
    width: 120px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.attendee_dtl a.icon-cross {
    text-decoration: none;
}
.attendee_dtl a.icon-cross:hover {
    color: #666 !important;
}
.attendee_dtl .form-check-input,
.ActiveListings_present .form-check-input {
    width: 20px;
    height: 20px;
    border-width: 2px;
}
.attendee_dtl .form-check-input:checked,
.ActiveListings_present .form-check-input:checked {
    background-color: #0b3454;
    border-color: #0b3454;
}
.icon-sort-arrow {
    font-size: 6px;
    color: #7f7f7f;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.icon-sort-arrow .icon-invert-down-arrow {
    transform: scaleY(-1);
}
.labelItem {
    color: #000;
    text-decoration: none;
    display: flex;
    align-items: center;
}
.text-center .labelItem {
    justify-content: center;
}
.labelItem:hover {
    color: #153d5b;
}
.labelItem .icon-sort {
    margin-left: 5px;
    width: 17px;
    height: 17px;
    display: inline-block;
    position: relative;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='%23343a40' stroke='none' d='M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z'/%3e%3c/svg%3e");
}
.labelItem .icon-sort::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    background: #f1f1f1;
    width: 12px;
    height: 2px;
    margin: auto;
    transform: rotate(-45deg);
    left: -2.5px;
    display: none;
}
.fitergroup .show .accordion-body {
    border-radius: 0 6px 6px 6px;
}
.fitergroup .accordion-body {
    background: #f1f1f1;
    border-radius: 6px;
}
.fitergroup .input-group .css-b62m3t-container {
    height: 38px !important;
}
.fitergroup .input-group .css-b62m3t-container div[class*='-control'] {
    height: 38px !important;
}
.fitergroup .input-group-text {
    border-color: #848484;
}
.btn-grpfiter {
    border: 0px solid #e1e1e1;
    border-radius: 20px;
    height: 40px;
    padding: 0 25px;
    font-size: 14px;
    font-weight: 500;
    background: #f1f1f1;
    position: relative;
}
.btn-grpfiter[aria-expanded='true'] {
    border-radius: 20px 20px 0 0;
    transition: all 0.3s ease-in-out;
}
.btn-grpfiter::before {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 0;
    right: 0;
    background: #f1f1f1;
    height: 10px;
    transition: all 0.3s ease-in-out;
    opacity: 0;
}
.btn-grpfiter[aria-expanded='true']::before {
    bottom: -10px;
    opacity: 1;
}
.mailSetting {
    position: relative;
}
.mailSetting .setting-dropdown {
    position: absolute;
    top: 30px;
    right: 10px;
    background: white;
    padding: 15px 20px;
    box-shadow: 0 0 3px 0px #ccc;
    border-radius: 6px;
    z-index: 1;
    border: 1px solid #ccc;
    text-align: right;
    display: none;
}
.mailSetting .setting-dropdown.open {
    display: block;
}
.mailSetting .setting-dropdown ul {
    padding: 0;
    margin: 0;
    list-style: none;
}
.mailSetting .setting-dropdown ul a {
    text-decoration: none;
    color: #000;
}
.mailSetting .setting-dropdown ul li:not(:last-child) {
    margin-bottom: 5px;
}
td a {
    color: #0b3454;
    opacity: 1;
    text-decoration: none;
}
td a:hover {
    color: #5cae48;
    opacity: 1;
}
.btn-conview,
.link {
    color: #0d6efd;
    text-decoration: underline;
}
.btn-conview:hover,
.link:hover {
    color: #5cae48;
    opacity: 1;
}
.tooltip-setting .tooltip-inner {
    background: #ffffff;
    color: #515151;
    border: 1px solid #cecfcf;
    box-shadow: 0px 5px 10px #00000033;
    padding: 15px 12px;
}
.tooltip.tooltip-setting .show {
    opacity: 2;
}
.tooltip-setting .bs-tooltip-top .tooltip-arrow::before {
    top: -1px;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #fff;
}
.tooltip-setting .bs-tooltip-bottom .tooltip-arrow::before {
    bottom: -1px;
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: #fff;
}
.tooltip-setting .tooltip-inner ul {
    padding: 0;
    margin: 0;
    text-align: left;
    list-style: none;
    line-height: 24px;
}
.tooltip-setting .tooltip-inner ul a {
    font-size: 14px;
    color: #000;
    text-decoration: none;
    padding: 0;
    border: none;
    font-weight: 500;
}
.bs-tooltip-auto.tooltip-setting[data-popper-placement^='top'] .tooltip-arrow::before,
.bs-tooltip-top.tooltip-setting .tooltip-arrow::before {
    border-top-color: #fff;
}
.tooltip-html {
}
.tooltip-html .tooltip-inner {
    background: #ffffff !important;
    color: #515151;
    border: 1px solid #cecfcf;
    box-shadow: 0px 5px 10px #00000033;
    padding: 15px 15px;
    max-width: 300px;
    line-height: 20px;
}
.tooltip.tooltip-html .show {
    opacity: 2;
}
.tooltip-html .bs-tooltip-top .tooltip-arrow::before {
    top: -1px;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #fff;
}
.tooltip-html .bs-tooltip-bottom .tooltip-arrow::before {
    bottom: -1px;
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: #fff;
}
.tooltip-html .tooltip-inner ul {
    padding: 0;
    margin: 0;
    text-align: left;
    list-style: none;
    line-height: 24px;
}
.tooltip-html .tooltip-inner ul a {
    font-size: 14px;
    color: #000;
    text-decoration: none;
}
.bs-tooltip-auto.tooltip-html[data-popper-placement^='top'] .tooltip-arrow::before,
.bs-tooltip-top.tooltip-html .tooltip-arrow::before {
    border-top-color: #fff;
}
.tooltip-html .tooltip-inner ul li {
    white-space: wrap;
}
.tooltip-html .tooltip-inner ul li:not(:last-child) {
    margin: 0 0 8px;
}
.tooltip-html .tooltip-inner ul li b {
    margin-right: 5px;
    font-weight: 500;
    color: #000;
}
.tooltip-html .tooltip-inner ol,
.tooltip-html .tooltip-inner p {
    padding: 0 0 0 20px;
    margin: 0;
    text-align: left;
    font-weight: 500;
    color: #000;
}
.datewrap {
    display: flex;
    flex-wrap: wrap;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #848484;
    border-radius: 6px;
    height: 40px;
    position: relative;
    overflow: hidden;
}
#config-demo,
.singledaterangePickerList,
.daterangePickerList {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    margin: auto;
    cursor: pointer;
    opacity: 0;
}
.datewrap .arrow {
    background: #f1f1f1 0% 0% no-repeat padding-box;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
}
/*.datewrap .arrow span {background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='1' d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z'/%3e%3c/svg%3e");font-size: 0;width: 25px;height: 25px;}*/
.datewrap .arrow span {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/arrow.svg) no-repeat center;
    font-size: 0;
    width: 25px;
    height: 25px;
}
.datewrap .from,
.datewrap .to {
    flex: 1;
}
.datewrap .form-control {
    height: 100% !important;
    border: 0 !important;
    background: #fff url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/calendar.svg) no-repeat 10px
        center;
    padding-left: 40px;
}
.fitergroup .accordion-body .form-select,
.fitergroup .accordion-body .form-control,
.tab-pane .fullViewMobile .form-select,
.tab-pane .fullViewMobile .form-control {
    height: 40px;
    border: 1px solid #848484;
}
.fitergroup .accordion-body .form-select.selected,
.fitergroup .accordion-body .form-control.selected {
    background-color: #b6dbac;
    border-color: #8baa83;
}
.form-control.form-search {
    background-image: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/search.svg);
    background-size: 14px;
    background-repeat: no-repeat;
    background-position: 13px center;
    padding-left: 34px;
}
.search.alumniSearch {
    max-width: 350px;
    width: 100%;
    background: #fff;
    border: 1px solid #7d7d7d;
    border-radius: 20px;
}
.search.alumniSearch .btn-search {
    right: auto;
    left: 7px;
    top: 6px;
}
.search.alumniSearch .form-control {
    padding-left: 40px;
}
.search.alumniSearch > div input:focus {
    box-shadow: none;
    background: transparent;
}
.search-100 .search.alumniSearch {
    max-width: 280px;
}
.singlelinename {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 125px;
}
.tablink img[width='30'] {
    width: 30px;
    min-width: 30px;
}
.icon-star.circle-img {
    position: absolute;
    bottom: -3px;
    right: -3px;
    background: #5cae48;
    border-radius: 50%;
    color: #fff;
    font-size: 7px;
    padding: 4px;
}
.table-responsive.attendee_dtl td a > i {
    width: 30px;
    height: 30px;
    font-size: 30px;
    background-position: center;
}
.table-responsive.attendee_dtl td a > i img {
    vertical-align: top;
}
.course-list-row span {
    vertical-align: top;
    margin-right: 4px;
}
.course-list-row i {
    margin-top: 0px;
}
.filterresult ul {
    margin: 0;
    padding: 10px 0 15px;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
}
.filterresult ul:empty {
    display: none;
}
.filterresult ul li:not(:last-child) {
    margin-right: 10px;
}
.filterresult ul li {
    margin-bottom: 5px;
}
.filterresult ul li a {
    background: #c8edff;
    border-radius: 20px;
    min-height: 32px;
    padding: 5px 20px;
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #000;
    text-decoration: none;
}
.filterresult ul li a i {
    margin-left: 8px;
    font-size: 9px;
}
.filterresult ul li.clear a {
    background: none;
    color: #d42941;
    text-decoration: underline;
}
.filterresult ul li.clear a:hover {
    color: #0b3454;
    text-decoration: none;
}
.filterresult {
    padding: 0;
    border-bottom: 1px solid #e5eaed;
    margin-bottom: 20px;
}
.pagination_custom {
    max-width: 751px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
}
.pagination_custom .pagination_custom_inner {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 20px;
}
.pagination_custom .pagination_custom_inner ul {
    padding: 0;
    margin: 0 20px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    justify-content: center;
    align-items: center;
}
.pagination_custom .pagination_custom_inner ul li:not(:last-child) {
    margin-right: 0;
}
.pagination_custom .pagination_custom_inner ul li {
    width: 30px;
    text-align: center;
    margin: 0 10px;
}
.pagination_custom a,
.pagination_custom .pagination_custom_inner ul li a {
    text-decoration: none;
    font-size: 16px;
    color: #0b3454;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}
.pagination_custom .pagination_custom_inner ul li.current a {
    background: #0b3454;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}
.summary-blocks {
    width: 1140px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 15px;
}
.summary-blocks .block {
    width: calc(20.19% - 1%);
    border-radius: 4px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    padding: 32px 20px;
}
.summary-blocks .block {
    background: #fff;
}
.summary-blocks .block:not(:last-child) {
    margin-right: 1%;
}
.summary-blocks .block .icon {
    margin-right: 15px;
}
.summary-blocks .block .block-detail .number {
    font-size: 26px;
    line-height: 30px;
    font-weight: 600;
}
.summary-blocks .block .block-detail .block-detail-title {
    font-size: 15px;
    line-height: 18px;
    font-weight: 500;
}
.summary-blocks .block.totalAlumni .block-detail {
    color: #d42941;
}
.summary-blocks .block.uptoDate .block-detail {
    color: #0b3454;
}
.summary-blocks .block.due .block-detail {
    color: #26a7df;
}
.summary-blocks .block.smartApplyLeads .block-detail {
    color: #403a81;
}
.summary-blocks .block.smartApplyRegd .block-detail {
    color: #6962a7;
}
.x-scroll {
    overflow-x: auto;
    scrollbar-width: thin;
}
.x-scroll::-webkit-scrollbar {
    height: 4px;
}
.x-scroll::-webkit-scrollbar-track {
    background: #f1f1f1;
}
.x-scroll::-webkit-scrollbar-thumb {
    background: #888;
}
.x-scroll::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.blue-badge {
    background: #68c2e9;
    font-weight: 500;
    border: 1px solid #68c2e9;
    border-radius: 6px;
    padding: 0 15px;
    height: 26px;
    display: inline-block;
    line-height: 26px;
    color: #fff !important;
    margin-bottom: 5px;
    font-size: 12px;
    text-transform: uppercase;
}
.sticky_table_3_columns th:first-child {
    position: sticky;
    left: 0;
    z-index: 3;
}
.sticky_table_3_columns th:nth-child(2) {
    position: sticky;
    left: 140px;
    z-index: 3;
}
.sticky_table_3_columns th:nth-child(3) {
    position: sticky;
    left: calc(140px + 250px);
    z-index: 3;
}
.sticky_table_3_columns td:first-child {
    position: sticky;
    left: 0;
    z-index: 3;
}
.sticky_table_3_columns td:nth-child(2) {
    position: sticky;
    left: 140px;
    z-index: 3;
}
.sticky_table_3_columns td:nth-child(3) {
    position: sticky;
    left: calc(140px + 250px);
    z-index: 3;
}
.sticky_table_3_columns th:nth-child(3)::before,
.sticky_table_3_columns td:nth-child(3)::before {
    content: '';
    width: 30px;
    position: absolute;
    right: 0px;
    bottom: 0;
    top: 0;
    background: transparent linear-gradient(270deg, #000000 0%, #00000000 100%);
    opacity: 0.05;
}
.sticky_table_3_columns td::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1px;
    height: 0;
    border-bottom: 1px solid #dee2e6;
}
.sticky_table_3_columns_noaction th:first-child {
    position: sticky;
    left: 0;
    z-index: 3;
}
.sticky_table_3_columns_noaction th:nth-child(2) {
    position: sticky;
    left: 250px;
    z-index: 3;
}
.sticky_table_3_columns_noaction th:nth-child(3) {
    position: sticky;
    left: calc(250px + 180px);
    z-index: 3;
}
.sticky_table_3_columns_noaction td:first-child {
    position: sticky;
    left: 0;
    z-index: 3;
}
.sticky_table_3_columns_noaction td:nth-child(2) {
    position: sticky;
    left: 250px;
    z-index: 3;
}
.sticky_table_3_columns_noaction td:nth-child(3) {
    position: sticky;
    left: calc(250px + 180px);
    z-index: 3;
}
.sticky_table_3_columns_noaction th:nth-child(3)::before,
.sticky_table_3_columns_noaction td:nth-child(3)::before {
    content: '';
    width: 30px;
    position: absolute;
    right: 0px;
    bottom: 0;
    top: 0;
    background: transparent linear-gradient(270deg, #000000 0%, #00000000 100%);
    opacity: 0.05;
}
.sticky_table_3_columns_noaction td::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1px;
    height: 0;
    border-bottom: 1px solid #dee2e6;
}
.sticky_table_4_columns th:first-child {
    position: sticky;
    left: 0;
    z-index: 3;
}
.sticky_table_4_columns th:nth-child(2) {
    position: sticky;
    left: 150px;
    z-index: 3;
}
.sticky_table_4_columns th:nth-child(3) {
    position: sticky;
    left: calc(150px + 225px);
    z-index: 3;
}
.sticky_table_4_columns th:nth-child(4) {
    position: sticky;
    left: calc(150px + 225px + 180px);
    z-index: 3;
}
.sticky_table_4_columns td:first-child {
    position: sticky;
    left: 0;
    z-index: 3;
}
.sticky_table_4_columns td:nth-child(2) {
    position: sticky;
    left: 150px;
    z-index: 3;
}
.sticky_table_4_columns td:nth-child(3) {
    position: sticky;
    left: calc(150px + 225px);
    z-index: 3;
}
.sticky_table_4_columns td:nth-child(4) {
    position: sticky;
    left: calc(150px + 225px + 180px);
    z-index: 3;
}
.sticky_table_4_columns th:nth-child(4)::before,
.sticky_table_4_columns td:nth-child(4)::before {
    content: '';
    width: 30px;
    position: absolute;
    right: 0px;
    bottom: 0;
    top: 0;
    background: transparent linear-gradient(270deg, #000000 0%, #00000000 100%);
    opacity: 0.05;
}
.sticky_table_4_columns td::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1px;
    height: 0;
    border-bottom: 1px solid #dee2e6;
}
.w-2300 {
    width: 2300px;
}
.w-2000 {
    width: 2000px;
}
.w-1780 {
    width: 1780px;
}
.w-90 {
    width: 90px;
}
.max-1140 {
    max-width: 1140px;
    width: 94%;
}
.max-1040 {
    max-width: 1040px;
    width: 94%;
}
.max-1010 {
    max-width: 1010px;
    width: 94%;
}
.max-950 {
    max-width: 950px;
}
.max-940 {
    max-width: 940px;
    width: 94%;
}
.max-900 {
    max-width: 900px;
    width: 94%;
}
.max-850 {
    max-width: 850px;
    width: 94%;
}
.max-800 {
    max-width: 800px;
    width: 94%;
}
.max-740 {
    max-width: 740px;
    width: 90%;
}
.max-750 {
    max-width: 750px;
    width: 90%;
}
.max-780 {
    max-width: 780px;
    width: 90%;
}
.max-700 {
    max-width: 700px !important;
    width: 90%;
}
.max-600 {
    max-width: 600px;
    width: 90%;
}
.max-620 {
    max-width: 620px;
    width: 90%;
}
.max-630 {
    max-width: 630px;
    width: 90%;
}
.max-540 {
    max-width: 540px;
    width: 90%;
}
.bg-gray-f2 {
    background: #f2f2f2;
}
.customselect_parnt > .form-select {
    border-radius: 25px;
    margin-right: 10px;
    border: 2px solid #7d7d7d;
    height: 36px;
    display: flex;
    align-items: center;
    line-height: 18px;
}
.customselect_parnt button {
    height: 36px;
}
.icon-download {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/download.svg) no-repeat 0 0 / 100%;
    width: 17px;
    height: 16px;
    display: inline-block;
}
.mailSetting .icon-download {
    filter: invert(16%) sepia(71%) saturate(697%) hue-rotate(167deg) brightness(92%) contrast(99%);
    -webkit-filter: invert(16%) sepia(71%) saturate(697%) hue-rotate(167deg) brightness(92%) contrast(99%);
}
.mailSetting .icon-download:hover {
    filter: invert(53%) sepia(75%) saturate(353%) hue-rotate(62deg) brightness(97%) contrast(89%);
    -webkit-filter: invert(53%) sepia(75%) saturate(353%) hue-rotate(62deg) brightness(97%) contrast(89%);
}
.gray_badge .badge {
    background: #f1f1f1;
    border-radius: 100px;
    height: 28px;
    padding: 0 15px;
    display: inline-block;
    font-size: 14px;
    color: #000;
    font-weight: 400;
    margin-bottom: 0px;
    max-width: 108px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    text-align: center;
    line-height: 28px;
}
.custom-btn a {
    margin: 0 2px;
    text-decoration: none;
    color: #212529;
}
.custom-btn a:hover {
    color: #868686;
}
.modal .form-control {
    min-height: 40px;
    font-weight: 500;
    color: #000;
    font-size: 16px;
}
.modal .form-label {
    font-weight: 500;
    font-size: 16px;
}
::-webkit-input-placeholder {
    color: #939ba2;
}
:-moz-placeholder {
    color: #939ba2;
    opacity: 1;
}
::-moz-placeholder {
    color: #939ba2;
    opacity: 1;
}
:-ms-input-placeholder {
    color: #939ba2;
}
::-ms-input-placeholder {
    color: #939ba2;
}
::placeholder {
    color: #939ba2;
}
.btn-log {
    background: #0b3454;
    padding: 0 16px;
    height: 40px;
    display: inline-flex;
    border-radius: 4px;
    color: #fff;
    text-decoration: none;
    line-height: 35px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    align-items: center;
    font-size: 14px;
}
.btn-log:hover {
    color: #fff;
    background: #155282;
}
.btn-green {
    background: #5cae48;
    color: #fff;
    border-color: #5cae48;
    padding: 0 25px;
    height: 40px;
}
.btn-green:hover {
    color: #fff;
    background: #367127;
    border-color: #367127;
}
.btn-green {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    position: relative;
    overflow: hidden;
    border: 0;
}
.btn-green span {
    position: relative;
}
.btn-completed::before,
.btn-green::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    bottom: 0;
    width: 0;
    background: #398227;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.btn-completed:hover::before,
.btn-green:hover::before,
.btn-liteblue:hover::before,
.btn-invite:hover::before {
    width: 100%;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.form-last-sent {
    height: 40px;
    display: flex;
    align-items: center;
    background: #eef6ff;
    border-radius: 5px;
    padding: 5px 15px;
}
.form-last-sent a {
    margin-right: 5px;
    color: #007bff;
}
.filterSection .form-label {
    font-weight: 500 !important;
    margin: 0 0 3px;
}
.form-check .form-check-input {
    width: 20px;
    height: 20px;
    vertical-align: top;
    display: inline-block;
    position: static;
    margin-right: 8px;
    margin-left: 0;
    margin-top: 0;
}
.form-check {
    display: flex;
    align-items: center;
    padding: 0;
}
.form-check .form-check-label {
    display: flex;
    align-items: center;
    line-height: 16px;
    margin: 0;
}
.form-check-input:checked {
    background-color: #29a745;
    border-color: #29a745;
}
.datepicker .datepicker-switch:hover,
.datepicker .prev:hover,
.datepicker .next:hover,
.datepicker tfoot tr th:hover {
    background: #eef5ff;
}
.datepicker {
    padding: 0;
    z-index: 9999;
}
.datepicker td,
.datepicker th {
    padding: 7px;
}
.datepicker-days thead {
    background: #eef5ff;
    font-weight: 500;
}
.datepicker-days thead th {
    font-weight: 500;
}
.datepicker-days thead th:hover {
    background: #007bff;
    color: #fff;
}
.datepicker-months,
.datepicker-days {
    overflow: hidden;
    background: #eef5ff;
    border-radius: 3px;
}
.datepicker-months tbody,
.datepicker-days tbody {
    background: #fff;
}
.form-date {
    background: #fff url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/calender.svg) no-repeat right
        10px center;
    padding-right: 40px;
}
.modal-backdrop {
    z-index: 1090;
}
.modal {
    z-index: 1091;
}
.modal-backdrop ~ .tooltip {
    z-index: 1099;
}
.half-gray {
    position: relative;
}
.half-gray:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    width: 50.5%;
    background: #f3f3f3;
    border-radius: 6px 0px 0px 6px;
}
.half-gray > div {
    position: relative;
}
.messages {
    flex: 1;
    display: flex;
    flex-direction: column;
}
.leftUser {
    align-items: end;
}
.leftUser p {
    background: #2296c9;
    border-radius: 10px 10px 0px 10px;
    padding: 7px 20px;
    color: #fff;
    margin-bottom: 10px;
}
.rightUser {
    align-items: start;
}
.rightUser p {
    background: #413a7d;
    border-radius: 10px 10px 10px 0px;
    opacity: 1;
    padding: 7px 20px;
    color: #fff;
    margin-bottom: 10px;
}
.conversation .userimg {
    display: flex;
    align-items: start;
    vertical-align: top;
}
.conversation {
    height: 310px;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-right: -8px;
}
.conversation {
    overflow-y: scroll;
    scrollbar-width: thin;
}
.conversation::-webkit-scrollbar {
    width: 1px;
}
.conversation::-webkit-scrollbar-track {
    background: #f1f1f1;
}
.conversation::-webkit-scrollbar-thumb {
    background: #888;
}
.conversation::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.conversation .row {
    position: relative;
}
.messages.rightUser .form-check-input {
    position: absolute;
    left: 12px;
    top: 9px;
}
.messages.leftUser .form-check-input {
    position: absolute;
    left: 12px;
    top: 9px;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.messages .form-check-input:focus {
    box-shadow: none;
}
.conversation div[class*='col'] {
    position: static;
}
.conversation.gap div[class*='col'] > div {
    padding: 0 20px;
}
.half-gray .modal-footer {
    background: #fff;
    padding: 20px;
}
.modal-footer .btn-good-qa {
    margin: auto;
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
    box-shadow: none;
    padding: 10px 25px;
    border-radius: 3px;
    text-transform: uppercase;
    font-weight: 500;
}
.modal-footer .btn-good-qa:hover {
    background-color: #218838;
    border-color: #1e7e34;
}
.fitergroup .form-label {
    font-weight: 500;
    margin: 0 0 3px;
}
.fitergroup .form-last-sent {
    border: 1px solid #bfcfe1;
}
.custom_litezoom:before {
    content: '';
    position: absolute;
    top: 100%;
    left: 10px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #fff;
}
.album_imgvdo {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
}
.image_tn:first-child {
    margin-right: 5px;
}
.image_tn {
    margin-bottom: 3px;
}
.table-responsive.attendee_dtl td .custom_litezoom a {
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
    text-decoration: none !important;
    color: #000;
    font-weight: 500;
    display: flex;
    align-items: center;
    border: 1px solid #cecece;
    border-radius: 26px;
    -webkit-border-radius: 26px;
    -moz-border-radius: 26px;
    -ms-border-radius: 26px;
    -o-border-radius: 26px;
    padding: 0 10px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    width: auto;
    background: none;
}
.custom_litezoom a:hover {
    color: #0b3454;
}
.table-responsive.attendee_dtl td .custom_litezoom a .icon-phone {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/image.svg) no-repeat 0 0;
    width: 16px;
    height: 15px;
    margin-right: 7px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.table-responsive.attendee_dtl td .custom_litezoom a .icon-video {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/video-camera.svg) no-repeat 0 0;
    width: 16px;
    height: 9px;
    margin-right: 7px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.custom_litezoom a:hover {
    background: #0c9ccc;
    border-color: #0c9ccc;
    color: #fff;
}
.custom_litezoom a:hover i {
    filter: brightness(0) invert(1);
    -webkit-filter: brightness(0) invert(1);
}
.table .view-list {
    text-align: center;
    font-size: 14px;
    color: #0c9ccc;
    font-weight: 500;
}
.table .view-list:hover {
    color: #007bff;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.table td b {
    font-weight: 500;
}

/* multi select start */
.select2-container {
    display: block;
}
.select2-container *:focus {
    outline: 0;
}
.input-group .select2-container--bootstrap4 {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}
.input-group-prepend ~ .select2-container--bootstrap4 .select2-selection {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.input-group > .select2-container--bootstrap4:not(:last-child) .select2-selection {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.select2-container--bootstrap4 .select2-selection {
    width: 100%;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -moz-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -ms-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
.btn-update {
    background: #0b3454;
    padding: 3px 20px;
    border-radius: 25px;
    color: #fff;
    text-decoration: none !important;
    font-weight: 500;
    height: 32px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn-update:hover {
    color: #fff;
    background: #0060aa;
}
.btn-create {
    background: #28a745;
    padding: 3px 20px;
    border-radius: 25px;
    color: #fff;
    text-decoration: none !important;
    font-weight: 500;
    height: 32px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn-create:hover {
    color: #fff;
    background: #10842a;
}
.alumni_profile_brife .btn-back .icon-left-arrow {
    transform: scaleX(-1);
    margin-left: 7px;
    font-size: 12px;
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    -o-transform: scaleX(-1);
}
.copylink > a {
    width: 100px;
    height: 40px;
    display: inline-flex;
    background: #5cae48;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 3px;
    margin-right: 5px;
    text-decoration: none;
}
.copylink > a.invite:hover {
    background: #408e2d;
    color: #fff;
}
.icon.copy {
    position: relative;
    cursor: pointer;
    width: 18px;
    display: inline-block;
    height: 18px;
}
.icon.copy:before {
    content: '';
    width: 12px;
    height: 17px;
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid #a6a6a6;
    border-radius: 2px;
    background: #fff;
}
.icon.copy:after {
    content: '';
    width: 12px;
    height: 17px;
    position: absolute;
    top: 3px;
    left: 3px;
    border: 1px solid #a6a6a6;
    border-radius: 2px;
    background: #fff;
}
.alumni_profile_image_wrap {
    width: 110px;
    height: 110px;
    background: #f1f1f1;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    position: relative;
}
.alumni_profile_image_wrap .alumni_profile_image {
    margin: 0;
}
.alumni_profile_image_wrap .pencil-edit {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #5cae48;
    padding: 2px;
    border-radius: 50%;
    background-size: 12px auto;
    width: 30px;
    height: 30px;
    transition: all 0.3s ease-in-out;
}
.alumni_profile_image_wrap .pencil-edit:hover {
    background-color: #72bb60;
}
.modal .form-control[name*='countryCode'] {
    width: 65px !important;
}
.modal .btn-verify {
    display: block;
    height: 25px;
    line-height: 25px;
    padding: 0 15px !important;
    width: auto;
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 500;
}
.modal .form-label a.icon.icon-cross {
    background: red;
    color: #fff;
    font-size: 8px;
    padding: 4px;
    border-radius: 50%;
    margin: 0;
}

@media (prefers-reduced-motion: reduce) {
    .select2-container--bootstrap4 .select2-selection {
        -webkit-transition: none;
        transition: none;
    }
}

.select2-container {
    width: 100%;
}
.select2-container .select2-search--inline .select2-search__field {
    width: 100% !important;
}
.select2-container--bootstrap4.select2-container--focus .select2-selection {
    border-color: #80bdff;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.select2-container--bootstrap4.select2-container--focus.select2-container--open .select2-selection {
    border-bottom: none;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.select2-container--bootstrap4.select2-container--open.select2-container--above .select2-selection {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.select2-container--bootstrap4.select2-container--open.select2-container--below .select2-selection {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.select2-container--bootstrap4.select2-container--disabled .select2-selection,
.select2-container--bootstrap4.select2-container--disabled.select2-container--focus .select2-selection {
    cursor: not-allowed;
    background-color: #e9ecef;
    border-color: #ced4da;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.select2-container--bootstrap4.select2-container--disabled .select2-search__field,
.select2-container--bootstrap4.select2-container--disabled.select2-container--focus .select2-search__field {
    background-color: transparent;
}
select.is-invalid ~ .select2-container--bootstrap4 .select2-selection,
form.was-validated select:invalid ~ .select2-container--bootstrap4 .select2-selection {
    border-color: #dc3545;
}
select.is-valid ~ .select2-container--bootstrap4 .select2-selection,
form.was-validated select:valid ~ .select2-container--bootstrap4 .select2-selection {
    border-color: #28a745;
}
.select2-container--bootstrap4 .select2-search {
    width: 100%;
}
.select2-container--bootstrap4 .select2-dropdown {
    border-color: #ced4da;
    border-radius: 0;
}
.select2-container--bootstrap4 .select2-dropdown.select2-dropdown--below {
    border-top: none;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}
.select2-container--bootstrap4 .select2-dropdown.select2-dropdown--above {
    border-top: 1px solid #ced4da;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}
.select2-container--bootstrap4 .select2-dropdown .select2-results__option[aria-selected='true'] {
    color: #212529;
    background-color: #f2f2f2;
}
.select2-container--bootstrap4 .select2-results__option[role='group'] {
    padding: 0;
}
.select2-container--bootstrap4 .select2-results__option[role='group'] .select2-results__options--nested .select2-results__option {
    padding-left: 1em;
}
.select2-container--bootstrap4 .select2-results__option {
    padding: 8px 15px 8px 35px;
    position: relative;
}
.select2-container--bootstrap4 .select2-results__option:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 10px;
    width: 15px;
    height: 15px;
    border: 1px solid #868686;
    margin: auto;
    border-radius: 3px;
}
.select2-container--bootstrap4 .select2-results__option--highlighted,
.select2-container--bootstrap4 .select2-results__option--highlighted.select2-results__option[aria-selected='true'] {
    color: #fff;
    background-color: #007bff;
}
.select2-container--bootstrap4 .select2-results__option[aria-selected='true']::before {
    border: 1px solid #868686;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='%23343a40' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='1' d='M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 15px 15px;
}
.select2-container--bootstrap4 .select2-results__option--highlighted.select2-results__option[aria-selected='false']::before {
    border: 1px solid #fff;
}
.select2-container--bootstrap4 .select2-results__option--highlighted.select2-results__option[aria-selected='true']::before {
    border: 1px solid #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='white' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='1' d='M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 15px 15px;
}
.select2-container--bootstrap4 .select2-results > .select2-results__options {
    max-height: 15em;
    overflow-y: auto;
}
.select2-container--bootstrap4 .select2-results__group {
    display: list-item;
    padding: 6px;
    color: #6c757d;
}
.select2-container--bootstrap4 .select2-selection__clear {
    float: right;
    width: 0.9em;
    height: 0.9em;
    padding-left: 0.15em;
    margin-top: 0.7em;
    margin-right: 0.3em;
    line-height: 0.75em;
    color: #f8f9fa;
    background-color: #c8c8c8;
    border-radius: 100%;
}
.select2-container--bootstrap4 .select2-selection__clear:hover {
    background-color: #afafaf;
}
.select2-container--bootstrap4 .select2-selection--single {
    height: calc(1.5em + 0.75rem + 2px) !important;
}
.select2-container--bootstrap4 .select2-selection--single .select2-selection__placeholder {
    line-height: calc(1.5em + 0.75rem);
    color: #6c757d;
}
.select2-container--bootstrap4 .select2-selection--single .select2-selection__arrow {
    position: absolute;
    top: 50%;
    right: 3px;
    width: 20px;
}
.select2-container--bootstrap4 .select2-selection--single .select2-selection__arrow b {
    position: absolute;
    top: 60%;
    left: 50%;
    width: 0;
    height: 0;
    margin-top: -2px;
    margin-left: -4px;
    border-color: #343a40 transparent transparent transparent;
    border-style: solid;
    border-width: 5px 4px 0;
}
.select2-container--bootstrap4 .select2-selection--single .select2-selection__rendered {
    padding-left: 0.75rem;
    line-height: calc(1.5em + 0.75rem);
    color: #495057;
}
.select2-search--dropdown .select2-search__field {
    padding: 0.375rem 0.75rem;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}
.select2-results__message {
    color: #6c757d;
}
.select2-container--bootstrap4 .select2-selection--multiple {
    min-height: calc(1.5em + 0.75rem + 2px) !important;
}
.select2-container--bootstrap4 .select2-selection--multiple .select2-selection__rendered {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    padding: 0 0.375rem;
    margin: 0;
    list-style: none;
}
.select2-container--bootstrap4 .select2-selection--multiple .select2-selection__choice {
    float: none;
    padding: 0;
    padding-right: 0.75rem;
    margin-top: 7px;
    margin-right: 0.375rem;
    color: #495057;
    cursor: pointer;
    border: 1px solid #bdc6d0;
    border-radius: 0.2rem;
    display: inline-block;
}
.select2-container--bootstrap4 .select2-selection--multiple .select2-search__field {
    color: #495057;
}
.select2-container--bootstrap4 .select2-selection--multiple .select2-selection__choice + .select2-search {
    width: 0;
    display: none;
}
.select2-container--bootstrap4 .select2-selection--multiple .select2-selection__choice__remove {
    float: left;
    padding-right: 3px;
    padding-left: 3px;
    margin-right: 1px;
    margin-left: 3px;
    font-weight: 700;
    color: #bdc6d0;
}
.select2-container--bootstrap4 .select2-selection--multiple .select2-selection__choice__remove:hover {
    color: #343a40;
    display: none;
}
.select2-container--bootstrap4 .select2-selection--multiple .select2-selection__clear {
    position: absolute !important;
    top: 0;
    right: 0.7em;
    float: none;
    margin-right: 0;
}
.select2-container--bootstrap4.select2-container--disabled .select2-selection--multiple .select2-selection__choice {
    padding: 0 5px;
    cursor: not-allowed;
}
.select2-container--bootstrap4.select2-container--disabled
    .select2-selection--multiple
    .select2-selection__choice
    .select2-selection__choice__remove {
    display: none;
}
.select-option .select2-container--bootstrap4 .select2-selection--multiple .select2-selection__rendered {
    height: 42px;
}
.select-option .select2-container--bootstrap4 .select2-selection {
    border: 1px solid #0b3454;
    height: 42px;
    border-radius: 6px;
}
.select-option .select2-container--bootstrap4.select2-container--focus .select2-selection {
    border-color: #0b3454;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.25);
}
.select2-container .select2-search--inline .select2-search__field {
    margin: 0 !important;
    padding: 5px 10px !important;
}
.select2-container--bootstrap4 .select2-selection {
    border: 1px solid #0b34544d !important;
    border-radius: 6px !important;
    height: 45px !important;
    font-weight: 500 !important;
    display: flex;
    align-items: center;
}
.select2-container--bootstrap4 .select2-selection--multiple .select2-selection__clear {
    margin: auto;
    top: 0;
    bottom: 0;
}
.select2-container--bootstrap4 .select2-selection {
    display: flex !important;
    align-items: center;
}
.select2-container--bootstrap4 .select2-selection--multiple .select2-search__field {
    margin: 0;
}
.select2-container--bootstrap4 .select2-selection--multiple .select2-selection__choice {
    margin: 2px 4px 2px 0 !important;
}
.select2-container--bootstrap4 .select2-selection--multiple .select2-search__field {
    color: #495057;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
}
.custom-select-multi .select2-container--bootstrap4 .select2-selection {
    height: 45px;
    border-color: #b5c2cb;
    box-shadow: none;
}
.custom-select-multi .select2-container--bootstrap4 .select2-selection .select2-search__field {
    margin: 0;
    height: 43px;
    width: 100% !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
}
.section-edit .select2-container--bootstrap4 .select2-selection {
    height: 55px !important;
    border-radius: 3px !important;
}
/* multi select end*/

.card_form .custom-border-84-20 {
    border: 1px solid #0b345480;
    border-radius: 5px;
    width: 20px;
    height: 20px;
    margin: 0 10px 0 0;
    position: static;
}
.card_form .custom-border-84-20:checked[type='radio'] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e") !important;
    background-color: #5cae48 !important;
    border-color: #5cae48 !important;
    box-shadow: none;
}
.inputfile_parent label {
    height: 80px;
    line-height: 60px;
}
.action_btns {
    background: #f5faff;
    border-radius: 6px;
    padding: 18px;
    min-height: 80px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.action_btns .btn-submit {
    background: #29a745;
    height: 45px;
    border-radius: 25px;
    min-width: 180px;
    font-size: 16px;
    font-weight: 500;
    margin: 0 10px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    text-decoration: none;
}
.action_btns .btn-submit:hover {
    background: #1b9737;
}
.action_btns .btn-cancel {
    width: auto;
    height: auto;
    background: none;
    font-size: 12px;
    text-transform: uppercase;
    color: #00000098;
    font-weight: 600;
    margin: 0 10px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.action_btns .btn-cancel:hover {
    color: #000;
}
.action_btns .btn-viewsummary {
    background: #0b3454;
    height: 45px;
    border-radius: 25px;
    min-width: 180px;
    font-size: 16px;
    font-weight: 500;
    margin: 0 10px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    color: #fff;
}
.action_btns .btn-viewsummary:hover {
    background: #0c8db6;
}
.form-switch .form-check-input {
    width: 60px;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em;
    transition: background-position 0.15s ease-in-out;
    height: 28px;
}
.form-switch .form-check-input:checked {
    background-position: right center;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.alumni_profile_brife_tab.student_profile_brife_tab {
    margin-bottom: 5px;
    overflow: auto;
    padding-bottom: 20px;
}
.alumni_profile_brife_tab.student_profile_brife_tab {
    margin-bottom: 5px;
    overflow: auto;
    padding-bottom: 20px;
    scrollbar-width: thin;
}
.alumni_profile_brife_tab.student_profile_brife_tab .nav {
    justify-content: center;
    width: max-content;
}
.alumni_profile_brife_tab::-webkit-scrollbar {
    height: 4px;
}
.alumni_profile_brife_tab::-webkit-scrollbar-track {
    background: #ccc;
}
.alumni_profile_brife_tab::-webkit-scrollbar-thumb {
    background-color: #eee;
    border-radius: 2px;
    border: 1px solid #ccc;
}
.tab-pane-tab {
    border: 0;
}
.tab-pane-tab .nav-item .nav-link {
    border: 1px solid #a9abae;
    padding: 0 20px;
    border-radius: 25px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 130px;
    color: #374050;
}
.tab-pane-tab .nav-item:not(:last-child) {
    margin-right: 10px;
}
.tab-pane-tab .nav-item .nav-link.active,
.tab-pane-tab .nav-item:hover .nav-link {
    border: 1px solid #5cae48;
    background: #5cae48;
    color: #fff;
}
.modal .icon.icon-cross.btn-delete {
    position: absolute;
    right: 20px;
    top: 20px;
    border-radius: 50%;
    background: #d42941;
    font-size: 8px;
    padding: 5px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}
.custom-switch {
    padding: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
}
.custom-switch label {
    background: #fff;
    padding: 5px;
    border-radius: 25px;
    position: relative;
    width: 100px;
    height: 40px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    border: 2px solid #f34e65;
    color: #f34e65;
}
.custom-switch input + label::before {
    width: 24px;
    height: 24px;
    display: inline-block;
    background: #f34e65;
    border-radius: 50%;
    content: '';
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.custom-switch input + label::after {
    content: 'NO';
    color: #f34e65;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    text-align: center;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.custom-switch input:checked + label {
    background: #5dae48;
    border-color: #5dae48;
}
.custom-switch input:checked + label::before {
    transform: translateX(62px);
    -webkit-transform: translateX(62px);
    -moz-transform: translateX(62px);
    -ms-transform: translateX(62px);
    -o-transform: translateX(62px);
    background: #fff;
}
.custom-switch input:checked + label::after {
    transform: translateX(-30px);
    content: 'YES';
    -webkit-transform: translateX(-30px);
    -moz-transform: translateX(-30px);
    -ms-transform: translateX(-30px);
    -o-transform: translateX(-30px);
    color: #fff;
}

@media (max-width: 991px) {
    div.table-responsive table.sticky_table_3_columns td,
    div.table-responsive table.sticky_table_3_columns th {
        position: static;
    }
    div.table-responsive table.sticky_table_3_columns_noaction td,
    div.table-responsive table.sticky_table_3_columns_noaction th {
        position: static;
    }
    div.table-responsive table.sticky_table_4_columns td,
    div.table-responsive table.sticky_table_4_columns th {
        position: static;
    }
}

@media (min-width: 767px) {
    .table-responsive.attendee_dtl.sticky-table > table td:first-child,
    .table-responsive.attendee_dtl.sticky-table > table th:first-child {
        position: sticky;
        left: 0;
        z-index: 2;
    }
    .table-responsive.attendee_dtl.sticky-table > table td:nth-child(2),
    .table-responsive.attendee_dtl.sticky-table > table th:nth-child(2) {
        position: sticky;
        left: 232px;
        z-index: 2;
    }
    .table-responsive.attendee_dtl.sticky-table > table td:first-child:before,
    .table-responsive.attendee_dtl.sticky-table > table td:nth-child(2):before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        background: #fff;
    }
    .table-responsive.attendee_dtl.sticky-table > table td:first-child div,
    .table-responsive.attendee_dtl.sticky-table > table td:nth-child(2) div {
        z-index: 1;
        position: relative;
    }
    .table-responsive.attendee_dtl.sticky-table > table th:nth-child(2)::after,
    .table-responsive.attendee_dtl.sticky-table > table td:nth-child(2)::after {
        content: '';
        background: transparent linear-gradient(270deg, #ffffff 0%, #000000 100%) 0% 0% no-repeat padding-box;
        opacity: 0.1;
        width: 30px;
        position: absolute;
        right: -30px;
        bottom: 0;
        top: 0;
    }
    .table-responsive.attendee_dtl.sticky-table > table td:nth-child(3),
    .table-responsive.attendee_dtl.sticky-table > table th:nth-child(3) {
        padding-left: 30px;
        width: 150px;
    }
    .table-responsive.sticky-table {
        overflow-x: scroll;
        scrollbar-width: thin;
    }
    .table-responsive.sticky-table::-webkit-scrollbar {
        height: 4px;
    }
    .table-responsive.sticky-table::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    .table-responsive.sticky-table::-webkit-scrollbar-thumb {
        background: #888;
    }
    .table-responsive.sticky-table::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

@media (max-width: 1138px) {
    .alumni_statistics .horizontalScrol {
        overflow-x: auto;
        padding-bottom: 15px;
    }
    .alumni_statistics .horizontalScrol::-webkit-scrollbar {
        height: 3px;
    }
    .alumni_statistics .horizontalScrol::-moz-scrollbar {
        height: 3px;
    }
    .alumni_statistics .horizontalScrol::-webkit-scrollbar-track {
        background: #eee;
    }
    .alumni_statistics .horizontalScrol::-webkit-scrollbar-thumb {
        background-color: #666;
        border-radius: 20px;
        border: 2px solid #666;
    }
    .alumni_statistics .top-colleges .nav {
        flex-wrap: nowrap;
        justify-content: start !important;
    }
}

@media (max-width: 800px) {
    .alumni_statistics .top-colleges .slider {
        padding: 0;
    }
    .half-gray:before {
        display: none;
    }
}

@media (max-width: 575px) {
    .alumni_statistics .top-countries .caountryname,
    .alumni_statistics .top-countries .career {
        width: 100% !important;
        margin-bottom: 5px;
    }
    .alumni_statistics .top-countries .flex-grow-1 {
        width: calc(100% - 50px);
    }
    .alumni_statistics .top-countries .w-10 {
        width: 50px;
    }
    .alumni_statistics .card_profiles .action_btns {
        justify-content: start !important;
        flex-wrap: wrap;
    }
    .alumni_statistics .card_profiles .action_btns .btn-outline-primary {
        margin-bottom: 10px;
    }
    .alumni_statistics .card_profiles figure.student {
        width: 50px;
        height: 50px;
        left: 20px;
    }
    .alumni_statistics .card_profiles .student_details h2 {
        margin: 0 0 10px 60px;
    }
    .alumni_statistics .card_profiles .student_details .sub_details figure {
        min-width: 50px;
        height: 50px;
        width: 50px;
    }
    .univarsity_logo .logo img {
        max-width: 150px;
    }
}

@media (max-width: 480px) {
    .alumni_profile_college_name_other_detail_wrap .phone_number {
        width: 100%;
        margin-bottom: 10px;
    }
}

.single-column ~ .btn-delete {
    left: 50%;
    right: auto;
}
.single-column + .col-sm-6 ~ .btn-delete {
    left: 100%;
}

@media (max-width: 575px) {
    .single-column ~ .btn-delete {
        left: 100%;
    }
}

.menu-label-child {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 5px;
    color: #26a7df;
}

.infinite-scroll-component {
    overflow-y: auto !important;
    overflow-x: hidden !important;
}
.form-select.assessment-select {
    width: 100%;
    max-width: 287px;
    border-radius: 25px;
    margin-right: 10px;
}

.mobile-select {
    display: none;
}

@media (max-width: 800px) {
    .headerRightSide .form-select.assessment-select {
        display: none;
    }
    .mobile-select {
        display: block;
        padding: 15px 4% 15px;
        background: #0b3454;
    }
    .mobile-select .form-select.assessment-select {
        width: 100%;
        max-width: 100%;
    }
}

/* broadcast form */

.responses_section {
    margin-top: 10px;
}
.responses_list {
    background: #f3f3f3 0% 0% no-repeat padding-box;
    border-radius: 6px;
    margin-bottom: 5px;
    height: 56px;
    display: flex;
    align-items: center;
    padding: 0 20px;
}
.responses_list .responses {
    width: 255px;
}
.responses_section .btn {
    margin-top: 20px;
}
.responses_section .btn:focus {
    box-shadow: none;
}
.responses_section .btn:focus-visible {
    outline: none;
}
.responses_list select {
    border: 2px solid #7d7d7d;
    border-radius: 18px;
    width: 130px;
    height: 36px;
    -moz-appearance: none;
    -o-appearance: none;
    -ms-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    padding: 0 12px;
}
.uploaded-list {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #d1d1d1;
    border-radius: 6px;
    margin-bottom: 5px;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    position: relative;
}
.file-type {
    width: 22px;
    margin-right: 10px;
}
.file-type img {
    width: 100%;
}
.uploaded-list label {
    font-size: 16px;
    font-weight: 400;
}
.uploaded-list .btn-close {
    width: 8px;
    height: 10px;
    padding: 0;
    position: absolute;
    right: -20px;
    top: 0;
    bottom: 0;
    margin: auto;
    opacity: 0.4;
}
.customselect_parnt .custom-select {
    position: relative;
    width: max-content;
    flex: unset;
    min-width: 109px;
}
.customselect_parnt .custom-select select {
    display: none; /*hide original SELECT element: */
}
.customselect_parnt .select-selected {
    background-color: DodgerBlue;
}
.customselect_parnt .select-selected:after {
    position: absolute;
    content: '';
    top: 14px;
    right: 10px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-color: #fff transparent transparent transparent;
}
.customselect_parnt .select-selected.select-arrow-active:after {
    border-color: transparent transparent #fff transparent;
    top: 7px;
}
.customselect_parnt .select-selected {
    color: #414141;
    padding: 0px 40px 0 14px;
    border: 2px solid #7d7d7d;
    border-color: #7d7d7d;
    cursor: pointer;
    border-radius: 18px;
    height: 32px;
    width: max-content;
    transition: all 0.3s ease-in-out;
    background: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 32px;
    min-width: 55px;
    box-sizing: initial;
    border-radius: 18px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 55px;
}
.customselect_parnt .select-selected::after {
    position: absolute;
    content: '';
    top: 12px;
    right: 20px;
    width: 8px;
    height: 8px;
    border: unset;
    border-top: 2px solid #414141;
    border-right: 2px solid #414141;
    -moz-transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}
.customselect_parnt .select-selected.select-arrow-active::after {
    border: unset;
    top: 15px;
    border-top: 2px solid #7d7d7d;
    border-right: 2px solid #7d7d7d;
    transform: rotate(-45deg);
    width: 10px;
    height: 10px;
    right: 12px;
}
.customselect_parnt .select-items div {
    color: #000000;
    padding: 3px 16px;
    border: 0px solid transparent;
    border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
}
.customselect_parnt .select-items div:first-child {
    margin-top: 10px;
}
.customselect_parnt .select-items div:last-child {
    margin-bottom: 10px;
}
.customselect_parnt .select-items {
    position: absolute;
    background-color: #fff;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
    border: 1px solid #dedede;
    border-radius: 18px;
    box-shadow: 0px 10px 20px #0000001a;
    width: 145px;
}
.customselect_parnt .select-hide {
    display: none;
}
.customselect_parnt .select-items div:hover,
.customselect_parnt .same-as-selected {
    background-color: rgba(0, 0, 0, 0.1);
}
.send_msg_text {
    width: max-content;
}
.send_msg_text > a {
    width: 150px;
    height: 70px;
    background: #5cae48;
    border-radius: 10px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    line-height: 23px;
    font-weight: 500;
}
.send_msg_text > a:not(:last-child) {
    margin-right: 10px;
}
.uniprof_btnmbl_align.paddings-67 {
    padding-left: 20px;
}
.welcome_pg {
    padding-left: 50px;
    padding-right: 50px;
}
.cpw_bg {
    padding: 40px;
    background: #f8f8f8;
    border-radius: 8px;
    margin: 0 0 30px 0;
}
.cpw_bg > div {
    width: 94%;
    max-width: 385px;
    margin: 0 auto;
}
.icon-green-right {
    width: 48px;
    height: 48px;
    background: #3bb54a;
    border-radius: 50%;
    margin: auto auto 25px;
    position: relative;
}
.icon-green-right:after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 23px;
    height: 13px;
    border-left: 5px solid #fff;
    border-bottom: 5px solid #fff;
    content: '';
    transform: rotate(-45deg);
    top: -7px;
}
.searchrw {
    position: relative;
    background: #f1f1f1;
    padding: 30px;
    border-radius: 6px;
}
.searchrw .btn.icon-search {
    position: absolute;
    padding: 0;
    left: 15px;
    top: 0;
    bottom: 0;
    margin: auto;
}
form.lstCustForm .searchrw input.form-control {
    border: 1px solid #e1e1e1;
    border-radius: 20px;
    padding: 0 0 0 50px;
}
.search_sugg .chkboxal {
    position: relative;
    padding-right: 35px;
}
.search_sugg .chkboxal .form-check {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: auto;
    margin: auto;
}
form.lstCustForm .search_sugg .chkboxal .form-check .form-check-input {
    width: 20px;
    height: 20px;
    margin-left: 0;
}
select {
    cursor: pointer;
}

@media (max-width: 575px) {
    form.lstCustForm .inputfile_parent label span.uploadlabel {
        line-height: 18px;
        white-space: normal;
    }
    .uploaded-list label {
        line-height: 16px;
    }
}

.lstCustForm .ql-toolbar.ql-snow {
    border-radius: 5px 5px 0 0;
    -webkit-border-radius: 5px 5px 0 0;
    -moz-border-radius: 5px 5px 0 0;
    -ms-border-radius: 5px 5px 0 0;
    -o-border-radius: 5px 5px 0 0;
}
.lstCustForm .ql-container.ql-snow {
    border-radius: 0 0 5px 5px;
    -webkit-border-radius: 0 0 5px 5px;
    -moz-border-radius: 0 0 5px 5px;
    -ms-border-radius: 0 0 5px 5px;
    -o-border-radius: 0 0 5px 5px;
    min-height: 250px;
}
.lstCustForm .ql-container.ql-snow .ql-editor {
    min-height: 250px;
}

h2.title::after {
    content: '';
    width: 40px;
    height: 2px;
    background: #2680eb;
    position: absolute;
    bottom: 0;
    left: 0;
}
h2.title.text-center::after {
    right: 0;
    margin: auto;
}

.btn-takeTest {
    border: 2px solid #0b3454;
    height: 40px;
    border-radius: 25px;
    padding: 0 25px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-weight: 500;
    color: #0b3454;
    background: #fff;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.btn-takeTest:hover {
    background: #0b3454;
    color: #fff;
}

.select-search-group .form-select {
    border-top-left-radius: 25px !important;
    border-bottom-left-radius: 25px !important;
    padding-left: 20px;
}

.package-wrap {
    margin: 20px auto 0px auto;
    display: inline-flex;
    flex-wrap: wrap;
    width: 100%;
}
.package-list-type {
    display: flex;
    border-bottom: 1px solid #dfe1e4;
    border-right: 1px solid #dfe1e4;
    border-radius: 0 0 6px 0;
    position: relative;
    width: calc(100% - 300px);
    overflow: auto;
}
.package-list-type {
    overflow-x: auto;
    scrollbar-width: thin;
}
.package-list-type::-webkit-scrollbar {
    height: 5px;
}
.package-list-type::-webkit-scrollbar-track {
    background: #ccc;
}
.package-list-type::-webkit-scrollbar-thumb {
    background-color: #333;
    border-radius: 2px;
    border: 1px solid #ccc;
}

.package-list-title {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    height: 50px;
}
.package-list-title label {
    margin-right: 10px;
}
.package-list .package-list-title + ul {
    margin-top: 0;
}
.package-list ul {
    list-style: none;
    margin: 50px 0 0 0;
    padding: 0px 0 10px;
    background: #fff;
    border-radius: 6px 0px 0px 6px;
    border: 1px solid #dfe1e4;
    border-right: none;
}
.package-list ul li {
    padding: 0 40px;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    height: 60px;
    display: flex;
    align-items: center;
}
.package-list ul li span {
    line-height: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.package-list ul li:nth-child(even) {
    background: #f6f8fa;
}
.package-type {
    flex: 1;
    min-width: 178px;
    position: relative;
}
.package-type ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.package-list {
    width: 300px;
}
.package-type ul li {
    display: flex;
    justify-content: center;
    align-items: center;
}
.package-type-header {
    border-radius: 6px 6px 0px 0px;
    opacity: 1;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 500;
    font-size: 20px;
}
.package-type-header.starter {
    background: transparent linear-gradient(270deg, #168abc 0%, #25a1d6 34%, #2596c7 66%, #228aaa 100%);
}
.package-type-header.bronze {
    background: transparent linear-gradient(270deg, #a96b4d 0%, #d7a169 34%, #d7a068 66%, #8f4c2e 100%);
}
.package-type-header.sliver {
    background: transparent linear-gradient(270deg, #858585 0%, #9598a0 34%, #b3b6bd 66%, #828280 100%);
}
.package-type-header.gold {
    background: transparent linear-gradient(270deg, #d0b211 0%, #d9c246 34%, #d8c63d 66%, #c8993f 100%);
}
.package-type-header.defaultHeader {
    border: 1px solid #dfe1e4;
    height: 51px;
    color: #000;
    font-size: 16px;
}
.package-type-body {
    border-left: 1px solid #dfe1e4;
    border-right: 1px solid #dfe1e4;
    padding: 1px 0 10px;
    background-color: #fff;
}
.package-type:not(:last-child) {
    margin-right: 10px;
}
.package-type ul li {
    height: 60px;
}
.package-type ul li:nth-child(even) {
    background: #f6f8fa;
}
.package-type-body .form-check-input {
    position: static;
    margin: 0;
    width: 23px;
    height: 23px;
    border: 1px solid #0b345480;
    border-radius: 4px;
    opacity: 1;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23CAD4DB' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
    background-size: 21px;
}
.package-type-body .form-check-input:checked {
    background-color: #29a745;
    border-color: #29a745;
}

.package-type:last-child {
    border-radius: 0 0 10px 0;
    overflow: hidden;
}
.package-list-type:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    border: 0;
    height: calc(100% - 50px);
    background: white;
    border-radius: 0 0 6px;
    top: 50px;
}

.package-wrap .slimScrollDiv {
    flex: auto;
    height: auto !important;
    overflow: visible !important;
}
.package-wrap .slimScrollDiv .package-list-type {
    width: 100% !important;
    height: 100% !important;
}
.slimScrollWrapper {
    position: sticky;
    bottom: 20px;
}

@media (max-width: 575px) {
    .package-list {
        width: 150px;
    }
    .package-list ul li {
        padding: 0 15px;
    }
    .package-list-type {
        width: calc(100% - 150px);
    }
}

.option ul {
    list-style: none;
    margin: 11px 0;
    text-align: left;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
    line-height: 18px;
    position: relative;
}
.option ul li:not(:last-child) {
    margin-bottom: 7px;
}
.option ul:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: -6px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #ccc;
}
.option ul a {
    font-size: 14px;
}

.icon-reset {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/reset.svg);
    width: 15px;
    height: 15px;
    display: inline-block;
    transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    -o-transform: scaleX(-1);
}
.btn-reset {
    display: flex;
    align-items: center;
    color: #0b3454;
    opacity: 0.8;
    padding-left: 0;
    padding-right: 0;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.btn-reset .icon-reset {
    margin-right: 5px;
}
.btn-reset:hover {
    opacity: 1;
}

.match-list {
    padding: 0;
    margin: 0;
    list-style: none;
}
.match-list li {
    position: relative;
    padding-left: 10px;
}
.match-list li::before {
    content: '';
    width: 6px;
    height: 6px;
    background: #0a3454;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 7px;
    opacity: 0.3;
}
.match-list li a {
    color: #0b3454;
}
.match-list li a:hover {
    color: #5cae48;
}
.show-more-btn {
    border: none;
    background: none;
    color: #099af3;
}
.show-more-btn:hover {
    color: #5cae48;
}
fieldset .form-check-inline {
    min-height: 45px;
    display: inline-flex;
    align-items: center;
    margin-top: 2px;
}
fieldset .form-check-inline input {
    margin-right: 5px;
    width: 20px;
    height: 20px;
    transition: all 0.3s ease-in-out;
}
fieldset .form-check-inline .form-check-label {
    margin-top: 2px;
}
.swal2-container {
    z-index: 99999;
}

.btn-modal {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 13px;
    height: 13px;
    padding: 0;
    background-size: 100%;
    z-index: 9;
}

.green-tick {
    padding: 0;
    margin: 0 0 20px;
    list-style: none;
}
.green-tick li {
    position: relative;
    padding: 0 0 0 20px;
}
.green-tick li:not(:last-child) {
    margin-bottom: 10px;
}
.green-tick li:before {
    content: '';
    position: absolute;
    width: 12px;
    height: 7px;
    border-left: 3px solid #5cae48;
    border-bottom: 3px solid #5cae48;
    top: 8px;
    left: 0;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
}

/* new csss for uei */
.alumni-networks-block {
    border: 1px solid #c8c8c8;
    border-radius: 10px;
    background: white;
    padding: 30px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 10px 12px 0px #ccc;
    height: 100%;
    text-align: center;
}

.alumni-networks-form .alumni-networks-block {
    background: #ffffff;
    box-shadow: 0px 3px 6px #0000001a;
    border: 1px solid #d2d2d2;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}

.alumni-networks-block img {
    margin-bottom: 30px;
}

.alumni-networks-block h4 {
    margin-bottom: 20px;
    color: #0b3454;
    font-size: 20px;
    font-weight: 500;
}

.alumni-networks-block-wrap {
    position: relative;
}

.alumni-networks-block-wrap:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    background: #51499c;
    height: 170px;
}

.alumni-networks-block-wrap.alumni-networks-form:before {
    height: 300px;
}

.alumni-networks-header h1 {
    color: #fff;
    font-size: 36px;
    font-weight: 600;
    line-height: 37px;
    margin-bottom: 20px;
}

.alumni-networks-header p {
    color: #fff;
    font-size: 20px;
}

.alumni-networks-block .btn-green {
    width: 180px;
    height: 45px;
    font-size: 16px;
}
.center-block {
    margin-left: auto;
    margin-right: auto;
    display: block;
}

label[for='agreeToUnivariety'] {
    order: 1;
}

/* new css 05022024 */

/* milestone */
.welcome-title h1 {
    font-size: 35px;
    font-weight: 600;
    margin: 0 0 5px;
}
.welcome-title p {
    font-size: 18px;
    margin: 0;
}

.milestone {
    background: #fff;
    box-shadow: 0px 3px 6px #0000001a;
    border: 1px solid #d2d2d2;
    border-radius: 6px;
    height: 100%;
    position: relative;
    padding: 20px 20px 30px;
    text-align: center;
}
.milestone .icon {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: -33px;
    background: #d42941;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}
.milestone .icon span {
    font-size: 25px;
    color: #fff;
    transition: all 0.3s ease-in-out;
}
span.icon-gear-hat {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/knowledge-management.svg) no-repeat 0
        0 / 100%;
    width: 28px;
    height: 28px;
    filter: invert(1);
    transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-filter: invert(1);
}
.milestone .icon span:before {
    color: #fff;
}
.milestone .count {
    font-size: 22px;
    font-weight: 600;
    text-align: left;
}
.milestone .btn {
    height: 40px;
    text-align: center;
    margin: auto;
    display: inline-block;
    padding: 0 25px;
    border-radius: 25px;
    line-height: 36px;
    overflow: hidden;
    position: relative;
}
.milestone .btn span,
.btn-green span,
.btn-liteblue span,
.btn-invite span {
    position: relative;
}

.milestone h3 {
    text-align: center;
    max-width: 162px;
    margin: auto;
    font-size: 20px;
    font-weight: 600;
}
.milestone .count {
    font-size: 22px;
    font-weight: 600;
}
.milestone h3 {
    text-align: center;
    max-width: 162px;
    margin: auto;
    font-size: 20px;
    font-weight: 600;
}
.milestone .progress-status {
    padding: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    text-align: center;
    list-style: none;
    justify-content: center;
    align-items: center;
    margin: 20px 0 0;
}
.milestone .progress-status li {
    width: 40px;
    height: 3px;
    background: #dedede;
    display: inline-block;
    margin: 0 3px;
}
.milestone .progress-status li.completed {
    background: #5cae48;
}
.activities_status {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
    cursor: pointer;
    padding: 15px 0 0 0;
}

.activities_status_hovercont ol {
    margin: 0 0 0 20px;
    padding: 0 10px 0 0;
    text-align: left;
}
.activities_status_hovercont ol li {
    line-height: 18px;
}
.activities_status_hovercont ol li:not(:last-child) {
    margin: 0 0 8px 0;
}
.activities_status_hovercont {
    background: #fff;
    border: 1px solid #d2d2d2;
    border-radius: 6px;
    padding: 20px;
    width: 270px;
    height: 165px;
    position: absolute;
    top: -30px;
    left: 0;
    right: 0;
    margin: auto;
    box-shadow: 0px 3px 6px #0000001a;
    display: none;
}
.activities_status_hovercont::after {
    position: absolute;
    content: '';
    width: 23px;
    height: 18px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/hover_after.png) left top no-repeat;
    bottom: -18px;
}
.activities_status_hovercont > div {
    height: 125px;
    max-height: 125px;
    font-size: 14px;
    font-weight: 400;
}
.activities_status:hover .activities_status_hovercont {
    display: block;
    color: #000;
}
.activities_status:hover {
    color: #26a7df;
}
.slimScrollDiv .slimScrollBar {
    width: 4px !important;
    background: #dedede !important;
    opacity: 1 !important;
    right: 0 !important;
}
.slimScrollDiv .slimScrollRail {
    display: block !important;
    width: 1px !important;
    right: 1px !important;
    opacity: 1 !important;
    background: #676767 !important;
    left: auto !important;
}

.activities_status_hovercont > div::-webkit-scrollbar {
    scrollbar-width: thin;
}

.btn-verify {
    background: linear-gradient(to right, #185584 50%, #0d3453 50%);
    color: #fff;
    width: 140px;
    height: 40px;
    line-height: 38px;
    padding: 0 25px;
    text-decoration: none;
    border-radius: 25px;
    text-align: center;
    background-size: 200% 100%;
    transition: all 0.5s;
    background-position: right bottom;
    border: 1px solid #0d3453;
    font-size: 16px;
    font-weight: 500;
}
.btn-border {
    background: linear-gradient(to right, #185584 50%, #0d3453 50%);
    color: #fff;
    width: max-content;
    height: 40px;
    line-height: 38px;
    padding: 0 25px;
    text-decoration: none;
    border-radius: 25px;
    text-align: center;
    background-size: 200% 100%;
    transition: all 0.5s;
    background-position: right bottom;
    border: 1px solid #0d3453;
    font-size: 16px;
    font-weight: 500;
    display: block;
    margin: 0 auto;
}
.btn-border:hover,
.btn-verify:hover {
    background-position: left bottom;
    color: #fff;
    background-size: 201% 100%;
}

.icn_details .btn-green {
    width: 140px;
}
.icn_details .btn-green span {
    font-weight: 500;
    font-size: 16px;
}
.icn_details .btn-green span.icon-correct::before {
    font-size: 13px;
}
a.dashb_arr {
    width: 40px;
    height: 40px;
    background: #f1f1f1;
    border-radius: 50%;
    position: relative;
    display: inline-block;
}
a.dashb_left::before {
    content: '';
    width: 8px;
    height: 8px;
    background: transparent;
    display: inline-block;
    position: absolute;
    left: 17px;
    top: 0;
    bottom: 0;
    right: auto;
    margin: auto;
    border-top: 1.5px solid #000;
    border-right: 1.5px solid #000;
    transform: rotate(-135deg);
}
a.dashb_right::before {
    content: '';
    width: 8px;
    height: 8px;
    background: transparent;
    display: inline-block;
    position: absolute;
    left: 15px;
    top: 0;
    bottom: 0;
    right: auto;
    margin: auto;
    border-top: 1.5px solid #000;
    border-right: 1.5px solid #000;
    transform: rotate(45deg);
}
.iconsec {
    height: 50px;
    width: 50px;
    background: #d42941;
    border-radius: 50%;
}
.iconsec > b {
    color: #fff;
    font-size: 23px;
}
.icn_details {
    background: #ffffff;
    box-shadow: 0px 3px 6px #0000001a;
    border: 1px solid #d2d2d2;
    border-radius: 6px;
    padding: 25px 30px;
}
.iconsec_title {
    font-weight: 500;
    font-size: 18px;
    color: #000;
    margin: 0;
}
.iconsec_cont {
    font-weight: 400;
    font-size: 16px;
    color: #000;
    display: none;
    margin: 0 0 8px;
}
.iconsec_link {
    text-decoration: underline;
    color: #147ad6;
    font-size: 14px;
}
.iconsec_link:hover {
    text-decoration: none;
}
.iconsec_link .readmore {
    display: block;
}
.iconsec_link .readless {
    display: none;
}
.iconsec_link.active .readmore {
    display: none;
}
.iconsec_link.active .readless {
    display: block;
}
.profile_setup_cont .row > .profile_setup {
    width: calc(100% - 330px);
}
.profile_setup_cont .row > .profile_setup_help {
    width: 304px;
    height: auto;
    background: transparent linear-gradient(180deg, #ffffff 0%, #f8f8f8 100%);
    border: 1px solid #e1e1e1;
    border-radius: 6px;
    padding: 30px;
    text-align: center;
}
.profile_setup > div:not(:last-child) {
    margin: 0 0 15px;
}
.container.profile_setup_cont {
    max-width: 100%;
}
.profile_setup_help > p:first-child {
    width: 190px;
    margin: auto;
    text-align: center;
}
.custom-nav > ul li.nav-item a.nav-link.dropdown-toggle::after {
    margin-left: 17px;
    vertical-align: middle;
    border-top: 7px solid;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
}
.activities_status_hovercont ol {
    margin: 0 0 0 20px;
    padding: 0 10px 0 0;
    text-align: left;
}
.activities_status_hovercont ol li {
    line-height: 18px;
}
.activities_status_hovercont ol li:not(:last-child) {
    margin: 0 0 8px 0;
}
.activities_status_hovercont {
    background: #fff;
    border: 1px solid #d2d2d2;
    border-radius: 6px;
    padding: 20px;
    width: 270px;
    height: 165px;
    position: absolute;
    top: -30px;
    left: 0;
    right: 0;
    margin: auto;
    box-shadow: 0px 3px 6px #0000001a;
    display: none;
}
/*.activities_status_hovercont::after { position: absolute; content: ""; width: 15px; height: 15px; background: #fff; border-right: 1px solid #d2d2d2; transform: rotate(45deg); bottom: -8px; border-bottom: 1px solid #d2d2d2; box-shadow: 0px 3px 6px #0000001A; }*/
.activities_status_hovercont::after {
    position: absolute;
    content: '';
    width: 23px;
    height: 18px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/hover_after.png) left top no-repeat;
    bottom: -18px;
}
.activities_status_hovercont > div {
    height: 125px;
    max-height: 125px;
    font-size: 14px;
    font-weight: 400;
}
.activities_status:hover .activities_status_hovercont {
    display: block;
    color: #000;
}
.activities_status:hover {
    color: #26a7df;
}
.slimScrollDiv .slimScrollBar {
    width: 4px !important;
    background: #dedede !important;
    opacity: 1 !important;
    right: 0 !important;
}
.slimScrollDiv .slimScrollRail {
    display: block !important;
    width: 1px !important;
    right: 1px !important;
    opacity: 1 !important;
    background: #676767 !important;
    left: auto !important;
}

.activities_status_hovercont > div::-webkit-scrollbar {
    scrollbar-width: thin;
}

.profile_setup > div.icn_details:first-child .iconsec_cont {
    display: block;
}
#dashboard-pills-tabContent .accordion-item .accordion-button:hover {
    box-shadow: 0px 0px 5px #0000001a;
    background: #fafafa;
    border-radius: 5px;
}
#dashboard-pills-tabContent .accordion-item .accordion-button[aria-expanded='true']:hover {
    box-shadow: unset;
    background: transparent;
    border-radius: 5px;
}
.float_horizontal_animate {
    transform: translatex(0px);
    animation: float_horizontal 4s ease-in-out infinite;
}

.profilePicSection {
    width: 200px;
    height: 200px;
    border: 1px dashed #707070;
    border-radius: 50%;
    margin: auto;
    padding: 14px;
}
.profilePicSection .profileImg {
    background: #e3e3e3;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.profileImg.icon-user {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 145px;
    color: #fff;
}
.editProfilePicSection {
    overflow: hidden;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.editprofileImg::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 172px;
    height: 172px;
    border: 2px solid #0b3454;
    margin: auto;
    border-radius: 50%;
    box-shadow: 0 0 0 100px rgba(255, 255, 255, 0.5);
}

.slideRange {
    -webkit-appearance: none;
    width: 100%;
    height: 5px;
    border-radius: 5px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
}
.slideRange::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 14px;
    height: 14px;
    border: 0;
    background: #5cae48;
    cursor: pointer;
    border-radius: 50%;
}
.slideRange::-moz-range-thumb {
    width: 14px;
    height: 14px;
    border: 0;
    background: #5cae48;
    cursor: pointer;
}
.slideRange::-ms-thumb {
    width: 14px;
    height: 14px;
    border: 0;
    background: #5cae48;
    cursor: pointer;
    border-radius: 50%;
}
/** Chrome*/
.slideRange::-webkit-range-progress {
    background-color: #5cae48;
    height: 5px;
    border-radius: 5px;
}
/** FF*/
input[type='range'].slideRange::-moz-range-progress {
    background-color: #5cae48;
    height: 5px;
    border-radius: 5px;
}
/* IE*/
input[type='range'].slideRange::-ms-fill-lower {
    background-color: #5cae48;
    height: 5px;
    border-radius: 5px;
}

@keyframes float_horizontal {
    0% {
        transform: translatex(0px);
    }
    50% {
        transform: translatex(-10px);
    }
    100% {
        transform: translatex(0px);
    }
}

@media (max-width: 767px) {
    .icn_details {
        display: block !important;
    }
    .iconsec {
        float: left;
    }
    .icn_details > .flex-1 {
        float: left;
        margin: 0 0 15px;
        width: calc(100% - 70px);
    }
    .btn-verify,
    .icn_details .btn-green {
        display: flex;
        clear: both;
        margin: 0 auto 0 !important;
        float: none;
        text-align: center;
        justify-content: center;
    }
    .profile_setup_cont .row > .profile_setup {
        width: 100%;
        margin: 0 0 30px 0;
    }
    .profile_setup_cont .row > .profile_setup_help {
        width: 100%;
        border-radius: 0;
        border-left: 0 solid #fff;
        border-right: 0 solid #fff;
        border-bottom: 0 solid #fff;
    }
    .max-1140.profile_container {
        width: 100%;
    }
    .profile_container .sideBar {
        width: 94%;
        margin: auto;
    }
    .profile_container .pageContent > div:first-child {
        width: 94%;
        margin: auto;
        padding-top: 35px !important;
    }
    .profile_container .pageContent > div:first-child .welcome-title {
        width: 100%;
    }
    .profile_container .container.profile_setup_cont {
        width: 100%;
        margin: 25px auto 25px !important;
    }
    .profile_setup_cont .row > .profile_setup {
        width: 94%;
        margin: 0 auto 30px auto;
    }
    .profile_container .footer {
        margin: 0;
    }
    .dashboard .btn-green {
        order: 1;
        margin: 0 5px !important;
    }
    .dashboard .dashb_left {
        order: 0;
        margin: 0 !important;
    }
    .dashboard .dashb_right {
        order: 2;
    }
    .profile_container .welcome-title h1 {
        font-size: 30px;
    }
    .profile_setup_help > p:first-child {
        width: 100%;
    }
}

/* milestone */

/* form start */

/*form tab css*/
/* .polartabsec {
    padding: 18px 40px;
    margin: 0;
    background: #f2f9ff;
    margin: 0 -40px;
} */
.xscroll {
    width: 100%;
    overflow-x: auto;
    scrollbar-width: thin;
    position: relative;
    z-index: 8;
}
.polartabsec ul,
ul.horizontal-tab {
    border-bottom: 0 solid #fff;
    width: max-content;
}
.polartabsec ul li,
ul.horizontal-tab li {
    background: transparent;
    border: 0px solid #7d7d7d;
    border-radius: 20px;
    height: 40px;
    padding: 0;
}
.polartabsec ul li:not(:last-child),
ul.horizontal-tab li:not(:last-child) {
    margin: 0 10px 0 0;
}
.polartabsec ul li button.nav-link,
ul.horizontal-tab li button.nav-link {
    color: #414141;
    font-size: 16px;
    font-weight: 500;
    height: 40px;
    line-height: 38px;
    background: #fff;
    border: 2px solid #7d7d7d;
    padding: 0 23px;
    border-radius: 20px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}
.polartabsec ul li button.nav-link:focus-visible,
ul.horizontal-tab li button.nav-link:focus-visible {
    outline: none;
}
.polartabsec ul li button.nav-link.active,
.polartabsec ul li button.nav-link:hover,
ul.horizontal-tab li button.nav-link.active,
ul.horizontal-tab li button.nav-link:hover {
    color: #fff;
}
.polartabsec ul li button.nav-link.active,
.polartabsec ul li button.nav-link:hover,
ul.horizontal-tab li button.nav-link.active,
ul.horizontal-tab li button.nav-link:hover {
    color: #fff;
    border: 2px solid #5cae48;
    background: #5cae48;
}
.polartabsec ul.social-integrations li button.nav-link.active,
.polartabsec ul.social-integrations li button.nav-link:hover {
    color: #fff;
    border: 2px solid #0b3454;
    background: #0b3454;
}
.polartabsec ul li button.nav-link i {
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 4px;
    margin-right: 6px;
}
.polartabsec ul li button.nav-link i.facebook {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/tab-facebook.svg) no-repeat 0 0;
}
.polartabsec ul li button.nav-link i.linkedin {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/tab-linkedin.svg) no-repeat 0 0;
}
.polartabsec ul li button.nav-link i.instagram {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/tab-instagram.png) no-repeat 0 0;
}

.JobInternshipForm {
    width: 94%;
    max-width: 670px;
    display: none;
}
.JobInternshipForm h2 {
    border-bottom: 1px solid rgba(125, 125, 125, 0.3);
    padding: 0 0 10px;
    margin: 0 0 40px;
}
form.lstCustForm .form-select,
form.lstCustForm input.form-control {
    border: 1px solid #848484;
    height: 45px;
}
form.lstCustForm textarea.form-control {
    border: 1px solid #848484;
}
form.lstCustForm .form-check .form-check-input {
    width: 24px;
    height: 24px;
    margin-left: 0px;
    border: 2px solid #848484;
}
form.lstCustForm .form-check:not(:last-child) {
    margin: 0 40px 5px 0;
}
form.lstCustForm .btn-greenm,
.btn-greenm252 {
    min-width: 252px;
}
form.lstCustForm label span {
    font-size: 14px;
    color: #848484;
    font-weight: 400;
    font-style: italic;
    float: right;
    display: inline-block;
    padding: 0 0 0 20px;
}
form.lstCustForm .inputfile_parent label span.uploadlabel {
    font-size: 16px;
    color: #414141;
    font-weight: 400;
    float: right;
    display: inline-block;
    padding: 0 0 0 8px;
    font-style: normal;
}
form.lstCustForm label span.icon {
    font-style: unset;
    color: #414141;
}
.fileclose {
    height: 12px;
    line-height: 10px;
    width: 12px;
    text-align: center;
    background: #959595;
    border-radius: 50%;
    color: #fff;
    font-size: 12px;
    cursor: pointer;
    display: none;
    margin-left: 6px;
    justify-content: center;
    align-items: center;
}
.fileclose span {
    font-size: 6px;
    display: block;
    margin: 0 0 0px 1px;
}
.fileicon {
    display: none;
    margin-right: 6px;
}
.docselect {
    display: none;
    align-items: center;
    width: max-content;
}
.docselect.docseladdbg {
    background: #f8f8f8;
    display: flex;
    padding: 7px 12px;
    margin: 10px 0 0 0;
    border-radius: 6px;
}
.inputfile::file-selector-button {
    font: inherit;
    display: none;
}
.inputfile_parent label {
    color: #f1e5e6;
    background-color: #f3f3f300;
    border: 1px dashed #848484;
    border-radius: 6px;
    height: 60px;
    line-height: 60px;
    max-width: 100%;
    font-size: 1.25rem;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.albmform .inputfile_parent label {
    height: 80px;
    line-height: 80px;
}
.inputfile_parent label span {
    font-size: 16px;
    color: #414141;
    font-weight: 500;
    font-style: unset;
    float: none;
    padding: 0 0 0 10px;
}
.inputfile_parent {
    position: relative;
    height: auto;
}
.inputfile_parent.profile .docselect {
    display: none;
}
.inputfile_parent.profile.select + p {
    display: none;
}
.inputfile_parent.profile label {
    background-color: #fff;
    border: 1px dashed #a8c5dd;
    border-radius: 6px;
    height: 40px;
    line-height: 40px;
}
.inputfile_parent.profile.select .docseladdbg {
    display: flex;
    background: #eaeaea;
}
.inputfile_parent.profile.pop-up label {
    background: #f3f3f3;
    border: 1px dashed #848484;
    border-radius: 6px;
    opacity: 1;
    height: 45px;
    line-height: 45px;
}

form.lstCustForm .form-select {
    background-image: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/select-arrow.svg);
    background-size: 12px;
}
form.lstCustForm .form-select,
form.lstCustForm input.form-control {
    border: 1px solid #0b34544d;
    height: 45px;
}
.form-control[class*='_Link'] {
    padding-left: 50px;
}
#youtubeLink {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='%23F61C0D' stroke='' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: left 0.75rem center;
    background-size: 20px 20px;
}
#facebookLink {
    background-image: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/facebook_input.svg);
    background-repeat: no-repeat;
    background-position: left 0.75rem center;
    background-size: 20px 20px;
}
#linkedinLink {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='%230077B5' stroke='' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: left 0.75rem center;
    background-size: 20px 20px;
}

.uploaded-list-logo {
    background: #ffffff;
    border: 1px solid #b5c1cb;
    border-radius: 6px;
    padding: 14px 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.uploaded-list-logo img {
    width: 100%;
}
.uploaded-list-logo .btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.25em 0.25em;
    color: #000;
    background: #0b3454
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
        center/ 10px auto no-repeat;
    border: 0;
    border-radius: 50%;
    opacity: 1;
    position: absolute;
    top: -10px;
    right: -10px;
}

.form-check-input + label.gap-switch {
    background: #fff;
    padding: 5px;
    border-radius: 25px;
    position: relative;
    width: 100px;
    height: 40px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    border: 2px solid #f34e65;
    color: #f34e65;
}
.form-check-input:checked + label.gap-switch {
    background: #5dae48;
    border-color: #5dae48;
}
.form-check-input + label.gap-switch:before {
    width: 24px;
    height: 24px;
    display: inline-block;
    background: #f34e65;
    border-radius: 50%;
    content: '';
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.form-check-input:checked + label.gap-switch::before {
    transform: translateX(62px);
    -webkit-transform: translateX(62px);
    -moz-transform: translateX(62px);
    -ms-transform: translateX(62px);
    -o-transform: translateX(62px);
    background: #fff;
}
.form-check-input + label.gap-switch:after {
    content: 'NO';
    color: #f34e65;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    text-align: center;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.form-check-input:checked + label.gap-switch::after {
    transform: translateX(-30px);
    content: 'YES';
    -webkit-transform: translateX(-30px);
    -moz-transform: translateX(-30px);
    -ms-transform: translateX(-30px);
    -o-transform: translateX(-30px);
    color: #fff;
}

.action_btns {
    max-width: 100%;
    background: #f5faff;
    padding: 20px;
    border-radius: 5px;
    margin: 35px 0 0 0px;
    text-align: center;
}
.action_btns button.nextbtn {
    padding: 0px 30px;
    font-size: 1.5em;
    width: 180px;
    cursor: pointer;
    border: 0px;
    position: relative;
    margin: auto;
    transition: all 0.25s ease;
    background: #0b3454;
    color: #fff;
    overflow: hidden;
    border-radius: 25px;
    height: 45px;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
}
.action_btns .btn-skip {
    font-size: 16px;
    display: inline-block;
}
.btn-skip {
    margin: 15px;
    font-size: 12px;
    text-decoration: none;
    font-weight: 500;
    color: #00000070;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.btn-skip:hover {
    color: #d42941;
}

.right-vertical-info {
    position: absolute;
    top: 0;
    bottom: 0;
    left: calc(100% + 10px);
    margin: auto;
    font-size: 20px;
    height: 21px;
    color: #27a7df;
}

.cta_add .btn-add {
    color: #27a7df;
    padding: 0 30px 0 0;
    text-decoration: none;
    display: flex;
    align-items: center;
}
.cta_add .btn-add:hover {
    color: #5cae48;
}
.cta_add .btn-add i {
    margin-right: 10px;
    margin-top: -4px;
}
.cta_add .btn-add i path {
    fill: #5cae48;
    color: #5cae48;
}
.cta_add .btn-add:focus {
    box-shadow: none;
}
.cta_add .btn-add:focus-visible {
    border: none;
    outline: none;
}
.cta_add .btn-add:hover i img {
    filter: invert(54%) sepia(94%) saturate(312%) hue-rotate(63deg) brightness(93%) contrast(83%);
}

.profilePicSection {
    width: 200px;
    height: 200px;
    border: 1px dashed #707070;
    border-radius: 50%;
    margin: auto;
    padding: 14px;
}
.profilePicSection .profileImg {
    background: #e3e3e3;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.profilePicSection.institute_logo {
    max-width: 300px;
    width: 100%;
    border-radius: 10px;
    height: 100%;
    min-height: 100px;
    border: 2px solid #0b3454;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    padding: 5px;
}
.profilePicSection.institute_logo .profileImg.add_new_logo {
    border-radius: 5px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: #666;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    height: auto;
    cursor: pointer;
}
.profilePicSection .profileImg.added_new_logo {
    border-radius: 0;
    background: none;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    height: auto;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
}
.profileImg.icon-user {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 145px;
    color: #fff;
}
.editProfilePicSection {
    overflow: hidden;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.editprofileImg::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 172px;
    height: 172px;
    border: 2px solid #0b3454;
    margin: auto;
    border-radius: 50%;
    box-shadow: 0 0 0 100px rgba(255, 255, 255, 0.5);
}

.slideRange {
    -webkit-appearance: none;
    width: 100%;
    height: 5px;
    border-radius: 5px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
}
.slideRange::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 14px;
    height: 14px;
    border: 0;
    background: #5cae48;
    cursor: pointer;
    border-radius: 50%;
}
.slideRange::-moz-range-thumb {
    width: 14px;
    height: 14px;
    border: 0;
    background: #5cae48;
    cursor: pointer;
}
.slideRange::-ms-thumb {
    width: 14px;
    height: 14px;
    border: 0;
    background: #5cae48;
    cursor: pointer;
    border-radius: 50%;
}
/** Chrome*/
.slideRange::-webkit-range-progress {
    background-color: #5cae48;
    height: 5px;
    border-radius: 5px;
}
/** FF*/
input[type='range'].slideRange::-moz-range-progress {
    background-color: #5cae48;
    height: 5px;
    border-radius: 5px;
}
/* IE*/
input[type='range'].slideRange::-ms-fill-lower {
    background-color: #5cae48;
    height: 5px;
    border-radius: 5px;
}
.emptyevent {
    border: 1px solid #d2d2d2;
    border-radius: 6px;
}
.radio-box * {
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.radio-box {
    background: #ffffff;
    border: 1px solid #0b34544d;
    border-radius: 6px;
    padding: 11px 15px;
    min-width: 124px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}
form.lstCustForm .radio-box span {
    padding: 0;
    font-size: 16px;
    font-style: normal;
    color: #000;
    font-weight: 500;
    float: none;
}
.radio-box i {
    margin-left: 8px;
    background: #ffffff;
    border: 1px solid #b5c1cb;
    opacity: 1;
    width: 18px;
    height: 18px;
    position: relative;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.radio-box i:before {
    content: '';
    position: absolute;
    width: 9px;
    height: 5px;
    border-left: 2px solid #b5c1cb;
    border-bottom: 2px solid #b5c1cb;
    transform: rotate(-45deg);
    left: 3px;
    top: 5px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
}
.form-check-input:checked + .radio-box {
    background: #5cae48;
    border-color: #5cae48;
}
.form-check-input:checked + .radio-box span {
    color: #fff;
}
.form-check-input:checked + .radio-box i {
    border-color: #fff;
    background: #5cae48;
}
.form-check-input:checked + .radio-box i:before {
    border-color: #fff;
}

.form-check.custom-rectbox-radio .form-check-input + label {
    width: 100%;
    height: 45px;
    font-weight: 500;
    border: 1px solid #0b3454;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    padding: 0 14px;
    position: relative;
    cursor: pointer;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
}
.form-check.custom-rectbox-radio .form-check-input + label span {
    color: #000;
    font-style: normal;
    font-weight: 500;
}
.form-check.custom-rectbox-radio .form-check-input:checked + label {
    background: #5dae48;
    border: 1px solid #5dae48;
    color: #fff;
}
.custom-rectbox-radio {
    padding: 0;
    margin: 0;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.custom-rectbox-radio:not(:last-child) {
    margin-right: 8px;
}
.radio_parent {
    display: flex;
    flex-wrap: wrap;
}
.form-check.custom-rectbox-radio .form-check-input + label:before {
    content: '';
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 24px;
    height: 24px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/male_icon.svg) no-repeat center;
}
.form-check.custom-rectbox-radio .form-check-input + label[for='female']:before {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/female_icon.svg) no-repeat center;
}
.form-check.custom-rectbox-radio .form-check-input:checked + label::before {
    filter: invert(1) brightness(100);
    -webkit-filter: invert(1) brightness(100);
}
.form-check.custom-rectbox-radio .form-check-input:checked + label span {
    color: #fff;
}
form.lstCustForm hr {
    border: 1px solid #7c8795;
}
.form-control.form-search {
    background-image: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/search.svg);
    background-size: 14px;
    background-repeat: no-repeat;
    background-position: 13px center;
    padding-left: 34px;
}

.form-title {
    border-bottom: 2px solid #dbdbdb;
    padding-bottom: 10px;
    font-size: 24px;
    font-weight: 700;
    margin: 10px 0 0;
}
.form-title span {
    position: relative;
}
.form-title span:before {
    content: '';
    position: absolute;
    bottom: -9px;
    left: 0;
    right: 0;
    background: #5cae48;
    height: 2px;
}

.datepicker .datepicker-switch:hover,
.datepicker .prev:hover,
.datepicker .next:hover,
.datepicker tfoot tr th:hover {
    background: #eef5ff;
}
.datepicker {
    padding: 0;
    z-index: 9999;
}
.datepicker td,
.datepicker th {
    padding: 7px;
}
.datepicker-days thead {
    background: #eef5ff;
    font-weight: 500;
}
.datepicker-days thead th {
    font-weight: 500;
}
.datepicker-days thead th:hover {
    background: #007bff;
    color: #fff;
}
.datepicker-months,
.datepicker-days {
    overflow: hidden;
    background: #eef5ff;
    border-radius: 3px;
}
.datepicker-months tbody,
.datepicker-days tbody {
    background: #fff;
}
.form-date {
    background: #fff url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/calender.svg) no-repeat right
        10px center;
    padding-right: 40px;
}

@media (max-width: 991px) {
}

@media (max-width: 575px) {
    .polartabsec {
        padding: 18px 20px;
        margin: 0 -20px;
    }
}

.tooltip-white .tooltip-inner {
    background: #fff;
    box-shadow: 0px 3px 6px #0000001a;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 230px;
    width: 100%;
}
.tooltip-white .tooltip-inner .badge {
    background: #f1f1f1;
    border-radius: 100px;
    height: 28px;
    padding: 0 15px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #000;
    font-weight: 400;
}
.tooltip-white .tooltip-inner .badge:not(:last-child) {
    margin-bottom: 5px;
}
.tooltip-white .tooltip-arrow::before {
    border-top-color: #fff;
}
.tooltip-white .tooltip-inner ol {
    padding: 0;
    margin: 0;
    color: #000;
    list-style: none;
}

/* form End */

/* filter table */

.attendee_dtl .table > thead th {
    background: #f1f1f1;
    font-size: 16px;
    font-weight: 500;
    height: 40px;
    border: 0;
}
.attendee_dtl .table > thead tr:nth-child(2) th {
    background: #e1e1e1;
}
.attendee_dtl .table > thead th:first-child {
    border-radius: 6px 0 0 6px;
}
.attendee_dtl .table > thead th:last-child {
    border-radius: 0 6px 6px 0;
}

.table-responsive::-webkit-scrollbar {
    height: 4px;
}
.table-responsive::-webkit-scrollbar-track {
    background: #ccc;
}
.table-responsive::-webkit-scrollbar-thumb {
    background-color: #333;
    border-radius: 2px;
    border: 1px solid #ccc;
}
.table-responsive.attendee_dtl > table td:first-child,
.table-responsive.attendee_dtl > table th:first-child {
    padding-left: 20px;
}
.table-responsive.attendee_dtl > table td {
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 16px;
    vertical-align: middle;
}
.table-responsive.attendee_dtl > table td[valign='top'] {
    vertical-align: top;
}
.table-responsive.attendee_dtl > table .btn {
    line-height: 15px;
    display: flex;
    align-items: center;
    padding: 0 20px !important;
    margin: 0 5px 0 0;
}
.table-responsive.attendee_dtl > table td.active {
    width: 315px;
}
.table-responsive.attendee_dtl > table td.engagement {
    width: 245px;
}
.table-responsive.attendee_dtl > table .university-name {
    width: 175px;
}
.table-responsive.attendee_dtl > table td.sentDeliveredOpened {
    width: 365px;
}
.table-responsive.attendee_dtl a.tablink {
    color: #0b3454;
    display: flex;
    align-items: center;
    text-decoration: none;
    line-height: 18px;
}
.table-responsive.attendee_dtl a.tablink:hover {
    color: #4c4f51;
}
.table-responsive.attendee_dtl a.btn-gray-border:hover {
    color: #fff;
}
.table-responsive.attendee_dtl > table td.deliveryStatus {
    width: 205px;
}
.table-responsive td {
    position: relative;
}

/* .table-responsive.attendee_dtl.withborder > table tr:not(:first-child) td {box-shadow: 4px 0px 1px 0px #B9B9B9;} */

.input-group-select select.form-select {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.empty-notification .profile-selection-notification {
    z-index: 9;
    left: 0;
    max-width: 1012px;
    margin: auto;
    width: 1012px;
    right: 0;
    position: absolute;
    top: 50px;
}
.table-responsive .icon-like.dislike {
    width: 15px;
    height: 15px;
    -moz-transform: scale(-1);
    -webkit-transform: scale(-1);
    -o-transform: scale(-1);
    -ms-transform: scale(-1);
    transform: scale(-1) translateY(-3px);
    display: inline-block;
}

.table_cus_badge {
    background: #fef7e8;
    border-radius: 20px;
    height: 36px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #000;
    font-weight: 500;
    padding: 0 15px;
    margin: 3px;
    min-width: 65px;
}
.tab-ul {
    padding: 0;
    line-height: 22px;
    list-style: none;
    margin: 0 13px 0 0;
}
.tab-ul a {
    color: #000;
}
.tab-ul a:hover {
    color: #414141;
}
.custom-btn a {
    margin: 0 2px;
    text-decoration: none;
    color: #000;
    font-size: 12px;
}
.active-status {
    background: #ffffff;
    border: 1px solid #d2d2d2;
    border-radius: 20px;
    width: 120px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.attendee_dtl a.icon-cross {
    text-decoration: none;
}
.attendee_dtl a.icon-cross:hover {
    color: #666 !important;
}

.attendee_dtl .form-check-input,
.ActiveListings_present .form-check-input {
    width: 20px;
    height: 20px;
    border-width: 2px;
}
.attendee_dtl .form-check-input:checked,
.ActiveListings_present .form-check-input:checked {
    background-color: #0b3454;
    border-color: #0b3454;
}

.icon-sort-arrow {
    font-size: 6px;
    color: #7f7f7f;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.icon-sort-arrow .icon-invert-down-arrow {
    transform: scaleY(-1);
}

.labelItem {
    color: #000;
    text-decoration: none;
    display: flex;
    align-items: center;
}
.text-center .labelItem {
    justify-content: center;
}
.labelItem:hover {
    color: #153d5b;
}
.labelItem .icon-sort {
    margin-left: 5px;
    width: 17px;
    height: 17px;
    display: inline-block;
    position: relative;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='%23343a40' stroke='none' d='M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z'/%3e%3c/svg%3e");
}
.labelItem .icon-sort::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    background: #f1f1f1;
    width: 12px;
    height: 2px;
    margin: auto;
    transform: rotate(-45deg);
    left: -2.5px;
    display: none;
}

.fitergroup .show .accordion-body {
    border-radius: 0 6px 6px 6px;
}
.fitergroup .accordion-body {
    background: #f1f1f1;
    border-radius: 6px;
}
.btn-grpfiter {
    border: 0px solid #e1e1e1;
    border-radius: 20px;
    height: 40px;
    padding: 0 25px;
    font-size: 14px;
    font-weight: 500;
    background: #f1f1f1;
    position: relative;
}
.btn-grpfiter[aria-expanded='true'] {
    border-radius: 20px 20px 0 0;
    transition: all 0.3s ease-in-out;
}
.btn-grpfiter::before {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 0;
    right: 0;
    background: #f1f1f1;
    height: 10px;
    transition: all 0.3s ease-in-out;
    opacity: 0;
}
.btn-grpfiter[aria-expanded='true']::before {
    bottom: -10px;
    opacity: 1;
}

.mailSetting {
    position: relative;
}
.mailSetting .setting-dropdown {
    position: absolute;
    top: 30px;
    right: 10px;
    background: white;
    padding: 15px 20px;
    box-shadow: 0 0 3px 0px #ccc;
    border-radius: 6px;
    z-index: 1;
    border: 1px solid #ccc;
    text-align: right;
    display: none;
}
.mailSetting .setting-dropdown.open {
    display: block;
}
.mailSetting .setting-dropdown ul {
    padding: 0;
    margin: 0;
    list-style: none;
}
.mailSetting .setting-dropdown ul a {
    text-decoration: none;
    color: #000;
}
.mailSetting .setting-dropdown ul li:not(:last-child) {
    margin-bottom: 5px;
}

td a {
    color: #0b3454;
    opacity: 1;
    text-decoration: none;
}
td a:hover {
    color: #5cae48;
    opacity: 1;
}

.btn-conview,
.link {
    color: #0d6efd;
    text-decoration: underline;
}
.btn-conview:hover,
.link:hover {
    color: #5cae48;
    opacity: 1;
}

.tooltip-setting .tooltip-inner {
    background: #ffffff;
    color: #515151;
    border: 1px solid #cecfcf;
    box-shadow: 0px 5px 10px #00000033;
    padding: 15px 12px;
}
.tooltip.tooltip-setting .show {
    opacity: 2;
}
.tooltip-setting .bs-tooltip-top .tooltip-arrow::before {
    top: -1px;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #fff;
}
.tooltip-setting .bs-tooltip-bottom .tooltip-arrow::before {
    bottom: -1px;
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: #fff;
}
.tooltip-setting .tooltip-inner ul {
    padding: 0;
    margin: 0;
    text-align: left;
    list-style: none;
    line-height: 24px;
}
.tooltip-setting .tooltip-inner ul a {
    font-size: 14px;
    color: #000;
    text-decoration: none;
    padding: 0;
    border: none;
    font-weight: 500;
    background: none;
    transition: none;
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
}
.bs-tooltip-auto.tooltip-setting[data-popper-placement^='top'] .tooltip-arrow::before,
.bs-tooltip-top.tooltip-setting .tooltip-arrow::before {
    border-top-color: #fff;
}

.tooltip-html {
}
.tooltip-html .tooltip-inner {
    background: #ffffff;
    color: #515151;
    border: 1px solid #cecfcf;
    box-shadow: 0px 5px 10px #00000033;
    padding: 15px 15px;
    max-width: 300px;
    line-height: 20px;
}
.tooltip.tooltip-html .show {
    opacity: 2;
}
.tooltip-html .bs-tooltip-top .tooltip-arrow::before {
    top: -1px;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #fff;
}
.tooltip-html .bs-tooltip-bottom .tooltip-arrow::before {
    bottom: -1px;
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: #fff;
}
.tooltip-html .tooltip-inner h6 {
    text-align: left;
}
.tooltip-html .tooltip-inner ul {
    padding: 0;
    margin: 0;
    text-align: left;
    list-style: none;
    line-height: 24px;
}
.tooltip-html .tooltip-inner ul a {
    font-size: 14px;
    color: #000;
    text-decoration: none;
}
.bs-tooltip-auto.tooltip-html[data-popper-placement^='top'] .tooltip-arrow::before,
.bs-tooltip-top.tooltip-html .tooltip-arrow::before {
    border-top-color: #fff;
}
.tooltip-html .tooltip-inner ul li {
    white-space: wrap;
}
.tooltip-html .tooltip-inner ul li:not(:last-child) {
    margin: 0 0 8px;
}
.tooltip-html .tooltip-inner ul li b {
    margin-right: 5px;
    font-weight: 500;
    color: #000;
}
.tooltip-html .tooltip-inner ol {
    padding: 0 0 0 20px;
    margin: 0 0 6px;
    text-align: left;
    font-weight: 500;
    color: #000;
}
.tooltip-html .tooltip-inner ol li {
    list-style: decimal;
}
.tooltip-html .tooltip-inner p {
    padding: 0 0 0 20px;
    margin: 0;
    text-align: left;
    font-weight: 500;
    color: #000;
}

.datewrap {
    display: flex;
    flex-wrap: wrap;
    background: #ffffff;
    border: 1px solid #848484;
    border-radius: 6px;
    height: 40px;
    position: relative;
    overflow: hidden;
}
#config-demo,
.singledaterangePickerList,
.daterangePickerList {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    margin: auto;
    cursor: pointer;
    opacity: 0;
}
.datewrap .arrow {
    background: #f1f1f1;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
}
/*.datewrap .arrow span {background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='1' d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z'/%3e%3c/svg%3e");font-size: 0;width: 25px;height: 25px;}*/
.datewrap .arrow span {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/arrow.svg) no-repeat center;
    font-size: 0;
    width: 25px;
    height: 25px;
}
.datewrap .from,
.datewrap .to {
    flex: 1;
}
.datewrap .form-control {
    height: 100% !important;
    border: 0 !important;
    background: #fff url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/calendar.svg) no-repeat 10px
        center;
    padding-left: 40px;
}
.fitergroup .accordion-body .form-select,
.fitergroup .accordion-body .form-control,
.tab-pane .fullViewMobile .form-select,
.tab-pane .fullViewMobile .form-control {
    height: 40px;
    border: 1px solid #848484;
}
.form-control.form-search {
    background-image: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/search.svg);
    background-size: 14px;
    background-repeat: no-repeat;
    background-position: 13px center;
    padding-left: 34px;
}

.search.alumniSearch {
    max-width: 350px;
    width: 100%;
    background: #fff;
    border: 1px solid #7d7d7d;
    border-radius: 20px;
}
.search.alumniSearch .btn-search {
    right: auto;
    left: 7px;
    top: 6px;
}
.search.alumniSearch .form-control {
    padding-left: 40px;
}
.search-100 .search.alumniSearch {
    max-width: 280px;
}

.singlelinename {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 125px;
}
.tablink img[width='30'] {
    width: 30px;
    min-width: 30px;
}
.tablink .icon-subtraction {
    position: absolute;
    bottom: -3px;
    right: -3px;
    width: 16px;
    height: 15px;
}
.tablink .icon-subtraction::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 15px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/subtraction-white-star.svg) no-repeat
        0 0 / 100%;
    z-index: 2;
}
.tablink .icon-subtraction::after {
    content: '';
    position: absolute;
    width: 66%;
    height: 65%;
    background: #fff;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}
.icon-star.circle-img {
    position: absolute;
    bottom: -3px;
    right: -3px;
    background: #5cae48;
    border-radius: 50%;
    color: #fff;
    font-size: 7px;
    padding: 4px;
}

.table-responsive.attendee_dtl td a > i {
    width: 40px;
    height: 40px;
    font-size: 30px;
    background-position: center;
}
.table-responsive.attendee_dtl td a > i img {
    vertical-align: top;
}
.course-list-row span {
    vertical-align: top;
    margin-right: 4px;
}
.course-list-row i {
    margin-top: 0px;
}

.filterresult ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
}
.filterresult ul li:not(:last-child) {
    margin-right: 10px;
}
.filterresult ul li {
    margin-bottom: 5px;
}
.filterresult ul li a {
    background: #e9f6fc;
    border-radius: 20px;
    min-height: 32px;
    padding: 5px 20px;
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #000;
    text-decoration: none;
}
.filterresult ul li a i {
    margin-left: 8px;
    font-size: 9px;
}
.filterresult ul li.clear a {
    background: none;
    color: #d42941;
    text-decoration: underline;
}
.filterresult ul li.clear a:hover {
    color: #0b3454;
    text-decoration: none;
}
.filterresult {
    padding: 10px 0 15px;
    border-bottom: 1px solid #e5eaed;
    margin-bottom: 20px;
}
.pagination_custom {
    max-width: 751px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
}
.pagination_custom .pagination_custom_inner {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 20px;
}
.pagination_custom .pagination_custom_inner ul {
    padding: 0;
    margin: 0 20px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    justify-content: center;
    align-items: center;
}
.pagination_custom .pagination_custom_inner ul li:not(:last-child) {
    margin-right: 0;
}
.pagination_custom .pagination_custom_inner ul li {
    width: 30px;
    text-align: center;
    margin: 0 10px;
}
.pagination_custom a,
.pagination_custom .pagination_custom_inner ul li a {
    text-decoration: none;
    font-size: 16px;
    color: #0b3454;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}
.pagination_custom .pagination_custom_inner ul li.current a {
    background: #0b3454;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}

.summary-blocks {
    width: 1140px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 15px;
}
.summary-blocks .block {
    width: calc(20.19% - 1%);
    border-radius: 4px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    padding: 32px 20px;
}
.summary-blocks .block {
    background: #fff;
}

.summary-blocks .block:not(:last-child) {
    margin-right: 1%;
}
.summary-blocks .block .icon {
    margin-right: 15px;
}
.summary-blocks .block .block-detail .number {
    font-size: 26px;
    line-height: 30px;
    font-weight: 600;
}
.summary-blocks .block .block-detail .block-detail-title {
    font-size: 15px;
    line-height: 18px;
    font-weight: 500;
}
.summary-blocks .block.totalAlumni .block-detail {
    color: #d42941;
}
.summary-blocks .block.uptoDate .block-detail {
    color: #0b3454;
}
.summary-blocks .block.due .block-detail {
    color: #26a7df;
}
.summary-blocks .block.smartApplyLeads .block-detail {
    color: #403a81;
}
.summary-blocks .block.smartApplyRegd .block-detail {
    color: #6962a7;
}

.x-scroll {
    overflow-x: auto;
    scrollbar-width: thin;
}
.x-scroll::-webkit-scrollbar {
    height: 4px;
}
.x-scroll::-webkit-scrollbar-track {
    background: #f1f1f1;
}
.x-scroll::-webkit-scrollbar-thumb {
    background: #888;
}
.x-scroll::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.x-scroll-big {
    overflow-x: auto;
}
.x-scroll-big::-webkit-scrollbar {
    height: 15px;
}
.x-scroll-big::-webkit-scrollbar-track {
    background: #ccc;
}
.x-scroll-big::-webkit-scrollbar-thumb {
    background: #666;
}
.x-scroll-big::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.blue-badge {
    background: #68c2e9;
    font-weight: 500;
    border: 1px solid #68c2e9;
    border-radius: 6px;
    padding: 0 15px;
    height: 26px;
    display: inline-block;
    line-height: 26px;
    color: #fff !important;
    margin-bottom: 5px;
    font-size: 12px;
    text-transform: uppercase;
}

.sticky_table_3_columns th:first-child {
    position: sticky;
    left: 0;
    z-index: 3;
}
.sticky_table_3_columns th:nth-child(2) {
    position: sticky;
    left: 140px;
    z-index: 3;
}
.sticky_table_3_columns th:nth-child(3) {
    position: sticky;
    left: calc(140px + 250px);
    z-index: 3;
}

.sticky_table_3_columns td:first-child {
    position: sticky;
    left: 0;
    z-index: 3;
}
.sticky_table_3_columns td:nth-child(2) {
    position: sticky;
    left: 140px;
    z-index: 3;
}
.sticky_table_3_columns td:nth-child(3) {
    position: sticky;
    left: calc(140px + 250px);
    z-index: 3;
}
.sticky_table_3_columns th:nth-child(3)::before,
.sticky_table_3_columns td:nth-child(3)::before {
    content: '';
    width: 30px;
    position: absolute;
    right: 0px;
    bottom: 0;
    top: 0;
    background: transparent linear-gradient(270deg, #000000 0%, #00000000 100%);
    opacity: 0.05;
}
.sticky_table_3_columns td::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1px;
    height: 0;
    border-bottom: 1px solid #dee2e6;
}

.sticky_table_3_columns_noaction th:first-child {
    position: sticky;
    left: 0;
    z-index: 3;
}
.sticky_table_3_columns_noaction th:nth-child(2) {
    position: sticky;
    left: 250px;
    z-index: 3;
}
.sticky_table_3_columns_noaction th:nth-child(3) {
    position: sticky;
    left: calc(250px + 180px);
    z-index: 3;
}

.sticky_table_3_columns_noaction td:first-child {
    position: sticky;
    left: 0;
    z-index: 3;
}
.sticky_table_3_columns_noaction td:nth-child(2) {
    position: sticky;
    left: 250px;
    z-index: 3;
}
.sticky_table_3_columns_noaction td:nth-child(3) {
    position: sticky;
    left: calc(250px + 180px);
    z-index: 3;
}
.sticky_table_3_columns_noaction th:nth-child(3)::before,
.sticky_table_3_columns_noaction td:nth-child(3)::before {
    content: '';
    width: 30px;
    position: absolute;
    right: 0px;
    bottom: 0;
    top: 0;
    background: transparent linear-gradient(270deg, #000000 0%, #00000000 100%);
    opacity: 0.05;
}
.sticky_table_3_columns_noaction td::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1px;
    height: 0;
    border-bottom: 1px solid #dee2e6;
}

.sticky_table_4_columns th:first-child {
    position: sticky;
    left: 0;
    z-index: 3;
}
.sticky_table_4_columns th:nth-child(2) {
    position: sticky;
    left: 150px;
    z-index: 3;
}
.sticky_table_4_columns th:nth-child(3) {
    position: sticky;
    left: calc(150px + 225px);
    z-index: 3;
}
.sticky_table_4_columns th:nth-child(4) {
    position: sticky;
    left: calc(150px + 225px + 180px);
    z-index: 3;
}

.sticky_table_4_columns td:first-child {
    position: sticky;
    left: 0;
    z-index: 3;
}
.sticky_table_4_columns td:nth-child(2) {
    position: sticky;
    left: 150px;
    z-index: 3;
}
.sticky_table_4_columns td:nth-child(3) {
    position: sticky;
    left: calc(150px + 225px);
    z-index: 3;
}
.sticky_table_4_columns td:nth-child(4) {
    position: sticky;
    left: calc(150px + 225px + 180px);
    z-index: 3;
}
.sticky_table_4_columns th:nth-child(4)::before,
.sticky_table_4_columns td:nth-child(4)::before {
    content: '';
    width: 30px;
    position: absolute;
    right: 0px;
    bottom: 0;
    top: 0;
    background: transparent linear-gradient(270deg, #000000 0%, #00000000 100%);
    opacity: 0.05;
}
.sticky_table_4_columns td::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1px;
    height: 0;
    border-bottom: 1px solid #dee2e6;
}

.customselect_parnt > .form-select {
    border-radius: 25px;
    margin-right: 10px;
    border: 2px solid #7d7d7d;
    height: 36px;
    display: flex;
    align-items: center;
    line-height: 18px;
}
.customselect_parnt button {
    height: 36px;
}
.icon-download {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/download.svg) no-repeat 0 0 / 100%;
    width: 17px;
    height: 16px;
    display: inline-block;
}
.mailSetting .icon-download {
    filter: invert(16%) sepia(71%) saturate(697%) hue-rotate(167deg) brightness(92%) contrast(99%);
    -webkit-filter: invert(16%) sepia(71%) saturate(697%) hue-rotate(167deg) brightness(92%) contrast(99%);
}
.mailSetting .icon-download:hover {
    filter: invert(53%) sepia(75%) saturate(353%) hue-rotate(62deg) brightness(97%) contrast(89%);
    -webkit-filter: invert(53%) sepia(75%) saturate(353%) hue-rotate(62deg) brightness(97%) contrast(89%);
}
.gray_badge .badge {
    background: #f1f1f1;
    border-radius: 100px;
    height: 28px;
    padding: 0 15px;
    display: inline-block;
    font-size: 14px;
    color: #000;
    font-weight: 400;
    margin-bottom: 5px;
    max-width: 108px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    text-align: center;
    line-height: 28px;
}

.custom-btn a {
    margin: 0 2px;
    text-decoration: none;
    color: #212529;
}
.custom-btn a:hover {
    color: #868686;
}

@media (max-width: 991px) {
    div.table-responsive table.sticky_table_3_columns td,
    div.table-responsive table.sticky_table_3_columns th {
        position: static;
    }
    div.table-responsive table.sticky_table_3_columns_noaction td,
    div.table-responsive table.sticky_table_3_columns_noaction th {
        position: static;
    }
    div.table-responsive table.sticky_table_4_columns td,
    div.table-responsive table.sticky_table_4_columns th {
        position: static;
    }
}

@media (min-width: 767px) {
    .table-responsive.attendee_dtl.sticky-table > table td:first-child,
    .table-responsive.attendee_dtl.sticky-table > table th:first-child {
        position: sticky;
        left: 0;
        z-index: 2;
    }
    .table-responsive.attendee_dtl.sticky-table > table td:nth-child(2),
    .table-responsive.attendee_dtl.sticky-table > table th:nth-child(2) {
        position: sticky;
        left: 232px;
        z-index: 2;
    }
    .table-responsive.attendee_dtl.sticky-table > table td:first-child:before,
    .table-responsive.attendee_dtl.sticky-table > table td:nth-child(2):before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        background: #fff;
    }
    .table-responsive.attendee_dtl.sticky-table > table td:first-child div,
    .table-responsive.attendee_dtl.sticky-table > table td:nth-child(2) div {
        z-index: 1;
        position: relative;
    }
    .table-responsive.attendee_dtl.sticky-table > table th:nth-child(2)::after,
    .table-responsive.attendee_dtl.sticky-table > table td:nth-child(2)::after {
        content: '';
        background: transparent linear-gradient(270deg, #ffffff 0%, #000000 100%);
        opacity: 0.1;
        width: 30px;
        position: absolute;
        right: -30px;
        bottom: 0;
        top: 0;
    }
    .table-responsive.attendee_dtl.sticky-table > table td:nth-child(3),
    .table-responsive.attendee_dtl.sticky-table > table th:nth-child(3) {
        padding-left: 30px;
        width: 150px;
    }

    .table-responsive.sticky-table {
        overflow-x: scroll;
        scrollbar-width: thin;
    }
    .table-responsive.sticky-table::-webkit-scrollbar {
        height: 4px;
    }
    .table-responsive.sticky-table::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    .table-responsive.sticky-table::-webkit-scrollbar-thumb {
        background: #888;
    }
    .table-responsive.sticky-table::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

/* filter table end */

.package-wrap {
    margin: 20px auto 40px auto;
    display: inline-flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 800px;
}
.package-list-type {
    display: flex;
    flex: 1;
    border-bottom: 1px solid #dfe1e4;
    border-right: 1px solid #dfe1e4;
    border-radius: 0 0 6px 0;
    position: relative;
}

.package-list-title {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    height: 50px;
}
.package-list-title label {
    margin-right: 10px;
}
.package-list .package-list-title + ul {
    margin-top: 0;
}
.package-list ul {
    list-style: none;
    margin: 50px 0 0 0;
    padding: 0px 0 10px;
    background: #fff;
    border-radius: 6px 0px 0px 6px;
    border: 1px solid #dfe1e4;
    border-right: none;
}
.package-list ul li {
    padding: 0 40px;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    height: 60px;
    display: flex;
    align-items: center;
}
.package-list ul li span {
    line-height: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.package-list ul li:nth-child(even) {
    background: #f6f8fa;
}
.package-type {
    flex: 1;
    min-width: 178px;
    position: relative;
}
.package-type ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.package-list {
    flex: 1;
}
.package-type ul li {
    display: flex;
    justify-content: center;
    align-items: center;
}
.package-type-header {
    border-radius: 6px 6px 0px 0px;
    opacity: 1;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 500;
    font-size: 20px;
}
.package-type-header.starter {
    background: transparent linear-gradient(270deg, #168abc 0%, #25a1d6 34%, #2596c7 66%, #228aaa 100%);
}
.package-type-header.bronze {
    background: transparent linear-gradient(270deg, #a96b4d 0%, #d7a169 34%, #d7a068 66%, #8f4c2e 100%);
}
.package-type-header.sliver {
    background: transparent linear-gradient(270deg, #858585 0%, #9598a0 34%, #b3b6bd 66%, #828280 100%);
}
.package-type-header.gold {
    background: transparent linear-gradient(270deg, #d0b211 0%, #d9c246 34%, #d8c63d 66%, #c8993f 100%);
}
.package-type-header.defaultHeader {
    border: 1px solid #dfe1e4;
    height: 51px;
    color: #000;
    font-size: 16px;
}
.package-type-body {
    border-left: 1px solid #dfe1e4;
    border-right: 1px solid #dfe1e4;
    padding: 1px 0 10px;
    background-color: #fff;
}
.package-type:not(:last-child) {
    margin-right: 10px;
}
.package-type ul li {
    height: 60px;
}
.package-type ul li:nth-child(even) {
    background: #f6f8fa;
}
.package-type-body .form-check-input {
    position: static;
    margin: 0;
    width: 23px;
    height: 23px;
    border: 1px solid #0b345480;
    border-radius: 4px;
    opacity: 1;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23CAD4DB' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
    background-size: 21px;
}
.package-type-body .form-check-input:checked {
    background-color: #29a745;
    border-color: #29a745;
}

.package-type:last-child {
    border-radius: 0 0 10px 0;
    overflow: hidden;
}
.package-list-type:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    border: 0;
    height: calc(100% - 50px);
    background: white;
    border-radius: 0 0 6px;
    top: 50px;
}

.package-wrap .slimScrollDiv {
    flex: auto;
    height: auto !important;
    overflow: visible !important;
}
.package-wrap .slimScrollDiv .package-list-type {
    width: 100% !important;
    height: 100% !important;
}
.slimScrollWrapper {
    position: sticky;
    bottom: 20px;
}

/* Bulk Imports  */

.bulk_imports_header {
    border-bottom: 1px solid #c1ccd4;
}
.steps_flow {
    margin: auto;
    padding: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    position: relative;
}
.polartabsec .steps_flow li {
    height: auto;
    position: relative;
}
.polartabsec .steps_flow li:not(:last-child) {
    margin-right: 254px;
}
.polartabsec .steps_flow li a {
    text-decoration: none;
    color: #000;
    font-size: 18px;
}
.polartabsec .steps_flow li.active a {
    font-weight: 500;
}
.steps_flow:before {
    content: '';
    position: absolute;
    left: 20px;
    bottom: -20px;
    right: 20px;
    border-bottom: 3px solid #5cae48;
    margin: auto;
}
.polartabsec .steps_flow li:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -30px;
    background: #ffffff;
    border: 2px solid #0b345433;
    opacity: 1;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin: auto;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.polartabsec .steps_flow li.active:before {
    background: #0db35c;
    border: 1px solid #0db35c;
    margin: auto;
    box-shadow: 0 0 0 5px #fff inset;
}
.polartabsec .steps_flow li.completed::before {
    background-color: #0db35c;
    border: 1px solid #0db35c;
    margin: auto;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='' stroke-linejoin='' stroke-width='2' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.import-title {
    text-align: center;
    margin-top: 30px;
}
.import-title h3 {
    font-size: 24px;
    font-weight: 500;
    margin: 0 0 5px;
}
.import-title p {
    font-size: 16px;
    margin-bottom: 5px;
}

.import-block * {
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.import-block {
    background: #ffffff;
    border: 1px solid #d2d2d2;
    border-radius: 6px;
    position: relative;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    overflow: hidden;
}
.import-block .icon {
    position: absolute;
    background: #0b3454;
    border-radius: 0px 4px 0px 72px;
    width: 78px;
    height: 78px;
    top: 0;
    right: 0;
}
.import-block .icon::before {
    filter: brightness(0) invert(1);
    -webkit-filter: brightness(0) invert(1);
}
.import-block-detail {
    padding: 20px 30px 30px;
}
.icon.icon_user::before {
    content: '';
    position: absolute;
    right: 14px;
    top: 14px;
    width: 30px;
    height: 30px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/selet-user.svg);
}
.icon.icon_display::before {
    content: '';
    position: absolute;
    right: 14px;
    top: 14px;
    width: 30px;
    height: 30px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/display-user.svg);
}
.import-block-detail h4 {
    font-size: 24px;
    font-weight: 500;
    margin: 0 0 15px;
}
.import-block-detail ul {
    margin: 0;
    padding: 0;
    max-width: 420px;
}
.import-block-detail ul li:not(:last-child) {
    margin-bottom: 18px;
}
.import-block-detail ul li {
    position: relative;
    padding: 0 0 0 18px;
    line-height: 20px;
}
.import-block-detail ul li:before {
    content: '';
    position: absolute;
    top: 3px;
    left: 0;
    background: #27a7df;
    width: 3px;
    height: 85%;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.import-block-select {
    background: #f2f9ff;
    width: 100%;
    height: 60px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    color: #0b345490;
    text-transform: uppercase;
    cursor: pointer;
}
.import-block-select i {
    width: 26px;
    height: 26px;
    border-radius: 5px;
    border: 1px solid #d2d2d2;
    background: #fff;
    margin-right: 10px;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    position: relative;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.inputfile_parent label.height-80 {
    height: 80px;
}
.inputfile_parent label.height-80 span {
    font-weight: 400;
}
.inputfile_parent label .text-skyblue {
    color: #27a7df;
}

.form-check-input:checked + div {
    border: 2px solid #5cae48;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.form-check-input:checked + div .icon {
    background: #f2f9ff;
}
.form-check-input:checked + div .icon:before {
    filter: brightness(1) invert(0);
    -webkit-filter: brightness(1) invert(0);
}
.form-check-input:checked + div .import-block-select {
    background: #5cae48;
    color: #fff;
}
.form-check-input:checked + div .import-block-select i {
    border-color: #fff;
    background-color: #fff;
}
.form-check-input:checked + div .import-block-select i::before {
    content: '';
    position: absolute;
    border-width: 0px 0px 3px 3px;
    border-style: solid;
    border-color: #5cae48;
    width: 14px;
    height: 7px;
    transform: rotate(-45deg);
    left: 6px;
    top: 7px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
}
td .form-check-input:checked + div {
    border: none;
}

.upload_disclaimer {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #0b3454;
    opacity: 0.8;
    padding: 14px 0;
}

.form-check-tick .form-check-input {
    width: 26px;
    height: 26px;
    margin-right: 8px;
    margin-top: 0;
}
.form-check-tick {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
}
.form-check-tick .form-check-input:checked {
    background-color: #5cae48;
    border-color: #5cae48;
}

.map-card-wrap {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 20px;
}
.map-card {
    border: 1px solid #dbdcdd;
    border-radius: 6px;
    width: 257px;
    min-width: 257px;
    overflow: hidden;
}
.map-card:not(:last-child) {
    margin-right: 10px;
}
.map-column {
    background: #f2f9ff;
    padding: 10px 15px 20px;
}
.map-title {
    color: #68696a;
    font-weight: 500;
    font-size: 14px;
}
.skip_column {
    height: 45px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
}
.skip_column .skip-link {
    color: #0b3454;
}
.column-list {
    padding: 0;
    margin: 0;
}
.column-list li {
    min-height: 45px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    padding: 0 15px;
}
.column-list-title {
    background: #f5f7f8;
    font-weight: 500;
}
.column-list li ~ li:not(:last-child) {
    border-bottom: 1px solid #ebebeb;
}

.modal-header .icon-correct {
    width: 50px;
    height: 50px;
    background: #5cae48;
    border-radius: 50%;
    color: #fff;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

@media (max-width: 575px) {
    .polartabsec .steps_flow li:not(:last-child) {
        margin-right: 145px;
    }
    .inputfile_parent label.height-80 span.uploadlabel {
        text-overflow: ellipsis;
        width: calc(100% - 75px);
        overflow: hidden;
    }
}
/* Bulk Imports end */

/* Manage Integrations */
.twoColSection.tab-content > .active {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    width: 100%;
}
.tab-inner {
    width: 100%;
}
.tab-inner > .row {
    height: 100%;
}
.twoColSection .nav {
    flex-direction: column;
    padding-top: 5px;
}
.twoColSection .nav .nav-item {
    padding-right: 5px;
}
.twoColSection .nav .nav-item .nav-link {
    width: 100%;
    border-radius: 0;
    text-align: left;
    color: #2b4e6a;
    font-weight: 500;
}
.twoColSection .nav .nav-item .nav-link.active {
    color: #5cae48;
    background: #fff;
}

.api-host {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    margin-top: 24px;
    background: #fffae5;
    padding: 15px;
    border-radius: 6px;
    margin-bottom: 20px;
    border: 2px dashed #f1ecd6;
    align-items: center;
}
.api-host label {
    background: #fff;
    padding: 10px 15px;
    border-radius: 6px 0px 0 6px;
    font-weight: 500;
    position: relative;
    margin-right: 30px;
}
.api-host label:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 100%;
    border-top: 22.5px solid transparent;
    border-bottom: 22.5px solid transparent;
    border-left: 15px solid #fff;
    height: 0;
    width: 0;
}
.api-url {
    font-size: 18px;
    font-weight: 500;
    color: #0b3454;
    width: calc(100% - 171px);
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
}
.api-url .icon-copy {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/copy.svg) no-repeat 0 0 / 100%;
    width: 26px;
    height: 26px;
    filter: invert(88%) sepia(7%) saturate(264%) hue-rotate(125deg) brightness(78%) contrast(91%);
    cursor: pointer;
    -webkit-filter: invert(88%) sepia(7%) saturate(264%) hue-rotate(125deg) brightness(78%) contrast(91%);
}
.api-url span {
    width: calc(100% - 26px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.badge.enabled,
.badge.success {
    background: #caf3c1;
    color: #000;
    border-radius: 25px;
    padding: 10px 30px;
    font-weight: 500;
    font-size: 16px;
}
.badge.unauthorized {
    background: #fdebc5;
    color: #000;
    border-radius: 25px;
    padding: 10px 30px;
    font-weight: 500;
    font-size: 16px;
}
.badge.serverError {
    background: #fedddd;
    color: #000;
    border-radius: 25px;
    padding: 10px 30px;
    font-weight: 500;
    font-size: 16px;
}
.access-key {
    color: #4b6a81;
    font-weight: 500;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
}
.access-key .icon-copy {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/copy.svg) no-repeat 0 0 / 100%;
    width: 26px;
    height: 26px;
    filter: invert(72%) sepia(25%) saturate(4663%) hue-rotate(167deg) brightness(94%) contrast(88%);
    cursor: pointer;
    -webkit-filter: invert(72%) sepia(25%) saturate(4663%) hue-rotate(167deg) brightness(94%) contrast(88%);
}
.client-ip {
    color: #0b3454;
    font-weight: 500;
}
.filterSearch .refresh-wrap {
    margin-left: auto;
}

.data-preview-wrap {
    border-radius: 6px;
    border: 1px solid #e1e1e1;
    overflow: hidden;
}
.data-preview-wrap .card_custom .alumni_presence {
    padding: 0 30px 40px;
}
.data-preview-wrap .card_custom .card-header {
    background: transparent;
    border-bottom: none;
    font-size: 22px;
    text-transform: uppercase;
    font-weight: 600;
    padding: 25px;
}
.data-preview-wrap .card_custom .card-header select {
    border-radius: 200px;
    height: 40px;
    border: 1px solid #8499a9;
    font-weight: 500;
    font-size: 16px;
    width: auto;
    max-width: 180px;
    padding-left: 15px;
    -webkit-border-radius: 200px;
    -moz-border-radius: 200px;
    -ms-border-radius: 200px;
    -o-border-radius: 200px;
}
.data-preview-wrap .card_custom .card-header p span img {
    margin-top: -7px !important;
}
.data-preview-wrap .top-countries .progress {
    height: 40px;
    background-color: rgba(0, 0, 0, 0.06);
}
.data-preview-wrap .top-countries .progress-bar.countries {
    background-color: #26a7df;
}
.data-preview-wrap .top-countries .exclude_india {
    font-size: 18px;
    font-weight: 500;
}
.data-preview-wrap .top-countries .caountryname {
    line-height: 15px;
}
.data-preview-wrap .top-careers .progress-bar.countries {
    background-color: #51499c;
}
.data-preview-wrap .top-countries-title {
    width: 100px;
}
.data-preview-wrap .top-countries-title + div {
    width: calc(100% - 150px);
}
.data-preview-wrap .percent-progress {
    width: 50px;
}
.switch_option label {
    cursor: pointer;
}
.switch_option .form-switch .form-check-input {
    width: 60px;
    height: 30px;
    margin: 0 10px 0 0;
    background-color: #c1ccd4;
    border-color: #c1ccd4;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/%3e%3c/svg%3e");
}
.switch_option .form-switch {
    padding: 0;
    align-items: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}
.switch_option .form-switch .form-check-input:checked {
    background-color: #5cae48;
    border-color: #5cae48;
}
.switch_option .form-switch .form-check-input:focus {
    box-shadow: none;
    outline: none;
}

.override_setting {
    border-top: 1px solid rgb(233, 246, 252);
}
.override_setting ul {
    padding: 0;
    margin: 0;
    list-style: none;
}
.override_setting ul li:last-child {
    padding-top: 15px;
}
.override_setting ul li:not(:last-child) {
    border-bottom: 1px solid #ccc;
    padding: 15px 0;
}
.override_setting .form-check .form-check-input {
    width: 20px;
    height: 20px;
    margin: 0 7px 0 0;
}
.override_setting .form-check {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    padding: 0;
}
.override_setting .form-check-input:checked {
    background-color: #fff;
    border-color: #5cae48;
}
.override_setting .form-check-input:checked[type='radio'] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%235cae48'/%3e%3c/svg%3e");
}

@media (max-width: 767px) {
    .twoColSection .nav {
        flex-direction: row;
        flex-wrap: nowrap;
        overflow: auto;
        white-space: nowrap;
        padding: 7px 0;
        height: 100%;
    }
    .twoColSection .nav .nav-item {
        padding-right: 0;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        align-items: center;
    }
    .twoColSection .nav .nav-item .nav-link {
        border-radius: 4px;
    }
    .tab-inner > .row {
        height: auto;
    }
}

@media (max-width: 575px) {
    .filterSearch .refresh-wrap {
        width: 100%;
        order: -1;
        margin-bottom: 15px;
        text-align: right;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        justify-content: end;
    }
}

/* Manage Integrations */

/* manage-widgets-wrap */
.manage-widgets-wrap ul {
    padding: 0;
    margin: 0;
    list-style: none;
}
.manage-widgets-wrap ul li {
    background: #ffffff;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    padding: 10px;
}
.manage-widgets-wrap ul li:not(:last-child) {
    margin-bottom: 5px;
}
.manage-widgets-wrap {
    margin-top: 25px;
}

.manage-widgets-wrap .form-check-tick .form-check-input {
    width: 22px;
    height: 22px;
}

.btn.btn-widgets-edit {
    background: #ffffff;
    border: 2px solid #0b3454;
    border-radius: 15px;
    height: 28px;
    line-height: 25px;
    padding: 0 20px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    color: #0b3454;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.btn.btn-widgets-edit:hover {
    background: #0b3454;
    color: #fff;
}

.btn.btn-priview {
    background: #ffffff;
    border: 2px solid #5cae48;
    border-radius: 15px;
    height: 28px;
    line-height: 25px;
    padding: 0 20px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    color: #5cae48;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.btn.btn-priview:hover {
    background: #5cae48;
    color: #fff;
}

.btn.btn-configure {
    background: #0b3454;
    border: 2px solid #0b3454;
    border-radius: 15px;
    height: 28px;
    line-height: 25px;
    padding: 0 20px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    color: #fff;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.btn.btn-configure:hover {
    background: #5cae48;
    border: 2px solid #5cae48;
    color: #fff;
}
/* manage-widgets-wrap */

/* authorized_accounts */
.authorized_accounts {
    margin-top: 35px;
}
.authorized_accounts_block {
    background: #ffffff;
    height: 100%;
    border: 1px solid #d2d2d2;
    border-radius: 6px;
    opacity: 1;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 20px;
}
.authorized_accounts_status {
    border-radius: 0px 5px 0px 6px;
    opacity: 1;
    width: 108px;
    height: 24px;
    position: absolute;
    right: 0;
    top: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
}
.authorized_accounts_status i {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 5px;
}
.authorized_accounts_status.active {
    background: #d6ffcc;
    color: #136000;
}
.authorized_accounts_status.active i {
    background: #5cae48;
    border: 3px solid #f4fff2;
}
.authorized_accounts_status.deactive {
    background: #f3f3f3;
    color: #303030;
}
.authorized_accounts_status.deactive i {
    background: #737373;
    border: 3px solid #ffffff;
}
.authorized_accounts_profile_img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
}
.authorized_accounts_profile_img img {
    width: auto;
    height: 100%;
}
.authorized_accounts_profile_img.logo {
    border: 1px solid #d5d5d5;
    padding: 3px;
}
.authorized_accounts_detail h3 {
    font-size: 20px;
    color: #0b3454;
    margin: 0;
}
.authorized_accounts_detail p {
    font-size: 14px;
}
.authorized_accounts_block > a {
    width: 110px;
    border-radius: 23px;
    height: 36px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.authorized_accounts_block a.btn-disconnect {
    background: #ffffff;
    border: 2px solid #e1697a;
    color: #d42941;
}
.authorized_accounts_block a.btn-disconnect:hover {
    background: #e1697a;
    color: #fff;
}
.authorized_accounts_block a.btn-authorize {
    background: #ffffff;
    border: 2px solid #547187;
    color: #0b3454;
}
.authorized_accounts_block a.btn-authorize:hover {
    background: #547187;
    color: #fff;
}

.authorized_accounts_block.new_account a {
    background: #f2f9ff;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    padding: 20px;
}
.authorized_accounts_block.new_account {
    position: relative;
    border: none;
    padding: 0;
}
.authorized_accounts_block.new_account a .plus {
    background: #f2f9ff;
    width: 70px;
    height: 70px;
    border: 1px solid #acb0b5;
    border-radius: 50%;
    margin-bottom: 15px;
    position: relative;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.authorized_accounts_block.new_account a .plus:before {
    content: '';
    position: absolute;
    width: 40px;
    height: 2px;
    background: #acb0b5;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.authorized_accounts_block.new_account a .plus:after {
    content: '';
    position: absolute;
    width: 2px;
    height: 40px;
    background: #acb0b5;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.authorized_accounts_block.new_account a span {
    width: 110px;
    border-radius: 23px;
    height: 36px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    background: #5cae48;
    color: #fff;
}

.authorized_accounts_block.new_account a:hover {
    background: #5cae48;
}
.authorized_accounts_block.new_account a:hover .plus {
    background: none;
    border-color: #fff;
}
.authorized_accounts_block.new_account a:hover .plus:before {
    background: #fff;
}
.authorized_accounts_block.new_account a:hover .plus:after {
    background: #fff;
}
.authorized_accounts_block.new_account a:hover span {
    background: #fff;
    color: #5cae48;
}

/* authorized_accounts */

/* alumni directory */

.aldir_scroll > .row > .aldir_box {
    width: 212px;
    height: 133px;
    padding: 0;
}
.aldir_scroll > .row > .aldir_box:not(:last-child) {
    margin-right: 20px;
}
.aldir_box .number {
    padding: 10px 12px 0 20px;
}
.aldir_box .number + div > div.font-16 {
    color: #0b3454;
    line-height: 18px;
    padding: 8px 0 0 18px;
}
.aldir_box .alumni-directory-img {
    margin-right: 10px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: flex-end;
}
.aldir_box .alumni-directory-block {
    justify-content: space-between;
    flex-direction: row;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
}
.aldir_box .alumni-directory-block > div {
    width: 100%;
}
.aldir_box .number + div > div.font-16.min94 {
    min-width: 94px;
}
.aldir_scroll > .row {
    width: max-content;
    margin: 0;
}

.fiterItem {
    padding: 0;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 6px;
    overflow: hidden;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.filterHeader {
    background: transparent;
    border-radius: 0;
    padding: 0;
    border: 0px solid rgba(0, 0, 0, 0.125);
}
.filterHeader button span.toggle {
    width: 10px;
    height: 10px;
    margin-left: 10px;
    position: relative;
}
.filterHeader button span.toggle::before {
    width: 10px;
    height: 10px;
    background: transparent;
    border-left: 2px solid #000;
    border-top: 2px solid #000;
    transform: rotate(45deg);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    content: '';
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.filterHeader button.collapsed span.toggle::before {
    transform: rotate(225deg);
    -webkit-transform: rotate(225deg);
    -moz-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    -o-transform: rotate(225deg);
}

.scrollMe {
    max-height: 215px;
    margin-bottom: 0px;
    overflow-y: auto;
    scrollbar-width: thin;
}
.scrollMe::-webkit-scrollbar {
    width: 4px;
}
.scrollMe::-moz-scrollbar {
    width: 4px;
}
.scrollMe::-webkit-scrollbar-track {
    background: #eee;
}
.scrollMe::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 20px;
    border: 2px solid #666;
}

.fiterItem .form-check:not(:last-child) {
    margin-bottom: 10px;
}
.fiterItem .form-check .form-check-input {
    width: 20px;
    height: 20px; /* margin-left: -20px; */
    border: 1px solid #bacfde;
}
.fiterItem label {
    cursor: pointer;
    padding-top: 3px !important;
}
.fiterItem .card {
    border-radius: 0;
    border: 0px solid #fff;
    padding: 0px 20px 20px;
}
.filterHeader button {
    background: #fff;
    border-radius: 0;
    padding: 0;
    border: 0px solid rgba(0, 0, 0, 0.125);
    padding: 15px 20px;
}
.filterHeader button.collapsed {
    border-bottom: 0px solid rgba(0, 0, 0, 0.125);
}
.filterSearchblk {
    margin: 0px;
    padding: 0 18px;
}
.filterSearchblk .filterSearch input {
    background: #f3f3f3;
    border: 0 solid #fff;
    border-radius: 6px;
    color: #000;
    height: 36px;
    line-height: 36px;
    margin: 0 4px 2px;
}
.filterSearchblk .filterSearch button {
    position: absolute;
    right: 14px;
    width: 14px;
    height: 14px;
    font-size: 14px;
    top: 9px;
    padding: 0;
    color: #414141;
}
.ji_filtersec {
    width: 100%;
    max-width: 320px;
}
form.minmaxlb .form-label {
    color: #535353;
    font-size: 14px;
    font-weight: 500;
    font-style: italic;
}
form.minmaxlb button {
    max-width: 91px;
    background: #0b3454;
    padding: 0;
    height: 36px;
    line-height: 36px;
    color: #fff;
    border-radius: 18px;
}
.fiterItem .card .form-check-input:checked,
form.lstCustForm .form-check-input:checked {
    background-color: #5cae48;
    border-color: #5cae48 !important;
}
.form-check-input:focus {
    box-shadow: 0 0 0 0 rgba(13, 110, 253, 0.25);
}

.alumni-directory-header {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}
.alumni-directory-header_search {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
}
.alumni-directorySearch {
    background: #ffffff;
    width: 365px;
    height: 40px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    border-radius: 25px;
    overflow: hidden;
    position: relative;
}
.alumni-directorySearch .form-control {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    border: 0;
    padding-left: 0;
}
.alumni-directorySearch .btn.icon-search {
    font-size: 12px;
    padding: 0 10px 0 20px;
}

.alumni-directory-header .btn-white {
    background: #ffffff;
    border-radius: 20px;
    padding: 0 20px;
    line-height: 40px;
    text-decoration: none;
    color: #000000;
    font-weight: 400;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.alumni-directory-header .btn-white:hover {
    background: #882130;
    color: #fff;
}

.alumni-directorySearch .btn-search-cross {
    position: absolute;
    right: 13px;
    top: 0;
    bottom: 0;
    margin: auto;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    color: #000;
    font-weight: 100 !important;
}
.alumni-directorySearch .btn-cross {
    width: 14px;
    height: 14px;
    position: absolute;
    right: -15px;
    top: 0;
    bottom: 0;
    margin: auto;
    transform: rotate(0deg);
    opacity: 0;
    transition: all 0.5s ease-in-out;
}
.alumni-directorySearch .btn-cross:before {
    content: '';
    position: absolute;
    width: 14px;
    height: 2px;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    margin: auto;
    background: #000;
    border-radius: 5px;
}
.alumni-directorySearch .btn-cross:after {
    content: '';
    position: absolute;
    width: 2px;
    height: 14px;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    margin: auto;
    background: #000;
    border-radius: 5px;
}
.alumni-directorySearch .form-control:valid + .btn-cross {
    opacity: 1;
    right: 15px;
    transform: rotate(135deg);
}

.alumni_directory_filter {
    padding: 30px 0;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
}
.alumni_directory_filter .ji_filtersec {
    width: 100%;
    max-width: 100%;
}
.alumni_directory_filter .fiterItem {
    width: calc(33.3% - 10px);
    margin: 0 5px;
    box-shadow: 0px 10px 25px #00000008;
}
.alumni_directory_filter .fiterItem.w-20 {
    width: calc(20% - 10px);
}
.alumni_directory_filter .fiterItem.width-25 {
    width: calc(25% - 10px);
}
.alumni_directory_filter .fiterItem.w-40 {
    width: calc(40% - 10px);
}
.alumni_directory_filter #additionalfilter .fiterItem {
    width: 28%;
}
.alumni_directory_filter #additionalfilter .fiterItem.w-20 {
    width: calc(20% - 10px);
}
.alumni_directory_filter #additionalfilter .fiterItem.width-25 {
    width: calc(25% - 10px);
}
.alumni_directory_filter #additionalfilter .fiterItem.width-50 {
    width: calc(50% - 10px);
}
.alumni_directory_filter #additionalfilter .fiterItem.w-40 {
    width: calc(40% - 10px);
}
.alumni_directory_filter #additionalfilter .fiterItem.campus {
    width: 41%;
}
.filterItemWrap .fiterItem {
    width: 190px;
}
.alumni_directory_filter .fiterItem.key {
    border-color: #5cae48;
}
.alumni_directory_filter .filterGroup {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    align-items: start;
    margin: 0 -5px;
}
.alumni_directory_filter .filterSearchblk {
    background: #fff;
    padding: 0 14px 10px;
}
.alumni_directory_filter .filterSearchblk .filterSearch input {
    margin: 0;
}
.filters-head {
    position: relative;
}
.filterItemWrap {
    position: absolute;
    top: -10px;
    left: 90px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    z-index: 1;
    align-items: start;
}
.alumni_directory_filter .filterItemWrap .filterHeader button {
    padding: 7px 15px;
}
.alumni_directory_filter .filterHeader button {
    transition: all 0.3s ease-in-out;
    padding: 15px 15px;
}
.alumni_directory_filter .filterHeader button:not([aria-expanded='false']) {
    padding-bottom: 8px;
}
.alumni_directory_filter .fiterItem .card {
    padding: 0px 15px 15px;
}
.alumni_directory_filter .scrollMe {
    max-height: 122px;
    min-height: 122px;
    overflow-x: hidden;
}
.alumni_directory_filter .fiterItem .form-check {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}
.alumni_directory_filter .fiterItem .form-check:not(:last-child) {
    margin-bottom: 4px;
}
.additionalfilter_count {
    display: block;
    letter-spacing: 2px;
    flex: 1;
    padding: 0 5px;
}
.additionalfilter_count:before {
    content: '(';
}
.additionalfilter_count:after {
    content: ')';
}
.btn-md-filter {
    text-decoration: none;
    transform: rotate(90deg);
    color: #000;
    font-size: 20px;
    transition: all 0.3s ease-in-out;
}
.btn-md-filter:hover {
    color: #000;
}
.btn-md-filter[aria-expanded='true'] {
    transform: rotate(270deg);
}
.additionalfilter_wrap {
    width: 100%;
    padding: 0 5px;
}
.alumni_directory_filter .fiterItem.campus {
    width: 450px;
}

.showProfiles_wrap {
    background: #fff;
    border: 1px solid #d2d2d2;
    border-radius: 6px;
    box-shadow: 0px 10px 25px #00000008;
    width: calc(100% - 10px);
    margin: 0 5px;
    padding: 17px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.showProfiles_left {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
}
.showProfiles_left h3 {
    margin: 0 30px 0 0;
    font-size: 18px;
    line-height: 20px;
    font-weight: 600;
}
.showProfiles_left .form-check-label {
    font-size: 16px;
}
.showProfiles_left .form-check-input {
    width: 20px;
    height: 20px;
    vertical-align: top;
    margin-top: -2px;
    margin-right: 10px;
}
.showProfiles_left .form-check.form-check-inline {
    display: inline-flex;
    align-items: center;
}
.showProfiles_left .check_list {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
}
.showProfiles_left .form-check-input:checked {
    background-color: #5cae48;
    border-color: #5cae48;
}
.showProfiles_right .icon {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/subtraction.svg);
    width: 16px;
    height: 25px;
    margin-right: 8px;
}
.showProfiles_right {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.showProfiles_right .title {
    font-size: 14px;
    letter-spacing: -0.35px;
    height: auto;
}

.alumni-bucket {
    background: #ffffff;
    border: 1px solid #d2d2d2;
    border-radius: 6px;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}
.alumni-bucket .form-check {
    position: absolute;
    left: 10px;
    top: 10px;
    cursor: pointer;
}
.alumni-bucket .block-link {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    z-index: 1;
}
.alumni_img {
    width: 60px;
    margin-right: 10px;
    height: 60px;
    background: #f1f1f1;
    border-radius: 50%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
}
.alumni_img img {
    width: auto;
    height: 100%;
}
.alumni_img.no_pic {
    align-items: end;
}
.alumni_img.no_pic img {
    width: auto;
    height: auto;
}
.alumni_name_brife h3 {
    font-size: 18px;
    margin: 0;
}
.class_year {
    color: #6a6868;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
}
.alumni_directory_details .btn-viewcompus {
    font-size: 13px;
    color: #1452ce;
    font-weight: 500;
    line-height: 18px;
    position: relative;
    z-index: 2;
    text-decoration: none;
}
.alumni_directory_details .btn-viewcompus:hover {
    color: #0b3454;
    text-decoration: underline;
}
.alumni_directory_details a.btn-send {
    padding: 0 20px;
    line-height: 36px;
    text-decoration: none;
    background: #5cae48;
    border-radius: 25px;
    height: 36px;
    display: inline-flex;
    color: #fff;
    align-items: center;
    transition: all 0.3s ease-in-out;
    position: relative;
    z-index: 2;
}
.alumni_directory_details a.btn-send:hover {
    background: #40862f;
}
.icon-alumni-send {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/send_alumni_profile.svg) no-repeat 0
        0 / 100%;
    width: 19px;
    height: 19px;
    margin-right: 8px;
    margin-left: -3px;
}
.alumni_univarsity_details {
    background: #f9f9f9;
    border-radius: 0px 0px 5px 5px;
    opacity: 1;
    display: flex;
    justify-content: center;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 15px 20px;
    position: relative;
}
.alumni_univarsity_details .alumni_univarsity_details_inner_cover {
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    align-items: start;
}
.alumni_univarsity_details .alumni_univarsity_details_inner_cover .alumni_univarsity_logo {
    width: 64px;
    margin-right: 10px;
    padding: 6px;
    background: #fff;
    border-radius: 50%;
    border: 1px solid #d2d2d2;
    height: 64px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
}
.alumni_univarsity_details .alumni_univarsity_details_inner_cover .alumni_univarsity_logo.icon-brife-case {
    height: 64px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #dc3545;
    border: 1px solid #dc3545;
}
.alumni_univarsity_details .alumni_univarsity_details_inner_cover .alumni_univarsity_logo img {
    width: auto;
    max-height: 100%;
}
.alumni_univarsity_details .alumni_univarsity_details_inner_cover .alumni_univarsity_brife {
    width: calc(100% - 75px);
}
.alumni_directory_details a.alumni_name_link {
    color: #000;
    text-decoration: none;
    font-size: 18px;
    line-height: 18px;
}
.alumni_directory_details a.alumni_name_link:hover {
    color: #666;
}
.alumni_directory_details .alumni_campus {
    color: #6a6868;
    font-size: 14px;
    font-weight: 500;
}

.btn-pushwebsite {
    background: #5cae48;
    border-radius: 20px;
    margin-top: 15px;
    display: inline-flex;
    height: 40px;
    padding: 0 20px;
    align-items: center;
    text-decoration: none;
    color: #fff;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.btn-pushwebsite i {
    margin-right: 10px;
    width: 20px;
    height: 20px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/web.svg) no-repeat center;
}
.btn-pushwebsite:hover {
    background: #3d842b;
    color: #fff;
}

.alumni_directory_details .alumni_course {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    margin-top: 8px;
}
.alumni_directory_details .alumni_course i {
    width: 24px;
    height: 24px;
    margin-right: 5px;
}
.alumni_directory_details .alumni_course i.study {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/student-book.svg) no-repeat center /
        100%;
}
.alumni_directory_details .alumni_course span {
    font-size: 16px;
    font-weight: 600;
}
.alumni_class_link {
    padding: 15px 20px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
}
.alumni_name_detail {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
}
.alumni_class_link .modal {background: rgba(0, 0, 0, 0.8);}
.alumni_name_brife {
    display: flex;
    flex-direction: column;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    justify-content: center;
}
.alumni_education {
    color: #4d4d4d;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    margin-bottom: 3px;
}
.alumni_univarsity_name {
    font-size: 19px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 4px;
}
.alumni_univarsity_name .icon-subtraction {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/subtraction.svg) no-repeat 0 0 / 100%;
    width: 12px;
    height: 20px;
    margin-left: 3px;
    display: inline-block;
    vertical-align: top;
}
.alumni_univarsity_subject {
    color: #0b3454;
    font-size: 14px;
    line-height: 16px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
}
.alumni_univarsity_subject span {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.alumni_univarsity_subject i {
    width: 16px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/subject_setting.svg) no-repeat 0 0 /
        100%;
    height: 16px;
    margin-right: 7px;
}
.alumni_univarsity_subject i.icon-brifecase {
    width: 16px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/briefcase.svg) no-repeat 0 0 / 100%;
    height: 16px;
    margin-right: 7px;
}

.alumni_name_brife h3 a {
    color: #000;
    text-decoration: none;
    font-size: 18px;
    line-height: 18px;
    word-break: break-all;
}
.alumni_name_brife .alumni_campus {
    color: #6a6868;
    font-size: 14px;
    font-weight: 500;
}
.alumni_name_brife .alumni_course {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    margin-top: 8px;
}
.alumni_name_brife .alumni_course i {
    width: 24px;
    height: 24px;
    margin-right: 5px;
}
.alumni_name_brife .alumni_course i.study {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/student-book.svg) no-repeat center /
        100%;
}
.alumni_name_brife .alumni_course span {
    font-size: 16px;
    font-weight: 600;
}

.filter_result ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
}
.filter_result_title {
    margin-bottom: 18px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 10px;
}
.filter_result_title .filter_result {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.filter_result ul li {
    background: #e9f6fc;
    border-radius: 25px;
    padding: 9px 23px;
    margin-top: 6px;
    margin-bottom: 6px;
}
.filter_result ul li:not(:last-child) {
    margin-right: 10px;
}
.filter_result ul li:last-child {
    background: no-repeat;
    padding: 9px 0;
}
.filter_result ul li span {
    margin-right: 10px;
}
.filter_result ul li .btn-cross {
    font-size: 10px;
    color: #000;
    text-decoration: none;
}
.filter_result ul li .btn-cross:hover {
    color: #105271;
}
.alumni_count {
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    margin: 18px 0 0;
}

.btn-additionalfilter {
    text-decoration: none;
    font-size: 14px;
    color: #1452ce;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    transition: all 0.3s ease-in-out;
}
.btn-additionalfilter span {
    text-decoration: underline;
    margin-right: 10px;
}
.btn-additionalfilter .icon-right-arrow {
    transform: rotate(90deg);
    transition: all 0.3s ease-in-out;
}
.btn-additionalfilter[aria-expanded='true'] .icon-right-arrow {
    transform: rotate(270deg);
}
.btn-additionalfilter:hover {
    color: #0b3454;
}
.btn-additionalfilter:hover span {
    text-decoration: none;
}

.alumni_directory_filter .filters-head h3 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    margin: 0;
    align-items: center;
}
.alumni_directory_filter .filters-head h3 .icon-filter + span {
    flex: 1;
}
.alumni_directory_filter .filters-head h3[aria-expanded='true'] i {
    transform: rotate(270deg);
}

.alumni_directory_details .load_more_btn {
    text-decoration: none;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
}
.alumni_directory_details .load_more_btn:hover {
    background: #0b3454;
    color: #fff;
    border-color: #0b3454;
}

.alumni_directory_details .load_more_btn img {
    animation: none;
    -webkit-animation: none;
    margin-right: 10px;
}
.alumni_directory_details .load_more_btn:hover img {
    filter: invert(1) brightness(100);
}
.tooltip-viewcompus .tooltip-arrow::before {
    display: none;
}
.alumni_directory_details .load_more_btn {
    text-decoration: none;
    text-transform: uppercase;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #cccc;
    padding: 10px 25px;
    border-radius: 25px;
    transition: all 0.3s ease-in-out;
    max-width: 250px;
    width: 100%;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.alumni_directory_details .load_more_btn:hover {
    background: #0b3454;
    color: #fff;
    border-color: #0b3454;
}

/* .alumni_profile_brife_wrap {border-bottom: 1px solid #E7E7E7;}
.alumni_profile_brife {padding: 40px 0;}
.alumni_profile_brife .btn-back {color: #000;font-size: 14px;line-height: 16px;font-weight: 500;text-decoration: none;}
.alumni_profile_brife .btn-back .icon-right-arrow {transform: scaleX(-1);margin-right: 7px;font-size: 12px;}
.alumni_profile_brife .btn-back:hover {color: #0b3454;}

.alumni_profile_name_college_other_detail_wrap {display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;-webkit-box-wrap: wrap;-moz-box-wrap: wrap;-ms-flex-wrap: wrap;-webkit-wrap: wrap;flex-wrap: wrap;}
.alumni_profile_image_name_other_detail {display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;-webkit-box-wrap: wrap;-moz-box-wrap: wrap;-ms-flex-wrap: wrap;-webkit-wrap: wrap;flex-wrap: wrap;padding: 0 45px 0 0;position: relative;width: 45%;}
.alumni_profile_image_name_other_detail:after {content: '';width: 1px;height: 100%;background: #D4D4D4;position: absolute;right: 0;top: 0;}
.alumni_profile_image {width: 110px;height: 110px;background: #F1F1F1;border-radius: 50%;display: flex;align-items: center;justify-content: center;overflow: hidden;margin-right: 20px;}
.alumni_profile_image img {width: auto;height: 100%;}
.alumni_profile_image.no_pic {align-items: end;}
.alumni_profile_image.no_pic img {height: 80%;}

.alumni_profile_name_other_detail {-webkit-box-flex: 1;-moz-box-flex: 1;-webkit-flex: 1;-ms-flex: 1;flex: 1;}
.alumni_profile_name_other_detail h2 {font-size: 30px;line-height: 18px;font-weight: 600;word-break: break-all;line-height: 35px;}
.alumni_profile_name_other_detail .alumni_profile_location {font-size: 14px;font-weight: 500;margin-bottom: 15px;}
.alumni_profile_class_college {position: relative;padding: 0 0 0 20px;margin-bottom: 13px;}
.alumni_profile_class_college:before {content: '';position: absolute;left: 6px;top: 0;bottom: 0;width: 3px;height: 100%;background: #D52941;border-radius: 5px;}
.alumni_profile_class_college .alumni_profile_class {font-size: 16px;font-weight: 500;letter-spacing: 0.32px;line-height: 18px;margin-bottom: 5px;}
.alumni_profile_class_college .alumni_profile_college {font-size: 16px;font-weight: 500;letter-spacing: 0.32px;line-height: 18px;}

.alumni_profile_college_phone {display: flex;flex-wrap: wrap;justify-content: space-between;align-items: center;}
.alumni_profile_college_phone .verifyLink {font-size: 16px;font-weight: 500;}
.verifiedLink {color: #5cae48;text-decoration: none;display: flex;align-items: center;font-weight: 400;font-size: 16px;}

.alumni_profile_name_other_detail .btn-send {width: 123px;height: 40px;display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;-webkit-box-wrap: wrap;-moz-box-wrap: wrap;-ms-flex-wrap: wrap;-webkit-wrap: wrap;flex-wrap: wrap;background: #5CAE48;border-radius: 25pc;color: #fff;text-decoration: none;align-items: center;padding: 0 25px;font-size: 16px;font-weight: 500;transition:all 0.3s ease-in-out;}
.alumni_profile_name_other_detail .btn-send .icon-send {background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/send_alumni_profile.svg) no-repeat 0 0 / 100%;width: 19px;height: 19px;margin-right: 10px;position:relative;transition:all 0.3s ease-in-out;left:0;}
.alumni_profile_name_other_detail .btn-send:hover{background:#2FA612}
.alumni_profile_name_other_detail .btn-send:hover .icon-send {left:3px}

.alumni_profile_college_name_other_detail_wrap {-webkit-box-flex: 1;-moz-box-flex: 1;-webkit-flex: 1;-ms-flex: 1;flex: 1;display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;-webkit-box-wrap: wrap;-moz-box-wrap: wrap;-ms-flex-wrap: wrap;-webkit-wrap: wrap;flex-wrap: wrap;padding: 0 0 0 80px;}
.alumni_profile_college_name_other_detail_wrap .alumni_profile_college_logo {width: 100px;height: 100px;display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;align-items: center;justify-content: center;border-radius: 50%;border: 1px solid #D2D2D2;margin-right: 18px;}
.alumni_profile_college_name_other_detail_wrap .alumni_profile_college_logo img {width: 70%;}
.alumni_profile_college_name_other_detail_wrap .alumni_profile_college_logo img[src*="college_icon"] {	width: 40px;	}
.alumni_profile_college_name_other_detail_wrap .alumni_profile_college_name_other_detail {-webkit-box-flex: 1;-moz-box-flex: 1;-webkit-flex: 1;-ms-flex: 1;flex: 1;display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;-webkit-box-wrap: wrap;-moz-box-wrap: wrap;-ms-flex-wrap: wrap;-webkit-wrap: wrap;flex-wrap: wrap;align-items: start;}

.alumni_profile_brife_tab {margin-bottom: 20px;width: 100%;}
.alumni_profile_status {list-style: none;margin: 0;padding: 0;}
.alumni_profile_status li {margin-bottom: 16px;width: 176px;position: relative;padding-left: 16px;}
.alumni_profile_status li .title {letter-spacing: 0.32px;color: #000000;font-size: 16px;}
.alumni_profile_status li p {font-size: 16px;font-weight: 600;line-height: 20px;letter-spacing: 0.32px;}
.alumni_profile_status li:before {content: '';width: 3px;height: 17px;position: absolute;left: 0;top: 4px;background: #d52941;border-radius: 5px;}

.alumni_profile_college_joined {color: #5CAE48;font-size: 14px;font-weight: 500;line-height: 18px;margin-bottom: 5px;}
.alumni_profile_college_name_detail {-webkit-box-flex: 1;-moz-box-flex: 1;-webkit-flex: 1;-ms-flex: 1;flex: 1;padding-right: 50px;}
.alumni_profile_college_name_other_detail_wrap .alumni_profile_college_name_detail h3 {font-size: 20px;line-height: 26px;font-weight: 600;}
.alumni_profile_college_subject {height: 30px;display: inline-flex;align-items: center;justify-content: center;padding: 0 15px;background: #E5F1FB;border-radius: 25px;font-size: 16px;line-height: 16px;font-weight: 600;color:#0B3454;max-width: 280px;width: auto;}
.alumni_profile_college_subject .icon {background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/course_alumni_profile.svg);width: 15px;height: 15px;margin-right: 8px;}
.alumni_profile_college_subject span{text-overflow: ellipsis;overflow: hidden;white-space: nowrap;width: calc(100% - 23px);}
.alumni_profile_brife_links {width: 100%;padding-left: 129px;margin-top: 20px;display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;align-items: center;}
.alumni_profile_brife_links > a { height: 40px; padding: 0 20px; border-radius: 25px; display: inline-flex; align-items: center; margin-right: 6px; text-decoration: none; transition:all 0.3s ease-in-out ; -webkit-transition:all 0.3s ease-in-out ; -moz-transition:all 0.3s ease-in-out ; -ms-transition:all 0.3s ease-in-out ; -o-transition:all 0.3s ease-in-out ; }
.alumni_profile_brife_links > a i {margin-right: 5px;}
.alumni_profile_brife_links > a.btn-send{background: #5CAE48;color: #fff;}
.alumni_profile_brife_links > a.btn-send:hover{background: #2FA612;}
.alumni_profile_brife_links > a.btn-send i.icon-send {background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/send_alumni_profile.svg) no-repeat 0 0 / 100%;width: 19px;height: 19px;margin-right: 10px;position: relative;transition: all 0.3s ease-in-out;left: 0;-webkit-transition: all 0.3s ease-in-out;-moz-transition: all 0.3s ease-in-out;-ms-transition: all 0.3s ease-in-out;-o-transition: all 0.3s ease-in-out;}
.alumni_profile_brife_links .btn-send:hover .icon-send {left: 3px;}

.alumni_profile_brife_links > a.btn-push-web {background: #27A7DF;color: #fff;}
.alumni_profile_brife_links > a.btn-push-web:hover{background: #078DC8;}
.alumni_profile_brife_links > a.btn-push-web i.icon-globe {background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/web.svg) no-repeat 0 0 / 100%;width: 19px;height: 19px;margin-right: 10px;position: relative;transition: all 0.3s ease-in-out;left: 0;-webkit-transition: all 0.3s ease-in-out;-moz-transition: all 0.3s ease-in-out;-ms-transition: all 0.3s ease-in-out;-o-transition: all 0.3s ease-in-out;}
.alumni_profile_brife_links .btn-push-web:hover .icon-globe {left: 3px;}

.alumni_profile_brife_links > a.btn-mark {background: #fff;border: 2px solid #0b3454;color: #0b3454;}
.alumni_profile_brife_links > a.btn-mark:hover {background: #0b3454;color: #fff;}

.alumni_profile_detail {padding: 30px 0;}
.alumni_profile_brife_tab .nav {justify-content: center;}
.alumni_profile_brife_tab .nav-item:not(:last-child) {margin-right: 10px;}
.alumni_profile_brife_tab .nav-pills .nav-link {min-width: 100px;height: 40px;white-space: nowrap;border: 2px solid #7D7D7D;border-radius: 25px;background: #fff;color: #000;padding: 0 20px;}
.alumni_profile_brife_tab .nav-pills .nav-link.active, .alumni_profile_brife_tab .nav-pills .nav-link:hover {background: #0B3454;border-color: #0B3454;color: #fff;} */

.underGraduate-detail .main_title,
.blockHeader {
    font-size: 22px;
    color: #000;
    width: auto;
    margin: -40px -40px 25px;
    border-radius: 6px 6px 0px 0px;
    padding: 20px 40px;
    line-height: 22px;
    border-bottom: 1px solid #f5f5f5;
}
.blockHeader .title span {
    font-weight: 600;
}
.graduateRow .college_detail_wrap .college_detail h3 {
    font-size: 20px;
    font-weight: 600;
    margin: 0 0 2px;
    transition: all 0.3s ease-in-out;
}
.graduateRow .college_detail_wrap:hover h3 {
    text-decoration: underline;
    color: #1452ce;
}
.graduateRow .college_detail_wrap .college_detail h3 .icon-subtraction {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/subtraction.svg) no-repeat 0 0 / 100%;
    width: 16px;
    height: 25px;
    margin-left: 3px;
    display: inline-block;
    vertical-align: top;
}
.graduateRow .college_detail_wrap .college_detail p {
    font-size: 16px;
    color: #414141;
    margin: 0 0 5px;
}
.graduateRow {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    position: relative;
}
.graduateRow:not(:last-child) {
    border-bottom: 2px solid #e5e5e5;
    margin-bottom: 28px;
    padding-bottom: 28px;
}
.underGraduate-detail,
.postGraduate-detail {
    box-shadow: 0px 10px 25px #00000008;
    border-radius: 6px;
    background: #fff;
    padding: 40px;
}
.graduateRow .college_detail_wrap {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    width: calc(100% - 265px);
    padding-right: 40px;
    position: relative;
    align-items: center;
}
.graduateRow .college_detail_wrap > a {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}
.graduateRow .college_detail_wrap:after {
    content: '';
    position: absolute;
    top: 13px;
    right: 0;
    width: 2px;
    height: 63px;
    background: #d1d1d1;
}
.graduateRow .offer {
    width: 265px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: center;
}
.graduateRow .college_detail_wrap .college_detail {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
}
.graduateRow .college_detail_wrap .college_detail .college_logo {
    margin-right: 20px;
    width: 150px;
    height: 95px;
    border: 1px solid #d5d5d5;
    border-radius: 6px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
}
.college_name_scholarship {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
.college_name_scholarship .college_name {
    width: calc(100% - 180px);
}
.graduateRow .college_detail_wrap .college_detail .college_logo .logo_link {
    padding: 10px;
    display: inline-flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}
.graduateRow .college_detail_wrap .college_detail .college_logo .logo_link img {
    width: auto;
    height: auto;
    max-width: 100%;
}
.graduateRow .college_detail_wrap .scholarship {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    text-align: center;
    line-height: 20px;
}
.graduateRow .college_detail_wrap .scholarship .scholarship_label {
    font-weight: 500;
    color: #8a8a8a;
}
.graduateRow .college_detail_wrap .scholarship .scholarship_amt {
    font-weight: 600;
    white-space: nowrap;
}
.graduateRow .offer > a,
.graduateRow .offer a.joindeLink {
    padding: 0 20px;
    text-decoration: none;
    height: 37px;
    background: #5cae48;
    color: #fff;
    border-radius: 25px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    transition: all 0.3s ease-in-out;
}
.graduateRow .offer > a:hover,
.graduateRow .offer a.joindeLink:hover {
    background: #3e9329;
}
.graduateRow .offer > a i,
.graduateRow .offer a.joindeLink i {
    margin-right: 10px;
}
.graduateRow .offer > a:hover {
    background: #3b8d27;
}
.graduateRow .offer .got_offer {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 5px;
}
.graduateRow .offer .got_offer i {
    margin-right: 10px;
    font-size: 20px;
    color: #5cae48;
}
.graduateRow .offer .offerLink {
    color: #1452ce;
}
.graduateRow .offer .offerLink:hover {
    color: #0b3454;
}
.graduateRow .offer .offer_joined i {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/hat_alumni.svg);
    width: 23px;
    height: 17px;
    margin-right: 10px;
}
.graduateRow .offer .offer_joined {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
}

.graduateRow.edit .college_detail_wrap {
    width: calc(100% - 350px);
}
.graduateRow .offer.offer_edit {
    flex-direction: row;
    align-items: center;
    width: 350px;
    justify-content: end;
}
.graduateRow .offer.offer_edit .edit_section,
.edit_section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: 20px;
    align-items: center;
}
.edit_section_inner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.graduateRow .offer .edit_link,
.edit_section .edit_link {
    width: 52px;
    height: 30px;
    background: #eaf4fc;
    border-radius: 20px;
    transition: all 0.3s ease-in-out;
    position: relative;
}
.graduateRow .offer .edit_link:hover,
.edit_section .edit_link:hover {
    background-color: #0b3454;
}
.graduateRow .offer .edit_link:before,
.edit_section .edit_link::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/draw.svg) no-repeat center / 18px
        auto;
}
.graduateRow .offer .edit_link:hover:before,
.edit_section .edit_link:hover::before {
    filter: invert(99%) sepia(1%) saturate(2%) hue-rotate(228deg) brightness(110%) contrast(100%);
}
.graduateRow .offer .del_link,
.edit_section .del_link,
.del_link {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/delete.svg);
    width: 14px;
    height: 16px;
    margin-left: 10px;
    opacity: 0.5;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.graduateRow .offer .del_link:hover,
.edit_section .del_link:hover,
.del_link:hover {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/delete_dark.svg);
    opacity: 1;
}
.graduateRow .offer .offer_existing_links {
    display: flex;
    flex-direction: column;
    align-items: end;
}
.form-group .del_link {
    position: absolute;
    top: 15px;
    right: -20px;
}

.graduation-title {
    font-size: 22px;
    font-weight: 400;
    margin: -15px 0 20px;
}
.graduation-title span {
    font-weight: 600;
}

.graduation_carousel_wrap {
    margin-top: 20px;
    box-shadow: 0px 10px 25px #00000008;
    border-radius: 6px;
    background: #fff;
    padding: 40px;
}
.graduation_carousel_wrap .card_profiles:first-child {
    margin-bottom: 18px;
}
.graduation_carousel_wrap .seactionheader {
    margin: -10px 0 27px;
}
.graduation_carousel_wrap .seactionheader h3 {
    font-size: 22px;
    font-weight: 400;
}
.graduation_carousel_wrap .seactionheader h3 span {
    font-weight: 600;
}
.card_profiles {
    border: 1px solid #d2d2d2;
    padding: 20px;
    border-radius: 6px;
    flex-direction: column !important;
    display: flex;
    justify-content: space-between;
    height: 100%;
    position: relative;
    background: #fff;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    overflow-y: auto;
}
.card_profiles::-webkit-scrollbar {
    width: 4px;
}
.card_profiles::-moz-scrollbar {
    width: 4px;
}
.card_profiles::-webkit-scrollbar-track {
    background: #ccc;
}
.card_profiles::-webkit-scrollbar-thumb {
    background-color: #666;
    border-radius: 20px;
    border: 2px solid #666;
}
.card_profiles .alumni-Profile-button-rounded {
    position: absolute;
    right: 20px;
    padding: 0 20px;
}
.card_profiles .alumni-Profile-button-rounded:hover,
.card_profiles .alumni-Profile-button-rounded:focus {
    background: #40952c;
    color: #fff;
    background-color: #40952c;
    border-color: 0;
    box-shadow: none;
}
.card_profiles figure.student {
    width: 90px;
    height: 90px;
    overflow: hidden;
    border-radius: 200px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.card_profiles figure.student img {
    border-radius: 200px;
}
.student_details {
    flex: 1;
}
.card_profiles .student_details figure img {
}
.card_profiles .student_details h2 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 15px;
    width: 65%;
    margin-top: 010px;
}
.card_profiles .student_details h2 a {
    color: #000;
    text-decoration: none;
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
    display: inline-block;
    overflow: hidden;
}
.card_profiles .student_details h2 a:hover {
    text-decoration: underline;
    color: #1452ce;
}
.card_profiles .student_details h2 span {
    display: block;
    font-size: 16px;
    color: #6a6868;
    font-weight: 500;
}
.card_profiles .student_details .sub_details {
    display: flex;
    flex-grow: 1 !important;
}
.card_profiles .student_details .sub_details_workExp {
    align-items: center;
    position: relative;
    padding-bottom: 5px;
}
.card_profiles .student_details .sub_details_achivements figure {
    background: #0b3454 !important;
}
.card_profiles .student_details .sub_details_workExp figure {
    border: 0 !important;
    border-radius: 100px !important;
    min-width: 50px !important;
    height: 50px !important;
    width: 50px !important;
    background-color: #d52941;
}
.card_profiles .student_details .sub_details_workExp figure img {
    width: 45%;
    height: auto;
}
.card_profiles .student_details .sub_details_workExp figure.bg_white {
    background-color: #fff !important;
    border: 1px solid #d2d2d2 !important;
}
.card_profiles .student_details .sub_details_workExp figure.bg_white img {
    width: 80%;
}
.card_profiles .student_details .sub_details_workExp:after {
    position: absolute;
    width: 83%;
    height: 1px;
    content: '';
    background: #c1c1c1;
    bottom: 0;
    right: 0; /* padding-bottom: 20px; */
}
.card_profiles .student_details .sub_details_workExp1:after {
    display: none;
}
.card_profiles .student_details .sub_details h3 {
    margin-bottom: 0;
    min-width: 43px;
    justify-content: center;
}
.card_profiles .student_details .sub_details figure {
    border: 1px solid #d2d2d2;
    border-radius: 100px;
    min-width: 80px;
    height: 80px;
    overflow: hidden;
    margin-right: 10px;
    margin-bottom: 0;
    width: 80px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.card_profiles .student_details .sub_details p {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 5px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 43px;
    overflow: hidden;
    line-height: 18px;
}
.card_profiles .student_details .sub_details a {
    color: #000;
    text-decoration: none;
}
.card_profiles .student_details .sub_details a:hover {
    text-decoration: underline;
    color: #1452ce;
}
.card_profiles .student_details .sub_details p.title,
.card_profiles .student_details .sub_details p.green-title {
    color: #5cae48;
    font-size: 14px;
    margin-bottom: 10px;
}
.card_profiles .student_details .sub_details span {
    color: #333333;
    font-size: 14px;
    margin-bottom: 15px;
    font-weight: 400;
}
.card_profiles .student_details .sub_details span.course {
    color: #0b3454;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    align-items: center;
    margin-bottom: 0;
}
.card_profiles .student_details .sub_details span.course img {
    margin-right: 6px;
    width: 16px;
}
.card_profiles .action_btns {
    margin-top: 20px;
    border-top: 1px solid #d2d2d2;
    padding-top: 20px;
    display: flex;
    justify-content: center !important;
    width: 100%;
}
.card_profiles .action_btns .btn-outline-primary {
    margin-right: 10px;
    border: 2px solid #5bae47;
    border-radius: 50px;
    padding: 7px 20px;
    color: #5bae47;
}
.card_profiles .action_btns .btn-outline-primary:hover img {
    filter: brightness(0) invert(1);
}
.card_profiles .action_btns .btn-outline-primary:hover {
    border: 2px solid #418d2e;
    background-color: #418d2e;
    color: #fff;
}
.card_profiles .action_btns .btn-secondary {
    border-radius: 50px;
    padding: 7px 20px;
    color: #fff;
    background: #0b3454;
}
.card_profiles .action_btns .btn-secondary:hover {
    background: #26a7df;
    border: 1px solid #26a7df;
}
.card_profiles .btn-send {
    width: 123px;
    height: 40px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    background: #5cae48;
    border-radius: 25pc;
    color: #fff;
    text-decoration: none;
    align-items: center;
    padding: 0 25px;
    font-size: 16px;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
}
.card_profiles .btn-send {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    position: absolute;
    top: 20px;
    right: 20px;
}
.card_profiles .btn-send .icon-send {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/send_alumni_profile.svg) no-repeat 0
        0 / 100%;
    width: 19px;
    height: 19px;
    margin-right: 10px;
    position: relative;
    transition: all 0.3s ease-in-out;
    left: 0;
}
.card_profiles .btn-send:hover .icon-send {
    left: 3px;
}
.card_profiles .btn-send:hover {
    background: #3b8d27;
}

.workExperience-detail {
    box-shadow: 0px 10px 25px #00000008;
    border-radius: 6px;
    background: #fff;
    padding: 40px;
}
.workExperience-detail .workExperience-title {
    font-size: 22px;
    font-weight: 400;
    margin: -15px 0 30px;
}
.workExperience-detail .workExperience-title span {
    font-weight: 600;
}
.work-block {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
}
.work-block .work-detail {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: flex;
    flex-direction: column;
}
.work-block .icon {
    width: 55px;
    height: 55px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    margin-right: 20px;
    background: #d52941;
    border-radius: 50%;
    color: #fff;
}
.work-block .work-detail p {
    font-size: 20px;
    line-height: 24px;
    margin: 0 0 5px;
    font-weight: 500;
}
.work-block .work-detail span {
    font-size: 16px;
    color: #6d6b6b;
    line-height: 16px;
    font-weight: 500;
}

.achievements-block {
    box-shadow: 0px 10px 25px #00000008;
    border-radius: 6px;
    background: #fff;
    padding: 30px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    position: relative;
}
.achievements-block:not(:last-child) {
    border-bottom: 1px solid #f5f5f5;
}
.achievements-icon-block {
    padding: 0 55px 0 30px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 185px;
    max-width: 185px;
    align-items: center;
    text-align: center;
    border-right: 1px solid #cecece;
    margin-right: 30px;
}
.achievements-icon-block .icon {
    width: 60px;
    height: 60px;
    background: #0b3454;
    border-radius: 50%;
    margin-bottom: 8px;
    position: relative;
}
.achievements-icon-block .icon.icon-medal:before {
    content: '';
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/exam_ico.svg);
    width: 26px;
    height: 36px;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    margin: auto;
}
.achievements-icon-block .icon.icon-grade:before {
    content: '';
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/exam_ico.svg);
    width: 25px;
    height: 31px;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    margin: auto;
}
.achievements-icon-block .icon.icon-publication:before {
    content: '';
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/publication_ico.svg);
    width: 29px;
    height: 31px;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    margin: auto;
}
.achievements-icon-block .icon.icon-promotion:before {
    content: '';
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/promotion_ico.svg);
    width: 32px;
    height: 37px;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    margin: auto;
}
.achievements-icon-block .icon.icon-course-completion:before {
    content: '';
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/course_ico.svg);
    width: 31px;
    height: 37px;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    margin: auto;
}
.achievements-icon-block .icon.icon-social-service:before {
    content: '';
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/social_ico.svg);
    width: 36px;
    height: 36px;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    margin: auto;
}
.achievements-icon-block .icon.icon-scholarship:before {
    content: '';
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/scholarship_ico.svg);
    width: 39px;
    height: 28px;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    margin: auto;
}
.achievements-icon-block .icon.icon-others:before {
    content: '';
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/others_ico.svg);
    width: 32px;
    height: 32px;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    margin: auto;
}
.achievements-icon-block .achievements-title {
    font-size: 18px;
    font-weight: 600;
}
.achievements-icon-block .achievements-year {
    font-size: 18px;
    line-height: 22px;
    color: #414141;
}
.achievements-block-detail h3 {
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 0px;
}
.achievements-block-detail h3 span {
    font-weight: 600;
}
.achievements-block-detail p {
    font-size: 16px;
    line-height: 24px;
    margin: 0;
}
.achievements-block-detail {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}
.hide {
    display: none;
}
.achievements-block-detail p.show .hide {
    display: inline;
}
.achievements-block-detail p.show .btn-readmore {
    font-size: 0;
}
.achievements-block-detail p.show .btn-readmore:before {
    content: 'Read Less';
    font-size: 16px;
}
.achievements-block-detail p .btn-readmore {
    color: #1452ce;
    transition: all 0.3s ease-in-out;
}
.achievements-block-detail p .btn-readmore:hover {
    color: #1046b3;
}
.achievements-block-detail .url-link {
    color: #1452ce;
    transition: all 0.3s ease-in-out;
}
.achievements-block-detail .url-link:hover {
    color: #1046b3;
}

.achievements-list {
    box-shadow: 0px 10px 25px #00000008;
    border-radius: 6px;
    background: #fff;
}
.achievements-block-title {
    padding: 20px 40px;
    border-bottom: 1px solid #f5f5f5;
    border-radius: 6px 6px 0 0;
}
.achievements-block-title .title {
    font-size: 22px;
}
.achievements-block-title .title span {
    font-weight: 600;
}

.btn-add-section {
    background: #ffffff;
    border: 1px solid #0b3454;
    border-radius: 19px;
    height: 37px;
    display: flex;
    padding: 0 20px;
    align-items: center;
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
    color: #0b3454;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.btn-add-section i {
    position: relative;
    width: 11px;
    height: 110px;
    margin-right: 10px;
}
.btn-add-section i:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #0b3454;
    width: 2px;
    height: 10px;
}
.btn-add-section i:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #0b3454;
    width: 10px;
    height: 2px;
}
.btn-add-section:hover {
    background: #0b3454;
    color: #fff;
}
.btn-add-section:hover i:before,
.btn-add-section:hover i:after {
    background: #fff;
}

.contributions-block {
    box-shadow: 0px 10px 25px #00000008;
    border-radius: 6px;
    background: #fff;
    padding: 40px;
}
.contributions-block:not(:last-child) {
    margin-bottom: 20px;
}
.contributions-block .act_lst:not(:last-child) {
    margin-bottom: 20px;
}
.contributions-block .seactionheader {
    margin: -10px 0 20px;
}
.contributions-block h3 {
    font-size: 22px;
    font-weight: 600;
}
.contributions-block .circle-effect::before {
    background: #6c8598;
}
.circle-effect::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    bottom: 0;
    width: 0;
    background: #08273f;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    right: 0;
    width: 0;
    height: 0;
    margin: auto;
    border-radius: 50%;
}
.circle-effect:hover::before {
    width: 100%;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    height: 100%;
}
.contributions-block .list_badge {
    color: #d52941;
    background: no-repeat;
    position: static;
    text-transform: uppercase;
}
.contributions-block .act_lst {
    padding: 25px 25px 25px 25px;
}
.contributions-block .video_block_name label {
    font-size: 16px;
}

.act_lst {
    border: 1px solid #d2d2d2;
    padding: 40px 25px 25px 25px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.act_lst > .row > div:last-child {
    width: 255px;
}
.act_lst > .row.postedrow > div:last-child,
.act_lst.actlst_listed > .row > div:last-child {
    width: 150px;
}
.list_badge {
    position: absolute;
    width: auto;
    height: 25px;
    line-height: 25px;
    color: #fff;
    background: #d42941;
    text-align: center;
    border-radius: 6px 0px 10px 0px;
    left: 0;
    top: 0;
    -webkit-border-radius: 6px 0px 10px 0px;
    -moz-border-radius: 6px 0px 10px 0px;
    -ms-border-radius: 6px 0px 10px 0px;
    -o-border-radius: 6px 0px 10px 0px;
    padding: 0 10px;
}
.exp_loc > a.btn {
    display: inline-block;
    border: 1px solid #dedede;
    height: 32px;
    line-height: 32px;
    padding: 0 16px;
    border-radius: 18px;
    font-size: 14px;
    font-weight: 500;
    color: #0b3454;
    margin-bottom: 10px;
    background: #fff;
    overflow: hidden;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    -ms-border-radius: 18px;
    -o-border-radius: 18px;
}
.exp_loc > a.btn:not(:last-child) {
    margin-right: 5px;
}
.exp_loc > a .btn > img {
    margin: -3px 8px 0 0;
}
.exp_loc > a.btn > span.icon,
.exp_loc > a.btn > img {
    margin: -3px 8px 0 0;
}
.person_detls {
    margin-top: 10px;
}

.listblk_icon {
    position: relative;
    border: 1px solid #dedede;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.listblk_icon > span.icon,
.album_shareblk > a.btn.btnicon span.icon {
    color: rgba(11, 52, 84, 0.4);
    margin: 0;
}
.act_lst > .row > div:last-child .btn-green {
    width: 150px;
}
.act_lst .btn-green {
    padding: 0 20px;
}

.seactionheader {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    flex-wrap: wrap;
}
.countnbtn {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.seactionheader h3 {
    font-size: 24px;
    margin: 0;
}
.countnbtn .count {
    margin-right: 20px;
    font-style: italic;
    font-size: 14px;
    color: #0b3454;
}
.custom-btns a {
    display: inline-block;
    color: #0b3454;
    text-decoration: none;
}
.custom-btns a:hover {
    color: #d42941;
}

.album_imgblk img {
    width: 100%;
    height: auto;
}
.album_blk {
    border: 1px solid #d2d2d2;
    border-radius: 6px;
}
.album_badge {
    background: #d42941;
    padding: 0 20px;
    height: 32px;
    line-height: 32px;
    border-radius: 6px 0 15px 0;
}
.album_imgblk {
    border-radius: 6px 6px 0 0;
    overflow: hidden;
}
.album_contblk {
    background: #fff;
    border-radius: 6px;
    width: calc(100% - 20px);
    margin: -40px auto 0;
    position: relative;
    padding: 20px 20px 15px;
}

.album_shareblk > span,
.album_shareblk > a.btn.btnicon {
    width: 40px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    height: 40px;
    line-height: 38px;
    border: 1px solid #dedede;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    padding: 0;
}
.album_shareblk > span:not(:last-child),
.album_shareblk > a.btn:not(:last-child) {
    margin: 0 0 5px;
}
.albumblk_footer {
    background: #f2f9ff;
    padding: 13px 30px;
    border-radius: 0 0 6px 6px;
}
.albumblk_footer img {
    margin: 0 6px 0 0;
}
.album_blk_parent {
    margin: 0 0 20px 0;
}
.album_imgvdo {
    right: 10px;
    top: 10px;
}
.image_tn {
    margin: 0 0 5px 0;
}
.image_tn > a {
    width: 63px;
    height: 30px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 16px;
    color: #e1e1e1;
    font-size: 13px;
    text-decoration: none;
}
.image_tn > a img.gallicon {
    width: 14px;
    height: auto;
    margin: 0 3px 0 0;
}
img.custimg {
    width: auto;
    display: block;
    margin: 0 auto;
}
.custom_litezoom:before {
    content: '';
    position: absolute;
    top: 100%;
    left: 10px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #fff;
}
.album_imgvdo {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
}
.image_tn:first-child {
    margin-right: 5px;
}
.image_tn {
    margin-bottom: 3px;
}
.table-responsive.attendee_dtl td .custom_litezoom a {
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
    text-decoration: none !important;
    color: #000;
    font-weight: 500;
    display: flex;
    align-items: center;
    border: 1px solid #cecece;
    border-radius: 26px;
    -webkit-border-radius: 26px;
    -moz-border-radius: 26px;
    -ms-border-radius: 26px;
    -o-border-radius: 26px;
    padding: 0 10px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    width: auto;
    background: none;
}
.custom_litezoom a:hover {
    color: #0b3454;
}
.table-responsive.attendee_dtl td .custom_litezoom a .icon-phone {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/image.svg) no-repeat 0 0;
    width: 16px;
    height: 15px;
    margin-right: 7px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.table-responsive.attendee_dtl td .custom_litezoom a .icon-video {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/video-camera.svg) no-repeat 0 0;
    width: 16px;
    height: 9px;
    margin-right: 7px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.custom_litezoom a:hover {
    background: #0c9ccc;
    border-color: #0c9ccc;
    color: #fff;
}
.custom_litezoom a:hover i {
    filter: brightness(0) invert(1);
    -webkit-filter: brightness(0) invert(1);
}

.skills-block {
    box-shadow: 0px 10px 25px #00000008;
    border-radius: 6px;
    background: #fff;
    padding: 40px;
    position: relative;
}
.skills-block:first-child {
    padding-bottom: 20px;
}
.skills-block.EntranceExam-skills-block {
    padding-bottom: 33px;
}
.skills-block:not(:last-child) {
    margin-bottom: 20px;
}
.skills-block h2 {
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 15px;
    margin: -10px 0 25px;
}
.skills-block h2 span {
    font-weight: 600;
}
.skills-block-detail {
    display: flex;
    flex-wrap: wrap;
}
.skills-block-detail .meter {
    margin: 0 12px 20px 0;
    border: 1px solid #c7c7c7;
    padding: 10px 30px;
    border-radius: 50px;
    font-size: 20px;
    font-weight: 500;
    align-items: center;
    height: 55px;
}
.skills-block-detail .meter img {
    width: 50px;
}
.skills-block-title {
    margin-top: -30px;
    margin-bottom: 20px;
}

.preferences-block {
    box-shadow: 0px 10px 25px #00000008;
    border-radius: 6px;
    background: #fff;
    padding: 40px;
}
.preferences-block:not(:last-child) {
    margin-bottom: 20px;
}
.preferences-block.EntranceExam-skills-block {
    padding-bottom: 33px;
}
.preferences-block h2 {
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 15px;
    margin: -10px 0 25px;
}
.preferences-block h2 span {
    font-weight: 600;
}
.preferences-block-detail {
    display: flex;
    flex-wrap: wrap;
}
.preferences-block-detail .meter {
    margin: 0 12px 20px 0;
    border: 1px solid #c7c7c7;
    padding: 10px 30px;
    border-radius: 50px;
    font-size: 20px;
    font-weight: 500;
    align-items: center;
}
.preferences-block-detail .meter img {
    width: 50px;
}
.preferences-block-title {
    margin-top: -30px;
    margin-bottom: 20px;
}

.interested-block .icon {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background: #0b3454;
    position: relative;
    margin-right: 20px;
}
.interested-block .icon.icon-bulb::before {
    content: '';
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/bulb.svg) no-repeat 0 0 / 34px auto;
    width: 34px;
    height: 36px;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    margin: auto;
}
.interested-block .icon.icon-note::before {
    content: '';
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/publication_ico.svg);
    width: 29px;
    height: 31px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}
.interested-block {
    display: flex;
    align-items: center;
}
.interested-block .interested-topics {
    font-size: 20px;
    flex: 1;
    line-height: 22px;
}
.interested-block .interested-topics span {
    font-weight: 500;
}
.meter {
    background: #ffffff;
    border: 1px solid #dedede;
    border-radius: 18px;
    opacity: 1;
    padding: 5px 15px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    margin-right: 5px;
    letter-spacing: 0.32px;
}
.meter:hover {
    box-shadow: 0px 0px 20px #00000033;
}
.meter:not(:last-child) {
    margin-bottom: 5px;
}
.graduationslider .meter {
    padding: 10px 30px;
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    border-radius: 50px;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
}

.profile-item {
    text-align: center;
    width: 196px;
    height: 210px;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
}
.owl-item:not(:last-child) .profile-item {
    border-right: 1px solid #d2d2d2;
}
.profile-item-img {
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 11px;
}
.profile-item-name-class h3 {
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 4px;
}
.profile-item-name-class p {
    margin: 0 0 11px;
    font-size: 14px;
    font-style: italic;
    color: #535353;
}
.profile-item-name-class .subject {
    color: #0b3454;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    position: relative;
    padding-top: 10px;
}
.profile-item-name-class .subject:before {
    content: '';
    width: 30px;
    height: 2px;
    background: #5cae48;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
}
.profile-item-name-class .btn-viewcompus {
    font-size: 13px;
    color: #1452ce;
    font-weight: 500;
    line-height: 18px;
    position: relative;
    z-index: 2;
    text-decoration: none;
}
.profile-item-name-class .btn-viewcompus:hover {
    color: #0b3454;
    text-decoration: underline;
}

.psychology-item {
    text-align: center;
    width: 245px;
    height: 196px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 10px;
}
.owl-item:not(:last-child) .psychology-item {
    border-right: 1px solid #d2d2d2;
}
.popularCollegesslider .owl-item.active + .owl-item.active + .owl-item.active + .owl-item.active .psychology-item {
    border-right: 0px solid #d2d2d2;
}
.psychology-item-img {
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    height: 90px;
    align-items: center;
}
.psychology-item-name h3 {
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 5px;
    line-height: 17px;
}
.psychology-item-name p {
    font-size: 14px;
    font-style: italic;
    font-weight: 500;
    line-height: 20px;
}

.alumni_directory_details .btn-working {
    font-size: 13px;
    color: #1452ce;
    font-weight: 500;
    line-height: 18px;
    position: relative;
    z-index: 2;
    text-decoration: none;
}
.alumni_directory_details .btn-working:hover {
    color: #0b3454;
    text-decoration: underline;
}

.tooltip-viewcompus .tooltip-inner {
    background: #3b3b3b;
    border-color: #3b3b3b;
}
.tooltip-working .tooltip-inner {
    display: flex;
    flex-direction: column;
    padding: 15px;
    background: #3b3b3b;
    border-color: #3b3b3b;
}
.tooltip-working .tooltip-inner .logo {
    position: relative;
    font-size: 20px;
}
.tooltip-working .tooltip-inner span.logo + span {
    font-size: 16px;
}
.tooltip-working.bs-tooltip-top .tooltip-arrow::before {
    border-top-color: #3b3b3b;
}
.tooltip-working.bs-tooltip-bottom .tooltip-arrow::before {
    border-bottom-color: #3b3b3b;
}
.tooltip-working.bs-tooltip-end .tooltip-arrow::before {
    border-right-color: #3b3b3b;
}
.tooltip-working.bs-tooltip-start .tooltip-arrow::before {
    border-left-color: #3b3b3b;
}

.alumni_name_brife > div {
    align-items: center;
}
.alumni_name_brife > div .sep {
    margin: 0 6px;
    color: #1452ce;
}

.custom_badge {
    display: inline-block;
    border: 1px solid #dedede;
    height: 32px;
    line-height: 32px;
    padding: 0 16px;
    border-radius: 18px;
    font-size: 14px;
    font-weight: 500;
    color: #0b3454;
    margin: 0 5px 10px 0;
    background: #fff;
    overflow: hidden;
}
.custom_badge .icon {
    margin-right: 5px;
}

.delete_confirm .icon-cross {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid red;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #e94e58;
}
.delete_confirm .del_text {
    margin: 20px 0 10px;
    text-align: center;
    max-width: 240px;
    font-size: 28px;
    line-height: 36px;
    font-weight: 500;
    width: 100%;
}
.delete_confirm .btn-delete {
    background: #d52941;
    border-radius: 31px;
    border: 0;
    height: 36px;
    color: #fff;
    padding: 0 20px;
    font-weight: 600;
    font-size: 14px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.delete_confirm .btn-delete:hover {
    background: #a0182b;
}

.selected_options ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}
.selected_options ul li {
    background: #f7fbff;
    border: 1px solid #0b34544d;
    border-radius: 18px;
    height: 35px;
    display: flex;
    align-items: center;
    padding: 0 18px;
    margin: 12px 12px 6px 0;
    font-size: 16px;
    font-weight: 500;
}
.selected_options ul li .icon-cross {
    font-size: 9px;
    margin: 2px 0 0 10px;
    text-decoration: none;
    color: #000;
}
.selected_options ul li .icon-cross:hover {
    text-decoration: none;
    color: #333;
}

.question .form-select.w-90 {
    width: 90px !important;
    max-width: 90px;
}

.custom_row_title {
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 20px;
}
.custom_row_title span {
    font-weight: 600;
}
.custom_row_block .program {
    font-size: 20px;
}
.custom_row_block .program span {
    font-weight: 500;
}
.program-time-interest {
    color: #414141;
    font-size: 16px;
    line-height: 20px;
}
.custom_row_block {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.custom_row_block .icon {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background: #0b3454;
    margin-right: 15px;
    position: relative;
}
.custom_row_block .icon.icon-hat:before {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/hat_alumni.svg) no-repeat 0 0 /26px;
    width: 26px;
    height: 20px;
    display: inline-block;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    position: absolute;
    filter: invert(1);
    -webkit-filter: invert(1);
}
.custom_row_block .icon + div {
    width: calc(100% - 70px);
}
.custom_row {
    width: 100%;
    border-top: 1px solid #dbdbdb;
    padding-top: 20px;
}
.custom_row:not(:last-child) {
    padding-bottom: 20px;
}

.radius_block {
    background: #ffffff;
    border: 1px solid #c7c7c7;
    border-radius: 30px;
    height: 60px;
    padding: 0 30px;
    display: flex;
    align-items: center;
    font-size: 20px;
    line-height: 20px;
    font-weight: 500;
}
.radius_block span {
    margin-right: 30px;
}
.radius_block i {
    font-size: 24px;
}
.radius_block i.gray {
    color: #8599a9;
}
.radius_block i.green {
    color: #5dae48;
}

.radius_block.yes {
    background: #5dae48;
    border-color: #5dae48;
    color: #fff;
}
.radius_block.yes span {
    margin: 0 20px;
}

.radius_block.no {
    background: #c7c7c7;
    color: #333;
}
.radius_block.no span {
    margin: 0 20px;
}

.universities_block {
    background: #ffffff;
    border: 1px solid #c7c7c7;
    border-radius: 30px;
    height: 60px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
}
.universities_block .logo {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    border-radius: 50%;
    overflow: hidden;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border: 1px solid #ccc;
}
.universities_block .logo img {
	width: auto;
	height: 100%;
}
.universities_block span {
    font-size: 20px;
    margin-right: 15px;
    line-height: 20px;
}
.logo.no_logo {
    width: 40px;
    border: 1px solid #cad6df;
}
.edit_section {
    font-size: 0;
}
.edit_section_toggle,
.custom-btns a.edit_section_toggle {
    display: none;
    width: 20px;
    height: 20px;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='%23343a40' stroke='none' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px 20px;
}

.video_block {
    background: #ffffff;
    border: 1px solid #d2d2d2;
    border-radius: 6px;
    overflow: hidden;
    text-decoration: none;
    position: relative;
    display: inline-block;
    color: #000;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.icon-invert-play {
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.video_block:hover,
.video_block:hover .icon-invert-play {
    color: #5cae48;
}
.video_block_name {
    background: #ffffff;
    border-radius: 6px 6px 0px 0px;
    opacity: 1;
    display: flex;
    justify-content: space-between;
    padding: 0px 20px;
    margin: -26px 11px 0;
    position: relative;
    align-items: center;
    height: 78px;
}
.video_block:hover .video_block_img img {
    transform: scale(1.1);
}
.video_block_img {
    overflow: hidden;
    display: inline-block;
}
.video_block_img img {
    border-radius: 6px 6px 0 0;
    transition: all 0.5s ease-in-out;
}
.video_block_name .icon-invert-play {
    font-size: 30px;
    color: #0b3454;
}
.video_block_name label {
    font-size: 18px;
    font-weight: 600;
    margin-right: 15px;
    line-height: 22px;
}
.video_block .video-btn {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}
#video_pop button.btn-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #fff !important;
    opacity: 1;
    z-index: 999;
    background-size: 40%;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 0;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
#video_pop button.btn-close:hover {
    background-color: #5cae48 !important;
}
#video_pop button.btn-close span {
    display: none;
}

.half-gray {
    position: relative;
}
.half-gray:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    width: 50.5%;
    background: #f3f3f3;
    border-radius: 6px 0px 0px 6px;
}
.half-gray > div {
    position: relative;
}

.messages {
    flex: 1;
    display: flex;
    flex-direction: column;
}
.leftUser {
    align-items: end;
}
.leftUser p {
    background: #2296c9;
    border-radius: 10px 10px 0px 10px;
    padding: 7px 20px;
    color: #fff;
    margin-bottom: 10px;
}
.rightUser {
    align-items: start;
}
.rightUser p {
    background: #413a7d;
    border-radius: 10px 10px 10px 0px;
    opacity: 1;
    padding: 7px 20px;
    color: #fff;
    margin-bottom: 10px;
}
.conversation .userimg {
    display: flex;
    align-items: start;
    vertical-align: top;
}
.conversation {
    height: 310px;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-right: -8px;
}

.conversation {
    overflow-y: scroll;
    scrollbar-width: thin;
}
.conversation::-webkit-scrollbar {
    width: 4px;
}
.conversation::-webkit-scrollbar-track {
    background: #f1f1f1;
}
.conversation::-webkit-scrollbar-thumb {
    background: #888;
}
.conversation::-webkit-scrollbar-thumb:hover {
    background: #555;
}

@media (max-width: 1199px) {
    .alumni_profile_name_college_other_detail_wrap.directory-detail .alumni_profile_image_name_other_detail {
        padding-right: 30px;
    }
    .alumni_profile_name_college_other_detail_wrap.directory-detail .alumni_profile_college_name_other_detail_wrap {
        padding-left: 30px;
    }
    .alumni_profile_name_college_other_detail_wrap.directory-detail
        .alumni_profile_college_name_other_detail_wrap
        .alumni_profile_college_name_other_detail {
        flex-direction: column;
    }
    .alumni_profile_name_college_other_detail_wrap.directory-detail
        .alumni_profile_college_name_other_detail_wrap
        .alumni_profile_college_name_other_detail
        .alumni_profile_college_name_detail {
        padding: 0 0 20px;
    }
    .alumni_profile_name_college_other_detail_wrap.directory-detail
        .alumni_profile_college_name_other_detail_wrap
        .alumni_profile_college_name_other_detail
        .alumni_profile_other_detail {
        width: 100%;
    }
    .alumni_profile_name_college_other_detail_wrap.directory-detail
        .alumni_profile_college_name_other_detail_wrap
        .alumni_profile_college_name_other_detail
        .alumni_profile_other_detail
        .alumni_profile_status {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-wrap: wrap;
        -moz-box-wrap: wrap;
        -ms-flex-wrap: wrap;
        -webkit-wrap: wrap;
        flex-wrap: wrap;
    }
    .alumni_profile_name_college_other_detail_wrap.directory-detail
        .alumni_profile_college_name_other_detail_wrap
        .alumni_profile_college_name_other_detail
        .alumni_profile_other_detail
        .alumni_profile_status
        li {
        width: 100%;
    }
    .alumni_profile_name_college_other_detail_wrap.directory-detail
        .alumni_profile_college_name_other_detail_wrap
        .alumni_profile_college_name_other_detail
        .alumni_profile_other_detail
        .alumni_profile_status
        li
        p {
        margin: 0;
    }
}

@media (max-width: 1150px) {
    .aldir_scroll {
        width: 100%;
        overflow-x: auto;
        scrollbar-width: thin;
    }
    .aldir_scroll::-webkit-scrollbar {
        height: 4px;
    }
    .aldir_scroll::-moz-scrollbar {
        height: 4px;
    }
    .aldir_scroll::-webkit-scrollbar-track {
        background: #e372848f;
    }
    .aldir_scroll::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 20px;
        border: 2px solid #810d20;
    }
}

@media (max-width: 1130px) {
    .alumni_profile_brife_tab {
        overflow: auto;
        scrollbar-width: thin;
        padding-bottom: 10px;
        margin-bottom: 10px;
    }
    .alumni_profile_brife_tab::-webkit-scrollbar {
        height: 4px;
    }
    .alumni_profile_brife_tab::-moz-scrollbar {
        height: 4px;
    }
    .alumni_profile_brife_tab::-webkit-scrollbar-track {
        background: #ccc;
    }
    .alumni_profile_brife_tab::-webkit-scrollbar-thumb {
        background-color: #666;
        border-radius: 20px;
        border: 2px solid #666;
    }
    .alumni_profile_brife_tab .nav {
        justify-content: start;
        flex-wrap: nowrap;
    }
}

@media (max-width: 991px) {
    .alumni-directorySearch {
        width: 230px;
    }
}

@media (max-width: 875px) {
    .graduateRow {
        flex-direction: column;
    }
    .graduateRow .college_detail_wrap {
        width: 100%;
    }
    .graduateRow .offer {
        width: 100%;
    }
    .college_name_scholarship {
        flex-direction: row;
        align-items: start;
    }
    .graduateRow .college_detail_wrap {
        width: 100%;
        padding: 0 0 20px;
        margin-bottom: 20px;
    }
    .graduateRow .college_detail_wrap::after {
        top: auto;
        right: 0;
        width: 75px;
        height: 2px;
        bottom: 0;
    }
    .graduateRow .offer {
        padding: 0;
        align-items: start;
    }
    .graduateRow .college_detail_wrap::after {
        right: auto;
        bottom: 0;
        left: 0;
    }

    .graduateRow.edit .college_detail_wrap {
        width: 100%;
    }
    .graduateRow .offer.offer_edit {
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: start;
    }
    .graduateRow .offer .offer_existing_links {
        align-items: start;
    }
}

@media (max-width: 820px) {
    .graduation_carousel_wrap {
        padding: 20px;
    }
    .graduationslider .meter {
        padding: 10px 15px;
        margin: 15px 0 10px 0px;
        font-size: 16px;
    }
    .postGraduate-detail {
        padding: 0;
        background: none;
        box-shadow: none;
        border: 1px solid #d2d2d2;
        overflow: hidden;
    }
    .postGraduate-detail .graduateRow {
        padding: 20px;
        background: #fff;
    }
    .graduation-title {
        margin: 0;
        background: #eff8ff;
        border-radius: 6px 6px 0px 0px;
        padding: 20px;
        text-align: center;
        line-height: 22px;
    }
    .graduation_carousel_wrap .seactionheader {
        margin: 0 0 15px;
        flex-direction: column;
        align-items: inherit;
    }
    .graduation_carousel_wrap .seactionheader .countnbtn {
        width: 100%;
        margin-top: 13px;
        justify-content: space-between;
    }
    .graduation_carousel_wrap .seactionheader h3 {
        width: auto;
        margin: -20px -20px 0;
        background: #eff8ff;
        border-radius: 6px 6px 0px 0px;
        padding: 20px;
        text-align: center;
        line-height: 22px;
    }
    .workExperience-detail .workExperience-title {
        width: auto;
        margin: 0;
        background: #eff8ff;
        border-radius: 6px 6px 0px 0px;
        padding: 20px;
        text-align: center;
        line-height: 22px;
    }
    .workExperience-detail {
        padding: 0px;
        overflow: hidden;
    }
    .workExperienceRow {
        padding: 25px;
    }
    .skills-block-title {
        margin: 0 0 20px;
        width: auto;
        margin: -40px -40px 20px;
        background: #eff8ff;
        border-radius: 6px 6px 0px 0px;
        padding: 20px;
        text-align: center;
        line-height: 22px;
    }
    .skills-block-title h2 {
        background: no-repeat;
        padding: 0;
        text-align: left;
    }
    .preferences-block {
        padding: 30px;
    }
    .preferences-block-title {
        width: auto;
        margin: -30px -30px 0px;
        background: #eff8ff;
        border-radius: 6px 6px 0px 0px;
        padding: 20px;
        line-height: 22px;
    }
    .custom_row:first-child {
        border: 0;
    }
    .achievements-block-title {
        background: #eff8ff;
    }
    .underGraduate-detail .main_title,
    .blockHeader {
        background: #eff8ff;
    }
    .graduateRow {
        background: #fff;
    }
    .postGraduate-detail .blockHeader {
        margin: 0;
    }
    .workExperience-detail .blockHeader {
        margin: 0;
    }
}

@media (min-width: 767px) and (max-width: 1099px) {
    .alumni_directory_filter .fiterItem.w-20 {
        width: calc(33.33% - 10px);
    }
    .alumni_directory_filter .fiterItem.w-40 {
        width: calc(50% - 10px);
    }
    .alumni_directory_filter #additionalfilter .fiterItem.w-20 {
        width: calc(33.3% - 10px);
    }
    .alumni_directory_filter .fiterItem.width-25 {
        width: calc(33.3% - 10px);
    }
    .alumni_directory_filter .fiterItem.width-50 {
        width: calc(50% - 10px);
    }
    .alumni_directory_filter #additionalfilter .fiterItem.w-40 {
        width: calc(33.3% - 10px);
    }
}

@media (max-width: 767px) {
    .filters {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        width: 100%;
        bottom: 0;
        background: #fff;
        z-index: 99;
        max-width: 100%;
        overflow-x: scroll;
        padding: 40px 20px;
        display: block !important;
        -moz-transform: translate3d(0, 0, 0);
        -webkit-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        height: 100%;
    }
    .filters.show {
        -moz-transform: translate3d(0, -100%, 0);
        -webkit-transform: translate3d(0, -100%, 0);
        -o-transform: translate3d(0, -100%, 0);
        -ms-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }
    .filters .btn-filter {
        position: absolute;
        right: 25px;
        font-size: 0;
        background: none;
        border: 0;
        width: 20px;
        height: 20px;
        top: 25px;
    }
    .filters .btn-filter::before {
        transform: translateY(10px) rotate(135deg);
        -moz-transform: translateY(10px) rotate(135deg);
        -webkit-transform: translateY(-5px) rotate(135deg);
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        width: 18px;
        height: 2px;
        content: '';
        background: #000;
        position: absolute;
        border-radius: 5px;
    }
    .filters .btn-filter::after {
        transform: translateY(-5px) rotate(-135deg);
        -moz-transform: translateY(-5px) rotate(-135deg);
        -webkit-transform: translateY(-5px) rotate(-135deg);
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        width: 18px;
        height: 2px;
        content: '';
        background: #000;
        position: absolute;
        border-radius: 5px;
    }
    .filters #accordionFilters {
        margin-bottom: 30px;
    }
    .filters > h3 {
        display: flex !important;
        align-items: center;
        justify-content: space-between;
    }
    /* .mobileFilter .btn-filter {border: 0;background: url(../images/filter.svg) no-repeat center right;padding-right: 0px;padding-left: 0;cursor: pointer;} */
    .mobileFilter .btn-filter {
        border: 0;
        padding-right: 0px;
        padding-left: 0;
        cursor: pointer;
    }
    .filters {
        max-width: 100%;
        width: 100%;
    }
    .ji_filtersec {
        max-width: unset;
    }
    
    .alumni_directory_filter {
        padding: 18px 0;
        padding-right: var(--bs-gutter-x, 0.75rem);
        padding-left: var(--bs-gutter-x, 0.75rem);
    }
    body[style*='overflow: hidden'] .alumni_directory_filter{display: none;}
    .alumni-directory-header_search {
        flex-direction: column;
        align-items: start;
    }
    .alumni-directory-header_search h2 {
        margin-bottom: 15px !important;
    }
    .alumni-directory-header {
        align-items: end;
    }
    .filter_result_title {
        flex-direction: column;
    }
    .filter_result_title .filter_result {
        display: none;
    }
    .alumni_count {
        text-align: right;
        margin: 0;
    }
    .alumni_directory_filter .fiterItem {
        width: 170px !important;
        margin: 0 !important;
        box-shadow: none;
        border: 0;
        border-radius: 0;
        border-bottom: 1px solid #e6e6e6 !important;
    }
    .alumni_directory_filter .filters {
        padding: 0;
        display: flex !important;
        flex-direction: column;
    }
    .alumni_directory_filter .filters > div:first-child {
        padding: 15px 20px;
        border-bottom: 1px solid #dbdbdb;
    }
    .alumni_directory_filter .filters-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: auto;
        padding: 0 15px;
        box-shadow: 0px 0px 3px 0px #ccc;
        position: relative;
        z-index: 1;
    }
    .alumni_directory_filter .filters-head + #accordionFilters {
        flex: 1 1;
        position: relative;
        background: #f5f5f5;
    }
    .alumni_directory_filter .filters #accordionFilters {
        -webkit-box-flex: 1;
        -moz-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        margin: 0;
        position: relative;
    }
    .alumni_directory_filter .filters #accordionFilters::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 170px;
        background: #f5f5f5;
    }
    .alumni_directory_filter .filters > #accordionFilters > .filterGroup.mt-3 {
        margin: 0 !important;
        flex-direction: column;
        height: 100%;
        position: relative;
    }
    .filterItemWrap {
        display: none;
    }
    .additionalfilter_count {
        padding: 0;
        flex: none;
    }
    .alumni_directory_filter .filterHeader button .font-16.font-600 {
        font-size: 14px !important;
    }
    .alumni_directory_filter .fiterItem .filterHeader > div {
        border-radius: 0;
    }
    .alumni_directory_filter .fiterItem .filterHeader > div button {
        background: #f5f5f5;
        border-radius: 0;
        padding: 10px;
        color: #000;
    }
    .alumni_directory_filter .fiterItem .filterHeader > div button .toggle {
        display: none;
    }
    .alumni_directory_filter .fiterItem .filterHeader > div button .font-18.font-600 {
        font-size: 16px !important;
        font-weight: 500 !important;
    }
    .additionalfilter_wrap {
        width: 170px;
        padding: 0;
        margin: 0 !important;
    }
    .btn-additionalfilter {
        display: none;
    }
    .additionalfilter_wrap > #additionalfilter {
        display: block !important;
    }
    .alumni_directory_filter #additionalfilter .fiterItem,
    .alumni_directory_filter #additionalfilter .fiterItem.campus {
        width: 100%;
    }
    .alumni_directory_filter .fiterItem .filterHeader + div {
        position: absolute;
        right: 0;
        top: 0;
        padding: 0;
        bottom: 0;
        width: calc(100% - 170px);
        padding: 15px;
        overflow: auto;
    }
    .alumni_directory_filter .filterGroup {
        margin: 0 !important;
    }
    .alumni_directory_filter .filterGroup.mt-3 {
        margin: 0 !important;
    }
    .filter_close_apply {
        background: #ffffff;
        border-top: 1px solid #e9e9e9;
        padding: 15px 20px;
        justify-content: center;
        align-items: center;
    }
    .filter_close_apply > a.btn-filter-apply {
        padding: 8px 30px;
        background: #5cae48;
        border-radius: 3px;
        text-decoration: none;
        color: #fff;
        margin: 0 15px;
        font-weight: 500;
        text-transform: uppercase;
        transition: all 0.3s ease-in-out;
    }
    .filter_close_apply > a.btn-filter-apply:hover {
        background: #448e32;
        color: #fff;
    }
    .filter_close_apply > a.btn-filter-close {
        text-decoration: none;
        text-transform: uppercase;
        color: #979797;
        font-weight: 500;
        transition: all 0.3s ease-in-out;
        margin: 0 15px;
    }
    .filter_close_apply > a.btn-filter-close:hover {
        color: #666262;
    }
    .alumni_directory_filter .showProfiles_right {
        margin-left: 12px;
    }
    .alumni_directory_filter .filterSearchblk {
        background: #fff;
        padding: 0 0 10px;
    }
    .alumni_directory_filter .fiterItem .card {
        padding: 0px 0px 15px;
    }
    .alumni_directory_filter .fiterItem .filterHeader > div button[aria-expanded='true'] {
        pointer-events: none;
        color: #1452ce;
    }
    .alumni_directory_filter .scrollMe {
        max-height: initial;
        min-height: initial;
    }

    .alumni_directory_filter .mobileFilter .btn-filter .icon-filter + span {
        -webkit-box-flex: 1;
        -moz-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        text-align: left;
    }
    .alumni_directory_filter .mobileFilter .btn-filter .icon-filter + span + span {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-wrap: wrap;
        -moz-box-wrap: wrap;
        -ms-flex-wrap: wrap;
        -webkit-wrap: wrap;
        flex-wrap: wrap;
        align-items: center;
        color: #b92940;
        font-size: 14px;
    }
    .alumni_directory_filter .mobileFilter .btn-filter {
        display: flex;
        align-items: center;
    }
    .alumni_directory_filter .mobileFilter .btn-filter .icon-filter + span + span .icon-right-arrow {
        font-size: 15px;
        transform: rotate(270deg);
        margin-left: 10px;
        color: #000;
        transition: all 0.3s ease-in-out;
    }
    .alumni_directory_filter .mobileFilter .btn-filter[aria-expanded='false'] .icon-right-arrow {
        transform: rotate(90deg) !important;
    }
    #filter_collapse h3 {
        pointer-events: none;
        margin: 20px 0 0 0;
    }
    .alumni_directory_filter {
        background: #f3f3f3;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 99;
        box-shadow: 0 0 5px 0px #cacaca;
    }
    .alumni_directory_filter .filters.show {
        -moz-transform: translate3d(0, 100%, 0);
        -webkit-transform: translate3d(0, 100%, 0);
        -o-transform: translate3d(0, 100%, 0);
        -ms-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
    .alumni_profile_image_name_other_detail {
        width: 100%;
        padding-bottom: 30px;
        margin-bottom: 30px;
    }
    .alumni_profile_college_name_other_detail_wrap .alumni_profile_college_name_other_detail {
        flex-direction: column;
    }
    .nolisting_wrap .nolisting {
        border: 0;
        padding: 20px 0 10px;
    }
    .nolisting_text {
        text-align: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .edit_section {
        position: absolute;
        right: 20px;
        top: 5px;
    }
    .countnbtn .edit_section {
        position: relative;
        left: auto;
        right: auto;
        top: 0;
        margin-left: 5px;
    }
    .skills-block .edit_section {
        top: 22px;
    }
    .edit_section .edit_section_toggle {
        display: inline-block;
    }
    .custom-btns a.edit_section_toggle {
        display: inline-block;
    }
    .edit_section .edit_section_inner {
        position: absolute;
        top: 50px;
        right: 0;
        background: #fff;
        box-shadow: 0px 0px 5px #57555533;
        display: flex;
        flex-direction: column;
        width: 100px;
        border-radius: 6px;
        overflow: hidden;
        opacity: 0;
        z-index: -1;
        transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
    }
    .edit_section.open .edit_section_inner {
        top: 30px;
        opacity: 1;
        z-index: 9;
    }
    .edit_section .edit_section_inner a {
        width: 100% !important;
        border-radius: 0 !important;
        padding: 0 0 0 30px;
        font-size: 14px;
        text-decoration: none;
        color: #000;
        line-height: 33px;
        height: 35px !important;
        background: #fff !important;
        position: relative;
        opacity: 1;
        margin: 0 !important;
        -webkit-border-radius: 0 !important;
        -moz-border-radius: 0 !important;
        -ms-border-radius: 0 !important;
        -o-border-radius: 0 !important;
        opacity: 1 !important;
    }
    .edit_section .edit_section_inner a:hover {
        color: #fff;
        background-color: #0b3454 !important;
    }
    .edit_section .edit_section_inner a.edit_link:before {
        width: 16px;
        height: 16px;
        margin: 0;
        background-size: 14px;
        left: 7px;
        top: 8px;
    }
    .edit_section .edit_section_inner a:first-child {
        border-bottom: 1px solid #eee;
    }
    .edit_section .edit_section_inner a.del_link:before {
        content: '';
        background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/delete.svg) no-repeat center /
            14px auto;
        width: 14px;
        height: 16px;
        position: absolute;
        left: 8px;
        top: 8px;
    }
    .edit_section .edit_section_inner a.del_link:hover {
        background: #0b3454;
        color: #fff;
    }
    .edit_section .edit_section_inner a.del_link:hover:before {
        filter: invert(92%) sepia(100%) saturate(0%) hue-rotate(202deg) brightness(106%) contrast(106%);
        -webkit-filter: invert(92%) sepia(100%) saturate(0%) hue-rotate(202deg) brightness(106%) contrast(106%);
    }
    .graduateRow .college_detail_wrap .scholarship {
        margin-right: 60px;
    }
    .achievements-block-detail .edit_section {
        top: 4px;
        right: 0;
    }
    .achievements-block-detail {
        padding-right: 25px;
    }
    .postGraduate-detail .graduateRow .offer.offer_edit .edit_section {
        top: 20px;
    }
    .work-block .edit_section {
        right: 0;
    }
    .work-block .work-detail {
        padding-right: 24px;
    }

    .alumni_profile_name_college_other_detail_wrap.directory-detail .alumni_profile_image_name_other_detail {
        padding: 0;
        margin: 0;
        order: 1;
    }
    .alumni_profile_name_college_other_detail_wrap.directory-detail .alumni_profile_image_name_other_detail:after {
        opacity: 0;
    }
    .alumni_profile_name_college_other_detail_wrap.directory-detail .alumni_profile_college_name_other_detail_wrap {
        padding: 0;
        order: 3;
    }
    .alumni_profile_name_college_other_detail_wrap.directory-detail .alumni_profile_brife_links {
        order: 2;
        border-bottom: 1px solid #dbdbdbcc;
        margin-bottom: 30px;
        padding-bottom: 30px;
        flex-wrap: wrap;
    }
    .alumni_profile_brife_links > a {
        margin-bottom: 10px;
    }
}

@media (min-width: 575px) and (max-width: 767px) {
    .filter_result_title {
        flex-direction: column;
    }
    .alumni_count {
        text-align: right;
    }
    .alumni_class_link {
        flex-direction: column;
        align-items: start;
    }
    .alumni_class_link .send_link {
        padding: 0px 0 0 70px;
    }
    .alumni_name_detail {
        width: 100%;
    }
}

@media (max-width: 575px) {
    .alumni-directory-header {
        flex-direction: column;
    }
    .alumni-directory-header_search {
        flex-direction: row;
        width: 100%;
        margin-bottom: 20px;
        justify-content: space-between;
        overflow: hidden;
        align-items: center;
    }
    .alumni-directory-header_search h2 {
        margin-bottom: 0 !important;
    }
    .alumni-directorySearch-wrap {
        position: relative;
    }
    .alumni-directorySearch-wrap .alumni-directorySearch {
        position: absolute;
        right: -230px;
        transition: all 0.3s ease-in-out;
    }
    .alumni-directorySearch-wrap.show .alumni-directorySearch {
        right: 0;
    }
    .btn-alumniSearchToggle.icon-search {
        width: 40px;
        height: 40px;
        background: #fff;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        color: #000;
        font-size: 12px;
    }
    .alumni-directorySearch .form-control + .btn-cross {
        opacity: 1;
        right: 15px;
        transform: rotate(135deg);
    }
    .alumni-switch-container {
        margin: auto;
    }
    .alumni-directorySearch .form-control {
        padding-right: 40px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .alumni_profile_image_name_other_detail {
        padding: 0 0 20px;
        margin-bottom: 20px;
    }
    .alumni_profile_college_name_other_detail .alumni_profile_other_detail {
        margin: 0 0 0 -98px;
    }
    .alumni_profile_status li {
        width: 47%;
        margin-bottom: 0;
    }
    .alumni_profile_status li:last-child {
        margin-right: 0;
    }
    .alumni_profile_image {
        width: 80px;
        height: 80px;
    }
    .alumni_profile_name_other_detail h2 {
        line-height: 30px;
        font-size: 24px;
        width: calc(100% - 50px);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
    .alumni_profile_college_name_other_detail_wrap .alumni_profile_college_logo {
        width: 80px;
        height: 80px;
    }
    .alumni_profile_brife {
        padding: 20px 0 10px;
    }
    /*.graduateRow .college_detail_wrap .college_detail {  flex-direction: column;align-items: center;}*/
    .graduateRow .college_detail_wrap .college_detail {
        padding-right: 0px;
        width: 100%;
    }
    .graduateRow {
        margin-left: -20px;
        margin-right: -20px;
        width: auto;
        padding: 5px 20px;
    }
    .graduateRow .college_detail_wrap .college_detail .college_logo {
        margin: 0 10px 10px 0;
        width: 70px;
        height: 70px;
        border-radius: 50%;
    }
    .graduateRow .college_name_scholarship {
        flex-direction: column;
        align-items: start;
        justify-content: start;
        text-align: left;
        width: calc(100% - 80px);
    }
    .graduateRow .college_name_scholarship .college_name {
        width: calc(100% - 20px);
    }
    .graduateRow .college_detail_wrap .college_detail h3 {
        font-size: 19px;
        line-height: 20px;
        word-break: break-word;
    }
    .alumni_profile_college_subject {
        padding: 0 15px;
        font-size: 15px;
    }
    .graduateRow .college_detail_wrap .scholarship {
        flex-direction: row;
    }
    .graduateRow .college_detail_wrap .scholarship .scholarship_label {
        margin-right: 7px;
    }
    .graduateRow .college_detail_wrap::after {
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 100%;
        height: 1px;
    }
    .graduateRow .offer {
        align-items: center;
        flex-direction: row;
        justify-content: center;
    }
    .graduateRow:not(:last-child) {
        margin-bottom: 15px;
        padding-bottom: 20px;
    }
    .underGraduate-detail {
        padding: 20px;
        box-shadow: none;
    }
    .workExperience-detail {
        padding: 0px;
    }
    .workExperienceRow {
        padding: 20px 20px 30px;
    }

    .achievements-block {
        flex-direction: column;
        align-items: center;
    }
    .achievements-icon-block {
        border-right: 0;
        margin: 0 0 18px;
        padding: 0 0 18px;
        border-bottom: 1px solid #cecece;
    }
    .achievements-block-detail {
        text-align: center;
    }
    .achievements-block-detail h3 {
        margin: 0;
    }
    .contributions-block .seactionheader {
        margin: 0px 0 20px;
        flex-direction: column;
        align-items: inherit;
    }
    .contributions-block .seactionheader > h3 {
        width: auto;
        margin: -20px -20px 0;
        background: #eff8ff;
        border-radius: 6px 6px 0px 0px;
        padding: 20px;
        text-align: center;
        line-height: 22px;
    }
    .contributions-block .seactionheader .countnbtn {
        width: 100%;
        margin-top: 10px;
        align-items: center;
        justify-content: space-between;
        position: relative;
    }
    .contributions-block .seactionheader .countnbtn .custom-btns {
        padding-right: 0;
    }
    .contributions-block .seactionheader .countnbtn .custom-btns .edit_section {
        right: 0;
        top: 0px;
    }
    .contributions-block {
        padding: 20px;
    }
    .skills-block {
        padding: 20px;
    }
    .skills-block:first-child {
        padding-bottom: 5px;
    }
    .skills-block.EntranceExam-skills-block {
        padding-bottom: 25px;
    }
    .skills-block h2 {
        margin: 0 0 20px;
        width: auto;
        margin: -20px -20px 20px;
        background: #eff8ff;
        border-radius: 6px 6px 0px 0px;
        padding: 20px;
        text-align: center;
        line-height: 22px;
    }
    .skills-block-detail .meter {
        font-size: 18px;
        align-items: center;
        padding: 10px 20px;
        line-height: 18px;
    }
    .card_profiles figure.student {
        position: absolute;
        left: 15px;
        top: 15px;
    }
    .card_profiles .student_details h2 {
        margin: 0 0 10px 90px;
    }
    .card_profiles .btn-send {
        position: static;
        margin-left: 90px;
    }
    .card_profiles .student_details .sub_details {
        margin-top: 15px;
    }
    .card_profiles .student_details .sub_details span.course {
        line-height: 15px;
    }
    .contributions-block .act_lst {
        padding: 15px;
    }
    .contributions-block .act_lst .postedtxt {
        text-align: left;
        position: absolute;
        top: 15px;
        white-space: nowrap;
        right: 15px;
    }
    .graduateRow .offer > a {
        margin: 0 10px 0 0;
    }
    .graduateRow .offer div.got_offer {
        margin-right: 15px;
        width: calc(100% - 173px);
        align-items: center;
        display: flex;
        margin-bottom: 0 !important;
        text-align: left;
        flex: 1;
        justify-content: center;
        line-height: 18px;
    }
    .graduateRow .offer .offer_joined {
        align-items: start;
        width: 158px;
        align-items: center;
        display: flex;
        margin-bottom: 0 !important;
        line-height: 16px;
    }
    .graduateRow .offer .offer_joined i {
        margin-right: 8px;
    }
    .contributions-block .act_lst > .row > div:last-child .btn-green {
        width: 135px;
    }
    .contributions-block .act_lst .btn-dark-blue {
        width: 135px;
    }
    /* .contributions-block  .listblk_icon {margin-bottom: 0;width: 34px;height: 34px;} */
    .contributions-block .act_lst .postedrow > div:last-child {
        width: 100% !important;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        border-top: 1px solid #d1d1d1;
        padding-top: 10px;
        margin-top: 8px;
    }
    .act_lst > .row.postedrow > div:last-child > div:first-child {
        width: calc(100% - 190px);
        margin: 0 0 0 15px;
        align-items: center;
    }
    .alumni_class_link {
        align-items: center;
    }
    .filter_result_title {
        margin-bottom: 12px;
    }
    .contributions-block .album_shareblk {
        padding: 5px 0 0 0px;
    }
    .profile-item,
    .psychology-item {
        width: 100%;
        border: 0 !important;
    }
    .skills-block-title {
        margin: 0 0 20px;
        width: auto;
        margin: -20px -20px 20px;
        background: #eff8ff;
        border-radius: 6px 6px 0px 0px;
        padding: 20px;
        text-align: center;
        line-height: 22px;
    }
    .skills-block-title h2 {
        padding: 0;
        text-align: left;
    }
    #edit_Higher_Education .question {
        padding-right: 20px;
    }
    #job_Preferences .question {
        padding-right: 20px;
    }
    .underGraduate-detail .main_title,
    .blockHeader {
        margin: -20px -20px 30px;
        padding: 20px;
    }
    .graduateRow .offer .offer_existing_links {
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .graduateRow .offer .got_offer {
        margin-right: 10px;
    }
    .graduateRow .offer a.joindeLink {
        margin: 0 10px 0 0;
    }
    .powerby {
        margin: auto;
    }
    .graduateRow .offer.offer_edit .edit_section {
        top: 10px;
    }
    .postGraduate-detail .graduateRow {
        margin: 0;
    }
    .alumni_profile_college_name_detail {
        width: 100%;
        min-height: 100px;
    }
    .alumni_profile_name_college_other_detail_wrap {
        padding-bottom: 20px;
    }
    .alumni_profile_name_college_other_detail_wrap.directory-detail .alumni_profile_brife_links {
        padding: 0 0 17px;
        margin-top: 5px;
    }
    .alumni_profile_brife_links > a {
        padding: 0 15px;
    }
    .alumni_profile_name_college_other_detail_wrap.directory-detail
        .alumni_profile_college_name_other_detail_wrap
        .alumni_profile_college_name_other_detail
        .alumni_profile_other_detail {
        width: calc(100% + 98px);
    }
    .alumni_profile_name_college_other_detail_wrap.directory-detail
        .alumni_profile_college_name_other_detail_wrap
        .alumni_profile_college_name_other_detail
        .alumni_profile_other_detail
        .alumni_profile_status
        li {
        margin-bottom: 15px;
    }
    .leftUser {
        padding-left: 55px;
    }
    .rightUser {
        padding-right: 55px;
    }
    .half-gray:before {
        display: none;
    }

    .universities_block span, .radius_block span, .skills-block-detail span {
        flex: 1;
        font-size: 15px;
        line-height: 16px;
    }
}
/* alumni directory */

/* alumni connect */

.univariety_main_banner_container {
    position: relative;
}
.univariety_main_banner_container img {
    position: relative;
    vertical-align: top;
}
.main_banner_text {
    position: absolute;
    top: 22%;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    max-width: 48%;
    text-align: center;
    color: #fff;
}
.banner_title {
    font-size: 3.3vw;
    color: #fff;
    font-weight: 600;
    margin-bottom: 35px;
    line-height: 3vw;
}
.banner_sub_title {
    font-size: 1.33vw;
    line-height: 30px;
    margin-bottom: 40px;
    color: #fac75d;
}
.btn-watch {
    width: 180px;
    height: 50px;
    border: 1px solid #5cae48;
    text-decoration: none;
    border-radius: 5px;
    background: #5cae48;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.btn-watch:hover {
    background: #fff;
    text-decoration: none;
    border-color: #fff;
    color: #5cae48;
}
.btn-watch i {
    width: 22px;
    height: 22px;
    background: #fff;
    border-radius: 50%;
    margin-right: 10px;
    position: relative;
}
.btn-watch i:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 7px solid #5cae48;
    top: 5.7px;
    left: 9px;
}
.btn-watch:hover i {
    background: #5cae48;
}
.btn-watch:hover i:before {
    border-left-color: #fff;
}

.univariety_alumni_members {
    background: #fff;
}
.univariety_alumni_members_container {
    padding: 80px 0;
    max-width: 1140px;
    width: 90%;
    margin: auto;
}
.univariety_alumni_members_container_title {
    font-size: 32px;
    color: #003457;
    font-weight: bold;
    margin-bottom: 0;
    line-height: 64px;
    text-align: center;
}
.univariety_alumni_members_container_title b {
    font-weight: 600;
}
.univariety_alumni_members_container_sub_title {
    font-size: 20px;
    color: #000;
    font-weight: 100;
    line-height: 26px;
    text-align: center;
    margin-bottom: 30px;
}
.alumni_members_callout {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
    text-align: center;
    width: 1140px;
}
.alumni_members_callout .number {
    font-size: 48px;
    font-weight: 600;
    color: #074da2;
}
.alumni_members_callout p {
    font-size: 24px;
    font-weight: 500;
    color: #000;
    margin: 0;
}

#video_pop button.close {
    border: none;
    position: absolute;
    top: 10px;
    right: 10px;
    background: #fff;
    opacity: 1;
    padding: 1px 5px;
    border-radius: 1px;
    z-index: 9;
    height: 24px;
    display: flex;
    align-items: start;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
}
#video_pop button.close span {
    line-height: 20px;
    vertical-align: top;
    margin: -1px 0 0;
}

.univariety_message_spotlight_container div[class*='univariety_'] {
    padding: 50px 60px;
}
.univariety_message_spotlight_container {
    max-width: 1150px;
    width: 100%;
    margin: auto;
    position: relative;
}
.univariety_message_spotlight {
    background: #f5faff;
    position: relative;
}
.univariety_message_spotlight:before {
    content: '';
    right: 0;
    bottom: 0;
    top: 0;
    background: #e8f6fc;
    position: absolute;
    width: 42%;
}
.univariety_spotlight {
    background: #e8f6fc;
}
.video_wrap {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 15px #0000000d;
    border-radius: 10px;
    padding: 20px;
}
.univariety_spotlight .spotlight_block {
    background: #fff 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 15px #0000000d;
    border-radius: 10px;
    padding: 20px;
}
.univariety_message_spotlight_container div[class*='univariety_'] h2 {
    text-align: center;
    margin-bottom: 40px;
    position: relative;
    font-size: 24px;
    font-weight: 600;
}
.univariety_message_spotlight_container div[class*='univariety_'] h2:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -13px;
    width: 50px;
    height: 3px;
    margin: auto;
}
.univariety_message_spotlight_container div.univariety_message h2:before {
    background: #5cae48;
}
.univariety_message_spotlight_container div.univariety_spotlight h2:before {
    background: #d42941;
}
.univariety_message_spotlight_container .owl-theme .owl-dots .owl-dot span {
    background: #fff;
    width: 12px;
    height: 12px;
}
.univariety_message_spotlight_container .owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
    background: #003457;
}

.btn-play {
    position: relative;
    display: inline-block;
}
.btn-play:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 62px;
    height: 62px;
    background-color: #5cae48;
    margin: auto;
    border-radius: 50%;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='white' stroke='' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: 14px 12px;
    background-size: 40px;
}

.alumni_network_count {
    overflow-y: hidden;
    overflow-x: auto;
    scrollbar-width: thin;
    padding-bottom: 8px;
}
.alumni_network_count::-webkit-scrollbar {
    height: 4px;
}
.alumni_network_count::-moz-scrollbar {
    height: 4px;
}
.alumni_network_count::-webkit-scrollbar-track {
    background: #ccc;
}
.alumni_network_count::-webkit-scrollbar-thumb {
    background-color: #666;
    border-radius: 20px;
    border: 2px solid #666;
}
.alumni_network_count ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}
.alumni_network_count li {
    background: #ffffff;
    box-shadow: 0px 3px 6px #0000001a;
    border: 1px solid #0034571a;
    border-radius: 10px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    width: 220px;
    min-width: 220px;
    text-align: center;
}
.alumni_network_count li b {
    color: #5cae48;
    font-size: 45px;
}
.alumni_network_count li:not(:last-child) {
    margin-right: 10px;
}
.alumni_network_count li p {
    margin: 0 !important;
    text-align: center;
    font-size: 18px;
    line-height: 20px;
    font-weight: 500;
}
.alumni_network_count li .icon {
    width: 42px;
    height: 42px;
    margin-bottom: 10px;
}
.alumni_network_count li .icon.hat {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/graduation-cap.svg) no-repeat 0 0;
    width: 48px;
}
.alumni_network_count li .icon.brifecase {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/career.svg) no-repeat 0 0;
    width: 42px;
}
.alumni_network_count li .icon.building {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/office-building.svg) no-repeat 0 0;
    width: 42px;
}
.alumni_network_count li .icon.global {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/union.svg) no-repeat 0 0;
    width: 42px;
}
.alumni_network_count li .icon.college {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/school.svg) no-repeat 0 0;
    width: 42px;
}

@media (max-width: 991px) {
    .univariety_message_spotlight::before {
        display: none;
    }
}

@media (max-width: 575px) {
    .univariety_message_spotlight_container div[class*='univariety_'] {
        padding: 40px 40px;
    }
}

.view_pw .btn {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 15px;
    width: 20px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
}
.view_pw .btn img {
    width: 20px;
}
.form-control[type='password'] + .btn .fview_icn {
    display: none;
}
.form-control[type='text'] + .btn .fview_strike_icn {
    display: none;
}

.login_signup_tab .form-check-input.agree-check + .form-check-label {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    flex: 1;
    align-items: center;
}
.login_signup_tab .form-check-input.agree-check + .form-check-label .social_whatapp {
    width: 17px;
    height: 17px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/whatsapp.svg) no-repeat center / 100%;
    margin-right: 6px;
}
.login_signup_tab .form-check-input.agree-check + .form-check-label span {
    flex: 1;
    color: #fff;
    font-size: 14px;
    padding: 0;
    line-height: 18px;
}

.login_signup_tab .nav .nav-item {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    text-align: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    border-bottom: 3px solid #3c5d76;
}
.login_signup_tab .nav .nav-item .nav-link {
    width: 100%;
    background: transparent;
    font-size: 18px;
    font-weight: 500;
    padding: 0 0 15px;
    position: relative;
    color: #fff;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.login_signup_tab .nav .nav-item .nav-link.active {
    font-weight: 600;
}
.login_signup_tab .nav .nav-item .nav-link:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -4px;
    margin: auto;
    width: 0;
    height: 5px;
    background: #5cae48;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.login_signup_tab .nav .nav-item .nav-link.active:before {
    width: 100%;
}

.email_icon .form-control {
    padding-left: 50px;
}
.password_icon .form-control {
    padding-left: 50px;
}
.email_icon:before {
    content: '';
    background-color: #fff0;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='%23E57F8D' stroke='none' stroke-linecap='none' stroke-linejoin='none' stroke-width='1' d='M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 26px;
    position: absolute;
    left: 10px;
    top: 0;
    width: 26px;
    height: 24px;
    margin: auto;
    bottom: 0;
}
.password_icon::before {
    content: '';
    background-color: #fff0;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='%23E57F8D' stroke='none' stroke-linecap='none' stroke-linejoin='none' stroke-width='1' d='M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 30px;
    position: absolute;
    left: 12px;
    top: 0;
    width: 21px;
    height: 30px;
    margin: auto;
    bottom: 0;
}

.filter-switch-wrap {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 40px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
}
.filter-switch .form-check-input {
    cursor: pointer;
    margin: 0 10px;
    width: 60px !important;
    height: 30px !important;
    background-color: #5cae48;
    border: none !important;
    background-size: 24px;
    background-position: right 4px center;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.filter-switch .form-check-input:checked {
    background-position: left 4px center;
}
.filter-switch .form-check-input:focus {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.filter-switch label {
    font-weight: 500;
    cursor: pointer;
}
.filter-switch .form-check-input + label {
    color: #474747;
    order: -1;
    font-weight: 400;
}
.filter-switch .form-check-input + label + label {
    color: #5cae48;
    font-weight: 600;
}
.filter-switch .form-check-input:checked + label {
    color: #5cae48;
    font-weight: 600;
}
.filter-switch .form-check-input:checked + label + label {
    color: #474747;
    font-weight: 400;
}

a.btn-clear {
    color: #d42941;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
a.btn-clear:hover {
    color: #0b3454;
}

.alumni-bucket .alumni_education a {
    color: #0a70be;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.alumni-bucket .alumni_education a:hover {
    color: #5cae48;
    text-decoration: none;
}

.alumni_univarsity_details.working {
    background: transparent linear-gradient(180deg, #eefaff 0%, #e5f7ff 100%) 0% 0% no-repeat padding-box;
}
.alumni_univarsity_details.pursuing {
    background: transparent linear-gradient(180deg, #fff7e6 0%, #fff6e3 100%) 0% 0% no-repeat padding-box;
}
.alumni_univarsity_details.examtaken {
    background: transparent linear-gradient(180deg, #fff0eb 0%, #fce1d8 100%) 0% 0% no-repeat padding-box;
}
.alumni_univarsity_details.homemaker {
    background: transparent linear-gradient(180deg, #f6f6f6 0%, #f8f8f8 100%) 0% 0% no-repeat padding-box;
}
.alumni_univarsity_details.break {
    background: transparent linear-gradient(180deg, #f1efff 0%, #f7f6ff 100%) 0% 0% no-repeat padding-box;
}
.tooltip.show.tooltip-white {
    opacity: 1;
}
@media (max-width: 767px) {
    .alumni_directory_filter .filters-head {
        -webkit-box-wrap: wrap;
        -moz-box-wrap: wrap;
        -ms-flex-wrap: wrap;
        -webkit-wrap: wrap;
        flex-wrap: wrap;
    }
    .filters-head .filter-switch-wrap {
        position: static;
        margin: 15px -20px 0px -20px;
        border-top: 1px solid #dbdbdb;
        width: calc(100% + 40px);
        padding: 12px 15px;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        justify-content: center;
    }
}

/* Setting button dropdown in admin menu */
td:last-child .dropdown-menu {
    right: 0;
    left: auto;
}

/* alumni marketing css */
.offcanvas {
    z-index: 10509;
}

.verticalScrol {
    max-height: 360px;
    overflow: auto;
}
.verticalScrol::-webkit-scrollbar {
    width: 3px;
}
.verticalScrol::-webkit-scrollbar-track {
    background: #ccc;
}
.verticalScrol::-webkit-scrollbar-thumb {
    background: #666;
}
.verticalScrol::-webkit-scrollbar-thumb:hover {
    background: #999;
}
.collegelist {
    list-style: none;
    padding: 0;
    margin: 0;
}
.collegelist li {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
}
.collegelist li i {
    margin-right: 15px;
    width: 85px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
}
.collegelist li:not(:last-child) {
    border-bottom: 1px solid #e9e9e9;
    padding-bottom: 15px;
    margin-bottom: 15px;
}
.collegelist li p {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
}

.verticalScrol .accordion-item {
    border: none;
}
.verticalScrol .accordion-item .accordion-button:not(.collapsed) {
    background-color: transparent;
}
.verticalScrol .accordion-item .accordion-button {
    font-size: 18px;
    font-weight: 600;
    color: #000;
    padding-left: 0;
    padding-right: 0;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}
.verticalScrol .accordion-item .accordion-button:focus {
    box-shadow: none;
    outline: none;
}

.horizontallist {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    list-style: none;
}
.horizontallist li {
    position: relative;
    margin: 0 15px 5px 0;
    padding-left: 12px;
}
.horizontallist li:before {
    content: '';
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: black;
    left: 0;
    top: 11px;
}
.verticalScrol .accordion-body {
    padding-left: 0;
    padding-right: 0;
}

.option ul {
    list-style: none;
    margin: 11px 0;
    text-align: left;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
    line-height: 18px;
    position: relative;
}
.option ul li:not(:last-child) {
    margin-bottom: 7px;
}
.option ul:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: -6px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #ccc;
}

.view-all-wrap {
    padding: 30px;
    margin: 50px -30px -30px;
    border-top: 1px solid #d4d4d4;
}

.btn-green[disabled] {
    background: #e2e2e2;
    border-radius: 20px;
    color: #a29d9d;
    text-decoration: none;
    opacity: 1;
}
.btn-green img {
    filter: brightness(0) invert(1);
    -webkit-filter: brightness(0) invert(1);
}
tr:hover .btn-green[disabled] {
    background: #333;
    color: #fff;
}
.btn-green[disabled]:hover {
    background: #333;
    color: #fff;
}

.icon-sort-arrow .up-arrow {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='%23343a40' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='' stroke-width='1' d='M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z'/%3e%3c/svg%3e");
    width: 15px;
    height: 15px;
    margin-right: -3px;
}
.icon-sort-arrow .down-arrow {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='%23343a40' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='' stroke-width='1' d='M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z'/%3e%3c/svg%3e");
    width: 15px;
    height: 15px;
    margin-left: -3px;
}

.radio-box * {
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.radio-box {
    background: #ffffff;
    border: 1px solid #0b34544d;
    border-radius: 6px;
    padding: 11px 15px;
    min-width: 124px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}
.radio-box span {
    padding: 0;
    font-size: 16px;
    font-style: normal;
    color: #000;
    font-weight: 500;
    float: none;
}
.radio-box i {
    margin-left: 8px;
    background: #ffffff;
    border: 1px solid #b5c1cb;
    opacity: 1;
    width: 18px;
    height: 18px;
    position: relative;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.radio-box i:before {
    content: '';
    position: absolute;
    width: 9px;
    height: 5px;
    border-left: 2px solid #b5c1cb;
    border-bottom: 2px solid #b5c1cb;
    transform: rotate(-45deg);
    left: 3px;
    top: 5px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
}
.form-check-input + .radio-box span {
    flex: 1;
}
.form-check-input:checked + .radio-box {
    background: #5cae48;
    border-color: #5cae48;
}
.form-check-input:checked + .radio-box span {
    color: #fff;
}
.form-check-input:checked + .radio-box i {
    border-color: #fff;
    background: #5cae48;
}
.form-check-input:checked + .radio-box i:before {
    border-color: #fff;
}

.action_btns {
    max-width: 100%;
    background: #f5faff;
    padding: 20px;
    border-radius: 5px;
    margin: 35px 0 0 0px;
    text-align: center;
}
.action_btns button.nextbtn {
    padding: 0px 30px;
    font-size: 1.5em;
    cursor: pointer;
    border: 0px;
    position: relative;
    margin: auto;
    transition: all 0.25s ease;
    background: #0b3454;
    color: #fff;
    overflow: hidden;
    border-radius: 25px;
    height: 45px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
}
.action_btns .btn-skip {
    font-size: 16px;
    display: inline-block;
}
.btn-skip {
    margin: 15px;
    font-size: 12px;
    text-decoration: none;
    font-weight: 500;
    color: #00000070;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

.btn-skip:hover {
    color: #d42941;
}
.radius-6 {
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}

.disableOpacity {
    opacity: 0;
    cursor: default !important;
}

.hrBg {
    border-color: #e9e9e9;
    background-color: #e9e9e9;
    opacity: 1;
}

.max-568 {
    max-width: 568px;
}

.new-btn-delete {
    width: 125px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 16px;
    font-weight: 500;
    color: #c30000;
    align-items: center;
}
.new-btn-delete i {
    margin-right: 7px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
}
.new-btn-delete img {
    opacity: 0.8;
}
.new-btn-delete:hover {
    color: #890000;
}
.new-btn-delete:hover img {
    opacity: 1;
}

.profile_section {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
}
.profile_section::after {
    content: '';
    position: absolute;
    background-color: #c4c4c4;
    width: 1px;
    height: 40px;
    top: 0;
    bottom: 0;
    right: 10px;
    margin: auto;
}
.profile_section .profile_section_img {
    width: 60px;
    margin-right: 15px;
}
.profile_section .profile_section_name {
    font-size: 22px;
    font-weight: 600;
    color: #0b3454;
    max-width: 150px;
    line-height: 24px;
    padding-right: 30px;
    margin-right: 10px;
}

.contact_detail {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}
.contact_detail div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
}
.contact_detail div:not(:last-child) {
    margin-bottom: 3px;
}
.contact_detail div i {
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-right: 7px;
    background-position: center;
    background-size: 20px;
}
.contact_detail div a {
    color: #0b3454;
    text-decoration: none;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.contact_detail div a:hover {
    color: #1c5785;
}
.contact_detail div.phone i {
    background-image: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/phone_icon.svg);
}
.contact_detail div.email i {
    background-image: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/email_icon.svg);
}

.lead_items b {
    font-weight: 500;
    color: #0b3454;
}
.custom-bottom-liteblue {
    border-bottom: 3px solid #f2f9ff;
}
.custom-bottom-litegray {
    border-bottom: 3px solid #e5eaed;
}

@media (max-width: 523px) {
    .profile_section {
        padding-bottom: 30px;
        margin-bottom: 10px;
    }
    .profile_section::after {
        width: 80px;
        height: 1px;
        left: 0;
        bottom: 3px;
        top: auto;
        right: auto;
    }
}

/* Bulk Imports  */

.bulk_imports_header {
    border-bottom: 1px solid #c1ccd4;
}
.steps_flow {
    margin: auto;
    padding: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    position: relative;
    list-style: none;
}
.polartabsec .steps_flow li {
    height: auto;
    position: relative;
}
.polartabsec .steps_flow li:not(:last-child) {
    margin-right: 254px;
}
.polartabsec .steps_flow li a {
    text-decoration: none;
    color: #000;
    font-size: 18px;
}
.polartabsec .steps_flow li.active a {
    font-weight: 500;
}
.steps_flow:before {
    content: '';
    position: absolute;
    left: 20px;
    bottom: -20px;
    right: 20px;
    border-bottom: 3px solid #5cae48;
    margin: auto;
}
.polartabsec .steps_flow li:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -30px;
    background: #ffffff;
    border: 2px solid #0b345433;
    opacity: 1;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin: auto;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.polartabsec .steps_flow li.active:before {
    background: #0db35c;
    border: 1px solid #0db35c;
    margin: auto;
    box-shadow: 0 0 0 5px #fff inset;
}
.polartabsec .steps_flow li.completed::before {
    background-color: #0db35c;
    border: 1px solid #0db35c;
    margin: auto;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='' stroke-linejoin='' stroke-width='2' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.import-title {
    text-align: center;
    margin-top: 30px;
}
.import-title h3 {
    font-size: 24px;
    font-weight: 500;
    margin: 0 0 5px;
}
.import-title p {
    font-size: 16px;
    margin-bottom: 5px;
}

.import-block * {
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.import-block {
    background: #ffffff;
    border: 1px solid #d2d2d2;
    border-radius: 6px;
    position: relative;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    overflow: hidden;
    height: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
}
.import-block .icon {
    position: absolute;
    background: #0b3454;
    border-radius: 0px 4px 0px 72px;
    width: 78px;
    height: 78px;
    top: 0;
    right: 0;
}
.import-block .icon::before {
    filter: brightness(0) invert(1);
    -webkit-filter: brightness(0) invert(1);
}
.import-block-detail {
    padding: 20px 30px 30px;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.icon.icon_user::before {
    content: '';
    position: absolute;
    right: 14px;
    top: 14px;
    width: 30px;
    height: 30px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/selet-user.svg);
}
.icon.icon_display::before {
    content: '';
    position: absolute;
    right: 14px;
    top: 14px;
    width: 30px;
    height: 30px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/display-user.svg);
}
.import-block-detail h4 {
    font-size: 24px;
    font-weight: 500;
    margin: 0 0 15px;
}
.import-block-detail ul {
    margin: 0;
    padding: 0;
    max-width: 420px;
    list-style: none;
}
.import-block-detail ul li:not(:last-child) {
    margin-bottom: 18px;
}
.import-block-detail ul li {
    position: relative;
    padding: 0 0 0 18px;
    line-height: 20px;
}
.import-block-detail ul li:before {
    content: '';
    position: absolute;
    top: 3px;
    left: 0;
    background: #27a7df;
    width: 3px;
    height: 85%;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.import-block-select {
    background: #f2f9ff;
    width: 100%;
    height: 60px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    color: #0b345490;
    text-transform: uppercase;
    cursor: pointer;
}
.import-block-select i {
    width: 26px;
    height: 26px;
    border-radius: 5px;
    border: 1px solid #d2d2d2;
    background: #fff;
    margin-right: 10px;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    position: relative;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.inputfile_parent label.height-80 {
    height: 80px;
}
.inputfile_parent label.height-80 span {
    font-weight: 400;
}
.inputfile_parent label .text-skyblue {
    color: #27a7df;
}

.form-check-input:checked + div {
    border: 1px solid #5cae48;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.form-check-input:checked + div .icon {
    background: #f2f9ff;
}
.form-check-input:checked + div .icon:before {
    filter: brightness(1) invert(0);
    -webkit-filter: brightness(1) invert(0);
}
.form-check-input:checked + div .import-block-select {
    background: #5cae48;
    color: #fff;
}
.form-check-input:checked + div .import-block-select i {
    border-color: #fff;
    background-color: #fff;
}
.form-check-input:checked + div .import-block-select i::before {
    content: '';
    position: absolute;
    border-width: 0px 0px 3px 3px;
    border-style: solid;
    border-color: #5cae48;
    width: 14px;
    height: 7px;
    transform: rotate(-45deg);
    left: 6px;
    top: 7px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
}

.upload_disclaimer {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #0b3454;
    opacity: 0.8;
    padding: 14px 0;
}

.form-check-tick .form-check-input {
    width: 26px;
    height: 26px;
    margin-right: 8px;
    margin-top: 0;
}
.form-check-tick {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
}
.form-check-tick .form-check-input:checked {
    background-color: #5cae48;
    border-color: #5cae48;
}

.map-card-wrap {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 20px;
}
.map-card {
    border: 1px solid #dbdcdd;
    border-radius: 6px;
    width: 257px;
    min-width: 257px;
    overflow: hidden;
}
.map-card:not(:last-child) {
    margin-right: 10px;
}
.map-column {
    background: #f2f9ff;
    padding: 10px 15px 20px;
}
.map-title {
    color: #68696a;
    font-weight: 500;
    font-size: 14px;
}
.skip_column {
    height: 45px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
}
.skip_column .skip-link {
    color: #0b3454;
}
.column-list {
    padding: 0;
    margin: 0;
}
.column-list li {
    min-height: 45px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    padding: 0 15px;
}
.column-list-title {
    background: #f5f7f8;
    font-weight: 500;
}
.column-list li ~ li:not(:last-child) {
    border-bottom: 1px solid #ebebeb;
}

.modal-header .icon-correct {
    width: 50px;
    height: 50px;
    background: #5cae48;
    border-radius: 50%;
    color: #fff;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.x-scroll-big {
    overflow-x: auto;
}
.x-scroll-big::-webkit-scrollbar {
    height: 15px;
}
.x-scroll-big::-webkit-scrollbar-track {
    background: #ccc;
}
.x-scroll-big::-webkit-scrollbar-thumb {
    background: #666;
}
.x-scroll-big::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.p-40 {
    padding: 40px;
}

.btn-disable {
    background: #e2e2e2;
    border-radius: 25px;
    height: 35px;
    color: #000;
    font-weight: 400;
    pointer-events: none;
}
.btn.btn-disable:hover {
    background: #666;
    color: #ffff;
}

.custom_step {
    background: #ffffff;
    border: 2px solid #5cae48;
    opacity: 1;
    border-radius: 5px 0px 0px 5px;
    width: 90px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    height: 40px;
    justify-content: center;
    color: #5cae48;
    font-weight: 500;
    position: relative;
    margin-right: 20px;
}
.custom_step:after {
    position: absolute;
    content: '';
    width: 28.3px;
    height: 28.3px;
    border-top: 2px solid #5cae48;
    border-right: 2px solid #5cae48;
    transform: rotate(45deg);
    right: -16px;
    background: #fff;
    top: 0;
    bottom: 0;
    margin: auto;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
}

.custom-tool {
    position: absolute;
    padding: 10px;
    background: #d6ffcc;
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
    white-space: nowrap;
    border-radius: 5px;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 40px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    left: calc(100% + 20px);
    padding: 0 20px;
}
.custom-tool:before {
    content: '';
    position: absolute;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid #d6ffcc;
    right: 100%;
}

.upload_section {
    background: #ffffff;
    border: 1px dashed #0b345480;
    border-radius: 6px;
    padding: 20px;
    position: relative;
}
.upload_section .cloud-wrap {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
}
.upload_section .cloud-wrap .cloud-img {
    margin-right: 15px;
}
.upload_section .btn-upload {
    width: 170px;
}
.upload_section .cloud-wrap .cloud-text {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    line-height: 18px;
}
.form-check-input {
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.upload_section u {
    color: #27a7df;
}

div.ck.ck-editor {
    min-height: 200px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
}
div.ck.ck-editor .ck-editor__main {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}
div.ck.ck-editor .ck-editor__main .ck-editor__editable_inline {
    display: block;
    width: 100%;
    border-radius: 0px 0px 6px 6px;
}
div.ck.ck-editor .ck-editor__main .ck-editor__editable_inline a {
    color: #0c4cbb;
    text-decoration: none;
}
div.ck.ck-editor .ck-reset_all .ck-sticky-panel__content .ck-toolbar {
    border-radius: 6px 6px 0 0;
}
.post-control ~ div.ck.ck-editor {
    min-height: 300px;
}

.circle-tick {
    width: 36px;
    height: 36px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    background: #5cae48;
    border-radius: 50%;
    margin-right: 12px;
    position: relative;
}
.circle-tick:before {
    content: '';
    position: absolute;
    width: 16px;
    height: 8px;
    border-left: 3px solid #fff;
    border-bottom: 3px solid #fff;
    top: 12px;
    left: 10px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
}

@media (max-width: 575px) {
    .polartabsec .steps_flow li:not(:last-child) {
        margin-right: 145px;
    }
    .inputfile_parent label.height-80 span.uploadlabel {
        text-overflow: ellipsis;
        width: calc(100% - 75px);
        overflow: hidden;
    }
    .custom-tool {
        display: none;
    }
}
/* Bulk Imports end */

/* social media post start */

.social_wrap ul {
    padding: 0;
    margin: 20px 0 0;
    list-style: none;
}
.social_wrap ul li {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    padding: 20px 0;
}
.social_wrap ul li:not(:last-child) {
    border-bottom: 1px solid #e5eaed;
}
.social_wrap ul li .post-view {
    width: calc(100% - 450px);
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
    margin-right: 90px;
}
.social_wrap ul li .post-view .post-name {
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    margin-right: 90px;
}
.social_wrap ul li .post-view .post-name .social-logo {
    margin-right: 15px;
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.social_wrap ul li .post-view .post-name .social-logo img{
    width: auto;
    height: 100%;
    max-width: inherit !important;
}
.social_wrap ul li .post-view .post-title {
    font-weight: 500;
    width: calc(100% - 65px);
}
.social_wrap ul li .post-view .post-title p{
    display: inline;
}
.social_wrap ul li .post-detail {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    margin-left: auto;
}
.social_wrap ul li .post-detail .post-detail-date {
    line-height: 16px;
    text-align: right;
    font-size: 14px;
    font-weight: 600;
    margin-right: 20px;
}
.social_wrap ul li .post-detail .post-detail-date span {
    font-size: 16px;
    font-weight: 400;
    font-style: italic;
}

.postOption {
    background: #fffae5;
    border: 1px dashed #e3dec8;
    border-radius: 6px;
    padding: 25px;
}
.postOption ul {
    margin: 0;
    padding: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;
    justify-content: space-between;
}
.postOption ul li {
    height: 50px;
    width: 48%;
    border-radius: 5px;
}
.postOption ul li label {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    padding: 0 18px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    cursor: pointer;
    position: relative;
}
.postOption ul li label b {
    position: absolute;
    width: 25px;
    height: 25px;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    bottom: 0;
    right: 15px;
    margin: auto;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.postOption ul li label[for='facebook'] {
    background-color: #3456a2;
}
.postOption ul li label[for='Twitter'] {
    background-color: #01a1f9;
}
.postOption ul li label[for='linkedin'] {
    background-color: #0077b7;
}
.postOption ul li label[for='instagram'] {
    background: rgb(171,16,128);
    background: linear-gradient(208deg, rgba(171,16,128,1) 0%, rgba(254,83,64,1) 70%, rgba(255,213,84,1) 100%);
}
.postOption ul li label i {
    width: 25px;
    height: 25px;
    margin-right: 10px;
    background-size: 100%;
    background-position: center;
}
.postOption ul li label[for='facebook'] i {
    background-image: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/facebook-white.svg);
}
.postOption ul li label[for='Twitter'] i {
    background-image: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/x-twtter.svg);
    background-size: 33px;
}
.postOption ul li label[for='linkedin'] i {
    background-image: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/linkedin.svg);
    background-size: 27px;
    background-color: #fff;
}
.postOption ul li label[for='instagram'] i {
    background-image: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/instagram.svg);
    background-size: 33px;
}
.postOption ul li input:checked ~ label b {
    background-color: #ffffff;
}
.postOption ul li input:checked ~ label b::before {
    content: '';
    width: 14px;
    height: 7px;
    position: absolute;
    border-left: 3px solid #5cae48;
    border-bottom: 3px solid #5cae48;
    left: 6px;
    top: 8px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
}

.post-preview {
    background: #f2f4f7;
    padding: 25px;
    border-radius: 5px;
    margin-top: 28px;
    height: calc(100% - 28px);
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
}
.post-preview h2 {
    font-size: 22px;
    text-align: center;
    font-weight: 500;
}
.post-preview-data {
    background: #ffff;
    padding: 25px;
    border-radius: 5px;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.post-preview-data a {
    color: #0c4cbb;
}

@media (max-width: 991px) {
    .social_wrap ul li .post-view .post-name {
        margin-right: 30px;
    }
    .social_wrap ul li .post-view {
        margin-right: 30px;
    }
}

@media (max-width: 767px) {
    .social_wrap ul li .post-view {
        margin-right: 0px;
        width: 100%;
    }
    .social_wrap ul li .post-detail {
        width: 100%;
        margin-top: 10px;
        justify-content: end;
    }
}

@media (max-width: 575px) {
    .postOption {
        padding: 10px;
    }
    .postOption ul li {
        width: 48.5%;
    }
    .postOption ul li label {
        padding: 0 10px;
        font-size: 15px;
    }
    .postOption ul li label i {
        margin-right: 0;
    }
}

@media (max-width: 480px) {
    .social_wrap ul li .post-view {
        flex-direction: column;
        align-items: start;
    }
    .social_wrap ul li .post-view .post-name {
        margin-right: 0;
        margin-bottom: 10px;
    }
    .social_wrap ul li .post-detail {
        justify-content: start;
        flex-direction: row-reverse;
    }
    .social_wrap ul li .post-detail .post-detail-date {
        margin: 0 0 0 10px;
        text-align: left;
    }
}

/* social media post End */

/* manage-widgets-wrap */
.manage-widgets-wrap ul {
    padding: 0;
    margin: 0;
    list-style: none;
}
.manage-widgets-wrap ul li {
    background: #ffffff;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    padding: 10px;
}
.manage-widgets-wrap ul li:not(:last-child) {
    margin-bottom: 5px;
}
.manage-widgets-wrap {
    margin-top: 25px;
}

.manage-widgets-wrap .form-check-tick .form-check-input {
    width: 22px;
    height: 22px;
}

.btn.btn-widgets-edit {
    background: #ffffff;
    border: 2px solid #0b3454;
    border-radius: 15px;
    height: 28px;
    line-height: 25px;
    padding: 0 20px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    color: #0b3454;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.btn.btn-widgets-edit:hover {
    background: #0b3454;
    color: #fff;
}

.btn.btn-priview {
    background: #ffffff;
    border: 2px solid #5cae48;
    border-radius: 15px;
    height: 28px;
    line-height: 25px;
    padding: 0 20px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    color: #5cae48;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.btn.btn-priview:hover {
    background: #5cae48;
    color: #fff;
}

.btn.btn-configure {
    background: #0b3454;
    border: 2px solid #0b3454;
    border-radius: 15px;
    height: 28px;
    line-height: 25px;
    padding: 0 20px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    color: #fff;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.btn.btn-configure:hover {
    background: #5cae48;
    border: 2px solid #5cae48;
    color: #fff;
}
/* manage-widgets-wrap */

.alumni-spotlight {
    background: #f2f4f7;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 0 8px 0px #aeaeae;
}
.alumni-spotlight a {
    text-decoration: none;
}
.alumni-spotlight > .bg-white {
    padding: 20px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
}
.alumni-card-wrap {
    padding: 50px 0 80px;
}
.alumni-card-wrap .alumni-card-slider {
    max-width: 350px;
    width: 90%;
    margin: auto;
    background: #ffffff;
    box-shadow: 0px 5px 15px #0000000d;
    border-radius: 10px;
    position: relative;
}
.alumni-card-wrap .alumni-card-slider:before {
    content: '';
    position: absolute;
    background: #d42941;
    left: 0;
    right: 0;
    margin: auto;
    height: 75px;
    border-radius: 10px 10px 200px 200px / 10px 10px 40px 40px;
}
.alumni-card-wrap .alumni-card-slider .alumni-card {
    padding: 25px 40px;
}
.alumni-card-wrap .alumni-card-slider .alumni-card .alumni-detail {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-bottom: 1px solid #0b345425;
    padding-bottom: 15px;
    margin-bottom: 15px;
}
.alumni-card-wrap .alumni-card-slider .alumni-card .alumni-logo {
    width: 100%;
    margin: auto;
    text-align: center;
}
.alumni-card-wrap .alumni-card-slider .alumni-card .alumni-logo img {
    max-width: 200px;
    width: auto;
    min-height: 40px;
    max-height: 120px;
}
.alumni-img-wrap {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    position: relative;
    margin-bottom: 18px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.alumni-img-wrap .alumni-img {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    justify-content: center;
    overflow: hidden;
    border-radius: 50%;
}
.alumni-img-wrap .alumni-img img {
    width: auto;
    max-width: fit-content;
    height: 100%;
}
.alumni-card-wrap .alumni-card-slider .owl-dots {
    position: absolute;
    left: 0;
    right: 0;
    top: calc(100% + 10px);
}
.alumni-card-wrap .alumni-card-slider .owl-dots .owl-dot span {
    background: #ffffff;
    border: 1px solid #00345767;
    width: 14px;
    height: 14px;
    margin: 5px;
}
.alumni-card-wrap .alumni-card-slider .owl-dots .owl-dot.active span {
    background: #003457;
    border: 1px solid #003457;
}

.alumni-img-wrap label {
    position: absolute;
    top: 0;
    right: 0;
}
.alumni-detail label {
    background: #ffffff;
    border: 1px solid #a8acaf;
    opacity: 1;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.alumni-detail label:hover {
    background: #a8acaf;
}
.alumni-detail label .pencil-edit {
    background-size: 13px;
    filter: invert(61%) sepia(65%) saturate(7465%) hue-rotate(205deg) brightness(103%) contrast(101%);
    -webkit-filter: invert(61%) sepia(65%) saturate(7465%) hue-rotate(205deg) brightness(103%) contrast(101%);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.alumni-detail label:hover .pencil-edit {
    background-size: 13px;
    filter: invert(100%) sepia(100%) saturate(20%) hue-rotate(333deg) brightness(104%) contrast(107%);
    -webkit-filter: invert(100%) sepia(100%) saturate(20%) hue-rotate(333deg) brightness(104%) contrast(107%);
}
.alumni-card-wrap .alumni-card-slider .alumni-card .alumni-detail h3 {
    font-size: 24px;
    font-weight: 600;
}
.alumni-card-wrap .alumni-card-slider .alumni-card .alumni-detail h4 {
    font-size: 16px;
}

.template-block {
    background: #f8f8f8;
    border: 1px solid #d5d5d5;
    border-radius: 6px;
    opacity: 1;
    padding: 10px;
    position: relative;
    overflow: hidden;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    height: 100%;
}
.template-block-image {
    position: relative;
    overflow: hidden;
    border-radius: 6px;
}
.template-block-name {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    line-height: 18px;
    padding: 20px 20px 10px;
}
.overview-block {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background: rgba(0, 0, 0, 0.8);
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    overflow: hidden;
}
.template-block:hover .overview-block {
    top: 0;
}
.overview-block .btn-choose {
    background: #5cae48;
    border-radius: 27px;
    width: 170px;
    height: 40px;
    color: #fff;
    text-decoration: none;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 10px -200%;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.overview-block .btn-choose:hover {
    background: #3e892b;
}
.template-block .overview-block:hover .btn-choose {
    margin-left: 0;
}
.overview-block .btn-preview {
    background: #fff;
    border-radius: 27px;
    width: 170px;
    height: 40px;
    color: #000;
    text-decoration: none;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 -200% 0 0;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.overview-block .btn-preview:hover {
    background: #5cae48;
    color: #fff;
}
.template-block .overview-block:hover .btn-preview {
    margin-right: 0;
}

.gray-gradient {
    background: transparent linear-gradient(180deg, #ffffff 0%, #f5f5f5 100%);
}
.border-d2d2d2 {
    border: 1px solid #d2d2d2;
}
.alumni-card-wrap .alumni-card-slider i.curve {
    content: '';
    position: absolute;
    background: #d42941;
    left: 0;
    right: 0;
    margin: auto;
    height: 75px;
    border-radius: 10px 10px 200px 200px / 10px 10px 40px 40px;
    top:0
}
.alumni_profile_name_other_detail .btn-send .icon-web {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/web.svg) no-repeat 0 0 / 100%;
    width: 19px;
    height: 19px;
    margin-right: 8px;
    position: relative;
    left: 0;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.alumni_profile_brife_links {
    width: 100%;
    padding-left: 129px;
    margin-top: 20px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
}
.alumni_profile_brife_links a {
    height: 40px;
    padding: 0 20px;
    border-radius: 25px;
    display: inline-flex;
    align-items: center;
    margin-right: 6px;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

.alumni_profile_brife_links a i {
    margin-right: 5px;
}
.alumni_profile_brife_links a.btn-send {
    background: #5cae48;
    color: #fff;
}
.alumni_profile_brife_links a.btn-send:hover {
    background: #2fa612;
}
.alumni_profile_brife_links a.btn-send i.icon-send {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/send_alumni_profile.svg) no-repeat 0
        0 / 100%;
    width: 19px;
    height: 19px;
    margin-right: 10px;
    position: relative;
    transition: all 0.3s ease-in-out;
    left: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.alumni_profile_brife_links a.btn-send i.icon-web {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/web.svg) no-repeat 0 0 / 100%;
    width: 19px;
    height: 19px;
    margin-right: 10px;
    position: relative;
    transition: all 0.3s ease-in-out;
    left: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.alumni_profile_brife_links .btn-send:hover .icon-send {
    left: 3px;
}

.alumni_profile_brife_links a.btn-push-web {
    background: #27a7df;
    color: #fff;
}
.alumni_profile_brife_links a.btn-push-web:hover {
    background: #078dc8;
}
.alumni_profile_brife_links a.btn-push-web i.icon-globe {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/web.svg) no-repeat 0 0 / 100%;
    width: 19px;
    height: 19px;
    margin-right: 10px;
    position: relative;
    transition: all 0.3s ease-in-out;
    left: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.alumni_profile_brife_links .btn-push-web:hover .icon-globe {
    left: 3px;
}

.alumni_profile_brife_links a.btn-mark {
    background: #fff;
    border: 2px solid #0b3454;
    color: #0b3454;
}
.alumni_profile_brife_links a.btn-mark:hover {
    background: #0b3454;
    color: #fff;
}

.listed-points {
    padding: 0;
    margin: 4px 0 0;
    list-style: none;
}
.listed-points li {
    margin-bottom: 0;
    padding-left: 12px;
    line-height: 20px;
    font-weight: 500;
}
.listed-points li:before {
    width: 5px;
    height: 5px;
    background: #000;
    top: 8px;
}

.top-48 {
    top: 48px;
}
.z-1 {
    z-index: 1;
}
.z-0 {
    z-index: 0;
}

.more_action {
    padding: 0;
    margin: 0;
    list-style: none;
    width: 150px;
}
.more_action a {
    padding: 0;
    color: #000;
    margin: 0;
    height: auto;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    text-decoration: none;
}
.more_action a:hover {
    color: #5cae48;
}
.more_action li:not(:last-child) {
    margin-bottom: 5px;
}

.btn-moreaction {
    background: #ffffff;
    border: 2px solid #7d7d7d;
    color: #000;
    height: 40px;
    padding: 0 20px;
    padding-right: 20px;
    padding-left: 20px;
    border-radius: 25px;
    display: inline-flex;
    align-items: center;
    margin-right: 6px;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.btn-moreaction:hover {
    background: #7d7d7d;
    color: #fff;
}
.alumni_profile_brife_links a[aria-expanded='true'] {
    background-color: #7d7d7d;
    color: #fff;
}
.btn-moreaction .dot {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin-left: 7px;
}
.btn-moreaction .dot:before,
.btn-moreaction .dot:after,
.btn-moreaction i {
    content: '';
    width: 3px;
    height: 3px;
    background: #333;
    border-radius: 50%;
    margin: 1px 0;
}
.btn-moreaction:hover .dot:before,
.btn-moreaction:hover .dot:after,
.btn-moreaction:hover i {
    background: #fff;
}
.alumni_profile_brife_links a[aria-expanded='true'] .dot::before,
.alumni_profile_brife_links a[aria-expanded='true'] .dot::after,
.alumni_profile_brife_links a[aria-expanded='true'] i {
    background: #fff;
}

.graduateRow .offer .got_offer i.icon-like.dislike {
    -moz-transform: scale(-1) translateY(-5px);
    -webkit-transform: scale(-1) translateY(-5px);
    -o-transform: scale(-1) translateY(-5px);
    -ms-transform: scale(-1) translateY(-5px);
    transform: scale(-1) translateY(-5px);
    display: inline-block;
    color: #e10000;
    vertical-align: top;
}
.workExperienceRow .offer {
    border-left: 1px solid #d1d1d1;
    min-height: 63px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    margin-left: 30px;
    padding-left: 25px;
}
.workExperienceRow .offer i {
    margin-right: 10px;
}
.workExperienceRow .offer i.icon-like {
    color: #5cae48;
}
.workExperienceRow .offer i.icon-like.dislike {
    -moz-transform: scale(-1) translateY(-8px);
    -webkit-transform: scale(-1) translateY(-8px);
    -o-transform: scale(-1) translateY(-8px);
    -ms-transform: scale(-1) translateY(-8px);
    transform: scale(-1) translateY(-8px);
    display: inline-block;
    vertical-align: top;
    color: #919191;
}

.no-after:after,
.no-before:before {
    content: none;
    display: none;
}

.card_profiles.auto_height {
    max-height: inherit;
    min-height: 0;
}

@media (max-width: 991px) {
    .alumni_profile_name_college_other_detail_wrap.directory-detail .alumni_profile_image_name_other_detail {
        padding: 0;
        margin: 0;
        order: 1;
    }
    .alumni_profile_name_college_other_detail_wrap.directory-detail .alumni_profile_image_name_other_detail:after {
        opacity: 0;
    }
    .alumni_profile_name_college_other_detail_wrap.directory-detail .alumni_profile_college_name_other_detail_wrap {
        padding: 0;
        order: 3;
    }
    .alumni_profile_name_college_other_detail_wrap.directory-detail .alumni_profile_brife_links {
        order: 2;
        border-bottom: 1px solid #dbdbdbcc;
        margin-bottom: 30px;
        padding-bottom: 30px;
        flex-wrap: wrap;
    }
    .alumni_profile_brife_links > a {
        margin-bottom: 10px;
    }
}

@media (max-width: 575px) {
    .alumni_profile_name_college_other_detail_wrap.directory-detail .alumni_profile_brife_links {
        padding-left: 0;
    }
    .workExperienceRow .offer {
        border-left: 0px solid #d1d1d1;
        min-height: 0;
        margin-left: 50px;
        padding-left: 25px;
    }
}

.campus-activities-blue-section {
    background: #f2f9ff;
    border-radius: 6px;
    padding: 25px 35px;
}
.campus-activities-detail {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
}
.campus-activities-label {
    margin-right: 10px;
}
.campus-activities-block {
    background: #ffffff;
    border: 1px solid #7cb0d8;
    border-radius: 15px;
    height: 30px;
    padding: 0 20px;
    font-weight: 500;
    line-height: 28px;
}

.campus-activities-card {
    background: #ffffff;
    border: 1px solid #d5d5d5;
    border-radius: 6px;
    opacity: 1;
    padding: 18px;
    height: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
}
.tag-wrap {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
}
.tag {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='%2326A7DF' stroke='none' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 1a1 1 0 0 0-1 1v4.586a1 1 0 0 0 .293.707l7 7a1 1 0 0 0 1.414 0l4.586-4.586a1 1 0 0 0 0-1.414l-7-7A1 1 0 0 0 6.586 1zm4 3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 17px;
    width: 17px;
    height: 17px;
    display: block;
    margin-right: 5px;
}
.tag-wrap span {
    color: #414141;
}

.activities-card-title {
    font-size: 18px;
    font-weight: 500;
    border-bottom: 1px solid #e9e9e9;
    padding-bottom: 7px;
}

.campus-activities-edit {
    background: #f2f4f7;
    padding: 30px;
    border-radius: 6px;
    margin-top: 34px;
    height: calc(100% - 80px);
}
.campus-activities-edit .title {
    text-align: center;
    font-weight: 500;
    margin-bottom: 20px;
    font-size: 24px;
}
.campus-activities-edit .campus-activities-card {
    height: auto;
}

.campus-activities-edit label {
    margin-left: 5px;
    background: #ffffff;
    border: 1px solid #a8acaf;
    opacity: 1;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.campus-activities-edit label:hover {
    background: #a8acaf;
}
.campus-activities-edit label .pencil-edit {
    background-size: 13px;
    filter: invert(61%) sepia(65%) saturate(7465%) hue-rotate(205deg) brightness(103%) contrast(101%);
    -webkit-filter: invert(61%) sepia(65%) saturate(7465%) hue-rotate(205deg) brightness(103%) contrast(101%);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.campus-activities-edit label:hover .pencil-edit {
    background-size: 13px;
    filter: invert(100%) sepia(100%) saturate(20%) hue-rotate(333deg) brightness(104%) contrast(107%);
    -webkit-filter: invert(100%) sepia(100%) saturate(20%) hue-rotate(333deg) brightness(104%) contrast(107%);
}
.campus-activities-card-img {
    position: relative;
}
.campus-activities-card-img label {
    position: absolute;
    right: 5px;
    top: 5px;
}

.school_detail_left_pane {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
}
.school_profile_img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: #f1f1f1;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: end;
    margin-right: 15px;
}
.school_profile_img img {
    width: auto;
    height: 100%;
}
.school_profile_img.nopic img {
    height: 80%;
}

.school_profile_name_other_detail {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    flex-direction: column;
    width: calc(100% - 95px);
    justify-content: center;
}
.school_profile_college {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
}
.school_profile_college_logo {
    width: 43px;
    height: 43px;
    padding: 5px;
    border: 1px solid #d2d2d2;
    border-radius: 50%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin-right: 5px;
}
.school_profile_college_logo img {
    width: 100%;
}
.school_profile_college_name {
    width: calc(100% - 48px);
    line-height: 16px;
    font-size: 14px;
    font-weight: 500;
}
.school_profile_name_other_detail h5 {
    font-size: 16px;
    margin: 0 0 5px;
}

.student_profile_status {
    list-style: none;
    margin: 0;
    padding: 0;
}
.student_profile_status li {
    margin-bottom: 16px;
    position: relative;
    padding-left: 16px;
}
.student_profile_status li .title {
    letter-spacing: 0.32px;
    color: #000000;
    font-size: 16px;
    line-height: 20px;
    display: inline-block;
}
.student_profile_status li p {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.32px;
}
.student_profile_status li:before {
    content: '';
    width: 3px;
    height: 17px;
    position: absolute;
    left: 0;
    top: 4px;
    background: #0b3454;
    border-radius: 5px;
}

.student_profile_status li .listed-points {
    padding: 0;
    margin: 4px 0 0;
    list-style: none;
}
.student_profile_status li .listed-points li {
    margin-bottom: 0;
    padding-left: 12px;
    line-height: 20px;
    font-weight: 500;
}
.student_profile_status li .listed-points li:before {
    width: 5px;
    height: 5px;
    background: #000;
    top: 8px;
}
.successful_journey * {
    position: relative;
}
.successful_journey {
    position: relative;
}
.successful_journey::before {
    content: '';
    background: transparent linear-gradient(180deg, #0b3454 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
    border-radius: 4px 4px 0px 0px;
    opacity: 1;
    height: 320px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
}
.successful_journey h3 {
    margin: 30px 0 25px;
    text-align: center;
    font-size: 16px;
    position: relative;
}
.successful_journey h3::before {
    content: '';
    position: absolute;
    width: 50px;
    height: 2px;
    background-color: #f1ac18;
    left: 0;
    right: 0;
    margin: auto;
    top: calc(100% + 7px);
}
.successful_journey h4 {
    color: #fff;
    margin: 20px 0 5px;
}
.successful_journey p {
    color: #fff;
    margin: 0px 0 5px;
    font-size: 16px;
}

@media (max-width: 991px) {
    .campus-activities-edit {
        height: auto;
        margin: 0;
    }
    .campus-activities-edit .campus-activities-card {
        max-width: 305px;
        margin: auto;
    }
}
@media (max-width: 575px) {
    .campus-activities-blue-section {
        padding: 20px 22px;
    }
}

.share-social-media {
    background: #fffae5;
    border: 1px dashed #e3dec8;
    border-radius: 6px;
    opacity: 1;
    margin-bottom: 20px;
}
.api-link {
    padding: 20px;
    border-bottom: 1px dashed #e3dec8;
}
.share-platform {
    padding: 20px;
}
.api-link-title {
    padding: 8px 10px 8px 20px;
    background: white;
    border-radius: 6px 0 0 6px;
    position: relative;
    margin-right: 15px;
}
.api-link-title:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 100%;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 10px solid white;
    width: 0;
    height: 0;
    margin: auto;
}
.link-url span {
    width: calc(100% - 29px);
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.link-url {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: start;
    max-width: 368px;
}
.link-url i {
    width: 21px;
    height: 21px;
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin-left: 8px;
}
.link-url i:before {
    width: 13px;
    height: 17px;
    border-radius: 4px 0 0 0;
    border-top: 2px solid #0b3454;
    content: '';
    position: absolute;
    background: #fffae5;
    border-left: 2px solid #0b3454;
}
.link-url i:after {
    width: 16px;
    height: 21px;
    border-radius: 4px;
    border: 2px solid #0b3454;
    content: '';
    position: absolute;
    background: #fffae5;
    top: 4px;
    left: 4px;
}
.share-platform button {
    border: 0;
    background: transparent;
    width: 150px;
    height: 48px;
    border-radius: 6px;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    display: flex;
    align-items: center;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.share-platform button i img {
    vertical-align: top;
}
.share-platform button.btn-facebook {
    background-color: #3456a2;
    padding: 0 0 0 10px;
}
.share-platform button.btn-facebook:hover {
    background-color: #173371;
}
.share-platform button.btn-twitter {
    background-color: #01a1f9;
    padding: 0 0 0 6px;
}
.share-platform button.btn-twitter:hover {
    background-color: #1ca7f3;
}
.share-platform button.btn-linkedin {
    background-color: #0077b5;
    padding: 0 0 0 14px;
}
.share-platform button.btn-linkedin:hover {
    background-color: #004fb5;
}
.share-platform button.btn-linkedin i {
    filter: brightness(0) invert(1);
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    margin-right: 8px;
}

.alumni-directory .btn-refresh {
    border-radius: 25px;
    padding: 0 25px;
    background: #d82a2a;
    border: 2px solid #ffffff;
    height: 40px;
    color: #fff;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.alumni-directory .btn-refresh i {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/refresh-page-option.svg) no-repeat
        center / 16px;
    width: 16px;
    height: 16px;
    margin-right: 8px;
    filter: brightness(0) invert(1);
    -webkit-filter: brightness(0) invert(1);
}
.alumni-directory .btn-refresh:hover {
    background: #fff;
    color: #d82a2a;
}
.alumni-directory .btn-refresh:hover i {
    filter: invert(31%) sepia(19%) saturate(7466%) hue-rotate(343deg) brightness(87%) contrast(95%);
    -webkit-filter: invert(31%) sepia(19%) saturate(7466%) hue-rotate(343deg) brightness(87%) contrast(95%);
}
.icon-down-arrow.left-arrow {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
}
.icon-down-arrow.right-arrow {
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
}

.academic_detail {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
}
.academic_detail .academic_logo {
    border: 1px solid #d2d2d2;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}
.academic_detail .academic_name {
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    width: calc(100% - 70px);
}

.border-0B3454 {
    border: 1px solid #0b3454;
}

.mail_alumni_wrap {
    background: transparent linear-gradient(179deg, #0b3454 0%, #c4c4c8 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 4px #0000000d;
    border-radius: 4px 4px 0px 0px;
    opacity: 1;
    color: #fff;
    margin-top: 20px;
}
.mail_alumni {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
}
.mail_alumni .mail_alumni_profile_img {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    border: 2px solid #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.mail_alumni .mail_alumni_profile_img img {
    width: 100%;
}
.mail_alumni .mail_alumni_profile_detail h2 {
    font-size: 28px;
    margin: 0;
}
.mail_alumni .mail_alumni_profile_detail p {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}
.mail_alumni .mail_alumni_profile_detail .icon-location-pin {
    margin-right: 3px;
}
.mail_alumni .mail_alumni_profile_detail ul {
    padding: 0 0 0 10px;
    list-style: none;
    line-height: 18px;
    position: relative;
    margin: 0;
}
.mail_alumni .mail_alumni_profile_detail ul:before {
    content: '';
    position: absolute;
    width: 3px;
    height: 100%;
    background: #fff;
    border-radius: 5px;
    top: 0;
    left: 0;
}
.compose_message p:last-child {
    margin: 0;
}

.alumni_univarsity_share {
    padding: 15px 20px;
}
.view_link {
    padding: 0 0 15px;
    text-align: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #d2d2d2;
    margin-bottom: 15px;
}
.alumni_univarsity_share .icon-mail {
    font-size: 0;
    width: 18px;
    height: 16px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/email_icon.svg) no-repeat center /
        100%;
}
.alumni_univarsity_share .icon-whatsapp {
    font-size: 0;
    width: 25px;
    height: 25px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/whatsapp.svg) no-repeat 0 0 / 100%;
}
.alumni_univarsity_share .icon-link {
    font-size: 0;
    width: 20px;
    height: 16px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/link.svg) no-repeat center / 100%;
}
.alumni_univarsity_share .icon-download {
    font-size: 0;
    width: 16px;
    height: 17px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/download_2.svg) no-repeat center /
        100%;
}
.alumni_univarsity_share a {
    margin: 0 15px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    position: relative;
}
.alumni_univarsity_share a:hover {
    transform: translateY(-3px);
    -webkit-transform: translateY(-3px);
    -moz-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    -o-transform: translateY(-3px);
}

@media (max-width: 575px) {
    .mail_alumni .mail_alumni_profile_img {
        width: 70px;
        height: 70px;
    }
    .mail_alumni_profile_detail {
        width: calc(100% - 90px);
    }
    .mail_alumni .mail_alumni_profile_detail h2 {
        font-size: 26px;
        margin: 0 0 4px;
        line-height: 26px;
    }
}

.collateral-documents-wrap {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
}
.documents-block {
    margin: 0 10px 20px;
    border: 1px solid #d5d5d5;
    border-radius: 6px;
    width: calc(20% - 20px);
    padding: 10px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
}
.documents-block-image img {
    width: 100%;
}
.documents-block .alumni_univarsity_share {
    padding: 0;
}

.collateral-video-block {
    border: 1px solid #d5d5d5;
    border-radius: 6px;
    padding: 10px;
    height: 100%;
}
.video-block-image .video-btn {
    display: inline-block;
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 6px;
    vertical-align: top;
}
.video-block-image .video-btn .img-fluid {
    width: 100%;
}
.video-block-image .video-btn i {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    color: #fff;
    font-size: 35px;
    width: 35px;
    height: 35px;
}
.video-block-image .video-btn .time-line {
    position: absolute;
    right: 10px;
    bottom: 10px;
    background: #000000 0% 0% no-repeat padding-box;
    border-radius: 4px;
    color: #fff;
    font-size: 12px;
    padding: 0px 10px;
    height: 24px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
}
.collateral-video-block .alumni_univarsity_share {
    padding: 0;
}

.pt-38 {
    padding-top: 38px;
}
.speaker-img-wrap {
    position: absolute;
    width: 60px;
    height: 60px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.speaker-img {
    position: absolute;
    width: 60px;
    height: 60px;
    border: 3px solid #fff;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.speaker-img img {
    width: auto;
    height: 100%;
}
.speaker-img-wrap label {
    position: absolute;
    top: -5px;
    right: -5px;
}
.album_contblk h3 {
    min-height: 49px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.album_blk label.img-edit {
    position: absolute;
    top: 15px;
    right: 15px;
}

.btn-preview {
    background: #ffffff;
    border: 2px solid #7d7d7d;
    border-radius: 27px;
    height: 45px;
    color: #7d7d7d;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.btn-preview:hover {
    background-color: #7d7d7d;
    color: #fff;
}

.text-blue-0C4CBB {
    color: #0c4cbb;
}
.text-blue-0C4CBB:hover {
    color: #5cae48;
}

.upload_section input {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: 100%;
    opacity: 0;
    z-index: 1;
    background: red;
}

.alumni-img-wrap label {
    position: absolute;
    top: 0;
    right: 0;
}
.album_blk label {
    background: #ffffff;
    border: 1px solid #a8acaf;
    opacity: 1;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.album_blk label:hover {
    background: #a8acaf;
}
.album_blk label .pencil-edit {
    background-size: 13px;
    filter: invert(61%) sepia(65%) saturate(7465%) hue-rotate(205deg) brightness(103%) contrast(101%);
    -webkit-filter: invert(61%) sepia(65%) saturate(7465%) hue-rotate(205deg) brightness(103%) contrast(101%);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.album_blk label:hover .pencil-edit {
    background-size: 13px;
    filter: invert(100%) sepia(100%) saturate(20%) hue-rotate(333deg) brightness(104%) contrast(107%);
    -webkit-filter: invert(100%) sepia(100%) saturate(20%) hue-rotate(333deg) brightness(104%) contrast(107%);
}

.line-height-21 {
    line-height: 21px;
}

.promote-alumni-wrap {
    background: #f2f4f7;
    padding: 60px 0;
    margin-bottom: 44px;
}
.promote-share-it ul {
    list-style: none;
    padding: 0;
    margin: 0;
    max-width: 234px;
}
.promote-share-it ul li:not(:last-child) {
    margin-bottom: 10px;
}
.promote-share-it ul a {
    background: #ffffff;
    border: 1px solid #b6c8d8;
    border-radius: 20px;
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    height: 40px;
    align-items: center;
    text-decoration: none;
    color: #0b3454;
    font-size: 16px;
    font-weight: 500;
    padding: 0 19px 0 25px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.promote-share-it ul a:hover {
    background: #b6c8d8;
}

.promote-share-it ul a i {
    width: 20px;
    height: 20px;
    margin-right: 12px;
}
.promote-share-it ul a i.mail {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/email.svg) no-repeat center / 100%;
    filter: invert(14%) sepia(69%) saturate(1363%) hue-rotate(182deg) brightness(90%) contrast(95%);
    -webkit-filter: invert(14%) sepia(69%) saturate(1363%) hue-rotate(182deg) brightness(90%) contrast(95%);
}
.promote-share-it ul a i.whatsapp {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/whatsapp.svg) no-repeat center / 100%;
}
.promote-share-it ul a i.link {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/link.svg) no-repeat center / 100%;
}
.promote-share-it ul a i.calender {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/blue-calender.svg) no-repeat center /
        100%;
}
.promote-share-it ul a span {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.promote-share-it ul a b.icon-double-arrow {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/double-right-arrows.svg) no-repeat
        center / 100%;
    width: 11px;
    height: 11px;
}
.promote-share-it ul a b.icon-copy {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/green-copy.svg) no-repeat center /
        100%;
    width: 15px;
    height: 15px;
}
.promote-share-it ul a b.icon-add {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/add-green.svg) no-repeat center /
        100%;
    width: 13px;
    height: 13px;
}

.promote-share-it ul a:hover b {
    filter: invert(0%) brightness(0%);
    -webkit-filter: invert(0%) brightness(0%);
}

.connect-block {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    height: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
}
.connect-block-top {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    padding: 15px;
    border-bottom: 1px solid #d9d9d9;
    align-items: center;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.connect-block-top .icon {
    margin-right: 12px;
}
.connect-block-top .icon.calender {
    width: 31px;
    height: 30px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/google-calender.svg) no-repeat center /
        100%;
}
.connect-block-top .icon.office {
    width: 26px;
    height: 32px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/office.svg) no-repeat center / 100%;
}
.connect-block-top .icon.outlook {
    width: 33px;
    height: 31px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/outlook.svg) no-repeat center / 100%;
}
.connect-block-top .icon.icloud {
    width: 36px;
    height: 24px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/icloud-black.svg) no-repeat center /
        100%;
}
.connect-block-bottom {
    padding: 15px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    justify-content: center;
}
.existing-calender h6 {
    font-size: 16px;
    margin: 0 0 3px;
}
.existing-calender span {
    font-size: 12px;
    line-height: 14px;
}
.existing-calender {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
}
.connect-block-bottom a {
    max-width: 120px;
    text-decoration: none;
}

@media (max-width: 1099px) {
    .documents-block {
        width: calc(25% - 20px);
    }
    .alumni_univarsity_share a {
        margin: 0 10px;
    }
}
@media (min-width: 767px) {
    .pt-md-38 {
        padding-top: 38px;
    }
}
@media (max-width: 767px) {
    .documents-block {
        width: calc(33.33% - 20px);
    }
}

@media (max-width: 575px) {
    .collateral-documents-wrap {
        margin-left: -5px;
        margin-right: -5px;
    }
    .documents-block {
        width: calc(50% - 10px);
        margin: 0 5px 10px;
    }
    .polartabsec + div.p-0 {
        padding-bottom: 0px !important;
    }
    .alumni_univarsity_share a {
        margin: 0 7px;
    }
}
.student-pref .edit_section + .custom_row {
    border-top: none;
}

.quill.editor {
    min-height: 330px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
}
.quill.editor .ql-toolbar.ql-snow {
    border-radius: 6px 6px 0 0;
}
.quill.editor .ql-container.ql-snow {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    font-size: 16px;
}
.quill.editor .ql-container.ql-snow p {
    margin-bottom: 15px;
}

.btn-clearBtn {
    background: #cd0000;
    height: 40px;
    padding: 0 25px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    position: relative;
    overflow: hidden;
    border: 0;
    border-radius: 27px;
    -webkit-border-radius: 27px;
    -moz-border-radius: 27px;
    -ms-border-radius: 27px;
    -o-border-radius: 27px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.btn-clearBtn:hover {
    background: #a30000;
    color: #fff;
}
.btn-clearBtn i {
    padding: 0;
    width: 18px;
    height: 18px;
    filter: invert(1);
    -webkit-filter: invert(1);
    margin-right: 5px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    background: transparent
        url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z%27/%3e%3c/svg%3e')
        0 0 / 18px auto no-repeat;
}

/* css30042024  */

.circle-right {
    position: relative;
    width: 48px;
    height: 48px;
    margin: auto;
    background: #5cae48;
    border-radius: 50%;
}
.circle-right:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 20px;
    height: 12px;
    border-left: 5px solid #fff;
    border-bottom: 5px solid #fff;
    transform: rotate(-45deg);
}

.college_blk {
    background: #ffffff;
    border: 1px solid #d2d2d2;
    border-radius: 6px;
    opacity: 1;
    overflow: hidden;
}
.college_blk .collegeName {
    padding: 18px 20px;
    position: relative;
}
.college_blk .collegeName h3 {
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
    margin-bottom: 8px;
}
.college_blk .collegeName i {
    color: #26a7df;
    font-size: 16px;
}
.college_detail_price i.icon-book {
    color: #f05a2a;
}
.college_blk .collegeName .tick {
    font-style: italic;
    color: #0b3454;
}
.college_blk .college_detail {
    background: #0b3454;
    padding: 20px;
    color: #fff;
}
.college_blk:not(:last-child) {
    margin-bottom: 23px;
}
.college_detail_price_deadline {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
}
.college_detail_price_deadline > div {
    width: 50%;
    margin-bottom: 20px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
}
.college_detail_price_deadline > div .icon {
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #db852b;
    box-shadow: 0px 3px 6px #00000029;
}
/* .college_detail_price_deadline > div .icon .icon-dollor { width: 16px; height: 23px; background: url(../images/mask-group-133.svg) no-repeat 0 0; filter: invert(44%) sepia(25%) saturate(6305%) hue-rotate(347deg) brightness(100%) contrast(89%); -webkit-filter: invert(44%) sepia(25%) saturate(6305%) hue-rotate(347deg) brightness(100%) contrast(89%); } */

.college_detail_price_deadline > div .icon .icon-dollor {
    width: 11px;
    height: 20px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/dolar.svg) no-repeat 0 0;
    filter: invert(44%) sepia(25%) saturate(6305%) hue-rotate(347deg) brightness(100%) contrast(89%);
    -webkit-filter: invert(44%) sepia(25%) saturate(6305%) hue-rotate(347deg) brightness(100%) contrast(89%);
}
.college_detail_price_deadline > div .icon .icon-canadian-dollar {
    width: 18px;
    height: 18px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/canadian-dollar.svg) no-repeat 0 0;
    filter: invert(44%) sepia(25%) saturate(6305%) hue-rotate(347deg) brightness(100%) contrast(89%);
    -webkit-filter: invert(44%) sepia(25%) saturate(6305%) hue-rotate(347deg) brightness(100%) contrast(89%);
}
.college_detail_price_deadline > div .icon .icon-yen-symbol {
    width: 12px;
    height: 16px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/yen-symbol.svg) no-repeat 0 0;
    filter: invert(44%) sepia(25%) saturate(6305%) hue-rotate(347deg) brightness(100%) contrast(89%);
    -webkit-filter: invert(44%) sepia(25%) saturate(6305%) hue-rotate(347deg) brightness(100%) contrast(89%);
}
.college_detail_price_deadline > div .icon .icon-australian-dollar {
    width: 17px;
    height: 15px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/australian-dollar.svg) no-repeat 0 0;
    filter: invert(44%) sepia(25%) saturate(6305%) hue-rotate(347deg) brightness(100%) contrast(89%);
    -webkit-filter: invert(44%) sepia(25%) saturate(6305%) hue-rotate(347deg) brightness(100%) contrast(89%);
}
.college_detail_price_deadline > div .icon .icon-euro {
    width: 12px;
    height: 15px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/euro.svg) no-repeat 0 0;
    filter: invert(44%) sepia(25%) saturate(6305%) hue-rotate(347deg) brightness(100%) contrast(89%);
    -webkit-filter: invert(44%) sepia(25%) saturate(6305%) hue-rotate(347deg) brightness(100%) contrast(89%);
}
.college_detail_price_deadline > div .icon .icon-franc {
    width: 11px;
    height: 13px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/franc.svg) no-repeat 0 0;
    filter: invert(44%) sepia(25%) saturate(6305%) hue-rotate(347deg) brightness(100%) contrast(89%);
    -webkit-filter: invert(44%) sepia(25%) saturate(6305%) hue-rotate(347deg) brightness(100%) contrast(89%);
}
.college_detail_price_deadline > div .icon .icon-great-britain-pound {
    width: 10px;
    height: 14px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/great-britain-pound.svg) no-repeat 0
        0;
    filter: invert(44%) sepia(25%) saturate(6305%) hue-rotate(347deg) brightness(100%) contrast(89%);
    -webkit-filter: invert(44%) sepia(25%) saturate(6305%) hue-rotate(347deg) brightness(100%) contrast(89%);
}
.college_detail_price_deadline > div .icon .icon-hong-kong-dollar {
    width: 20px;
    height: 12px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/hong-kong-dollar.svg) no-repeat 0 0;
    filter: invert(44%) sepia(25%) saturate(6305%) hue-rotate(347deg) brightness(100%) contrast(89%);
    -webkit-filter: invert(44%) sepia(25%) saturate(6305%) hue-rotate(347deg) brightness(100%) contrast(89%);
}
.college_detail_price_deadline > div .icon .icon-malaysian-ringgit {
    width: 15px;
    height: 12px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/malaysian-ringgit.svg) no-repeat 0 0;
    filter: invert(44%) sepia(25%) saturate(6305%) hue-rotate(347deg) brightness(100%) contrast(89%);
    -webkit-filter: invert(44%) sepia(25%) saturate(6305%) hue-rotate(347deg) brightness(100%) contrast(89%);
}
.college_detail_price_deadline > div .icon .icon-new-zealand-dollar {
    width: 21px;
    height: 11px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/new-zealand-dollar.svg) no-repeat 0 0;
    filter: invert(44%) sepia(25%) saturate(6305%) hue-rotate(347deg) brightness(100%) contrast(89%);
    -webkit-filter: invert(44%) sepia(25%) saturate(6305%) hue-rotate(347deg) brightness(100%) contrast(89%);
}
.college_detail_price_deadline > div .icon .icon-rupee-indian {
    width: 11px;
    height: 17px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/rupee-indian.svg) no-repeat 0 0;
    filter: invert(44%) sepia(25%) saturate(6305%) hue-rotate(347deg) brightness(100%) contrast(89%);
    -webkit-filter: invert(44%) sepia(25%) saturate(6305%) hue-rotate(347deg) brightness(100%) contrast(89%);
}
.college_detail_price_deadline > div .icon .icon-singapore-dollar {
    width: 18px;
    height: 18px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/singapore-dollar.svg) no-repeat 0 0;
    filter: invert(44%) sepia(25%) saturate(6305%) hue-rotate(347deg) brightness(100%) contrast(89%);
    -webkit-filter: invert(44%) sepia(25%) saturate(6305%) hue-rotate(347deg) brightness(100%) contrast(89%);
}
.college_detail_price_deadline > div .icon .icon-united-arab-emirates-dirham {
    width: 16px;
    height: 9px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/united-arab-emirates-dirham.svg)
        no-repeat 0 0;
    filter: invert(44%) sepia(25%) saturate(6305%) hue-rotate(347deg) brightness(100%) contrast(89%);
    -webkit-filter: invert(44%) sepia(25%) saturate(6305%) hue-rotate(347deg) brightness(100%) contrast(89%);
}
.college_detail_price_deadline > div .icon .icon-open-book,
.icon-open-book {
    width: 18px;
    height: 15px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/open-book.svg) no-repeat 0 0;
    filter: invert(44%) sepia(25%) saturate(6305%) hue-rotate(347deg) brightness(100%) contrast(89%);
    -webkit-filter: invert(44%) sepia(25%) saturate(6305%) hue-rotate(347deg) brightness(100%) contrast(89%);
    background-size: cover;
}
.college_detail_price_deadline > div .icon + div {
    line-height: 19px;
}

.college_blk_left {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
}
.college_blk_right {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: end;
}
.college_blk_right .icon-down-arrow {
    display: none;
}
.college_blk_right .btn-green:after {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    border: 0;
}
.college_blk .collegeName .college_blk_left .college_detail_price i.icon-book {
    color: #d97d1d;
}
.college_blk .collegeName .college_blk_left .icon-hat {
    filter: invert(57%) sepia(68%) saturate(1168%) hue-rotate(165deg) brightness(90%) contrast(92%);
    -webkit-filter: invert(57%) sepia(68%) saturate(1168%) hue-rotate(165deg) brightness(90%) contrast(92%);
    vertical-align: top;
    margin-top: 2px;
}
.college_blk_right .toggle-link {
    position: relative;
}
.college_blk_right .toggle-link a + i {
    position: absolute;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 0px solid #368124;
    left: 0;
    right: 0;
    margin: auto;
    width: 0;
    height: 0;
    top: 100%;
    transition: all 0.3s ease-in-out;
}
.college_blk_right .toggle-link a[aria-expanded='true'] + i {
    border-top: 7px solid #368124;
}

.listed-subject {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.listed-subject ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.listed-subject ul li {
    background: #ffffff;
    border-radius: 20px;
    padding: 7px 20px;
    color: #000;
    font-size: 15px;
    font-weight: 500;
    margin: 0 5px 5px 0;
}
.listed-subject .cut-off {
    margin: 0 5px 5px 5px;
    font-size: 14px;
    font-style: italic;
}

.select-toggle {
    position: absolute;
    top: 10px;
    right: 10px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
}
.select-toggle > a {
    width: 20px;
    height: 20px;
    background: #0d345333;
    border-radius: 50%;
    position: relative;
}
.select-toggle .btn-add-toggle {
    margin-right: 10px;
}
.select-toggle .btn-add-toggle:before {
    content: '';
    position: absolute;
    width: 10px;
    height: 2px;
    background: #fff;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.select-toggle .btn-add-toggle:after {
    content: '';
    position: absolute;
    width: 2px;
    height: 10px;
    background: #fff;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    border-radius: 10px;
}
.select-toggle > a.btn-select-toggle::before {
    content: '';
    position: absolute;
    width: 10px;
    height: 5px;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    left: 5px;
    top: 7px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
}

.college_blk .college {
    background: #fff;
    padding: 10px 20px;
    border-radius: 6px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.college_blk .college:hover {
    box-shadow: 0px 0px 6px #0000001a;
}
.college_blk .college:not(:last-child) {
    margin-bottom: 5px;
}
.college_blk .icon-anchor {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/maximize.svg);
    width: 13px;
    height: 13px;
    display: inline-block;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.college_blk .college:hover .icon-anchor {
    filter: invert(66%) sepia(20%) saturate(1333%) hue-rotate(63deg) brightness(87%) contrast(80%);
    webkit-filter: invert(66%) sepia(20%) saturate(1333%) hue-rotate(63deg) brightness(87%) contrast(80%);
}
.college_blk .college .college-brife {
    flex: 1;
    margin-right: 20px;
    color: #000;
}
.college_blk .collegeName i.icon-medal {
    filter: invert(44%) sepia(25%) saturate(6305%) hue-rotate(347deg) brightness(100%) contrast(89%);
    -webkit-filter: invert(44%) sepia(25%) saturate(6305%) hue-rotate(347deg) brightness(100%) contrast(89%);
}

.college_detail .btn-viewdetail {
    max-width: 220px;
    background: #fff;
    width: 90%;
    margin: 18px auto auto; /*border: 2px solid #7D7D7D;*/
    border-radius: 20px;
    height: 40px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
}
.college_detail .btn-viewdetail:hover {
    background: #5cae48;
    color: #fff;
}

.deadlines-list .college_detail {
    background: #fff;
    padding-top: 0;
}
.deadlines-list .college_blk .college {
    background: #153d5b;
}
.deadlines-list .college_blk .college .college-brife {
    color: #fff;
}
.deadlines-list .college_detail .btn-viewdetail {
    background: #5cae48;
    color: #fff;
}
.deadlines-list .college_detail .btn-viewdetail:hover {
    background: #398227;
}

.toggle-link a.btn-green[aria-expanded='true']::before {
    width: 100%;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.toggle-link a.btn-green[aria-expanded='true'] span.icon-down-arrow {
    transform: rotate(180deg);
}
.toggle-link a.btn-green span.icon-down-arrow {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.btn.btn-accordion[aria-expanded='true'] {
    transform: rotate(180deg);
}
.btn.btn-accordion {
    transition: all 0.3s ease-in-out;
}
.college_blk .collegeName > .toggle-link.round-toggle {
    position: absolute;
    top: 0;
    right: 20px;
    bottom: 0;
    margin: auto !important;
    width: 48px !important;
    height: 48px;
}
.college_blk .collegeName > .toggle-link .btn.btn-accordion {
    border: 2px solid #0b345433;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    position: static;
}
.colondetailRow {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
}
.colondetailRowLabel {
    width: 140px;
    font-size: 14px;
}
.colondetailRow .colon {
    margin: 0 20px;
}
.colondetailRowBrife {
    font-weight: 600;
}
.colondetailRow:not(:last-child) {
    margin-bottom: 15px;
}
.colondetail {
    margin: 5px 0;
}

.college-brife a {
    color: #0b3454;
    text-decoration: none;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.college-brife a:hover {
    color: #414141;
}

.btn-reset {
    box-shadow: 0px 0px 0px #0b34541a;
    border: 0px solid #b5c1cb;
    border-radius: 25px;
    width: 70px;
    height: 32px;
    line-height: 30px;
    padding: 0;
    font-size: 15px;
    font-weight: 500;
    background: none;
}
.btn-reset:hover {
    background: none;
    color: #0b3454;
}
.btn-reset i {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/arrow-circle.svg);
    width: 12px;
    height: 14px;
    display: inline-block;
    margin-right: 5px;
}

.compareCollegeswrap {
    background: #fff;
    border-radius: 5px;
    padding: 40px 30px 10px 5%;
}
.tabular-data {
    position: relative;
}
.heading {
    height: 99px;
}
.collegeBlock {
    background: #fff;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid #ececec;
}
.tabular-data .content {
    min-height: 60px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    width: 100%;
    align-items: center;
}
.tabular-data .collegeBlock .content {
    justify-content: center;
}
.tabular-data .mobile-scroll .content span {
    padding: 0 25px;
    font-family: 'Jost', sans-serif !important;
    font-size: 16px;
    text-align: center;
}
.tabular-data div:not(.mobile-scroll) .content span {
    font-weight: 500;
    font-family: 'Jost', sans-serif;
    font-size: 16px;
}
.tabular-data .collegeBlock .content:nth-child(2n + 1) {
    background: #f6f6f6;
}
.tabular-data .collegeBlock .content:not(:last-child) {
    border-bottom: 1px solid #ececec;
}
.collegeBlock .heading {
    border-bottom: 1px solid #ececec;
    position: relative;
    background: #0b3454;
    color: #fff;
    padding: 25px 12px 0;
}
.collegeBlock .heading .icon.icon-checked {
    width: 15px;
    height: 15px;
    position: absolute;
    background: #fff;
    border-radius: 50%;
    left: 12px;
    top: 28px;
    display: none;
}
.icon.icon-checked {
    width: 15px;
    height: 15px;
    position: absolute;
    background: #fff;
    border-radius: 50%;
    left: 12px;
    top: 28px;
    display: none;
}
.collegeBlock .heading .compare_add {
    width: 15px;
    height: 15px;
    display: inline-block;
    background: #fff;
    border-radius: 50%;
    margin-top: 3px;
    position: relative;
}
.collegeBlock .heading .compare_add:before {
    content: '';
    position: absolute;
    width: 9px;
    height: 5px;
    border-left: 2px solid #0b3454;
    border-bottom: 2px solid #0b3454;
    transform: rotate(-45deg);
    top: 4px;
    left: 3px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
}
.collegeBlock .heading .btn-close-small {
    width: 10px;
    height: 10px;
    padding: 0 !important;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 9;
    opacity: 1;
}
.collegeBlock .close.closetag {
    position: absolute;
    top: 0px;
    right: 7px;
}
.collegeBlock .close.closetag i {
    color: #fff;
}
.tabular-data .content.admChanceDiv[style*='block'] {
    display: flex !important;
}
.tabular-data .content.ranking {
    flex-direction: column;
    align-items: self-start;
    justify-content: center;
}
.tabular-data .collegeBlock .content.ranking {
    align-items: center;
}
.tabular-data .collegeBlock .content a {
    background: #e6e6e6;
    padding: 8px 20px;
    border-radius: 25px;
    color: #185094;
    text-decoration: none;
    min-width: 110px;
    width: 100%;
    display: inline-block;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    font-weight: 500;
    font-family: 'Jost', sans-serif;
}
.tabular-data .collegeBlock .content a:hover {
    background: #185094;
    color: #fff;
}
.collegeBlock h6 span {
    font-size: 20px;
    font-weight: 500;
}
.collegeBlock .heading h6 i {
    font-size: 16px;
}
.compare_add.added i {
    color: #f4ad17;
}
#checkChanceBlockDiv {
    display: none !important;
}
.btn-load {
    border: 1px solid #e1e1e1;
    background: #f8f8f8;
    border-radius: 3px;
    font-size: 16px;
    color: #414141;
}
.blue-text {
    color: #2680eb;
}

.btn-play {
    color: #0b3454 !important;
}
.btn-play:hover {
    color: #5cae48 !important;
    opacity: 1 !important;
}

@media (max-width: 1199px) {
    .mobile-scroll {
        overflow: auto;
    }
    .mobile-scroll > .row {
        width: 730px;
    }
    .college_blk .collegeName .toggle-link {
        width: 100%;
        margin-top: 15px;
        order: 1;
    }
}

@media (max-width: 575px) {
    .tabular-data div:not(.mobile-scroll) .content span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

/* .pagination_custom */

.pagination-div .pagination {
    list-style: none;
    height: 31.5px;
    /* width: 31.5px; */
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
    cursor: pointer;
}

.pagination-div .active div {
    background-color: #5cae48;
    color: #fff;
    border-radius: 50%;
}

.pagination-div .page-item {
    list-style: none;
    padding: 0;
    margin: 0 5px;
}
.pagination-div .page-item div {
    height: 31.5px;
    width: 31.5px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pagination-div .previous button {
    width: 32px;
    height: 32px;
    background: #a3a3a3;
    border-radius: 50%;
    margin: 0 5px;
    position: relative;
    border: none;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.pagination-div .previous button:before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border-top: 3px solid #fff;
    border-right: 3px solid #fff;
    transform: rotate(-135deg);
    top: 12px;
    left: 13px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
}
.pagination-div .next button {
    width: 32px !important;
    height: 32px;
    background: #a3a3a3;
    border-radius: 50%;
    margin: 0 5px;
    position: relative;
    border: none;
}
.pagination-div .next button:before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border-top: 3px solid #fff;
    border-right: 3px solid #fff;
    transform: rotate(-135deg);
    top: 12px;
    left: 13px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
}
.heading-search .custom-select {
    border: 1px solid #e6e6e6 !important;
    border-radius: 4px;
    height: 45px !important;
}
.heading-search .custom-select div[class*='singleValue'],
.heading-search .custom-select div[id*='placeholder'] {
    padding-left: 5px;
}

.search_college i {
    position: absolute;
    right: -22px;
    top: 18px;
    font-size: 12px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    cursor: pointer;
}
.search_college {
    overflow: hidden;
}
.search_college .form-control:valid ~ i {
    right: 15px;
}
.search_college .form-search {
    padding: 0 30px 0 35px !important;
}
.icon-loan {
    width: 20px;
    height: 24px;
    display: inline-block;
    background-image: url('https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/loan.svg');
}

.btn-holder {
    width: 181px;
}

/* manage-widget css */

.bg-blue-164F88 {
    background-color: #164f88;
}
.bg-blue-083461 {
    background-color: #083461;
}
.bg-gray-F2F4F7 {
    background-color: #f2f4f7;
}
.bg-gray-EFEFEF {
    background-color: #efefef;
}
.bg-yellow-FFFAE5 {
    background-color: #fffae5;
}
.text-yellow {
    color: #f5ae18 !important;
}
.cursor-pointer {
    cursor: pointer;
}
.white-filter {
    filter: brightness(0) invert(1);
    -webkit-filter: brightness(0) invert(1);
}

.form-control.form-search.form-gray-search {
    background-color: #f2f4f7;
    border: none;
    color: #000;
}
.form-control.form-search.form-gray-search::placeholder {
    color: #000;
}
.opacity-7 {
    opacity: 0.7;
}

.guiding_alumni_block {
    background: #ffffff;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    opacity: 1;
    padding: 20px;
    max-width: 270px;
    transition: all 0.7s ease-in-out;
    -webkit-transition: all 0.7s ease-in-out;
    -moz-transition: all 0.7s ease-in-out;
    -ms-transition: all 0.7s ease-in-out;
    -o-transition: all 0.7s ease-in-out;
}
.active .guiding_alumni_block {
    transform: translatey(0%);
    -webkit-transform: translatey(0%);
    -moz-transform: translatey(0%);
    -ms-transform: translatey(0%);
    -o-transform: translatey(0%);
}
.guiding_alumni_block .guiding_alumni_top {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid #e2e2e2;
    padding-bottom: 12px;
}
.guiding_alumni_profile_img_wrap {
    position: relative;
    width: 80px;
    height: 80px;
    margin-bottom: 8px;
}
.guiding_alumni_profile_img_wrap .guiding_alumni_profile_img {
    margin: 0;
}
.guiding_alumni_profile_img_wrap label {
    background: #27a7df;
    border: 1px solid #fff;
    opacity: 1;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    position: absolute;
    right: 0;
    bottom: 0;
}
.guiding_alumni_profile_img_wrap label .pencil-edit {
    filter: brightness(0) invert(1);
    width: 13px;
    height: 13px;
    background-size: 100%;
    -webkit-filter: brightness(0) invert(1);
}
.guiding_alumni_block .guiding_alumni_top .guiding_alumni_profile_img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 8px;
}
.guiding_alumni_block .guiding_alumni_top .guiding_alumni_profile_name {
    font-size: 20px;
    line-height: 26px;
    font-weight: 600;
    color: #0b3454;
    text-align: center;
}

.guiding_alumni_college_cource {
    padding-top: 14px;
}
.guiding_alumni_college_cource ul {
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: 14px;
}
.guiding_alumni_college_cource ul li:not(:last-child) {
    margin-bottom: 12px;
}
.guiding_alumni_college_cource ul li {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    color: #000;
    font-weight: 500;
}
.guiding_alumni_college_cource ul li i{width: 24px;height: 24px;margin-right: 8px;padding-top: 2px;}
.guiding_alumni_college_cource ul li i.college {
    width: 24px;
    height: 24px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/small-school.svg) no-repeat center;
    margin-right: 8px;
}
.guiding_alumni_college_cource ul li i.brifecase {
    width: 24px;
    height: 24px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/small-brifecase.svg) no-repeat center;
    margin-right: 8px;
}
.guiding_alumni_college_cource ul li i.tool {
    width: 24px;
    height: 24px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/tool.svg) no-repeat center;
    margin-right: 8px;
}
.guiding_alumni_college_cource ul li i.cap {
    width: 24px;
    height: 24px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/graduation-cap.svg) no-repeat center /
        100% !important;
    margin-right: 8px;
}
.guiding_alumni_college_cource ul li i.star {
    width: 24px;
    height: 24px;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23F5AE18' stroke-linecap='' stroke-linejoin='' stroke-width='1' d='M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.56.56 0 0 0-.163-.505L1.71 6.745l4.052-.576a.53.53 0 0 0 .393-.288L8 2.223l1.847 3.658a.53.53 0 0 0 .393.288l4.052.575-2.906 2.77a.56.56 0 0 0-.163.506l.694 3.957-3.686-1.894a.5.5 0 0 0-.461 0z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 18px 18px !important;
    margin-right: 8px;
}
.guiding_alumni_college_cource ul li i.badge {
    width: 24px;
    height: 24px;
    background-color: transparent;
    background-image: url("https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/medal.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 21px !important;
    margin-right: 8px;
    display: block;
}
.guiding_alumni_college_cource ul li i {
    font-size: 18px;
    background-size: 20px !important;
}
.guiding_alumni_college_cource ul li span {
    width: calc(100% - 32px);
    text-align: left;
    font-size: 14px;
    line-height: 17px;
    vertical-align: top;
    margin: 4px 0 0;
}
.guiding_alumni_college_cource ul li span b {
    display: block;
    font-weight: 400;
}

#WidgetsAlumniCarousel .guiding_alumni_block {
    transform: translatey(-150%);
    -webkit-transform: translatey(-150%);
    -moz-transform: translatey(-150%);
    -ms-transform: translatey(-150%);
    -o-transform: translatey(-150%);
}
#WidgetsAlumniCarousel .active .guiding_alumni_block {
    transform: translatey(0%);
    -webkit-transform: translatey(0%);
    -moz-transform: translatey(0%);
    -ms-transform: translatey(0%);
    -o-transform: translatey(0%);
}
#WidgetsAlumniCarousel .bg-white {
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
#WidgetsAlumniCarousel .owl-nav {
    width: 57.5%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: space-around;
    margin-top: 25px;
    margin-left: auto;
}
#WidgetsAlumniCarousel.owl-carousel .owl-nav button {
    border-radius: 25px;
    padding: 0 25px !important;
    line-height: 32px;
    border: 2px solid #7d7d7d;
    height: 40px;
    display: inline-flex;
    text-decoration: none;
    color: #000;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    position: relative;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    background: #fff;
}
#WidgetsAlumniCarousel.owl-carousel .owl-nav button:hover {
    background: #7d7d7d;
    color: #fff;
}
#WidgetsAlumniCarousel.owl-carousel .owl-nav button span {
    font-size: 28px;
    display: inline-block;
    line-height: 0;
    vertical-align: top;
    margin: -4px 0 0;
}
#WidgetsAlumniCarousel.owl-carousel .owl-nav button.owl-prev:after {
    content: 'Previous Profile';
    margin-left: 7px;
}
#WidgetsAlumniCarousel.owl-carousel .owl-nav button.owl-next:before {
    content: 'Next Profile';
    margin-right: 7px;
}
#WidgetsAlumniCarousel .owl-dots {
    display: none;
}

.owl-carousel .owl-stage {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}
.owl-carousel .owl-stage .item {
    height: 100%;
}

.embed-wrap hr {
    border-bottom: 1px dashed #201a00;
    background: none;
}

.fourcardslider .owl-nav,
.twocardslider .owl-nav {
    text-align: left;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}
.fourcardslider .owl-nav button,
.twocardslider .owl-nav button {
    width: 40px;
    height: 40px;
    background: #fff !important;
    font-size: 20px !important;
    display: -webkit-box !important;
    display: -moz-box !important;
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
}
.fourcardslider .owl-nav button span,
.twocardslider .owl-nav button span {
    font-size: 40px !important;
    margin: -7px 0 0;
    vertical-align: top;
}
.owl-theme.fourcardslider .owl-nav,
.owl-theme.twocardslider .owl-nav [class*='owl-']:hover {
    color: #0b3454;
}

.icon-eye {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/eye.svg) no-repeat 0 0 / 100%;
    width: 32px;
    height: 20px;
    display: block;
}
.icon-eye-black {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/eye.svg) no-repeat 0 0 / 100%;
    width: 32px;
    height: 20px;
    display: block;
    filter: brightness(0) invert(0);
    -webkit-filter: brightness(0) invert(0);
}

.ratio20 {
    width: 20px;
    height: 20px;
}

.navdotnone .owl-nav,
.navdotnone .owl-dots {
    display: none !important;
}
.btn-border-add {
    border-radius: 25px;
    padding: 0 25px !important;
    line-height: 32px;
    border: 1px solid #c1c1c1;
    height: 40px;
    display: inline-flex;
    text-decoration: none;
    color: #27a7df;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    position: relative;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    background: #fff;
}
.btn-border-add i {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}
.btn-border-add:hover {
    background-color: #27a7df;
    border: 1px solid #27a7df;
    color: #fff;
}
.btn-border-add:hover i {
    filter: brightness(0) invert(1);
    -webkit-filter: brightness(0) invert(1);
}
a.text-skyblue {
    color: #27a7df;
}
a.text-skyblue:hover {
    color: #0b3454;
}

.Vertical-graph-block {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #dcdcdc;
    border-radius: 6px;
    padding: 30px;
    width: 100%;
    max-width: 400px;
}
.Vertical-graph-block.max-450 {
    max-width: 450px;
}
.Vertical-graph-block h2 {
    text-align: center;
    font-size: 24px;
    color: #000;
    font-weight: 600;
    margin-bottom: 20px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-content: center;
    justify-content: center;
}
.Vertical-graph-block h2 .pencil-edit {
    filter: invert(53%) sepia(95%) saturate(1016%) hue-rotate(165deg) brightness(94%) contrast(86%);
    -webkit-filter: invert(53%) sepia(95%) saturate(1016%) hue-rotate(165deg) brightness(94%) contrast(86%);
}
.Vertical-graph-block h2 a {
    margin-left: 8px;
}
.Vertical-graph-block .bigVerticalProgress {
    justify-content: center;
}
.Vertical-graph-block .bigVerticalProgress .verticleProgress {
    padding: 0 8px;
    width: auto;
    margin: 0;
}
.Vertical-graph-block .bigVerticalProgress .graphContainer .progress {
    width: 40px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    overflow: visible;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
}
.Vertical-graph-block .bigVerticalProgress .graphContainer .progress.lite-blue {
    background-color: #def3fc;
}
.Vertical-graph-block .bigVerticalProgress .graphContainer .progress.lite-blue .progress-bar {
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    background-color: #27a7df;
}
.Vertical-graph-block .bigVerticalProgress .graphContainer .progress.lite-red {
    background-color: #fff2f4;
}
.Vertical-graph-block .bigVerticalProgress .graphContainer .progress.lite-red .progress-bar {
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    background-color: #d42941;
}
.Vertical-graph-block .bigVerticalProgress .graphContainer .progress .progresstext {
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
}

.Vertical-graph-block .HorizontalProgress {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}
.Vertical-graph-block .HorizontalProgress .graphContainer {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding-right: 30px;
    position: relative;
}
.Vertical-graph-block .HorizontalProgress .caption {
    width: 90px;
    text-align: right;
    margin-right: 10px;
    line-height: 16px;
}
.Vertical-graph-block .HorizontalProgress:not(:last-child) {
    margin-bottom: 10px;
}
.Vertical-graph-block .HorizontalProgress .graphContainer .progress {
    height: 40px;
    border-radius: 0;
}
.Vertical-graph-block .HorizontalProgress .graphContainer .progress.lite-orange {
    background-color: #fff4dc;
}
.Vertical-graph-block .HorizontalProgress .graphContainer .progress.lite-orange .progress-bar {
    background-color: #f5ae18;
}
.Vertical-graph-block .HorizontalProgress .graphContainer .progress .progresstext {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: -10px;
    line-height: 40px;
    font-weight: 500;
    font-size: 16px;
}

.btn-trash-right {
    position: absolute;
    top: 13px;
    right: -18px;
    width: 15px;
    height: 15px;
    background-position: center;
    background-size: 15px;
}

.top-college-wrap {
    max-width: 850px;
    width: 96%;
    margin: auto;
    text-align: center;
}
.top-college-wrap .nav {
    background: #164f88;
    border-radius: 6px;
    max-width: 720px;
    margin: auto;
    justify-content: space-between;
}
.top-college-wrap .nav button {
    height: 50px;
    color: #fff;
}
.top-college-wrap .nav button.active {
    background: #f5ae18;
    color: #000;
}
.logo-block {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #c8c8c8;
    border-radius: 6px;
    padding: 20px;
    width: 158px;
    height: 100%;
}
.logo-block .logo-img {
    margin-bottom: 10px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: end;
    justify-content: center;
}
.logo-block .logo-img img {
    width: auto;
}

.top-college-wrap .top-colleges-slider .owl-nav {
    margin-top: 0;
}
.top-college-wrap .top-colleges-slider .owl-nav button {
    position: absolute;
    top: 0;
    bottom: 0;
    background: #fff;
    width: 40px;
    height: 40px;
    border-radius: 3px;
    border: none;
    margin: auto;
    overflow: hidden;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
}
.top-college-wrap .top-colleges-slider .owl-nav button:hover {
    background-color: #000;
}
.top-college-wrap .top-colleges-slider .owl-nav button span {
    font-size: 40px;
    vertical-align: top;
    height: 40px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 34px;
    margin: -5px 0 0;
}
.top-college-wrap .top-colleges-slider .owl-nav button.owl-prev {
    left: -60px;
}
.top-college-wrap .top-colleges-slider .owl-nav button.owl-next {
    right: -60px;
}
.top-college-wrap .top-colleges-slider .owl-nav button.disabled {
    opacity: 0.3;
}

.max-500 {
    width: 96%;
    max-width: 500px;
}

.btn-skyblue {
    height: 30px;
    background: #27a7df;
    margin: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 25px;
    font-weight: 500 !important;
    cursor: pointer;
    border: 0;
    padding: 5px 20px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    text-decoration: none !important;
}
.btn-skyblue:hover {
    background-color: #0b3454;
    color: #fff;
}

.modal .colleges-list {
    max-height: 340px;
    overflow: auto;
}
.modal .colleges-list ul {
    margin: 0;
    padding: 0;
}
.modal .colleges-list ul li {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
}
.modal .colleges-list ul li:not(:last-child) {
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #ccc;
}
.modal .colleges-list ul li .logoImg {
    width: 90px;
    margin-right: 15px;
}
.modal .colleges-list ul li .logoName {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(100% - 105px);
}
.modal .colleges-list ul li .logoName p {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
}
.modal .colleges-list ul li .logoName span {
    font-style: italic;
}
.modal .colleges-list ul li .logoImg.w-150 {
    width: 150px;
    text-align: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
}
.modal .colleges-list ul li .logoImg + div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
}
.modal .colleges-list ul li .logoImg + div p {
    margin: 0;
}

.leftPaneTab {
    max-width: 280px;
    text-align: left;
}
.leftPaneTab .nav-link {
    text-align: left;
    background: #164f88;
    border-radius: 6px;
    min-height: 76px;
    color: #fff;
    padding: 20px;
    line-height: 18px;
    position: relative;
}
.leftPaneTab .nav-link::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: calc(100% - 15px);
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 13px solid transparent;
    width: 0;
    height: 0;
    margin: auto;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.leftPaneTab .nav-link:not(:last-child) {
    margin-bottom: 10px;
}
.leftPaneTab .nav-link.active {
    background: #27a7df;
}
.leftPaneTab .nav-link.active::before {
    border-left: 13px solid #27a7df;
    left: 100%;
}

.testimonial_block {
    background: #164f88;
    border-radius: 6px;
    padding: 30px;
    color: #fff;
}
.testimonial_block .testimonial_img_brife {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    border-bottom: 1px solid #3a7ab9;
    margin-bottom: 15px;
}
.testimonial_block .testimonial_img_brife .testimonial_img_wrap {
    position: relative;
    width: 80px;
    height: 80px;
    margin-right: 20px;
}
.testimonial_block .testimonial_img_brife .testimonial_img_wrap .testimonial_img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
}
.testimonial_block .testimonial_img_brife .testimonial_img_wrap label {
    background: #27a7df;
    border: 1px solid #fff;
    opacity: 1;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    position: absolute;
    right: 0;
    bottom: 0;
}
.testimonial_block .testimonial_img_brife .testimonial_img_wrap label .pencil-edit {
    filter: brightness(0) invert(1);
    width: 13px;
    height: 13px;
    background-size: 100%;
    -webkit-filter: brightness(0) invert(1);
}
.testimonial_block .testimonial_img_brife .testimonial_brife {
    width: calc(100% - 100px);
}
.testimonial_block label {
    margin-left: 4px;
    cursor: pointer;
}
.testimonial_block .pencil-edit {
    filter: brightness(0) invert(1);
    -webkit-filter: brightness(0) invert(1);
}
.testimonial_detail p:first-child::before {
    content: '';
    display: inline-block;
    width: 27px;
    height: 20px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/quote.svg) no-repeat 0 0;
    margin-right: 10px;
    position: relative;
    top: 5px;
}

.directory-stats-wrap {
    background: #fff;
    width: 100%;
    padding: 30px;
    border-radius: 5px;
}
.directory-stats-wrap ul {
    padding: 0;
    margin: 0;
    list-style: none;
}
.directory-stats-wrap ul li {
    position: relative;
    padding: 20px 20px 20px 41px;
    background: #f2f4f7;
    min-height: 50px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
}
.directory-stats-wrap ul li:before {
    content: '';
    width: 10px;
    height: 24px;
    border-left: 4px dotted #bdbdbd;
    border-right: 4px dotted #bdbdbd;
    position: absolute;
    left: 17px;
    top: 0;
    margin: auto;
    bottom: 0;
}
.directory-stats-wrap ul li:not(:last-child) {
    margin-bottom: 10px;
}
.directory-stats-wrap ul li .stats-name-detail span {
    font-size: 18px;
    color: #214764;
    margin-right: 6px;
    line-height: 22px;
}
.directory-stats-wrap ul li .stats-name-detail b {
    color: #5cae48;
    font-size: 22px;
}
.directory-stats-wrap ul li .stats-name-detail {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
}
.del-directory-item {
    background: #ffdfe4;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-left: 15px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
}
.del-directory-item i {
    width: 14px;
    height: 14px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/trash.svg) no-repeat center / 100%;
    opacity: 0.8;
}

.alumni_network {
    background: #fff;
}
.alumni_network .sub-title {
    font-size: 40px;
    font-weight: 500;
    color: #2d2d2d;
    text-align: center;
    line-height: 50px;
    margin: 0;
}
.alumni_network_count {
    overflow-y: hidden;
    overflow-x: auto;
    scrollbar-width: thin;
    padding-bottom: 8px;
}
.alumni_network_count::-webkit-scrollbar {
    height: 4px;
}
.alumni_network_count::-moz-scrollbar {
    height: 4px;
}
.alumni_network_count::-webkit-scrollbar-track {
    background: #ccc;
}
.alumni_network_count::-webkit-scrollbar-thumb {
    background-color: #666;
    border-radius: 20px;
    border: 2px solid #666;
}
.alumni_network_count ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: start;
}
.alumni_network_count li {
    background: #ffffff;
    box-shadow: 0px 3px 6px #0000001a;
    border: 1px solid #0034571a;
    border-radius: 10px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
    width: 220px;
    min-width: 220px;
    text-align: center;
}
.alumni_network_count li b {
    color: #5cae48;
    font-size: 45px;
    line-height: 60px;
}
.alumni_network_count li:not(:last-child) {
    margin-right: 10px;
}
.alumni_network_count li p {
    margin: 7px 0 0 !important;
    text-align: center;
    font-size: 18px;
    line-height: 20px;
    font-weight: 500;
    opacity: 1;
    color: #0b3454;
    padding: 0;
}

.btn-video-del {
    background: #fff;
    width: auto;
    height: auto;
    border-radius: 25px;
    margin-left: 15px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
    padding: 6px 15px;
    top: 10px;
    right: 10px;
    color: #000;
    text-decoration: none !important;
    border: 2px solid transparent;
}
.btn-video-del i {
    width: 14px;
    height: 14px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/trash.svg) no-repeat center / 100%;
    margin-right: 4px;
}
.btn-video-del:hover {
    background: #c90000;
    border: 2px solid #fff;
    color: #fff;
}
.btn-video-del:hover i {
    filter: brightness(0) invert(1);
    -webkit-filter: brightness(0) invert(1);
}
.nav-pills.slider-tab {
    width: max-content;
}
.nav-pills.slider-tab .nav-item:not(:last-child) {
    margin-right: 10px;
}
.nav-pills.slider-tab .nav-link {
    padding: 7px 20px;
    border: 1px solid #a4b3be;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    color: #0b3454;
    position: relative;
}
.nav-pills.slider-tab .nav-link:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: calc(100% - 8px);
    margin: auto;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid transparent;
    width: 0;
    height: 0;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.nav-pills.slider-tab .nav-link.active {
    background: #0b3454;
    border-color: #0b3454;
    color: #fff;
}
.nav-pills.slider-tab .nav-link.active:before {
    bottom: 100%;
    border-bottom-color: #0b3454;
}
.slider-tab .btn-add {
    padding: 7px 15px 7px 10px;
    border: 1px solid #a4b3be;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    color: #0b3454;
    background: #fff;
}
.slider-tab .btn-add i {
    filter: invert(13%) sepia(330%) saturate(6748%) hue-rotate(188deg) brightness(50%) contrast(91%);
    -webkit-filter: invert(13%) sepia(330%) saturate(6748%) hue-rotate(188deg) brightness(50%) contrast(91%);
}
.slider-tab .btn-add:hover {
    background: #0b3454;
    border: 1px solid #0b3454;
    color: #fff;
}
.slider-tab .btn-add:hover i {
    filter: brightness(0) invert(1);
    -webkit-filter: brightness(0) invert(1);
}
.univariety_main_banner {
    padding: 0;
}
.univariety_main_banner_container {
    position: relative;
}
.univariety_main_banner_container img {
    position: relative;
    vertical-align: top;
}
.main_banner_text {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    max-width: 48%;
    text-align: center;
    color: #fff;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.banner_title {
    font-size: 60px;
    color: #fff;
    font-weight: 600;
    line-height: 70px;
    max-width: 760px;
    margin: 0 0 20px;
}
.max-1140 .banner_title {
    font-size: 46px;
    line-height: 50px;
}
.banner_sub_title {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 40px;
    color: #fff;
}
.btn-watch {
    width: 175px;
    height: 50px;
    border: 1px solid #5cae48;
    text-decoration: none !important;
    border-radius: 5px;
    background: #5cae48;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.btn-watch:hover {
    background: #fff;
    border-color: #fff;
    color: #27377b;
}
.btn-watch i {
    width: 22px;
    height: 22px;
    background: #fff;
    border-radius: 50%;
    margin-right: 10px;
    position: relative;
}
.btn-watch i::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 7px solid #5cae48;
    top: 6px;
    left: 9px;
}
.btn-watch:hover i {
    background: #28377c;
}
.btn-watch:hover i:before {
    border-left-color: #fff;
}

.preview-slider#alumni-card-slider .owl-dots {
    display: none;
}
.preview-slider#alumni-card-slider .owl-nav {
    display: block !important;
}
.preview-slider#alumni-card-slider .owl-nav button {
    background: #fff;
    width: 40px;
    height: 50px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto !important;
    border-radius: 0 6px 6px 0;
    font-size: 0;
}
.preview-slider#alumni-card-slider .owl-nav button::before {
    content: '';
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='black' stroke='black' stroke-linecap='round' stroke-linejoin='round' stroke-width='1' d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: left 5px center;
}
.preview-slider#alumni-card-slider .owl-nav button.owl-prev {
    left: 0;
}
.preview-slider#alumni-card-slider .owl-nav button.owl-next {
    right: 0;
    transform: scaleX(-1);
}

@media (max-width: 991px) {
    .Vertical-graph-block .bigVerticalProgress {
        max-width: max-content;
        width: max-content;
    }
    .top-college-wrap .top-colleges-slider .owl-nav {
        margin-top: 10px;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        justify-content: center;
    }
    .top-college-wrap .top-colleges-slider .owl-nav button {
        position: static;
        margin: 5px;
    }
    .banner_title {
        font-size: 34px;
        line-height: 36px;
    }
    .banner_sub_title {
        font-size: 20px;
        line-height: 22px;
        margin-bottom: 20px;
    }
}

@media (max-width: 767px) {
    #WidgetsAlumniCarousel .owl-nav {
        width: 100%;
        justify-content: center;
    }
    .guiding_alumni_block {
        transform: translatey(0%);
        -webkit-transform: translatey(0%);
        -moz-transform: translatey(0%);
        -ms-transform: translatey(0%);
        -o-transform: translatey(0%);
    }
    .top-college-wrap .nav {
        width: max-content;
    }
    .leftPaneTab {
        max-width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        padding-bottom: 20px;
    }
    .leftPaneTab .nav-link {
        max-width: 280px;
    }
    .leftPaneTab .nav {
        width: max-content;
    }
    .leftPaneTab .nav-link:not(:last-child) {
        margin: 0 10px 0 0;
    }
    .leftPaneTab .nav-link::before {
        position: absolute;
        top: calc(100% - 0px);
        bottom: auto;
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;
        border-left: 15px solid transparent;
        width: 0;
        height: 0;
        margin: auto;
        left: 0;
        right: 0;
        border-right: 15px solid transparent;
    }
    .leftPaneTab .nav-link.active::before {
        border-left: 13px solid transparent;
        top: 100%;
        border-top: 13px solid #27a7df;
        left: 0;
    }
    .banner_title {
        font-size: 50px;
        line-height: 59px;
    }
    .banner_sub_title {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 20px;
    }
}

@media (max-width: 575px) {
    #WidgetsAlumniCarousel.owl-carousel .owl-nav button {
        padding: 0 15px !important;
    }
    #WidgetsAlumniCarousel.owl-carousel .owl-nav button span {
        display: none;
    }
    .position-static-for-mobile {
        position: static !important;
    }
    .directory-stats-wrap {
        padding: 10px;
    }
    .main_banner_text {
        max-width: 90%;
    }
    .banner_title {
        font-size: 50px;
        line-height: 59px;
    }
    .banner_sub_title {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 20px;
    }
}

.lstCustForm .custom-input-wrap .select__value-container {
    flex-wrap: wrap;
    height: auto;
    max-height: 141px;
    overflow: auto;
}

input[type='date']::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

/* Alumni QA */

.bg-liteblue-F2F9FF {
    background-color: #f2f9ff;
}

.chat-tab .nav-link {
    background: #ffffff;
    border: 1px solid #0b345459;
    border-radius: 25px;
    opacity: 1;
    padding: 10px 30px;
    color: #0b3454;
}
.chat-tab .nav-item:not(:last-child) {
    margin-right: 10px;
}
.chat-tab .nav-link.active {
    background: #0b3454;
    color: #fff;
}

.alumni-chat-img {
    position: absolute;
    bottom: -114px;
    right: 0;
    max-width: 240px;
}

.header-title .icon_message {
    width: 35px;
    height: 35px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/chat.svg) no-repeat 0 0 / 35px;
    margin-right: 10px;
}

.alumni_chat_profile_list {
    min-width: 300px;
    width: 100%;
}
.alumni_chat_profile_list .heading {
    font-size: 18px;
    font-weight: 500;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
    padding: 18px 0;
    border-bottom: 1px solid #d6d6d6;
    height: auto;
}
.alumni_chat_profile_list .heading .icon_message {
    width: 30px;
    height: 30px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/chat.svg) no-repeat 0 0 / 30px;
    margin-right: 10px;
}
.alumni_chat_profile_list ul {
    padding: 0;
    margin: 0;
    list-style: none;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 500px;
}
.alumni_chat_profile_list ul::-webkit-scrollbar {
    width: 4px;
}
.alumni_chat_profile_list ul::-webkit-scrollbar-track {
    background: #f1f1f1;
}
.alumni_chat_profile_list ul::-webkit-scrollbar-thumb {
    background: #888;
}
.alumni_chat_profile_list ul::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.alumni_chat_profile_list ul li {
    padding: 15px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.alumni_chat_profile_list ul li.unread {
    background: #f3f9ff;
}
.alumni_chat_profile_list ul li:not(:last-child) {
    border-bottom: 1px solid #d6d6d6;
}
.alumni_chat_profile_list ul li a {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #000000;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
}
.alumni_chat_profile_list ul li a .img-alumni {
    border-radius: 50%;
    margin-right: 10px;
    overflow: hidden;
    width: 60px;
}
.alumni_chat_profile_list ul li a .alumni-name-batch {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
}
.alumni_chat_profile_list ul li a .alumni-name-batch .alumni-batch {
    color: #6a6868;
    font-size: 14px;
}
.alumni_chat_profile_list ul li a .alumni-name-batch .alumni-message {
    font-size: 14px;
    font-weight: 500;
    color: #6a6868;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    width: 100%;
    max-width: 165px;
}
.alumni_chat_profile_list ul li a.del-alumni {
    background: #ffdfe4;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: 15px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
}
.alumni_chat_profile_list ul li a.del-alumni i {
    width: 18px;
    height: 18px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/trash.svg) no-repeat center / 100%;
    opacity: 0.8;
}
.text-student {
    color: #c38400;
}
.text-school {
    color: #26a7df;
}
.text-alumni {
    color: #d42941;
}

.alumni_chat_block {
    background: #f2f9ff
        url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/question_marks_background.png);
    padding: 0px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 565px;
}
.alumni_chat_to {
    padding: 30px 30px 0;
}
.alumni_chat_to label {
    font-weight: 500;
    font-size: 18px;
}
/* .sent_too_wrap .sent_too {background: #FFFFFF;border: 1px solid #0B345459;border-radius: 25px;opacity: 1;padding: 0 18px;height: 35px;display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;align-items: center;} */
.sent_too_wrap .sent_too {
    background: #ffffff;
    border: 1px solid #0b345459;
    border-radius: 25px;
    opacity: 1;
    padding: 0 18px;
    height: 35px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
}
.count-unread {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #5cae48;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
}
.blue-block {
    background: transparent linear-gradient(104deg, #0062af 0%, #175381 100%) 0% 0% no-repeat padding-box;
    padding: 24px 30px;
    color: #fff;
    font-size: 18px;
    line-height: 22px;
    position: relative;
}
.blue-block:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
}
.blue-block p:last-child {
    margin: 0;
}
.blue-block ol {
    padding: 0 0 0 21px;
}
.blue-block ol:last-child {
    margin: 0;
}
.justify-content-end .blue-block {
    margin-right: 8px;
    border-radius: 6px 6px 0px 6px;
    -webkit-border-radius: 6px 6px 0px 6px;
    -moz-border-radius: 6px 6px 0px 6px;
    -ms-border-radius: 6px 6px 0px 6px;
    -o-border-radius: 6px 6px 0px 6px;
}
.justify-content-end .blue-block:after {
    right: -8px;
    bottom: 0px;
    border-left: 5px solid #175381;
    border-bottom: 5px solid #175381;
    border-top: 5px solid transparent;
    border-right: 5px solid transparent;
}
.justify-content-start .blue-block {
    margin-left: 8px;
    border-radius: 6px 6px 6px 0px;
    -webkit-border-radius: 6px 6px 6px 0px;
    -moz-border-radius: 6px 6px 6px 0px;
    -ms-border-radius: 6px 6px 6px 0px;
    -o-border-radius: 6px 6px 6px 0px;
}
.justify-content-start .blue-block:after {
    left: -8px;
    bottom: 0px;
    border-left: 5px solid transparent;
    border-bottom: 5px solid #0161ac;
    border-top: 5px solid transparent;
    border-right: 5px solid #0161ad;
}
.alumni_chat_conversation_send .form-control {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #0b345459;
    border-radius: 10px;
    opacity: 1;
}
.alumni_chat_conversation_block {
    max-height: 395px;
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 395px;
    padding: 0 30px;
}
.alumni_chat_conversation_send {
    padding: 0px 30px 30px 30px;
}
.get_started {
    text-align: center;
}
.get_started .get_started_like {
    width: 54px;
    height: 62px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/star-like.svg) no-repeat 0 0;
    margin-bottom: 20px;
}
.get_started h3 {
    font-size: 28px;
    font-weight: 600;
}

.liteblue-border-block {
    background: #fff;
    padding: 24px 30px;
    color: #000;
    font-size: 18px;
    line-height: 22px;
    position: relative;
    border: 1px solid #afdafb;
}
.liteblue-border-block::after {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    background: #fff;
}
.justify-content-end .liteblue-border-block {
    margin-right: 8px;
    border-radius: 6px 6px 0px 6px;
    -webkit-border-radius: 6px 6px 0px 6px;
    -moz-border-radius: 6px 6px 0px 6px;
    -ms-border-radius: 6px 6px 0px 6px;
    -o-border-radius: 6px 6px 0px 6px;
}
.justify-content-end .liteblue-border-block::after {
    right: -8px;
    bottom: -1px;
    border-left: 1px solid #fff;
    border-bottom: 1px solid #afdafb;
    border-top: 1px solid #fff;
    border-right: 1px solid #afdafb;
    transform: skew(44deg);
    -webkit-transform: skew(44deg);
    -moz-transform: skew(44deg);
    -ms-transform: skew(44deg);
    -o-transform: skew(44deg);
}
.justify-content-start .liteblue-border-block {
    margin-left: 8px;
    border-radius: 6px 6px 6px 0px;
    -webkit-border-radius: 6px 6px 6px 0px;
    -moz-border-radius: 6px 6px 6px 0px;
    -ms-border-radius: 6px 6px 6px 0px;
    -o-border-radius: 6px 6px 6px 0px;
}
.justify-content-start .liteblue-border-block::after {
    left: -8px;
    bottom: -1px;
    border-left: 1px solid #afdafb;
    border-bottom: 1px solid #afdafb;
    border-top: 1px solid #fff;
    border-right: 1px solid #fff;
    transform: skew(44deg);
    -webkit-transform: skew(-44deg);
    -moz-transform: skew(44deg);
    -ms-transform: skew(44deg);
    -o-transform: skew(44deg);
}
.liteblue-border-block p:last-child {
    margin: 0;
}
.liteblue-border-block ol {
    padding: 0 0 0 21px;
}
.liteblue-border-block ol:last-child {
    margin: 0;
}

.get_started_prompt a, .get_started_prompt button { display: inline-block; background: #f1feff; border: 1px solid #9dc3e9; border-radius: 25px; opacity: 1; padding: 8px 30px; text-decoration: none; font-size: 18px; color: #0B3454; transition:all 0.3s ease-in-out ; -webkit-transition:all 0.3s ease-in-out ; -moz-transition:all 0.3s ease-in-out ; -ms-transition:all 0.3s ease-in-out ; -o-transition:all 0.3s ease-in-out ; -webkit-border-radius:; -moz-border-radius:; -ms-border-radius:; -o-border-radius:; }
.get_started_prompt a:hover,.get_started_prompt button:hover {background: #bae8ff;color: #000;}

.get_started_prompt a.highlight, .get_started_prompt button.highlight {background: #fff1d1;border-color: #ffbf00;color: #895900;}
.get_started_prompt a.highlight:hover, .get_started_prompt button.highlight:hover{background: #ffbf00;border-color: #ffbf00;color: #000;}

.chat_now {
    background: url(../images/bg-chat-now.png) no-repeat center / cover;
    padding: 15px;
    border-radius: 5px;
    margin: 20px 30px 0 30px;
}
.chat_now + .alumni_chat_conversation_block {
    max-height: 285px;
    min-height: 285px;
}

.alumni_chat_conversation_header {
    padding: 16px 30px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d4e1ee;
    position: relative;
}
.alumni_chat_conversation_header > i {
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    height: 17px;
    margin: auto;
    width: 30px;
    text-align: center;
    cursor: pointer;
}
.alumni-user-leftpane {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
}
.alumni-user-leftpane .img-alumni {
    margin-right: 10px;
}
.alumni-user-leftpane .alumni-name-batch {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
}
.alumni-user-leftpane img {
    width: 50px;
    height: 50px;
}
.alumni-user-leftpane .alumni-name-batch .alumni-name {
    font-size: 18px;
    line-height: 22px;
    color: #000;
    font-weight: 500;
}
.alumni-message {
    color: #6a6868;
    font-size: 14px;
}
.mark-star label {
    background: #f3ac19;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.mark-star label .icon-star {
    font-size: 13px;
}
.mark-star input:checked ~ label,
.mark-star.selected input ~ label {
    background: #5cae48;
}

.btn-alumni-guidance {
    background: #d52941;
    box-shadow: 0px 3px 14px #ab162b96;
    border-radius: 32px;
    position: fixed;
    right: 25px;
    bottom: 25px;
    color: #fff;
    text-decoration: none;
    width: 163px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
    padding: 12px 20px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.btn-alumni-guidance i {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/light-bulb.svg) no-repeat 0 0;
    width: 35px;
    height: 39px;
    margin-right: 5px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.btn-alumni-guidance span {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    line-height: 16px;
}
.btn-alumni-guidance:hover {
    background: #fec458;
}
.btn-alumni-guidance:hover i {
    filter: invert(24%) sepia(29%) saturate(5786%) hue-rotate(333deg) brightness(93%) contrast(95%);
    -webkit-filter: invert(24%) sepia(29%) saturate(5786%) hue-rotate(333deg) brightness(93%) contrast(95%);
}

.post-block {
    background: #ffffff;
    border: 1px solid #d2d2d2;
    border-radius: 6px;
    opacity: 1;
    padding: 30px;
}
.post-block:not(:last-child) {
    margin-bottom: 20px;
}
.post-block .post-img {
    margin-top: 20px;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    overflow: hidden;
}
.post-block .post-img img {
    width: auto;
    height: 100%;
}
.post-block .brife {
    margin: 0;
    max-width: 700px;
} /*overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;*/
.post-head {
    background: #ffecc5 0% 0% no-repeat padding-box;
    border-radius: 4px;
    display: inline-block;
    font-size: 12px;
    padding: 4px 10px;
    font-weight: 500;
    margin-bottom: 5px;
}
.post-alumni-name {
    font-size: 18px;
    font-weight: 600;
}
.post-alumni-batch {
    color: #6a6868;
    font-size: 14px;
    font-weight: 500;
}

.answered_wrap .post-head {
    background: #bdf6af;
}
.answered_wrap .post-img {
    margin: 0;
    width: 40px;
    height: 40px;
}
.answered_wrap p {
    margin: 0;
}

.more-answer {
    margin-left: 55px;
    color: #26a7df;
}
.more-answer:hover {
    margin-left: 55px;
    color: #0b8ac2;
}
.tags-wrap {
    max-width: 745px;
}
.tags-wrap span {
    background: #fee9bd;
    border-radius: 15px;
    padding: 5px 18px;
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 500;
}
.tags-wrap span:not(:last-child) {
    margin-right: 5px;
}
.tags-wrap span + span {
    background: #c2e9fe;
}
.tags-wrap span + span + span {
    background: #fed7dc;
}
.tags-wrap span + span + span + span {
    background: #f8bef6;
}
.tags-wrap span + span + span + span + span {
    background: #e5d8ff;
}

.video_block_img .icon-invert-play {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    font-size: 50px;
    color: white;
    z-index: 9;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.9;
}

.btn-gray-edit {
    background: #ffffff;
    border: 1px solid #d1d1d1;
    border-radius: 18px;
    padding: 8px 18px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    color: #26a7df;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.btn-gray-edit:hover {
    background: #0b3454;
    border-color: #0b3454;
}
.btn-gray-edit i {
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.btn-gray-edit:hover .pencil-edit {
    filter: brightness(0) invert(1);
    -webkit-filter: brightness(0) invert(1);
}

.mark-flag .icon-flag {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/black-flag.svg) no-repeat 0 0 / 20px;
    width: 20px;
    height: 20px;
    margin-right: 5px;
}
.mark-flag label {
    cursor: pointer;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}
.mark-flag input:checked ~ label .icon-flag {
    filter: invert(50%) sepia(99%) saturate(3201%) hue-rotate(71deg) brightness(96%) contrast(103%);
    -webkit-filter: invert(50%) sepia(99%) saturate(3201%) hue-rotate(71deg) brightness(96%) contrast(103%);
}
.mark-flag input:checked ~ label span {
    font-weight: 500;
    color: #5cae48;
}
.horizontal-divider {
    border-left: 1px solid #d2d2d2;
    margin: 0 3px;
}

.conversation-badge {
    background: #ffffff;
    border: 1px solid #c5c5c5f2;
    opacity: 1;
    width: 40px;
    height: 40px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #4a7394;
    font-size: 22px;
}

@media (max-width: 767px) {
    .alumni_chat_block {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 99;
        min-height: 0;
    }
    .alumni_chat_conversation_block {
        flex: 0;
        flex-basis: calc(100% - 183px);
        min-height: initial;
        max-height: initial;
    }
    .get_started h3{
        font-size: 20px;
    }
    .get_started_prompt a, .get_started_prompt button{
        font-size: 15px;
    }
}

@media (max-width: 575px) {
    .alumni-chat-img {
        position: absolute;
        bottom: -28px;
        right: 0;
        max-width: 130px;
    }
    .post-block {
        padding: 20px;
    }
}

@media (max-width: 480px) {
    .post-brife-answer {
        margin-left: -77px;
    }
}

/* widget 13062024 css */

.filter-white {
    filter: brightness(0) invert(1);
    -webkit-filter: brightness(0) invert(1);
}

.left-100 {
    left: 100%;
}
nav .new {
    font-size: 14px;
    background: #5cae48;
    padding: 0 10px;
    border-radius: 25px;
    color: #fff;
    margin-left: 5px;
}

.filter-toggle-wrap {
    position: absolute;
    top: -10px;
    right: 30px;
}
.filter-toggle-wrap a {
    background: #ffffff;
    border: 1px solid #b4b4b4;
    border-radius: 20px;
    padding: 7px 25px;
    text-decoration: none;
    display: inline-block;
    color: #474747;
    margin: 0 2px;
}
.filter-toggle-wrap a.active {
    background: #5cae48;
    color: #fff;
    border-color: #5cae48;
}

.filter-sort {
    position: absolute;
    right: 40px;
    top: 13px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    font-size: 14px;
}
.filter-sort .double-arrow {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}
.filter-sort .double-arrow:before {
    content: '';
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid #949494;
    margin-bottom: 1px;
}
.filter-sort .double-arrow:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #949494;
}

.verify_massrecruiter {
    margin: -6px 0 0 70px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    width: calc(100% - 70px);
}
.verify_massrecruiter ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    color: #0b3454;
}
.verify_massrecruiter ul li {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
}
.verify_massrecruiter ul li i {
    margin-right: 5px;
}
.verify_massrecruiter ul .verify {
    width: 19px;
    height: 24px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/green-verified.svg) no-repeat 0 0 /
        100%;
    display: inline-block;
}
.verify_massrecruiter ul .massrecruiter {
    width: 23px;
    height: 23px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/search-user.svg) no-repeat 0 0 / 100%;
    display: inline-block;
}
.verify_massrecruiter ul li:not(:last-child) {
    border-right: 1px solid #dedcdc;
    padding-right: 10px;
    margin-right: 10px;
}
.confirmRecruiter {
    background: #ffffff;
    border: 2px solid #474747;
    border-radius: 18px;
    padding: 3px 15px;
}

.alumni_univarsity_subject i.icon-brifecase {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/small-brifecase.svg) no-repeat 0 0 /
        100%;
    filter: brightness(0) invert(0);
    -webkit-filter: brightness(0) invert(0);
}

.report-block-list ul.verticleListDesktop li {
    width: 100%;
    padding: 0;
}
.report-block-list ul li:not(:last-child) {
    margin-bottom: 5px;
}
.report-block-list ul li span {
    width: calc(100% - 22px);
    line-height: 18px;
}
.report-block-list ul li i.lite-dark-blue {
    background: #256298;
}

.queriesStatsVerticleReport-col.single-progress .progress {
    width: 100%;
    background-color: #f1f1f160;
}
.queriesStatsVerticleReport-col.single-progress .progress .progress-bar::before {
    bottom: calc(100% + 2px);
    color: black;
    font-size: 20px;
    font-weight: 500;
}
.mint-green {
    background-color: #4bc0c0;
}
.lite-dark-blue {
    background-color: #256298;
}
.dark-yellow {
    background-color: #efd17d;
}
.queriesStatsVerticleReport-col .dark-blue {
    background-color: #0b3454;
}
.queriesStatsVerticleReport-col .lite-blue {
    background-color: #26a7df;
}
.queriesStatsVerticleReport-col.single-progress.w-78 {
    width: 78px;
}
.queriesStatsVerticleReport .queriesStatsVerticleReport-col.single-progress.w-78 .progress-wrap {
    height: 275px;
}
.queriesStatsVerticleReport-col.single-progress.w-78:not(:last-child) {
    margin-right: 12px;
}

.verticalslant {
    padding: 40px 0 0 12px;
    margin: 0;
    text-orientation: mixed;
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    white-space: nowrap;
    writing-mode: vertical-rl;
    -webkit-writing-mode: vertical-rl;
    -ms-writing-mode: vertical-rl;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-weight: 500;
}

.message_spotlight h2 {
    text-align: center;
    position: relative;
    margin: 0 0 50px;
    font-size: 26px;
}
.message_spotlight h2:before {
    content: '';
    position: absolute;
    top: calc(100% + 12px);
    left: 0;
    right: 0;
    margin: auto;
    width: 60px;
    height: 3px;
    background: #d42941;
}
.message_spotlight .message_university_block h2:before {
    background: #5cae48;
}
.alumni_spotlight_block .alumni-card-wrap .alumni-card-slider .alumni-card {
    padding-top: 0;
}

.x-scroll {
    overflow-y: hidden;
    overflow-x: auto;
    scrollbar-width: thin;
    padding-bottom: 10px;
    margin-bottom: 10px;
}
.x-scroll::-webkit-scrollbar {
    height: 4px;
}
.x-scroll::-moz-scrollbar {
    height: 4px;
}
.x-scroll::-webkit-scrollbar-track {
    background: #ccc;
}
.x-scroll::-webkit-scrollbar-thumb {
    background-color: #666;
    border-radius: 20px;
    border: 2px solid #666;
}
.welcome .custom_tab .nav {
    margin: 0;
    min-width: 950px;
    border-bottom: 2px solid #e4edf4;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
}
.welcome .custom_tab .nav .nav-item {
    border: 0;
    margin: 0;
}
.welcome .custom_tab .nav .nav-item .nav-link {
    box-shadow: none;
    border: 0 !important;
    margin: 0;
    overflow: visible;
    background: none;
    font-size: 20px;
    font-weight: 600;
    padding: 23px;
    position: relative;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    cursor: pointer;
    white-space: nowrap;
    color: #000;
}
.welcome .custom_tab .nav .nav-item .nav-link.active {
    color: #5cae48;
}
.welcome .custom_tab .nav .nav-item .nav-link:hover {
    color: #000;
}
.welcome .custom_tab .nav .nav-item .nav-link::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -4px;
    width: 0;
    background: #5cae48;
    height: 5px;
    border-radius: 0;
    margin: auto;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.welcome .custom_tab .nav .nav-item .nav-link::after {
    display: none;
}
.welcome .custom_tab .nav .nav-item .nav-link.active:before {
    width: 100%;
}
.welcome .custom_tab .tab-content {
    background: none;
    padding: 40px 0 0;
    border: none;
}
.welcome .custom_tab .nav-tabs > li.active > a:focus,
.welcome .nav-tabs > li > a:hover {
    background: none;
}
.custom_tab .row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
}
.custom_tab .vc_row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}
.flex-row-reverse {
    flex-direction: row-reverse;
}
.custom_tab {
    margin-top: 30px;
}
.custom_tab h3 {
    font-size: 32px;
    line-height: 32px;
    font-weight: 500;
    color: #000 !important;
    margin: 0 0 28px;
    text-transform: capitalize;
    position: relative;
    padding-bottom: 0;
}
.checked-list {
    padding: 0;
    margin: 0 0 30px;
    list-style: none;
    font-size: 18px;
    line-height: 26px;
}
.checked-list li:not(:last-child) {
    margin-bottom: 24px;
}
.checked-list li {
    padding: 0 0 0 26px;
    position: relative;
    color: #000;
    font-size: 18px;
    line-height: 26px;
}
.checked-list li:before {
    content: '';
    position: absolute;
    left: 0;
    top: 5px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/check.svg) no-repeat 0 0;
    width: 16px;
    height: 16px;
}
.btn-signUp {
    background: #5cae48;
    opacity: 1;
    width: 140px;
    height: 40px;
    line-height: 40px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    position: relative;
    border-radius: 25px;
    overflow: hidden;
    border: 2px solid #5cae48;
    text-transform: capitalize;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    text-decoration: none;
}
.btn-signUp:hover {
    background: #fff;
    color: #0b3454;
    text-decoration: none !important;
    border-color: #0b3454;
}

.threecardslider .item {
    padding: 4px;
}
.gray-box {
    box-shadow: 0px 2px 3px #0000001a;
    border: 1px solid #0034571a;
    border-radius: 10px;
    padding: 30px;
    overflow: hidden;
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.gray-box > div {
    position: relative;
}
.gray-box:before {
    content: '';
    height: 80px;
    background: #f0f0f0;
    position: absolute;
    left: -10px;
    right: -10px;
    top: -10px;
    transform: skewY(1deg);
    -webkit-transform: skewY(1deg);
    -moz-transform: skewY(1deg);
    -ms-transform: skewY(1deg);
    -o-transform: skewY(1deg);
}
.gray-box-img-wrap {
    width: 80px;
    height: 80px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/star-graph.svg) no-repeat center /
        100%;
    margin-bottom: 20px;
    padding: 10px;
}
.gray-box-img-wrap .gray-box-img {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border: 2px solid #fff;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.gray-box .brife {
    text-align: center;
    line-height: 20px;
    position: relative;
}
.gray-box-post-date {
    position: relative;
    margin-top: 15px;
    padding-top: 15px;
    text-align: center;
    font-size: 14px;
}
.gray-box-post-date:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: -1px;
    width: 50px;
    height: 3px;
    background: #53a132;
    margin: auto;
}

.threecardslider.owl-theme .owl-dots {
    display: none;
}
/* .threecardslider.owl-theme .owl-nav {display: block !important;margin: 0;} */
.threecardslider.owl-theme .owl-nav button {
    background: #fff;
    width: 36px;
    height: 36px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto !important;
    border: 1px solid #0b34544d;
    border-radius: 10px;
    font-size: 0;
}
.threecardslider.owl-theme .owl-nav button::before {
    content: '';
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='black' stroke='black' stroke-linecap='round' stroke-linejoin='round' stroke-width='1' d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background-size: 18px;
    background-repeat: no-repeat;
    background-position: left 7px center;
}
.threecardslider.owl-theme .owl-nav button.owl-prev {
    left: -50px;
}
.threecardslider.owl-theme .owl-nav button.owl-next {
    right: -50px;
    transform: scaleX(-1);
}

.btn-blue .icon-play {
    width: 18px;
    height: 18px;
    background: #fff;
    border-radius: 50%;
    margin-right: 5px;
    position: relative;
}
.btn-blue .icon-play:before {
    content: '';
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid #26a7df;
    margin: auto;
    position: absolute;
    left: 8px;
    top: 5px;
}

.job-box {
    border: 1px solid #c3d4e2;
    border-radius: 6px;
    overflow: hidden;
    height: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
}
.btn-border-gray:hover img[src*='icon'] {
    filter: brightness(0) invert(1);
}

.merchandise-block {
    width: 100%;
    height: 350px;
    border-radius: 6px;
    overflow: hidden;
    position: relative;
    background: #d0cecf;
}
.merchandise-block.gold {
    background-color: #ffcd56;
}
.product-name-price {
    position: absolute;
    left: 3px;
    right: 3px;
    bottom: 3px;
    margin: auto;
    background: #fff;
    padding: 15px;
    border-radius: 6px;
    text-align: center;
}

.offer-block {
    background: #ffffff;
    border: 1px solid #d2d2d2;
    border-radius: 6px;
    overflow: hidden;
    margin-left: 2px;
}
.offer-block .brand-logo {
    height: 118px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
}
.coupon_offer {
    padding: 20px;
    background: #f4faff;
}
.givingBackSchool {
    background-color: #f4f4f4;
}
.alumni-block {
    box-shadow: 0px 20px 40px #88949b29;
    border-radius: 4px;
    padding: 50px;
}
.alumni-block:not(:last-child) {
    margin-bottom: 40px;
}
.alumni-block.monitor {
    background: #fff;
}
.alumni-block.careerTalk {
    background: #fff;
}
.alumni-block h3 {
    font-size: 32px;
    font-weight: 600;
    line-height: 50px;
    color: #2d2d2d;
    margin: 0 0 5px;
}
.givingBackSchool .alumni-block p {
    font-size: 18px;
    line-height: 28px;
    color: #000;
    margin: 0 0 20px !important;
    text-align: left;
    opacity: 1;
    margin-bottom: 20px;
}
.btn-interested {
    background: #5cae48;
    opacity: 1;
    width: auto;
    height: 40px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    position: relative;
    overflow: hidden;
    border: 2px solid #5cae48;
    text-transform: capitalize;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    padding: 0 28px;
    text-decoration: none;
}
.btn-interested:hover {
    background: #fff;
    color: #0b3454;
    text-decoration: none !important;
    border-color: #0b3454;
}

.icon-selected-user {
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/selected-user.svg) no-repeat 0 0;
    width: 31px;
    height: 27px;
    display: inline-block;
}
.block-header {
    width: 100%;
    display: inline-block;
    padding: 17px 30px;
    border-bottom: 1px solid #d5d3d3;
}
.block-custom-tab {
    margin-top: 50px;
    margin-left: 40px;
}
.btn-border-lite-blue {
    border: 1px solid #26a7df;
    border-radius: 6px;
    opacity: 1;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    height: 40px;
    color: #26a7df;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.btn-border-lite-blue .icon-note {
    filter: invert(72%) sepia(36.1%) saturate(530%) hue-rotate(165deg) brightness(93%) contrast(87%);
    -webkit-filter: invert(72%) sepia(36.1%) saturate(530%) hue-rotate(165deg) brightness(93%) contrast(87%);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.btn-border-lite-blue i img {
    filter: invert(51%) sepia(93%) saturate(720%) hue-rotate(165deg) brightness(94%) contrast(85%);
}
.btn.btn-border-lite-blue:hover {
    background: #26a7df;
    color: #fff;
}
.btn-border-lite-blue:hover .icon-note,
.btn-border-lite-blue:hover i img {
    filter: brightness(0) invert(1);
    -webkit-filter: brightness(0) invert(1);
}
.block-custom-tab .nav-item:not(:last-child) {
    margin-right: 20px;
}
.block-custom-tab .nav-item .nav-link {
    padding: 0 0 6px;
    background: none;
    color: #000000;
    font-weight: 500;
    position: relative;
}
.block-custom-tab .nav-item .nav-link.active {
    color: #5cae48;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.block-custom-tab .nav {
    justify-content: center;
}
.block-custom-tab .nav.custom-sub-tab {
    justify-content: start;
}

.block-custom-tab .nav-item .nav-link:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border-bottom: 2px solid #5cae48;
    width: 0;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.block-custom-tab .nav-item .nav-link.active:before {
    width: 100%;
    opacity: 1;
}

.custom-sub-tab .nav-link {
    width: 175px;
    border-radius: 0;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    color: #000;
    border-left: 4px solid #fff;
    border-radius: 6px 0 0 6px;
    padding: 15px 20px;
}
.custom-sub-tab .nav-link.active {
    background: #f2f4f7;
    color: #000;
    border-color: #5cae48;
}

.btn-green .icon-question.circle {
    border-radius: 50%;
    border: 2px solid #fff;
    padding: 2px;
    font-size: 12px;
    width: 22px;
    height: 22px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 4px;
}

.listed-border {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-wrap: wrap;
    -moz-box-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
    background: #ffffff;
    border: 1px solid #dde4ee;
    border-radius: 6px;
    min-height: 50px;
    padding: 10px 20px;
}
.listed-border:not(.disabled) {
    background-color: #26a7df;
    border-color: #26a7df;
    color: #fff;
}
.listed-border:not(.disabled) .tick:before {
    border-color: #fff;
}
.listed-border.disabled {
    opacity: 0.4;
}
.listed-border.disabled .note-wrap {
    display: none;
}
.listed-border .tick:before {
    content: '';
    position: absolute;
    width: 15px;
    height: 7px;
    border-left: 3px solid #000;
    border-bottom: 3px solid #000;
    transform: rotate(-40deg);
    -webkit-transform: rotate(-40deg);
    -moz-transform: rotate(-40deg);
    -ms-transform: rotate(-40deg);
    -o-transform: rotate(-40deg);
}
.listed-border .tick {
    position: relative;
    width: 15px;
    height: 11px;
}
.listed-border p {
    margin: 0 16px;
    width: calc(100% - 78px);
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.listed-border.red {
    background-color: #c10101;
    border-color: #c10101;
    color: #fff;
}
.listed-border.red .tick:before {
    border-color: #fff;
}
.listed-border.red .note-wrap {
    background: #9b0e0e;
}
.note-wrap {
    width: 30px;
    height: 30px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #0a6e9a;
    border-radius: 50%;
}
.note-wrap .icon-note {
    width: 12px;
    height: 15px;
    background-size: 100%;
    filter: brightness(0) invert(1);
    -webkit-filter: brightness(0) invert(1);
}
.blue-link {
    color: #1664bc;
}
.blue-link:hover {
    color: #5cae48;
}

.meter-wrap {
    width: 40px;
    height: 40px;
    padding: 3px;
    background: #fff;
    border-radius: 50%;
    position: relative;
}
.meter-icon {
    border-top: 10px solid #f2c32f;
    border-left: 10px solid #e03f4f;
    border-right: 10px solid #1ea56b;
    border-radius: 50%;
    position: absolute;
    left: 2px;
    right: 2px;
    top: 2px;
    bottom: 2px;
    border-bottom: 10px solid transparent;
}
.meter-icon:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 7px;
    height: 7px;
    background: #000;
    border-radius: 50%;
}
.pointer-pin {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}
.meter-icon .pointer-pin:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    border-right: 13px solid #000;
    left: -7px;
    top: 5.7px;
}

.meter-title{font-size: 10px;font-weight: 600;text-align: center; margin-top: 1px;width:35px;text-align: center;}

.meter-wrap.positive .meter-icon .pointer-pin {
    transform: rotate(175deg);
    -webkit-transform: rotate(175deg);
    -moz-transform: rotate(175deg);
    -ms-transform: rotate(175deg);
    -o-transform: rotate(175deg);
}
.meter-wrap.neutral .meter-icon .pointer-pin {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
}
.icon-open-folder {
    width: 17px;
    height: 17px;
    display: inline-block;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/open-folder.svg) no-repeat center /
        100%;
}

.disabled-text {
    color: #000;
    opacity: 0.4;
    font-weight: 500;
}

@media (min-width: 575px) and (max-width: 1150px) {
    .verify_massrecruiter {
        margin: 0px 0 0 70px;
    }
    .verify_massrecruiter .confirmRecruiter {
        margin-top: 10px;
    }
    .verify_massrecruiter ul li {
        margin-top: 5px;
        margin-bottom: 5px;
    }
}

@media (max-width: 1139px) {
    .threecardslider.owl-theme .owl-nav button {
        position: relative;
        margin: 0 4px !important;
        left: auto !important;
        top: auto;
        bottom: auto;
        right: auto !important;
    }
}

@media (min-width: 991px) {
    .half-pink::after {
        position: absolute;
        content: '';
        top: 0;
        bottom: 0;
        right: 0;
        background: #ffeff1;
        width: 40%;
    }
}

@media (max-width: 991px) {
    .report-block-list ul.verticleListDesktop li {
        width: auto;
        padding-right: 15px;
    }
    .report-block-list ul li span {
        white-space: nowrap;
    }
}

@media (min-width: 768px) {
    .alumni_directory_filter #additionalfilter .fiterItem.col4 {
        width: 24.1%;
    }
}
@media (max-width: 767px) {
    .filter-toggle-wrap {
        top: 10px;
    }
    .filter-toggle-wrap a {
        padding: 4px 15px;
    }
    .report-block-list ul.verticleListTabMobile li {
        width: 100%;
    }
    .block-custom-tab {
        margin-left: 0;
    }
    .block-custom-tab .nav.custom-sub-tab {
        justify-content: center;
        margin-top: 30px;
    }
    .custom-sub-tab .nav-link {
        border-top: 4px solid white;
        border-radius: 6px 6px 0px 0;
        border-left: none;
        text-align: center;
        width: auto;
        white-space: nowrap;
    }
}

@media (max-width: 575px) {
    .verify_massrecruiter {
        width: 100%;
    }
    .block-header {
        padding: 17px 17px;
    }
    .block-custom-tab .nav.custom-sub-tab {
        justify-content: start;
        overflow: auto;
        flex-wrap: nowrap;
        width: 100%;
        padding: 0 5px;
    }
}

.message_date label {
    color: #919191;
    cursor: pointer;
}
.message_date label.dislike {
    -moz-transform: scale(-1) translateY(0px);
    -webkit-transform: scale(-1) translateY(0px);
    -o-transform: scale(-1) translateY(0px);
    -ms-transform: scale(-1) translateY(0px);
    transform: scale(-1) translateY(0px);
    display: inline-block;
    vertical-align: top;
    color: #919191;
}
.message_date input:checked + label[for='like'],
.message_date input:checked + label.like {
    color: #5cae48;
}
.message_date input:checked + label[for='dislike'],
.message_date input:checked + label.dislike {
    color: #333;
}
.updateadditionalinformation .custom-multi-select {
    border: 1px solid #e5e5e5 !important;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    height: auto !important;
}
.updateadditionalinformation .custom-multi-select > div{
    height: auto !important;
}
.updateadditionalinformation .custom-multi-select div.css-3w2yfm-ValueContainer {
    overflow: visible;
    white-space: normal;
    overflow-x: visible;
}
.updateadditionalinformation .custom-multi-select .css-b62m3t-container .css-t3ipsp-control,
.updateadditionalinformation .custom-multi-select .select__control,
.updateadditionalinformation .custom-multi-select .css-13cymwt-control {
    height: auto !important;
}
.updateadditionalinformation .custom-multi-select .css-b62m3t-container div[data-value] {
    display: inline-flex !important ;
  }
.logoImg.cls_top_companies img {
    max-height: 58px;
}
.logoImg img[src*='building.svg'] {
    width: 40px;
    height: auto;
}

.iframe-preview {
    max-width: 1366px;
    margin: 0 auto;
    padding: 0;
    height: 100%;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.iframe-preview.iframe-mobile {
    max-width: 400px;
}

/* Custom Scrollbar using CSS */
.iframe-preview {
    overflow-y: scroll;
}

/* scrollbar width */
.iframe-preview::-webkit-scrollbar {
    width: 5px;
}

/* scrollbar track */
.iframe-preview::-webkit-scrollbar-track {
    background: #eee;
}

/* scrollbar handle */
.iframe-preview::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background-color: #333;
}

.togglelinks {
    margin: auto;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 4px;
    max-width: 320px;
    width: 100%;
    height: 50px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    overflow: hidden;
    border: 1px solid #0b3454;
}
.togglelinks a {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #0b3454;
    font-size: 16px;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.togglelinks a.btn-desktop i {
    width: 24px;
    height: 21px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/desktop-monitor.svg);
    margin-right: 10px;
}
.togglelinks a.btn-mobile i {
    width: 14px;
    height: 24px;
    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/dark-smartphone.svg);
    margin-right: 10px;
}
.togglelinks a.active {
    background: #0b3454;
    color: #fff;
}
.togglelinks a.active i,
.togglelinks a:hover i {
    filter: invert(1) brightness(170%);
}
.togglelinks a:hover {
    background: #294a64;
    color: #fff;
}
.tooltip-inner {
    background: #0b3454 !important;
    border: 1px solid #0b3454;
    font-size: 14px !important;
}
.bs-tooltip-top .tooltip-arrow::before {
    border-top-color: #0b3454;
}
.eventslink_willingness {
    color: white !important;
}
.form-select[disabled] {
    pointer-events: none;
    background-image: none !important;
}
.icon-yellow {
    filter: invert(62%) sepia(61%) saturate(610%) hue-rotate(0deg) brightness(104%) contrast(92%);
    -webkit-filter: invert(62%) sepia(61%) saturate(610%) hue-rotate(0deg) brightness(104%) contrast(92%);
}

.form-grid-section+.form-grid-section {
    margin-top: 30px;
}

.form-grid-section {
    padding: 25px;
    background: #f1f1f1;
    border-radius: 18px;
}

.form-grid-section .btn-delete {
    top: 0;
    bottom: 0;
    margin: auto;
}

.shake-err {
    animation: shake 0.2s ease-in-out 0s 2;
    color: #FF0000;
}

@keyframes shake {
    0% {
        transform: translateX(0.2rem);
    }

    25% {
        transform: translateX(0.5rem);
    }

    75% {
        transform: translateX(-0.5rem);
    }

    100% {
        transform: translateX(0rem);
    }
}

.upload_section.drag-over {
    border: 2px dashed #007bff;
    /* Blue border to indicate drag over */
    background-color: #f0f8ff;
    /* Light blue background color */
    cursor: default;
    /* Cursor set to default or as needed */
}

.cloud-text span {
    font-weight: bold;
    /* Make the "Drop here" text bold for emphasis */
}
.sd_formsec label:empty {
	display: none;
}

body:not(.modal-open) .modal-backdrop.show{display: none !important;}


.college-type ~ div.college-type-option {
    display: none;
}

.college-type:checked ~ div.college-type-option {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}
.card.linkedin .card-header img {
    filter: brightness(00) invert(1);
    border-radius: 0 !important;
    width: 22px;
    margin-right: 10px;
    vertical-align: top;
}
.form-select{border: 1px solid #b5c2cb;height: 45px;}


.referred-college-logo img {max-height: 90px;max-width: 200px;}
.referral-progress-card {  }
.profileimg-name { padding: 20px; background: #0B3454; border-radius: 8px 8px 0 0; -webkit-border-radius: 8px 8px 0 0; -moz-border-radius: 8px 8px 0 0; -ms-border-radius: 8px 8px 0 0; -o-border-radius: 8px 8px 0 0; display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; flex-direction: column; align-items: center;}
.referral-profile-detail {background: #FFFFFF;border: 1px solid #A8ACAF;border-radius: 0 0 8px 8px;-webkit-border-radius: 0 0 8px 8px;-moz-border-radius: 0 0 8px 8px;-ms-border-radius: 0 0 8px 8px;-o-border-radius: 0 0 8px 8px;padding:25px ;}
.referral-profile-detail ul{padding: 0;margin: 0;list-style: none;}
.referral-profile-detail ul li:not(:last-child){margin-bottom: 15px;}
.referral-profile-detail ul li{display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex;}
.referral-profile-detail ul li i {width: 25px;height: 25px;display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex;margin-right: 15px;margin-top: 2px;}
.referral-profile-detail ul li i.hand-share{background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/deal.svg) no-repeat top center;}
.referral-profile-detail ul li i.mobile{background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/mobile.svg) no-repeat top center;}
.referral-profile-detail ul li i.email{background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/mail.svg) no-repeat top center;}
.referral-profile-detail ul li i.referred {background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/referred-on.svg) no-repeat top center;}
.referral-profile-detail ul li div{width: calc(100% - 40px);line-height: 18px;word-wrap: anywhere;}
.referral-profile-detail ul li a{color: #358CEF; text-decoration: none;}
.referral-profile-detail ul li a:hover{color: #0B3454;}
.profileimg-name .img { display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; justify-content: center; align-items: center; border-radius: 50%; overflow: hidden; margin-top: -60px; width: 90px; height: 90px; box-shadow: 0 0 1px 0px #6f6f6f; -webkit-border-radius: 50%; -moz-border-radius: 50%; -ms-border-radius: 50%; -o-border-radius: 50%;}
.profileimg-name .img img {width: auto;height: 100%;}
.profileimg-name .profile-name {color: #fff;margin-top: 8px;font-size: 20px;font-weight: 600;}

.referral-progress-track {background: #F9FCFF;border: 2px solid #0B345436;border-radius: 20px;opacity: 1;}
.referral-progress-track ul{padding: 0;margin: 0;list-style: none;}
.referral-progress-track ul li{display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex;align-items: center;}
.referral-progress-track ul li:not(:last-child){margin-bottom: 50px;}
.referral-progress-track ul li .icon{width: 37px;min-width: 37px;display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex;align-items: center;justify-content: center;}
.referral-progress-track ul li .dot{ width: 26px; height: 26px;margin: 0 20px; position: relative;display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex;align-items: center;justify-content: center;}
.referral-progress-track ul li .dot i{width: 20px; height: 20px;border: 2px solid #6B6868; border-radius:50% ; -webkit-border-radius:50% ; -moz-border-radius:50% ; -ms-border-radius:50% ; -o-border-radius:50% ;background-color: #fff;}
.referral-progress-track ul li:not(:last-child) .dot::after {content: '';position: absolute;left: 0;right: 0;margin: auto;border-right: 2px dashed #6B6868;height: calc(100% + 50px);top: 100%;width: 0;}
.referral-progress-track ul li:not(.completed) img {filter: invert(46%) sepia(0%) saturate(14%) hue-rotate(6deg) brightness(91%) contrast(96%);-webkit-filter: invert(46%) sepia(0%) saturate(14%) hue-rotate(6deg) brightness(91%) contrast(96%);}
.referral-progress-track ul li.completed .dot i{background: #5CAE48;border-color:#5CAE48;width: 26px;height: 26px;}
.referral-progress-track ul li.completed .dot i::before { content: ''; position: absolute; border-left: 3px solid #fff; border-bottom: 3px solid #fff; width: 13px; height: 7px; top: 8px; left: 6px; transform: rotate(-45deg); -webkit-transform: rotate(-45deg); -moz-transform: rotate(-45deg); -ms-transform: rotate(-45deg); -o-transform: rotate(-45deg);}
.referral-progress-track ul li.completed .dot::after{border-right: 2px solid #5CAE48;}
.referral-progress-track ul li.completed .track-label .font-16{font-weight: 600;}
.icon.workex-logo {border: 1px solid #ccc;padding: 10px;background: none;}


table.row, div[contenteditable] table {    margin: auto;}

table.row>*  {padding:inherit}

table.row tr, table.row td, div[contenteditable] table tr, div[contenteditable] table td{display: block;}

.edit-controls i{font-size: 24px;cursor: pointer;}

.edit-controls .icon-disable {
    position: relative;
    border: 1px solid #ccc;
    border-radius: 50%;
    padding: 5px;
    width: 35px;
    height: 35px;
}

.edit-controls button{
    border: none;padding: 0;background-color: transparent;
}

.edit-controls .icon-disable:before {
    content: '';
    position: absolute;
    width: 24px;
    height: 2px;
    background: #585555;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
}

div.custom-multi-select div[class*="css"] {
    height: auto !important;
    white-space: normal;
}

.lstCustForm div.custom-multi-select div[class*="-control"] {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border: 1px solid #b5c2cb !important;
}

.dual-search{display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-webkit-box-flex: 1;-moz-box-flex: 1;-webkit-flex: 1;-ms-flex: 1;flex: 1;}

@media (max-width: 575px) {
    .dual-search{order: -1;flex-basis: 100%; margin-bottom: 10px;} 
}

.icon-plus.circle {border: 2px solid #fff;border-radius: 50%;margin-right: 7px;width: 18px;height: 18px;}
.icon-plus.circle::before, .icon-plus.circle::after  {height: 8px;}


div.owl-carousel.cardslider .owl-nav {
  margin: 5px 0 0 45px;
  text-align: left;display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;
  
}

div.owl-carousel.cardslider .owl-nav button {
  background: #0b3454;
  padding: 2px 15px !important;
  border-radius: 25px;
  margin: 2px;
  line-height: 17px;
  display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
div.owl-carousel.cardslider .owl-nav button span {
	display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;
	margin: -2px 0 0;
}

.nav-item.disabled{
    cursor: not-allowed !important
}
/* new contribution tab design */
.block-custom-tab .video_block {background: #ffffff;border: 1px solid #d2d2d2;border-radius: 6px;overflow: hidden;text-decoration: none;position: relative;display: inline-block;color: #000;transition: all 0.3s ease-in-out;-webkit-transition: all 0.3s ease-in-out;-moz-transition: all 0.3s ease-in-out;-ms-transition: all 0.3s ease-in-out;-o-transition: all 0.3s ease-in-out;padding: 0;}

.block-custom-tab  .video_block:hover{background-color: #fff;}
.block-custom-tab  .video_block:hover,
.block-custom-tab  .video_block:hover .icon-invert-play {
color: #5cae48;
}
/* end contribution tab new*/
.custom-alret {
    padding: 10px 25px;
    box-shadow: 0 0 4px 3px #ccc;
    border-radius: 45px;
    margin-bottom: 25px;
}

button.btn-close.w-20 {
    width: 14px;
    padding: 0;
}

.loaderNew {width: 50px;aspect-ratio: 1;display: grid;border: 4px solid #0000;border-radius: 50%;border-right-color: #25b09b;animation: l15 1s infinite linear;}
.loaderNew::before,.loaderNew::after {content: "";grid-area: 1/1;margin: 2px;border: inherit;border-radius: 50%;animation: l15 2s infinite;}
.loaderNew::after {margin: 8px;animation-duration: 3s;}

@keyframes l15 {
    100% {
        transform: rotate(1turn)
    }
}

.widget-list:not(:last-child) {border-bottom: 1px solid #e5e5e5;}
.manage-widgets-wrap .icon-down-arrow{transition: all 0.3s ease-in-out;}
.manage-widgets-wrap .icon-down-arrow[aria-expanded="true"]{transform: rotate(180deg);}


/* New css add 03022025 */

.social-platform-wrap {width: max-content;min-width: 100%;}
.social-platform-wrap ul {list-style: none;padding: 0;margin: 0;display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;}
.social-platform-wrap ul li {background: #FFFFFF;border: 1px solid #D2D2D2;border-radius: 6px;width: 275px;height: 156px;position: relative;padding: 20px;display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;flex-direction: column;align-items: center;}
.social-platform-wrap ul li:not(:last-child){margin-right: 13px;}
.social-platform-wrap ul li .form-input-check { position: absolute; top: 20px; left: 20px; width: 20px; height: 20px; border: 1px solid #888F95; appearance: none; border-radius: 4px; transition: all 0.3s ease-in-out; -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -ms-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out; -webkit-border-radius: 4px; -moz-border-radius: 4px; -ms-border-radius: 4px; -o-border-radius: 4px;cursor: pointer;}
.social-platform-wrap ul li .form-input-check:checked{background-color: #5CAE48;border-color: #5CAE48;background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");}

.social-platform-wrap ul li label {font-size: 18px;font-weight: 600;margin-bottom: 7px;}
.share-copy {position: absolute;bottom: 20px;right: 20px;display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;}
.share-link {background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/images/share-icon.svg) no-repeat 0 0;width: 22px;height: 24px;margin-right: 20px;}
.copy-link{background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/images/copy-icon.svg) no-repeat 0 0;width: 22px;height: 24px;}
.share-copy a:hover{ filter: invert(54%) sepia(40%) saturate(590%) hue-rotate(62deg) brightness(101%) contrast(94%); -webkit-filter: invert(54%) sepia(40%) saturate(590%) hue-rotate(62deg) brightness(101%) contrast(94%); }

.form-check .form-check-input[disabled]:checked {	background-color: #606060;	border-color: #626262;}
.form-captcha {width: 100%;height: 45px;border: 1px solid #D2D2D2;display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;align-items: center;justify-content: center;border-radius: 4px;-webkit-border-radius: 4px;-moz-border-radius: 4px;-ms-border-radius: 4px;-o-border-radius: 4px;}

/* ai chat */

.btn-chat-ai { background: #5cae48; position: fixed; bottom: 40px; right: 40px; padding: 10px 20px; border-radius: 25px; text-decoration: none; color: #fff; display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; align-items: center; font-size: 18px; text-decoration: none; transition:all 0.3s ease-in-out ; -webkit-transition:all 0.3s ease-in-out ; -moz-transition:all 0.3s ease-in-out ; -ms-transition:all 0.3s ease-in-out ; -o-transition:all 0.3s ease-in-out ; }
.btn-chat-ai i {  margin-right: 7px;}
.btn-chat-ai:hover{color: #fff;background-color: #0b3454;}

.ai-chat-wrap {height: 100vh;padding: 0;}
.ai-chat-wrap .ai-chat {height: 100%;max-width: 1140px;width: 96%;margin: auto;border-radius: 10px;}
.ai-chat-wrap .ai-chat .alumni_chat_block {background: none;min-height: inherit;}
.ai-chat-wrap .ai-chat .alumni_chat_block_header {padding: 20px 0;background: transparent;border-radius: 10px 10px 0px 0px;border-bottom: 1px solid #efefef;-webkit-border-radius: 10px 10px 0px 0px;-moz-border-radius: 10px 10px 0px 0px;-ms-border-radius: 10px 10px 0px 0px;-o-border-radius: 10px 10px 0px 0px;position: relative;overflow: hidden;}
.ai-chat-wrap .ai-chat .alumni_chat_conversation_send {padding: 30px 0;background: transparent;border-radius: 0px 0 10px 10px;border-top: 1px solid #efefef;-webkit-border-radius: 0px 0 10px 10px;-moz-border-radius: 0px 0 10px 10px;-ms-border-radius: 0px 0 10px 10px;-o-border-radius: 0px 0 10px 10px;}
.alumni_chat_block_header i.icon-chat-invert {position: absolute;top: 0;right: 20px;font-size: 140px;width: 140px;color: #e1e1e1;}
.ai-img { width: 70px; height: 70px; display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex; align-items: end; background: #ededed; justify-content: center; border-radius: 50%; overflow: hidden; -webkit-border-radius: 50%; -moz-border-radius: 50%; -ms-border-radius: 50%; -o-border-radius: 50%;}
i.icon-compose-mail {background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/images/message-compose.svg) no-repeat 0 0;width: 40px;height: 40px;display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;margin: 0;padding: 0;}

.ai-chat .get_started {flex-direction: column-reverse;overflow: auto;}
.coversation-block{flex-direction: column-reverse;overflow: auto;}
.ai-chat .icon-turn-off {width: 15px;height: 15px;border-left: 2px solid #555;border-bottom: 2px solid #555;border-right: 2px solid #555;border-radius: 50%;border-top: 3px solid transparent;position: relative;top: -20px;right: 5px;text-decoration: none;transition: all 0.3s ease-in-out;-webkit-transition: all 0.3s ease-in-out;-moz-transition: all 0.3s ease-in-out;-ms-transition: all 0.3s ease-in-out;-o-transition: all 0.3s ease-in-out;}
.ai-chat .icon-turn-off:before {content: '';position: absolute;left: 0;right: 0;top: auto;width: 2px;height: 8px;bottom: 5px;background: #555;margin: auto;transition: all 0.3s ease-in-out;-webkit-transition: all 0.3s ease-in-out;-moz-transition: all 0.3s ease-in-out;-ms-transition: all 0.3s ease-in-out;-o-transition: all 0.3s ease-in-out;}

.ai-chat .icon-turn-off:hover{border-left: 2px solid #f0555f;border-bottom: 2px solid #f0555f;border-right: 2px solid #f0555f;}
.ai-chat .icon-turn-off:hover:before {background: #f0555f;}

.justify-content-end .date {text-align: right;}
.user-chat .ai-img {  width: 60px; height: 60px;font-size: 48px;  color: #979797;}

.rating-emoji label { background-repeat: no-repeat; background-position: center; background-size: 30px; margin-right: 10px; width: 30px; height: 30px; cursor: pointer; transition:all 0.3s ease-in-out ; -webkit-transition:all 0.3s ease-in-out ; -moz-transition:all 0.3s ease-in-out ; -ms-transition:all 0.3s ease-in-out ; -o-transition:all 0.3s ease-in-out ; background-color: #000; border: 1px solid #af8d09; border-radius: 50%; -webkit-border-radius: 50%; -moz-border-radius: 50%; -ms-border-radius: 50%; -o-border-radius: 50%; }
.rating-emoji .sad{background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='%23ffd21f' stroke='%23343a40' stroke-linecap='' stroke-linejoin='' stroke-width='0' d='M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5m-2.715 5.933a.5.5 0 0 1-.183-.683A4.5 4.5 0 0 1 8 9.5a4.5 4.5 0 0 1 3.898 2.25.5.5 0 0 1-.866.5A3.5 3.5 0 0 0 8 10.5a3.5 3.5 0 0 0-3.032 1.75.5.5 0 0 1-.683.183M10 8c-.552 0-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5S10.552 8 10 8'/%3e%3c/svg%3e");}
input[name="rating"]:checked + .sad{background-color: #fff;border: 1px solid #ae000b;background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='%23ae000b' stroke='%23343a40' stroke-linecap='' stroke-linejoin='' stroke-width='0' d='M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5m-2.715 5.933a.5.5 0 0 1-.183-.683A4.5 4.5 0 0 1 8 9.5a4.5 4.5 0 0 1 3.898 2.25.5.5 0 0 1-.866.5A3.5 3.5 0 0 0 8 10.5a3.5 3.5 0 0 0-3.032 1.75.5.5 0 0 1-.683.183M10 8c-.552 0-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5S10.552 8 10 8'/%3e%3c/svg%3e");}

.rating-emoji .neutral{background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='%23ffd21f' stroke='%23343a40' stroke-linecap='' stroke-linejoin='' stroke-width='0' d='M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5m-3 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5M10 8c-.552 0-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5S10.552 8 10 8'/%3e%3c/svg%3e");}
input[name="rating"]:checked + .neutral{background-color: #fff;border: 1px solid #f1a200;background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='%23f1a200' stroke='%23343a40' stroke-linecap='' stroke-linejoin='' stroke-width='0' d='M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5m-3 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5M10 8c-.552 0-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5S10.552 8 10 8'/%3e%3c/svg%3e");}

.rating-emoji .happy{background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='%23ffd21f' stroke='%23343a40' stroke-linecap='' stroke-linejoin='' stroke-width='0' d='M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5M4.285 9.567a.5.5 0 0 1 .683.183A3.5 3.5 0 0 0 8 11.5a3.5 3.5 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683M10 8c-.552 0-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5S10.552 8 10 8'/%3e%3c/svg%3e");}
input[name="rating"]:checked + .happy{background-color: #fff;border: 1px solid #5bae47;background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='%235bae47' stroke='%23343a40' stroke-linecap='' stroke-linejoin='' stroke-width='0' d='M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5M4.285 9.567a.5.5 0 0 1 .683.183A3.5 3.5 0 0 0 8 11.5a3.5 3.5 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683M10 8c-.552 0-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5S10.552 8 10 8'/%3e%3c/svg%3e");}

ul.feed-back-list {padding: 5px 0 0;margin: 0;list-style: none;max-width: 500px;}
ul.feed-back-list li:not(:last-child) {margin-bottom: 10px;}
ul.feed-back-list li{background: #f7f7f7;border-radius: 50px;-webkit-border-radius: 50px;-moz-border-radius: 50px;-ms-border-radius: 50px;-o-border-radius: 50px;padding: 12px 25px;border: 1px solid #ccc;line-height: 18px;font-size: 16px;}

@media (max-width:767px){
.ai-chat-wrap{padding: 0;}
.ai-chat-wrap .ai-chat{ width: 100%; border-radius:0 ; -webkit-border-radius:0 ; -moz-border-radius:0 ; -ms-border-radius:0 ; -o-border-radius:0 ; }
.ai-chat-wrap .ai-chat .alumni_chat_block_header {padding: 15px;border-radius: 0;-webkit-border-radius: 0;-moz-border-radius: 0;-ms-border-radius: 0;-o-border-radius: 0;}
.ai-img {width: 55px;height: 55px;}
.ai-chat-wrap .ai-chat .alumni_chat_conversation_send {padding: 30px 15px 15px;border-radius: 0;-webkit-border-radius: 0;-moz-border-radius: 0;-ms-border-radius: 0;-o-border-radius: 0;}
.get_started_prompt a {	padding: 6px 20px;	font-size: 16px;}
ul.feed-back-list li{ border-radius: 5px; -webkit-border-radius: 5px; -moz-border-radius: 5px; -ms-border-radius: 5px; -o-border-radius: 5px; padding: 10px; }
}

.college-type ~ div.college-type-option {display: none;}
.college-type:checked ~ div.college-type-option {display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;}

/* .max-min-250{min-width: 250px;max-width: 250px;} */

.TopperSlider .nav {justify-content: center;}
.TopperSlider .nav .nav-item:not(:last-child){margin-right: 20px;}
.TopperSlider .nav .nav-link {background: transparent;border: 1px solid #206EAA;position: relative;color: #fff;white-space: nowrap;}
.TopperSlider .nav .nav-link::before { content: ''; position: absolute; bottom: 0; left: 0; right: 0; border-left: 7px solid transparent; border-right: 7px solid transparent; border-top: 7px solid #4096d9; width: 0; height: 0; margin: auto; opacity: 0; transition:all 0.3s ease-in-out ; -webkit-transition:all 0.3s ease-in-out ; -moz-transition:all 0.3s ease-in-out ; -ms-transition:all 0.3s ease-in-out ; -o-transition:all 0.3s ease-in-out ; }
.TopperSlider .nav .nav-link.active{background-color: #4096D9;border: 1px solid #4096D9;}
.TopperSlider .nav .nav-link.active:before{bottom: -8px;opacity: 1;}

.TopperSlider .scroll{overflow-x: auto;overflow-y: hidden;}
.TopperSlider .scroll::-webkit-scrollbar {width: 4px;}
.TopperSlider .scroll::-webkit-scrollbar-track {background: #f1f1f1;}
.TopperSlider .scroll::-webkit-scrollbar-thumb {background: #888;}
.TopperSlider .scroll::-webkit-scrollbar-thumb:hover {background: #555;} 


@media (min-width:768px){
.TopperSlider.vertical-style {display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-webkit-box-wrap: wrap;-moz-box-wrap: wrap;-ms-flex-wrap: wrap;-webkit-wrap: wrap;flex-wrap: wrap;}
.TopperSlider.vertical-style .scroll {width: 160px;margin-right: 20px;}
.TopperSlider.vertical-style .tab-content {width: calc(100% - 180px);}
.TopperSlider.vertical-style .nav{margin-top: 10px;width: 100%;}
.TopperSlider.vertical-style .nav .nav-item{width: 100%;}
.TopperSlider.vertical-style .nav .nav-item:not(:last-child) {	margin: 0 0 20px;}
.TopperSlider.vertical-style .nav .nav-link{width: calc(100% - 15px);padding: 14px 25px;}
.TopperSlider.vertical-style .nav .nav-link::before {bottom: 0;left: auto;right: -15px;top: 0;border-left: 7px solid #4096d9;border-right: 7px solid transparent;border-top: 7px solid transparent;border-bottom: 7px solid transparent;}
.TopperSlider.vertical-style .threecardslider.owl-theme .owl-nav button{position: relative;margin: 0 5px !important;left: auto;right: auto;}
}

@media (max-width:767px){
.TopperSlider .nav {justify-content: start;flex-wrap: nowrap;}
}

.card-wrap{display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex;flex-direction: column;height: 100%;}
.card-wrap .card-title {font-size: 18px;font-weight: 500;text-align: center;}
.card-wrap .card-detail {background: #F5F5F5 ;border-radius: 12px;padding: 14px;-webkit-box-flex: 1;-moz-box-flex: 1;-webkit-flex: 1;-ms-flex: 1;flex: 1;}
.card-wrap .card-detail .post-img{margin-bottom: 7px;}
.noScrollBarScroll {max-height: 315px;overflow-y: auto;min-height: 315px;scrollbar-width: none;}
.noScrollBarScroll .scroll::-webkit-scrollbar {width: 0px;}

.canvas {display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-webkit-box-wrap: wrap;-moz-box-wrap: wrap;-ms-flex-wrap: wrap;-webkit-wrap: wrap;flex-wrap: wrap;}
.canvas-edit-tools {background: #0B3454;display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-webkit-box-wrap: wrap;-moz-box-wrap: wrap;-ms-flex-wrap: wrap;-webkit-wrap: wrap;}

.canvas-edit-tools ul {padding: 0;margin: 0;list-style: none;width: 75px;}
.canvas-edit-tools ul li {display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex;justify-content: center;align-items: center;color: #fff;border-bottom: 1px solid #236CA5;}
.canvas-edit-tools ul li a{ padding: 20px; display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; -webkit-box-wrap: wrap; -moz-box-wrap: wrap; -ms-flex-wrap: wrap; -webkit-wrap: wrap; flex-wrap: wrap; justify-content: center; align-items: center; text-decoration: none; color: #85C5F8; min-height: 75px; width: 100%; transition:all 0.3s ease-in-out ; -webkit-transition:all 0.3s ease-in-out ; -moz-transition:all 0.3s ease-in-out ; -ms-transition:all 0.3s ease-in-out ; -o-transition:all 0.3s ease-in-out ; }
.canvas-edit-tools ul li a i{ transition:all 0.3s ease-in-out ; -webkit-transition:all 0.3s ease-in-out ; -moz-transition:all 0.3s ease-in-out ; -ms-transition:all 0.3s ease-in-out ; -o-transition:all 0.3s ease-in-out ; }
.canvas-edit-tools ul li a:hover, .canvas-edit-tools ul li a.active{background-color: #236CA5;color: #fff;}
.canvas-edit-tools ul li a:hover i, .canvas-edit-tools ul li a.active i{ filter:brightness(0) invert(1) ; -webkit-filter:brightness(0) invert(1) ; }
.canvas-main {-webkit-box-flex: 1;-moz-box-flex: 1;-webkit-flex: 1;-ms-flex: 1;flex: 1;background: #F5F5F5;display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex;flex-direction: column;}

.icon-resize {width: 22px;height: 22px;background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/images/maximize.svg) no-repeat center;}
.icon-picture {width: 22px;height: 22px;background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/images/gallery.svg) no-repeat center;}
.icon-text {width: 22px;height: 22px;background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/images/text.svg) no-repeat center;}

.icon-original{width: 48px;height: 61px;background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/images/orginal.svg) no-repeat center;}
.icon-instagram-story{width: 37px;height: 69px;background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/images/instagramstory.svg) no-repeat center;}
.icon-youtube-thumbnail{width: 87px;height: 50px;background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/images/outubethumbnail.svg) no-repeat center;}
.icon-facebook-profile{width: 62px;height: 62px;background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/images/facebookprofile.svg) no-repeat center;}
.icon-banner{width: 87px;height: 17px;background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/images/banner.svg) no-repeat center;}
.icon-large-rectangle{width: 92px;height: 36px;background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/images/largerectangle.svg) no-repeat center;}

.canvas-edit-tools-options {border-left: 1px solid #236CA5;height: 100%;width: 0;transition: all 0.3s ease-in-out;-webkit-transition: all 0.3s ease-in-out;-moz-transition: all 0.3s ease-in-out;-ms-transition: all 0.3s ease-in-out;-o-transition: all 0.3s ease-in-out;overflow: hidden;}
.canvas-edit-tools-options ul {display: none;-webkit-box-wrap: wrap;-moz-box-wrap: wrap;-ms-flex-wrap: wrap;-webkit-wrap: wrap;flex-wrap: wrap;width: auto;width: 300px;padding: 10px 5px;justify-content: center;}
.canvas-edit-tools-options ul li {margin: 5px;border: none;}
.canvas-edit-tools-options ul li a {width: 130px;height: 100%;min-height: 130px; flex-direction: column;border-radius: 5px;background: #0D3E64;padding: 10px;flex-wrap: nowrap;  text-align: center;}
.canvas-edit-tools-options ul li a:hover{background-color: #fff;color: #0B3454;}
.canvas-edit-tools-options ul li a:hover i{ filter:none; -webkit-filter:none; }
.canvas-edit {background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/images/border-graph.svg) no-repeat 0 0 / 100%;padding: 10px;max-width: 360px;width: 100%;display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex;justify-content: center;align-items: center;height: 357px;}
.open  .canvas-edit-tools-options {width: 300px;}
.open .canvas-edit-tools-options ul {display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex;}

.icon-wrap {display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex;justify-content: center;align-items: center;height: 70px;margin-bottom: 5px;}

.scale {background: #0B3454;display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex;justify-content: center;align-items: center;height: 40px;}
.scale a { border: 1px solid #408AC2; width: 20px; height: 20px; position: relative; margin: 0 10px; border-radius: 50%; -webkit-border-radius: 50%; -moz-border-radius: 50%; -ms-border-radius: 50%; -o-border-radius: 50%; transition:all 0.3s ease-in-out ; -webkit-transition:all 0.3s ease-in-out ; -moz-transition:all 0.3s ease-in-out ; -ms-transition:all 0.3s ease-in-out ; -o-transition:all 0.3s ease-in-out ; }
.scale a::before{ content: ''; position: absolute; width: 10px; height: 2px; background: #408AC2; left: 0; right: 0; top: 0; bottom: 0; margin: auto; transition:all 0.3s ease-in-out ; -webkit-transition:all 0.3s ease-in-out ; -moz-transition:all 0.3s ease-in-out ; -ms-transition:all 0.3s ease-in-out ; -o-transition:all 0.3s ease-in-out ; }
.scale a::after{ content: ''; position: absolute; width: 10px; height: 2px; background: #408AC2; left: 0; right: 0; top: 0; bottom: 0; margin: auto; transition:all 0.3s ease-in-out ; -webkit-transition:all 0.3s ease-in-out ; -moz-transition:all 0.3s ease-in-out ; -ms-transition:all 0.3s ease-in-out ; -o-transition:all 0.3s ease-in-out ; }
.scale a.btn-plus::after{ transform:rotate(90deg) ; -webkit-transform:rotate(90deg) ; -moz-transform:rotate(90deg) ; -ms-transform:rotate(90deg) ; -o-transform:rotate(90deg) ; }
.scale a:hover{background: #408AC2;}
.scale a:hover::before, .scale a:hover::after{background: #fff;}

.scale .range {width: 180px;}
.scale .range .progress {height: 2px;border-radius: 5px;background: #074D83;overflow: visible;}
.scale .range .progress .progress-bar {background: #fff;position: relative;overflow: visible;}
.scale .range .progress .progress-bar:after {content: '';position: absolute;background: #0b3454;width: 12px;height: 12px;border: 2px solid #fff;right: -6px;border-radius: 50%;}

.canvas-main .icon-picture {width: 90px;height: 90px;background-size: 100%;filter: brightness(0) invert(0);opacity: 0.5;margin-bottom: 15px;}

.btn.btn-open {background: #F5F5F5;border: 1px solid #6B6868;padding: 3px 30px 5px;border-radius: 25px;font-weight: 500;transition:all 0.3s ease-in-out ;-webkit-transition:all 0.3s ease-in-out ;-moz-transition:all 0.3s ease-in-out ;-ms-transition:all 0.3s ease-in-out ;-o-transition:all 0.3s ease-in-out ;}
.btn.btn-open:hover{background: #6B6868;color: #fff;}

.canvas-photos-library {	width: 300px;}
.canvas-photos-library > div{padding: 12px;}
.canvas-photos-library > div:first-child{border-bottom: 1px solid #236CA5;}
.search-photos .form-control {	border: none;	height: 40px;}
.canvas-photos-library .link{color: #6AABDD;text-decoration: none;}
.canvas-photos-library .link:hover{color: #fff;}
.canvas-text {padding: 15px;height: 100%;width: 300px;} 
.text-block {background: #fff;border-radius: 8px;padding: 15px;}
.height-90 {height: 90px;}
.color-wrap {background: #FFFFFF;border-radius: 4px;padding: 10px;display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex;}
.color-wrap label {margin-right: 8px;font-weight: 600;}
.color-wrap input {border: none;width: 22px;height: 22px;border-radius: 5px;}

.text-color-236CA5{color: #236CA5;}
.btn-back-text {background: transparent;border: 2px solid #fff;border-radius: 25px;height: 40px;color: #fff;transition:all 0.3s ease-in-out ;-webkit-transition:all 0.3s ease-in-out ;-moz-transition:all 0.3s ease-in-out ;-ms-transition:all 0.3s ease-in-out ;-o-transition:all 0.3s ease-in-out ;}
.btn-back-text:hover{background: #fff;color: #000;}

.color-field { display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; -webkit-box-wrap: wrap; -moz-box-wrap: wrap; -ms-flex-wrap: wrap; -webkit-wrap: wrap; flex-wrap: wrap; background: #FFFFFF 0% 0% no-repeat padding-box; border: 1px solid #D2D2D2; border-radius: 4px; padding: 6px; -webkit-border-radius: 4px; -moz-border-radius: 4px; -ms-border-radius: 4px; -o-border-radius: 4px; }
.color-field label {margin-right: 8px;margin-left: 5px;}
.color-field input { border: none; outline: none; appearance: none; box-shadow: none; border-radius: 4px; -webkit-border-radius: 4px; -moz-border-radius: 4px; -ms-border-radius: 4px; -o-border-radius: 4px;}

.profile-poster-wrapper {background: transparent linear-gradient(117deg, #00805F 0%, #FDB813 60%, #A6CE3A 100%) 0% 0% no-repeat padding-box;padding: 30px 30px;min-width: 1092px;max-width: 1200px;}
.profile-poster {background: #FFFFFF;border-radius: 40px 40px 0 0;padding: 2%;display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;-webkit-box-wrap: wrap;-moz-box-wrap: wrap;-ms-flex-wrap: wrap;-webkit-wrap: wrap;flex-wrap: wrap;position: relative;overflow: hidden;min-height: 605px;}

.profile-poster-curve {content: '';background: #00805f;position: absolute;bottom: 0;top: 394px;left: -123px;width: 537px;height: 537px;border-radius: 50%;}
.profile-poster-left {width: 25%;display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex;flex-direction: column;align-items: center;padding-top: 20px;position: relative;}
.alumni-cards-curve-first {position: absolute;background: #00805f;border-radius: 50%;width: 190%;height: 28%;left: -44%;right: 0;margin: auto;top: -3%;z-index: 1;}
.alumni-cards-curve-second {position: absolute;background: #c8c32a;border-radius: 50%;width: 190%;height: 28%;left: -43%;right: 0;margin: auto;top: -2%;z-index: 0;transform: rotate(2deg);}
.profile-poster-left .powerby {flex-direction: revert;margin-top: 15px;color: #fff;}
.profile-poster-left .powerby span {margin-right: 5px;}
.college-logo {margin-bottom: 20px;}
.community-title {margin-bottom: 20px;font-size: 32px;font-weight: 600;color: #008060;width: 90%;text-align: center;line-height: 35px;}
.scan-wrap {background: #fff;padding: 18px;max-width: 185px;text-align: center;}
.scan-heading {font-size: 12px;line-height: 17px;letter-spacing: 6.01px;color: #000000;margin-bottom: 12px;}
.profile-poster-right {width: 75%;padding: 50px 0 0;}
.profile-poster-right h3{font-size: 20px;font-weight: 600;margin-bottom: 0px;text-align: center;}

.profile-poster-alumni {display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;flex-wrap: wrap;-webkit-box-wrap: wrap;-moz-box-wrap: wrap;-ms-flex-wrap: wrap;-webkit-wrap: wrap;margin-bottom: 50px;justify-content: center;}

.profile-poster-right h3[contenteditable] {border: 2px dashed #000;position: relative;padding: 3px 7px;}
.profile-poster-right h3:focus-visible {outline: none;}
.profile-poster-right h3[contenteditable]::before {content: "\e912";font-family: "icomoon" !important;font-style: normal;font-weight: normal;font-variant: normal;text-transform: none;line-height: 1;position: absolute;top: -23px;right: -23px;}
.alumni-cards {width: 23.4%;border: 1px solid rgba(195, 195, 195, 0.35);border-radius: 12px;opacity: 1;display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;flex-direction: column;align-items: center;padding: 2% 10px;position: relative;min-height: 275px;background-color: #ffffff; }
.alumni-cards .alumni-img {width: 80px;height: 80px;display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;justify-content: center;overflow: hidden;border-radius: 50%;background-color: #f0f0f0; z-index: 2;position: relative;}
.alumni-cards .alumni-img img {min-width: 100%;min-height: 100%;max-height: 100%;object-fit: cover; }
.alumni-cards h4 {color: #FDB813;font-size: 15px;margin: 10px 0 0;min-height: 20px; text-align: center;}
.alumni-cards .batch {font-size: 13px;min-height: 16px;}
.alumni-cards .batch + i {width: 59px;height: 1px;background: linear-gradient(90deg, #A6CE3A 0%, #00805F 57%, #FDB813 100%);margin: 10px 0;}
.alumni-cards > div {min-height: 20px;}
.alumni-cards:not(:last-child) {margin-right: 14px;}
.alumni-cards {width: 23.4%;background: #FFF;border: 1px solid #C3C3C359;border-radius: 12px;opacity: 1;display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;flex-wrap: wrap;-webkit-box-wrap: wrap;-moz-box-wrap: wrap;-ms-flex-wrap: wrap;-webkit-wrap: wrap;flex-direction: column;align-items: center;padding: 2% 10px;position: relative;}
.alumni-cards > div{position: relative;}
.alumni-cards h4 {color: #FDB813;font-size: 15px;margin: 10px 0 0;}
.alumni-cards > div.batch {font-size: 13px;}

.alumni-cards .divider {width: 59px;height: 0.720733642578125px;background: transparent linear-gradient(90deg, #A6CE3A 0%, #00805F 57%, #FDB813 100%) 0% 0% no-repeat padding-box;margin: 10px 0;}
.alumni-University-list {text-align: center;font-size: 14px;}
.alumni-University-list .heading {color: #000;}
.alumni-University-list .brife {color: #A6CE3A;font-size: 14px;font-weight: 600;}
.alumni-University-list:not(:last-child) {margin-bottom: 10px;}
.alumni-companies-list {display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;justify-content: center;}
.company-block { border: 1px solid #00805F5C; border-radius: 6px; opacity: 1; overflow: hidden; display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; align-items: center; justify-content: center; height: 65px; padding: 0 10px; -webkit-border-radius: 6px; -moz-border-radius: 6px; -ms-border-radius: 6px; -o-border-radius: 6px;}
.company-block:not(:last-child) {margin-right: 7px;}
.company-block img {max-width: 90px;height: auto;}

.leaders-poster {max-width: 1080px;background: #0164A7 ;position: relative;overflow: hidden;}
.leaders-poster-container{background-color: #fff;border-radius: 98px 0px 0px 0px;}

.leaders-poster .college-logo {position: absolute;top: 7%;left: 9%;z-index: 2;margin: 0;width: 18.5%;}
.leaders-poster .college-logo img{max-height: 80px;}
.leaders-poster-alumni {position: absolute;left: 13.5%;top: 29%;z-index: 1;background: #C79757;padding: 10.8%;border-radius: 50%;overflow: hidden;display: flex;justify-content: center;align-items: center;}
.leaders-poster-alumni img {position: absolute;top: 0;bottom: 0;margin: auto;width: auto;height: 100%;}
.leaders-poster-alumni-detail {position: absolute;left: 40%;top: 35%;width: 49%;}
.leaders-poster-alumni-name {color: #0064A7;font-size: 50px;font-weight: 700;line-height: 55px;padding-left: 3%;}
.leaders-poster-alumni-batch-school {font-size: 30px;font-weight: 500;padding-left: 3%;}
.curve-line {margin: 10px 0 40px;}
.pursuing {padding-left:3%;position: relative;}
.pursuing i {position: absolute;width: 6px;height: 40px;background: #C79757;left: 0%;top: 4%;}
.pursuing-logo{width: 30px;height: 30px;display: flex;align-items: center;justify-content: center;}
.pursuing-logo img{max-width: 30px;max-height: 30px;}
.pursuing-title {font-size: 35px;margin-bottom: 20px;}
.pursuing-title span {font-weight: 600;}
.expertise {position: absolute;top: 79%;left: 12%;font-size: 1.6vw;width: 60%;line-height: 2.1vw;}
.expertise strong {display: block;}
.expertise i {position: absolute;width: 6px;height: 40px;background: #C79757;left: -4%;top: -34%;}
.max-540{max-width: 540px;}
.max-540 .leaders-poster-alumni-name{font-size: 24px;line-height: 26px;}
.max-540 .leaders-poster-alumni-batch-school {	font-size: 16px;}
.max-540 .pursuing-title {font-size: 20px;margin-bottom: 5px;}
.max-540 .pursuing i {width: 2px;height: 20px;left: 0%;top: 4%;}
.max-540 .expertise i {width: 3px;height: 30px;left: -6%;top: -34%;}
.max-540 .expertise {font-size: 20px;line-height: 24px;}
.max-540 .curve-line {	margin: 0px 0 20px;}

.max-430{max-width: 430px;}
.min-430{min-width: 430px;}
.max-430 .leaders-poster-alumni {	left: 12.5%;}
.max-430 .leaders-poster-alumni-detail {left: 37%;top: 32%;}
.max-430 .leaders-poster-alumni-name{font-size: 20px;  line-height: 22px;}
.max-430 .leaders-poster-alumni-batch-school {	font-size: 13px;}
.max-430 .pursuing-title {font-size: 18px;margin-bottom: 5px;}
.max-430 .pursuing-title.font-13{line-height: 14px;}
.max-430 .pursuing i {width: 2px;height: 20px;left: 0%;top: 4%;}
.max-430 .expertise i {width: 3px;height: 30px;left: -6%;top: -34%;}
.max-430 .expertise {font-size: 17px;line-height: 20px;}
.max-430 .curve-line {	margin: 0px 0 5px;}

.leaders-poster.preview {max-width: 710px;margin: 0 auto;}
.leaders-poster.preview .college-logo {top: 10%;left: 10%;width: 20%;}
.leaders-poster.preview .college-logo img {max-height: 100px;}
.leaders-poster.preview .leaders-poster-alumni{top:26%}
.leaders-poster.preview .leaders-poster-alumni-name {font-size: 36px;line-height: 40px;}
.leaders-poster.preview .curve-line {margin: 10px 0 20px;}
.leaders-poster.preview .pursuing-title.font-13{font-size: 18px !important;line-height: 20px;}
.leaders-poster.preview .leaders-poster-alumni-detail {top: 28%;}
.leaders-poster.preview .leaders-poster-alumni-batch-school {font-size: 20px;}
.leaders-poster.preview  .expertise {font-size: 1.3vw;line-height: 1.8vw;}

.social-post-tab {overflow: auto;padding: 15px;flex-wrap: nowrap;}
.social-post-tab::-webkit-scrollbar {height: 3px;}
.social-post-tab::-webkit-scrollbar-track {background: #ccc;}
.social-post-tab::-webkit-scrollbar-thumb {background: #666;}
.social-post-tab::-webkit-scrollbar-thumb:hover {background: #999;} 
.social-post-tab .nav-item:not(:last-child){margin-right:10px}
.social-post-tab .nav-item .nav-link{border-radius:25px;padding:12px 25px;background-color: transparent;color: #000;display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex; align-items: center;}
.social-post-tab .nav-item .nav-link i{margin-right: 10px;}

.social-post-tab .nav-item .nav-link.popular{border: 1px solid #F1AC18;}
.social-post-tab .nav-item .nav-link.popular i{background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/images/tab-effect.svg);width: 17px;height: 17px; }
.social-post-tab .nav-item .nav-link.facebook{border: 1px solid #1877F2;}
.social-post-tab .nav-item .nav-link.facebook i{background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/images/tab-facebook.svg);width: 22px;height: 22px;}
.social-post-tab .nav-item .nav-link.instagram{border: 1px solid #E30085;}
.social-post-tab .nav-item .nav-link.instagram i{background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/images/tab-instagram.svg);width: 20px;height: 20px;}
.social-post-tab .nav-item .nav-link.youtube{border: 1px solid #FF0000;}
.social-post-tab .nav-item .nav-link.youtube i{background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/images/tab-youtube.svg);width: 27px;height: 19px;}
.social-post-tab .nav-item .nav-link.twitter{border: 1px solid #000000;}
.social-post-tab .nav-item .nav-link.twitter i{background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/images/tab-twitter.svg);width: 17px;height: 17px;}
.social-post-tab .nav-item .nav-link.email{border: 1px solid #D82A2A;}
.social-post-tab .nav-item .nav-link.email i{background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/images/tab-email.svg);width: 26px;height: 15px;}

.social-post-tab .nav-item .nav-link:not(.active, :hover) {background: #0B3454;color:#fff;border-color: #0B3454;}
.social-post-tab .nav-item .nav-link:not(.active, :hover) i {filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(150%);-webkit-filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(150%);}
.social-post-tab .nav-item .nav-link.popular:not(.active, :hover) i, .social-post-tab .nav-item .nav-link.instagram:not(.active, :hover) i {filter: brightness(0)invert(1);-webkit-filter: brightness(0)invert(1);}

.social-post-template-block {background: #E8E8E8;border-radius: 12px;padding: 30px 40px;height: 100%;text-align: center;position: relative;overflow: hidden;}
.social-post-template-block::before{ content: ''; position: absolute; left: 0; right:0; top: 0; width: 100%; height: 0; margin: auto; transition:all 0.3s ease-in-out ; -webkit-transition:all 0.3s ease-in-out ; -moz-transition:all 0.3s ease-in-out ; -ms-transition:all 0.3s ease-in-out ; -o-transition:all 0.3s ease-in-out ;background: rgba(0, 0, 0, .6); }
.social-post-template-block:hover::before{height: 100%;}
.social-post-template-block .template-block-icon {height: 125px;display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;justify-content: center;align-items: end;margin: 0 0 20px;}
.social-post-template-block h3 {font-size: 18px;margin: 0 0 5px;}
.social-post-template-block p {margin: 0;}
.social-post-template-block a {position: absolute;left: 0;right: 0;top: 100%;bottom: auto;margin: auto;width: 150px;height: 40px;background: #fff;display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;justify-content: center;align-items: center;text-decoration: none;border-radius: 25px;color: #000;transition:all 0.3s ease-in-out ;-webkit-transition:all 0.3s ease-in-out ;-moz-transition:all 0.3s ease-in-out ;-ms-transition:all 0.3s ease-in-out ;-o-transition:all 0.3s ease-in-out ;opacity: 0;}
.social-post-template-block a:hover{background: #5cae48;color: #fff;}
.social-post-template-block:hover a{opacity: 1;top: 0;  bottom: 0;}

.btn-verticle-popup {position: fixed;z-index: 9;top: 270px;width: 40px;right: 0;display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;flex-direction: column;padding: 20px 0px;border-radius: 0 10px 10px 0;-webkit-border-radius: 0 10px 10px 0;-moz-border-radius: 0 10px 10px 0;-ms-border-radius: 0 10px 10px 0;-o-border-radius: 0 10px 10px 0;text-decoration: none;margin: 2px 0px;writing-mode: vertical-rl;text-orientation: mixed;transform: rotate(180deg);-webkit-transform: rotate(180deg);-moz-transform: rotate(180deg);-ms-transform: rotate(180deg);-o-transform: rotate(180deg);white-space: nowrap;writing-mode: vertical-rl;	align-items: center;border: 0;background-color: #0B3454;color: #ffffff;line-height: 40px;font-weight: 600;transition: all 0.3s ease-in-out;-webkit-transition: all 0.3s ease-in-out;-moz-transition: all 0.3s ease-in-out;-ms-transition: all 0.3s ease-in-out;-o-transition: all 0.3s ease-in-out;}
.btn-verticle-popup:hover{background-color: #398227;}

.btn-horizontal-popup { position: fixed; bottom: 0; right: 30px; padding: 8px 20px; font-weight: 600; border: 0; border-radius: 7px 7px 0 0px; background: #0B3454; color: #fff; -webkit-border-radius: 7px 7px 0 0px; -moz-border-radius: 7px 7px 0 0px; -ms-border-radius: 7px 7px 0 0px; -o-border-radius: 7px 7px 0 0px;}
.btn-horizontal-popup:hover{background-color: #398227;}

.blue-filter{ filter: invert(26%) sepia(99%) saturate(7494%) hue-rotate(215deg) brightness(84%) contrast(91%); -webkit-filter: invert(26%) sepia(99%) saturate(7494%) hue-rotate(215deg) brightness(84%) contrast(91%); }
.offer_joined i {background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/hat_alumni.svg);width: 23px;height: 17px;margin-right: 0;}

.qr-wrap {background: #0B3454;border-radius: 12px;padding: 35px;margin-bottom: 25px;}
.icon-write-review {background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/write-review.svg);width: 36px;height: 36px;display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;}

.review {display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;flex-wrap: wrap;-webkit-box-wrap: wrap;-moz-box-wrap: wrap;-ms-flex-wrap: wrap;-webkit-wrap: wrap;flex-direction: row-reverse;}
.review label {background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='%23707070' stroke='%23707070' stroke-linecap='' stroke-linejoin='' stroke-width='1' d='M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.56.56 0 0 0-.163-.505L1.71 6.745l4.052-.576a.53.53 0 0 0 .393-.288L8 2.223l1.847 3.658a.53.53 0 0 0 .393.288l4.052.575-2.906 2.77a.56.56 0 0 0-.163.506l.694 3.957-3.686-1.894a.5.5 0 0 0-.461 0z'/%3e%3c/svg%3e");background-repeat: no-repeat;background-position: center;background-size: 100%;width: 16px;height: 16px;margin-right: 3px;cursor: pointer;position: relative;} 

.review input:checked ~ label {background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='%23FFC107' stroke='%23FFC107' stroke-linecap='' stroke-linejoin='' stroke-width='1' d='M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z'/%3e%3c/svg%3e");}
.google-review-ui *{font-family: "Open Sans", sans-serif;}
.google-review-ui h2 {border-bottom: 1px solid #E4E4E4;padding-bottom: 20px;font-weight: 700;}
.google-review-ui .review label {width: 60px;height: 60px;margin-right: 30px;background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='%23707070' stroke='%23707070' stroke-linecap='' stroke-linejoin='' stroke-width='0' d='M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.56.56 0 0 0-.163-.505L1.71 6.745l4.052-.576a.53.53 0 0 0 .393-.288L8 2.223l1.847 3.658a.53.53 0 0 0 .393.288l4.052.575-2.906 2.77a.56.56 0 0 0-.163.506l.694 3.957-3.686-1.894a.5.5 0 0 0-.461 0z'/%3e%3c/svg%3e");}
.google-review-ui .review input:checked ~ label {background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='%23FFC107' stroke='%23FFC107' stroke-linecap='' stroke-linejoin='' stroke-width='0' d='M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z'/%3e%3c/svg%3e");}
.profile-section-detail{line-height: 28px;}
.icon-camera {background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/icon-camera.svg) no-repeat 0 0;width: 20px;height: 20px;display: block;}
.google-review-ui .btn-border-gray{color: #0C4CBB;}
.google-review-ui .btn-border-gray:hover{color: #fff;}
.google-review-ui .btn-border-gray:hover i{ filter: brightness(0) invert(1) ; -webkit-filter: brightness(0) invert(1) ; }

td .review{flex-direction: row;}
.review i {background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='%23707070' stroke='%23707070' stroke-linecap='' stroke-linejoin='' stroke-width='1' d='M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.56.56 0 0 0-.163-.505L1.71 6.745l4.052-.576a.53.53 0 0 0 .393-.288L8 2.223l1.847 3.658a.53.53 0 0 0 .393.288l4.052.575-2.906 2.77a.56.56 0 0 0-.163.506l.694 3.957-3.686-1.894a.5.5 0 0 0-.461 0z'/%3e%3c/svg%3e");background-repeat: no-repeat;background-position: center;background-size: 100%;width: 16px;height: 16px;margin-right: 3px;cursor: pointer;position: relative;} 
.review i:before {content: '';position: absolute;width: 18px;height: 18px;left: 0;top: 0;bottom: 0;background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='%23FFC107' stroke='%23FFC107' stroke-linecap='' stroke-linejoin='' stroke-width='0' d='M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z'/%3e%3c/svg%3e");background-repeat: no-repeat;background-size: 16px;opacity: 0;}

.review.poor i:first-child{background: none;}
.review.poor i:first-child::before {opacity: 1;}
.review.poor.half i:nth-child(2)::before{width: 8px;opacity: 1;}
.review.average i:first-child, .review.average i:nth-child(2){background: none;}
.review.average i:first-child::before, .review.average i:nth-child(2)::before {opacity: 1;}
.review.average.half i:nth-child(3)::before {width: 8px;opacity: 1;}
.review.good i:first-child, .review.good i:nth-child(2), .review.good i:nth-child(3){background: none;}
.review.good i:first-child::before, .review.good i:nth-child(2)::before, .review.good i:nth-child(3)::before {opacity: 1;}
.review.good.half i:nth-child(4)::before {width: 8px;opacity: 1;}
.review.very-good i:first-child, .review.very-good i:nth-child(2), .review.very-good i:nth-child(3), .review.very-good i:nth-child(4){background: none;}
.review.very-good i:first-child::before, .review.very-good i:nth-child(2)::before, .review.very-good i:nth-child(3)::before, .review.very-good i:nth-child(4)::before {opacity: 1;}
.review.very-good.half i:nth-child(5)::before {width: 8px;opacity: 1;}

.review.excellent i:first-child, .review.excellent i:nth-child(2), .review.excellent i:nth-child(3), .review.excellent i:nth-child(4), .review.excellent i:nth-child(5){background: none;}
.review.excellent i:first-child::before, .review.excellent i:nth-child(2)::before, .review.excellent i:nth-child(3)::before, .review.excellent i:nth-child(4)::before, .review.excellent i:nth-child(5)::before {opacity: 1;}

@media (max-width:767px){
.google-review-ui .review label {width: 40px;height: 40px;margin-right: 10px;}
}

@media (max-width:480px){
.leaders-poster .college-logo {width: 14.5%;}
.leaders-poster-alumni {padding: 9%;}
.max-430 .leaders-poster-alumni-name {font-size: 16px;line-height: 18px;}
.max-430 .leaders-poster-alumni-batch-school {font-size: 11px;}
.max-430 .pursuing-title {font-size: 14px;margin-bottom: 5px;}
.max-430 .expertise {font-size: 13px;line-height: 16px;}
}

@media (max-width: 400px) {
.max-430 .leaders-poster-alumni {top: 27%;}
.leaders-poster-alumni {padding: 8%;}
.max-430 .leaders-poster-alumni-detail {left: 32%;top: 28%;width: 52%;}
.max-430 .leaders-poster-alumni-name {font-size: 14px;line-height: 16px;}
.max-430 .leaders-poster-alumni-batch-school {font-size: 11px;line-height: 13px;}
.max-430 .expertise {font-size: 9px;line-height: 13px;}
.max-430 .curve-line {margin: 0;}
.max-430 .pursuing-title {font-size: 14px;margin-bottom: 0;}
}

.w-300{width: 300px;}


.poster-3 *{z-index: 3;}
i.slant {position: absolute;background: #2c256a;width: 210%;height: 240%;left: 0;top: 0;z-index: 1;transform: rotate(22deg);}
i.bottom-strip {background: #1c1555;height: 16%;position: absolute;left: 0;bottom: 0;width: 100%;z-index: 2;}
.leaders-poster.poster-3 .college-logo {top: 7%;left: 4%;width: 34%;}
.badge-template {position: absolute;top: 0;right: 40px;max-width: 100px;}
.max-430 .leaders-poster.poster-3 .expertise {font-size: 15px;line-height: 16px;position: absolute;bottom: 17px;left: 20px;width: 100%;max-width: 280px;top: auto;}
.max-430 .leaders-poster.poster-3 .leaders-poster-alumni-batch-school {margin-bottom: 5%;}
.poster-powerby{bottom: 13px;right: 15px;}
.max-430 .leaders-poster.poster-3 .leaders-poster-alumni-detail {left: 36%;top: 30%;width: 60%;}
.max-430 .leaders-poster.poster-3 .pursuing i {width: 7px;height: 7px;left: 0%;top: 8%;border-radius: 50%;background: #ffc221;}
.max-430 .leaders-poster.poster-3 .pursuing{padding-left: 6%;position: relative;}
.max-430 .leaders-poster.poster-3 .leaders-poster-alumni-batch-school i {display: inline-block;background: rgb(255,255,255);background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(46,36,101,1) 100%);height: 1px;width: 55px;vertical-align: top;margin: 10px 0 0 10px;}

.web-sertificate{min-width: 484px;margin:0 auto;position: relative;}
.web-sertificate > div{position: absolute;}
.web-sertificate .certificate-holder-name {top: 44%;left: 0;right: 0;text-align: center;font-size: 28px;font-weight: 600;}
.web-sertificate .certificate-holder-school {top: 56%;left: 0;right: 0;text-align: center;font-size: 12px;font-weight: 500;}
.web-sertificate .presenting {top: 63%;left: 0;right: 0;text-align: center;font-size: 16px;font-weight: 500;}
.web-sertificate .presenting .title {color: #828080;font-size: 13px;}
.web-sertificate .presenting .incontext {font-size: 13px;font-weight: 600;}
.web-sertificate .presenting .date {font-size: 13px;}

.web-sertificate.preview {max-width: 710px;margin:0 auto;position: relative;}
.web-sertificate > div{position: absolute;}
.web-sertificate.preview .certificate-holder-name {top: 44%;left: 0;right: 0;text-align: center;font-size: 42px;font-weight: 600;}
.web-sertificate.preview .certificate-holder-school {top: 56%;left: 0;right: 0;text-align: center;font-size: 16px;font-weight: 500;}
.web-sertificate.preview .presenting {top: 63%;left: 0;right: 0;text-align: center;font-size: 16px;font-weight: 500;}
.web-sertificate.preview .presenting .title {color: #828080;}
.web-sertificate.preview .presenting .incontext {font-size: 17px;font-weight: 600;}
.web-sertificate.preview .presenting .date {font-size: 14px;}

.alumniMembershipCardWrap {width: 484px;background: #FFFFFF 0% 0% no-repeat padding-box;border-radius: 17px;padding: 10px;}
.alumniMembershipCard{display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;flex-wrap: wrap;-webkit-box-wrap: wrap;-moz-box-wrap: wrap;-ms-flex-wrap: wrap;-webkit-wrap: wrap; position: relative;border-radius: 17px;overflow: hidden;}
.alumniMembershipCard i{width: 100%;position: absolute;height: 100%;left: 0;top: 0;z-index: 0;}
.alumniMembershipCard i.white-strip {width: 0;left: auto;right: -2px;border-width: 0 27px 350px 0px;border-color: transparent #fff transparent transparent;top: 0;bottom: 0;border-style: solid;}
.alumniMembershipCard-leftPane {width: 70%;position: relative;background: #053E6C;}
.alumniMembershipCardWrap .card-title-wrap {padding: 5% 8%;}
.alumniMembershipCardWrap .cardTitle .title {font-size: 45px;color: #fff;line-height: 45px;}
.alumniMembershipCardWrap .cardTitle .sub {font-size: 14px;color: #fff;font-weight: 600;letter-spacing: 10px;border-bottom: 2px solid #fff;margin-bottom: 20px;padding-bottom: 10px;}
.alumniMembershipCardWrap .card-title-wrap .school {font-size: 18px;font-weight: 600;color: #fff;}

.alumniMembershipCard-rightPane {width: 30%;position: relative;display: flex;flex-direction: column;justify-content: space-between;align-items: center;}
.alumniMembershipCardWrap .blueshade-strip {background: #1166A8;padding: 4% 8%;}
.alumniMembershipCardWrap .blueshade-strip .cardUser {font-size: 18px;font-weight: 600;color: #fff;}
.alumniMembershipCardWrap .blueshade-strip .cardUserBatch {font-size: 14px;font-weight: 600;color: #fff;}
.alumniMembershipCardWrap .validityWrap{padding: 4% 8%;}
.alumniMembershipCardWrap .validityWrap .cardNumber {font-size: 20px;color: #fff;letter-spacing: 5px;}
.alumniMembershipCardWrap .validity {font-size: 14px;color: #fff;font-weight: 600;}

.alumniMembershipCardWrap.preview {width: 637px;}
.alumniMembershipCardWrap.preview .cardTitle .title {font-size: 70px;color: #fff;line-height: 75px;}
.alumniMembershipCardWrap.preview .cardTitle .sub {font-size: 30px;color: #fff;font-weight: 600;letter-spacing: 10px;border-bottom: 2px solid #fff;margin-bottom: 20px;padding-bottom: 10px;}
.alumniMembershipCardWrap.preview .card-title-wrap .school {font-size: 30px;font-weight: 600;color: #fff;}
.alumniMembershipCardWrap.preview .blueshade-strip .cardUser {font-size: 22px;font-weight: 600;color: #fff;}
.alumniMembershipCardWrap.preview .blueshade-strip .cardUserBatch {font-size: 18px;font-weight: 600;color: #fff;}
.alumniMembershipCardWrap.preview .validityWrap .cardNumber {font-size: 30px;color: #fff;letter-spacing: 5px;}
.alumniMembershipCardWrap.preview .validity {font-size: 16px;color: #fff;font-weight: 600;}


.journey-poster{min-width: 484px;max-width: 484px;}
.journey-poster .top-title{font-size: 18px;}
.journey-poster .img-placehlder{position: relative;z-index: 0;}
.journey-poster .blue-wrap{background-color: #354b9c;padding: 15px;position: relative;z-index: 2;}
.journey-poster .profileWrap {  display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;align-items: end;max-width: 385px;margin: auto;margin-top: -35px;}
.journey-poster .profileWrap .pro-picture {width: 100px;height: 100px;display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;align-items: center;justify-content: center;border-radius: 50%;overflow: hidden;border: 2px solid #fff;margin-right: 30px;}
.journey-poster .profileWrap h2 {font-size: 24px;font-weight: 700;width: calc(100% - 200px);color: #fff;}
.journey-poster ul.journey li .content-wrap {flex: 1;display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;flex-wrap: wrap;-webkit-box-wrap: wrap;-moz-box-wrap: wrap;-ms-flex-wrap: wrap;-webkit-wrap: wrap;flex-direction: column;padding: 12px;font-weight: 500;word-break: break-word;}
.journey-poster:not(.preview) ul.journey li .content-wrap {font-size: 14px;}
.journey-poster .white-content-wrap .white-wrap {z-index: 2;  background: #fff;max-width: 600px;width: 90%;margin: 0 auto;padding: 15px;border: 1px solid #e3e3e3;}
.journey-poster .white-content-wrap h3 {color: #3aae6f;font-weight: 600;font-size: 20px;margin-bottom: 15px;}
.journey-poster .white-content-wrap .white-wrap ul.dots li{display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;flex-wrap: wrap;-webkit-box-wrap: wrap;-moz-box-wrap: wrap;-ms-flex-wrap: wrap;-webkit-wrap: wrap;font-size: 14px;}
.journey-poster .white-content-wrap .white-wrap ul.dots li:not(:last-child){margin-bottom: 10px;}
.journey-poster .white-content-wrap .white-wrap ul.dots li .dot{width: 8px;background-color: #3aae6f;height: 8px;border-radius: 50%;margin-right: 11px;margin-top: 7px;vertical-align: top;}
.journey-poster .white-content-wrap .white-wrap ul.dots li span{flex:1}
.journey-poster .white-content-wrap {position: relative;padding-bottom: 30px;}

.journey-poster.preview{min-width: 730px;max-width: 730px;margin: 0 auto;}
.journey-poster.preview .top-title{font-size: 24px;}
.journey-poster.preview  .blue-wrap{padding: 30px;}
.journey-poster.preview .profileWrap {  display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;align-items: end;max-width: 450px;margin: auto;margin-top: -71px;}
.journey-poster.preview .profileWrap .pro-picture {width: 170px;height: 170px;display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;align-items: center;justify-content: center;border-radius: 50%;overflow: hidden;border: 2px solid #fff;margin-right: 30px;}
.journey-poster.preview .profileWrap h2 {font-size: 37px;font-weight: 700;width: calc(100% - 200px);color: #fff;}
.journey-poster.preview .white-content-wrap {padding-bottom: 60px;}
.journey-poster .white-content-wrap > i{position: absolute;left: 0;right: 0;top: 0;height: 80px;background-color: #354b9c;z-index: 0;}
.journey-poster.preview .white-content-wrap .white-wrap{padding: 40px;}
.journey-poster .white-content-wrap .white-wrap:not(:last-child){margin-bottom: 15px;}
.journey-poster .white-content-wrap .white-wrap ul {padding: 0;margin: 0;list-style: none;}
.journey-poster .white-content-wrap .white-wrap ul.journey {display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;flex-wrap: wrap;-webkit-box-wrap: wrap;-moz-box-wrap: wrap;-ms-flex-wrap: wrap;-webkit-wrap: wrap;}
.journey-poster .white-content-wrap .white-wrap ul.journey li {  display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;  flex-direction: column;  text-align: center;flex:1}
.journey-poster ul.journey li .number {  color: #3aae6f;  font-weight: 500;  padding: 8px;}
.journey-poster ul.journey li .line {width: 100%;height: 3px;background: #3aae6f;position: relative;}
.journey-poster ul.journey li .dot{position: absolute;left: 0;right: 0;margin: auto;width: 14px;height: 14px;background-color: #fff;border: 2px solid #3aae6f;border-radius: 50%;top: 0;bottom: 0;margin: auto;}
.journey-poster.preview ul.journey li .content-wrap {padding: 15px 5px;}
.journey-poster ul.journey li .logo {  margin-top: auto;}
.journey-poster ul.journey li .logo img{max-width: 100%;}
.journey-poster.preview .white-content-wrap h3 {font-size: 24px;}
.journey-poster.preview .white-content-wrap .white-wrap ul.dots li{font-size: 16px;}

.ratio-16{width: 16px;height: 16px;}
.icon-down-arrow{transition: all 0.3s ease-in-out;}
.icon-down-arrow[aria-expanded="true"]{transform: rotate(180deg);}
.border-d52941{border: 1px solid #d52941;}

.height-20 {height: 20px !important;}
.bg-blue-0d3453{background-color: #0d3453;}
.bg-gray-f5f5f5{background-color: #f5f5f5;}
.bg-gray-E6E6E6{background-color: #E6E6E6;}
.dash-border{border-bottom: 2px dashed #ccc;opacity: 1;background-color: transparent;}
.min-h-80{min-height: 80px;}

.btn-gray-de { background: #D2D2D2 ; border: 1px solid #D2D2D2; border-radius: 25px; padding: 8px 18px; display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; align-items: center; color: #000; transition:all 0.3s ease-in-out ; -webkit-transition:all 0.3s ease-in-out ; -moz-transition:all 0.3s ease-in-out ; -ms-transition:all 0.3s ease-in-out ; -o-transition:all 0.3s ease-in-out ;}
.btn-gray-de:hover {background: #0b3454;border-color: #0b3454;color: #fff;}

.lastTRLineRemove tr:last-child{border-color: transparent !important;}
.work-block .work-detail .icon-hat{background: url(https://ags-images-bucket.s3.ap-south-1.amazonaws.com/images/hat.svg);background-size: auto;width: 24px;height: 23px;background-size: 100%;display: flex;}
.alumni_img_link .icon-star.circle-img {bottom: 0px;right: 3px;}
.alumni_univarsity_name .icon-hat{background: url(https://ags-images-bucket.s3.ap-south-1.amazonaws.com/images/hat.svg);background-size: auto;width: 24px;height: 23px;background-size: 100%;display: flex;}