@import 'variable.scss'; 
@import 'mixins.scss'; 
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@100;200;300;400;500;600;700&display=swap');
*{padding: 0;margin: 0;box-sizing: border-box;font-family: 'Jost', sans-serif;}

body{
    @include displayFlex;
    flex-direction: column;
    min-height: 100vh;    
}

.font{
    &-9{font-size: 9px;}
    &-10{font-size: 10px;}
    &-11{font-size: 11px;}
    &-12{font-size: 12px;}
    &-13{font-size: 13px;}
    &-14{font-size: 14px;}
    &-15{font-size: 15px;}
    &-16{font-size: 16px;}
    &-17{font-size: 17px;}
    &-18{font-size: 18px;}
    &-19{font-size: 19px;}
    &-20{font-size: 20px;line-height: 22px;}
    &-21{font-size: 21px;}
    &-22{font-size: 22px;}
    &-23{font-size: 23px;}
    &-24{font-size: 24px;}
    &-25{font-size: 25px;}
    &-26{font-size: 26px;}
    &-27{font-size: 27px;}
    &-28{font-size: 28px;}
    &-29{font-size: 29px;}
    &-30{font-size: 30px;}
    &-31{font-size: 31px;}
    &-32{font-size: 32px;}

    &-100{font-weight: 100;}
    &-300{font-weight: 300;}
    &-400{font-weight: 400;}
    &-500{font-weight: 500;}
    &-600{font-weight: 600;}
    &-700{font-weight: 700;}
    &-900{font-weight: 900;}
}

.text{
    &-dark-gray{color: $dark-gray;}
}

.flex-1{
    @include flex-1;
}

.w{
    &-140 {width: 140px;}
    &-270 {
        max-width: 270px;
        width: 100%;
    }
    &-312{
        max-width: 312px;
        width: 100%;
    }
    &-800{
        max-width: 800px;
        width: 100%;
    }
    &-860{
        max-width: 860px;
        width: 100%;
    }
    &-1030{
        max-width: 1030px;
        width: 100%;
    }
}

.height{
    &-46{height: 46px;}
}

.min-height{
    &-250{min-height: 250px;}
}

.bg-{
    &grd-blue{
        background: rgb(23,76,117);
        background: -moz-radial-gradient(circle, rgba(23,76,117,1) 0%, rgba(9,50,83,1) 100%);
        background: -webkit-radial-gradient(circle, rgba(23,76,117,1) 0%, rgba(9,50,83,1) 100%);
        background: radial-gradient(circle, rgba(23,76,117,1) 0%, rgba(9,50,83,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#174c75",endColorstr="#093253",GradientType=1);
    }
}


.btn-green {
    background: #5CAE48;
    border-radius: 25px;
    height: 36px;
    @include displayFlex;
    justify-content: center;
    align-items: center;
    color: $white;
    &:hover {
        background: #f8b018;
      }
}

.wrapper {
    @include displayFlex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #fff;
    .dark-section{
        @include flex-1;
        background-color: rgb(11, 52, 84);     
        background-image: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/collage-building.svg);
        background-repeat: no-repeat;
        background-position: right top 30%;
        background-blend-mode: multiply;
        // clip-path: polygon(50% 0%, 100% 0, 100% 90%, 50% 100%, 0 90%, 0 0);
        @media (max-width: 575px){
            clip-path: polygon(50% 0%, 100% 0, 100% 93%, 50% 100%, 0 93%, 0 0);
        }
        &.student-img{
            background-image: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/gradute.svg);
            background-position: bottom center;
            background-size: 800px;
            @media (max-width: 575px){
                background-size: 450px;
            }
        }
        &.bg-grd-blue{
            background: rgb(23,76,117);
            background: -moz-radial-gradient(circle, rgba(23,76,117,1) 0%, rgba(9,50,83,1) 100%);
            background: -webkit-radial-gradient(circle, rgba(23,76,117,1) 0%, rgba(9,50,83,1) 100%);
            background: radial-gradient(circle, rgba(23,76,117,1) 0%, rgba(9,50,83,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#174c75",endColorstr="#093253",GradientType=1);
        }
        .logo {
            background: #fff;
            @include displayFlex;
            @include borderRadius(0 0 10px 10px);
            align-items: end;
            justify-content: center;            
            margin: 0 auto;
            padding: 15px 30px;
            max-width: 300px;
            max-height: 230px;
            min-width: 300px;

            img {
                height: 100%;
                width: auto;
                max-width: 100%;
            }
        }
        .home-page-title {
            font-size: 40px;
            text-align: center;
            @media (max-width: 1024px){
                font-size: 30px;
            }
            @media (max-width: 767px){
                font-size: 23px;
            }
        }
        .sep {
            width: 285px;
            height: 2px;
            background: #fff;
            @include borderRadius(5px);
            margin: 30px 0;
            &.blue{
                height: 2px;
                background: #2B6B9F;
                margin: 15px 0 45px;
            }
        }

        .school-name {
            font-size: 45px;
            line-height: 50px;
            text-align: center;
            @media (max-width: 1024px){
                font-size: 50px;
                line-height: 60px;
            }
        }

        .sub-school-tag {
            font-size: 36px;
            text-align: center;
            line-height: 51px;font-weight: 500;
            @media (max-width: 1024px){
                font-size: 30px;
                line-height: 34px;
            }
        }
        
        .header-icon {
            margin-bottom: 22px;
            @include displayFlex;
            align-items: end;
            position: relative;
            &::before {
                //content: '';
                position: absolute;
                width: 50px;
                height: 50px;
                background: #022642;
                @include borderRadius(50%);
                top: 0;
                right: -10px;
            }
        }

        .list {
            margin: 0;
            padding: 0;
            list-style: none;
            font-size: 30px;

            @media (max-width: 575px){
                font-size: 20px;
                line-height: 30px;
                padding-top: 40px;
                padding-left:40px ;
                padding-bottom: 50px;
            }
            li{
                position: relative;
                padding-left: 40px;
                &::before{
                    content: "";
                    position: absolute;
                    top: 9px;
                    left: 0;
                    background: url(https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/green-check.svg) no-repeat left top/100%;
                    width: 24px;
                    height: 24px;
                    @media (max-width: 575px){
                        width: 20px;
                        height: 20px;
                        top: 5px;
                    }
                }
                &:not(:last-child){
                    margin-bottom: 30px;
                }
            }
        }

        .id-title {
            font-size: 30px;
            line-height: 45px;
            font-weight: 600;
        }

        .form-title{
            font-size: 32px;
            line-height: 40px;
            text-align: center;
            font-weight: 600;
            border: none;
            @media (max-width: 575px){
                font-size: 20px;
                line-height: 26px;
                text-align: left;
                font-weight: 500;
            }
        }
        
        .white-label {
            background: white;
            width: 100%;
            height: 60px;
            @include borderRadius(35px);
            max-width: 200px;
            min-width: 200px;
            padding: 0 25px;
            color: black;
            @include displayFlex;
            align-items: center;
            font-weight: 500;
            font-size: 18px;
            cursor: pointer;
            i {
                width: 13px;
                height: 13px;
                border: 1px solid #666666;
                border-radius: 50%;
                margin-right: 10px;
            }
        }

        .form-check-input:checked + .white-label{
            i {
                border-color:$green-line;
                background-color: $green-line;
            }
        }

        .btn-submit {
            width: 140px;
            background: #5CAE48;
            border-radius: 25px;
            height: 36px;
            @include displayFlex;
            justify-content: center;
            align-items: center;
            color: $white;
            &:hover {
                background: #f8b018;
              }
        }

    }

        .footer-text-1 {
            font-size: 36px;
            line-height: 45px;
            font-weight: 600;
            text-align: center;
            @media (max-width: 1024px){
                font-size: 24px;line-height: 32px;
            }
        }
    
        .footer-text-2 {
            font-size: 30px;
            text-align: center;
            @media (max-width: 1024px){
                font-size: 24px;
            }
        }

    .principal-wrap {
        width: 281px;
        position: relative;
        margin: 0 auto;
        .arrow-img {
            position: absolute;
            right: calc(100% + 10px);
            bottom: 10px;
        }
        .btn-green {
            width: 100%;
            background: #5CAE48;
            border-radius: 25px;
            height: 44px;
            @include displayFlex;
            justify-content: center;
            align-items: center;
            color: $white;
            &:hover {
                background: #f8b018;
              }
        }
    }

.navigation_footer_wrap{
    position: sticky;
    bottom: 30px;
    height: 0;
    @media (max-width: 575px){
        height: 50px;
    }

    .navigation_footer {
        position: absolute;
        left: 0;
        right: 0;
        width: calc(100% - 80px);
        height: 55px;
        margin: auto;
        bottom: 0;
        @include displayFlex;
        justify-content: space-between;
        max-width: 250px;
        background: #fff;
        @include borderRadius(25px);
        align-items: center;
        padding: 0 5px;
        box-shadow: 0 0px 10px 1px #7676762e;

        a {
            width: auto;
            height: 45px;
            // background: url(../images/blue-arrow.svg) no-repeat 0 0;
            @include displayFlex;
            @include borderRadius(25px);
            align-items: center;
            padding: 0 20px;
            background-color: $green-line;
            color: $white;
            text-decoration: none;
            @include transition(all, 0.3s, ease-in-out);

            &.btn-back {
                i{
                    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='white' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0'/%3e%3c/svg%3e");
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 16px 12px;
                    width: 10px;
                    height: 20px;
                    margin-right: 7px;
                }
            }
            &.btn-next {
                i{
                    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='white' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708'/%3e%3c/svg%3e");
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 16px 12px;
                    width: 10px;
                    height: 20px;
                    margin-left: 7px;
                }
            }

            &:hover{
                background-color: #35911f;
                text-decoration: none;
            }

            span{text-decoration: none;}
        }


    }
    }
}



.left-graph {
    position: absolute;
    left: 0;
    top: -40%;
    width: 50%;
    @media (max-width: 1500px){
        top: -30%;
    }
    @media (max-width: 1250px){
        top: -20%;
    }
    @media (max-width: 1100px){
        top: -10%;
    }
    @media (max-width: 575px){
        top: 0%;
    }
    img {
        width: 100%;
      }
  }
  .right-graph {
    position: absolute;
    right: 0;
    top: -71%;
    width: 50%;
    @media (max-width: 1500px){
        top: -50%;
    }
    @media (max-width: 1250px){
        top: -40%;
    }
    @media (max-width: 1100px){
        top: -30%;
    }
    @media (max-width: 575px){
        top: -9%;
        width: 70%;
    }
    img {
        width: 100%;
      }
  }
 

  .thanksTxt {
	font-size: 100px;
	font-weight: 700;
	margin: 30px 0;
	font-style: italic;
    @media (max-width: 1100px){
        font-size: 60px;
        margin: 20px 0;
    }
    &-sub{
        font-size: 36px;
	    font-weight: 600;
        text-align: center;
        @media (max-width: 575px){
            font-size: 24px;
        }
        &-2{
            font-size: 46px;
            font-weight: 600;  
            text-align: center;
            @media (max-width: 575px){
                font-size: 30px;
            }
        }
        }
    }
    
    @media (max-width: 1100px){
        .thumb img {
            max-width: 130px;
        }
    }
    


    .form-label {
        display: inline-block;
        margin-bottom: 0.5rem;
        font-weight: 600;
      }
      
      .form-control {
        width: 100%;
        padding: 0.5rem;
        // margin-bottom: 0.5rem;
        box-sizing: border-box;
      }
      
      .error-message {
        color: red;
        font-size: 0.875rem;
        margin-top: -0.25rem; /* Prevent the error message from adding extra space */
        margin-bottom: 0.5rem;
        position: absolute; /* Position the error message absolutely */
      }
      
      .form-group {
        position: relative; /* Necessary to position the error message within the form group */
        margin-bottom: 1.5rem; /* Add space between form groups */
      }
      
      
    //   .col-12 {
    //     width: 100%;
    //   }
      
    //   .col-md-6 {
    //     width: 48%;
    //   }
      
      input.error {
        border-color: red;
      }